import fetchJson from "../../lib/fetchJson";
import { get, post } from "../../network";
import { Response } from "../../types/Response";
import { findByModel, updateDictionary } from "./SbxService";
import { getFetchedResults, organizationFindQuery } from "../../utils";
import { Condition } from "../../types/Sbx";
import { Find } from "sbxcorejs";
import { Query } from "../../components/Shared/QueryComponent/QueryComponent";
import { OperatorQuery, Query as QueryFind } from "../../utils/Query";

export const getAccountService = async ({
  token,
  account_key,
}: {
  token: string;
  account_key: string;
}) => {
  if (!token?.toLowerCase().startsWith("bearer")) {
    token = `Bearer ${token}`;
  }

  try {
    return await fetchJson(`/api/accounts/${account_key}`, {
      method: "GET",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        authorization: `${token}`,
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
    });
  } catch (error: any) {
    console.error(error);
    return { success: false, error: error.message };
  }
};

export async function getAccounts(
  params?:
    | {
        where?: Condition[];
        pagination?: boolean;
        page?: number;
        pageSize?: number;
        fetch?: string[];
      }
    | undefined,
) {
  if (params) {
    const where = await organizationFindQuery({
      where: params.where ?? [],
      row_model: "sbx_crm",
    });

    if (where?.length > 0) {
      params.where = where;
    }
  }

  return post(`/api/accounts`, params).then((res: any) => {
    let fetch = ["contact", "company.city"];

    if (params?.fetch && params.fetch.length > 0) {
      fetch = params.fetch;
    }

    const response = getFetchedResults(res, fetch);
    return {
      success: res.success,
      items: response.results,
      total_items: res.row_count,
    };
  });
}

export async function findByLike({
  search,
  size,
}: {
  search: string;
  size?: number;
}): Promise<Response> {
  const res = await get(`/api/search/v1.5/sbx_crm/${search}`);
  if (res.success && res.items?.length) {
    let query = new Find("sbx_crm", 0);

    if (res.items.length > 0) {
      query.andWhereIsIn("_KEY", size ? res.items.slice(0, size) : res.items);
    }

    const nQuery: Query = query.compile();

    const where = await organizationFindQuery({
      where: nQuery.where ?? [],
      row_model: "sbx_crm",
    });

    if (where?.length > 0) {
      nQuery.where = where;
    }

    let resAccounts = await findByModel({
      fetch: ["contact", "company"],
      row_model: "sbx_crm",
      where: nQuery.where,
      // where: res.items.length > 0 ? {keys: size ? res.items.slice(0, size) : res.items} : []
    });
    if (resAccounts) {
      const companies =
        resAccounts.fetched_results.company ||
        resAccounts.fetched_results.customer ||
        {};
      const contacts =
        resAccounts.fetched_results.contact ||
        resAccounts.fetched_results.user ||
        {};
      const searchArr = search
        .toLowerCase()
        .split(" ")
        .filter((v) => v);

      const items = resAccounts.results.map((account: any) => {
        account.company = companies[account.company] || null;
        account.contact = contacts[account.contact] || null;
        return account;
      });

      const query = new QueryFind(items);
      query.setCondition({ values: searchArr, operator: OperatorQuery.or });
      query.sort([
        "company.company_name",
        "company.contact_email",
        "company.tax_id",
        "company.office_phone",
        "company.account_id",
        "contact.first_name",
        "contact.last_email",
        "contact.email",
        "contact.mobile_phone",
      ]);
      return {
        ...resAccounts,
        items: query.getItems(),
        total_items: resAccounts.row_count,
      };
    }
  }
  return { success: true, items: [], total_items: 0 };
}

export async function findByModelLike({
  search,
  size,
  row_model,
  fetch = [],
  where,
}: {
  search: string;
  size?: number;
  row_model: string;
  fetch?: string[];
  where?: Condition[];
}): Promise<Response> {
  const res = await get(`/api/search/v1.5/${row_model}/${search}`);
  if (res.success && res.items?.length) {
    let response = await findByModel({
      fetch,
      row_model,
      where,
    });

    if (response?.success) {
      let results = response.results;
      if (response.results.length > 0) {
        results =
          getFetchedResults(response, fetch).results?.map((item) => ({
            ...item,
            row_model,
          })) ?? [];
      }

      return { ...response, results };
    }
  }
  return { success: true, items: [], total_items: 0 };
}

export function findByKey(key: string): Promise<Response<any>> {
  return get(`/api/accounts/${key}`).then((res: any) => {
    return { success: res.success, item: res.results[0] };
  });
}

export function getProjectsByAccount(params: {
  size: number;
  page: number;
  client: string;
}) {
  return get(`/api/v2/wf/process/execution`, { ...params });
}

export const getAccountsByKeys = ({
  accountKeys,
}: {
  accountKeys: string[];
}) => {
  return post("/api/accounts", { keys: accountKeys });
};

export function updateClientsDictionary() {
  return updateDictionary({
    model: "sbx_crm",
    fetch: ["contact", "company"],
    fields: [
      "company.company_name",
      "company.contact_email",
      "company.tax_id",
      "company.office_phone",
      "company.account_id",
      "contact.first_name",
      "contact.last_email",
      "contact.email",
      "contact.mobile_phone",
    ],
  });
}
