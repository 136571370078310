import * as React from 'react';
import {useState} from 'react';
import ChatBotComponent, {ChatMessages} from "../Shared/ChatbotComponent/ChatBotComponent";
import {BaseAnalyticQuery, Report, TruncateReport} from "../../types/Analytic";
import {State} from "../../types/State";
import useAsyncEffect from "../../hooks/useAsyncEffect";
import {askLlmReportQuery, initializeLlmReportQuery} from "../../services/backend/AnalyticsService";
import {convertDateToNumberDate, getQueryWithTruncate, toast} from "../../utils";
import {useSelector} from "react-redux";
import {authReducer} from "../../store/Selectors";
import ChatBotImageComponent from "../Shared/ChatbotComponent/ChatBotImageComponent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import PopoverComponent from "../Shared/PopoverComponent";
import useTranslate from "../../hooks/useTranslate";
import ButtonComponent from "../Shared/ButtonComponent";
import {updateModelRow} from "../../services/backend/SbxService";

type Props = {
    query: BaseAnalyticQuery
    report: Report
};


const HelperButton = ({report, setContext, context}: {
    report: Report,
    context: string,
    setContext: (value: string) => void
}) => {
    const {t} = useTranslate("common")
    const [loading, setLoading] = useState(State.IDLE)
    const [newContext, setNewContext] = useState('')
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(prevState => !prevState)

    React.useEffect(() => {

        if (newContext === "" && context !== "") {
            setNewContext(context)
        }

    }, [context, newContext]);

    return <div>
        <PopoverComponent buttonClassName={"m-0 p-0"} visible={visible} onVisibleChange={setVisible}
                          label={<FontAwesomeIcon className="pointer" onClick={() => {
                          }} icon={faInfoCircle} color={'white'}/>}
                          trigger="click" placement="bottom"
                          id={"data" + "_pop_info"}>
            <div className="d-flex flex-column gap-2">
                <div className="d-flex justify-content-between align-items-center">
                    <b> {t("description")}</b>
                    <FontAwesomeIcon onClick={toggle} className="pointer" icon={faTimesCircle}/>
                </div>


                <textarea name="" id=""
                          style={{width: '30em'}}
                          rows={10}
                          onChange={(e) => setNewContext(e.target.value)}
                          value={newContext}
                          className="form-control"/>

                <div className="d-flex justify-content-end">

                    <ButtonComponent disabled={loading === State.PENDING} loading={loading === State.PENDING}
                                     label={t("save")} onClick={async () => {
                        setLoading(State.PENDING)
                        const res = await updateModelRow([{
                            _KEY: report._KEY,
                            context: newContext
                        }], "sbx_crm_report")

                        toast({
                            message: res?.success ? `${t("description")} ${t("updated")}` : res?.error || t("error"),
                            type: res?.success ? 'success' : 'error'
                        })

                        setContext(newContext)
                        setLoading(State.RESOLVED)
                        toggle()
                    }}/>

                </div>
            </div>
        </PopoverComponent>

    </div>
}

const ReportChatBotComponent = ({query, report}: Props) => {
    const {user: {config}} = useSelector(authReducer)
    const [visibleChat, setVisibleChat] = useState(false)
    const toggle = () => setVisibleChat(prevState => !prevState)
    const [chatMessages, setChatMessages] = useState<ChatMessages[]>([])
    const [initialLoading, setInitialLoading] = useState(State.IDLE)
    const [loading, setLoading] = useState(State.IDLE)
    const [llmKey, setLlmKey] = useState('')
    const [context, setContext] = useState("")

    useAsyncEffect(async () => {
        if (report?._KEY && query && visibleChat && !llmKey) {
            setInitialLoading(State.PENDING)
            const response = await initializeLlmReportQuery(getQueryWithTruncate(report, query), report)
            if (response?.success && response.key) {
                setLlmKey(response.key)
                setInitialLoading(State.RESOLVED)
            } else {
                toast({
                    message: response?.error || 'Error initializing the data',
                    type: 'error'
                })
                setInitialLoading(State.REJECTED)
            }
        }

        if (report?.context) {
            setContext(report.context)
        }

    }, [query, report, visibleChat, llmKey])

    const askLlm = async (question: string) => {


        setLoading(State.PENDING)

        const params: {
            key: string,
            question: string,
            report_key: string,
            context?: string,
            template_context: string
        } = {
            key: llmKey, question, report_key: report._KEY ?? "", template_context: ""
        }

        if (context && context !== "" && context !== null) {
            params.context = context
        }

        if (config?.sbx_crm?.ai_context?.business) {
            params.template_context = config.sbx_crm.ai_context.business
        }


        const nQuery = getQueryWithTruncate(report, query)


        if ((nQuery.truncate as TruncateReport)?.hasOwnProperty("range")) {
            let dates: string[] = []
            if ((nQuery.truncate as TruncateReport)?.range?.toString().includes("-")) {
                dates = (nQuery.truncate as TruncateReport)?.range?.toString().split("-") ?? []
            } else {
                const today = new Date()
                let rangeOption = (nQuery.truncate as TruncateReport)?.range
                switch (rangeOption) {
                    case 0: {
                        const startDate = new Date(today.getFullYear(), today.getMonth(), 1)
                        const endDate = new Date()
                        dates = [convertDateToNumberDate(startDate), convertDateToNumberDate(endDate)]
                    }
                        break;
                    case 10: {
                        dates = [convertDateToNumberDate(today), convertDateToNumberDate(today)]
                    }
                        break;
                    case 1: {
                        const newToday = new Date()
                        newToday.setDate(newToday.getDate() - 30)
                        dates = [convertDateToNumberDate(newToday), convertDateToNumberDate(today)]
                    }
                        break;
                }
            }


            if (dates && dates?.length > 0) {
                if (!params.context) {
                    params.context = ""
                }

                params.context += `\n this dataset is being filtered from ${dates[0]} to ${dates[1]}`
            }

        }

        const response = await askLlmReportQuery(params)
        if (response?.success) {
            setChatMessages(prevState => [...prevState, {
                user: "bot",
                message: response.message ?? "",
                createdAt: new Date()
            }])


            if (response?.images && Object.keys(response.images).length > 0) {
                Object.keys(response.images).forEach(imageName => {
                    setChatMessages(prevState => [...prevState, {
                        user: "bot",
                        messageStyle: {},
                        messageClassname: "d-flex justify-content-center",
                        message: <ChatBotImageComponent imageName={imageName}
                                                        imageBase64={response.images[imageName]}/>,
                        createdAt: new Date()
                    }])
                })
            }
        }
        setLoading(State.RESOLVED)
    }

    return (
        <ChatBotComponent toggle={toggle} visibleChat={visibleChat} helperButton={<HelperButton
            context={context} setContext={setContext} report={report}/>}
                          initialLoading={initialLoading === State.PENDING} chatMessages={chatMessages}
                          loading={loading === State.PENDING} setLoading={isLoading => {
            setLoading(isLoading ? State.PENDING : State.RESOLVED)
        }}
                          setChatMessages={(message) => {
                              setChatMessages(prevState => [...prevState, {user: "me", message, createdAt: new Date()}])
                              askLlm(message)
                          }}/>
    );
};

export default ReportChatBotComponent