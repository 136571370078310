import * as React from 'react';
import {useContext} from 'react';
import {RenderQueryContext, typesValidation} from "./RenderReportQueryComponent";
import useTranslate from "../../../hooks/useTranslate";
import {Controller} from "react-hook-form";
import {Andor} from "../../../types/Sbx";
import styled from "styled-components";
import CreatableSelectComponent from "../../Shared/FieldComponents/CreatableSelectComponent";
import ButtonComponent from "../../Shared/ButtonComponent";
import {faCirclePlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import {Switch} from "antd";
import SelectComponent from "../../Shared/FieldComponents/SelectComponent";
import {getOptionsByField} from "../../Shared/QueryComponent/Utils";
import SpinnerComponent from "../../Shared/SpinnerComponent";
import FieldInput from "../../Shared/QueryComponent/FieldInput";

const Container = styled.div`
  display: grid;
  grid-template-columns: 5em 15em 10em 10em 2em;
  gap: 15px;
  align-items: end;
`


const SbxUserQueryComponent = () => {

    const {t} = useTranslate('common');
    const {models, loading, control, append, remove, fields, watch} = useContext(RenderQueryContext)

    if (!watch) return null;

    return (
        <div>
            <Controller control={control} render={({field: {onChange, value}}) => {
                const options = models.map(model => ({
                    label: model.name,
                    value: model.name
                }))
                return <div>
                    <span>{t("model")}</span>
                    <CreatableSelectComponent name={"Models"} placeholder={`${t("select_placeholder")}`}
                                              value={options.find(model => model.value === value)}
                                              loading={loading}
                                              disabled={loading}
                                              onChange={event => onChange(event.value)}
                                              options={options}/>
                </div>
            }} name={"row_model"}/>

            <div className="my-3 d-flex justify-content-end">
                <ButtonComponent label={t("add_condition")} disabled={!watch("row_model") || loading}
                                 icon={faCirclePlus}
                                 loading={loading} onClick={() => {
                    append({
                        ANDOR: Andor.AND,
                        FIELD: "",
                        VAL: "",
                        OP: ""
                    })
                }}/>
            </div>



            {loading ?
                <div className="d-flex justify-content-center">
                    <SpinnerComponent/>
                </div>
                :
                fields.map((field, index) => {
                    return <Container key={index}>

                        <Controller control={control} render={({field: {onChange, value}}) => {
                            return <Switch
                                unCheckedChildren={'Or'}
                                checkedChildren={t('And')}
                                onChange={checked => {
                                    onChange(checked ? Andor.AND : Andor.OR)
                                }}
                                checked={value.toUpperCase() === Andor.AND}/>
                        }} name={`where.${index}.ANDOR`}/>


                        <Controller control={control} render={({field: {onChange, value}}) => {
                            const options = models.find(model => model.name === watch("row_model"))?.properties.map(prop => ({
                                label: prop.name,
                                value: prop.name
                            }))

                            return <div>
                                <span>{t("field")}</span>
                                <CreatableSelectComponent name={"fields"} className="flex-grow-1"
                                                          placeholder={`${t("select_placeholder")}`}
                                                          value={options?.find(model => model.value === value)}
                                                          loading={loading}
                                                          onChange={event => {
                                                              onChange(event.value)
                                                          }}
                                                          disabled={loading || !watch("row_model")}
                                                          options={options ?? []}/>
                            </div>
                        }} name={`where.${index}.FIELD`}/>

                        <div>
                            <span>{t("condition")}</span>
                            <Controller control={control} render={({field: {onChange, value, name}}) => {



                                const type = models.find(model => model.name === watch("row_model"))?.properties.find(prop => prop.name === watch(`where.${index}.FIELD`))?.type ?? undefined
                                return <div>
                                    <SelectComponent
                                        id={name}
                                        name={name}
                                        value={getOptionsByField(t, type).find(option => option.value.condition === value)}
                                        onChange={event => onChange(event.value.condition)}
                                        options={getOptionsByField(t, type)}/>
                                </div>
                            }} name={`where.${index}.OP`}/>
                        </div>

                        <Controller control={control} render={({field: {onChange, value}}) => {

                            const prop = models.find(model => model.name === watch("row_model"))?.properties.find(prop => prop.name === watch(`where.${index}.FIELD`))

                            return <div>
                                <span>{t("value")}</span>
                                <FieldInput
                                    type={prop?.type ? typesValidation[prop.type] ?? prop?.type ?? "" : ""}
                                    value={value} onChange={onChange}
                                    id={`where.${index}.FIELD_${watch("row_model")}`} disabled={loading}/>
                            </div>
                        }} name={`where.${index}.VAL`}/>

                        <ButtonComponent label={""} icon={faTrash} color={"danger"} iconColor={"white"} className="p-1"
                                         loading={loading} onClick={() => remove(index)}/>

                    </Container>
                })}


        </div>
    );
};

export default SbxUserQueryComponent