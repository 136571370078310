import * as React from 'react';
import {useContext, useMemo, useState} from 'react';
import {DropdownItem} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faSpinner, faTimes, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import ButtonComponent from "../Shared/ButtonComponent";
import {AnalyticQueryAction, SourceFilter, SourceFrom} from "../../types/Analytic";
import {Model, ModelsResponse, SbxConditionTypeReport, SbxModelField} from "../../types/Sbx";
import useTranslate from "../../hooks/useTranslate";
import {Controller, UseControllerProps, UseFieldArrayRemove, UseFieldArrayUpdate, UseFormWatch} from "react-hook-form";
import {ActionButton, renderTextByType, renderType, ReportContext} from "./NewReportGeneratorComponent";
import RadioButtonComponent from "../Shared/FieldComponents/RadioButtonComponent";
import styled from "styled-components";
import DateComponent from "../Shared/FieldComponents/DateComponent";
import RenderMonthElement from "../Shared/RenderMonthElement";
import {convertDateCustom, convertDateToDDMMMYYYY, isValidDate} from "../../utils";
import DropdownActionComponent from "./NewActionsReportGeneratorComponent/DropdownActionComponent";
import {getColumnName, getColumnType} from "./NewActionsReportGeneratorComponent/utils";
import {conditionOptions, conditionOptionsDisplay} from "./ReportFilterComponent";
import ColumnsActionsWrapperComponent from "./ColumnsActionsWrapperComponent";
import {convertDatePyFormatToJsFormat} from "../../utils/analyticsUtils";
import SearchSuggestionOption from "./SearchSuggestionOption";
import SelectComponent from "../Shared/FieldComponents/SelectComponent";
import {StringOption} from "../../types/Select";
import useAsyncEffect from "../../hooks/useAsyncEffect";

type Props = {
    index: number
    remove: UseFieldArrayRemove,
    control: UseControllerProps<any>["control"]
    model?: ModelsResponse
    watch: UseFormWatch<{ filters: SourceFilter[] }>
    update: UseFieldArrayUpdate<{ filters: SourceFilter[] }, "filters">
    queryAction?: AnalyticQueryAction,
    indexAction?: number
    color?: string
};


type DateFilterOption = {
    label: string;
    transform: (date: Date) => string;
};

const Li = styled.li`
  &:hover {
    color: #5e72e4
  }
`

const getBaseDate = (date: string) => {
    const newDate = new Date(date);
    newDate.setHours(0)
    newDate.setMilliseconds(0)
    newDate.setMinutes(0)
    newDate.setSeconds(0)
    newDate.setUTCHours(0, 0, 0, 0)
    return newDate
}

const getEndBaseDate = (date: string) => {
    const newDate = new Date(date);
    newDate.setHours(23)
    newDate.setMilliseconds(59)
    newDate.setMinutes(59)
    newDate.setSeconds(59)
    newDate.setUTCHours(23, 59, 59, 59)
    return newDate
}

const disabledAction = (filter_operator: string) => {
    return filter_operator.toUpperCase() === SbxConditionTypeReport.EXIST
        || filter_operator.toUpperCase() === SbxConditionTypeReport.NO_EXIST
        || filter_operator === "{column} == {column}"
        || filter_operator === "{column} != {column}"
}

const GridTitle = styled.div`
  display: grid;
  grid-template-columns: auto auto 200px auto;
  align-items: center;
`

const specialFilters = ['keys', 'range']


const FilterDropdownComponent = ({
                                     index,
                                     remove,
                                     model,
                                     control,
                                     update,
                                     watch,
                                     indexAction,
                                     queryAction,
                                     color
                                 }: Props) => {

    const {t} = useTranslate("common")
    const {
        getActionColumns,
        state,
        query,
        getLoadingAndDisabledAction,
        mainQuery,
        getColumns
    } = useContext(ReportContext)
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [showReference, setShowReference] = useState(false)
    const [columns, setColumns] = useState<{ name: string, type?: SbxModelField }[]>([])
    const [allColumns, setAllColumns] = useState<string[]>([])
    const [isReferenceField, setIsReferenceField] = useState(false)
    const toggle = () => {
        setDropdownOpen((prevState) => !prevState);
    }

    const dateFilterOptions: DateFilterOption[] = useMemo(() => [
        {
            label: t("today"),
            transform: (date: Date) => getBaseDate(date.toISOString()).toISOString(),
        },
        {
            label: t("next_7_days"),
            transform: (date: Date) => {
                const newDate = getBaseDate(date.toISOString())
                newDate.setDate(date.getDate() + 7);
                return newDate.toISOString();
            },
        },
        {
            label: t("previous_7_days"),
            transform: (date: Date) => {
                const newDate = getBaseDate(date.toISOString())
                newDate.setDate(date.getDate() - 7);
                return newDate.toISOString();
            },
        },
        {
            label: t("next_30_days"),
            transform: (date: Date) => {
                const newDate = getBaseDate(date.toISOString())
                newDate.setDate(date.getDate() + 30);
                return newDate.toISOString();
            },
        },
        {
            label: t("previous_30_days"),
            transform: (date: Date) => {
                const newDate = getBaseDate(date.toISOString())
                newDate.setDate(date.getDate() - 30);
                return newDate.toISOString();
            },
        },
        {
            label: t("next_365_days"),
            transform: (date: Date) => {
                const newDate = getBaseDate(date.toISOString())
                newDate.setDate(date.getDate() + 365);
                return newDate.toISOString();
            },
        },
        {
            label: t("previous_365_days"),
            transform: (date: Date) => {
                const newDate = getBaseDate(date.toISOString())
                newDate.setDate(date.getDate() - 365);
                return newDate.toISOString();
            },
        },
    ], [])

    React.useEffect(() => {

        let nColumns: { name: string, type?: SbxModelField }[] = []

        if (typeof indexAction !== 'undefined' && queryAction) {
            nColumns = getActionColumns(queryAction, indexAction).map(column => ({name: column.value}))
        } else {
            if (model) {
                nColumns = [...model.properties]
            }
        }

        setColumns(nColumns.sort((a, b) => a.name.localeCompare(b.name)))

    }, [queryAction, indexAction, model, state]);

    React.useEffect(() => {
        const subscription = watch((obj, {name, type}) => {
            if ((obj["filters"] && obj["filters"].length > 0 && obj["filters"][index]
                && (!obj["filters"][index]!.field || (!obj["filters"][index]!.value && obj["filters"][index]!.value !== 0)))) {

                
                if (obj["filters"][index]!.filter_operator
                    && !disabledAction(obj["filters"][index]!.filter_operator ?? "")
                    && typeof obj["filters"][index]!.value !== 'boolean') {
                    setDropdownOpen(true)
                }

            }

            if (obj['filters'] && obj['filters'][index] && obj['filters'][index]!.field){
                const field = model?.properties.find(property => property.name === obj['filters']![index]!.field)
                setIsReferenceField(field?.type === SbxModelField.REFERENCE || obj['filters']![index]!.field!.includes('.'))
            }
        });
        return () => subscription.unsubscribe();
    }, [watch, model, index]);

    useAsyncEffect(async () => {

        if (!!mainQuery) {
            const response = await getColumns(mainQuery)

            if (response?.items && response.items.length > 0) {
                setAllColumns(response.items)
            }
        }

    }, [mainQuery])

    function formatValues(field: string, value: Date | string, structure = false) {
        try {
            const date1 = structure ? value as Date : value ? new Date(value) : null

            if (!date1) return null

            if (!isValidDate(date1)) return null

            if (structure) {

                if (field.includes("toDate")) {
                    return getEndBaseDate(date1.toISOString()).toISOString()
                }

                return getBaseDate(date1.toISOString()).toISOString()
            } else {
                if (value.toString().includes('00:00:00')) {
                    date1.setDate(date1.getDate() + 1)
                }
                return date1
            }
        } catch (e) {
            return new Date()
        }

    }

    const getField = ({type, value, onChange, disabled, field}: {
        type: SbxModelField,
        value: any,
        onChange: (e: any) => void,
        disabled?: boolean
        field: string
    }) => {

        let format = getFilterDateFormat(field)

        if (query.source.from === SourceFrom.GOOGLE_ANALYTICS){
            format = "yyyy-MM-dd"
        }

        switch (type) {
            case SbxModelField.BOOLEAN:
                return <RadioButtonComponent containerClassName="d-flex align-items-center"
                                             className="d-flex align-items-center me-2" onChange={event => {
                    onChange(event?.value)
                }}
                                             id={`filters.${index}.value`} name={''}
                                             value={{value, label: value ? "True" : 'False'}} options={[
                    {label: 'True', value: queryAction ? "1" : true}, {label: 'False', value: queryAction ? "0" : false}
                ]}/>

            case SbxModelField.DATE:
                return <ul className="list-group list-group-flush">
                    {dateFilterOptions.map((option, index) => (
                        <Li key={index} className="pointer shading list-group-item" onClick={() => {
                            let value = option.transform(new Date())
                            if (format) {
                                const date = convertDateCustom(new Date(value), format) as string

                                if (date) {
                                    value = query.source.from === SourceFrom.GOOGLE_ANALYTICS ? date : `'${date}'`
                                }
                            }

                            onChange(value)
                        }}>
                            <span>{option.label}</span>
                        </Li>
                    ))}

                    <div className="py-3">
                        <span>{t("custom_date")}</span>
                        <DateComponent id={"custom"} isOutsideRange={() => false} placeholder={t("custom_date")}
                                       value={format ? null : formatValues(field, value as string, false) as Date}
                                       onChange={e => {
                                           if (format) {
                                               const date = convertDateCustom(e as Date, format)
                                               onChange(query.source.from === SourceFrom.GOOGLE_ANALYTICS ? date : `'${date}'`)
                                           } else {
                                               onChange(formatValues(field, e as Date, true))
                                           }
                                       }}

                                       renderMonthElement={RenderMonthElement}/>
                    </div>
                </ul>

            default:
                return <input type={type ? renderType(type as SbxModelField) ?? "text" : "text"}
                              className="form-control"
                              value={value || ""}
                              disabled={disabled}
                              placeholder={`${t(type ? renderTextByType(type as SbxModelField) ?? "enter_text" : "enter_text")}`}
                              onChange={evt => {
                                  let value = evt.currentTarget.value
                                  onChange(value)
                              }}
                />
        }
    }

    const getFilterDateFormat = (field: string) => {
        let format = ""
        const actions = [...query.actions].flat().filter(action => action.type === "transform" && action.columns?.some((column: {
            [key: string]: string
        }) => column[field]))

        const last_action = actions[actions.length - 1]
        if (last_action?.columns) {
            const transform = last_action.columns.filter((column: { [key: string]: string }) => column[field]).at(-1)
            if (Object.keys(transform).length > 0 && transform[field]) {
                format = convertDatePyFormatToJsFormat(transform[field].split(" ").at(-1))
            }
        }

        return format
    }

    const renderToggleMenu = (filter: SourceFilter) => {
        // if (model && model.properties.length > 0) {
        if (!filter.field) {
            return <>
                <DropdownItem header>
                    {model?.name ?? ""}
                </DropdownItem>
                <DropdownItem divider/>
                {getLoadingAndDisabledAction({action: queryAction, index: indexAction}) ?
                    <DropdownItem disabled>
                        <FontAwesomeIcon icon={faSpinner} size={"1x"} spin/>
                    </DropdownItem> :
                    <>
                        <ColumnsActionsWrapperComponent>
                            {search => {
                                return <>
                                    {[...columns].filter(column => search.length > 0 ? column.name.toLowerCase().includes(search.toLowerCase()) : true).sort((a, b) => a.name.localeCompare(b.name)).map(property => {
                                            return <Controller
                                                key={property.name} control={control}
                                                render={({field, fieldState, formState}) => {
                                                    const onClick = () => {
                                                        field.onChange(property.name)
                                                    }

                                                    return <DropdownItem
                                                        className="d-flex gap-2 align-items-center justify-content-between"
                                                        onClick={() => {
                                                            setShowReference(false)
                                                            onClick()
                                                        }}
                                                    >
                                        <span>
                                            {getColumnName({
                                                type: property.type,
                                                name: property.name,
                                                columnsType: state.columnsType,
                                                index: indexAction
                                            })}
                                        </span>

                                                        {property.type === SbxModelField.REFERENCE &&
                                                            <span className="font-size-xl" onClick={(event) => {
                                                                event.stopPropagation()
                                                                setShowReference(true)
                                                                onClick()
                                                            }}>&#8594;</span>}
                                                    </DropdownItem>
                                                }} name={`filters.${index}.field`}/>
                                        }
                                    )}
                                    {search && <Controller
                                        key={"search"} control={control}
                                        render={({field}) => {
                                            return <SearchSuggestionOption search={search} onChange={field.onChange}
                                                                           toggle={() => {}}/>
                                        }} name={`filters.${index}.field`}
                                    />}

                                </>
                            }}
                        </ColumnsActionsWrapperComponent>
                    </>
                }
            </>
        }

        const field = model?.properties.find(property => property.name === filter.field)

        const type = typeof indexAction !== "undefined" && state.columnsType[indexAction] ? state.columnsType[indexAction][filter.field] : field?.type

        let deepProperties: Model[] = []

        const isReference = field?.type === SbxModelField.REFERENCE && !queryAction

        if (isReference && Object.values(state.deepFetch)) {
            deepProperties = Object.values(state.deepFetch)?.find(modelFetch => modelFetch.id === field?.reference_type)?.properties ?? []
        }

        if (isReference && !filter.value && showReference) {
            return <>
                <DropdownItem header className="d-flex align-items-center gap-2">

                    <ActionButton className="btn" color={color ?? "white"} onClick={() => {
                        update(index, {...filter, field: ""})
                    }}>
                        <FontAwesomeIcon icon={faArrowLeft} className="pointer white"/>
                    </ActionButton>
                    <span>{filter.field}</span>
                </DropdownItem>
                <DropdownItem divider/>
                <ColumnsActionsWrapperComponent>
                    {search => {
                        return <>
                            {
                                deepProperties.filter(column => column.name.toLowerCase().includes(search.toLowerCase())).map(property =>
                                    <Controller
                                        key={property.name} control={control}
                                        render={({field, fieldState, formState}) => {
                                            return <DropdownItem
                                                className="d-flex gap-2 align-items-center"
                                                onClick={() => {
                                                    field.onChange(`${field.value}.${property.name}`)
                                                }}>
                                                {getColumnName({
                                                    type: property.type,
                                                    name: property.name,
                                                    columnsType: state.columnsType,
                                                    index: indexAction
                                                })}

                                            </DropdownItem>
                                        }} name={`filters.${index}.field`}/>)
                            }

                            {search && <Controller
                                key={"search"} control={control}
                                render={({field}) => {
                                    return <SearchSuggestionOption search={search} onChange={field.onChange}
                                                                   toggle={toggle}/>
                                }} name={`filters.${index}.field`}
                            />}
                        </>
                    }}
                </ColumnsActionsWrapperComponent>
            </>
        }

        let customValue: StringOption | null = null


        if (specialFilters.includes(filter.filter_operator)) {
            customValue = {
                label: t(filter.filter_operator),
                value: filter.filter_operator
            }
        }


        return <div className="d-flex flex-column px-2 gap-2">
            <GridTitle className="border-bottom py-2 gap-3">

                <ActionButton className="btn" color={color ?? "white"} onClick={() => {
                    const field = (filter.field ?? "").includes(".") ? (filter.field ?? "").split(".")[0] : ""

                    update(index, {...filter, field, value: ""})
                }}>
                    <FontAwesomeIcon icon={faArrowLeft} className="pointer white"/>
                </ActionButton>

                <span
                    className="text-center text-primary ">{model?.name ? `${model.name}.` : ""}{filter.field}</span>


                <Controller render={
                    ({field: {onChange, value}}) => {
                        return <SelectComponent sortOptions id={""} name={""} menuPosition={"absolute"}
                                                disabled={specialFilters.includes(filter.filter_operator)}
                                                value={customValue ? customValue : conditionOptions(t, !!queryAction).find(option => option.format === value || option.value === value)}
                                                options={conditionOptions(t, !!queryAction).filter(option => (!option.type || !type) || (type && (option.type.includes(type))))}
                                                onChange={evt => {
                                                    if (evt.format && !queryAction) {
                                                        onChange(evt.format)
                                                    } else {
                                                        onChange(evt.value)
                                                    }
                                                }}/>
                    }
                } name={`filters.${index}.filter_operator`} control={control}/>


                <ActionButton className="btn" color={'transparent'} onClick={() => {
                    toggle()
                }}>
                    <FontAwesomeIcon icon={faTimes} className="pointer" color={'#141414'}/>
                </ActionButton>
            </GridTitle>


            {customValue ? <>
                    <ColumnsActionsWrapperComponent>
                        {search => {
                            return <div style={{overflow: 'auto', maxHeight: '30vh'}}>

                                {allColumns.length === 0 ?
                                    <div className="text-center text-gray"><span>{t("empty")}</span></div> : null}
                                {[...allColumns].filter(column => column.toLowerCase().includes(search.toLowerCase())).sort((a, b) => a.localeCompare(b)).map(property => {
                                        return <Controller
                                            key={property} control={control}
                                            render={({field}) => {
                                                const onClick = () => {
                                                    field.onChange(`pdf.${property}`)
                                                }

                                                return <DropdownItem
                                                    className="d-flex gap-2 align-items-center justify-content-between"
                                                    onClick={() => {
                                                        onClick()
                                                        toggle()
                                                    }}>
                                                    <span>pdf.{property}</span>
                                                </DropdownItem>
                                            }} name={`filters.${index}.value`}/>
                                    }
                                )}

                                {search && <Controller
                                    key={"search"} control={control}
                                    render={({field}) => {
                                        return <SearchSuggestionOption search={search} onChange={field.onChange}
                                                                       toggle={toggle}/>
                                    }} name={`filters.${index}.value`}
                                />}

                            </div>
                        }}
                    </ColumnsActionsWrapperComponent>
                </> :


                <Controller render={
                    ({field: {onChange, value}}) => {

                        const filter_operator = watch(`filters.${index}.filter_operator`) ?? ""

                        // For sbx filter
                        const modelSplitted = filter?.field?.split(".") ?? ""

                        if (filter?.field) {

                            // Find type by sbx or Analytic columns

                            const deepFetch = Object.values(state.deepFetch).find(model => model.name === modelSplitted[0] || model.alias?.includes(modelSplitted[0]))

                            const type = filter.field.includes(".") ?
                                deepFetch?.properties?.find(column => column.name === modelSplitted?.at(-1))?.type ?? SbxModelField.STRING
                                : getColumnType({
                                index: indexAction,
                                columnsType: state.columnsType,
                                type: field?.type ?? "",
                                name: filter.field
                            }) as SbxModelField ?? SbxModelField.STRING


                            return getField({
                                value,
                                onChange,
                                field: filter.field,
                                disabled: disabledAction(filter_operator),
                                type: type as SbxModelField
                            })
                        }

                        return <span/>
                    }
                } name={`filters.${index}.value`} control={control}/>}


            <div className="border-top pt-2 justify-content-end d-flex">
                <ButtonComponent label={t("save")} onClick={() => {
                    toggle()
                }}/>
            </div>
        </div>

        // }

        // return <span/>
    }

    const checkBasicConfigMenu = () => {
        const filter = watch(`filters.${index}`)
        if (model && filter.field) {
            const field = model.properties.find(property => property.name === filter.field)
            return field?.type === SbxModelField.REFERENCE && state.deepFetch[filter.field] && !queryAction
        }
        return !filter.field
    }

    const getFilterName = () => {
        const filter = watch(`filters.${index}`)

        let isDate = state.columnsType ? state.columnsType[indexAction ?? 0] ? state.columnsType[indexAction ?? 0][filter.field ?? ""] === SbxModelField.DATE : columns.find(column => column.name === filter.field)?.type === SbxModelField.DATE : false

        const format = getFilterDateFormat(filter.field ?? "")

        return <span className="d-flex align-items-center gap-2">
            {filter?.field ? `${filter.field} 
            ${conditionOptionsDisplay(t, !!queryAction).find(option => option.format === (filter.filter_operator ?? "").toUpperCase() || (option.value.toUpperCase() === (filter.filter_operator ?? "").toUpperCase()))?.label ?? ""} 
            ${isDate ? filter.value ? format ? filter.value : convertDateToDDMMMYYYY(new Date(filter.value)) : "" : filter.value ?? ""}`
                : `${t("report:new_filter")} ` + (index + 1)}

            {filter && <FontAwesomeIcon icon={faTimesCircle} className="pointer" onClick={() => {
                remove(index)
            }}/>}
        </span>
    }

    const getDivOverflow = () => {
        // watch(`filters.${index}`).field ? isReferenceField && watch(`filters.${index}`).field.includes(".") ? '' : "auto" : 'auto'

        if (watch(`filters.${index}`).field){

            const filter = watch(`filters.${index}`)
            const modelSplitted = filter?.field?.split(".") ?? ""
            const deepFetch = Object.values(state.deepFetch).find(model => model.name === modelSplitted[0] || model.alias?.includes(modelSplitted[0]))
            const type = filter.field.includes(".") ?
                deepFetch?.properties?.find(column => column.name === modelSplitted?.at(-1))?.type ?? SbxModelField.STRING
                : getColumnType({
                index: indexAction,
                columnsType: state.columnsType,
                type: "",
                name: filter.field
            }) as SbxModelField ?? SbxModelField.STRING

            if (type === SbxModelField.DATE){
                return "auto"
            }

            const columnType = model?.properties.find(column => column.name === filter.field)
            if (columnType && columnType.type === SbxModelField.DATE){
                return "auto"
            }

            if (isReferenceField){
                if(watch(`filters.${index}`).field.includes(".")){
                    return ""
                }else{
                    return "auto"
                }

            }

            return ""
        }else{
            return "auto"
        }

    }


    return (
        <DropdownActionComponent isOpen={dropdownOpen} setDropdownOpen={setDropdownOpen}
                                 dropdownStyle={{
                                     background: color ?? "#5e72e4",
                                     borderColor: color ?? "#5e72e4",
                                     marginTop: '0.2rem'
                                 }}
                                 menuStyle={{
                                     overflow:getDivOverflow(),
                                     maxHeight: "30vh",
                                 }}
                                 basicConfigMenu={checkBasicConfigMenu()}
                                 toggle={() => {
                                     toggle()
                                 }} header={getFilterName()}>
            {renderToggleMenu(watch(`filters.${index}`))}
        </DropdownActionComponent>
    );
};

export default FilterDropdownComponent