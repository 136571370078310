import {get, post} from '../../network';
import {analyticQueryValidation} from '../../utils/analyticsUtils';
import {BaseAnalyticQuery, Report, Source} from '../../types/Analytic';
import {EventMeta} from '../../classes/TrackEvent';

export const getCustomerGrowth = () => {
    return get("/api/customer-news-and-last-purchase").then(res => res);
}

export const getProductGroupsByYear = () => {
    return get(`/api/productgroup`).then(res => res);
}

export const getCustomerCohorts = () => {
    return get("/api/customer-cohorts").then(res => res)
}

export const getMarketingData = () => {
    return get("/api/marketing").then(res => res)
}

export const executeAnalyticJson = (json: BaseAnalyticQuery ) => {
    return post('/api/v2/sbx/analytics/query/execute_commands', analyticQueryValidation({...json}));
}

export const executeReportQuery = (json: BaseAnalyticQuery, report: Report ) => {
    return post(`/api/v2/sbx/analytics/query/$[domain]/${report._KEY}/execute`, analyticQueryValidation({...json}));
}

export const initializeLlmReportQuery = (json: BaseAnalyticQuery, report: Report ) => {
    return post(`/api/v2/sbx/analytics/query/$[domain]/${report._KEY}/initialize_llm`, analyticQueryValidation({...json}));
}

export const askLlmReportQuery = (params:{key: string, question: string, report_key: string, context?: string} ) => {
    return post(`/api/v2/sbx/analytics/query/$[domain]/${params.report_key}/ask_llm`, {
        ...params
    });
}

export const executeReloadReportQuery = (json: BaseAnalyticQuery, report: Report ) => {
    return post(`/api/v2/sbx/analytics/query/$[domain]/${report._KEY}/execute_and_reload_cache`, analyticQueryValidation({...json}));
}

export const getLastReportUpdate = (report: Report) => {
    return get(`/api/v2/sbx/analytics/query/$[domain]/${report._KEY}/last_cache`).then(res => res)
}

export const getTruncateFieldList = (source: Source, report_key: string) => {
    return post(`/api/v2/sbx/analytics/query/$[domain]/${report_key}/list`, source).then(res => res)
}


export const executeUpdateCache = (json: BaseAnalyticQuery ) => {
    return post('/api/v2/sbx/analytics/query/execute_and_reload_cache', json);
}

export const getAnalyticJsonColumns = (json: BaseAnalyticQuery) => {
    return post('/api/v2/sbx/analytics/query/get_columns', analyticQueryValidation({...json}));
}

export const getSchema = (schemas: Source[]) => {
    return post('/api/v2/sbx/analytics/get_schema', schemas).then(res => res)
}

export const createEventService = (params: {
    meta: EventMeta,
    props: any,
    extra?: any
}) => {
    return post('/api/event', params).then(res => res);
};
