import React from 'react';
import {FormTask, Task} from '../types/Task';
import {
  Control,
  FormState,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormUnregister,
  UseFormWatch
} from 'react-hook-form';
import {TaskProcessState} from '../components/TaskComponent/TaskComponent';
import {State} from "../types/State";

type TaskContextProps = {
  task?: Task | null
  dispatchForm?: ({name, value}: { name: string, value: any, type?: string }) => void;
  loadingForm?: boolean;
  form?: FormTask | null
  register?: UseFormRegister<any>
  unregister?: UseFormUnregister<any>
  taskState?: TaskProcessState
  watch?: UseFormWatch<any>
  control?: Control<any, object>
  formState?: FormState<any>
  loadingTask?: State
  loadingItems?: State
  setValue?: UseFormSetValue<any>,
  setError?: any;
  setTask?: any;
  clearErrors?: any
  setLoadingItems?: any;
  reset?: any;
  formKey?: string,
  setFormKey?: ()=>void
  getFormValue?: UseFormGetValues<any>
}

export const TaskComponentContext = React.createContext<TaskContextProps>({});

const TaskContextComponent = ({children, taskState}: { children: JSX.Element, taskState: TaskContextProps }) => {

  return <TaskComponentContext.Provider value={taskState}>
    {children}
  </TaskComponentContext.Provider>;
};

export default TaskContextComponent;

