import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import useTranslate from "../../../hooks/useTranslate";
import ButtonComponent from "../ButtonComponent";
import {faMicrophone, faPaperPlane, faStop} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {uploadAudioFile} from "../../../services/backend/ContentService";
import {Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";


type Props = {
    submit: (message: string) => void
    loading: boolean
    setLoading: (loading: boolean) => void
    disabled?: boolean
};
let audioType = 'audio/webm';
const languagues = ["english", "spanish", "dutch"]
const ChatBotInputComponent = ({submit, loading, disabled, setLoading}: Props) => {
    const [inputValue, setInputValue] = useState('');
    const {t} = useTranslate("common")
    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    // const [audioBlob, setAudioBlob] = useState<Blob | null>(null);
    const [recording, setRecording] = useState(false);
    const [lang, setLang] = useState("english")
    // const audioRef = useRef<HTMLAudioElement>(null);
    const streamRef = useRef<MediaStream | null>(null);
    const recorderRef = useRef<MediaRecorder | null>(null);

    const resizeTextArea = () => {

        if (textAreaRef.current) {
            if (inputValue.length === 0) {
                textAreaRef.current.style.height = "23.5px";
            } else {
                textAreaRef.current.style.height = "auto";
                textAreaRef.current.style.height = Math.min(textAreaRef.current.scrollHeight, 43) + "px";
            }
        }
    };

    useEffect(resizeTextArea, [inputValue]);

    const startRecording = async () => {
        if (navigator.mediaDevices) {
            try {
                // setAudioBlob(null)
                const stream = await navigator.mediaDevices.getUserMedia({audio: true});
                streamRef.current = stream;
                const mediaRecorder = new MediaRecorder(stream);
                const audioChunks: Blob[] = [];

                mediaRecorder.addEventListener('dataavailable', (event) => {
                    audioChunks.push(event.data);
                });

                mediaRecorder.addEventListener('stop', async () => {
                    const audioBlob = new Blob(audioChunks, {type: audioType});
                    if (audioBlob) {
                        // setAudioBlob(audioBlob);
                        setLoading(true)
                        const response = await uploadAudioFile({
                            blob: audioBlob,
                            lang
                        })

                        if (response?.success && response.transcription) {
                            submit(response.transcription ?? "")
                        } else {
                            setLoading(false)
                        }
                    }

                });

                mediaRecorder.start();
                setRecording(true);
                recorderRef.current = mediaRecorder;
            } catch (error) {
                console.error('Error accessing microphone:', error);
            }
        }
    };

    const stopRecording = async () => {
        if (recorderRef.current) {
            recorderRef.current.stop();
        }
        if (streamRef.current) {
            streamRef.current.getTracks().forEach((track) => track.stop());
        }


        setRecording(false);
    };

    // const handlePlay = () => {
    //     if (audioRef.current) {
    //         if (audioRef.current.paused) {
    //             audioRef.current.play();
    //         } else {
    //             audioRef.current.pause();
    //         }
    //     }
    // };


    return (
        <div className="border-top">
            {loading && <div className="d-flex align-items-center justify-content-end px-4">
                <span className="text-gray px-4">
                    {t("report:getting_your_results")}
                </span>
                <div className="dot-carousel">
                </div>
            </div>}


            {recording && <span style={{color: 'red'}} className="p-3">
                        {t("report:recording")}...
                    <FontAwesomeIcon icon={faMicrophone} color={'red'} size={'1x'}/>
                    </span>}


            <div className="align-items-center border d-flex m-2 px-4 py-2  rounded-pill shadow-lg">


      <textarea
          ref={textAreaRef}
          rows={1}
          style={{
              width: '100%',
              border: 'none',
              resize: 'none',
              outline: 'none'
          }}
          value={inputValue}
          onKeyDown={(event) => {
              if (event.key === 'Enter') {
                  submit(inputValue)
                  setInputValue('')
              }
          }}
          className="pl-4"
          disabled={loading || disabled}
          onChange={event => setInputValue(event.target.value)}
          placeholder={t("enter_text")}
      />

                <div className="d-flex align-items-center gap-2">

                    <UncontrolledDropdown group>
                        <Button color="default"  disabled={loading || disabled} style={{padding: 0}} outline onClick={() => {
                            if (recording) {
                                stopRecording()
                            } else {
                                startRecording()
                            }
                        }}>
                            <FontAwesomeIcon icon={recording ? faStop : faMicrophone} color={recording ? "red" : ""}/>
                        </Button>
                        <DropdownToggle disabled={loading || disabled}
                            caret
                            style={{padding: 5}}
                            color="default"
                        />
                        <DropdownMenu >

                            <DropdownItem disabled header>
                                {t("report:select_a_language_to_record_your_voice")}
                            </DropdownItem>

                            {languagues.map((lang, index) => {
                                return <DropdownItem key={index} onClick={() => {
                                    setLang(lang)
                                    startRecording()
                                }}>
                                    {t(`${lang}`)}
                                </DropdownItem>
                            })}
                        </DropdownMenu>
                    </UncontrolledDropdown>

                    <ButtonComponent label={""} disabled={loading || disabled} onClick={() => {
                        submit(inputValue)
                        setInputValue('')
                    }} icon={faPaperPlane} sizeIcon={'1x'}
                                     className="rounded-circle d-flex align-items-center justify-content-center"
                                     style={{width: "25px", height: "25px"}}/>
                </div>

            </div>
        </div>
    );
};

export default ChatBotInputComponent