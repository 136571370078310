import * as React from 'react';
import {useState} from 'react';
import ModalComponent from "../Shared/Modal/ModalComponent/ModalComponent";
import {useDispatch, useSelector} from "react-redux";
import {authReducer} from "../../store/Selectors";
import {Response} from "../../types/Response";
import {UserData} from "../../types/User";
import {login} from "../../services/backend/AuthService";
import {error} from "../../utils";
import useTranslate from "../../hooks/useTranslate";
import ButtonComponent from "../Shared/ButtonComponent";
import {faLock} from "@fortawesome/free-solid-svg-icons";
import {actionsModal, ModalTypes} from "../../store/Modal/Slice";
import {State} from "../../types/State";


interface IProps {
    data: IPropsAuthenticationModal;
    open: boolean;
}


export interface IPropsAuthenticationModal {
    type: ModalTypes.AUTHENTICATION_COMPONENT_MODAL;
    onConfirm: (data?: any) => any;
    onClose?: (data?: any) => any;
    state?: string
    closeModal?: boolean;
    // onResultSuccess: (success: boolean) => void
}

const AuthenticationModuleModal = ({open, data}: IProps) => {
    const passwordRef = React.useRef<HTMLInputElement>(null);
    const {user} = useSelector(authReducer)
    const {t} = useTranslate('custom-message');
    const dispatch = useDispatch()
    const toggle = () => dispatch(actionsModal.closeModal({type: ModalTypes.AUTHENTICATION_COMPONENT_MODAL}));
    const [loading, setLoading] = useState(State.IDLE)


    const loginCheck = async () => {
        const password = passwordRef.current?.value
        if (password && user?.email) {
            setLoading(State.PENDING)
            const res: Response<UserData> = await login({username: user.email, password})
            if (res?.success) {
                toggle()
                setLoading(State.RESOLVED)
                data.onConfirm(res?.success)
            } else {
                error(t("wrong_password"))
                setLoading(State.REJECTED)
            }
        } else {
            error(t("wrong_password"))
        }
    }
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            loginCheck()
        }
    };
    return (
        <ModalComponent isOpen={open} title={`${t("common:validate")} ${t("common:password")}`} toggle={toggle}
                        noFooter>
            <div className="d-flex flex-column gap-1 p-3 ">
                <h3>{t("common:input_password")}</h3>
                <input type="password" onKeyDown={handleKeyDown} disabled={loading === State.PENDING}
                       placeholder={`${t("common:password")}...`}
                       className="form-control"
                       ref={passwordRef}/>
                <div className="d-flex justify-content-end">
                    <ButtonComponent onClick={loginCheck} loading={loading === State.PENDING}
                                     disabled={loading === State.PENDING} label={t("common:validate")} icon={faLock}
                                     color={"primary"}/>
                </div>
            </div>
        </ModalComponent>
    );
};

export default AuthenticationModuleModal