import {State} from "../../../../types/State";
import {Andor, GroupConditionsValues, Model, ModelsResponse, SbxConditionType} from "../../../../types/Sbx";
import {AnyData} from "../../../../types/AnyData";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {uuidV4} from "../../../../utils";

export enum StateLocal {
  SEARCHING = "SEARCHING",
  FETCH_REFERENCES_PENDING = "FETCH_REFERENCES_PENDING",
  FETCH_REFERENCES_RESOLVED = "FETCH_REFERENCES_RESOLVED",
  GETTING_RESULT = "GETTING_RESULT",
  RESOLVED_RESULT = "RESOLVED_RESULT"
}


export interface IState {
  rows: ModelsResponse[];
  state: State | StateLocal;
  build: State;
  model: null | { label: string, value: ModelsResponse };
  conditions: GroupConditionsValues[];
  results: any[];
  fetched_map: AnyData,
  modelResult: Model[],
  modelFetched: { [key: string]: ModelsResponse };
  totalItems: number,
  queryTab?: string;
}

export const initialState: IState = {
  rows: [],
  state: State.IDLE,
  build: State.BUILDING,
  model: null,
  conditions: [],
  results: [],
  fetched_map: {},
  modelResult: [],
  modelFetched: {},
  totalItems: 0
}


const slice = createSlice({
  name: "queryComponent",
  initialState,
  reducers: {
    setModels: (state, action: PayloadAction<any[]>) => {
      state.rows = action.payload
    },
    changeState: (state, action: PayloadAction<State | StateLocal>) => {
      state.state = action.payload;
    },
    changeBuild: (state, action: PayloadAction<State>) => {
      state.build = action.payload;
    },
    changeQuery: (state, action: PayloadAction<string>) => {
      state.queryTab = action.payload;
    },
    changeModel: (state, action: PayloadAction<{ label: string, value: ModelsResponse }>) => {
      state.model = action.payload;
      state.model = action.payload;
      state.conditions = [];
      state.results = [];
      state.fetched_map = {};
      state.totalItems = 0;
    },
    changeAndorGroup(state, action: PayloadAction<{ index: number, andor: Andor }>) {
      let conditions = state.conditions;
      let currentGroup = conditions[action.payload.index];
      currentGroup.ANDOR = action.payload.andor;
      conditions[action.payload.index] = currentGroup;
      state.conditions = conditions;
    },
    changeFetchReferences: (state, action: PayloadAction<any>) => {
      state.modelFetched = action.payload;
      state.state = StateLocal.FETCH_REFERENCES_RESOLVED;
    },
    changeResult: (state, action: PayloadAction<{ results: any[], fetched_map: AnyData, model: Model[], totalItems: number, noChangeState?: boolean }>) => {
      state.results = action.payload.results;
      state.fetched_map = action.payload.fetched_map;
      state.modelResult = action.payload.model;
      state.totalItems = action.payload.totalItems;
      if (!action.payload.noChangeState) {
        state.state = State.RESOLVED;
      }
    },
    addCondition: (state, action: PayloadAction<number>) => {
      let conditions = state.conditions;
      let currentGroup = conditions[action.payload];
      currentGroup.GROUP = new Array(...currentGroup.GROUP).concat({
        ANDOR: Andor.AND,
        FIELD: null,
        SUB_FIELD: null,
        OP: null,
        VAL: null,
        key: uuidV4()
      });
      conditions[action.payload] = currentGroup;
      state.conditions = conditions;
    },
    setConditions: (state, action: PayloadAction<GroupConditionsValues[]>) => {
      state.conditions = action.payload;
      state.state = State.RESOLVED
    },
    clearConditions: (state, action: PayloadAction<number>) => {
      state.conditions = state.conditions.filter((_, index) => index !== action.payload);
    },
    removeCondition: (state, action: PayloadAction<{ groupIndex: number, conditionIndex: number }>) => {
      let conditions = state.conditions;
      let currentGroup = conditions[action.payload.groupIndex];
      currentGroup.GROUP = currentGroup.GROUP.filter((_, index) => index !== action.payload.conditionIndex);
      state.conditions = conditions;
    },
    changeCondition: (state, action: PayloadAction<{ groupIndex: number, conditionIndex: number, values: any }>) => {
      if (action.payload.values.OP?.value === SbxConditionType.EXIST || action.payload.values.OP?.value === SbxConditionType.NO_EXIST) {
        action.payload.values.VAL = null;
      }
      let conditions = state.conditions;
      let currentGroup = conditions[action.payload.groupIndex];
      let con = new Array(...currentGroup.GROUP);
      con[action.payload.conditionIndex] = Object.assign(con[action.payload.conditionIndex], action.payload.values)
    },
    addGroup: (state) => {
      let conditions = state.conditions;
      conditions.push({
        GROUP: [{
          ANDOR: Andor.AND,
          FIELD: null,
          SUB_FIELD: null,
          OP: null,
          VAL: null,
          key: uuidV4()
        }], ANDOR: Andor.AND
      })
      state.conditions = conditions;
    },
    removeAllGroups(state) {
      state.conditions = [];
    }
  }
})

const reducer = slice.reducer;
export const actions = slice.actions;

export default reducer;
