import * as React from 'react';
import {useCallback, useState} from 'react';
import {DropdownItem,} from 'reactstrap';
import useTranslate from "../../../hooks/useTranslate";
import ButtonComponent from "../ButtonComponent";
import {faSearch, faSortAlphaDown, faSortAlphaDownAlt, faSortDown, faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {sortIntFun, sortStringFun} from "../../../utils";
import {Popover} from "antd";
import useScrollIsDown from "../../../hooks/useScrollIsDown";


type Props = {
    columnItems: any[]
    filterRowsByColumn: ({valueList, search}: { valueList?: (string | number)[], search?: string }) => void
    sortColumn: ({asc}: { asc?: boolean }) => void
};

const TableColumnFilterMenuComponent = ({sortColumn, columnItems, filterRowsByColumn}: Props) => {

    const [open, setOpen] = useState(false)
    const [checkedItems, setCheckedItems] = useState<{ [item: string]: boolean }>({});
    const [search, setSearch] = useState("")
    const [maxItems, setMaxItems] = useState(10)
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const item = event.target.name;
        const isChecked = event.target.checked;
        let nCheckedItems: { [item: string]: boolean } = {}
        if (item === t("select_all")) {
            nCheckedItems = Object.keys(checkedItems).reduce((acc: { [item: string]: boolean }, key) => {
                acc[validateFilterName(key)] = isChecked
                return acc
            }, {})
        } else {
            nCheckedItems = {...checkedItems, [validateFilterName(item)]: isChecked}
        }

        setCheckedItems(nCheckedItems)
        // if (isCheckedApply) {
        const checkedList = Object.keys(nCheckedItems).filter((key) => nCheckedItems[key] && key !== t("select_all"))
        filterRowsByColumn({valueList: checkedList.map(item => item === "empty" ? "" : item)})
        // }

    };
    const [items, setItems] = useState<any[]>([])
    const [copyItems, setCopyItems] = useState<any[]>([])


    const validateFilterName = (name: string) => {
        return (name === "" || name === null) ? "empty" : name
    }

    const setDefaultValues = useCallback(() => {
        const checkedMap: { [item: string]: boolean } = {
            [t("select_all")]: true
        }
        for (const item of columnItems) {
            checkedMap[validateFilterName(item)] = true
        }

        setCheckedItems(checkedMap)
        setSearch("")
    }, [columnItems]);

    React.useEffect(() => {
        if (columnItems.length > 0) {

            setCopyItems(columnItems)
            setItems(columnItems.slice(0, maxItems))

            if (Object.keys(checkedItems).length === 0) {
                setDefaultValues()
            } else {

                if (columnItems.some(item => !checkedItems.hasOwnProperty(validateFilterName(item)))) {

                    let nCheckedItems = {...checkedItems}
                    const newItems = columnItems.filter(item => !checkedItems.hasOwnProperty(validateFilterName(item)))
                    for (const item of newItems) {
                        nCheckedItems[validateFilterName(item)] = true
                    }
                    setCheckedItems(nCheckedItems)
                }
            }
        }
    }, [columnItems, checkedItems]);

    useScrollIsDown("list_column_items", (down, top, ref) => {
        if (down) {
            setMaxItems(maxItems + 10)
            setItems(copyItems.slice(0, maxItems + 10))
        } else if (top) {
            setMaxItems(10)
            setItems(copyItems.slice(0, 10))
            
        }
    });

    const {t} = useTranslate('common');

    const content = () => {
        return <div style={{width: "25em", minHeight: '20vh', maxHeight: "80vh"}} className="d-flex flex-column p-2 ">


            <div className="d-flex justify-content-between">

                <div className="d-flex flex-column">
                    <span className="text-capitalize">{t("report:sort")}</span>
                    <div className="d-flex align-items-center gap-2">
                        <ButtonComponent outline label={t("ascending")} onClick={() => {
                            sortColumn({asc: true})
                        }} icon={faSortAlphaDown}/>
                        <ButtonComponent outline label={t("descending")} onClick={() => {
                            sortColumn({asc: false})
                        }} icon={faSortAlphaDownAlt}/>
                    </div>
                </div>
                <span className="text-end" onClick={() => {
                    setOpen(false)
                }}>
                <FontAwesomeIcon icon={faTimes} color={""} className="pointer"/>
            </span>
            </div>

            <DropdownItem divider/>

            <div className="d-flex flex-column mb-2">

                <div className="d-flex align-items-center border rounded mb-2">
                    <input type="text" className="form-control border-0" value={search}
                           onChange={event => {
                               setSearch(event.target.value)
                               filterRowsByColumn({search: event.target.value})
                           }} placeholder={`${t("inputSearch")}...`}/>
                    <div className="p-2">
                        <FontAwesomeIcon icon={faSearch}/>
                    </div>
                </div>


                <div style={{maxHeight: "250px"}} className="overflow-auto " id={"list_column_items"}>
                    <ul className="list-group">

                        <li key={t("select_all")} className="list-group-item d-flex align-items-center gap-2">
                            <input
                                type="checkbox"
                                name={t("select_all")}
                                checked={checkedItems[t("select_all")] ?? false}
                                onChange={handleChange}
                            />
                            <span>{t("select_all")}</span>
                        </li>

                        {copyItems.some(item => item === "" || item === "empty" || item === null) &&
                            <li key={t("empty")} className="list-group-item d-flex align-items-center gap-2">
                                <input
                                    type="checkbox"
                                    name={"empty"}
                                    checked={checkedItems["empty"] ?? false}
                                    onChange={handleChange}
                                />
                                <span>{t("empty")}</span>
                            </li>}

                        {items.length > 0 && items.sort((a, b) => typeof a === "string" ? sortStringFun({
                            a,
                            b,
                            type: "ASC"
                        }) : sortIntFun({
                            a,
                            b,
                            type: "ASC"
                        })).filter(name => (name !== null && name !== "empty" && name !== "") && (search.length > 0 ? ((name ?? "").toString().toLowerCase() ?? "").includes((search.toLowerCase() ?? "") ?? "") : true)).slice(0, maxItems).map((name, index) => {
                            return <li key={`${name}_${index}`}
                                       className="list-group-item d-flex align-items-center gap-2">
                                <input
                                    type="checkbox"
                                    name={validateFilterName(name)}
                                    checked={checkedItems[validateFilterName(name)] ?? false}
                                    onChange={handleChange}
                                />
                                <span>
                                    {name}
                                </span>
                            </li>
                        })}
                    </ul>
                </div>
            </div>

            <div className="d-flex align-items-center justify-content-end gap-2 border-top pt-2">


                <ButtonComponent outline label={t("clear_filter")} size={"sm"} color={"danger"} onClick={() => {
                    setDefaultValues()
                    filterRowsByColumn({})
                }}/>
            </div>


        </div>
    }


    return <Popover placement="bottom" overlayStyle={{zIndex: 3333}} content={content()} open={open}
                    onOpenChange={setOpen} trigger="click">
        <FontAwesomeIcon icon={faSortDown} className="pointer mx-2"/>
    </Popover>

};

export default TableColumnFilterMenuComponent