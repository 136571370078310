import React, {useState} from "react";
import {DateRangePicker, FocusedInputShape} from "react-dates";
import moment, {Moment} from "moment";
import {useSelector} from "react-redux";
import {authReducer} from "../../../store/Selectors";

interface IProps {
  value: { startDate: Date | null, endDate: Date | null };
  id: string;
  required?: boolean;
  readonly?: boolean;
  disabled?: boolean;
  block?: boolean;
  minimumNights?: number
  placeholderStart?: string;
  className?: string
  placeholderEnd?: string;
  onFocusedChange?: (e: Boolean, focusName: FocusedInputShape | null) => void;
  isOutsideRange?: (day?: Moment) => boolean;
  displayFormat?: string | (() => string);
  onChange?: (date: { startDate: Date | null, endDate: Date | null }) => void;
  orientation?: "vertical" | "horizontal"
  endDateOffset?: (day: moment.Moment) => moment.Moment
  startDateOffset?: (day: moment.Moment) => moment.Moment
  renderMonthElement?: ((props: {
    month: moment.Moment;
    onMonthSelect: (currentMonth: moment.Moment, newMonthVal: string) => void;
    onYearSelect: (currentMonth: moment.Moment, newMonthVal: string) => void;
    isVisible: boolean;
  }) => React.ReactNode)
}

const DateRangeComponent: React.FC<IProps> = (props) => {

  const {
    value,
    onChange,
    id,
    disabled,
    readonly,
    required,
    placeholderStart,
    placeholderEnd,
    block = true,
    displayFormat,
    endDateOffset,
    startDateOffset,
    isOutsideRange,
    onFocusedChange,
    orientation = "horizontal",
    renderMonthElement, minimumNights
  } = props;
  const [focused, setFocused] = useState<any>(null);

  const {user: {config}} = useSelector(authReducer)

  return (
    <div className={`date-picker-component`}>
      <DateRangePicker

        disabled={disabled}
        readOnly={readonly}
        orientation={orientation}
        required={required}
        endDatePlaceholderText={placeholderEnd}
        startDatePlaceholderText={placeholderStart}
        block={block}

        displayFormat={displayFormat ?? config?.sbx_crm?.utils?.displayFormat ?? undefined}
        startDateOffset={startDateOffset as any}
        endDateOffset={endDateOffset as any}
        renderMonthElement={renderMonthElement as any}
        isOutsideRange={isOutsideRange as any}
        minimumNights={minimumNights}
        startDateId={`start_date_id_` + (id || new Date().getDay())}
        endDateId={`end_date_id_` + (id || new Date().getDay())}
        startDate={value.startDate ? moment(value.startDate) : null}
        endDate={value.endDate ? moment(value.endDate) : null}
        onDatesChange={({startDate, endDate}) => onChange && onChange({
          startDate: startDate ? startDate.toDate() : null,
          endDate: endDate ? endDate.toDate() : null
        })}
        focusedInput={focused}
        onFocusChange={e => {
          setFocused(e)
          if (onFocusedChange) {
            onFocusedChange(Boolean(e), e)
          }
        }}
      />
    </div>
  );
};

export default DateRangeComponent;
