import React, {useEffect} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil, faPlusCircle, faTrash} from "@fortawesome/free-solid-svg-icons";
import TreeComponent, {TreeViewDataItem} from "./TreeComponent";
import {PARENT_SEPARATOR} from "./TreeComponent/TreeItemRecursive";


interface IProps {
  tree: TreeViewDataItem[];
  onItemClick: (data: TreeViewDataItem) => void;
  onNewItemClick?: (data: TreeViewDataItem) => void;
  onEditItem?: (data: TreeViewDataItem) => void;
  onRemoveItem?: (data: TreeViewDataItem) => void;
  filter: string;
  onSelections: (srt: string) => void;
}

export const TreeViewComponent = ({
                                    tree,
                                    onItemClick: clickItem,
                                    filter,
                                    onSelections,
                                    onNewItemClick,
                                    onEditItem,
                                    onRemoveItem
                                  }: IProps) => {

  const [expand, setExpand] = React.useState<{ ids: string[], idField: string }>({ids: [], idField: 'text'});
  const [select, setSelect] = React.useState(['']);

  const onItemClick = (event: TreeViewDataItem) => {
    clickItem(event);
    setSelect([event.id]);
  }

  useEffect(() => {
    if (select.length === 1) {
      onSelections(select[0]);
    }
  }, [select])

  //search in the data tree with filter value
  useEffect(() => {
    const ids: string[] = [];
    const selections: string[] = [];

    function _recursive(tree: TreeViewDataItem[]) {
      tree.forEach(e => {
        if (e.text.toLowerCase().includes(filter.toLowerCase())) {
          selections.push(e.id);
          let acc = "";
          (e.id as string).split(PARENT_SEPARATOR).forEach(e2 => {
            acc = acc ? acc.concat(PARENT_SEPARATOR, e2) : e2;
            if (!ids.some(e => acc === e)) {
              ids.push(acc);
            }
          });
        }
        if (e.items) {
          _recursive(e.items);
        }
      })
    }

    if (filter) _recursive(tree);

    setExpand({
      ...expand, ids
    });

    setSelect(selections);

  }, [filter])


  function ItemComponent(props: { item: TreeViewDataItem }) {
    return <span>{props.item.text}
      {!!props.item.items &&
        <FontAwesomeIcon
          size="sm" onClick={() => onNewItemClick && onNewItemClick(props.item)}
          title={"Add new field"}
          className="pointer text-primary ms-1" icon={faPlusCircle}/>}
      <FontAwesomeIcon
        size="sm" onClick={() => onEditItem && onEditItem(props.item)}
        title={"Edit new field"}
        className="pointer text-primary  mx-2" icon={faPencil}/>
      <FontAwesomeIcon
        size="sm" onClick={() => onRemoveItem && onRemoveItem(props.item)}
        title={"Remove field"}
        className="pointer text-danger" icon={faTrash}/>
    </span>
  }

  return (
    <div>
      <TreeComponent
        expandedItems={expand.ids}
        onExpandedItems={exp => setExpand({...expand, ids: exp})}
        selected={select}
        item={ItemComponent}
        onItemCLick={onItemClick}
        data={tree}/>
    </div>
  )
}

export default TreeViewComponent;