import * as React from 'react';
import {useContext} from 'react';
import {AnalyticQueryAction} from "../../../types/Analytic";
import {ReportContext} from "../NewReportGeneratorComponent";

type Props = {
    index: number
    action: AnalyticQueryAction
};

const NewLimitActionComponent = ({action, index}: Props) => {
    const {setQueryAction} = useContext(ReportContext)

    return (
        <div><input type="number" min={1} className="form-control"
                    defaultValue={action.top || 10} onChange={event => {
            setQueryAction({
                ...action,
                top: event.currentTarget.value ? parseInt(event.currentTarget.value) : 1
            }, index)
        }} placeholder={'Enter limit number...'}/></div>
    );
};

export default NewLimitActionComponent