import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faSpinner, faTimes} from "@fortawesome/free-solid-svg-icons";
import {Button} from "reactstrap";
import ModalComponent, { SizeModal } from "./ModalComponent/ModalComponent";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {actionsModal, ModalTypes} from "../../../store/Modal/Slice";
import useTranslate from "../../../hooks/useTranslate";
import { CustomState } from "../../../store/GlobalStateReducer";
import { State } from "../../../types/State";

interface IProps {
  data: IPropsConfirmModal;
  open: boolean;
}

export interface IPropsConfirmModal {
  type: ModalTypes.CONFIRM;
  onConfirm: (data?: any) => any;
  onClose?: (data?: any) => any;
  message: React.ReactNode;
  info?: any;
  title?: React.ReactNode;
  body?: React.ReactNode;
  state?: string;
  customState?: [CustomState, State],
  closeModal?: boolean;
  formId?: string
  noFooter?: boolean,
  size?: SizeModal;
}

const ConfirmationModal = (
  {open, data: {onConfirm, message, info, title, state = "", body, closeModal, formId, onClose, noFooter = false, size, customState}}: IProps) => {
  const dispatch = useDispatch();
  const stateReducers: any = useSelector(state => state);

  const {t} = useTranslate("common");

  const isLoading = Object.keys(stateReducers).find(key => {
    return stateReducers[key]?.state === state || stateReducers[key]?.mfaStates === state;
  });

  const loadingCustomState = 
  !!customState &&
  Object.keys(stateReducers).some((key) => {
    const [type, status] = customState;
    return (
      stateReducers[key]?.customState?.[type] === status
    );
  });

  const loading = isLoading || loadingCustomState;

  const toggle = () => {
    if (onClose) {
      onClose(info);
    }
    dispatch(actionsModal.closeModal({type: ModalTypes.CONFIRM}))
  }

  return (
    <ModalComponent
      title={title}
      size={size}
      footer={
        noFooter ? <></> :<>
          <Button
            type={formId ? "submit" : "button"}
            form={formId}
            disabled={!!loading}
            onClick={() => {
              onConfirm(info)
              closeModal && dispatch(actionsModal.closeModal({type: ModalTypes.CONFIRM}))
            }}
            color="primary" size="sm">
            <FontAwesomeIcon icon={loading ? faSpinner : faCheck} spin={!!loading}/>{" " + t("confirm")}
          </Button>
          <Button
            disabled={!!loading}
            onClick={toggle}
            color="light" size="sm">
            <FontAwesomeIcon icon={faTimes}/> {" " + t("cancel")}
          </Button>
        </>
      }
      isOpen={open}
      toggle={() => !loading && toggle()}>
      <div className={formId ? "" : "text-center"}>
        {message}
      </div>
      {body}
    </ModalComponent>
  )
}


export default ConfirmationModal;
