import * as React from 'react';
import {useState} from 'react';
import {AnalyticQueryAction, BaseAnalyticQuery, Report, TruncateReport} from "../../types/Analytic";
import TruncateWrapperComponent from "./TruncateWrapperComponent";
import ButtonComponent from "../Shared/ButtonComponent";
import useTranslate from "../../hooks/useTranslate";


type Props = {
    truncate?: TruncateReport | null
    launchQuery?: (query: Report, reload?: boolean) => void
    report: Report
    query?: BaseAnalyticQuery
    setTruncate?: (truncate: TruncateReport) => void
    getItemsFromTableColumn?: (column: string) => string[]
    disabled?: boolean
};

const TruncateListComponent = ({
                                   truncate,
                                   query,
                                   report,
                                   disabled,
                                   launchQuery,
                                   setTruncate,
                                   getItemsFromTableColumn
                               }: Props) => {
    const [localQuery, setLocalQuery] = useState<BaseAnalyticQuery | null>(null)
    const [rangeType, setRangeType] = useState("dates_range")
    const [launchData, setLaunchData] = useState<null | Report>(null)
    const {t} = useTranslate('common')
    React.useEffect(() => {
        if (localQuery === null && query) {
            setLocalQuery(query)
        }
    }, [query, localQuery]);

    const setTruncateAction = ({action, nTruncate, report, index}: {
        nTruncate?: TruncateReport,
        action?: AnalyticQueryAction,
        report: Report,
        index?: number
    }) => {
        if (launchQuery) {

            if (action) {
                if (nTruncate && query) {
                    const newAction = {...action, truncate: nTruncate as TruncateReport}
                    const nQuery = {
                        ...query, actions: query.actions?.map((item, actionIndex) => {

                            if (item.type === "date_analysis") {
                                if (rangeType !== "dates_range") {
                                    item = {...item, analysis_by: rangeType.split("_")[0].replace("s", "")}
                                }
                            }

                            if (actionIndex === index) {
                                return newAction
                            }
                            return item
                        })
                    }


                    setLocalQuery(nQuery)
                    launchQuery({
                        ...report,
                        query: JSON.stringify(nQuery)
                    })
                }
            } else {

                let launch = true
                const nQuery = {
                    ...query, actions: query?.actions?.map((item, actionIndex) => {
                        if (item.type === "date_analysis") {
                            if (rangeType !== "dates_range") {
                                item = {...item, analysis_by: rangeType.split("_")[0].replace("s", "")}
                            } else {
                                if (localQuery) {
                                    const actionAnalysis = localQuery.actions?.find((action, index) => action.type === "date_analysis" && index === actionIndex)?.analysis_by
                                    if (actionAnalysis) {
                                        item = {...item, analysis_by: actionAnalysis}
                                    }
                                }
                            }
                        }

                        return item
                    }) ?? []
                }

                const newReport = {
                    ...report as Report,
                    query: query ? JSON.stringify(nQuery) : report.query,
                    truncate: JSON.stringify(nTruncate)
                }
                if (report.skip_execution_on_open) {
                    launch = false
                    setLaunchData(newReport)
                }

                if (launch) {
                    launchQuery(newReport)
                }

            }
        }
    }

    const renderTruncateMerge = () => {
        if (localQuery?.actions && localQuery?.actions?.length > 0) {
            const actions = localQuery.actions.reduce((arr: {
                truncate: TruncateReport,
                action: AnalyticQueryAction,
                index: number
            }[], action, index) => {
                if (action.type === "merge" && action.truncate && (action.truncate as TruncateReport)?.model) {

                    arr.push({
                        truncate: (action.truncate as TruncateReport),
                        action,
                        index
                    })
                }
                return arr;
            }, [])

            return actions.map(({truncate, action, index}) => {
                return <TruncateWrapperComponent rangeType={rangeType} setRangeType={setRangeType} disabled={disabled}
                                                 truncate={truncate} report={report}
                                                 getItemsFromTableColumn={getItemsFromTableColumn}
                                                 launchQuery={launchQuery} action={action} index={index}
                                                 setTruncate={setTruncate}
                                                 key={`truncate_report_${report._KEY ?? ""}_${truncate.name ?? ""}_${truncate.range}_${truncate.format}_${index}`}
                                                 setTruncateAction={setTruncateAction}/>
            })
        }
        return null
    }

    return (
        <div className="d-flex flex-wrap gap-3 align-items-end">
            {truncate ? <TruncateWrapperComponent rangeType={rangeType} setRangeType={setRangeType} truncate={truncate}
                                                  report={report} disabled={disabled}
                                                  launchQuery={launchQuery}
                                                  getItemsFromTableColumn={getItemsFromTableColumn}
                                                  key={`truncate_report_${report._KEY ?? ""}_${truncate.name ?? ""}`}
                                                  setTruncate={setTruncate}
                                                  setTruncateAction={setTruncateAction}/> : null}

            {renderTruncateMerge()}

            {report.skip_execution_on_open ?
                <div className="mb-2">
                    <ButtonComponent label={t("launch")} onClick={() => {
                        launchQuery && launchQuery(launchData as Report)
                    }} color={"primary"}/>
                </div>
                : null}
        </div>
    );
};

export default TruncateListComponent