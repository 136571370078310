import * as React from 'react';
import {useContext, useState} from 'react';
import {DropdownItem} from "reactstrap";
import {Controller, UseControllerProps, UseFieldArrayRemove, UseFormGetValues, UseFormWatch} from "react-hook-form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ModelsResponse} from "../../../../types/Sbx";
import {ReportContext} from "../../NewReportGeneratorComponent";
import {faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import {AnalyticQueryAction} from "../../../../types/Analytic";
import DropdownActionComponent from "../DropdownActionComponent";
import {ReportSummarizeForm} from "./ReportSummarizeComponent";
import ActionDropdownBodyComponent
    from "../ActionDropdownComponent/ActionDropdownBodyComponent";

type Props = {
    index: number
    remove: UseFieldArrayRemove,
    control: UseControllerProps<any>["control"]
    model?: ModelsResponse
    watch: UseFormWatch<ReportSummarizeForm>
    indexAction?: number
    queryAction?: AnalyticQueryAction
    color?: string
    formName: 'columns' | 'index_columns' | 'hierarchy_columns'
    isDateAnalysis?: boolean
    getValues: UseFormGetValues<ReportSummarizeForm>
};

const ReportSummarizeColumnsComponent = ({
                                             index,
                                             remove,
                                             model,
                                             control,
                                             indexAction,
                                             queryAction,
                                             watch, formName,
                                             getValues, color, isDateAnalysis
                                         }: Props) => {

    const {state, getActionColumns, getLoadingAndDisabledAction} = useContext(ReportContext)
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [columns, setColumns] = useState<string[]>([])

    const toggle = () => {
        setDropdownOpen((prevState) => !prevState);
    }

    React.useEffect(() => {

        let baseColumns: string[] = []

        if (typeof indexAction !== 'undefined' && queryAction) {
            baseColumns = getActionColumns(queryAction, indexAction).filter(column => {
                if (isDateAnalysis) {
                    return state.columnsType[index] ? (state.columnsType[index][column.value] === "DATE" ?? "STRING") : true
                }
                return true
            }).map(column => column.value)
        } else {
            baseColumns = state.baseColumns
        }

        const conditions = getValues(`conditions`)
        const columnsReport = getValues(formName as 'columns' | 'index_columns')

        if (conditions && conditions.length > 0) {
            baseColumns = baseColumns.filter(column => !conditions.some(condition => condition!.column === column))
        }

        if (columnsReport && columnsReport.length > 0) {
            baseColumns = baseColumns.filter(column => !columnsReport.some(columnReport => columnReport!.name === column))
        }

        setColumns([...baseColumns].sort((a, b) => a.localeCompare(b)))

    }, [queryAction, indexAction, state, formName]);

    React.useEffect(() => {
        const subscription = watch((obj) => {

            const list = obj[formName] ?? []

            if (list?.length > 0 && !list[index]?.name) {
                setDropdownOpen(true)
            }
        });
        return () => subscription.unsubscribe();
    }, [watch, index]);

    const renderToggleMenu = () => {

        // if (model && model.properties.length > 0) {
            return <>
                <DropdownItem header>{model?.name ?? ""}</DropdownItem>
                <DropdownItem divider/>

                <Controller render={({field}) => {
                    return <ActionDropdownBodyComponent loading={getLoadingAndDisabledAction({action: queryAction, index: indexAction})} onChange={value => {
                        field.onChange(value)
                    }} toggle={toggle} index={indexAction} showType columns={columns}/>
                }} control={control} name={`${formName}.${index}.name`}/>
            </>
        // }
        // return <span/>
    }

    const getFilterName = () => {
        const column = watch(`${formName}.${index}`)
        return <span className="d-flex align-items-center gap-2">
            {column.name ? <span>{column.name}</span> : "column " + (index + 1)}
            {column && <FontAwesomeIcon icon={faTimesCircle} className="pointer" onClick={() => {
                remove(index)
            }}/>}
        </span>
    }

    return (
        <DropdownActionComponent dropdownStyle={{background: color ?? "#5e72e4", borderColor: color ?? "#5e72e4"}}
                                 basicConfigMenu isOpen={dropdownOpen} toggle={toggle} header={getFilterName()}>
            {renderToggleMenu()}
        </DropdownActionComponent>

    );
};

export default ReportSummarizeColumnsComponent