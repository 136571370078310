import React, {useContext, useState} from 'react';
import {AnalyticQuery, BaseAnalyticQuery, Source} from '../../../types/Analytic';
import {Button} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEye, faPlusCircle} from '@fortawesome/free-solid-svg-icons';
import SourceQuerySelectorComponent from './SourceQuerySelectorComponent';
import SourcesListComponent from './SourcesListComponent';
import ReportPreviewComponentWrapper from '../ReportPreviewComponentWrapper/ReportPreviewComponentWrapper';
import {ReportGeneratorContext, sourceBase} from '../ReportGeneratorComponent';
import {removeDuplicateFromArray, updateAnalyticSource, uuidV4} from '../../../utils';
import {StringOption} from '../../../types/Select';
import {getAnalyticJsonColumns} from "../../../services/backend/AnalyticsService";
import {State} from "../../../types/State";
import useTranslate from "../../../hooks/useTranslate";
import {Tooltip} from "antd";
import styled from "styled-components";

export interface UpdateSource {
  sourceKey: keyof Source,
  sourceValue?: any,
  sourceId?: string
}

export const mergeTypeOptions: StringOption[] = [
  {label: 'inner', value: "inner"},
  {label: 'outer', value: "outer"},
  {label: 'left', value: "left"},
  {label: 'right', value: "right"}
];

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  gap: 15px;
  align-items: self-start;
  
  @media(max-width: 799px){
    grid-template-columns: 1fr;
  }
`

const SourceComponent = () => {

  const {
    reportState: {analyticQuery, sourceColumns},
    dispatchForm,
    dispatchMultiForm, getAllActionColumns
  } = useContext(ReportGeneratorContext);
  const [currentSource, setCurrentSource] = useState<Source>(analyticQuery.source)

  React.useEffect(() => {

    if (!currentSource.with && analyticQuery.source.with){
      setCurrentSource(analyticQuery.source)
    }

  }, [analyticQuery.source]);


  const {t} = useTranslate('report');
  const addNewSource = async () => {
    const newSource = {...sourceBase, temporal_id: uuidV4()};
    delete newSource.action;
    setCurrentSource(newSource)


    const sources = analyticQuery.source.sources ? [...analyticQuery.source.sources, newSource] : [newSource];
    updateSource([{
      sourceId: analyticQuery.source.temporal_id,
      sourceKey: 'sources',
      sourceValue: sources
    }]);

    const query: BaseAnalyticQuery = {
      source: {...analyticQuery.source, sources},
      actions: []
    };

    dispatchForm({name: 'isLoading', value: State.PENDING})
    const data = await getAnalyticJsonColumns(query);
    if (data?.items && data.items.length > 0) {
      data.items = removeDuplicateFromArray(data.items.filter(column => column));
      const newSourceColumns = {
        ...sourceColumns,
        [newSource.temporal_id]: data.items
      };

      dispatchMultiForm([
        {name: 'sourceColumns', value: newSourceColumns},
        {name: 'isLoading', value: State.RESOLVED}
      ])

    } else {
      dispatchForm({name: 'isLoading', value: State.REJECTED})
    }

  };

  const updateSource = (sources: UpdateSource[], query?: AnalyticQuery) => {
    if (sources.some(source => source.sourceKey === "sources")) {
      const sourcesQuantity = sources.find(source => source.sourceKey === "sources")?.sourceValue?.length
      if (typeof sourcesQuantity === "number") {
        sources.push({
          sourceKey: 'action',
          sourceValue: sourcesQuantity === 0 ? 'get' : 'merge',
          sourceId: analyticQuery.source.temporal_id
        })
      }
    }

    const currentSourceProperty = sources.find(source => source.sourceId === currentSource.temporal_id)

    if (currentSourceProperty){
      const newSource = {...currentSource, [currentSourceProperty.sourceKey]: currentSourceProperty.sourceValue}
      setCurrentSource(newSource)
    }


    const newQuery = updateAnalyticSource({sources, query: query ?? analyticQuery});
    dispatchForm({name: 'analyticQuery', value: newQuery});
    if (newQuery && newQuery.actions.length > 0){
      getAllActionColumns(newQuery)
    }
  }

  return (
    <Container>
      <div className="card">
        <div className="card-header d-flex align-items-center justify-content-between gap-1 border-bottom p-2">
          <span> {t("common:source")}</span>
          <div>
            <Tooltip title={t("add-source")}>

              <Button color={'success'} size={'sm'}
                      onClick={addNewSource}>
                <FontAwesomeIcon icon={faPlusCircle} className="mr-1"/>
                <span>{t("add-source")}</span>
              </Button>
            </Tooltip>
          </div>
        </div>
        <div className="d-flex flex-column px-2 overflow-auto py-2" style={{maxHeight: '70vh'}}>
          <div className="d-flex flex-column p-3">
              <div className="text-end">
                <Button outline={currentSource.temporal_id !== analyticQuery.source.temporal_id} color={"primary"} onClick={() => setCurrentSource(analyticQuery.source)}>
                  <FontAwesomeIcon icon={faEye} className="pointer" />
                </Button>
              </div>
              <SourceQuerySelectorComponent source={analyticQuery.source} updateSource={updateSource}/>
          </div>

          {analyticQuery?.source?.sources && analyticQuery?.source?.sources!?.length > 0 &&
            <SourcesListComponent sources={analyticQuery.source.sources} currentSource={currentSource} setCurrentSource={setCurrentSource}
                                  updateSource={updateSource}/>
          }

        </div>
      </div>

      {currentSource && <ReportPreviewComponentWrapper key={currentSource.temporal_id} source={currentSource} updateSource={updateSource}/>}

    </Container>
  );
};

export default SourceComponent;
