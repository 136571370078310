import * as React from 'react';
import {Field} from "../../../types/Field";
import {UseFormRegister} from "react-hook-form";
import {useContext, useEffect, useState} from "react";
import {CrmInputContext} from "./CrmRenderInput";
import {TaskComponentContext} from "../../../context/TaskContext";

type Props = {
  field: Field,
  register?: UseFormRegister<any>
};


const CrmLargeTextComponent = ({field, register}: Props) => {

  const [defaultValue, setDefaultValue] = useState<string>('');
  const {getDefaultValue} = useContext(CrmInputContext)
  const {watch, setValue} = useContext(TaskComponentContext);

  useEffect(() => {
    let subscription: any = null
    if (watch) {
      subscription = watch((obj, {name, type}) => {
        setTimeout(() => {
          const value = getDefaultValue(field)
          setDefaultValue(value ?? "");
        }, 1000)
      });
    }
    return () => subscription?.unsubscribe();
  }, [watch, field]);

  React.useEffect(() => {

    if (!defaultValue && setValue){
      setValue(field.name, undefined)
    }

  }, [defaultValue, field]);


  return register ? <textarea className="form-control"
                              id={field.name + '_' + (field.id ?? field.sort_index.toString() + "_"+field.name)}
                              disabled={field.read_only}
                              defaultValue={defaultValue}
                              {...register(field.name, {required: field.required && !field.read_only})}
  >
          </textarea> : null
};

export default CrmLargeTextComponent