import React, {useContext, useState} from 'react';
import ModalComponent from '../Shared/Modal/ModalComponent/ModalComponent';
import EditorComponent from '../Shared/EditorComponent/EditorComponent';
import {ReportGeneratorContext} from './ReportGeneratorComponent';
import {addTemporalIdToQuery, getAnalyticQueryFlat, getAnalyticQueryGrouped, getQuerySources} from '../../utils/analyticsUtils';
import {AnalyticQuery} from '../../types/Analytic';
import {Button} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faDownload, faTimes} from '@fortawesome/free-solid-svg-icons';

type Props = {
  toggle: (show: boolean) => void
}

const JsonToQueryComponent = ({toggle}: Props) => {

  const {dispatchMultiForm, getAllActionColumns, getAllSourcesColumns, getColumns} = useContext(ReportGeneratorContext);
  const [query, setQuery] = useState<string>("");
  return (
    <ModalComponent isOpen size={"xl"} toggle={() => {}} footer={<div className="d-flex align-items-end gap-2">
      <Button color={"success"} onClick={() => {
        const parseQuery: AnalyticQuery = JSON.parse(query)

        if (parseQuery){
          dispatchMultiForm([
            {name: 'analyticQuery', value: getAnalyticQueryGrouped(addTemporalIdToQuery(getAnalyticQueryFlat(parseQuery)))},
            {name: 'queriesSource', value: getQuerySources(getAnalyticQueryFlat(parseQuery))},
          ])

          getColumns({analyticQuery: parseQuery})
          getAllActionColumns(parseQuery)
          getAllSourcesColumns(parseQuery)
          toggle(false)
        }
      }}>
        <FontAwesomeIcon className={"me-1"} icon={faDownload} />
        Get query
      </Button>

      <Button color={"danger"} onClick={() => toggle(false)}>
        <FontAwesomeIcon className={"me-1"} icon={faTimes} />
        Cancel
      </Button>
    </div>}>
      <EditorComponent width="100%"
                       height="500px" onChange={value => {

          setQuery(value)

      }} />
    </ModalComponent>
  );
};

export default JsonToQueryComponent;
