import { actionsModal, ModalTypes } from "../../../../store/Modal/Slice";
import ModalComponent, { ModalProps } from "../ModalComponent/ModalComponent";
import { useDispatch, useSelector } from "react-redux";
import React, { useState } from "react";
import { CustomState } from "../../../../store/GlobalStateReducer";
import { State } from "../../../../types/State";

interface IProps<T> {
  open: boolean;
  data: IPropsDynamicComponentModal<T>;
}

export interface IPropsDynamicComponentModal<T>
  extends Omit<
    ModalProps,
    "type" | "isOpen" | "toggle" | "isLoading" | "onSuccess"
  > {
  type: ModalTypes.DYNAMIC_COMPONENT_MODAL;
  component?: React.ReactNode;
  value?: T;
  identifierKey?: string;
  renderComponent?: (
    value: T,
    onChangeValue: React.Dispatch<React.SetStateAction<T>>,
  ) => React.ReactElement<HTMLElement>;
  typeForm?: "button" | "submit" | "reset";
  state?: string;
  customState?: [CustomState, State],
  toggle?: () => void;
  onSuccess?: (data?: any) => void;
  closeAfterSuccess?: boolean;
  centered?: boolean;
  onlyDisabled?: boolean;
}

function DynamicComponentModal<T>({
  data: {
    component,
    size,
    footer,
    title,
    typeForm,
    noFooter,
    onSuccess,
    labelCancel,
    labelSuccess,
    form,
    zIndex,
    value,
    header,
    disabled,
    state,
    customState,
    renderComponent,
    toggle: onToggle,
    closeAfterSuccess = false,
    centered = false,
    identifierKey,
    onlyDisabled = false
  },
  open,
}: IProps<T>) {
  const dispatch = useDispatch();
  const stateReducers: any = useSelector((state) => state);

  const [newValue, setValue] = useState<T>(value!);

  function toggle() {
    dispatch(
      actionsModal.closeModal({
        type: ModalTypes.DYNAMIC_COMPONENT_MODAL,
        identifierKey,
      }),
    );
  }

  const loading =
    !!state &&
    Object.keys(stateReducers).some((key) => {
      return (
        stateReducers[key]?.state === state ||
        stateReducers[key]?.mfaStates === state ||
        stateReducers[key]?.linkStates === state
      );
    });

  const loadingCustomState = 
  !!customState &&
  Object.keys(stateReducers).some((key) => {
    const [type, status] = customState;
    return (
      stateReducers[key]?.customState?.[type] === status
    );
  });

  return (
    <ModalComponent
      size={size}
      type={typeForm}
      title={title}
      centered={centered}
      footer={footer}
      isOpen={open}
      noFooter={noFooter}
      onSuccess={() => {
        if (onSuccess) onSuccess(newValue);
        if (closeAfterSuccess) toggle();
      }}
      labelCancel={labelCancel}
      labelSuccess={labelSuccess}
      disabled={disabled}
      form={form}
      zIndex={zIndex}
      isLoading={loading || loadingCustomState}
      header={header}
      toggle={onToggle ?? toggle}
      onlyDisabled={onlyDisabled}
    >
      <>
        {component}
        {renderComponent && renderComponent(newValue, setValue)}
      </>
    </ModalComponent>
  );
}

export default DynamicComponentModal;
