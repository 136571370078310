import * as React from 'react';
import {useState} from 'react';
import {Report, TruncateReport, TruncateUpdateFields} from "../../types/Analytic";
import CreatableSelectComponent from "../Shared/FieldComponents/CreatableSelectComponent";
import {capitalize, convertNumberDateToDate, labelRange, uuidV4} from "../../utils";
import useTranslate from "../../hooks/useTranslate";
import useAsyncEffect from "../../hooks/useAsyncEffect";
import {MultiReport} from "../analytics/AnalyticsPageComponent";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons";
import TruncateByTypeComponent from "./TruncateByTypeComponent";

type Props = {
    truncate: TruncateReport
    report?: Report | MultiReport
    setTruncate?: (truncate: string, launch?: boolean, report?: Report) => void
    launchQuery?: (query: Report) => void
    getItemsFromTableColumn?:(column: string) => string[]
    disabled?: boolean
    rangeType: string
    setRangeType: (type: string) => void
};

const TruncateComponent = ({truncate, report, disabled, setTruncate, launchQuery, getItemsFromTableColumn, rangeType, setRangeType}: Props) => {
    const {t} = useTranslate('common')
    const [truncateRange, setTruncateRange] = useState<{
        endDate: Date | null,
        startDate: Date | null
    }>({
        endDate: null,
        startDate: null
    })

    const [rangeOption, setRangeOption] = useState<number | string>("")
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => {
        setDropdownOpen((prevState) => !prevState);
    }

    const handleTruncate = ({
                                prop,
                                value,
                                launch
                            }: { prop: keyof TruncateReport, value: string | number, launch?: boolean }) => {
        if (report?.truncate) {
            if (setTruncate) {
                setTruncate(JSON.stringify({...truncate, [prop]: value}), launch)
            }
        }
    }


    const getUpdateFieldLabel = ({prop, value}: { prop: keyof TruncateUpdateFields, value: string }) => {
        const item = (truncate.update_fields as TruncateUpdateFields)[prop]?.find(field => field.name === value)
        return item?.label ?? value
    }

    useAsyncEffect(async () => {

        if ((!t(labelRange[truncate.range as (number | string)]) ?? "") && !rangeOption) {

            if (typeof truncate.range === "string" && truncate.range.includes("-")) {
                const [startDate, endDate] = truncate.range.split("-")

                setTruncateRange({
                    startDate: convertNumberDateToDate(startDate),
                    endDate: convertNumberDateToDate(endDate)
                })
            }

            setRangeOption("select-date")
        }

    }, [truncate]);

    React.useEffect(() => {
        const today = new Date()
        if (typeof rangeOption === 'number' && rangeOption >= 0) {
            switch (rangeOption) {
                case 0: {
                    const startDate = new Date(today.getFullYear(), today.getMonth(), 1)
                    const endDate = new Date()
                    setTruncateRange({startDate, endDate})
                }
                    break;
                case 10: {
                    setTruncateRange({startDate: today, endDate: today})
                }
                    break;
                case 1: {
                    const newToday = new Date()
                    newToday.setDate(newToday.getDate() - 30)
                    setTruncateRange({startDate: newToday, endDate: today})
                }
                    break;
            }
        }

    }, [rangeOption]);

    React.useEffect(() => {


        if (!rangeOption.toString() && typeof truncate.range === 'number' && truncate.range >= 0) {
            setRangeOption(truncate.range)
        }


    }, [rangeOption, truncate]);

    const dropdownData = () => {
        return <Dropdown isOpen={dropdownOpen} toggle={toggle} direction={'down'}>
            <DropdownToggle outline color="transparent" className="px-0">
                <u className="text-capitalize d-flex align-items-center gap-1">
                    {capitalize(truncate.name)}
                    <FontAwesomeIcon icon={faChevronDown}/></u>
            </DropdownToggle>

            <DropdownMenu>
                {truncate.models_and_fields?.sort((a, b) => a.name.localeCompare(b.name)).map(item => (
                    <DropdownItem onClick={() => {
                        if (setTruncate) {
                            setTruncate(JSON.stringify({
                                ...truncate,
                                model: item.model,
                                field: item.field,
                                name: item.name
                            }), true)
                        }
                    }}>{item.name}</DropdownItem>
                ))}
            </DropdownMenu>

        </Dropdown>
    }

    const getTruncateName = () => {
        if (truncate.models_and_fields && truncate.models_and_fields.length > 0) {
            return dropdownData()
        }

        return capitalize(truncate.name)
    }

    return (
        <div className="d-flex flex-column">

            {truncate?.name && <b>
                {getTruncateName()}
            </b>}


            {
                truncate.update_fields && Object.keys(truncate.update_fields).length > 0 &&
                <>
                    {(truncate.update_fields as TruncateUpdateFields).model &&
                        <div className="d-flex flex-column col-12 col-md-3 col-lg-2">
                            <span>{t("model")}: </span>
                            <CreatableSelectComponent id={uuidV4()} defaultValue={{
                                label: getUpdateFieldLabel({value: truncate.model, prop: "model"}) ?? "",
                                value: truncate.model
                            }} onChange={evt => {
                                handleTruncate({prop: "model", value: evt.value})
                            }} name={"model"}
                                                      options={(truncate.update_fields as TruncateUpdateFields).model.map(item => ({
                                                          label: item.label,
                                                          value: item.name
                                                      }))}/>
                        </div>}

                    {(truncate.update_fields as TruncateUpdateFields).field &&
                        <div className="d-flex flex-column col-12 col-md-3 col-lg-2">
                            <span>{t("field")}: </span>
                            <CreatableSelectComponent id={uuidV4()} defaultValue={{
                                label: getUpdateFieldLabel({value: truncate.field ?? "", prop: "field"}) ?? "",
                                value: truncate.field
                            }} onChange={evt => {
                                handleTruncate({prop: "field", value: evt.value})
                            }} name={"field"}
                                                      options={(truncate.update_fields as TruncateUpdateFields).field.map(item => ({
                                                          label: item.label,
                                                          value: item.name
                                                      }))}/>
                        </div>}
                </>
            }


            <TruncateByTypeComponent disabled={disabled} rangeType={rangeType} setRangeType={setRangeType}
                                     truncate={truncate}  setTruncate={setTruncate} getItemsFromTableColumn={getItemsFromTableColumn} setTruncateRange={setTruncateRange}
                                     truncateRange={truncateRange} report={report} launchQuery={launchQuery}/>


        </div>
    );
};

export default TruncateComponent