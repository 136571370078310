import React, {FormEvent, useCallback, useEffect, useState} from 'react';
import {actionsModal, ModalTypes} from '../../../../store/Modal/Slice';
import {GatewayProcess, ProcessModel} from '../../../../types/models/processModel/Process';
import ModalComponent from '../ModalComponent/ModalComponent';
import {useDispatch} from 'react-redux';
import useTranslate from '../../../../hooks/useTranslate';
import {createOrUpdateProcessModelService} from '../../../../services/backend/ProcessService';
import {Button, CardBody} from 'reactstrap';
import {Field} from '../../../../types/Field';
import CreateFieldPreview from '../CreateFieldModal/CreateFieldPreview';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSave, faSpinner, faTimes} from '@fortawesome/free-solid-svg-icons';
import {fields} from './config';

export interface IProps {
  open: boolean;
  data: IPropsUpsertGatewayModal;

}

export interface IPropsUpsertGatewayModal {
  type: ModalTypes.UPSERT_GATEWAY_MODAL;
  gateway?: GatewayProcess,
  currentProcessModel: ProcessModel;
  onFinish: (process: ProcessModel) => void;
}

const UpsertGatewayModal = (props: IProps) => {

  const dispatch = useDispatch();
  const {t} = useTranslate('common');
  const [fieldsInput, setFieldsInput] = useState<Field[]>([]);
  const [gateway, setGateway] = useState<GatewayProcess>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [processModel] = useState(props.data.currentProcessModel);
  const toggle = () => {
    dispatch(actionsModal.closeModal({type: ModalTypes.UPSERT_GATEWAY_MODAL}));
  };

  const options: any = {
    gateway_type: [
      {value: 'EXCLUSIVE', label: 'EXCLUSIVE'},
      {value: 'PARALLEL', label: 'PARALLEL'},
      {label: 'INCLUSIVE', value: 'INCLUSIVE'}
    ]
  };

  const setData = useCallback((evt: any) => {
    setGateway((e: any) => ({...e, id: evt.id}));
    setFieldsInput(fields.map((field, i) => {
      const tt = evt as any;
      const val = tt[field.name] ?? field.default_value;
      const default_value = options[field.name]?.find((opt: any) => opt.value === val) ?? val;
      setGateway((e) => ({...e, [field.name]: default_value}));

      return {
        ...field,
        id: i
      };
    }));
  }, [processModel]);

  useEffect(() => {
    setData(props.data.gateway ?? {});
  }, [props.data.gateway]);


  function getGateway(evt: any) {
    let newGateway: GatewayProcess = {...evt};
    fields.forEach(field => {
      const value = gateway[field.name as keyof GatewayProcess];
      const tt = typeof value;
      newGateway[field.name as keyof GatewayProcess] = (tt === 'object' && value ? (value as any).value : value) ?? '';
    });

    return newGateway;
  }

  async function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    let newProcessModel = {...processModel};
    setLoading(true);

    if (gateway.id && newProcessModel.gateways) {
      newProcessModel.gateways = newProcessModel.gateways.map(evt => {
        if (evt.id === gateway.id) {
          return getGateway(evt);
        }
        return evt;
      });
    } else {
      let gatewayT = new Array(...newProcessModel.gateways ?? []);
      const nv = getGateway({});
      gatewayT.push(nv);
      newProcessModel.gateways = gatewayT;
    }
    const res = await createOrUpdateProcessModelService(newProcessModel);
    if (res?.success) {
      props.data.onFinish(res.item);
      toggle();
    }
    setLoading(false);
  }

  return (
    <ModalComponent
      isLoading={loading}
      size="md"
      title={(props.data?.gateway?.id ? t('update') : t('new')) + ' ' + t('gateway')}
      isOpen={props.open}
      noFooter
      toggle={toggle}>
      <form className="card" id="formUpsertModal" onSubmit={onSubmit}>
        <CardBody>
          {fieldsInput.map(field => {
            return (
              <CreateFieldPreview
                key={field.name}
                options={options[field.name]}
                onChange={e => setGateway(gateway => ({...gateway, [field.name]: e}))}
                value={gateway[field.name as keyof GatewayProcess]}
                field={field}/>
            );
          })}
          <div className="d-flex justify-content-end gap-1">
            <Button disabled={loading} color="primary" size="sm">
              <FontAwesomeIcon spin={loading} icon={loading ? faSpinner : faSave} className="me-1"/>
              {t('save')}
            </Button>
            {!!gateway.id &&
            <Button type="button" onClick={toggle} disabled={loading} color="light" size="sm"
                    className="me-1">
              <FontAwesomeIcon icon={faTimes} className="me-1"/>
              {t('cancel')}
            </Button>}
          </div>
        </CardBody>
      </form>
    </ModalComponent>
  );
};

export default UpsertGatewayModal;
