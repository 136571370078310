import * as React from 'react';
import {AnalyticQueryAction} from "../../../types/Analytic";
import {useContext, useRef, useState} from "react";
import {ReportContext} from "../NewReportGeneratorComponent";
import {IsJsonString} from "../../../utils";

type Props = {
    action: AnalyticQueryAction
    indexAction: number
};
const DefaultActionComponent = ({action, indexAction}: Props) => {
    const {setQueryAction} = useContext(ReportContext)
    let onMount = useRef(false)

    const [value, setValue] = useState("")

    React.useEffect(() => {

        if (value === "" && !onMount.current){
            setValue(JSON.stringify(action, null, "\t"))
            onMount.current = true
        }

    }, [action, value]);

    return (
        <div>
            <textarea className="form-control" defaultValue={value || ""} onChange={event => {
                const value = event.currentTarget.value
                if (IsJsonString(value)) {
                    setQueryAction({...JSON.parse(value), temporal_id: action.temporal_id}, indexAction)
                    setValue(JSON.stringify({...JSON.parse(value), temporal_id: action.temporal_id}))
                }
            }} rows={10}></textarea>
        </div>
    );
};

export default DefaultActionComponent