import {
  faAt,
  faCalendarDay,
  faCheckCircle,
  faClock,
  faComment,
  faCommentAlt,
  faCopy,
  faDotCircle,
  faFile,
  faGripHorizontal,
  faHashtag,
  faIdCard,
  faKey,
  faLayerGroup,
  faListAlt,
  faMoneyBillAlt,
  faPhoneAlt,
  faTable,
} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import IField from "../../../types/FormBuilder/IField";
import CurrencyComponent from "../../Shared/FieldComponents/CurrencyComponent";
import DateComponent from "../../Shared/FieldComponents/DateComponent";
import DateRangeComponent from "../../Shared/FieldComponents/DateRangeComponent";
import DocumentComponent from "../../Shared/FieldComponents/DocumentComponent";
import EmailComponent from "../../Shared/FieldComponents/EmailComponent";
import LargeTextComponent from "../../Shared/FieldComponents/LargeTextComponent";
import MultiFileComponent from "../../Shared/FieldComponents/MultiFileComponent";
import MultiSelectComponent from "../../Shared/FieldComponents/MultiSelectComponent";
import NumberComponent from "../../Shared/FieldComponents/NumberComponent";
import PasswordComponent from "../../Shared/FieldComponents/PasswordComponent";
import PhoneComponent from "../../Shared/FieldComponents/PhoneComponent";
import SingleFileComponent from "../../Shared/FieldComponents/SingleFileComponent";
import SmallTextComponent from "../../Shared/FieldComponents/SmallTextComponent";
import TableComponent from "../../Shared/FieldComponents/TableComponent";
import FormGroupComponent from "../../Shared/FieldComponents/FormGroupComponent";
import TimeComponent from "../../Shared/FieldComponents/TimeComponent";
import CheckboxComponent from "../../Shared/FieldComponents/CheckboxComponent";
import RadioButtonComponent from "../../Shared/FieldComponents/RadioButtonComponent";
import { FieldType, SubType } from "../../../types/FieldType";
import { plainToClass } from "class-transformer";
import SelectComponent from "../../Shared/FieldComponents/SelectComponent";
import TitleComponent from "../../Shared/FieldComponents/TitleComponent";
import ToDoListComponent from "../../Shared/FieldComponents/ToDoListComponent";
import CalendarPickerComponent from "../../Shared/FieldComponents/CalendarPicker/CalendarPickerComponent";
import MultiInputComponent from "../../Shared/FieldComponents/MultiInputComponent";

let currency: number = 0;
export const currencyField: IField = {
  ...plainToClass(IField, {
    label: "Moneda - etiqueta",
    name: "moneda_nombre",
    size: 12,
    field_type: FieldType.SMALL_TEXT,
    sub_type: SubType.CURRENCY,
    required: false,
    single_value: true,
    read_only: false,
    icon: faMoneyBillAlt,
  }),
  component: CurrencyComponent,
  input: (
    <CurrencyComponent
      id="small_text_currency"
      name="small_text_currency"
      value={currency}
      placeholder="here placeholder currency"
      onChange={(val) => (currency = val)}
    />
  ),
};

let date: Date | null = new Date();
export const DateField: IField = {
  ...plainToClass(IField, {
    label: "Fecha - etiqueta",
    name: "fecha_nombre",
    size: 12,
    field_type: FieldType.DATE,
    required: false,
    single_value: true,
    read_only: false,
    icon: faCalendarDay,
  }),
  component: DateComponent,
  input: (
    <DateComponent
      id="date_input"
      value={date}
      onChange={(val: any) => (date = val)}
    />
  ),
};

let dateRage: { startDate: Date; endDate: Date | null } = {
  startDate: new Date(),
  endDate: null,
};
export const DateRangeField: IField = {
  ...plainToClass(IField, {
    label: "Rango de fechas - etiqueta",
    name: "rango_fecha_nombre",
    size: 12,
    field_type: FieldType.DATE_RANGE,
    required: false,
    single_value: true,
    read_only: false,
    icon: faCalendarDay,
  }),
  component: DateRangeComponent,
  input: (
    <DateRangeComponent
      id="date_input"
      value={dateRage}
      onChange={(val: any) => (dateRage = val)}
    />
  ),
};

let document = "";
export const documentField: IField = {
  ...plainToClass(IField, {
    label: "Documento - etiqueta",
    name: "documento_nombre",
    size: 12,
    field_type: FieldType.SMALL_TEXT,
    sub_type: SubType.DOCUMENT,
    required: false,
    single_value: true,
    read_only: false,
    icon: faIdCard,
  }),
  component: DocumentComponent,
  input: (
    <DocumentComponent
      id="small_text_document"
      name="small_text_document"
      value={document}
      placeholder="here placeholder document"
      onChange={(val) => (document = val)}
    />
  ),
};

let email = "";
export const emailField: IField = {
  ...plainToClass(IField, {
    label: "Email - etiqueta",
    name: "email_nombre",
    size: 12,
    field_type: FieldType.SMALL_TEXT,
    sub_type: SubType.EMAIL,
    required: false,
    single_value: true,
    read_only: false,
    icon: faAt,
  }),
  component: EmailComponent,
  input: (
    <EmailComponent
      id="small_text_email"
      name="small_text_email"
      value={email}
      placeholder={"here placeholder email"}
      onChange={(val) => (email = val)}
    />
  ),
};

export const formGroup: IField = plainToClass(IField, {
  label: "Grupo - etiqueta",
  name: "grupo_nombre",
  size: 12,
  field_type: FieldType.FORM_GROUP,
  required: false,
  single_value: true,
  read_only: false,
  icon: faLayerGroup,
});

let largeText = "";
export const largeTextField: IField = {
  ...plainToClass(IField, {
    label: "Texto largo - etiqueta",
    name: "texto_largo_nombre",
    size: 12,
    field_type: FieldType.LARGE_TEXT,
    required: false,
    single_value: true,
    read_only: false,
    icon: faCommentAlt,
  }),
  component: LargeTextComponent,
  input: (
    <LargeTextComponent
      id="large_text_default"
      name="large_text_default"
      value={largeText}
      placeholder={"here placeholder email"}
      onChange={(val) => (largeText = val ?? "")}
    />
  ),
};

// let multiFile: File[] = [];
export const MultiFileField: IField = {
  ...plainToClass(IField, {
    label: "Archivos - etiqueta",
    name: "archivos_nombre",
    size: 12,
    field_type: FieldType.FILE,
    required: false,
    single_value: false,
    read_only: false,
    icon: faCopy,
  }),
  component: MultiFileComponent,
  input: <div />,
};

export const multiSelectField: IField = {
  ...plainToClass(IField, {
    label: "Selector Múltiple - etiqueta",
    name: "selector_multiple_nombre",
    size: 12,
    field_type: FieldType.OPTIONS,
    sub_type: SubType.SELECT,
    required: false,
    single_value: false,
    read_only: false,
    icon: faListAlt,
  }),
  component: MultiSelectComponent,
  input: (
    <MultiSelectComponent
      id="multi_select_input"
      name="multi_select_input"
      options={[]}
      value={[]}
      placeholder="here placeholder multi select"
      onChange={() => null}
    />
  ),
};

export const multiInputField: IField = {
  ...plainToClass(IField, {
    label: "Input multiple",
    name: "input_multiple_nombre",
    size: 12,
    field_type: FieldType.OPTIONS,
    sub_type: SubType.INPUT,
    required: false,
    single_value: false,
    read_only: false,
    icon: faListAlt,
  }),
  component: MultiInputComponent,
  input: undefined,
};

let number = "";
export const numberField: IField = {
  ...plainToClass(IField, {
    label: "Número - etiqueta",
    name: "número_nombre",
    size: 12,
    field_type: FieldType.SMALL_TEXT,
    sub_type: SubType.NUMBER,
    required: false,
    single_value: true,
    read_only: false,
    icon: faHashtag,
  }),
  component: NumberComponent,
  input: (
    <NumberComponent
      id="number_input"
      name="number_input"
      value={number}
      register={undefined}
      onChange={(val) => (number = val.toString())}
      placeholder="here placeholder number"
    />
  ),
};

let password = "";
export const passwordField: IField = {
  ...plainToClass(IField, {
    label: "Contraseña - etiqueta",
    name: "contrasena_nombre",
    size: 12,
    field_type: FieldType.SMALL_TEXT,
    sub_type: SubType.PASSWORD,
    required: false,
    single_value: true,
    read_only: false,
    icon: faKey,
  }),
  component: PasswordComponent,
  input: (
    <PasswordComponent
      id="small_text_password"
      name="small_text_password"
      value={password}
      placeholder="here placeholder password"
      onChange={(val) => (password = val)}
    />
  ),
};

let phone = "";
export const phoneField: IField = {
  ...plainToClass(IField, {
    label: "Telefono - etiqueta",
    name: "telefono_nombre",
    size: 12,
    field_type: FieldType.SMALL_TEXT,
    sub_type: SubType.PHONE,
    required: false,
    single_value: true,
    read_only: false,
    icon: faPhoneAlt,
  }),
  component: PhoneComponent,
  input: (
    <PhoneComponent
      id="small_text_phone"
      name="small_text_phone"
      value={phone}
      placeholder="here placeholder phone"
      onChange={(val) => (phone = val)}
    />
  ),
};

export const titleField: IField = {
  ...plainToClass(IField, {
    label: "Titulo - etiqueta",
    name: "titulo_nombre",
    size: 12,
    field_type: FieldType.LABEL,
    required: false,
    single_value: true,
    read_only: false,
    icon: faComment,
  }),
  component: TitleComponent,
  input: <h1>Label</h1>,
};

export const selectField: IField = {
  ...plainToClass(IField, {
    label: "Selector - etiqueta",
    name: "selector_nombre",
    size: 12,
    field_type: FieldType.OPTIONS,
    sub_type: SubType.SELECT,
    required: false,
    single_value: true,
    read_only: false,
    icon: faListAlt,
    format_rules_definition: {
      columns_labels: [
        {
          name: "test",
          label: "test",
          compound_name: "${test_1} ${test_1}",
          value: ["_KEY", "first_name", "last_name"],
        },
      ],
    },
  }),
  component: SelectComponent,
  input: (
    <SelectComponent
      id="select_input"
      name="select_input"
      options={[]}
      value={""}
      placeholder="here placeholder select"
      onChange={() => null}
    />
  ),
};

export const dynamicField: IField = {
  ...plainToClass(IField, {
    label: "Selector - etiqueta",
    name: "dynamic_field",
    size: 12,
    field_type: FieldType.OPTIONS,
    sub_type: SubType.DYNAMIC,
    required: false,
    single_value: true,
    read_only: false,
    icon: faListAlt,
  }),
  component: SelectComponent,
  input: (
    <SelectComponent
      id="select_input"
      name="select_input"
      options={[]}
      value={""}
      placeholder="here placeholder dynamic"
      onChange={() => null}
    />
  ),
};
export const selectToDoField: IField = {
  ...plainToClass(IField, {
    label: "Selector - label",
    name: "selector_list_name",
    size: 12,
    field_type: FieldType.OPTIONS,
    sub_type: SubType.TO_DO_LIST,
    required: false,
    single_value: false,
    read_only: false,
    icon: faListAlt,
    format_rules_definition: {
      columns_labels: [
        {
          name: "example_name",
          label: "example_label",
          compound_name: "${example_name}",
          value: ["example_value_1"],
        },
      ],
      sort: "example_name",
      show_date: true,
      show_percentage: true,
      allow_edit: false,
      n_to_be_completed: "all",
      full_container: true,
    },
  }),
  component: ToDoListComponent,
};

export const appointmentPickerField: IField = {
  ...plainToClass(IField, {
    label: "Appointment picker - label",
    name: "appointment_picker_name",
    size: 12,
    field_type: FieldType.APPOINTMENT_PICKER,
    required: false,
    single_value: false,
    read_only: false,
    icon: faCalendarDay,
    format_rules_definition: {
      appointment_props: {
        type: "crm_user",
        title: "Example appointment",
        account_list: ["${here_field_name}"],
        responsible: "${here_field_name}",

        //this props belongs to the appointment to associate the other entities.
        data: {
          //city and country is a model name, and label belongs to props in the model data
          city: {
            value: "${here_field_name}",
            label: "${city_name} ${city_code}",
          },
          country: {
            value: "${here_field_name}",
            label: "${country_name} ${country_code}",
          },
        },
        // is an extra values when the appointment is created example: 'object_name_start_date'
        columns_labels: [
          "start_date",
          "end_date",
          "start_time",
          "end_time",
          "type",
          "title",
          "crm_user",
        ],
        disabled_days: [0, 6],
      },
    },
  }),
  component: CalendarPickerComponent,
};

// let singleFile: File | null = null;
export const SingleFileField: IField = {
  ...plainToClass(IField, {
    label: "Archivo - etiqueta",
    name: "archivo_nombre",
    size: 12,
    field_type: FieldType.FILE,
    required: false,
    single_value: true,
    read_only: false,
    icon: faFile,
  }),
  component: SingleFileComponent,
  input: <div />,
};

let smallText = "";
export const smallTextField: IField = {
  ...plainToClass(IField, {
    label: "Texto corto - etiqueta",
    name: "texto_corto_nombre",
    size: 12,
    field_type: FieldType.SMALL_TEXT,
    required: false,
    single_value: true,
    read_only: false,
    icon: faComment,
  }),
  component: SmallTextComponent,
  input: (
    <SmallTextComponent
      id="small_text_default"
      name="small_text_default"
      value={smallText}
      placeholder={"here placeholder default"}
      onChange={(val) => (smallText = val)}
    />
  ),
};

let table: { [key: string]: any } = {};
export const tableField: IField = {
  ...plainToClass(IField, {
    label: "Table -label",
    name: "tabla_nombre",
    size: 12,
    field_type: FieldType.TABLE,
    required: false,
    single_value: false,
    read_only: false,
    icon: faTable,
    format_rules_definition: {
      columns_labels: [],
    },
  }),
  component: TableComponent,
  input: (
    <TableComponent
      id="table"
      name="table"
      value={table}
      fields={[]}
      onChange={(val) => (table = val)}
    />
  ),
};

let container: { [key: string]: any } = {};
export const fieldsContainer: IField = {
  ...plainToClass(IField, {
    label: "Grupo - etiqueta",
    name: "grupo_nombre",
    size: 1,
    field_type: FieldType.FORM_GROUP,
    icon: faGripHorizontal,
    single_value: true,
  }),
  component: FormGroupComponent,
  input: (
    <FormGroupComponent
      value={container}
      onChange={(e) => (container = e)}
      count={2}
      fields={[]}
    />
  ),
};
let time = "";
export const timeField: IField = {
  ...plainToClass(IField, {
    label: "Hora - etiqueta",
    name: "hora_nombre",
    size: 12,
    field_type: FieldType.SMALL_TEXT,
    sub_type: SubType.TIME,
    required: false,
    single_value: true,
    read_only: false,
    icon: faClock,
  }),
  component: TimeComponent,
  input: (
    <TimeComponent
      id="small_text_time"
      name="small_text_time"
      value={time}
      placeholder="here placeholder time"
      onChange={(val) => (time = val)}
    />
  ),
};

let check = null;
export const checkboxField: IField = {
  ...plainToClass(IField, {
    label: "Checkbox - etiqueta",
    name: "checkbox_nombre",
    size: 1,
    field_type: FieldType.OPTIONS,
    sub_type: SubType.TOGGLE,
    required: false,
    single_value: false,
    read_only: false,
    icon: faCheckCircle,
  }),
  component: CheckboxComponent,
  input: (
    <CheckboxComponent
      id={"checkbox__"}
      name={"checkbox"}
      value={check}
      onChange={(e) => (check = e)}
      options={[]}
    />
  ),
};

let radio = null;
export const radioField: IField = {
  ...plainToClass(IField, {
    label: "Radio - etiqueta",
    name: "radio_nombre",
    size: 1,
    field_type: FieldType.OPTIONS,
    sub_type: SubType.TOGGLE,
    required: false,
    single_value: true,
    read_only: false,
    icon: faDotCircle,
  }),
  component: RadioButtonComponent,
  input: (
    <RadioButtonComponent
      id={"radio__"}
      name={"radio"}
      value={radio}
      onChange={(e) => (radio = e)}
      options={[]}
    />
  ),
};
