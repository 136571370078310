import React, {useContext, useState} from 'react';
import {State} from '../../../types/State';
import {uuidV4} from '../../../utils';
import {Source} from '../../../types/Analytic';
import {mergeTypeOptions, UpdateSource} from './SourceComponent';
import {ReportGeneratorContext} from '../ReportGeneratorComponent';
import {StringOption} from '../../../types/Select';
import useTranslate from "../../../hooks/useTranslate";
import CreatableSelectComponent from "../../Shared/FieldComponents/CreatableSelectComponent";

type Props = {
  source: Source
  updateSource: (sources: UpdateSource[]) => void
  indexColumns: StringOption[]
  mainColumns: StringOption[]
  sourceIndexColumn?: string,
  sourceMainColumn?: string
  sourceMergeType?: string
  isMergeSource?: boolean // From source and not from actions
}

const SourceMergeColumnsComponent = ({
                                       source,
                                       updateSource,
                                       indexColumns,
                                       mainColumns,
                                       sourceIndexColumn,
                                       sourceMainColumn,
                                       sourceMergeType, isMergeSource
                                     }: Props) => {
  const {reportState: {isLoading}} = useContext(ReportGeneratorContext);
  const [indexColumn, setIndexColumn] = useState('');
  const [mainColumn, setMainColumn] = useState('');
  const [mergeType, setMergeType] = useState('');
  const {t} = useTranslate('report');

  React.useEffect(() => {
    if (sourceIndexColumn) {
      setIndexColumn(sourceIndexColumn);
    } else {
      if (source.index_column) {
        setIndexColumn(source.index_column);
      }
    }
  }, [sourceIndexColumn, source.index_column]);

  React.useEffect(() => {
    if (sourceMainColumn) {
      setMainColumn(sourceMainColumn);
    } else {
      if (source.main_column) {
        setMainColumn(source.main_column);
      }
    }
  }, [sourceMainColumn, source.main_column]);

  React.useEffect(() => {
    if (sourceMergeType) {
      setMergeType(sourceMergeType);
    } else {
      if (source.merge_type) {
        setMergeType(source.merge_type);
      }
    }
  }, [sourceMergeType, source.merge_type]);

  return (
    <>
      {!isMergeSource && <div className="col-xs-6 col-sm-3 col-md-4 col-xxl-3">
        <label>{t("merge-type")}: </label>
        <CreatableSelectComponent menuPosition={'fixed'} loading={isLoading === State.PENDING}
                         disabled={isLoading === State.PENDING} id={uuidV4()} name={''}
                         value={mergeType ? {
                           label: mergeType,
                           value: mergeType
                         } : null} onChange={evt => {
          updateSource([{
            sourceKey: 'merge_type',
            sourceValue: evt.value,
            sourceId: source.temporal_id
          }]);

          setMergeType(evt.value);
        }}
                         options={mergeTypeOptions}
                         placeholder={'MergeType'}/>
      </div>}

      <div className="col-xs-6 col-sm-3 col-md-4 col-xxl-4">
          <label>{t("index-column")}: </label>
        <CreatableSelectComponent menuPosition={'fixed'} loading={isLoading === State.PENDING}
                         disabled={!source?.with || isLoading === State.PENDING} id={uuidV4()} name={''}
                         value={indexColumn ? {
                           label: indexColumn,
                           value: indexColumn
                         } : null} onChange={evt => {

          updateSource([{
            sourceKey: 'index_column',
            sourceValue: evt.value,
            sourceId: source.temporal_id
          }]);
          setIndexColumn(evt.value);
        }

        }
                         options={indexColumns} placeholder={t("index-column")}/>
      </div>

      <div className="col-xs-6 col-sm-3 col-md-4 col-xxl-4">
        <label>{t("main-column")}: </label>
        <CreatableSelectComponent menuPosition={'fixed'} loading={isLoading === State.PENDING}
                         disabled={isLoading === State.PENDING} id={uuidV4()} name={''}
                         value={mainColumn ? {
                           label: mainColumn,
                           value: mainColumn
                         } : null} onChange={evt => {
          updateSource([{
            sourceKey: 'main_column',
            sourceValue: evt.value,
            sourceId: source.temporal_id
          }]);
          setMainColumn(evt.value);
        }}
                         options={mainColumns}
                         placeholder={t("main-column")}/>
      </div>
    </>
  );
};

export default SourceMergeColumnsComponent;
