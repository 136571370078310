import * as React from 'react';
import {useState} from 'react';
import {BaseAnalyticQuery, Report, SourceFrom} from "../../types/Analytic";
import useMemoAsync from "../../hooks/useMemoAsync";
import {IsJsonString, toast} from "../../utils";
import {useSelector} from "react-redux";
import {authReducer} from "../../store/Selectors";
import {State} from "../../types/State";
import ButtonComponent from "../Shared/ButtonComponent";
import ColumnsActionsWrapperComponent from "../NewReportGeneratorComponent/ColumnsActionsWrapperComponent";
import {DropdownItem} from "reactstrap";
import ActionDropdownColumns
    from "../NewReportGeneratorComponent/NewActionsReportGeneratorComponent/ActionDropdownComponent/ActionDropdownColumns";
import useTranslate from "../../hooks/useTranslate";
import {faChartLine} from "@fortawesome/free-solid-svg-icons";
import {baseQuery} from "../NewReportGeneratorComponent/Slice";
import {getAnalyticJsonColumns} from "../../services/backend/AnalyticsService";
import {getCrmReports} from "../../services/backend/ReportServices";

type Props = {
    query: BaseAnalyticQuery
    data?: any[]
    launchQuery: (report: Report) => void
    loadingData: State
    report?: Report
    trainingStatus: string,
    setTrainingStatus: (status: string) => void,
    predictInfo?: { [key: string]: any }
};

const statusMessage: {
    [key: string]: string
} = {
    "training model": 'please_wait_a_few_minutes_before_you_try_again',
    "Error when trying to train model": "check_the_training_data_before_you_try_to_train_again",
    "Error when trying to predict": "check_the_prediction_data_before_you_try_again"
}

const translateStatus: {
    [key: string]: string
} = {
    "training model": 'training_model',
    "Error when trying to train model": "error_when_trying_to_train_model",
    "Error when trying to predict": "error_when_trying_to_predict"
}

const ReportAsyncMlComponent = ({
                                    query,
                                    data,
                                    loadingData,
                                    launchQuery,
                                    predictInfo,
                                    report,
                                    setTrainingStatus,
                                    trainingStatus
                                }: Props) => {
    const {user} = useSelector(authReducer)
    const {t} = useTranslate('report')
    const [fileName, setFileName] = useState("");
    const [loading, setLoading] = useState(State.IDLE)
    const [selectedReport, setSelectedReport] = useState<Report | undefined>(undefined)
    React.useEffect(() => {
        setLoading(loadingData)
    }, [loadingData]);

    const [csvData, setCsvData] = useState<any[]>([])
    const reports: Report[] = useMemoAsync(async () => {
        setLoading(State.PENDING)
        const response = await getCrmReports(user);
        if (response && response.length > 0) {
            setLoading(State.RESOLVED)
            return response as Report[]
        }
        setLoading(State.RESOLVED)

        return []
    }, [query]);

    const checkMissingColumns = (trainColumns: string[], predictColumns: string[]) => {
        if (predictColumns.length !== trainColumns.length) {
            const setB = new Set(predictColumns);
            const missingColumns = trainColumns.filter(element => !setB.has(element))
            // console.log('missingColumns', missingColumns)

            if (missingColumns.length > 0) {
                setLoading(State.RESOLVED)
                toast({
                    message: `${t("missing_columns_in_prediction_data")} ${missingColumns.join(', ')}`,
                    type: "error"
                })
                return
            }
        }
    }

    const handleTrain = async (type: 'train' | 'predict') => {
        const mlActionIndex = query.actions.findIndex(action => action.type === 'async_ml')
        if (mlActionIndex !== -1) {
            // setTypeAsync(type)
            const actions = query.actions.slice(mlActionIndex,)
            actions[0] = {
                ...actions[0],
                subtype: type,
            }

            let trainColumns: string[] = []
            let predictColumns: string[] = []

            setLoading(State.PENDING)
            const responseColumnsTrain = await getAnalyticJsonColumns({
                ...query,
                actions: query.actions.slice(0, mlActionIndex)
            })

            if (responseColumnsTrain?.items) {
                trainColumns = responseColumnsTrain?.items
            }

            if (selectedReport) {

                setTrainingStatus('starting_prediction')
                let selectedReportQuery = IsJsonString(selectedReport.query) ? JSON.parse(selectedReport.query) : null
                if (selectedReportQuery) {
                    const responseColumnsPredict = await getAnalyticJsonColumns({
                        ...selectedReportQuery,
                        actions: selectedReportQuery.actions
                    })

                    if (responseColumnsPredict?.items) {
                        predictColumns = responseColumnsPredict?.items
                    }

                    checkMissingColumns(trainColumns, predictColumns)

                    selectedReportQuery = {
                        ...selectedReportQuery,
                        actions: [...selectedReportQuery.actions, ...actions]
                    }

                    launchQuery({...selectedReport, query: JSON.stringify(selectedReportQuery)})
                }
            }

            if (csvData.length > 0 && report) {
                predictColumns = Object.keys(csvData[0])
                checkMissingColumns(trainColumns, predictColumns)

                setTrainingStatus('starting_prediction')
                const newQuery: BaseAnalyticQuery = {
                    source: {
                        ...baseQuery,
                        from: SourceFrom.JSON,
                        data: csvData
                    },
                    actions: [...(actions ?? [])]
                }
                launchQuery({...report, query: JSON.stringify(newQuery)})
            }

            if (!selectedReport && csvData.length === 0) {
                toast({
                    message: t(`select_a_report_or_csv_file`),
                    type: "info"
                })
            }

        }
    }

    const processData = (data: string) => {
        setSelectedReport(undefined)
        const list = [];
        const dataStringLines = data.split(/\r\n|\n/);
        const headers = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
        for (let i = 1; i < dataStringLines.length; i++) {
            const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
            if (headers && row.length == headers.length) {
                const obj: any = {};
                for (let j = 0; j < headers.length; j++) {
                    let d = row[j];
                    if (d.length > 0) {
                        if (d[0] == '"')
                            d = d.substring(1, d.length - 1);
                        if (d[d.length - 1] == '"')
                            d = d.substring(d.length - 2, 1);
                    }
                    if (headers[j]) {
                        obj[headers[j]] = d;
                    }
                }
                if (Object.values(obj).filter(x => x).length > 0) {
                    list.push(obj);
                }
            }
        }
        setCsvData(list)
    }

    const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        try {
            if (e.target?.files) {
                const file = e.target.files[0];
                const reader = new FileReader();
                reader.onload = (evt: ProgressEvent<FileReader>) => {
                    /* Parse data */
                    if (evt?.target?.result) {
                        const bstr = evt.target.result;
                        const XLSX = require("xlsx");

                        const wb = XLSX.read(bstr, {type: 'binary'});
                        /* Get first worksheet */
                        const wsname = wb.SheetNames[0];
                        const ws = wb.Sheets[wsname];
                        /* Convert array of arrays */
                        const data = XLSX.utils.sheet_to_csv(ws);
                        setFileName(file.name || "");
                        processData(data);
                    }
                };
                reader.readAsText(file, "UTF-8");
                reader.readAsBinaryString(file);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const flattenObject = (obj: { [key: string]: any }, prefix = '') => {
        return obj ? Object.keys(obj).reduce((acc: { [key: string]: any }, key) => {
            if (!["model_type", "status", "model_explanation", "data", "model_metadata"].includes(key)) {
                return acc
            }
            const pre = prefix.length ? prefix + '.' : '';
            if (typeof obj[key] === 'object') {
                Object.assign(acc, flattenObject(obj[key], pre + key));
            } else {
                acc[pre + key] = obj[key];
            }
            return acc;
        }, {}) : {};
    };

    return (

        <div className="bg-white p-3 d-flex flex-column">

            <div className="d-flex align-items-center gap-2">


                <div className="border p-2">
                    <label className="btn btn-light btn-sm" htmlFor="file-upload">{t("common:upload_file")}</label>
                    {fileName && <b className="ms-2">{fileName}</b>}
                    <input
                        disabled={loading === State.PENDING}
                        id="file-upload"
                        className="d-none"
                        type="file"
                        accept=".csv"
                        onChange={e => handleFileUpload(e)}
                    />
                </div>

                <span>
                    {t("common:or")}
                </span>

                <ActionDropdownColumns columns={[]} color={"#5e72e4"} loading={loading === State.PENDING}
                                       label={selectedReport?.name ?? `${t("select")} ${t("common:report")}`}
                                       onChange={() => {
                                       }}>
                    <ColumnsActionsWrapperComponent>
                        {search => {
                            return <>
                                {reports?.filter(report => report.name.toLowerCase().includes(search.toLowerCase())).map((report, indexColumn) => {
                                    return <DropdownItem onClick={() => {
                                        setSelectedReport(report)
                                        setCsvData([])
                                        setFileName('')
                                    }} key={report.name + "_" + indexColumn}>
                                        {report.name}
                                    </DropdownItem>
                                })}
                            </>
                        }}
                    </ColumnsActionsWrapperComponent>
                </ActionDropdownColumns>

                <ButtonComponent disabled={loading === State.PENDING}
                                 loading={loading === State.PENDING && data?.length === 0}
                                 icon={faChartLine} label={t('predict')} color={'primary'} onClick={() => {
                    handleTrain('predict')
                }}/>
            </div>


            {((predictInfo && Object.keys(predictInfo).length > 0) || data && data?.length > 0 || trainingStatus) &&
                <div className="my-2">
                    <div className="table-responsive">
                        <table className="table table-bordered">
                            <thead>
                            <tr>
                                {predictInfo && Object.keys(predictInfo).length > 0 ? Object.keys(flattenObject(predictInfo)).map((key, index) => (
                                        <th key={index}>{key.split(".").at(-1) ? t(key.split(".").at(-1) ?? "") : key}</th>
                                    )) :
                                    <th>
                                        {t("common:status")}
                                    </th>
                                }
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                {predictInfo && Object.keys(predictInfo).length > 0 ? Object.values(flattenObject(predictInfo)).map((value, index) => (
                                    <td key={index}>{t(translateStatus[value.toLowerCase()] ?? value)} {typeof value === "string" ? statusMessage[value.toLowerCase()] ? ` - ${t(statusMessage[value.toLowerCase()])}` : "" : ""}</td>
                                )) : <td>
                                    {t(trainingStatus)}
                                </td>}
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>}

        </div>
    );
};

export default ReportAsyncMlComponent