import * as React from 'react';
import {Fragment, useState} from 'react';
import {Item} from "./TableCustomColumnMenuComponent";
import {usePopper} from "react-popper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";

type Props = {
    item: Item
    isOpen: boolean
    renderMenuItem: (props: { item: Item, isChild?: boolean }) => React.ReactNode
    toggleItem: (key: string, isChild?: boolean) => void
    isChild?: boolean
};
const TableColumnsListChildren = ({item, renderMenuItem, isOpen, toggleItem, isChild}: Props) => {

    const [referenceElement, setReferenceElement] = useState<HTMLLIElement | null>(null);
    const [popperElement, setPopperElement] = useState<any | null>(null);
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement: 'auto',
        strategy: "fixed"
    });

    const hasChildren = item.children && item.children.length > 0;

    return <li key={item.key} ref={setReferenceElement} className={`menu-item fw-normal shading list-group-item p-0 ${hasChildren ? 'has-children' : ''}`}>
        <div
            className={`btn menu-toggle d-flex justify-content-between align-items-center gap-2 pointer ${isOpen ? 'show ' : ''}`}
            onClick={() => {
                if(item.type !== "group" && hasChildren) {
                    toggleItem(item.key, isChild)
                }

                item.onClick && item.onClick()
            }}
        >

                    <span className={`${item.type === "group" ? "text-gray" : ""}`}>
                     {item.label}
                    </span>
            {hasChildren && item.type !== "group" && <FontAwesomeIcon icon={faArrowRight} className=" "/>}
        </div>



        {hasChildren && isOpen && item.type !== "group" && (
            <ul className={`list-group-flush sub-menu`}  ref={setPopperElement}
                style={{...styles.popper, zIndex: 1100}}
                {...attributes.popper}>
                {item.children!.map((child, index) => (
                    <Fragment key={child.key + "_"+index}>
                        {renderMenuItem({item: child, isChild: true})}
                    </Fragment>
                ))}
            </ul>
        )}
    </li>
};

export default TableColumnsListChildren