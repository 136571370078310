import React, {useContext} from 'react';
import {ActionType, AnalyticQuery, AnalyticQueryAction} from '../../../types/Analytic';
import {removeDuplicateFromArray, uuidV4} from '../../../utils';
import {ReportGeneratorContext, sourceBase} from '../ReportGeneratorComponent';
import {DragDropContext, Draggable, Droppable, DroppableStateSnapshot, DropResult} from "react-beautiful-dnd-next";
import ActionComponent from "./ActionComponent";
import {State} from "../../../types/State";
import {getAnalyticJsonColumns} from "../../../services/backend/AnalyticsService";
import useTranslate from "../../../hooks/useTranslate";
import styled from "styled-components";
import {
    faCalendar, faCalendarPlus,
    faChartLine,
    faCodeBranch,
    faDatabase,
    faExchangeAlt,
    faFilter,
    faHandHoldingUsd,
    faLayerGroup,
    faMousePointer,
    faPenAlt,
    faPowerOff,
    faSave,
    faSitemap,
    faSort,
    faSpinner,
    faSuperscript,
    faTable
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getAnalyticQueryFlat} from "../../../utils/analyticsUtils";


const reorder = (list: AnalyticQueryAction[][], startIndex: number, endIndex: number) => {
    const result = Array.from([...list]);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const Container = styled.div`
  display: grid;
  grid-template-columns: 0.6fr 5fr;
  gap: 15px;
  text-align: center;
`

type Props = {
    showMlActions?: boolean
}

const ActionsComponent = ({showMlActions}: Props) => {

    const {
        reportState: {analyticQuery, actionColumns, actionLoading, sourceColumns, sourceColumnsLoading},
        dispatchForm,
        getColumns,
        dispatchMultiForm, getAllActionColumns
    } = useContext(ReportGeneratorContext);
    const {t} = useTranslate('common');

    const getActionList = () => {
        if (showMlActions) {
            return [
                {label: t('report:forecast'), value: 'forecast', icon: faChartLine},
                {label: t('report:monetary_segmentation'), value: 'monetary_segmentation', icon: faHandHoldingUsd},
                {label: t('report:segmentation'), value: 'segmentation', icon: faCodeBranch},
                {label: t('report:binary_classifier'), value: 'binary_classifier', icon: faSitemap},
            ]
        }

        return [
            {label: t('report:limit'), value: 'limit', icon: faSuperscript},
            {label: t('report:sort'), value: 'sort', icon: faSort},
            {label: t('report:rename'), value: 'rename', icon: faPenAlt},
            {label: t('report:select'), value: 'select', icon: faMousePointer},
            {label: t('report:group_by'), value: 'group_by', icon: faLayerGroup},
            {label: t('report:pivot'), value: 'pivot', icon: faTable},
            {label: t('report:data-transformation'), value: 'data_transform', icon: faExchangeAlt},
            {label: t('report:date_formatter'), value: 'date_formatter', icon: faCalendar},
            {label: t('report:date_operations'), value: 'date_operations', icon: faCalendarPlus},
            {label: t('report:filter'), value: 'filter', icon: faFilter},
            {label: t('report:default_values'), value: 'default_values', icon: faPowerOff},
            {label: t('report:merge'), value: 'merge', icon: faDatabase},
            {label: t('save'), value: 'save', icon: faSave},
            {label: t("report:drill_down"), value: 'drill_down', icon: faTable},
        ]
    }

    const getDefaultAction = (actionType: ActionType) => {

        let baseAction: AnalyticQueryAction = {type: actionType, temporal_id: uuidV4()};

        const action: { [action_type: string]: AnalyticQueryAction } = {
            limit: {
                ...baseAction,
                top: 10
            },
            sort: {
                ...baseAction,
                columns: [],
                ascending: true
            },
            select: {
                ...baseAction,
                columns: []
            },
            rename: {
                ...baseAction,
                renamed_columns: {}
            },
            group_by: {
                ...baseAction,
                agg: {},
                columns: []
            },
            drill_down: {
                ...baseAction,
                type: "group_by",
                subtype: "drill_down",
                agg: {},
                hierarchy: [],
                columns: []
            },
            pivot: {
                ...baseAction,
                agg: {},
                index_columns: [],
                columns: []
            },
            data_transform: {
                ...baseAction,
                type: "transform",
                subtype: "data_transform",
                filter: "*",
                name: "",
                transformation: ""
            },
            date_formatter: {
                ...baseAction,
                type: "transform",
                filter: "*",
                subtype: "date_formatter",
                name: "",
                transformation: ""
            },
            date_operations: {
                ...baseAction,
                type: "transform",
                subtype: "date_operations",
                filter: "*",
                name: "",
                transformation: ""
            },
            filter: {
                ...baseAction,
                filter: ""
            },
            default_values: {
                ...baseAction,
                columns: {}
            },
            merge: {
                ...baseAction,
                main_column: "",
                index_column: "",
                merge_type: "inner",
                source: sourceBase,
                actions: []
            },
            forecast: {
                ...baseAction,
                type: "ml",
                subtype: "forecast",
                x: "",
                y: "",
                forecast_from: "",
                forecast_to: "",
                seasonal: 12,
                domain: 0
            },
            monetary_segmentation: {
                ...baseAction,
                type: "ml",
                subtype: "monetary_segmentation"
            },
            segmentation: {
                ...baseAction,
                type: "ml",
                subtype: "segmentation",
                columns: [],
                n_segments: 0,
                id: ''

            },
            binary_classifier: {
                ...baseAction,
                type: "ml",
                subtype: "binary_classifier",
                columns: [],
                target: ""
            },
            save: {
                ...baseAction,
                with: "",
                in: 0
            },

        }


        const query = {...analyticQuery};
        query.actions = [...analyticQuery.actions, [action[baseAction.type]]];
        dispatchForm({name: 'analyticQuery', value: query});
        dispatchMultiForm([
            {name: 'analyticQuery', value: query},
            {name: 'actionLoading', value: {...actionLoading, [baseAction.temporal_id ?? ""]: State.IDLE}},
        ])

        getColumns({analyticQuery: query, action: action[baseAction.type]});
    };

    const onDragEnd = (result: DropResult) => {
        if (!result.destination) {
            return;
        }

        if (result.destination.droppableId === result.source.droppableId && result.destination.droppableId !== "actions") {
            return;
        }

        if (analyticQuery.actions.length > 0 && result.destination.droppableId === result.source.droppableId) {

            const actions = reorder(
                [...analyticQuery.actions],
                result.source.index,
                result.destination.index
            );

            const query = {...analyticQuery, actions}
            dispatchForm({name: 'analyticQuery', value: query});
            getAllActionColumns(query)
        } else {
            getDefaultAction(result.draggableId as ActionType)
        }


    }

    const getColumnsByAction = async ({
                                          action,
                                          nQuery
                                      }: { action: AnalyticQueryAction, nQuery?: AnalyticQuery }) => {
        let newQuery = getAnalyticQueryFlat(nQuery ?? analyticQuery)

        dispatchForm({name: "actionLoading", value: {...actionLoading, [action.temporal_id ?? ""]: State.PENDING}})

        const index = newQuery.actions.findIndex(nAction => nAction.temporal_id === action.temporal_id);
        const query = {...newQuery, actions: newQuery.actions.slice(0, index)};
        const data = await getAnalyticJsonColumns(query);
        let newActionColumns = {}

        if (data?.items && data.items.length > 0 && action.temporal_id) {
            data.items = removeDuplicateFromArray(data.items.filter(column => column));

            newActionColumns = {
                ...actionColumns,
                [action.temporal_id]: data.items
            };


        }


        dispatchMultiForm([
            {name: 'actionColumns', value: newActionColumns},
            {name: "actionLoading", value: {...actionLoading, [action.temporal_id ?? ""]: State.RESOLVED}}
        ])
    }

    const getColumsLoading = () => {

        return <div className="d-flex align-items-center gap-2 justify-content-center">
            <FontAwesomeIcon pulse icon={faSpinner} size={"2x"}/>
            <span className="fw-bold">{t("loading")} {t("columns")}...</span>
        </div>


    }

    return (
        <Container>
            <DragDropContext onDragEnd={onDragEnd}>

                <div className="border border-primary " style={{maxHeight: '70vh', overflowY: "auto"}}>
                    <div className="card-header">
                        <span>{t("action_list")}</span>
                    </div>
                    <Droppable droppableId="action_list" isDropDisabled={Object.keys(sourceColumns).length === 0}>
                        {provided => (
                            <div key={"droppable_action_list"} ref={provided.innerRef} {...provided.droppableProps}>
                                {getActionList().map((action, index) => {
                                    return <Draggable key={action.value + "_" + index} draggableId={action.value}
                                                      index={index}>
                                        {provided => (
                                            <div key={action.value}
                                                 className="p-2 d-flex flex-column align-items-center"
                                                 style={{border: "1px solid ghostwhite"}}
                                                 ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                {action.icon && <FontAwesomeIcon icon={action.icon} size={"2x"}/>}
                                                <span>{action.label}</span>
                                            </div>
                                        )}
                                    </Draggable>
                                })}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </div>

                <div className="card">
                    <div
                        className="card-header d-flex align-items-center justify-content-between gap-1 border-bottom p-2">
                        <span>{t("actions")}</span>
                    </div>
                    <Droppable droppableId="actions" isDropDisabled={Object.keys(sourceColumns).length === 0}>
                        {(provided, snapshot: DroppableStateSnapshot) => (
                            <div key={"actions_list"} className="d-flex flex-column gap-2 px-2 overflow-auto py-4"
                                 style={{
                                     maxHeight: '70vh',
                                     minHeight: '50vh',
                                     border: snapshot.isDraggingOver ? "5px dotted #5e72e4" : "",
                                     margin: snapshot.isDraggingOver ? "5px" : ""
                                 }}
                                 ref={provided.innerRef} {...provided.droppableProps}>


                                {(Object.keys(sourceColumns).length === 0 || sourceColumnsLoading === State.PENDING) ?
                                    <div className="text-center">
                                        {sourceColumnsLoading === State.PENDING ? getColumsLoading() :
                                            <h3 className="text-lightgray">{t("select_source_before_action")}</h3>
                                        }
                                    </div> : (analyticQuery.actions.length === 0 || snapshot.isDraggingOver) &&
                                    <div className="text-center">
                                        <h3 className="text-lightgray">{t("drag_and_drop_action_here")}</h3>
                                    </div>}

                                {(sourceColumnsLoading !== State.PENDING) && analyticQuery.actions.flat().filter(action => !action.dependency_action_id).map((action, index) => (
                                    <Draggable draggableId={"draggable_id_" + action.type + '_' + index} index={index}
                                               key={"draggable_key_" + action.type + '_' + index}>
                                        {provided => (
                                            <div
                                                ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                <ActionComponent key={action.type + '_' + index} action={action}
                                                                 index={index}
                                                                 getColumnsByAction={() => getColumnsByAction({action})}/>

                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </div>

            </DragDropContext>
        </Container>
    );
};

export default ActionsComponent;
