import * as React from 'react';
import {useContext, useState} from 'react';
import styled from "styled-components";
import {Control, Controller, UseFormRegister, UseFormWatch} from "react-hook-form";
import {TruncateReport} from "../../types/Analytic";
import CreatableSelectComponent from "../Shared/FieldComponents/CreatableSelectComponent";
import useTranslate from "../../hooks/useTranslate";
import {capitalize} from "../../utils";
import {StringOption} from "../../types/Select";
import {ReportContext} from "./NewReportGeneratorComponent";

const ContainerInputs = styled.div`
  display: grid;
  grid-template-columns: 16em 16em 10em 10em;
  flex-grow: 1;
  gap: 10px;
  border: 1px dashed lightgray;
  padding: 10px;
  margin: 10px;
  @media (max-width: 799px) {
    grid-template-columns: 1fr;
  }


`
type FormValues = {
    truncate: TruncateReport,
    others: TruncateReport[]
}

type Props = {
    name: "truncate" | `others.${number}`
    watch: UseFormWatch<FormValues>
    control: Control<FormValues, any>
    // ranges?: StringOption[]
    register: UseFormRegister<FormValues>
    // loading: State
};

const typeList = ["year", "month", "day"]

const defaultRangeOption: (StringOption & { name: string })[] = [
    {"label": "None", value: "", name: "none"},
    {"label": "Today", value: "${today}", name: "common:today"},
    // {"label": "Tomorrow", value: "${tomorrow}", name: "common:tomorrow"},
    // {"label": "Yesterday", value: "${yesterday}", name: "common:yesterday"},
]

const TruncateRelativeComponent = ({name, control, register}: Props) => {
    const {t} = useTranslate("report");

    const [rangeOptions, setRangeOptions] = useState<StringOption[]>([])

    const {query, mainQuery} = useContext(ReportContext)

    React.useEffect(() => {

        let options: StringOption[] = []

        if ((query.truncate as TruncateReport)?.name && name.includes("others")) {
            const name = (query.truncate as TruncateReport)?.name ?? ""
            options.push({
                label: name,
                value: name
            })
        }

        if (mainQuery?.actions && mainQuery?.actions.length > 0 && mainQuery.actions.flat().some(action => action.type === "merge")){
            mainQuery.actions.flat().forEach(action => {
                if (action.type === "merge" && action.truncate && (action.truncate as TruncateReport)?.name ) {
                    options.push({
                        label: (action.truncate as TruncateReport).name ?? "",
                        value: (action.truncate as TruncateReport).name ?? ""
                    })
                }
            })
        }

        if (mainQuery?.truncate && (mainQuery?.truncate as TruncateReport).name) {
            options.push({
                label: (mainQuery?.truncate as TruncateReport).name ?? "",
                value: (mainQuery?.truncate as TruncateReport).name ?? ""
            })
        }



        setRangeOptions([...defaultRangeOption.map(option => ({
            label: option.name ? t(`${option.name}`) : option.label,
            value: option.value
        })), ...options.sort((a, b) => a.label.localeCompare(b.label))])


    }, [query, mainQuery]);

    return (
        <ContainerInputs className="py-3">
            <div className="d-flex flex-column">
                <span>{t("relative_to")}</span>
                <Controller defaultValue={""} render={({field}) => {
                    return <CreatableSelectComponent
                        value={field.value ? {
                            label: rangeOptions.find(option => option.value === field.value)?.label,
                            value: field.value
                        } : null}
                        sortOptions={false}
                        placeholder={t("common:select_placeholder")}
                        // disabled={State.PENDING === loading}
                        // loading={State.PENDING === loading}
                        name={`${name}.range`}
                        options={rangeOptions ?? []}
                        onChange={evt => {
                            field.onChange(evt?.value)
                        }}/>
                }} name={`${name}.range`}
                            control={control}/>
            </div>

            <div className="d-flex flex-column">
                <span>{t("relative_type")}</span>
                <Controller defaultValue={'month'} render={({field}) => {
                    return <CreatableSelectComponent
                        value={field.value ? {
                            label: capitalize(t(field.value)),
                            value: field.value
                        } : null}
                        placeholder={t("common:select_placeholder")}
                        // disabled={State.PENDING === loading}
                        // loading={State.PENDING === loading}
                        name={`${name}.relative_type`}
                        options={typeList.map(type => ({
                            label: capitalize(t(`${type}`)),
                            value: type
                        }))}
                        onChange={evt => {
                            field.onChange(evt?.value)
                        }}/>
                }} name={`${name}.relative_type`} rules={{required: true}}
                            control={control}/>
            </div>


            <div className="d-flex flex-column">
                <span>{t("relative_value_from")}</span>
                <input type="number" placeholder={t("relative_value_from")}
                       className="form-control" {...register(`${name}.relative_value_from`, {required: true})}/>
            </div>

            <div className="d-flex flex-column">
                <span>{t("relative_value_to")}</span>
                <input type="number" placeholder={t("relative_value_to")}
                       className="form-control" {...register(`${name}.relative_value_to`, {required: true})}/>
            </div>
        </ContainerInputs>
    );
};

export default TruncateRelativeComponent