import {Config, UserData} from "../../types/User";

export const AuthActions = {
  AUTHENTICATE: "auth/login",
  AUTHENTICATE_WITH_LINK: "auth/loginWithLink",
  AUTHENTICATE_AS: "auth/login_as",
  ME: "auth/me",
  FORGOT_PASSWORD: "auth/forgotPassword",
  RECOVER_PASSWORD: "auth/recoverPasswordWithCode",
  VERIFICATION_CODE: "auth/verificationCode",
  ACTIVATE_MFA_EMAIL: "auth/verificationCodeActivate",
  GET_CODE_TO_ACTIVATE: "auth/generateCode",
  GET_LINK_TO_USER: "auth/generateLinkToUser"
}

export enum AuthStates {
  AUTHENTICATING = "AUTHENTICATING",
  AUTHENTICATED = "AUTHENTICATED",
  MFA_VERIFICATION = "MFA_VERIFICATION",
  START_FORGOT_PASSWORD = "START_FORGOT_PASSWORD",
  FORGOT_PASSWORD_SEND = "FORGOT_PASSWORD_SEND",
  FORGOT_PASSWORD_FAILED = "FORGOT_PASSWORD_FAILED",
  START_RECOVER_PASSWORD = "START_RECOVER_PASSWORD",
  PASSWORD_RECOVERED = "PASSWORD_RECOVERED",
  RECOVER_FAILED = "RECOVER_FAILED",
  AUTHENTICATION_FAILED = "AUTHENTICATION_FAILED",
  IDLE = "IDLE",
  GENERATING_CODE = "GENERATING_CODE",
  CODE_SEND = "CODE_SEND",
  ACTIVATING_MFA_EMAIL = "ACTIVATING_MFA_EMAIL",
  DEACTIVATING_MFA_EMAIL = "DEACTIVATING_MFA_EMAIL",
  MFA_EMAIL_ACTIVATED = "MFA_EMAIL_ACTIVATED",
  MFA_EMAIL_FAILED = "MFA_EMAIL_FAILED",
  MFA_EMAIL_DEACTIVATED = "MFA_EMAIL_DEACTIVATED",
  GENERATING_LINK_TO_LOGIN = "GENERATING_LINK_TO_LOGIN",
  LINK_TO_LOGIN_GENERATED = "LINK_TO_LOGIN_GENERATED",
  LINK_TO_LOGIN_FAILED = "LINK_TO_LOGIN_FAILED",
  USER_PASSWORD_EXPIRED = "USER_PASSWORD_EXPIRED"
}

export interface Domain {
  id: number,
  user_id: number,
  name: string,
  display_name: string,
  config: Config
}

export interface AuthReducer {
  user: UserData;

  path?: string;
  linkAccess?: { link: string }
  mfaStates?: AuthStates;
  linkStates?: AuthStates;
  state: AuthStates;
}
