import * as React from 'react';
import {useContext, useRef} from 'react';
import {AnalyticQueryAction} from "../../../../types/Analytic";
import {useFieldArray, useForm} from "react-hook-form";
import RenameMenuActionComponent from "./RenameMenuActionComponent";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import useTranslate from "../../../../hooks/useTranslate";
import {ActionButton, ReportContext} from "../../NewReportGeneratorComponent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

type Props = {
    index: number
    action: AnalyticQueryAction
    color?: string
};

type Form = {
    current_name: string
    new_name: string
}

const NewRenameActionComponent = ({action, index, color}: Props) => {

    const {setQueryAction, query} = useContext(ReportContext)
    const {control, watch} = useForm<{ columns: Form[] }>()
    const {t} = useTranslate("common")
    const {append, fields, update, remove, replace} = useFieldArray({
        control,
        name: "columns"
    })
    let onMount = useRef(false)

    React.useEffect(() => {
        const subscription = watch((obj, {name, type}) => {
            const columns = obj['columns']

            if (columns && columns.length > 0) {
                const renameColumns = columns.reduce((obj: { [key: string]: string }, column) => {

                    if (column && column.current_name && column.new_name) {
                        obj[column.current_name] = column.new_name
                    }

                    return obj;
                }, {})


                if (Object.keys(renameColumns).length > 0) {
                    setQueryAction({
                        ...action,
                        renamed_columns: renameColumns
                    }, index)
                }
            }
        });
        return () => subscription.unsubscribe();
    }, [watch, index, action, query]);

    React.useEffect(() => {

        if (!onMount.current) {
            onMount.current = true

            if (action.renamed_columns && Object.keys(action.renamed_columns).length > 0){
                const columns = Object.keys(action.renamed_columns).map((key, index) => ({
                    current_name: key,
                    new_name: action.renamed_columns ? action.renamed_columns[key] : ""
                }))

                replace(columns)

            }
        }


    }, [action]);

    return (
        <div className="d-flex flex-wrap gap-2">

            {fields.map((field, indexField) => (
                <RenameMenuActionComponent update={update} control={control} key={field.id} index={indexField}
                                           watch={watch}
                                           indexAction={index} remove={remove} queryAction={action}/>
            ))}


            <ActionButton className="btn" color={color ?? "white"} onClick={() => {
                append({
                    current_name: "",
                    new_name: ""
                })
            }}>
                <FontAwesomeIcon icon={faPlus} className="pointer white"/>
                {fields.length === 0 && <span>{t("add")}</span>}
            </ActionButton>
        </div>
    );
};

export default NewRenameActionComponent