import * as React from 'react';
import {useContext, useRef} from 'react';
import {AnalyticQueryAction} from "../../../../types/Analytic";
import {Controller, useForm} from "react-hook-form";
import ActionDropdownColumns from "../ActionDropdownComponent/ActionDropdownColumns";
import {ReportContext} from "../../NewReportGeneratorComponent";
import useTranslate from "../../../../hooks/useTranslate";

type Props = {
    action: AnalyticQueryAction;
    indexAction: number;
    color?: string;
};

type Options = {
    target: string,
    type: "classification" | "regression" | "forecasting"
    datetime_index?: string
}

type Form = {
    model_name?: string
    options: Options
}
const AsyncMlActionComponent = ({action, indexAction, color}: Props) => {

    const {t} = useTranslate('report')
    const {setQueryAction, getActionColumns} = useContext(ReportContext)
    const {register, control, watch, setValue} = useForm<Form>({
        defaultValues: {
            model_name: "",
            options: {
                type: "regression",
                target: ""
            }
        }
    })

    let onMount = useRef(false)

    React.useEffect(() => {
        const subscription = watch((form) => {

                const options: Options = {
                    target: form.options?.target ?? "",
                    type: form.options?.type ?? "regression"
                }

                if (form.options?.datetime_index) {
                    options.datetime_index = form.options?.datetime_index
                }

                setQueryAction({
                    ...action,
                    options,
                    model_name: form.model_name
                }, indexAction)
            }
        )
        return () => subscription.unsubscribe()
    }, [watch])

    React.useEffect(() => {

        if (!onMount.current) {
            if (action) {
                setValue('model_name', action.model_name ?? "")
                setValue('options.target', action.options?.target ?? "")
                setValue('options.type', action.options?.type ?? "regression")
                if (action.options?.datetime_index) {
                    setValue('options.datetime_index', action.options?.datetime_index)
                }
            }

            onMount.current = true
        }

    }, [action]);

    return (
        <div className="d-flex flex-wrap gap-2 align-items-center">

            <span>{t("model_name")}</span>
            <div>
                <input type="text" className="form-control"
                       placeholder={`${t('model_name')}`} {...register('model_name')}/>
            </div>

            <span>{t('target')}</span>
            <Controller render={({field}) => {
                return <ActionDropdownColumns color={color} onChange={field.onChange}
                                              columns={getActionColumns(action, indexAction).map(column => column.value)}
                                              label={field.value ? field.value : t('target')}/>
            }} name={"options.target"} control={control}/>

            {t('common:type')}
            <Controller render={({field}) => {
                return <ActionDropdownColumns color={color} onChange={value => {

                    if (value !== "forecasting") {
                        setValue('options.datetime_index', undefined)
                    }

                    field.onChange(value)
                }}
                                              columns={[
                                                  'classification',
                                                  'forecasting',
                                                  'regression'
                                              ]}
                                              label={field.value ?? t('common:type')}/>
            }} name={"options.type"} control={control}/>

            {watch("options.type") === "forecasting" && <><span>{t('datetime_index')}</span> <Controller
                render={({field}) => {
                    return <ActionDropdownColumns color={color} onChange={field.onChange}
                                                  columns={getActionColumns(action, indexAction).map(column => column.value)}
                                                  label={field.value ?? t('datetime_index')}/>
                }} name={"options.datetime_index"} control={control}/> </>}

        </div>
    );
};

export default AsyncMlActionComponent