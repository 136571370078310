import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {FormState, FormStates} from './Types';
import Form from '../../types/FormType';
import {plainToClass} from 'class-transformer';
import DataList from '../../types/FormBuilder/DataList';
import {Field} from '../../types/Field';
import {toMap} from "../../utils";

export const initialStateForm: FormState = {
  forms: [],
  formsMap: {},
  currentForm: plainToClass(Form, {
    id: 1,
    name: '',
    fields: [],
    domain: 0,
  }),
  parentFormId: null,
  fields: [],
  state: FormStates.IDLE,
  listProviders: []
};

const formReducer = createSlice({
  initialState: initialStateForm,
  name: "form",
  reducers: {
    getForms(state) {
      state.state = FormStates.FORMS_PENDING;
    },
    setForms: (state, action: PayloadAction<Form[]>) => {
      state.forms = action.payload;
      state.formsMap = toMap(action.payload, "id")
      state.state = FormStates.FORMS_RESOLVED;
    },
    getFormById(state, action: PayloadAction<number>) {
      state.state = FormStates.FORM_PENDING;
    },
    deleteFormById(state, action: PayloadAction<number>) {
      state.state = FormStates.FORM_PENDING;
    },
    setCurrentForm(state, action: PayloadAction<Form>) {
      state.currentForm = action.payload;
      state.state = FormStates.FORM_RESOLVED;
    },
    upsertForm(state, action: PayloadAction<{ name: string, id?: number }>) {
      state.state = FormStates.FORM_PENDING;
    },
    upsertField(state, action: PayloadAction<{ formId: number, field: Field }>) {
      state.state = FormStates.FIELD_PENDING;
    },
    setCurrentField(state, action: PayloadAction<Field | null>) {
      state.currentField = action.payload;
    },
    deleteFieldById(state, action: PayloadAction<{ formId: number, fieldId: number }>) {
      state.state = FormStates.FIELD_PENDING;
    },
    getListProver(state) {
      state.state = FormStates.LIST_PROVIDER_PENDING;
    },
    setListProvider(state, action: PayloadAction<DataList[]>) {
      state.state = FormStates.LIST_PROVIDER_RESOLVED;
      state.listProviders = action.payload;
    },
    updateFields(state, action: PayloadAction<{ sourceIndex: number, destinationIndex: number }>) {
      let form = Object.assign({}, state.currentForm);
      form.fields = form.fields.map((f: any, i: number) => {
        if (i === action.payload.sourceIndex) {
          f.sort_index = action.payload.destinationIndex;
        } else if (i === action.payload.destinationIndex) {
          f.sort_index = action.payload.sourceIndex;
        } else {
          f.sort_index = i;
        }

        return f;
      }).sort((a: any, b: any) => a.sort_index - b.sort_index);
      state.currentForm = form;
    },
    cloneForm(state, action: PayloadAction<Form>) {
      state.state = FormStates.CLONING_FORM;
    },
    changeState(state, action: PayloadAction<FormStates>) {
      state.state = action.payload;
    }
  }
});

export const actionsForm = formReducer.actions;
export default formReducer.reducer;
