import React, {useReducer} from 'react';
import ModalComponent from '../Shared/Modal/ModalComponent/ModalComponent';
import {SubmitHandler, useForm} from 'react-hook-form';
import useAsyncEffect from '../../hooks/useAsyncEffect';
import {ModelsResponse, SbxResponse} from '../../types/Sbx';
import {findByModel, insertSbxModelService, updateModelRow} from '../../services/backend/SbxService';
import {State} from '../../types/State';
import {useRouter} from 'next/router';
import {ReportReducerState} from './ReportGeneratorComponent';
import {IsJsonString, removeDuplicateFromArrayObj} from '../../utils';
import {CacheTruncate, Report, TruncateReport} from '../../types/Analytic';
import {StringOption} from "../../types/Select";
import {useSelector} from "react-redux";
import {authReducer} from "../../store/Selectors";
import SaveModalFieldComponent from "../ReportsComponent/SaveModalFieldComponent";
import {baseCache} from "./TruncateGeneratorComponent/TruncateGeneratorComponent";
import useTranslate from "../../hooks/useTranslate";
import {getAnalyticQueryFlat, removeTemporalIdFromQuery} from "../../utils/analyticsUtils";

type Props = {
    isOpen: boolean
    toggle: () => void;
    reportState: ReportReducerState
    report?: Report
}

export type MetaOptions = 'report_columns' | 'models' | 'model' | 'truncate' | 'time_format'

export interface InputField {
    name: string,
    type: 'text' | 'number' | 'select' | 'multi-select' | 'boolean' | 'email' | 'object'
    label?: string;
    placeholder?: string
    required?: boolean
    isLoading?: boolean
    actions?: { label: string, value: string, options?: MetaOptions, model?: string }[]
    actionsList?: (StringOption & { sub_type?: string })[]
    isDisabled?: boolean
    sub_field?: boolean
    sub_type?: 'text' | 'number' | 'select' | 'multi-select' | 'boolean' | 'email' | 'object'
    json?: string,
    isMulti?: boolean
    isArray?: boolean
    jsonLabel?: string,
    setActions?: (actions: { label: string, value: any }[]) => void
    defaultValue?: any
    defaultValueSelect?: { label: string, value: any } | { label: string, value: any }[] | null | string
    options?: { label: string, value: any }[]
}


interface LocalReport extends Report {
    handleQuery: boolean;
}

enum Types {
    SET_STATE,
    SET_MULTI_STATE
}

interface InitialReducerState {
    loading: State,
    reports: LocalReport[]
    models: ModelsResponse[]
    truncateModel: string,
    actions: { [action: string]: StringOption[] }
}

const initialState: InitialReducerState = {
    loading: State.IDLE,
    reports: [],
    models: [],
    truncateModel: "",
    actions: {}
};


const customColumnActionList = [
    {label: "Money dot no decimal ($32.000)", value: "money_dot_no_decimal", sub_type: 'multi-select'},
    // {label: "Money Comma no decimal ($42,000)", value: "money_comma_no_decimal", sub_type: 'multi-select'},
    {label: "Money Comma decimal ($42,000.00)", value:  "money_comma_decimal", sub_type: 'multi-select'},
    // {label: "Dot (32.000.01)", value: "dot", sub_type: 'multi-select'},
    {label: "Comma (37,000,02)", value: "comma", sub_type: 'multi-select'},
    // {label: "Money dot ($32000.32)", value: "money_dot", sub_type: 'multi-select'},
    // {label: "Money comma ($32000,22)", value: "money_comma", sub_type: 'multi-select'},
    {label: "Dot no decimal (32.000)", value: "dot_no_decimal", sub_type: 'multi-select'},
    {label: "Comma no decimal (32,000)", value: "comma_no_decimal", sub_type: 'multi-select'},
    {label: "Percentage (90,9%)", value: "percentage", sub_type: 'multi-select'},
]

function reducer(state: InitialReducerState, {
    type,
    payload
}: { type: Types, payload: { name: string, value?: any } | { name: string, value: any }[] }) {
    switch (type) {
        case Types.SET_STATE:
            return {
                ...state,
                [(payload as { name: string, value: any }).name]: (payload as { name: string, value: any }).value
            };
        case Types.SET_MULTI_STATE:
            (payload as { name: keyof InitialReducerState, value: any }[]).forEach(data => {
                state = {...state, [data.name]: data.value};
            });
            return {...state};
        default:
            throw new Error();
    }
}

const SaveReportModal = ({isOpen, toggle, reportState, report}: Props) => {
    const {register, handleSubmit, control, getValues, setValue} = useForm<LocalReport>();
    const {user} = useSelector(authReducer)
    const [stateLocal, dispatchLocal] = useReducer(reducer, initialState);
    const {loading, actions, reports, models} = stateLocal
    const {t} = useTranslate('report');

    const fieldsColumn = (): InputField[] => [
        {
            name: 'name',
            type: 'text',
            label: t('common:name'),
            required: true,
            placeholder: 'Name...',
            defaultValue: report?.name
        },
        {
            name: 'ncolumn',
            type: 'number',
            label: t('ncolumn'),
            placeholder: 'Column number...',
            defaultValue: report?.ncolumn
        },
        {
            name: "alias",
            type: 'object',
            label: "Cache",
            sub_field: true,
            // defaultValue: report?.alias,
            actions: [
                {label: "Key", value: "key"},
                {label: "TTL", value: "ttl"},
                {label: "update", value: "update"},
            ],
            setActions: (actions) => {
            },

            isLoading: loading === State.PENDING,
            isDisabled: true,
            actionsList: []
        },
        {
            name: "visible_for",
            type: 'object',
            label: t('visible_for'),
            sub_field: true,
            defaultValue: report?.visible_for,
            actions: actions["visible_for"] ?? [],
            setActions: (actions) => dispatchForm({
                name: 'actions',
                value: {...stateLocal.actions, visible_for: actions}
            }),
            defaultValueSelect: report?.visible_for ? IsJsonString(report.visible_for as string) ? Object.keys(JSON.parse(report.visible_for as string)).map((column: string) => ({
                label: column,
                value: column
            })) : null : null,
            actionsList: Object.keys(user).length > 0 ? Object.keys(user).map(key => ({label: key, value: key})) : []
        },
        {name: 'order_group', type: 'number', label: t('order_group'), placeholder: 'Order group...'},
        {
            name: 'parent_group',
            type: 'select',
            label: t('parent_report'),
            placeholder: 'Parent report...',
            isDisabled: loading === State.PENDING,
            isLoading: loading === State.PENDING,
            options: reports.map(report => ({label: report.name, value: report._KEY}))
        },
        {
            name: 'show_all_columns',
            type: 'boolean',
            label: t('show_all_columns'),
            defaultValue: report?.show_all_columns
        },{
            name: 'remove_empty_columns',
            type: 'boolean',
            label: t('remove_empty_columns'),
            defaultValue: report?.remove_empty_columns
        },
        {name: 'custom', type: 'boolean', label: t('custom'), defaultValue: report?.custom},
        {name: 'group', type: 'boolean', label: t('group'), defaultValue: report?.group}


    ]

    const dispatchForm = ({name, value}: { name: keyof InitialReducerState, value: any }) => {
        dispatchLocal({type: Types.SET_STATE, payload: {value, name}});
    };
    const dispatchMultiForm = (forms: { name: keyof InitialReducerState, value: any }[]) => {
        dispatchLocal({type: Types.SET_MULTI_STATE, payload: forms});
    };

    const history = useRouter();

    useAsyncEffect(async () => {
        dispatchForm({name: 'loading', value: State.PENDING})
        const resFind: SbxResponse<LocalReport> = await findByModel({row_model: 'sbx_crm_report'})
        if (resFind?.success && resFind.results) {
            dispatchMultiForm([
                {name: 'loading', value: State.RESOLVED},
                {name: 'reports', value: resFind.results}
            ])
        } else {
            dispatchForm({name: 'loading', value: State.REJECTED})
        }
    }, []);

    React.useEffect(() => {

        const newActions: { [action: string]: StringOption[] } = {}

        if (report?.metadata && IsJsonString(report.metadata as string)) {
            const meta = JSON.parse(report.metadata as string)
            newActions.metadata = Object.keys(meta).map(key => ({label: key, value: key}))
        }

        if (report?.visible_for && IsJsonString(report.visible_for as string)) {
            const visibleFor = JSON.parse(report.visible_for as string)
            newActions.visible_for = Object.keys(visibleFor).map(key => ({label: key, value: key}))
        }

        if (report?.custom_column && IsJsonString(report.visible_for as string)) {
            const customColumn = JSON.parse(report.custom_column as string)
            newActions.custom_column = removeDuplicateFromArrayObj(customColumn.map((item: { column: string, type: string }) => ({
                label: customColumnActionList.find(column => column.value === item.type)?.label ?? "",
                value: item.type,
                sub_type: "multi-select"
            })), "value")
        }

        if (Object.keys(newActions).length > 0) {
            dispatchForm({name: 'actions', value: newActions})
        }

    }, [report]);

    React.useEffect(() => {

        fieldsColumn().forEach(field => {
            const name = field.isArray ? field.name.split(".")[0] : field.name as keyof Report;

            const value = getValues(name as keyof Report)

            if (field.type === "object" && field.defaultValue && (!value || Object.keys(value).every((key: string) => value[key]))) {
                setValue(name as keyof Report, IsJsonString(field.defaultValue) ? JSON.parse(field.defaultValue) : field.defaultValue)
            }
        })

    }, [report]);


    const onSubmit: SubmitHandler<Report> = async (data) => {
        dispatchForm({name: 'loading', value: State.PENDING})
        const newQuery: Report = {
            ...data,
            query: JSON.stringify(removeTemporalIdFromQuery(getAnalyticQueryFlat(reportState.analyticQuery))),
            active: true,
            truncate: reportState.truncate ?? ""
        }


        if (newQuery.truncate && IsJsonString(newQuery.truncate as string)) {
            const truncate: TruncateReport = JSON.parse(newQuery.truncate as string)
            const condition1 = !truncate.cache ? false : Object.keys(baseCache).length === Object.keys(truncate.cache!).length && !Object.keys(truncate.cache!).filter(key => truncate.cache && typeof truncate.cache[key as keyof CacheTruncate] !== "boolean").every(key => (truncate.cache![key as keyof CacheTruncate] as string)!.length > 0)
            const condition2 = truncate.type === "text" || truncate.others?.some(truncate => truncate.type === "text")
            if (truncate.cache && (condition1 || condition2)) {
                delete truncate.cache

            }
            newQuery.truncate = JSON.stringify(truncate)
        }


        Object.keys(newQuery as Report).forEach(key => {
            if (newQuery[key as keyof Report] && typeof newQuery[key as keyof Report] === "object") {
                (newQuery as Report & any)[key as keyof Report] = JSON.stringify(newQuery[key as keyof Report])
            }
        })

        let res: SbxResponse;
        let key = ""

        if (report && report._KEY) {
            res = await updateModelRow([{...newQuery, _KEY: report._KEY}], "sbx_crm_report")
            if (res.success && report._KEY) {
                key = report._KEY
            } else {
                dispatchForm({name: 'loading', value: State.REJECTED})
            }
        } else {
            if (report?.type){
                newQuery.type = report.type
            }

            if (report?.metadata){
                newQuery.metadata = report.metadata
            }
            res = await insertSbxModelService({row_model: "sbx_crm_report", rows: [newQuery]})
            if (res.success && res.keys) {
                key = res.keys[0]
            } else {
                dispatchForm({name: 'loading', value: State.REJECTED})
            }
        }

        if (key) {
            await history.push(`/analytics/crm-reports/${key}?from=report_generator`)
        }


    };

    return (
        <ModalComponent isOpen={isOpen} isLoading={loading === State.PENDING} disabled={loading === State.PENDING}
                        form="new_group_form" size={'xl'} toggle={toggle} title={<span>Save report</span>}
                        type="submit">
            <form onSubmit={handleSubmit(onSubmit)} id={'new_group_form'}>
                <div className="row gap-2 gap-lg-0">
                    {fieldsColumn().map((field, index) =>
                        <SaveModalFieldComponent key={field.name + "_" + index} loading={loading}
                                                 getValues={getValues} reportState={reportState}
                                                 report={report} models={models}
                                                 register={register}
                                                 setValue={setValue}
                                                 control={control} field={field}/>
                    )}
                </div>
            </form>
        </ModalComponent>
    );
};

export default SaveReportModal;
