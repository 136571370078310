import React, { useRef, useState } from "react";
import { Account, SalesAdressee } from "../../types/Account";
import {
  findByLike,
  findByModelLike,
} from "../../services/backend/AccountService";
import AutoSuggest from "react-autosuggest";
import { useRouter } from "next/router";
import {
  capitalize,
  debounceTime,
  getInterpretVar,
  removeLastCharacter,
} from "../../utils";
import useTranslate from "../../hooks/useTranslate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { authReducer } from "../../store/Selectors";
import { Condition } from "../../types/Sbx";

const AutoSuggestAccounts = () => {
  const [search, setSearch] = useState("");
  const [items, setItems] = useState<
    ((Account | SalesAdressee) & { row_model: string })[]
  >([]);
  const router = useRouter();
  const state = useRef(false);
  const { current: loading } = state;
  const { t } = useTranslate("common");
  const { user } = useSelector(authReducer);
  const config = user.config;

  const loadSuggestions = async (search: string) => {
    state.current = true;
    const res = await findByLike({ search, size: 20 });
    if (res.success && res.items) {
      state.current = false;

      let nItems = [...res.items];

      // if show addresses is true
      if (config?.sbx_crm?.addressee?.showAddresses) {
        const fetch = config?.sbx_crm.addressee?.search?.fetch_models ?? [];
        const where: Condition[] = (
          (config?.sbx_crm.addressee?.filter_list_conditions ??
            []) as Condition[]
        ).map((field) => ({
          ...field,
          GROUP: field.GROUP.map((group) => {
            if (
              group.VAL &&
              typeof group.VAL === "string" &&
              (group.VAL as string).includes("currentUser")
            ) {
              group = {
                ...group,
                VAL: getInterpretVar({ strVar: group.VAL, item: user }),
              };
            }
            return group;
          }),
        }));

        let response = await findByModelLike({
          row_model: "sales_addressee",
          search,
          fetch,
          where,
        });

        if (response.success && response.results) {
          nItems = [...nItems, ...response.results];
        }
      }

      setItems(nItems);
    }
    state.current = false;
  };

  const onChange = (event: any, { newValue }: any) => {
    setSearch(newValue);
  };

  const onSuggestionsFetchRequested = async ({ value }: any) => {
    await debounceTime(loadSuggestions, value, 1000);
    // await loadSuggestions(value);
  };
  const onSuggestionsClearRequested = () => {
    setItems([]);
  };

  const getSuggestionValue = (suggestion: any) => {
    return suggestion.name ?? "";
  };

  const onSuggestionSelected = async (
    event: any,
    { suggestion }: { suggestion: Account | SalesAdressee },
  ) => {
    if (suggestion.row_model === "sales_addressee") {
      await router.push(
        `/home/accounts/detail/${suggestion.account._KEY}?addressee=${suggestion._KEY}`,
      );
    } else {
      await router.push(`/home/accounts/detail/${suggestion._KEY}`);
    }
  };

  const renderSuggestion = (
    suggestion: (Account | SalesAdressee) & { row_model: string },
  ) => {
    if (suggestion.row_model === "sales_addressee") {
      return (
        <span>
          <b>
            {removeLastCharacter(t("routes:addressee") ?? "").toUpperCase()} -{" "}
            {capitalize(`${suggestion.name}`)}
          </b>
          <br />
          {suggestion.code && (
            <small className="text-dark">{`${
              config?.sbx_crm?.accounts?.id_label ?? "Id"
            }: ${suggestion.code}`}</small>
          )}
        </span>
      );
    }

    return (
      <span>
        <b>
          {config?.sbx_crm?.addressee.showAddresses
            ? `${removeLastCharacter(
                t("routes:accounts") ?? "",
              ).toUpperCase()} - `
            : ""}{" "}
          {capitalize(`${capitalize(suggestion.company.company_name)}`)}
        </b>
        <br />
        <small>
          {capitalize(
            `${suggestion.contact?.first_name ?? ""} ${
              suggestion.contact?.last_name ?? ""
            }`,
          )}
        </small>
        <br />
        {suggestion.company.account_id && (
          <small className="text-dark">{`${
            config?.sbx_crm?.accounts?.id_label ?? "Id"
          }: ${suggestion.company.account_id}`}</small>
        )}
      </span>
    );
  };

  return (
    <div className="position-relative">
      {loading && (
        <FontAwesomeIcon
          style={{
            position: "absolute",
            right: 50,
            zIndex: 10000,
            top: 12,
          }}
          icon={faSpinner}
          spin
        />
      )}
      <AutoSuggest
        suggestions={items}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        onSuggestionSelected={onSuggestionSelected}
        renderSuggestion={(
          suggestion: (Account | SalesAdressee) & { row_model: string },
        ) => renderSuggestion(suggestion)}
        inputProps={{
          placeholder: t("search"),
          value: search,
          onChange: onChange,
          className: `search-input`,
        }}
      />
    </div>
  );
};

export default AutoSuggestAccounts;
