import * as React from 'react';
import {useContext, useRef, useState} from 'react';
import {StringOption} from "../../../../types/Select";
import useTranslate from "../../../../hooks/useTranslate";
import styled from "styled-components";
import ActionDropdownColumns from "../ActionDropdownComponent/ActionDropdownColumns";
import {getColumnType} from "../utils";
import {ReportContext} from "../../NewReportGeneratorComponent";
import {faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Fade} from "reactstrap";

type Props = {
    color: string
    columns: StringOption[]
    value: string
    index: number
    onChange: (value: string) => void
}

const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0]

const operators = ["/", "*", "-", "+", "."]

const actions = ["(", ")", "AC", "DEL", "="]

const ContainerNumbers = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 75px);
  grid-auto-rows: 30px;
  gap: 0.5rem !important;
`


const NumberItem = styled.button`
  color: white;
  font-size: 1rem;
`


const OperatorItem = styled.button`
  color: white;
  font-size: 1.2rem;
`


function isValidateExpression(expression: string): boolean {
    let parenthesesBalance = 0;

    const regex = /^(?:pdf\.[a-zA-Z_]+|\d+(\.\d+)?|\+|-|\*|\/|\(|\)|=|\s)+$/;
    const match = expression.match(regex);
    if (!match || match[0] !== expression) {
        return false;
    }

    // Check for incomplete expressions
    if (/[\+\-\*\/=]$/.test(expression)) {
        return false;
    }

    // Check for invalid operations
    const invalidOperations = /(\+\+|--|\*\*|\/\/|\+\*|\+\-|\+\)|\-\*|\-\-|\-\+|\-\)|\/\*|\/\+|\*\*|\*\+|\*\/|\+\*|\+\-|\+\/|--|\-\*|\-\-|\-\+|\-\*|\-\-|\-\+|\/\*|\/\+|\/\-|\*\*|\*\+|\*\-|\*\/|\+\/|--|\+\*|\-\*|\/\*|\/\+|\/\/|\*\*|\*\+|\*\-|\*\/|\+\/|--|\+\*|\-\*|\/\*|\/\+|\/\/)/g;
    const invalidMatches = Array.from(expression.matchAll(invalidOperations));
    if (invalidMatches.length > 0) {
        return false;
    }


    // Check for balanced parentheses
    for (const char of expression) {
        if (char === '(') {
            parenthesesBalance++;
        } else if (char === ')') {
            parenthesesBalance--;
            if (parenthesesBalance < 0) {
                return false; // More closing parentheses than opening ones
            }
        }
    }

    // Check for expression starting or ending with an operator
    if (/^[\+\-\*\/]/.test(expression) || /[\+\-\*\/]$/.test(expression)) {
        return false;
    }

    return parenthesesBalance === 0; // True if parentheses are balanced
}





function splitValidExpression(expression: string): string[] {
    const regex = /(?:pdf\.[a-zA-Z_]+|\d+(\.\d+)?|[\+\-\*\/\(\)=])/g;
    return expression.match(regex) || [];
}


const TransformMathOperations = ({color, columns, onChange, value, index}: Props) => {
    const {t} = useTranslate('report')
    const {state} = useContext(ReportContext)
    const [operationList, setOperationList] = useState<string[]>([])
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [itemSelected, setItemSelected] = useState<null | number>(null)
    let onMount = useRef(false)

    React.useEffect(() => {
        if (operationList.length > 0) {
            const isValidOperation = isValidateExpression(operationList.join(""))
            setShowErrorMessage(!isValidOperation)

            if (isValidOperation) {
                onChange(operationList.join(""))
            }

        } else {
            if (!value){
                onChange("")
            }

            setShowErrorMessage(false)
            setItemSelected(null)
        }


    }, [operationList, value]);

    React.useEffect(() => {
        if (value && !onMount.current) {
            const splitExpression = splitValidExpression(value);
            if (splitExpression.length > 0){
                setOperationList(splitExpression)
            }
            onMount.current = true
        }

    }, [value]);


    return (

        <div className="d-flex flex-column gap-2 flex-grow-1">
            <div className={`border bg-gray p-3 d-flex flex-grow-1`} style={{maxWidth: '50vw', overflowX: 'auto'}}>
                {operationList.length > 0 ? operationList.map((variable, index) => {
                    return <div key={`${variable}_${index}`} className={`position-relative ${operators.includes(variable) ? "px-1" : ""}`} onClick={(event) => {
                        if (event.detail === 2) {
                            setItemSelected(index)
                        }
                    }}>
                        <span>{variable}</span>
                    </div>
                }) : <span>{t("operation")}</span>}
            </div>

            {itemSelected !== null && itemSelected >= 0 && <div className="d-flex align-items-center gap-1">
                <span><b>{t("item_selected")}:</b> {operationList[itemSelected]}</span>
                <FontAwesomeIcon icon={faTimesCircle} className="pointer" color={"red"}
                                 onClick={() => {
                                     const newOperationList = operationList.filter((_, index) => index !== itemSelected)
                                     setOperationList(newOperationList)
                                     setItemSelected(null)
                                 }}/>
            </div>}



            <div className="d-flex align-items-start gap-2">
                <div className="d-flex flex-wrap align-items-center gap-3">
                    <ActionDropdownColumns color={color} onChange={(value) => {
                        setOperationList([...operationList, `pdf.${value}`])
                    }} columns={columns.filter(column => {
                        const type = getColumnType({
                            index: index,
                            columnsType: state.columnsType,
                            name: column.value
                        })

                        if (["FLOAT", "INT"].includes(type)) {
                            return true
                        }
                    }).map(column => column.value)} label={t("common:columns")}/>
                </div>


                <div className="d-flex flex-column gap-1">

                    <ContainerNumbers>
                        {operators.map((operator, index) => {
                            return <OperatorItem key={index} onClick={() => {
                                setOperationList([...operationList, operator])
                            }} className="btn" style={{backgroundColor: color}}>{operator}</OperatorItem>
                        })}

                        {numbers.map((number, index) => {
                            return <NumberItem key={index} onClick={() => {
                                setOperationList([...operationList, number.toString()])
                            }} className="btn" style={{backgroundColor: color}}>{number}</NumberItem>
                        })}

                        {actions.map((action, index) => {
                            return <NumberItem key={index} onClick={() => {
                                switch (action) {
                                    case "AC":
                                        setOperationList([])
                                        break;
                                    case "DEL":
                                        const newOperationList = operationList.slice(0, -1)
                                        setOperationList(newOperationList)
                                        break;
                                    default:
                                        setOperationList([...operationList, action])
                                        break;
                                }


                            }} className="btn" style={{backgroundColor: color}}>{action}</NumberItem>
                        })}
                    </ContainerNumbers>
                </div>


            </div>

            <Fade
                in={showErrorMessage}
                tag="span"
            >
                <span className="font-sm"
                      style={{color: "red"}}>{t("custom-message:there_is_a_syntax_error_in_the_formula")}</span>
            </Fade>
        </div>
    );
};

export default TransformMathOperations