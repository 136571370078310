import {
  Button,
  Card,
  CardBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Table,
  UncontrolledButtonDropdown,
} from "reactstrap";
import React, {CSSProperties, Fragment, useEffect, useState} from "react";
import SpinnerComponent from "../SpinnerComponent";
import {Pagination, Switch} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faEllipsisV,
  faEnvelope,
  faEye,
  faFileExport, faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import Permission from "../../AuthorityPermission/Permission";
import { Permissions } from "../../../types/Permissions";
import {
  BaseAccountData,
  capitalize,
  checkIsValidHexColor,
  checkValidColor, containsHTML,
  containsNumbers,
  convertDateToYYYYMM,
  convertDateToYYYYMMDD,
  convertDateToYYYYMMDDHHmmaa,
  convertDateToYYYYMMMDD,
  convertNumberDateToDate,
  convertTableRowsToCSVString,
  DEFAULT_SIZE,
  downloadTextToFile,
  filterDataV2,
  formatDateFunc,
  getBaseAction,
  getCompoundName,
  getContentFileName,
  getObjValueInDeep,
  isEncoded,
  IsJsonString,
  openInNewTab,
  removeDuplicateFromArray, transformHtmlArrayToSpan,
  uuidV4,
} from "../../../utils";
import { ModelsResponse, SbxModelField } from "../../../types/Sbx";
import PopoverComponent from "../PopoverComponent";
import useTranslate from "../../../hooks/useTranslate";
// @ts-ignore
import locale from "react-json-editor-ajrm/locale/en";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/Reducers";
import { actionHistory } from "../../../store/SearchReducer";
import { ListProviderOption } from "../../../types/Task";
import DropdownMenuHeader from "../DropdownComponent/DropdownMenuHeader";
import { SbxCrmDataColumn, TypeFIle } from "../../../types/User";
import { ButtonType } from "../../LayoutComponenents/types/ButtonType";
import { ColumnsLabels, FormatRules } from "../../../types/Field";
import EditorComponent from "../EditorComponent/EditorComponent";
import { Content } from "../../../types/Folder/Content";
import { downloadFileService, getFile } from "../../../services/UtilsService";
import { FileTypeIcon } from "../ContentEngine";
import useIgnoreFirstEffect from "../../../hooks/useIgnoreFirstEffect";
import RenderImageViewer from "../RenderImageViewer/RenderImageViewer";
import { useRouter } from "next/router";
import TableColumnFilterMenuComponent from "../TableColumnFilterMenuComponent/TableColumnFilterMenuComponent";
import TableCustomColumnMenuComponent from "../TableColumnFilterMenuComponent/TableCustomColumnMenuComponent";
import useAsyncEffect from "../../../hooks/useAsyncEffect";
import { findAllModels } from "../../../services/backend/SbxService";
import wsIcon from "../../../public/assets/images/wsicon.jpg";
import Image from "next/image";
import { actionsModal, ModalTypes } from "../../../store/Modal/Slice";
import ButtonComponent from "../ButtonComponent";
import { reportPropType } from "../../ReportsComponent/ReportComponent";
import AnalyticsPageComponent from "../../analytics/AnalyticsPageComponent";
import ExcelButtonComponent from "../ExcelButtonComponent";
import {TableCustom} from "./Table";

export type CustomTableColumnType =
  | "String"
  | "Date"
  | "Formatter"
  | SbxModelField
  | "Reference"
  | "Fixed"
  | "Icon"
  | "Json"
  | "NumberDate"
  | "DateFormat"
  | "Boolean"
  | "List_provider"
  | "Year_month"
  | "Custom"
  | "DateTime"
  | "ReactNode"
  | "Color"
  | "Array"
  | "ArrayObject"
  | "Document"
  | "Image"
  | "Mailto"
  | "Whatsapp"
  | "Average";

export interface Column extends FormatRules {
  name: string;
  data?: any;
  header: string;
  parent?: string;
  reference?: string;
  className?: string;
  style?: CSSProperties;
  action?: React.ReactNode;
  isSort?: boolean;
  isDrillDown?: boolean;
  formatDate?: string;
  isTotalColumn?: boolean;
  metadata_type?: string[]; // [average, total]
  headerClassName?: string;
  customShowColumn?: (value: any, index?: number) => void;
  type?: CustomTableColumnType;
  zIndex?: number;
  value?: string;
  column_reference?: SbxCrmDataColumn;
  key?: string; // Any data key Ex: Report key
  extra_query?: string;
  colSpan?: number;
  rowSpan?: number;
  sub_columns?: Column[]
}

export interface Action {
  label?: React.ReactNode;
  disabled?: boolean;
  title?: string;
  onAction?: (row: any, index?: number) => void;
  permission?: Permissions | Permissions[];
  visible?: boolean;
  visibleRow?: (row: any) => boolean;
  custom?: boolean;
  customComponent?: (row: any) => JSX.Element | JSX.Element[];
  type?: "primary" | "secondary" | "danger" | "success" | "warning" | string;
}

export interface TableIProps {
  id?: string;
  pageHistory?: boolean;
  columns: Column[];
  subColumns?: Column[];
  hideColumnsReports?: Column[];
  data: any[];
  tableData?: TableCustom;
  actions?: Action[];
  loading?: boolean;
  pagination?: boolean;
  showSizeChanger?: boolean;
  currentPage?: number;
  rowAction?: (row: any, header: Column) => void;
  tableShading?: boolean;
  getData?: (data: any[]) => void;
  onChangePage?: (page: number, size: number) => void;
  totalData?: number;
  actionsType?: "list" | "button";
  getColumns?: (columns: Column[]) => void;
  columnsSetting?: boolean;
  onShowSizeChange?: (page: number, size: number) => void;
  filter?: boolean;
  useLocalPage?: boolean;
  exportButtons?: { type: TypeFIle; color: ButtonType }[];
  actionsColumnLabel?: string;
  actionsColumnClass?: string;
  removeEmptyColumns?: boolean;
  sortableTable?: boolean;
  shadow?: "none" | "lg" | "sm";
  localFilter?: boolean;
  isReportTable?: boolean;
  loadSbxModels?: string[];
  accountData?: BaseAccountData;
  sizePerPage?: number;
  updateColumnsProps?: ({
    reportProp,
    column_name,
    value,
    subProp,
  }: {
    column_name: string;
    value: any;
    subProp?: string;
    reportProp: reportPropType;
  }) => void;
}

const animateColumnMove = (columnIndex: number, newPosition: number) => {
  const ths = document.querySelectorAll("th");
  const distance = newPosition - columnIndex;

  // Movemos la columna actualmente en la posición "columnIndex" hacia su nueva posición
  ths[columnIndex].style.transform = `translateX(${distance * 100}%)`;

  // Establecemos la opacidad de la columna en su nueva posición a 0
  ths[newPosition].style.opacity = "0";

  // Agregamos una transición suave a la columna que se está moviendo
  ths[columnIndex].style.transition =
    `transform 0.3s ease-in-out, opacity 0.3s ease-in-out`;

  // Cuando la transición termine, eliminamos la transformación y la transición y restablecemos la opacidad de la columna en su nueva posición a 1
  ths[columnIndex].addEventListener(
    "transitionend",
    () => {
      ths[columnIndex].style.transform = "";
      ths[columnIndex].style.transition = "";
      ths[newPosition].style.opacity = "1";
    },
    { once: true },
  );
};

export const CustomTableComponent = ({
  columns: columnsData, subColumns,
  data,
  filter: search = true,
  actions,
  sizePerPage = DEFAULT_SIZE,
  loading,
  pagination = true,
  tableShading,
  rowAction,
  onChangePage,
  totalData,
  showSizeChanger,
  actionsType,
  id,
  getColumns,
  columnsSetting,
  onShowSizeChange,
  useLocalPage = false,
  exportButtons = [],
  actionsColumnLabel = "",
  actionsColumnClass,
  removeEmptyColumns,
  currentPage,
  shadow,
  loadSbxModels,
  isReportTable,
  getData,
  localFilter,
  tableData,
  updateColumnsProps,
  hideColumnsReports, sortableTable = true,
  pageHistory = true,
  accountData,
}: TableIProps) => {
  const { page, text } = useSelector((state: RootState) => state.SearchReducer);
  const [newRows, setNewRows] = useState<Array<any>>([]);
  const [excludeColumns, setExcludeColumns] = useState<string[]>([]);
  const [columns, setColumns] = useState<Column[]>([]);
  const [copyColumns, setCopyColumns] = useState<Column[]>([]);
  const [filterLocal, setFilterLocal] = useState("");
  const [filter, setFilter] = useState("");
  const [selectAll, setSelection] = useState(true);
  const { t } = useTranslate("common");
  const [size, setSize] = useState(sizePerPage);
  const dispatch = useDispatch();
  const [localPage, setLocalPage] = useState(1);
  const [rows, setRows] = useState<any[]>([]);
  const [sbxModels, setSbxModels] = useState<ModelsResponse[]>([]);
  const [filtersByTable, setFiltersByTable] = useState<{
    [column_name: string]: {
      column: Column;
      valueList: any[];
    };
  }>({});
  const router = useRouter();

  useAsyncEffect(async () => {
    if (loadSbxModels && loadSbxModels.length > 0) {
      const sbxModels = await findAllModels();
      if (sbxModels?.success && sbxModels.items) {
        setSbxModels(
          sbxModels.items.filter((model) => loadSbxModels.includes(model.name)),
        );
      }
    }
  }, [loadSbxModels]);

  const [draggedColumnIndex, setDraggedColumnIndex] = useState<number | null>(
    null,
  );

  const [overColumnIndex, setOverColumnIndex] = useState<number | null>(null);

  const handleDragStart = (index: number) => {
    setDraggedColumnIndex(index);
  };

  const handleDragEnter = (index: number) => {
    setOverColumnIndex(index);
  };

  const handleDragEnd = () => {
    if (draggedColumnIndex !== null && overColumnIndex !== null) {
      const newColumns = [...columns];
      const draggedColumn = newColumns[draggedColumnIndex];
      newColumns.splice(draggedColumnIndex, 1);
      newColumns.splice(overColumnIndex, 0, draggedColumn);
      setColumns(newColumns);
      setDraggedColumnIndex(null);
      setOverColumnIndex(null);

      if (updateColumnsProps) {
        reportUpdateColumns({
          columns: newColumns.map((column) => column.name),
        });
      }

      // Animamos el movimiento de cada columna que se está moviendo
      if (draggedColumnIndex < overColumnIndex) {
        for (let i = draggedColumnIndex; i < overColumnIndex; i++) {
          animateColumnMove(i, i + 1);
        }
      } else {
        for (let i = overColumnIndex; i < draggedColumnIndex; i++) {
          animateColumnMove(i, i + 1);
        }
      }
    }
  };

  useEffect(() => {
    if (search) {
      const filteredData = filterDataV2(data, text);
      const rowsChanged = filteredData.length !== newRows.length;
      setNewRows(filteredData);
      if (rowsChanged && localPage > 1) {
        setLocalPage(1);
      }
    } else {
      setNewRows(data);
    }
  }, [data, text, localPage, size, setLocalPage]);

  useEffect(() => {
    if (localFilter) {
      if (filterLocal.trim()) {
        setNewRows(filterDataV2(data, filterLocal));
      } else {
        setNewRows(data);
      }
    }
  }, [data, filterLocal]);

  React.useEffect(() => {
    if (useLocalPage && !onChangePage) {
      setLocalPage(1);
    }
  }, [data]);

  React.useEffect(() => {
    if (currentPage && currentPage > 0 && useLocalPage && onChangePage) {
      setLocalPage(currentPage);
    }
  }, [currentPage]);

  React.useEffect(() => {
    if (!useLocalPage) {
      setLocalPage(page);
    }
  }, [page]);

  useIgnoreFirstEffect(() => {
    if (onChangePage) {
      onChangePage(localPage, size);
    }
  }, [localPage]);

  React.useEffect(() => {
    setRows(
      pagination && !totalData
        ? size > 0
          ? newRows.slice((localPage - 1) * size, (localPage - 1) * size + size)
          : newRows
        : newRows,
    );
  }, [pagination, totalData, localPage, size, newRows]);

  const getValue = ({
    value,
    header,
    index,
    row,
  }: {
    value:
      | string
      | number
      | boolean
      | ListProviderOption
      | { [key: string]: any }
      | Content;
    header: Column;
    index?: number;
    row?: any;
  }) => {


    if (header.type) {



      const cases: { [column: string]: () => any } = {
        [SbxModelField.DATE]: () =>
          value ? convertDateToYYYYMMMDD(value as string) : "",
        Date: () => (value ? convertDateToYYYYMMMDD(value as string) : ""),
        DateFormat: () =>
          value
            ? formatDateFunc(new Date(value as string), header.formatDate)
            : "",
        DateTime: () => convertDateToYYYYMMDDHHmmaa(new Date(value as string)),
        NumberDate: () =>
          convertDateToYYYYMMDD(convertNumberDateToDate(value as number)),
        Reference: () =>
          typeof value === "string"
            ? value
            : getObjValueInDeep(
                value as {
                  [key: string]: any;
                },
                header.value as string,
              ),
        Formatter: () => {
          const formatter = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            // These options are needed to round to whole numbers if that's what you want.
            //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
            //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
          });

          return formatter.format(value as number);
        },
        Formatter0: () => {
          const formatter = new Intl.NumberFormat("es-ES", {
            // style: 'currency',
            // currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,

            // These options are needed to round to whole numbers if that's what you want.
            //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
            //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
          });

          return "$" + formatter.format(value as number);
        },
        ArrayObject: () => (
          <PopoverComponent
            id="style_rules_definition_id"
            label={<FontAwesomeIcon icon={faEdit} />}
            trigger="click"
            title=""
            zIndex={header.zIndex}
            placement="bottom"
          >
            <div style={{ minWidth: "500px" }}>
              <EditorComponent
                readOnly
                width={"100%"}
                value={
                  typeof value !== "string"
                    ? JSON.stringify(value, null, "\t")
                    : value
                }
                theme="dark_mitsuketa_tribute"
                height="450px"
              />
            </div>
          </PopoverComponent>
        ),
        Json: () => (
          <PopoverComponent
            id="style_rules_definition_id"
            label={<FontAwesomeIcon icon={faEdit} />}
            trigger="click"
            title=""
            zIndex={header.zIndex}
            placement="bottom"
          >
            <div style={{ minWidth: "500px" }}>
              <EditorComponent
                readOnly
                width={"100%"}
                value={
                  typeof value !== "string"
                    ? JSON.stringify(value, null, "\t")
                    : value
                }
                theme="dark_mitsuketa_tribute"
                height="450px"
              />
            </div>
          </PopoverComponent>
        ),
        [SbxModelField.BOOLEAN]: () => (Boolean(value) ? t("yes") : "No"),
        Boolean: () => (Boolean(value) ? t("yes") : "No"),
        Fixed: () => (typeof value === "number" ? value.toFixed(2) : value),
        List_provider: () => {
          if ((value as ListProviderOption)?.label) {
            return (value as ListProviderOption).label;
          } else if (
            header.format_rules &&
            header.format_rules.columns_labels?.length > 0
          ) {
            return getCompoundName({
              columns: header.format_rules.columns_labels,
              item: value,
            });
          } else {
            if (typeof value === "string") {
              return value;
            }
          }

          return "";
        },
        Year_month: () =>
          convertDateToYYYYMM(convertNumberDateToDate(value + "01")),
        Custom: () =>
          header.customShowColumn
            ? header.customShowColumn(
                value,
                index !== undefined && index >= 0
                  ? index + (localPage - 1) * size
                  : undefined,
              )
            : "",
        Color: () => (
          <div
            className="rounded-circle "
            style={{
              backgroundColor: checkValidColor(value as string)
                ? (value as string)
                : "white",
              height: "15px",
              width: "15px",
            }}
          ></div>
        ),
        Document: () => {
          // debugger
          if (!value) {
            return "";
          }

          async function onConfirmDownload(fileData: Content) {
            const res: any = await getFile(fileData.key);
            if (res.success) {
              await downloadFileService(res.url, res.name);
            }
          }

          if (typeof value === "string" && !IsJsonString(value as string)) {
            if (Array.isArray(JSON.parse(value))) {
            } else {
              value = JSON.stringify([value as string]);
            }
          }

          if (
            IsJsonString(value as string) &&
            Array.isArray(JSON.parse(value as string))
          ) {
            const documents: Content[] = JSON.parse(value as string);

            return (
              <div className="d-flex flex-column gap-2">
                {" "}
                {documents.map((document, index) => (
                  <span
                    key={document.key + "_" + index}
                    onClick={() =>
                      document.name ? onConfirmDownload(document) : null
                    }
                    className="underline d-flex align-items-center gap-2 pointer"
                  >
                    {document.name ? (
                      <>
                        <FileTypeIcon name={document.name} />{" "}
                        {getContentFileName(document)}
                      </>
                    ) : (
                      t("no_file_found")
                    )}
                  </span>
                ))}
              </div>
            );
          }

          return "";
        },
        comma_no_decimal: () =>
        {
          if  (!value && value !== 0) {
            return "";
          }


          return new Intl.NumberFormat("en-US").format(value as number)
        },
        money_comma_decimal: () =>{

          if  (!value && value !== 0) {
            return "";
          }

          return new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(value as number)
        },
        dot_no_decimal: () =>
          new Intl.NumberFormat("es-ES", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }).format(value as number),
        comma: () =>
          new Intl.NumberFormat("en-US", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }).format(value as number),
        comma_decimal: () => {
          return new Intl.NumberFormat("en-US", {
            style: "decimal",
          }).format(value as number);
        },
        two_decimal: () => {
          return new Intl.NumberFormat("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            style: "decimal",
          }).format(value as number);
        },
        money_dot_decimal: () => {
          return new Intl.NumberFormat("es-CO", {
            style: "currency",
            currency: "COP",
          }).format(value as number);
          //Output: $12.000,00
        },
        percentage: () => {
          try {

            if (!value && value !== 0) {
                return "";
            }

            if (typeof value === "number") {
              return value.toFixed(2) + "%";
            }

            if (containsNumbers(value as string)) {
              return parseInt(value as string).toString(2) + "%";
            }

            return value ? (value ?? 0) + "%" : "";
          } catch (e) {
            return value ? (value ?? 0) + "%" : "";
          }
        },
        money_comma_no_decimal: () =>
        {
          if  (!value && value !== 0) {
            return "";
          }

          return new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })
        },
        money_dot_no_decimal: () =>
        {

          if  (!value && value !== 0) {
            return "";
          }


          return "$" +
              new Intl.NumberFormat("es-ES", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }).format(value as number)
        },
        Image: () => {
          let images = IsJsonString(value as string)
            ? JSON.parse(value as string)
            : value;
          if (images) {
            images = Array.isArray(images) ? images : [images];
          }

          return (
            <div>
              {images.filter((item: string) => item).length > 0 ? (
                <RenderImageViewer images={images} />
              ) : (
                <span>{t("custom-message:no-images-message")}.</span>
              )}
            </div>
          );
        },
        Link: () => {
          // console.log("header", header)
          return (
            <span
              className="underline pointer text-link"
              onClick={() => {
                if (header.value) {
                  if (isEncoded(header.value as string)) {
                    const decode = decodeURI(header.value as string);
                    const newUrl = decode.replaceAll(
                      "{row_value}",
                      value as string,
                    );
                    openInNewTab(encodeURI(newUrl));
                  } else {
                    let url = header.value;

                    if (url) {
                      url = url.replaceAll("{row_value}", value as string);
                    }

                    openInNewTab(url as string);
                  }
                }
              }}
            >
              {value as string}
            </span>
          );
        },
        TextColor: () => {
          return (
            <span
              style={{
                color: checkIsValidHexColor(header.value as string)
                  ? (header.value as string)
                  : "black",
              }}
            >
              {value as string}
            </span>
          );
        },
        Report: () => {
          if (header.key) {
            return (
              <div>
                <ButtonComponent
                  label={`${t("report:watch-report")}`}
                  icon={faEye}
                  onClick={() => {
                    dispatch(
                      actionsModal.openModal({
                        size: "xl",
                        noFooter: true,
                        type: ModalTypes.DYNAMIC_COMPONENT_MODAL,
                        title: "Report",
                        component: (
                          <AnalyticsPageComponent
                            baseActions={getBaseAction({
                              ...accountData,
                              extra_query: header.extra_query ?? "",
                              data: row,
                            })}
                            reportKeyProp={header?.key as string}
                          />
                        ),
                      }),
                    );
                  }}
                />
              </div>
            );
          }

          return null;
        },
        Whatsapp: () => {
          return (
            <span
              className="pointer"
              onClick={() => {
                openInNewTab(`https://wa.me/57${value}`);
              }}
            >
              {value as string}{" "}
              <Image
                src={wsIcon}
                alt=""
                style={{ width: "15px", height: "15px" }}
              />
            </span>
          );
        },
        Mailto: () => {
          return (
            <span>
              {value as string}{" "}
              <FontAwesomeIcon
                icon={faEnvelope}
                className="pointer"
                color={"pointer"}
                onClick={() => {
                  window.open(`mailto:${value}`);
                }}
              />
            </span>
          );
        },
      };



      return cases[header.type] && cases[header.type]()
        ? cases[header.type]()
        : ((Array.isArray(value)
            ? (value as string[]).join(", ")
            : value) as string);
    }


    if (value && typeof value === "string") {
      let elementList =[]

      if (IsJsonString(value) && Array.isArray(JSON.parse(value))){
        elementList = JSON.parse(value)
      }else{
        elementList = [value]
      }

      if (containsHTML(elementList)) {
        elementList = transformHtmlArrayToSpan(elementList)
        return <div>
          <ul>
            {elementList.map((element: string, index: number) => {
              return <li key={index}>
                <div dangerouslySetInnerHTML={{__html: element}} />
              </li>
            })}
          </ul>
        </div>
      }
    }


    return (
      Array.isArray(value) ? (value as string[]).join(", ") : value
    ) as string;
  };

  const getRowValue = ({
    row,
    header,
    index,
  }: {
    row: any;
    header: Column;
    index?: number;
  }) => {
    if (header) {
      try {
        if (header?.column_reference?.compound_name && row[header.name]) {
          return (
            getCompoundName({
              columns: [header.column_reference] as any as ColumnsLabels[],
              item: row[header.name],
            }) ?? ""
          );
        } else if (
          header?.value &&
          row[header.name] &&
          row[header.name].hasOwnProperty(header.value)
        ) {
          return getValue({
            value: row[header.name][header.value],
            header,
            index,
            row,
          });
        } else if (
          row[header.name] &&
          (!header.value || header.type === "Reference")
        ) {
          return getValue({ value: row[header.name], header, index, row });
        } else if (
          typeof row[header.name] === "number" &&
          row[header.name] === 0
        ) {
          return getValue({ value: row[header.name], header, index, row });
        }

        return getValue({ value: row[header.name], header, index, row });
      } catch (e) {
        return "";
      }
    }

    return "";
  };

  useEffect(() => {
    if (getColumns) {
      getColumns(columns);
    }
  }, [columns]);

  React.useEffect(() => {
    const newColumns = columnsData
      .filter((c) => !excludeColumns.some((cx) => cx === c.name))
      .map((column) => ({
        ...column,
        isSort: false,
      }));

    setColumns(newColumns);

    setCopyColumns(newColumns);
  }, [excludeColumns, columnsData]);

  const actionsMobileMenu = (row: any, rowIndex: number) => {
    return (
      <UncontrolledButtonDropdown className="mini-menu custom">
        <DropdownToggle className="border-0">
          <FontAwesomeIcon icon={faEllipsisV} />
        </DropdownToggle>
        <DropdownMenu className="position-fixed">
          <DropdownMenuHeader title={"Acciones"} />
          {actions
            ?.filter(({ visible = true }) => visible)
            .map(
              (act, index) =>
                !act.custom &&
                (act.permission ? (
                  <Permission key={index} permission={act.permission}>
                    {getActionButton(row, act, index, rowIndex, true)}
                  </Permission>
                ) : (
                  <React.Fragment key={uuidV4()}>
                    {getActionButton(row, act, index, rowIndex, true)}
                  </React.Fragment>
                )),
            )}
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    );
  };

  const getActionButton = (
    row: any,
    action: Action,
    index: number,
    rowIndex: number,
    isMobile = false,
  ) => {
    if (action.hasOwnProperty("visibleRow") && !action.visibleRow!(row)) {
      return <div />;
    }

    if (isMobile) {
      return (
        <DropdownItem
          disabled={action.disabled}
          key={index}
          title={action.title}
          className={"pl-4 pr-2 " + `text-${action.type}`}
          onClick={() => {
            action.onAction && action.onAction(row, rowIndex);
          }}
        >
          {action.label}
        </DropdownItem>
      );
    }

    return action?.custom && action.customComponent ? (
      action.customComponent(row)
    ) : (
      <Button
        disabled={action.disabled}
        size="sm"
        title={action.title}
        key={index}
        className="mx-1"
        onClick={() => action.onAction && action.onAction(row, rowIndex)}
        color={action.type}
      >
        {action.label}
      </Button>
    );
  };

  const renderActionsMenu = (row: any, rowIndex: number) => {
    switch (actionsType) {
      case "list":
        return actionsMobileMenu(row, rowIndex);
      default:
        return actions
          ?.filter(({ visible = true }) => visible)
          .map((action, index) =>
            action.permission ? (
              <Permission key={index} permission={action.permission}>
                {getActionButton(row, action, index, rowIndex)}
              </Permission>
            ) : (
              <React.Fragment key={uuidV4()}>
                {getActionButton(row, action, index, rowIndex)}
              </React.Fragment>
            ),
          );
    }
  };

  function alterExclude(columnName: string) {
    const exist = excludeColumns.some((cx) => cx === columnName);
    const array = new Array(...excludeColumns);

    if (exist) {
      setExcludeColumns(array.filter((c) => c !== columnName));
    } else {
      array.push(columnName);
      setExcludeColumns(array);
    }
  }

  const filterColumns = (f: string) => {
    return removeDuplicateFromArray(
      [...columnsData, ...(hideColumnsReports ?? [])]?.filter(
        (c) => c.header.toLowerCase().indexOf(f.toLowerCase()) !== -1,
      ) ?? [],
    );
  };

  React.useEffect(() => {
    if (hideColumnsReports && hideColumnsReports.length > 0) {
      setSelection(false);
      setExcludeColumns((prevState) => [
        ...prevState,
        ...hideColumnsReports.map((column) => column.name),
      ]);
    } else {
      setExcludeColumns([]);
    }
  }, [hideColumnsReports]);

  const total_items = totalData || newRows.length;

  const getColumnTotal = (column: Column, calculate: boolean = false) => {
    if (calculate) {



      if (tableData){
        if (column.name === "total_column_by_row") {

          const columns = getColumnsToSumRows().map(column => column.name)
          const value = tableData.getAllTableData().reduce((acc, row, rowIndex) => {

            return acc + getTotalByRow(columns, rowIndex)

          }, 0)



          return getValue({value, header: column});
        }

        const value =  tableData.getTotalByColumn(column)
        return getValue({ value, header: column });
      }else{

        if (column.name === "total_column_by_row") {
          const columns = getColumnsToSumRows().map(column => column.name)
          const value = newRows.reduce((row, rowIndex) => {
            return row + getTotalByRow(columns, rowIndex)
          }, 0)

          return getValue({value, header: column});
        }

        let total = newRows.reduce((total, row) => {
          if (row[column.name]) {
            total += row[column.name];
          }

          return total;
        }, 0);

        if (typeof total === "number") {
          total = parseFloat(total.toFixed(2));
        }

        return getValue({ value: total, header: column });
      }


    }

    return null;
  };


  const getTotalByRow = (columns: string[], rowIndex: number) => {
    if  (tableData){
        return tableData.getTotalByRow(columns, rowIndex)
    }

    if (columns.length > 0) {
      return columns.reduce((total, column) => {
        const value = newRows[rowIndex][column];
        if (value) {
          total += value;
        }
        return total;
      }, 0);
    }

    return null
  }

  const getAverageColumn = (column: Column) => {
    const total = getColumnTotal(column, true);

    if (total) {
      if (typeof total === "number") {
        return newRows.length > 0 ? total / newRows.length : null;
      }

      return total;
    }

    return null;
  };

  const isTableTotal = () => {

    return columns.some((column) => column.isTotalColumn || (column.sub_columns && column.sub_columns.some((subColumn) => subColumn.isTotalColumn)));
  };

  const isTableAverage = () => {
    return columns.some((column) => column.metadata_type?.includes("average") || (column.sub_columns && column.sub_columns.some((subColumn) => subColumn.metadata_type?.includes("average"))));
  };

  const isTableTotalByRow = () => {
    return columns.some((column) => column.metadata_type?.includes("sum_rows") || (column.sub_columns && column.sub_columns.some((subColumn) => subColumn.metadata_type?.includes("sum_rows"))));
  };

  function downloadDataToFile(type: TypeFIle) {
    const text = convertTableRowsToCSVString(columns, rows);
    downloadTextToFile(text, type, convertDateToYYYYMMDD(new Date()));
  }

  React.useEffect(() => {
    // console.log("columnsData", columnsData)
    // console.log("rows", rows)

    const allRows = tableData ? tableData.getAllTableData() : newRows;

    if (removeEmptyColumns && allRows.length > 0 && copyColumns.length > 0) {
      if (tableData) {
        setColumns(tableData.getColumnsContainsValues(copyColumns));
      } else {
        setColumns(
          [...copyColumns].filter((column) =>
            allRows.some((row) => {
              return (
                getRowValue({ row, header: column }) ||
                parseInt(
                  getRowValue({
                    row,
                    header: column,
                  }),
                ) === 0 ||
                (typeof getRowValue({ row, header: column }) === "string" &&
                  getRowValue({
                    row,
                    header: column,
                  }).includes("0.0"))
              );
            }),
          ),
        );
      }
    }
  }, [removeEmptyColumns, newRows, copyColumns, tableData]);

  function parseLocaleNumber(stringNumber: string, locale?: string) {
    const thousandSeparator = Intl.NumberFormat(locale)
      .format(11111)
      .replace(/\p{Number}/gu, "");
    const decimalSeparator = Intl.NumberFormat(locale)
      .format(1.1)
      .replace(/\p{Number}/gu, "");

    return parseFloat(
      stringNumber
        .replace(new RegExp("\\" + thousandSeparator, "g"), "")
        .replace(new RegExp("\\" + decimalSeparator), "."),
    );
  }

  const sortColumn = ({
    column,
    asc = true,
  }: {
    column: Column;
    asc?: boolean;
  }) => {
    const baseData = [...(tableData ? tableData.getAllTableData() : newRows)];
    let nData = [...baseData];

    const nRows = [...nData].sort((a, b) => {
      const aItem = getRowValue({ row: a, header: column });
      const bItem = getRowValue({ row: b, header: column });

      if (typeof aItem === "string" && typeof bItem === "string") {
        if (containsNumbers(aItem) && containsNumbers(bItem)) {
          const sItemA = parseLocaleNumber(aItem.replace("$", ""));
          const sItemB = parseLocaleNumber(bItem.replace("$", ""));

          return asc ? sItemA - sItemB : sItemB - sItemA;
        } else {
          return asc ? aItem.localeCompare(bItem) : bItem.localeCompare(aItem);
        }
      } else if (typeof aItem === "number" && typeof bItem === "number") {
        return asc ? aItem - bItem : bItem - aItem;
      } else if (typeof aItem === "number" && typeof bItem === "string") {
        const sItemB = containsNumbers(bItem) ? parseLocaleNumber(bItem.replace("$", "")) : bItem;
        if (typeof sItemB === "number") {
          return asc ? aItem - sItemB : sItemB - aItem;
        } else {
          return asc ? aItem.toString().localeCompare(sItemB) : sItemB.localeCompare(aItem.toString());
        }
      } else if (typeof aItem === "string" && typeof bItem === "number") {
        const sItemA = containsNumbers(aItem) ? parseLocaleNumber(aItem.replace("$", "")) : aItem;
        if (typeof sItemA === "number") {
          return asc ? sItemA - bItem : bItem - sItemA;
        } else {
          return asc ? sItemA.localeCompare(bItem.toString()) : bItem.toString().localeCompare(sItemA);
        }
      }
      return 0; // En caso de tipos inesperados, no cambiar el orden
    });

    if (tableData) {
      tableData.setTableData(nRows);
      setLocalPage(1)
      onChangePage && onChangePage(1, size);
    } else {
      setNewRows(nRows);
    }


    setColumns((currentColumns) => {
      return currentColumns.map((nColumn) => {
        if (nColumn.name === column.name) {
          nColumn.isSort = !nColumn.isSort;
        }
        return nColumn;
      });
    });
  };

  const filterRowsByColumn = ({
    valueList,
    column,
    search,
  }: {
    valueList?: (string | number)[];
    column: Column;
    search?: string;
  }) => {
    const baseData = [...(tableData ? tableData.getAllTableData() : data)];
    let nData = [...baseData];

    const filters = { ...filtersByTable };

    if (valueList) {
      filters[column.name] = {
        column,
        valueList,
      };
    } else {
      delete filters[column.name];
    }

    if (Object.keys(filters).length > 0) {
      nData = [...baseData].filter((row) => {
        return Object.keys(filters).every((key) => {
          return filters[key].valueList.includes(
            (
              getRowValue({
                row,
                header: filters[key].column,
              }) ?? ""
            ).toString(),
          );
        });
      });
    }



    setFiltersByTable(filters);

    if (search) {
      nData = [...baseData].filter((row) =>
        (
          getRowValue({
            row,
            header: column,
          }) ?? ""
        )
          .toString()
          .toLowerCase()
          .includes(search.toLowerCase()),
      );
    }

    if (nData.length === baseData.length) {
      if (tableData) {
        tableData.restoreTableData();
        // getData && getData(tableData.getTableData());
        onChangePage && onChangePage(1, size);
      } else {
        setNewRows(data);
        getData && getData(data);
      }
    } else {
      if (tableData) {
        tableData.setTableData(nData);
        onChangePage && onChangePage(1, size);
      } else {
        setNewRows(nData);
        getData && getData(nData);
      }
    }

    setLocalPage(1);
  };

  const reportUpdateColumns = ({ columns }: { columns: string[] }) => {
    // console.log("columns", columns)
    if (updateColumnsProps) {
      updateColumnsProps({
        reportProp: "sort",
        value: columns,
        column_name: "",
      });
    }
  };

  const getRow = ({index, column, row, row_index}: {index: number, column: Column, row: any, row_index: number}) => {
    return <td
        key={index}
        // rowSpan={column.rowSpan ?? 1}
        colSpan={column.colSpan ?? 1}
        onClick={() => {
          if (
              column.className?.includes("underline") &&
              row.url
          ) {
            router.push(row.url);
          } else {
            if (rowAction) {
              rowAction(row, column);
            }
          }
        }}
        className={`${column.className ?? ""}`}
    >
      {getRowValue({row, header: column, index: row_index})}
      {column.isDrillDown &&
          getRowValue({
            row,
            header: column,
            index: row_index,
          }) && (
              <ButtonComponent
                  label={""}
                  icon={faInfoCircle}
                  tooltipLabel={t(
                      "custom-message:drill_down_to_see_details",
                  )}
              />
          )}
    </td>
  }

  const getColumnsToSumRows = () => {
    return columns.filter((column) => column.metadata_type?.includes("sum_rows") || (column.sub_columns && column.sub_columns.some((subColumn) => subColumn.metadata_type?.includes("sum_rows"))))
  }

  return (
      <div
          className={"mb-3 " + (shadow ? ` shadow-${shadow} ` : " card main-card")}
      >
        <div className={!shadow ? "card-body" : ""}>
          {loading ? (
              <div className="d-flex justify-content-center">
                <SpinnerComponent/>
              </div>
          ) : (
              <>
                {exportButtons.map((button, index) => (
                    <div
                        className="d-flex justify-content-end mb-3"
                        key={"export_button_" + index}
                    >
                      {button.type === "xls" ? (
                          <ExcelButtonComponent
                              color={button.color}
                              excelHeaders={columns.map((column) => ({
                                label: column.header,
                                value: column.name,
                              }))}
                              excelData={rows}
                              isLoading={loading}
                          />
                      ) : (
                          <Button
                              className="me-1"
                              onClick={() => downloadDataToFile(button.type)}
                              color={button.color}
                              size="sm"
                          >
                            <FontAwesomeIcon icon={faFileExport} className="me-1"/>
                            {`${t("common:export")} ${button.type}`}
                          </Button>
                      )}
                    </div>
                ))}
                {columnsSetting && (
                    <div>
                      <div className="d-flex align-items-center justify-content-end">
                        <span className="mb-2">{t("columns")}:</span>{" "}
                        {
                          <PopoverComponent
                              zIndex={3000}
                              label={
                                <FontAwesomeIcon
                          className="text-gray"
                          icon={faEllipsisV}
                        />
                      }
                      trigger="click"
                      title="Columns"
                      placement="left"
                      id={"pop-columns" + id}
                    >
                      <div
                        style={{
                          maxHeight: "80vh",
                          overflowY: "auto",
                        }}
                      >
                        <FormGroup>
                          <label>
                            {!selectAll ? t("select_all") : t("deselect_all")}
                            <Switch
                              className="ms-1"
                              defaultChecked={selectAll}
                              onChange={(e) => {
                                let newExcludeColumns = [
                                  ...columns.map((c) => c.name),
                                ];

                                if (
                                  hideColumnsReports &&
                                  hideColumnsReports.length > 0
                                ) {
                                  newExcludeColumns = newExcludeColumns.concat(
                                    hideColumnsReports.map((c) => c.name),
                                  );
                                }

                                if (updateColumnsProps) {
                                  reportUpdateColumns({
                                    columns: e ? [] : newExcludeColumns,
                                  });
                                }
                                setExcludeColumns(e ? [] : newExcludeColumns);
                              }}
                            />
                          </label>
                        </FormGroup>
                        <FormGroup>
                          <Input
                            placeholder={t("search")}
                            value={filter}
                            onChange={(e) => setFilter(e.target.value)}
                          />
                        </FormGroup>
                        {filterColumns(filter).map((c, index) => {
                          const checked = !excludeColumns?.some(
                            (cx) => cx === c.name,
                          );
                          return (
                            <div key={c.name + index}>
                              <label className="m-0">
                                <input
                                  onChange={(event) => {
                                    alterExclude(c.name);

                                    const isChecked = event.target.checked;
                                    if (updateColumnsProps) {
                                      let nColumns = [...columns].map(
                                        (column) => column.name,
                                      );
                                      if (isChecked) {
                                        nColumns = [...nColumns, c.name];
                                      } else {
                                        nColumns = nColumns.filter(
                                          (column) => column !== c.name,
                                        );
                                      }

                                      reportUpdateColumns({
                                        columns:
                                          removeDuplicateFromArray(nColumns),
                                      });
                                    }
                                  }}
                                  type="checkbox"
                                  checked={checked}
                                />{" "}
                                {" " +
                                  capitalize(c.header)
                                    .replaceAll("-", " ")
                                    .replaceAll("_", " ")}
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    </PopoverComponent>
                  }
                </div>
              </div>
            )}

            {localFilter && (
              <div className="form-search">
                <input
                  className="form-control"
                  placeholder={t("common:search").concat("...")}
                  type="search"
                  onChange={(e) => setFilterLocal(e.target.value)}
                  value={filterLocal}
                />
              </div>
            )}
            <Table
              className={`table-hover table-bordered ${columns.some(column => column.sub_columns && column.sub_columns.length > 0) ? "d-table" : "d-lg-table d-none"}`}
              responsive
              id={id ?? ""}
            >
              <thead>
                <tr>
                  {(isTableTotal() || isTableAverage()) && (
                    <th
                      style={{ borderBottom: "1px solid #eeeeee" }}
                      className="fw-bold"
                    />
                  )}
                  {columns.filter(column => column.name !=='total_column_by_row').map((column, index) => (
                    <th
                      style={sortableTable ? {
                        ...column.style,
                        whiteSpace: "nowrap",
                        borderBottom: "1px solid #eeeeee",
                        minWidth: column.style?.minWidth ?? "100px",
                        backgroundColor:
                          draggedColumnIndex === index
                            ? "lightgray"
                            : overColumnIndex === index
                              ? "gray"
                              : undefined,
                        cursor: "move",
                      } : {}}
                      className={column.headerClassName ?? ""}
                      draggable={sortableTable}
                      onDragStart={() => sortableTable ? handleDragStart(index) : undefined}
                      onDragEnter={() => sortableTable ?  handleDragEnter(index) : undefined}
                      onDragEnd={() => sortableTable ? handleDragEnd() : undefined}
                      rowSpan={column.rowSpan ?? 1}
                      colSpan={column.colSpan ?? 1}
                      key={`column_${
                        column.isSort ? "sorteable_" : ""
                      }${index}`}
                    >
                      <span className="d-flex align-items-center justify-content-between">
                        <span className="d-flex">
                          <span>{column.header}</span>
                          {column.sub_columns && column.sub_columns?.length > 0 ? null : <TableColumnFilterMenuComponent
                              sortColumn={({asc}) =>
                                  sortColumn({asc, column})
                              }
                              filterRowsByColumn={({valueList, search}) =>
                                  filterRowsByColumn({
                                    column,
                                    valueList,
                                    search,
                                  })
                              }
                              columnItems={tableData ? tableData.columnItems(column) : removeDuplicateFromArray(data.map((row) => getRowValue({
                                row,
                                header: column,
                              })))
                              }
                          />}
                        </span>

                        {isReportTable && (
                          <span>
                            <TableCustomColumnMenuComponent
                              updateColumnsProps={updateColumnsProps}
                              column={column}
                              sbxModels={sbxModels}
                              columnItems={removeDuplicateFromArray(
                                data.map((row) =>
                                  getRowValue({
                                    row,
                                    header: column,
                                  }),
                                ),
                              )}
                            />
                          </span>
                        )}
                      </span>

                      {column.action && column.action}
                    </th>
                  ))}
                  {isTableTotalByRow() && (
                      <th
                          style={{
                            borderBottom: "1px solid #eeeeee",
                          }}
                          rowSpan={ 1}
                          colSpan={ 1}
                          key={`column_total`}
                      >
                      <span className="d-flex align-items-center justify-content-between">
                        <span className="d-flex">
                          Total
                        </span>

                        {isReportTable && (
                            <span>
                            <TableCustomColumnMenuComponent
                                updateColumnsProps={updateColumnsProps}
                                column={columns.find(column => column.name === 'total_column_by_row') ?? {
                                  name: 'total_column_by_row'
                                }  as Column}
                                columnItems={[getTotalByRow(getColumnsToSumRows().map(column => column.name), 0)] ?? []}
                            />
                          </span>
                        )}
                      </span>
                      </th>
                  )}
                  {actions && actions.length > 0 && (
                      <th
                          className={actionsColumnClass ?? ""}
                          style={{borderBottom: "1px solid #eeeeee"}}
                      >
                        {actionsColumnLabel ?? ""}
                      </th>
                  )}
                </tr>
                {columns && columns.length > 0 && columns.some(column => column.sub_columns && column.sub_columns.length > 0) ?
                    <tr>
                      {(isTableTotal() || isTableAverage()) && (
                          <th
                              style={{borderBottom: "1px solid #eeeeee"}}
                              className="fw-bold"
                          />
                      )}
                      {columns.filter(column => column.sub_columns).map((column, index) => (
                          column.sub_columns && column.sub_columns.map((subColumn, subIndex) => (
                              <th rowSpan={subColumn.rowSpan ?? 1}
                                  colSpan={subColumn.colSpan ?? 1}
                                  style={{
                                    whiteSpace: 'nowrap',
                                    ...(subColumn.style ?? {})}}
                                  key={`${column.name}_${index}_${subColumn.name}_${subIndex}`}>
                            <span className="d-flex align-items-center justify-content-between">
                        <span className="d-flex">
                          <span>{subColumn.header}</span>
                          <TableColumnFilterMenuComponent
                              sortColumn={({asc}) =>
                                  sortColumn({asc, column: subColumn})
                              }
                              filterRowsByColumn={({valueList, search}) =>
                                  filterRowsByColumn({
                                    column: subColumn,
                                    valueList,
                                    search,
                                  })
                              }
                              columnItems={tableData ? tableData.columnItems(subColumn) : removeDuplicateFromArray(data.map((row) => getRowValue({
                                row,
                                header: subColumn,
                              })))
                              }
                          />
                        </span>

                              {isReportTable && (
                                  <span>
                            <TableCustomColumnMenuComponent
                                updateColumnsProps={updateColumnsProps}
                                column={subColumn}
                                sbxModels={sbxModels}
                                columnItems={removeDuplicateFromArray(
                                    data.map((row) =>
                                        getRowValue({
                                          row,
                                          header: subColumn,
                                        }),
                                    ),
                                )}
                            />
                          </span>
                              )}
                      </span>
                              </th>
                          ))
                      ))}


                    </tr> : null}
              </thead>
              <tbody>
              {rows.map((row, row_index) => (
                  <tr
                      key={`row_${row.id ?? uuidV4()}_${row_index}`}
                    className={`${
                      tableShading ? "pointer shading py-5" : "py-5"
                    }`}
                  >
                    {(isTableTotal() || isTableAverage()) && <td />}

                    {columns.filter(column => column.name !=='total_column_by_row').map((column, index) => (

                        column.sub_columns && column.sub_columns.length > 0 ? column.sub_columns.map((subColumn, subIndex) => (
                                <Fragment key={`${column.name}_${index}_${subColumn.name}_${subIndex}`}>
                                  {getRow({index, column: subColumn, row, row_index})}
                                </Fragment>
                            ))

                            :
                            getRow({index, column, row, row_index})

                    ))}

                    {isTableTotalByRow() && getColumnsToSumRows().length > 0 ? <td className={`${columns.find(column => column.name === 'total_column_by_row')?.type?.includes(
                        "money")
                        ? "text-end"
                        : "text-start"}`}>
                      {getValue({
                        value: getTotalByRow(getColumnsToSumRows().map(column => column.name), row_index) ?? 0,
                        header: columns.find(column => column.name === 'total_column_by_row') ?? {
                          name: 'total_column_by_row'
                        } as Column,
                      })}
                    </td> : null}

                    {actions && actions.length > 0 && (
                      <td className="d-table-cell ">
                        <div
                          className={
                            actionsColumnClass ?? "d-flex justify-content-end"
                          }
                        >
                          {actions.some((action, index) =>
                            action.hasOwnProperty("visibleRow")
                              ? action.visibleRow!(row)
                              : true,
                          ) && renderActionsMenu(row, row_index)}
                        </div>
                      </td>
                    )}
                  </tr>
                ))}

                {isTableTotal() && rows.length > 0 && (
                  <>
                    <tr>
                      <td className="fw-bold ">Total</td>
                      {columns.map((column, index) => (
                          column.sub_columns && column.sub_columns.length > 0 ? column.sub_columns.map((subColumn, subIndex) => (
                                  <td
                                      key={`total_${column.name}_${index}_${subColumn.name}_${subIndex}`}
                                      colSpan={subColumn.colSpan ?? 1}
                                      className={`fw-bold  ${
                                          subColumn.headerClassName ??
                                          subColumn.type?.includes(
                                              "money")
                                              ? "text-end"
                                              : "text-start"
                                      } `}
                                      style={subColumn.style ?? {}}
                                  >
                                    {getColumnTotal(subColumn, subColumn.isTotalColumn || column.name === "total_column_by_row")}
                                  </td>
                              )) :
                              <td
                                  key={index}
                                  colSpan={column.colSpan ?? 1}
                                  className={`fw-bold  ${
                                      column.headerClassName ??
                                      column.type?.includes("money")
                                          ? "text-end"
                                          : "text-start"
                                  } `}
                                  style={column.style ?? {}}
                              >
                                {getColumnTotal(column, column.isTotalColumn || column.name === "total_column_by_row")}
                        </td>
                      ))}
                    </tr>
                  </>
                )}

              {isTableAverage() && rows.length > 0 && (
                  <>
                    <tr>
                      <td className="fw-bold ">{t("average")}</td>


                      {columns.map((column, index) => {
                        if (column.sub_columns && column.sub_columns.length > 0) {
                          return column.sub_columns.map((subColumn, subIndex) => (
                              <td
                                  key={`average_${column.name}_${index}_${subColumn.name}_${subIndex}`}
                                  colSpan={subColumn.colSpan ?? 1}
                                  className={`fw-bold  ${
                                      subColumn.headerClassName ??
                                      subColumn.type?.includes("money")
                                          ? "text-end"
                                          : "text-start"
                                  } `}
                                  style={subColumn.style ?? {}}
                              >
                                {subColumn.metadata_type?.includes("average")
                                    ? getAverageColumn(subColumn)
                                    : null}
                              </td>
                          ));

                        }
                        const avg = column.metadata_type?.includes("average")
                            ? getAverageColumn(column)
                            : null;
                        return (
                            <td
                                key={index}
                                colSpan={column.colSpan ?? 1}
                                className={`fw-bold  ${
                                    column.headerClassName ??
                                    column.type?.includes("money")
                                        ? "text-end"
                                        : "text-start"
                                } `}
                                style={column.style ?? {}}
                            >
                              {avg}
                            </td>
                        );
                      })}
                    </tr>
                  </>
                )}
              </tbody>
            </Table>

                {!columns.some(column => column.sub_columns && column.sub_columns.length > 0) &&
                    <div className="d-lg-none d-block custom px-3">
                      {rows.map((row, row_index) => {
                        return (
                            <Card key={row_index} className="my-2">
                              <CardBody className="d-flex align-items-center">
                                <div className="w-100">
                                  {columns.map((column, index) => (
                                      <span key={index}>
                            {getRowValue({
                              row,
                              header: column,
                              index: row_index,
                            }) && (
                                <div
                                    className="m-0"
                                    onClick={() =>
                                        rowAction ? rowAction(row, column) : undefined
                                    }
                                >
                                  <b>{column.header}:</b>{" "}
                                  {getRowValue({
                                    row,
                                    header: column,
                                    index: row_index,
                                  })}
                                </div>
                            )}
                          </span>
                                  ))}
                                </div>
                                {actions && actionsMobileMenu(row, row_index)}
                              </CardBody>
                            </Card>
                        );
                      })}
                    </div>}
          </>
        )}
        {!total_items && !loading && (
          <div className="text-center py-5">{t("empty_table")}</div>
        )}
        {!!total_items && pagination && (
          <div className="d-flex justify-content-between  align-items-center px-2">
            <div>
              <small className="text-gray">
                {size * localPage > total_items
                  ? total_items
                  : size * localPage}{" "}
                of {total_items}
              </small>
            </div>
            <Pagination
              disabled={loading}
              current={pageHistory ? localPage : currentPage}
              onShowSizeChange={(p, s) => {
                onShowSizeChange && onShowSizeChange(p, s);
                setSize(s);
              }}
              pageSizeOptions={[
                sizePerPage.toString(),
                "30",
                "60",
                "100",
                "500",
                "1000",
              ]}
              showSizeChanger={total_items > sizePerPage && showSizeChanger}
              onChange={(page) =>
                useLocalPage
                  ? setLocalPage(page)
                  : pageHistory
                    ? dispatch(actionHistory.changePage(page))
                    : onChangePage && onChangePage(page, size)
              }
              pageSize={size}
              total={total_items}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomTableComponent;
