import React, {useState} from 'react';
import {Offcanvas, OffcanvasBody, OffcanvasHeader} from 'reactstrap';
import {AnalyticQuery} from '../../../types/Analytic';

import dynamic from "next/dynamic";
import {getAnalyticQueryFlat} from "../../../utils/analyticsUtils";

const ReactJson = dynamic(() => import("react-json-view"), {ssr: false})


type Props = {
  query: AnalyticQuery
  toggleCanvas: () => void
}

const ReportQueryPreview = ({query, toggleCanvas}: Props) => {

  const [localQuery, setLocalQuery] = useState<null | AnalyticQuery>(null)

  React.useEffect(() => {

    if (query){
      const nQuery = {...query}

      if (!nQuery.truncate || nQuery.truncate === "" || nQuery.truncate === "{}"){
        delete nQuery.truncate
      }

      setLocalQuery(nQuery)

    }

  }, [query]);


  return (
    <Offcanvas
      direction="start"
      toggle={toggleCanvas}
      zIndex={2050}
      isOpen
    >
      <OffcanvasHeader className="d-flex align-items-center justify-content-between" toggle={toggleCanvas}>
        Preview Query
      </OffcanvasHeader>
      <OffcanvasBody>
        {localQuery && <ReactJson src={getAnalyticQueryFlat(localQuery)}/>}
      </OffcanvasBody>
    </Offcanvas>
  );
};

export default ReportQueryPreview;
