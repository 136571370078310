import fetchJson from "../../lib/fetchJson";
import { TaskProcess } from "../../types/Task";
import { get, post, put } from "../../network";
import { EventProcess, ProcessModel, Sequence, } from "../../types/models/processModel/Process";
import { sortSequences, sortTaskEventBySequences } from "../../utils";
import { TemplateWhatsapp } from "../../types/Template";

export const createOrUpdateProcessModel = async (
  token: string,
  processModel: ProcessModel,
) => {
  if (!token?.toLowerCase().startsWith("bearer")) {
    token = `Bearer ${token}`;
  }

  try {
    return await fetchJson(`/api/v2/wf/process/model/workflow`, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        authorization: token,
        "Content-Type": "application/json",
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer",
      body: JSON.stringify(processModel),
    });
  } catch (error: any) {
    console.error(error);
    return { success: false, error: error.message };
  }
};

export const updateProcessTasks = async (token: string, task: TaskProcess) => {
  if (!token?.toLowerCase().startsWith("bearer")) {
    token = `Bearer ${token}`;
  }

  try {
    return await fetchJson(
      `/api/v2/wf/process/model/${task.process_id}/task/${task.id}`,
      {
        method: "PUT", // *GET, POST, PUT, DELETE, etc.
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
          authorization: token,
          "Content-Type": "application/json",
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer",
        body: JSON.stringify(task),
      },
    );
  } catch (error: any) {
    console.error(error);
    return { success: false, error: error.message };
  }
};

export const updateProcessEvent = async (
  token: string,
  event: EventProcess,
) => {
  if (!token?.toLowerCase().startsWith("bearer")) {
    token = `Bearer ${token}`;
  }

  try {
    return await fetchJson(
      `/api/v2/wf/process/model/${event.process_id}/event/${event.id}`,
      {
        method: "PUT", // *GET, POST, PUT, DELETE, etc.
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
          authorization: token,
          "Content-Type": "application/json",
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer",
        body: JSON.stringify(event),
      },
    );
  } catch (error: any) {
    console.error(error);
    return { success: false, error: error.message };
  }
};

export const updateProcessSequence = async (sequence: Sequence) => {
  return put(
    `/api/v2/wf/process/model/${sequence.process_id}/sequence/${sequence.id}`,
    sequence,
  );
};

export const getProcessById = async (token: string, process_id: number) => {
  if (!token?.toLowerCase().startsWith("bearer")) {
    token = `Bearer ${token}`;
  }

  try {
    return await fetchJson(`/api/v2/wf/process/model/${process_id}`, {
      method: "GET",
      cache: "no-cache",
      headers: {
        authorization: token,
        "Content-Type": "application/json",
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
    });
  } catch (error: any) {
    console.error(error);
    return { success: false, error: error.message };
  }
};

export function getProcessModelByIdService(id: number | string) {
  return get(`/api/v2/wf/process/model/${id}`);
}

export async function sortTaskBySequences(processId: number) {
  const resProcess = await getProcessModelByIdService(processId);
  if (!resProcess.success) return [];
  const { events, tasks, sequences, gateways } = resProcess.item;
  const sequencesSorted = sortSequences(sequences, events);
  return sortTaskEventBySequences(tasks, sequencesSorted, events, gateways);
}

export const getListModelProcess = () => {
  return get("/api/v2/wf/process").then((res) => res);
};

export const getModelProcessDataFields = (process_id: number) => {
  return get(`/api/v2/wf/process/model/${process_id}/data_fields`)
    .then((result) => result)
    .catch((error) => error);
};
export const getDataStoreList = (process_id: number | string) => {
  return get(`/api/v2/wf/process/model/${process_id}/data_store`)
    .then((result) => result)
    .catch((error) => error);
};

export const getAllProcessExecutionService = (params?: {
  client?: string;
  process?: number;
  page?: number;
  size?: number;
  end_date?: number;
  start_date?: number;
}) => {
  return get(`/api/v2/wf/process/execution`, params);
};

export const updateProcessModelService = (params: ProcessModel) => {
  return put(`/api/v2/wf/process/model/${params.id}`, params);
};

export const createOrUpdateProcessModelService = (
  processModel: ProcessModel,
) => {
  return post("/api/v2/wf/process/model/workflow", processModel);
};

export const saveWhatsappTask = async (
  action: {
    key: string
    callback_url: string
    template_id: number | undefined
  },
  token: string,
  callback: string
) => {
  if (!token?.toLowerCase().startsWith("bearer")) {
    token = `Bearer ${token}`;
  }

  try {
    return await fetchJson(`/api/v1/crm_actions`, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        authorization: token,
        "callback": callback,
        "Content-Type": "application/json",
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer",
      body: JSON.stringify(action),
    });
  } catch (error: any) {
    console.error(error);
    return {success: false, error: error.message};
  }
};

export const getTemplates = (token: string) => {
  return get("/api/v1/templates",
      {},
      {Authorization: "Bearer " + token}
  ).then(res => res as unknown as TemplateWhatsapp[]);
};

export const launchProcessService = (
  processId: number,
  params?: { account?: string; fields: any[] },
) => {
  return post(`/api/v2/wf/process/launch/${processId}`, params);
};

export const updateProcessExecutionService = (
  processId: number,
  processExecutionId: number,
  params: {
    crm_account: string;
  },
) => {
  return post(
    `/api/v2/wf/process/${processId}/execution/${processExecutionId}`,
    params,
  );
};

export const fixEmptyProcessDataService = (
  processId: number,
  processExecutionId: number,
) => {
  return get(
    `/api/v2/wf/process/${processId}/execution/${processExecutionId}/fix_empty_process_data`,
  );
};
