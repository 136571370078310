import React, {useCallback, useEffect, useState} from 'react';
import ModalComponent from './ModalComponent/ModalComponent';
import {actionsModal, ModalTypes} from '../../../store/Modal/Slice';
import {useDispatch, useSelector} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSearch, faSpinner, faTimesCircle} from '@fortawesome/free-solid-svg-icons';
import {RootState} from '../../../store/Reducers';
import {PermissionStates} from '../../../store/Permission/Types';
import {actionsPermissions} from '../../../store/Permission/Slice';
import useTranslate from '../../../hooks/useTranslate';
import {getGroupById} from '../../../services/backend/PermissionsService';
import {findByModel} from '../../../services/backend/SbxService';

import {State} from '../../../types/State';
import {UserInfo} from '../../../types/UserInfo';
import CustomTableComponent, {Column} from '../CustomTableComponent/CustomTableComponent';
import {debounceTime, removeItemFromArray} from '../../../utils';
import useAsyncEffect from "../../../hooks/useAsyncEffect";
import {Find} from "sbxcorejs";
import {Query} from "../QueryComponent/QueryComponent";
import TabContents from "../TabContents";
import {Andor, GroupCondition, SbxConditionType} from "../../../types/Sbx";


interface IProps {
  open: boolean;
  data: IPropsGroupUserModal;
}


export interface IPropsGroupUserModal {
  type: ModalTypes.UPDATE_GROUP_USER;
  id: number;
}

export interface IPropsGroupUser {
  avatar?: string;
  code?: string;
  code_expiration_at?: string;
  domain_id: number;
  email: string;
  enabled: boolean;
  first_name: string;
  from_source?: string;
  full_name: string;
  user_id: number;
  id?: number;
  last_name: string;
  username: string;
  check: boolean;
}


const row_model = 'sbx_crm_user';

const tableColumns: Column[] = [
  {
    type: 'String',
    name: `first_name`,
    header: 'First name'
  },
  {
    type: 'String',
    name: `last_name`,
    header: 'Last name'
  },
  {
    type: 'String',
    name: 'email',
    header: 'Email'
  },
  {
    type: 'ReactNode',
    name: 'activeInput',
    header: 'Is active '
  }
];

const GroupAddUserModal = (props: IProps) => {
  const {open, data} = props;
  const dispatch = useDispatch();
  const {state, user} = useSelector((state: RootState) => state.PermissionReducer);
  const [userList, setUserList] = useState<IPropsGroupUser[]>([]);
  const [userCheck, setUserCheck] = useState<number[]>([]);
  const [userUnCheck, setUserUnCheck] = useState<number[]>([]);
  const [userGroupList, setUserGroupList] = useState<UserInfo[]>([]);
  const [newRowsTable, setNewRowsTable] = useState<Array<IPropsGroupUser>>([]);
  const [loading, setLoading] = useState<State>(State.IDLE);
  const [loadingCheck, setLoadingCheck] = useState(State.IDLE);
  const {t} = useTranslate('permissions-manager');
  const [search, setSearch] = useState('');
  const [isOnlyGroup, setIsOnlyGroup] = useState(false);
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0)

  function check(userList: IPropsGroupUser[], userGroupList: UserInfo[]) {
    let temp = userList.map((res) => {
      return {
        ...res, check: false, activeInput:
          <input type="checkbox" checked={false} onChange={() => onChange(data.id, res.user_id, res.check)}/>
      };
    });
    let inArray = temp;
    for (let i = 0; i < temp.length; ++i) {
      for (let j = 0; j < userGroupList.length; ++j) {
        if (userGroupList[j].id === temp[i].user_id) {
          inArray[i].check = true;
          inArray[i].activeInput =
            <input type="checkbox" checked={true}
                   onChange={() => onChange(data.id, inArray[i].user_id, inArray[i].check)}/>
        }
      }
    }
    setNewRowsTable(inArray);
  }

  function onChange(groupId: number, userId: number, type: boolean) {
    let existsIds = userGroupList.find((data) => data.id === userId);
    const exit = userUnCheck.find((res) => res === userId)
    const exitCheck = userCheck.find((res) => res === userId)
    let user: any = userList.filter((res: IPropsGroupUser) => (res.user_id === userId));
    user = user[0]
    if (user) {
      user.id = userId
      delete user._KEY;
      delete user?._META
    }
    if ((existsIds || exit) && !exitCheck) {
      if (type) {
        const newListGroup = userGroupList.filter((resBy) => (resBy?.id !== userId))
        setUserGroupList(newListGroup)
        setUserUnCheck([...userUnCheck, userId])
      } else {
        user && setUserGroupList([...userGroupList, user])
        setUserUnCheck(removeItemFromArray(userId, userUnCheck))
      }
    } else {
      if (!type) {
        setUserCheck([...userCheck, userId])
        user && setUserGroupList([...userGroupList, user])
      } else {
        const newListGroup = userGroupList.filter((resBy) => (resBy?.id !== userId))
        user && setUserGroupList(newListGroup)
        setUserCheck(removeItemFromArray(userId, userCheck))
      }
    }
    setLoadingCheck(State.RESOLVED);
  }

  function stateRejected() {
    setLoading(State.REJECTED);
    setLoadingCheck(State.REJECTED);
  }

  const getUsers = async (page = 1, size = 15, noSearch?: boolean) => {
    let query = new Find(row_model, 0)
    query.andWhereIsEqualTo("enabled", true)

    if (search && !noSearch) {
      query.newGroupWithAnd()
      query.andWhereItContains("first_name", search)
      query.orWhereItContains("last_name", search)
      query.orWhereItContains("email", search)
    }

    query = query.compile()
    return findByModel({row_model, where: (query as any as Query).where, page, size});
  };

  const setUsersData = async (groupId: number, users: IPropsGroupUser[], total_items: number) => {
    const res = await getGroupById(groupId);
    if (res.success) {
      setUserGroupList(res.item.users);
      setUserList(users);
      setTotalItems(total_items);
      setLoadingCheck(State.RESOLVED);
      setLoading(State.RESOLVED);
    } else {
      stateRejected();
    }
  }

  const callback = useCallback(async (noSearch?: boolean) => {
    if (!isOnlyGroup) {
      setLoading(State.PENDING);
      const resAllUsers = await getUsers(page, 15, noSearch);
      if (data.id && resAllUsers && resAllUsers.success) {
        await setUsersData(data.id, resAllUsers.items, resAllUsers.total_items)
      } else {
        stateRejected();
      }
    }

  }, [data.id, user, page, isOnlyGroup])

  useAsyncEffect(async () => callback(), [callback]);



  useEffect(() => {
    check(userList, userGroupList);
  }, [userList, userGroupList, loadingCheck]);


  function toggle() {
    dispatch(actionsModal.closeModal({type: ModalTypes.UPDATE_GROUP_USER}));
  }

  const searchUsers = async (params?: GroupCondition[]) => {
    setLoading(State.PENDING);
    let query = new Find(row_model, 0);

    query.andWhereIsEqualTo("enabled", true);
    query.newGroupWithAnd();

    if (!params) {
      query.andWhereItContains("first_name", search);
      query.orWhereItContains("last_name", search);
      query.orWhereItContains("email", search);
    }

    params?.forEach(con => {
      switch (con.OP) {
        case SbxConditionType.EQUAL_TO:
          if (con.ANDOR === Andor.AND) {
            query.andWhereIsEqualTo(con.FIELD, con.VAL);
          } else {
            query.orWhereIsEqualTo(con.FIELD, con.VAL);
          }
          break;
      }
    })

    query = query.compile();

    const response = await debounceTime(findByModel, {
      row_model,
      where: (query as any as Query).where,
      page: 1,
      size: params?.length ?? 15
    }, 500);

    if (response?.success) {
      await setUsersData(data.id, response.items, response.total_items)
    } else {
      stateRejected()
    }
  }


  React.useEffect(() => {
    if (state === PermissionStates.GROUP_RESOLVED && loadingCheck === State.PENDING) {
      setLoadingCheck(State.RESOLVED)
      toggle()
    }

  }, [state]);

  useAsyncEffect(async () => {
    if (isOnlyGroup) {
      await searchUsers(userGroupList.map(grupUser => {
        return {
          VAL: grupUser.id,
          FIELD: "user_id",
          ANDOR: Andor.OR,
          OP: SbxConditionType.EQUAL_TO
        }
      }))
    }
  }, [isOnlyGroup])

  function onSubmit(groupId: number) {
    setLoadingCheck(State.PENDING);
    dispatch(actionsPermissions.arrayMembership({groupId, userUnCheck, userCheck}))
    setUserCheck([])
    setUserUnCheck([])
  }


  return (
    <ModalComponent
      isLoading={loading === State.PENDING}
      size={'lg'}
      title={<>{' ' + t('membership')}</>}
      isOpen={open}
      form={'new_group_form'}
      footer={(
        <div className="text-right">
          <button
            onClick={() => onSubmit(data.id)}
            disabled={State.PENDING === loadingCheck}
            className="btn btn-primary btn-sm mx-2">
            {t('common:save')} {loadingCheck === State.PENDING && <FontAwesomeIcon icon={faSpinner} spin={true}/>}
          </button>
          <button
            onClick={() => toggle()}
            className="btn btn-light btn-sm">
            {t('common:close')}
          </button>
        </div>
      )}
      toggle={() => toggle()}>
      <>
        <TabContents
          reloadInfo
          tabs={[
            {
              label: "All users",
              loaded: () => setIsOnlyGroup(false),
              component: (
                <>
                  <div className={"input-group mb-3 " + (isOnlyGroup ? "d-none" : "")}>
                    <input type="text"
                           className="form-control border-end-0"
                           value={search}
                           onChange={event => setSearch(event.target.value)} placeholder={t('common:search')}
                           onKeyDown={(event) => { if(event.key === 'Enter') { searchUsers() } }}
                           aria-describedby="basic-addon2"/>
                    {search && (
                      <div
                        className="d-flex flex-column justify-content-center border-top border-bottom px-3 bg-white pointer">
                        <span className="" id="basic-addon2" onClick={async () => {
                          setSearch("")
                          await callback(true);
                        }}>
                          <FontAwesomeIcon icon={faTimesCircle}/>
                        </span>
                      </div>
                    )}
                    <div className="input-group-append bg-white pointer">
                      <span className="input-group-text bg-white" id="basic-addon2" onClick={() => searchUsers()}>
                        <FontAwesomeIcon icon={faSearch}/>
                      </span>
                    </div>
                  </div>

                  <CustomTableComponent
                    key={"tap-1"}
                    loading={loading === State.PENDING}
                    useLocalPage
                    totalData={totalItems}
                    columns={tableColumns}
                    onChangePage={(page) => {
                      setPage(page);
                    }}
                    showSizeChanger={false}
                    currentPage={page}
                    data={newRowsTable}
                  />
                </>
              )
            },
            {
              label: "Only this group",
              loaded: async () => {
                if (search) {
                  await callback(true);
                }
                setIsOnlyGroup(true)
              },
              component: (
                <>
                  <CustomTableComponent
                    key={"tap-2"}
                    localFilter
                    loading={loading === State.PENDING}
                    useLocalPage
                    columns={tableColumns}
                    showSizeChanger={false}
                    currentPage={page}
                    data={newRowsTable}
                  />
                </>
              ),

            }
          ]}/>

      </>
    </ModalComponent>
  );
};

export default GroupAddUserModal;
