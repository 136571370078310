import React, {useContext} from 'react';
import SourceQuerySelectorComponent from './SourceQuerySelectorComponent';
import {Button} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEye, faTrash} from '@fortawesome/free-solid-svg-icons';
import {ReportGeneratorContext} from '../ReportGeneratorComponent';
import {Source} from '../../../types/Analytic';
import {UpdateSource} from './SourceComponent';
import useTranslate from "../../../hooks/useTranslate";
import {Tooltip} from "antd";

type Props = {
  source: Source
  sources: Source[]
  currentSource: Source
  updateSource: (sources: UpdateSource[]) => void
  setCurrentSource: (source: Source) => void
}

const SourceItemComponent = ({source, updateSource, sources, setCurrentSource, currentSource}: Props) => {
  const {reportState: {analyticQuery}} = useContext(ReportGeneratorContext);

  const {t} = useTranslate('common');
  return (
    <div
      className="d-flex flex-column border-top border-night-sky p-3 mb-2 "
      key={source.temporal_id}>

      <div className="">
        <div className="text-end">

          <Button outline={currentSource.temporal_id !== source.temporal_id} color={"primary"} onClick={() => setCurrentSource(source)}>
            <FontAwesomeIcon icon={faEye} className="pointer" />
          </Button>
        </div>
        <SourceQuerySelectorComponent isMerge source={source} updateSource={updateSource}/>
      </div>


      <div className="d-flex align-items-center justify-content-end gap-2">
        <Tooltip title={t("remove") + " " + t("source")}>
          <Button color={'danger'} size={'sm'}
                  onClick={() => {
                    const newSources = sources.filter(nSource => nSource.temporal_id !== source.temporal_id);

                    updateSource([{
                      sourceId: analyticQuery.source.temporal_id ?? "",
                      sourceKey: 'sources',
                      sourceValue: newSources
                    }]);
                  }}>
            <FontAwesomeIcon icon={faTrash} className="mr-1"/>
            <span>{t("remove") + " " + t("source")}</span>
          </Button>
        </Tooltip>
      </div>
      {/*{isOpenCanvas && <ReportPreviewComponentWrapper source={source} updateSource={updateSource}/>}*/}
    </div>
  );
};

export default SourceItemComponent;
