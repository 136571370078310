import React, {useEffect, useState} from "react";
import {RootState} from "../../../store/Reducers";
import {useSelector} from "react-redux";
import LogoutCard from "../../Shared/LogoutCard";
import SearchBox from "../../Shared/SearchBox";
import useDeviceDetect from "../../../hooks/useDeviceDetect";
import LanguageMenuComponent from "../../Shared/LanguageMenuComponent/LanguageMenuComponent";



type Props = {
  sidebarOpen: boolean;
  setSidebarOpen: (open: (prevState: boolean) => boolean) => void;
}

const NavBarComponent = ({sidebarOpen, setSidebarOpen}: Props) => {

  const {user: {full_name, email, login_as_id, config}} = useSelector((state: RootState) => state.AuthReducer);


  const [mobileOpen, setMobileOpen] = useState(false);
  const [timeout, setTimeOut] = useState<any>(null);
  const {isMobile} = useDeviceDetect();


  useEffect(() => {
    if (sidebarOpen) {
      if (timeout) {
        clearTimeout(timeout);
        setTimeOut(null);
        return;
      }
      const time = setTimeout(() => {
        setSidebarOpen(prevState => false);
        setTimeOut(null);
      }, 5000);
      setTimeOut(time);
    }
  }, [sidebarOpen])

  return (
    <div className="app-header header-shadow">
      <div className="app-header__logo">
        <div
          style={{background: `url("${config?.sbx_crm?.sbx_crm_image ?? "../../../public/assets/images/logo.png"}") no-repeat center`}}
          onClick={() => setSidebarOpen(prevState => !prevState)}
             className={`logo-src`}/>

        <div className="header__pane ms-auto">
          <div>
            <button
              onClick={() => setSidebarOpen(prevState => !prevState)}
              className={`hamburger close-sidebar-btn hamburger--elastic`}>
              <span className="hamburger-box">
                <span className="hamburger-inner"/>
              </span>
            </button>
          </div>
        </div>
      </div>
      <div className="app-header__mobile-menu">
        <div>
          <button onClick={() => setSidebarOpen(prevState => !prevState)}
                  className={`hamburger hamburger--elastic mobile-toggle-nav ${sidebarOpen ? "is-active" : ""}`}>
            <span className="hamburger-box">
              <span className="hamburger-inner"/>
            </span>
          </button>
        </div>
      </div>
      <div className="app-header__menu">
        <span>
          <button
            onClick={() => setMobileOpen(!mobileOpen)}
            type="button"
            className={"btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav " + (mobileOpen ? "active" : "")}>
            <span className="btn-icon-wrapper">
              <i className="fa fa-ellipsis-v fa-w-6"/>
            </span>
          </button>
        </span>
      </div>
      <div className={"app-header__content " + (mobileOpen ? "header-mobile-open" : "")}>
        <div className="app-header-left">
          <SearchBox/>
          {!isMobile && !sidebarOpen && (
            <div className="ms-3 d-lg-block d-none">
              <img
                onClick={() => setSidebarOpen(prevState => !prevState)}
                style={{height: "40px"}}
                // src="https://gaspais.com.co/wp-content/uploads/2020/08/LOGO-GAS-PAIS.png"
                src={config?.sbx_crm?.sbx_crm_image ?? "" ?? "../../../public/assets/images/logo.png"}
                alt=""/>
            </div>
          )}
        </div>
        <div className="app-header-right">
          <LanguageMenuComponent />
          <LogoutCard fullName={`${login_as_id ? "Logged-in as: " : ""}` + full_name} email={email}/>
        </div>
      </div>
    </div>
  )
}

export default NavBarComponent;
