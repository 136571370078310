import * as React from 'react';
import {useContext} from 'react';
import ColumnsActionsWrapperComponent from "../../ColumnsActionsWrapperComponent";
import {DropdownItem} from "reactstrap";
import {getColumnName} from "../utils";
import SearchSuggestionOption from "../../SearchSuggestionOption";
import {ReportContext} from "../../NewReportGeneratorComponent";
import useTranslate from "../../../../hooks/useTranslate";

type Props = {
    onChange: (value: string) => void,
    columns: string[],
    showType?: boolean,
    language?: "common" | "report"
    toggle?: () => void,
    index?: number,
    loading?: boolean
    disabled?: boolean
};
const ActionDropdownBodyComponent = ({columns, showType, language, disabled, onChange, loading, toggle, index}: Props) => {
    const {state} = useContext(ReportContext)
    const {t} = useTranslate("report")
    return (
        <ColumnsActionsWrapperComponent>
            {search => {
                return <>

                    {loading && <DropdownItem disabled className="justify-content-center">
                        Loading...
                    </DropdownItem>}

                    {columns.filter(column => column.toLowerCase().includes(search.toLowerCase())).map((column, indexColumn) => {
                        return <DropdownItem disabled={disabled} onClick={() => {
                            onChange(column)
                            if (toggle) {
                                toggle()
                            }
                        }} key={column + "_" + indexColumn}>
                            {showType ? getColumnName({
                                name: column,
                                columnsType: state.columnsType,
                                index
                            }) : t(`${language ? language + ":" : ""}${column}`)}
                        </DropdownItem>
                    })}

                    {search ?
                        <SearchSuggestionOption search={search} onChange={() => {
                        }} toggle={() => {
                            onChange(search)
                            if (toggle) {
                                toggle()
                            }
                        }}/> : columns.length === 0 ? <DropdownItem disabled>{t("common:empty")}</DropdownItem> : null
                    }
                </>
            }}
        </ColumnsActionsWrapperComponent>
    );
};

export default ActionDropdownBodyComponent