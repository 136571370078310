import React, {Fragment, useContext, useState} from 'react';
import {AggAnalytic, AnalyticBusinessDay, AnalyticQueryAction} from '../../../../types/Analytic';
import {ReportGeneratorContext, sourceBase} from '../../ReportGeneratorComponent';
import {uuidV4} from '../../../../utils';
import {State} from '../../../../types/State';
import CustomTableComponent, {Action, Column} from '../../../Shared/CustomTableComponent/CustomTableComponent';
import {analyticAggList} from '../../../../utils/analyticsUtils';
import {OnChangeActionParams} from '../ActionComponent';
import ButtonComponent from '../../../Shared/ButtonComponent';
import {faArrowRight, faMinusCircle, faPlus} from '@fortawesome/free-solid-svg-icons';
import ReportPreviewComponentWrapper from '../../ReportPreviewComponentWrapper/ReportPreviewComponentWrapper';
import CreatableSelectComponent from "../../../Shared/FieldComponents/CreatableSelectComponent";
import TabContents, {Tab} from "../../../Shared/TabContents";
import useTranslate from "../../../../hooks/useTranslate";
import ActionsForGroupByComponent from "./ActionsForGroupByComponent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

type Props = {
    onChangeAction: (actions: OnChangeActionParams[]) => void
    action: AnalyticQueryAction
    isPivot?: boolean
    isDrillDown?: boolean
}

const columnsTable: Column[] = [
    {name: 'label', header: 'Column'},
];

const ActionGroupByComponent = ({action, onChangeAction, isPivot, isDrillDown}: Props) => {

    const {
        reportState: {isLoading, actionLoading}, getActionColumns,
    } = useContext(ReportGeneratorContext);
    const [groupByColumns, setGroupByColumns] = useState<{ label: string, value: string }[]>([]);
    const [groupByAggregation, setGroupByAggregation] = useState<{ label: string, value: string }[]>([]);
    const [groupByIndexColumns, setGroupByIndexColumns] = useState<{ label: string, value: string }[]>([]);
    const {t} = useTranslate("common")
    const [aggBusinessDay, setAggBusinessDay] = useState<null | AnalyticBusinessDay>(null);
    const onChangeAgg = (agg: { label: string, value: string }[], column: { label: string, value: string }) => {

        const newAgg = {...action.agg};

        groupByAggregation.forEach(agg => {
            if (!newAgg[agg.value]) {
                newAgg[agg.value] = [];
            }
        })

        if (newAgg) {
            if (!newAgg[column.value]) {
                newAgg[column.value] = [];
            }
            newAgg[column.value] = agg.length > 0 ? agg.map(aggItem => aggItem.value) : [];

            onChangeAction([
                {actionKey: 'agg', action, value: newAgg}
            ]);
        }
    };

    const actions: Action[] = [
        {
            custom: true,
            customComponent: (column: { label: string, value: string }) => <div key={uuidV4()}
                                                                                style={aggBusinessDay ? {} : {width: '20em'}}
                                                                                className={`d-flex flex-column ${aggBusinessDay ? "align-items-xl-center gap-2 flex-xl-row" : ""}`}>

                <CreatableSelectComponent isMulti id={uuidV4()} onChange={evt => onChangeAgg(evt, column)} name={'agg'}
                                          value={action.agg && action.agg[column.value] && action.agg[column.value].length > 0 ? action.agg[column.value].filter(aggColumn => typeof aggColumn === "string").map(aggColumn => ({
                                              label: aggColumn,
                                              value: aggColumn
                                          })) : null}
                    // menuPosition={'fixed'}
                    //   className="z-index.tsx-1000"
                                          options={analyticAggList.map(agg => ({label: agg, value: agg}))}/>

                {action.agg && action.agg[column.value] && action.agg[column.value].length > 0 && action.agg[column.value].some(agg => typeof agg === 'string' && agg === '@business_day') &&
                    <div className="d-flex ">
                        <ButtonComponent label={(aggBusinessDay ? "Update" : 'Add') + ' source'} size={'sm'}
                                         icon={faPlus}
                                         color={'primary'} onClick={() => {

                            if (action.agg) {

                                if (aggBusinessDay) {

                                } else {
                                    const newSource: AnalyticBusinessDay = {
                                        source: {...sourceBase, temporal_id: uuidV4()},
                                        temporal_id: uuidV4(),
                                        business_day_column: '',
                                        agg: '',
                                        month_column: '',
                                        year_column: '',
                                        multiply_by: ''
                                    };
                                    setAggBusinessDay(newSource);
                                    action.agg[column.value].push(newSource);
                                }


                            }
                            // toggleCanvas();

                        }}/>
                        {aggBusinessDay && <ButtonComponent icon={faPlus} label={'Add operation columns'}/>}
                    </div>
                }
            </div>
        }

    ];


    const onChangeColumns = (evt: { label: string, value: string }[], key: 'columns' | 'index_columns') => {
        if (evt.length === 0) {
            onChangeAction([
                {actionKey: 'agg', action, value: action.agg ?? {}},
                {actionKey: key, action, value: []},
            ]);
        } else {
            onChangeAction([
                {actionKey: key, action, value: evt.map(column => column.value)},
            ]);
        }
    };

    const onChangeAggregation = (evt: { label: string, value: string }[]) => {
        setGroupByAggregation(evt);
        if (action.agg) {
            action.agg = Object.keys(action.agg).reduce((obj: AggAnalytic, key) => {
                if (evt.some(column => column.value === key) && action.agg) {
                    obj[key] = action.agg[key];
                }
                return obj;
            }, {});
        }
    };

    React.useEffect(() => {

        if (action.agg && Object.keys(action.agg)) {
            setGroupByAggregation(Object.keys(action.agg).map(agg => ({label: agg, value: agg})));
        }

        if (action.columns && action.columns.length > 0) {
            setGroupByColumns((action.columns as string[]).map(column => ({label: column, value: column})));
        }

        if (isPivot && action.index_columns && action.index_columns.length > 0) {
            setGroupByIndexColumns((action.index_columns as string[]).map(column => ({label: column, value: column})));
        }

    }, [action]);

    const tabs: Tab[] = [
        {
            label: t("columns"), component: <>
                {isDrillDown && <div className="d-flex flex-column gap-3">
                    <div className="d-flex align-items-center gap-3 flex-grow-1">


                        <div className="text-start flex-grow-1">
                            <span className="fw-bold">Agregar a jerarquia</span>
                            <CreatableSelectComponent name={"hierarchy"} className="text-start flex-grow-1 w-50"
                                                      onChange={event => {
                                                          if (action.hierarchy) {
                                                              onChangeAction([
                                                                  {
                                                                      actionKey: 'hierarchy',
                                                                      action,
                                                                      value: action.hierarchy.concat([event.value])
                                                                  }
                                                              ]);
                                                          }
                                                      }}
                                                      disabled={isLoading === State.PENDING}
                                                      loading={(action.temporal_id && actionLoading[action.temporal_id] === State.PENDING) || isLoading === State.PENDING}
                                                      options={getActionColumns(action).map(option => option.options).flat().filter(option => action.hierarchy ? !action.hierarchy.includes(option.value) : true)}/>
                        </div>


                    </div>


                    {action.hierarchy && action.hierarchy.length > 0 &&
                        <div className={"d-flex align-items-center gap-2"}>
                            {action.hierarchy.map((column, index) => (
                                <Fragment key={column + "_" + index}>
                                    <div className="border p-2 rounded" >
                                    <span >{column} {" "} <FontAwesomeIcon
                                        icon={faMinusCircle}
                                        color={"red"} onClick={() => {
                                        if (action.hierarchy) {
                                            onChangeAction([
                                                {
                                                    actionKey: 'hierarchy',
                                                    action,
                                                    value: action.hierarchy.filter(hierarchyC => hierarchyC !== column)
                                                }
                                            ]);
                                        }
                                    }}
                                        className="pointer"/></span>
                                    </div>

                                    {index !== (action.hierarchy!.length - 1) && <FontAwesomeIcon icon={faArrowRight}/>}
                                </Fragment>
                            ))}
                        </div>}


                </div>}
                <div className="d-flex flex-column flex-lg-row gap-2">
                    <div className={`col-12 col-lg-${isPivot ? "4" : "6"}`}>
                        <b>{t("columns")}:</b>
                        <CreatableSelectComponent
                            className="text-start"
                            disabled={(action.temporal_id && actionLoading[action.temporal_id] === State.PENDING) || isLoading === State.PENDING}
                            loading={(action.temporal_id && actionLoading[action.temporal_id] === State.PENDING) || isLoading === State.PENDING}
                            id={uuidV4()}
                            name={'columns'}
                            isMulti
                            onChange={value => {
                                onChangeColumns(value, 'columns')
                                setGroupByColumns(value)
                            }}
                            // menuPosition={'fixed'}
                            value={groupByColumns}
                            options={getActionColumns(action).map(option => option.options).flat()
                                .filter(column => !groupByAggregation.some(byColumn => byColumn.value === column.value)
                                    && !groupByIndexColumns.some(byColumn => byColumn.value === column.value)
                                    && (action.hierarchy ? !action.hierarchy?.includes(column.value) : true)).map(column => ({
                                label: column.label,
                                value: column.value
                            }))}/>
                    </div>

                    {isPivot && <div className={`col-12 col-lg-4`}>
                        <b>Index Columns:</b>
                        <CreatableSelectComponent
                            className="text-start"
                            disabled={(action.temporal_id && actionLoading[action.temporal_id] === State.PENDING) || isLoading === State.PENDING}
                            loading={(action.temporal_id && actionLoading[action.temporal_id] === State.PENDING) || isLoading === State.PENDING}
                            id={uuidV4()}
                            isMulti
                            name={'index_columns'}
                            onChange={value => {
                                onChangeColumns(value, 'index_columns')
                                setGroupByIndexColumns(value)
                            }}
                            // menuPosition={'fixed'}
                            value={groupByIndexColumns}
                            options={getActionColumns(action).map(option => option.options).flat()
                                .filter(column => !groupByAggregation.some(byColumn => byColumn.value === column.value)
                                    && !groupByColumns.some(byColumn => byColumn.value === column.value) ).map(column => ({
                                label: column.label,
                                value: column.value
                            }))}/>
                    </div>}

                    <div className={`col-12 col-lg-${isPivot ? "4" : "6"}`}>


                        <b>{t("report:aggregation")}:</b>
                        <CreatableSelectComponent
                            className="text-start"
                            disabled={(action.temporal_id && actionLoading[action.temporal_id] === State.PENDING) || isLoading === State.PENDING}
                            loading={(action.temporal_id && actionLoading[action.temporal_id] === State.PENDING) || isLoading === State.PENDING}
                            id={uuidV4()}
                            isMulti
                            name={'columns'}
                            onChange={onChangeAggregation}
                            // menuPosition={'fixed'}
                            value={groupByAggregation}
                            options={getActionColumns(action).map(option => option.options).flat()
                                .filter(column => !groupByColumns.some(byColumn => byColumn.value === column.value)
                                    && !groupByIndexColumns.some(byColumn => byColumn.value === column.value)
                                    && (action.hierarchy ? !action.hierarchy?.includes(column.value) : true)).map(column => ({
                                label: column.label,
                                value: column.value
                            }))}/>

                    </div>

                </div>

                <CustomTableComponent columns={columnsTable} data={groupByAggregation} actions={actions}
                                      actionsColumnClass={'d-flex justify-content-center'}
                                      actionsColumnLabel={t("aggregation_functions")}/>

                {aggBusinessDay?.source &&
                    <ReportPreviewComponentWrapper source={aggBusinessDay.source} updateSource={(sources) => {
                        if (action.agg) {
                            if (aggBusinessDay?.source) {
                                const sourceAgg: any = {...aggBusinessDay.source}

                                for (const source of sources) {
                                    if (source.sourceId === (sourceAgg).temporal_id) {
                                        sourceAgg[source.sourceKey] = source.sourceValue
                                    }
                                }

                                setAggBusinessDay(prevState => prevState ? ({...prevState, source: sourceAgg}) : null);
                            }
                        }
                    }}/>}
            </>
        },
        {
            label: t("actions"),
            component: <ActionsForGroupByComponent onChangeAction={onChangeAction} action={action}/>
        }
    ]


    return (
        <div className="d-flex flex-column gap-2">


            <TabContents tabs={tabs}/>


        </div>
    );
};

export default ActionGroupByComponent;
