import * as React from 'react';
import {useContext} from 'react';
import styled from "styled-components";
import {AnalyticQueryAction} from "../../../../types/Analytic";
import CreatableSelectComponent from "../../../Shared/FieldComponents/CreatableSelectComponent";
import {ReportContext} from "../../NewReportGeneratorComponent";
import useTranslate from "../../../../hooks/useTranslate";

type Props = {
    action: AnalyticQueryAction
    index: number
};

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 15px;

  @media (max-width: 998px) {
    grid-template-columns: 1fr;
  }
`
const NewMonetarySegmentationComponent = ({index, action}: Props) => {
    const {getActionColumns, setQueryAction, getLoadingAndDisabledAction} = useContext(ReportContext)
    const {t} = useTranslate('report');
    return (
        <Container>
            <div>
                <span>{t("customer_id")}</span>
                <CreatableSelectComponent name={"customer_id"} defaultValue={action.customer_id ? {
                    label: action.customer_id,
                    value: action.customer_id
                } : null}
                                          disabled={getLoadingAndDisabledAction({action, index})}
                                          loading={getLoadingAndDisabledAction({action, index})}
                                          options={getActionColumns(action, index)}
                                          onChange={evt => {

                                              setQueryAction({
                                                  ...action,
                                                  customer_id: evt.value
                                              }, index)
                                          }}/>
            </div>

            <div>
                <span>{t("invoice_id")}</span>
                <CreatableSelectComponent name={"invoice_id"} defaultValue={action.invoice_id ? {
                    label: action.invoice_id,
                    value: action.invoice_id
                } : null}
                                          disabled={getLoadingAndDisabledAction({action, index})}
                                          loading={getLoadingAndDisabledAction({action, index})}
                                          options={getActionColumns(action, index)}
                                          onChange={evt => {
                                              setQueryAction({
                                                  ...action,
                                                  invoice_id: evt.value
                                              }, index)
                                          }}/>
            </div>

            <div>
                <span>{t("invoice_date")} (YYYYMMDD)</span>
                <CreatableSelectComponent name={"invoice_date"} defaultValue={action.invoice_date ? {
                    label: action.invoice_date,
                    value: action.invoice_date
                } : null}
                                          disabled={getLoadingAndDisabledAction({action, index})}
                                          loading={getLoadingAndDisabledAction({action, index})}
                                          options={getActionColumns(action, index)}
                                          onChange={evt => {
                                              setQueryAction({
                                                  ...action,
                                                  invoice_date: evt.value
                                              }, index)
                                          }}/>
            </div>

            <div>
                <span>{t("invoice_total")}</span>
                <CreatableSelectComponent name={"invoice_total"} defaultValue={action.invoice_total ? {
                    label: action.invoice_total,
                    value: action.invoice_total
                } : null}
                                          disabled={getLoadingAndDisabledAction({action, index})}
                                          loading={getLoadingAndDisabledAction({action, index})}
                                          options={getActionColumns(action, index)}
                                          onChange={evt => {
                                              setQueryAction({
                                                  ...action,
                                                  invoice_total: evt.value
                                              }, index)
                                          }}/>
            </div>
        </Container>
    );
};

export default NewMonetarySegmentationComponent