import React from 'react';
import {OptionsType, StylesConfig, ValueType} from "react-select";
import ValidateFieldRequired from "../ValidateFieldRequired";
import CreatableSelect from 'react-select/creatable';
import useTranslate from "../../../hooks/useTranslate";
import {SelectComponentsConfig} from "react-select/src/components";
import {NumberOption, StringOption} from "../../../types/Select";

interface IProps {
    [key: string]: any;

    id?: string;
    name: string;
    value?: ValueType<any, any>;
    options: OptionsType<any>;
    invalid?: boolean;
    readonly?: boolean;
    disabled?: boolean;
    defaultValue?: ValueType<any, any>;
    isMulti?: boolean
    required?: boolean;
    closeMenuOnSelect?: boolean;
    placeholder?: string;
    styles?: StylesConfig<any, any>
    className?: string;
    isClearable?: boolean;
    loading?: boolean;
    components?: SelectComponentsConfig<any, any>
    onChange?: (value: Array<ValueType<any, any>> | ValueType<any, any>) => void;
    filterOption?: (option: StringOption | NumberOption, rawInput: string) => boolean;
    menuPosition?: 'absolute' | 'fixed'
    sortOptions?: boolean
    onInputChange?: (value: string) => void;
    onCreateOption?: (value: string) => void;
    inputValue?: string
    setIsOpen?: (isOpen: boolean) => void
    menuIsOpen?: boolean
    noOptionsMessage?: string
}

const MultiSelectComponent = (props: IProps) => {

    const {
        id,
        name,
        value,
        onChange,
        className,
        options,
        placeholder,
        required,
        disabled,
        loading,
        onInputChange,
        onCreateOption,
        menuPosition = "fixed",
        isClearable=false,
        defaultValue, setIsOpen,
        sortOptions = true, menuIsOpen,
        closeMenuOnSelect = true, styles, filterOption,
        isMulti, components, inputValue, noOptionsMessage
    } = props;

    let opts: OptionsType<any> = [];
    if (options && options.length > 0) {
        if (sortOptions) {
            opts = [...(options as { label: string, value: string }[])].filter(item => item.label).sort((a, b) => a.label.toString().localeCompare(b.label.toString()))
        } else {
            opts = [...options]

        }
    }


    const {t} = useTranslate("common");

    return <ValidateFieldRequired
        disabled={disabled}
        value={value}
        required={required}>
        <>
            <CreatableSelect isMulti={isMulti ?? false}
                             isLoading={loading}
                             classNamePrefix="react-select"
                             placeholder={placeholder ? placeholder : `${t('select_placeholder')}`}
                             className={`${className ?? ""} `}
                             id={id}
                             isClearable={isClearable}
                             components={components}
                             defaultValue={defaultValue}
                             menuPosition={menuPosition ?? undefined}
                             isDisabled={disabled}
                             name={name}
                             styles={styles}
                             filterOption={filterOption}
                             inputValue={inputValue}
                             onCreateOption={(inputValue) => {
                                 if (onCreateOption) {
                                     onCreateOption(inputValue);
                                 } else {
                                     if (onChange) {
                                         if (isMulti) {
                                             onChange([...(value && Array.isArray(value) ? value : []), {
                                                 label: inputValue,
                                                 value: inputValue
                                             }])
                                         } else {
                                             onChange({label: inputValue, value: inputValue})
                                         }
                                     }
                                 }

                             }}
                             closeMenuOnSelect={closeMenuOnSelect}
                             value={value}
                             noOptionsMessage={noOptionsMessage ? () => noOptionsMessage : undefined}
                             onChange={(value: any) => onChange ? onChange(value) : null}
                             onInputChange={(e) => {
                                 if (onInputChange) onInputChange(e);
                             }}
                             onMenuOpen={() => {
                                 setIsOpen && setIsOpen(true)
                             }}
                             onMenuClose={() => {
                                    setIsOpen && setIsOpen(false)
                             }}
                             menuIsOpen={menuIsOpen}
                             options={opts}
            />
        </>
    </ValidateFieldRequired>
};

export default MultiSelectComponent;
