import * as React from 'react';
import {CSSProperties, useEffect, useRef} from 'react';
import {Dropdown, DropdownMenu, DropdownToggle} from "reactstrap";

type Props = {
    isOpen: boolean
    toggle: () => void
    children: React.ReactNode
    header: React.ReactNode
    menuStyle?: CSSProperties
    dropdownStyle?: CSSProperties
    basicConfigMenu?: boolean
    search?: string
    setDropdownOpen?: React.Dispatch<React.SetStateAction<boolean>>;
    setSearch?: React.Dispatch<React.SetStateAction<string>>;
    closeOutside?: boolean
};

const basicStyle: CSSProperties = {maxHeight: '40vh', overflowY: "auto", zIndex: 99, background: "white"}

const DropdownActionComponent = ({
                                     isOpen,
                                     menuStyle,
                                     basicConfigMenu,
                                     header,
                                     toggle,
                                     children, closeOutside,
                                     dropdownStyle, setDropdownOpen
                                 }: Props) => {

    const divRef = useRef<HTMLDivElement | null>(null);


    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                divRef.current &&
                !divRef.current.contains(event.target as Node) &&
                document.activeElement !== divRef.current
            ) {

                // if (setDropdownOpen && closeOutside) {
                    // setDropdownOpen(false)
                // }

            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);


    console.log()

    return (

        <Dropdown isOpen={isOpen} toggle={() => {
        }} direction={'down'}>
            <DropdownToggle color="primary" style={dropdownStyle ?? {}}
                            onClick={() => {
                                toggle()
                            }}>
                {header}
            </DropdownToggle>
            {isOpen && <div ref={divRef} tabIndex={0}>
                <DropdownMenu style={menuStyle && Object.keys(menuStyle).length > 0 ? menuStyle : basicConfigMenu ? basicStyle : {}}>
                    {children}
                </DropdownMenu>
            </div>}
        </Dropdown>

    );
};

export default DropdownActionComponent