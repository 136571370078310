import * as React from 'react';
import {DropdownItem} from "reactstrap";
import useTranslate from "../../hooks/useTranslate";

type Props = {
    search: string
    onChange: (search: string) => void
    toggle: () => void
};
const SearchSuggestionOption = ({search, toggle, onChange}: Props) => {
    const {t} = useTranslate("common")
    return <DropdownItem className="fw-bold" onClick={() => {
        onChange(search)
        toggle()
    }}>
        {t("create")} "{search}"
    </DropdownItem>
};

export default SearchSuggestionOption