import * as React from 'react';
import {Fragment, useCallback, useContext, useState} from 'react';
import {
    formatDateOptions
} from "../ActionsComponent/TransformComponent/DateTransformComponent/DateTransformFormComponent";
import {ModelsResponse} from "../../../types/Sbx";
import {AnalyticQuery, CacheTruncate, TruncateReport} from "../../../types/Analytic";
import CreatableSelectComponent from "../../Shared/FieldComponents/CreatableSelectComponent";
import {StringOption} from "../../../types/Select";
import RenderMonthElement from "../../Shared/RenderMonthElement";
import useTranslate from "../../../hooks/useTranslate";
import DateComponent from "../../Shared/FieldComponents/DateComponent";
import RadioButtonComponent from "../../Shared/FieldComponents/RadioButtonComponent";
import {convertDateToNumberDate, toast, uuidV4} from "../../../utils";
import {Control, Controller, UseFormGetValues, UseFormRegister} from "react-hook-form";
import ButtonComponent from "../../Shared/ButtonComponent";
import {executeReloadReportQuery} from "../../../services/backend/AnalyticsService";
import {State} from "../../../types/State";
import {ReportGeneratorContext} from "../ReportGeneratorComponent";
import {getAnalyticQueryFlat} from "../../../utils/analyticsUtils";

type Props = {
    models: ModelsResponse[]
    isLoading: boolean
    cacheTruncate: CacheTruncate
    truncate: TruncateReport
    register: UseFormRegister<any>
    getValue: UseFormGetValues<any>
    control: Control<any>
    analyticQuery: AnalyticQuery;
    setCache: (cache: CacheTruncate) => void
};

const force_save_properties: {name: keyof CacheTruncate, value: string}[] = [{name: 'force_save_from', value: "from"}, {name: 'force_save_to', value: "to"}]

// truncate.cache.force_reload = true
// truncate.cache.force_save_from = truncate.cache.from
// truncate.cache.force_save_to = truncate.cache.to
// truncate.cache.cached_by_range = "monthly"

type TruncateField = {
    field: keyof CacheTruncate
    disabled?: boolean,
    type: "select" | "boolean" | "calendar" | "text"
    options?: (StringOption | { label: string, value: boolean })[],
    sub_type?: "number_date"
}


const TruncateCacheFormComponent = ({
                                        models,
                                        isLoading,
                                        cacheTruncate,
                                        setCache,
                                        control,
                                        register,
                                        getValue,
                            analyticQuery
                                    }: Props) => {

    const {t} = useTranslate("common");
    const [loading, setLoading] = useState(State.IDLE)

    const {
        reportState: {report},
    } = useContext(ReportGeneratorContext);

    const formFields: () => TruncateField[] = useCallback(() => {
        return [
            {field: "name", disabled: isLoading, type: "text"},
            {field: "append_format", disabled: isLoading, options: formatDateOptions, type: "select"},
            {field: "from", disabled: isLoading, type: "calendar", sub_type: "number_date"},
            {field: "to", disabled: isLoading, type: "calendar", sub_type: "number_date"},
            {
                field: "append_type",
                disabled: isLoading,
                options: [{label: t("columns"), value: "columns"}, {label: t("rows"), value: "rows"}],
                type: "select"
            },
            {
                field: "append_index", disabled: isLoading, options: [...models.map(model => ({
                    label: model.name,
                    value: model.name
                }))], type: "select"
            },
            {
                field: "append_at",
                disabled: isLoading,
                options: [{label: t("start"), value: "start"}, {label: t("end"), value: "end"}],
                type: "select"
            },
            // {
            //     field: "force_save",
            //     disabled: isLoading,
            //     type: "boolean",
            //     options: [{label: t("yes"), value: true}, {label: "No", value: false}]
            // },
        ]
    }, [models])

    const renderFormFields = (field: TruncateField, index: number) => {
        const input: { [key: string]: JSX.Element } = {
            select: <Controller control={control} render={({field: {onChange, value}}) => {
                return <CreatableSelectComponent menuPosition={"fixed"} id={`cache-option`} name={`$cache-select`}
                                                 className="flex-grow-1"
                                                 loading={isLoading}
                                                 key={field.field}
                                                 onChange={event => {
                                                     onChange(event.value)
                                                 }}
                                                 value={field.options ? field.options.find(opt => opt.value === value) ?? value ? {label: value, value} :  null: []}
                                                 options={field.options ?? []}/>
            }} name={"cache." + field.field}/>,

            calendar:
                <Controller control={control} render={({field: {onChange, value}}) => {
                    return <DateComponent
                        id={field.field}
                        value={value ?? null}
                        key={field.field}
                        onChange={e => {
                            onChange(convertDateToNumberDate(e as Date).toString())
                        }}
                        disabled={field.disabled}
                        required
                        renderMonthElement={RenderMonthElement}
                        isOutsideRange={() => false}
                        orientation={'vertical'}
                    />

                }} name={"cache." + field.field}/>
            ,
            boolean: <Controller control={control} render={({field: {onChange, value}}) => {
                return <RadioButtonComponent id={uuidV4()} name={field.field}
                                             value={value ? {
                                                 label: value ? t("yes") : "No",
                                                 value: value
                                             } : null} key={field.field} onChange={event => {
                    onChange(event?.value)
                }}
                                             options={[{label: t("yes"), value: true}, {
                                                 label: t("no"),
                                                 value: false
                                             }]}/>
            }} name={"cache." + field.field}/>


        }


        return <Fragment key={"field_" + field.field + "_" + index}>
            <span>{t(field.field)}</span>
            {input[field.type] ??
                <input type={field.type} key={field.field} defaultValue={getValue(field.field) ?? ""}
                       className={"form-control"} {...register(`cache.${field.field}`)}
                />}
        </Fragment>
    }

    return (
        <div className="card d-flex flex-column gap-2 p-4">
            <div
                className="card-header d-flex align-items-center justify-content-between gap-1 border-bottom p-2">
                <span>Cache</span>
            </div>
            {formFields().map((field, index) => (renderFormFields(field, index)))}

            <div className="d-flex gap-2 align-items-center">
                <ButtonComponent label={`${t("update")} cache`} loading={State.PENDING === loading } onClick={async () => {
                    if (report){
                        setLoading(State.PENDING)
                        const cache: CacheTruncate = {...cacheTruncate}

                        force_save_properties.forEach(prop => {
                            (cache as any)[prop.name] = cache[prop.value as keyof typeof cache]
                        })

                        cache.force_reload = true
                        cache.force_save = true



                        // const response = await executeUpdateCache({...analyticQuery, truncate: {...(analyticQuery.truncate as TruncateReport), cache}})
                        const response = await executeReloadReportQuery({...getAnalyticQueryFlat(analyticQuery), truncate: {...(analyticQuery.truncate as TruncateReport), cache}}, report)
                        if (response?.success){
                            toast({type:"success", message: `cache ${t("updated")}`})
                            setLoading(State.RESOLVED)
                        }else{
                            setLoading(State.REJECTED)
                        }
                    }




                }}/>
            </div>
        </div>
    );
};

export default TruncateCacheFormComponent