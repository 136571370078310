import React, {useState} from 'react';
import ModalComponent from '../../Shared/Modal/ModalComponent/ModalComponent';
import {removeDuplicateFromArray, removeDuplicateFromArrayDeepObj, uuidV4} from '../../../utils';
import {SubmitHandler, useForm} from 'react-hook-form';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSave, faTrash} from '@fortawesome/free-solid-svg-icons';
import {Button} from 'reactstrap';
import useTranslate from '../../../hooks/useTranslate';
import CreatableSelectComponent from "../../Shared/FieldComponents/CreatableSelectComponent";
import {StringOption} from "../../../types/Select";

type Props = {
    isOpen: boolean
    toggle: () => void;
    isLoading: boolean
    columns: { label: string, value: string, options: StringOption }[]
    renameColumns: { current_name: string, new_name: string }[]
    setColumns: (rename_columns: { current_name: string, new_name: string }[]) => void;
    deleteColumnAction: (current_name: string, new_name: string) => void
}

const ActionRenameModal = ({
                               isOpen,
                               toggle,
                               columns,
                               setColumns,
                               deleteColumnAction,
                               renameColumns,
                               isLoading
                           }: Props) => {

    const {register, handleSubmit, setValue} = useForm<any>();
    const [modalRenameColumns, setModalRenameColumns] = useState<{ label: string, value: string }[]>([]);
    const {t} = useTranslate('common');
    const [columnsAction, setColumnsAction] = useState<string[]>([])

    const onSubmit: SubmitHandler<any> = (data) => {

        const newColumns = Object.keys(data).filter(key => data[key] && !columnsAction.includes(data[key])).map(key => ({
            current_name: key,
            new_name: data[key]
        }));

        setColumns(newColumns);
        toggle();
    };

    React.useEffect(() => {
        if (renameColumns.length > 0) {
            setModalRenameColumns(renameColumns.map(column => ({label: column.current_name, value: column.new_name})));
        } else {
            const options = columns.filter(column => !modalRenameColumns.some(rColumn => rColumn.label === column.value)).map(option => option.options).flat()
            // setModalRenameColumns(columns.filter(column => !modalRenameColumns.some(rColumn => rColumn.label === column.value)))
            setModalRenameColumns(removeDuplicateFromArrayDeepObj(options, "value"))
            setColumnsAction(removeDuplicateFromArray(options.map(option => option.value)))
        }
    }, [renameColumns, columns]);

    return (
        <ModalComponent title={'Rename columns'} isOpen={isOpen} size={"xl"} toggle={toggle} noFooter>
            <div className="d-flex flex-column">
                <b>Columns:</b>
                <CreatableSelectComponent isMulti disabled={isLoading} loading={isLoading} id={uuidV4()}
                                          name={'columns'}
                                          value={modalRenameColumns}
                                          onChange={evt => setModalRenameColumns(evt)}
                                          options={columns.filter(column => !modalRenameColumns.some(rColumn => rColumn.label === column.value))}/>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                        <tr>
                            <th scope="col">Current column name</th>
                            <th scope="col">New Column name</th>
                        </tr>
                        </thead>
                        <tbody>
                        {modalRenameColumns.map((column, index) => (
                            <tr key={`${column.value}_${index}`}>
                                <td>{column.label}</td>
                                <td>
                                    <input type="text" className="form-control" placeholder={column.value}
                                           defaultValue={column.value || ''} {...register(column.label, {required: true})}/>
                                </td>

                                <td>
                                    <Button size={'sm'} color={'danger'}
                                            onClick={() => {
                                                setModalRenameColumns(modalColumns => modalColumns.filter(nColumn => nColumn.value !== column.value));
                                                setValue(column.label, null)
                                                // if (getValues(column.value)) {
                                                //   deleteColumnAction(column.value, getValues(column.value));
                                                // }
                                            }}>
                                        <FontAwesomeIcon icon={faTrash}/> Remove
                                    </Button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>

                <div className="text-end">
                    <Button size={'sm'} color="primary">
                        <FontAwesomeIcon icon={faSave}/> {' ' + t('save')}
                    </Button>
                </div>
            </form>
        </ModalComponent>
    );
};

export default ActionRenameModal;
