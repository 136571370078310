import React from 'react';
import Select, {OptionsType, ValueType} from "react-select";
import ValidateFieldRequired from "../ValidateFieldRequired";
import useTranslate from "../../../hooks/useTranslate";
import {NumberOption, StringOption} from "../../../types/Select";

interface IProps {
  [key: string]: any;

  id: string;
  name: string;
  value?: ValueType<any, any>;
  options: OptionsType<any>;
  invalid?: boolean;
  readonly?: boolean;
  disabled?: boolean;
  defaultValue?: ValueType<any, any>;
  required?: boolean;
  placeholder?: string;
  className?: string;
  loading?: boolean;
  onChange?: (value: Array<ValueType<any, any>>) => void;
  filterOption?: (option: StringOption | NumberOption, rawInput: string) => boolean;
  menuPosition?: 'absolute' | 'fixed'
  onInputChange?: (value: string) => void;
}

const MultiSelectComponent = (props: IProps) => {

  const {
    id,
    name,
    value,
    onChange,
    className,
    options,
    placeholder,
    required,
    disabled,
    loading,
    onInputChange,
    menuPosition,
      filterOption,
    defaultValue
  } = props;
  const {t} = useTranslate("common");
  return <ValidateFieldRequired
    disabled={disabled}
    value={value}
    required={required}>
    <>
      <Select isMulti={true}
              isLoading={loading}
              classNamePrefix="react-select"
              placeholder={placeholder?placeholder:`${t('common:search')}...`}
              className={className}
              id={id}
              defaultValue={defaultValue}
              menuPosition={menuPosition ?? undefined}
              isDisabled={disabled}
              name={name}
              filterOption={filterOption}
              value={value}
              onChange={(value: any) => onChange ? onChange(value) : null}
              onInputChange={(e) => {
                if (onInputChange) onInputChange(e);
              }}
              options={options}
      />
    </>
  </ValidateFieldRequired>
};

export default MultiSelectComponent;
