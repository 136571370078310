import * as React from 'react';
import {StringOption} from "../../../../types/Select";
import {useContext, useMemo, useRef} from "react";
import useTranslate from "../../../../hooks/useTranslate";
import {Control, Controller, useFieldArray, UseFieldArrayRemove, useForm, useWatch} from "react-hook-form";
import ActionDropdownColumns from "../ActionDropdownComponent/ActionDropdownColumns";
import ColumnsActionsWrapperComponent from "../../ColumnsActionsWrapperComponent";
import {DropdownItem} from "reactstrap";
import SearchSuggestionOption from "../../SearchSuggestionOption";
import {ActionButton, ReportContext} from "../../NewReportGeneratorComponent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlusCircle, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import {getColumnType} from "../utils";

type Form = {
    columns: { name: string }[]
    operation: string
};

type Props = {
    color: string
    columns: StringOption[]
    value: string
    index: number
    onChange: (value: string) => void
}

const Display = ({control, index, t, remove}: {
    index: number,
    control: Control<Form, any>,
    t: (value: string) => string,
    remove: UseFieldArrayRemove
}) => {
    const data = useWatch({
        control,
        name: `columns.${index}`
    });

    return <div className="d-flex align-items-center gap-1">
        <span>{data?.name ? data.name : `${t("common:column")} ${index + 1}`}</span>
        <FontAwesomeIcon icon={faTimesCircle} className="pointer" onClick={() => {
            remove(index)
        }}/>
    </div>;
};

const TransformTotalizeCalculator = ({columns, value, onChange, color, index}: Props) => {
    const {state} = useContext(ReportContext)
    const {t} = useTranslate('report')
    let onMount = useRef(false)
    const {control, watch, getValues, setValue} = useForm<Form>({
        defaultValues: {
            operation: '@summarize',
        }
    })
    const {append, fields, remove, replace} = useFieldArray({
        control,
        name: "columns"
    })
    const operations = useMemo(() => [
        {label: t("summarize"), value: "@summarize"},
        {label: `${t("percentage")}`, value: "@percentage"},
        {label: `${t("differential")}`, value: "@differential"},
    ], [])

    const handleShowColumnButton = () => {
        const operation = watch("operation")
        switch (operation) {
            case "@differential":
                return fields.length < 2
            default:
                return fields.length < 1
        }
    }


    React.useEffect(() => {
        const subscription = watch((form) => {
                if (form.columns && form.columns!?.length > 0) {
                    onChange(`${form.operation} ${form.columns.map(it => it?.name ?? "").join(" ")} `)
                }
            }
        )
        return () => subscription.unsubscribe()
    }, [watch])

    React.useEffect(() => {
        if (value && !onMount.current) {
            const form = getValues()
            if (form.columns.length === 0) {
                const dataSplit = value.split(" ")
                const operation = dataSplit[0]
                setValue("operation", operation ?? "")
                setValue("columns", dataSplit.length > 0 ? dataSplit.slice(1, dataSplit.length - 1).map(it => ({name: it})) ?? [] : [])
            }
            onMount.current = true
        }

    }, [value]);


    return (
        <div className="d-flex flex-wrap align-items-center gap-2">
            <Controller render={({field}) => {
                return <ActionDropdownColumns columns={[]} color={color}
                                              label={operations.find(op => op.value === watch("operation"))?.label ?? ""}
                                              onChange={() => {
                                              }}>
                    <ColumnsActionsWrapperComponent>
                        {search => {
                            return <>
                                {operations.filter(column => column.value.toLowerCase().includes(search.toLowerCase())).map((column, indexColumn) => {
                                    return <DropdownItem onClick={() => {
                                        field.onChange(column.value)
                                        replace([])
                                    }} key={column + "_" + indexColumn}>
                                        {column.label}
                                    </DropdownItem>
                                })}

                                {search ?
                                    <SearchSuggestionOption search={search} onChange={() => {
                                    }} toggle={() => {
                                        field.onChange(`'${search}'`)
                                        replace([])
                                    }}/> : operations.length === 0 ?
                                        <DropdownItem disabled>{t("common:empty")}</DropdownItem> : null
                                }
                            </>
                        }}
                    </ColumnsActionsWrapperComponent>
                </ActionDropdownColumns>
            }} name={`operation`} control={control}/>

            {fields.map((fieldArray, indexField) => {
                return <div key={fieldArray.id}>
                    <Controller render={({field}) => {
                        return <ActionDropdownColumns columns={[]} color={color}
                                                      showCaret={false}
                                                      label={<Display control={control} remove={remove} index={indexField} t={t}/>}
                                                      onChange={() => {
                                                      }}>

                            <ColumnsActionsWrapperComponent>
                                {search => {
                                    return <>
                                        {columns.filter(column => {
                                            const type = getColumnType({
                                                index: index,
                                                columnsType: state.columnsType,
                                                name: column.value
                                            })

                                            // console.log('type', type)

                                            if (["FLOAT", "INT"].includes(type)) {
                                                return true
                                            }
                                        }).filter(column => column.value.toLowerCase().includes(search.toLowerCase())).map((column, indexColumn) => {
                                            return <DropdownItem onClick={() => {
                                                field.onChange(column.value)
                                            }} key={column + "_" + indexColumn}>
                                                {column.label}
                                            </DropdownItem>
                                        })}

                                        {search ?
                                            <SearchSuggestionOption search={search} onChange={() => {
                                            }} toggle={() => {
                                                field.onChange(`${search}`)
                                            }}/> : columns.length === 0 ?
                                                <DropdownItem disabled>{t("common:empty")}</DropdownItem> : null
                                        }
                                    </>
                                }}
                            </ColumnsActionsWrapperComponent>
                        </ActionDropdownColumns>
                    }} name={`columns.${indexField}.name`} control={control}/>
                </div>
            })}

            {handleShowColumnButton() && <ActionButton className="btn" color={color} onClick={() => append({name: ""})}>
                <FontAwesomeIcon icon={faPlusCircle}/>
                {t("common:add")} {t("common:column")}
            </ActionButton>}
        </div>
    );
};

export default TransformTotalizeCalculator