import React, {CSSProperties} from "react";
import {Switch} from "antd";

interface Opt {
    label: string,
    value: any,
    data?: any
}

interface IProps {
    id: string,
    name: string,
    value: null | Opt[],
    options: Opt[],
    invalid?: boolean,
    required?: boolean,
    readonly?: boolean,
    disabled?: boolean,
    style?: CSSProperties,
    placeholder?: string,
    className?: string,
    onChange?: (value: null | Opt[]) => void
}

const SwitchComponent = (props: IProps) => {
    const {onChange, options, value, id, disabled} = props;

    function handleEventChange(e: Opt) {
        if (value && onChange) {
            let newValue: any[];
            const t = value.find(o => e.value === o.value);
            if (t) {
                newValue = value.filter(o => o.value !== e.value);
                if (newValue.length) {
                    onChange(newValue);
                } else {
                    onChange(null);
                }
            } else {
                newValue = new Array(...value);
                newValue.push(e);
                onChange(newValue);
            }
        } else if (onChange) {
            onChange([e])
        }
    }

    return (
        <div id={id} className="sbx__checkbox">
            {options.map(op => {
                const exist = value ? !!value.find(o => o.value === op.value) : false;
                return (
                    <div  key={op.value} className="my-1">

                        <Switch
                            disabled={disabled}
                            id={op.value + id + "check"}
                            checkedChildren={op.label}
                            unCheckedChildren={op.label}
                            checked={exist}
                            onChange={() => handleEventChange(op)}/>
                    </div>
                )
            })}
        </div>
    )
}


export default SwitchComponent;
