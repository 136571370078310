import React, {ReactElement} from "react";
import {TreeViewDataItem} from "./index";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretDown, faCaretRight, faMinus} from "@fortawesome/free-solid-svg-icons";
import {Button, Collapse} from "reactstrap";

export const PARENT_SEPARATOR = "_";

interface IProps {
  item: TreeViewDataItem,
  component?: (props: { item: TreeViewDataItem; }) => ReactElement;
  onItemCLick?: (item: TreeViewDataItem) => void;
  selected?: string[];
  expandedItems?: string[];
  onExpandedItems?: (items: string[]) => void
}

const TreeItemRecursive = ({
                             item,
                             component: ItemComponent,
                             onItemCLick,
                             selected,
                             expandedItems,
                             onExpandedItems
                           }: IProps) => {
  const {items, id, text, expanded} = item;

  const isSelected = selected ? selected.some(e => e === item.id) : false;
  const isExpanded = (expandedItems ? expandedItems.some(e => e === item.id) : false) || expanded;

  function onExpand() {
    if (expandedItems && onExpandedItems) {
      if (isExpanded) {
        onExpandedItems(expandedItems.filter(e => e !== id));
      } else {
        onExpandedItems([...expandedItems, id]);
      }
    }
  }

  return (
    <div
      id={id}
      style={{paddingLeft: (id.split(PARENT_SEPARATOR).length * 4).toString().concat("px")}}>
      <>
        {!!items ? <Button onClick={() => onExpand()}
                           aria-controls={`collapse_${id}`}
                           aria-expanded={isExpanded} type="button" color="link">

          <FontAwesomeIcon className="pointer" icon={isExpanded ? faCaretDown : faCaretRight}/>
        </Button> : null}

        <span className={(isSelected ? "bg-warning p-2 rounded shadow-sm" : "") + " pointer no-selectable"}
              onClick={() => onItemCLick && onItemCLick(item)}>
          {!items && (
            <Button color="link">
              <FontAwesomeIcon className="text-gray"
                               size={"sm"}
                               icon={faMinus}/>
            </Button>
          )}
          {ItemComponent ? <ItemComponent item={item}/> : text}
        </span>
      </>
      {items?.length ? (
        <Collapse isOpen={isExpanded}>
          <div id={`collapse_${id}`}>
            {items.map(e => <TreeItemRecursive selected={selected} key={e.id}
                                               onItemCLick={onItemCLick}
                                               onExpandedItems={onExpandedItems}
                                               expandedItems={expandedItems}
                                               component={ItemComponent}
                                               item={e}/>)}
          </div>
        </Collapse>
      ) : null}
    </div>
  )
}

export default TreeItemRecursive;