import React, {ReactElement} from "react";
import TreeItemRecursive from "./TreeItemRecursive";

export interface TreeViewDataItem {
  text: string;
  id: string;
  expanded?: boolean;
  checked?: boolean;
  selected?: boolean;
  items?: TreeViewDataItem[];
  data?: any;
}

export interface TreeIProps {
  data: TreeViewDataItem[];
  item?: (props: { item: TreeViewDataItem; }) => ReactElement;
  onItemCLick?: (item: TreeViewDataItem) => void;
  selected?: string[];
  expandedItems?: string[];
  onExpandedItems?: (items: string[]) => void
}

const TreeComponent = (props: TreeIProps) => {

  const {
    data,
    item,
    onItemCLick,
    selected,
    expandedItems,
    onExpandedItems
  } = props;

  return (
    <div>
      {data.map(e => (
        <TreeItemRecursive key={e.id}
                           expandedItems={expandedItems}
                           onExpandedItems={onExpandedItems}
                           selected={selected}
                           onItemCLick={onItemCLick}
                           component={item}
                           item={e}/>)
      )}
    </div>
  )
}


export default TreeComponent;

