import * as React from 'react';
import {useRef, useState} from 'react';
// import SuggestionRuleSearchComponent from "../../../Shared/Modal/SuggestionRuleSearchComponent";
import useTranslate from "../../../../hooks/useTranslate";
import SuggestionRuleSearchComponent from "../../../Shared/Modal/SuggestionRuleSearchComponent";
import {uuidV4} from "../../../../utils";

type Props = {
    transformation: string,
    setTransformation: (transformation: string) => void
    columns: { label: string, value: string }[]

};

const SuggestedTransformInput = ({transformation, setTransformation, columns}: Props) => {
    const {t} = useTranslate('common');
    const divRef = useRef<HTMLDivElement>(null);
    const [showSuggestion, setShowSuggestion] = useState(false)
    // const divRef = useRef<HTMLDivElement>(null);
    const [search, setSearch] = useState("")
    const [id, setId] = useState("")

    React.useEffect(() => {
        setId(uuidV4())
    }, []);

    React.useEffect(() => {
        const keyDownHandler = (event: { key: string; preventDefault: () => void; }) => {
            if (event.key === 'Escape') {
                event.preventDefault();
                setShowSuggestion(false)
            }
        };

        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, []);


    const inputRef = useRef<HTMLInputElement | null>(null);


    const handleCursorChange = (e: any) => {
        const cursor = e.target.selectionStart
        const words = transformation.split(" ");
        const cursorWordIndex = transformation.substr(0, cursor).split(" ").length - 1;
        setSearch(words[cursorWordIndex].replace(/[^a-zA-Z0-9._]/g, ''))
    };


    return (
        <div className="d-flex align-items-center" ref={divRef}>
            <input type="text" id={id} value={transformation || ""}  onMouseUp={handleCursorChange}
                   onMouseDown={handleCursorChange} placeholder={`${t('transformation')}...`}
                   onChange={event => {
                       if (event.currentTarget.value) {
                           setShowSuggestion(true)
                       }
                       setTransformation(event.currentTarget.value)

                   }} onKeyUp={handleCursorChange}
                   className="form-control" ref={inputRef}/>


            {showSuggestion && <SuggestionRuleSearchComponent
                search={search}
                // style={{bottom: ((divRef.current?.offsetTop ?? 0) - 30) + "px", minWidth: "300px"}}
                style={{top: ((divRef?.current?.offsetTop ?? 0)) + 45 + "px"}}
                setFocusField={focus => setShowSuggestion(focus)}
                setValue={setTransformation} value={transformation}
                options={columns.map(column => ({
                    label: "pdf." + column.label,
                    value: "pdf." + column.value
                }))} id={id}/>}
        </div>
    );
};

export default SuggestedTransformInput