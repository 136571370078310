import * as React from 'react';
import {useContext, useState} from 'react';
import ModalComponent from "../Shared/Modal/ModalComponent/ModalComponent";
import {Button} from "reactstrap";
import {AnalyticQuery, BaseAnalyticQuery} from "../../types/Analytic";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faTimes} from "@fortawesome/free-solid-svg-icons";
import EditorComponent from "../Shared/EditorComponent/EditorComponent";
import {ReportContext} from "./NewReportGeneratorComponent";
import useTranslate from "../../hooks/useTranslate";
import {actionsReport} from "./Slice";
import {getAnalyticQueryGrouped} from "../../utils/analyticsUtils";
import {uuidV4} from "../../utils";

type Props = {
    toggle: () => void
};
const NewJsonToQueryComponent = ({toggle}: Props) => {

    const {t} = useTranslate('report');
    const [localQuery, setLocalQuery] = useState<string>("");
    const {setQuery, dispatch, getAndSetAllColumns } = useContext(ReportContext)
    return (
        <ModalComponent isOpen size={"xl"} toggle={() => {
        }} footer={<div className="d-flex align-items-end gap-2">
            <Button color={"success"} onClick={() => {
                let parseQuery: BaseAnalyticQuery | AnalyticQuery = JSON.parse(localQuery)

                if (parseQuery) {

                    parseQuery = {
                        ...parseQuery,
                        source: {
                            ...parseQuery.source,
                            temporal_id: uuidV4()
                        }
                    }

                    parseQuery = getAnalyticQueryGrouped(parseQuery as BaseAnalyticQuery, true)
                    dispatch(actionsReport.setBaseQuery({
                        source: {...parseQuery.source},
                        actions: []
                    }))
                    setQuery(parseQuery)

                    if (getAndSetAllColumns) {
                        getAndSetAllColumns({pQuery: parseQuery})
                    }

                    toggle()
                }
            }}>
                <FontAwesomeIcon className={"me-1"} icon={faDownload}/>
                {t("get_query")}
            </Button>

            <Button color={"danger"} onClick={toggle}>
                <FontAwesomeIcon className={"me-1"} icon={faTimes}/>
                {t("common:cancel")}
            </Button>
        </div>}>
            <EditorComponent width="100%"
                             height="500px" onChange={value => {

                setLocalQuery(value)

            }}/>
        </ModalComponent>
    )
};

export default NewJsonToQueryComponent