import {languageData, t} from "../public/routes";
import {useCallback, useEffect, useState} from "react";
import {AnyData} from "../types/AnyData";
import {useSelector} from "react-redux";
import {RootState} from "../store/Reducers";


const UseTranslate = (module: string) => {

  const [languages, setLanguages] = useState<AnyData>(languageData());
  const {user: {config}} = useSelector((root: RootState) => root.AuthReducer);

  const tCallBack = useCallback((field: string) => {
    if (languages) {
      return t(field, module, languages)
    }
    return (field ?? "").replaceAll("_", " ")
  }, [languages])

  useEffect(() => {
    // const languages: any = languageData();
    if (config) {
      const {sbx_crm: {locales}} = config;
      const languagesMapped = Object.keys(languages).reduce((obj: any, key) => {
        if (locales && locales[key]) {

          obj[key] = Object.keys(languages[key]).reduce((obj2: any, key2) => {
            if (locales[key][key2]) {
              obj2[key2] = Object.assign(languages[key][key2], locales[key][key2]);
            } else {
              obj2[key2] = languages[key][key2];
            }
            return obj2;
          }, {})
        } else {
          obj[key] = languages[key];
        }
        return obj;
      }, {});

      setLanguages(languagesMapped);
    }
  }, [config]);

  return {
    t: tCallBack as (field: string) => string,
    languages: null
  };
}


export default UseTranslate;
