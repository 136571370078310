import {FilterOperator} from "./Analytic";

export interface SbxResponse<T = any> {
  success: boolean;
  model?: Model[];
  fetched_results?: { [key: string]: { [key: string]: any } }
  row_count?: number;
  total_pages?: number;
  results?: T[];
  keys?: string[]
  item?: any
  items?: T[];
  error?: string
}

export enum SbxModelField {
  STRING = "STRING",
  BOOLEAN = "BOOLEAN",
  REFERENCE = "REFERENCE",
  INT = "INT",
  FLOAT = "FLOAT",
  TEXT = "TEXT",
  DATE = "DATE"
}

export enum SbxAlternativeModelField {
  LIST_PROVIDER_DB = "LIST_PROVIDER_DB",
  LIST_PROVIDER_SBX = "LIST_PROVIDER_SBX",
  NUMBER_DATE = "NUMBER_DATE",
  EMAIL = "EMAIL",
  PHONE_STRING = "PHONE_STRING",
  DOCUMENT = "DOCUMENT"
}

export enum Andor {
  AND = "AND",
  OR = "OR"
}

type SbxConditionTypes = "=" | ">" | "<" | "IS NOT" | "IS" | "<>" | "IN";

export enum SbxConditionType {
  EQUAL_TO = "=",
  GREATER_THAN = ">",
  SMALLER_THAN = "<",
  EXIST = "IS NOT",
  NO_EXIST = "IS",
  DIFFERENT_OF = "<>",
  LIKE = "LIKE",
  IN = "IN",
}

export enum SbxConditionTypeReport  {
  EXIST = "IS NOT NULL",
  NO_EXIST = "IS NULL",
}

type ConditionVal = string | number | null | number[] | boolean | string[]

export interface GroupCondition {
  ANDOR: Andor | "AND" | "OR";
  FIELD: string;
  OP: SbxConditionType | SbxConditionTypes | FilterOperator;
  VAL: ConditionVal
}

export interface GroupConditionValue {
  ANDOR: Andor | "AND" | "OR";
  FIELD: null | { label: string, value: Model };
  SUB_FIELD: null | { label: string, value: Model };
  OP: null | {

    label: string, value: { condition: SbxConditionType, format?: string; }
  };
  VAL: ConditionVal
  key: string;
}

export interface GroupConditionsValues {
  ANDOR: Andor | "AND" | "OR";
  GROUP: GroupConditionValue[];
}

export interface Condition {
  ANDOR: Andor | "AND" | "OR";
  GROUP: GroupCondition[]
}

export interface SbxQuery {
  domain?: string | number;
  fetch?: string[];
  page?: number;
  size?: number;
  where?: Condition[];
  rows?: { [key: string]: string | number }[]
  row_model?: string;
}

export class Model {
  id!: number;
  type!: SbxModelField;
  name!: string;
  reference_type!: number;
  reference_type_name!: string;
  is_label!: boolean;
  is_array!: boolean;
  is_required!: boolean;
  is_sequence!: boolean;
  reference_model!: any
}

export interface ModelsResponse {
  id: number;
  name: string;
  properties: Model[]
  alias?: string[]
}

export interface META {
  created_time: string;
  updated_time: string;
}

export interface SbxQueryResponse {
  WHERE: any[],
  where: any[],
  FETCH: string[]
  fetch: string[]
  ROW_MODEL: string
  row_model: string
}


export interface SbxGeneralRow {
  _KEY: string;
  _META: META
}

export interface SBXFile {
  mime_type: "image/png" | string
  name: string,
  size: number
  success: boolean
  url: string
  file_key: string
}
