import { Account } from "./Account";

export class Appointment {
  _KEY!: string;
  summary!: string;
  description!: string;
  invitees!: string;
  workflow_id!: number;
  start_date!: number;
  due_date!: number;
  start_time!: string;
  end_time!: string;
  todo!: string;
  all_day!: boolean;
  account!: string;
  sales_addressee!: string;
  crm_user!: string;
  account_data?: { label: string; value: string; data: Account };
  data?: string;
}
