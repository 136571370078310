export enum State {
  PENDING = "PENDING",
  IDLE = "IDLE",
  RESOLVED = "RESOLVED",
  BUILDING = "BUILDING",
  REJECTED = "REJECTED",
  OTHER_PENDING = "OTHER_PENDING",
  SENDING = "SENDING",
  NETWORK_FAILED = "NETWORK_FAILED",
  FINISHED = "FINISHED",
}