import * as React from 'react';
import {useContext, useState} from 'react';
import {Field} from "../../../types/Field";
import {Control, Controller} from "react-hook-form";
import IntermediateAppointment from "./IntermediateAppointment";
import IField from "../../../types/FormBuilder/IField";
import {Task} from "../../../types/Task";
import {CrmInputContext} from "./CrmRenderInput";
import {getDateFromValue} from "../../../utils";

type Props = {
  field: Field
  control?: Control<any, object>
  task?: Task | null;
  preview?: boolean;
};
const CrmAppointmentPickerComponent = ({field, control, task, preview}: Props) => {


  const [appointmentKey, setAppointmentKey] = useState("")
  const {getDefaultValue} = useContext(CrmInputContext)

  const getDataFromProcessData = (field: IField) => {
    try {
      if (task?.process_data) {
        const value = JSON.parse(task.process_data[field.name].value);
        return value.appointment_key;
      }
      throw Error("No data");
    } catch (e) {
      return undefined;
    }
  }

  React.useEffect(() => {
    setAppointmentKey(getDataFromProcessData(field))
  }, [field]);

  function formatValues(field: Field, value: any, structure?: boolean) {
    if (structure) {
      return value?.date ? JSON.stringify({
        ...value,
        date: value.date.toISOString(),
        time: value.time?.toISOString()
      }) : null;
    } else {
      const val = value ? JSON.parse(value) : null;
      return val ? {
        ...val,
        date: getDateFromValue(val.date),
        time: getDateFromValue(val.time)
      } : val;
    }
  }


  return (
    <Controller
      control={control}
      name={field.name}
      defaultValue={() => getDefaultValue(field)}
      rules={{
        required: field.required && !field.read_only
      }}
      render={({field: {onChange, value}}) => (
        <IntermediateAppointment
          fieldProps={field}
          value={formatValues(field, value)}
          process={task?.process_data}
          preview={preview}
          pickerProps={{
            id: field.id.toString(),
            value: formatValues(field, value)?.date,
            timeSecondary: formatValues(field, value)?.time ?? formatValues(field, value)?.date,
            onChange: (date: Date, time?: Date) => onChange(formatValues(field, {
              date,
              time,
              appointment_key: appointmentKey,
              type: field.format_rules_definition.appointment_props?.type ?? "",
              title: field.format_rules_definition.appointment_props?.title ?? ""
            }, true)),
            required: field.required,

          }}
        />
      )}
    />
  );
};

export default CrmAppointmentPickerComponent