import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ModelsResponse} from "../../../types/Sbx";
import {TruncateReport} from "../../../types/Analytic";


type InitialState = {
    models: ModelsResponse[]
    // truncate: TruncateReport
}

export const baseTruncate: TruncateReport = {
    field: "",
    type: "date",
    model: "",
    format: "",
    range: "",
    others: []
}

export const baseTextTruncate: TruncateReport = {
    type: "text",
    model: "",
    value: "",
    label_field: "",
}

export const initialState: InitialState = {
    models: []
    // truncate: {...baseTruncate, temporal_id: uuidV4()}
};

export const truncateSlice = createSlice({
    name: "truncate",
    initialState,
    reducers: {
        setModels(state, {payload}: PayloadAction<{ models: ModelsResponse[] }>) {
            state.models = payload.models
        }
    }
});
