import * as React from "react";
import {getDefaultVarsFromStr, getVariableDefaultValue} from "../../../utils";
import {useContext, useState} from "react";
import {TaskComponentContext} from "../../../context/TaskContext";

const TitleComponent: React.FC<{ id: string, defaultValue: string }> = (props) => {

  const [title, setTitle] = useState('')
  const {
    task,
    getFormValue,
  } = useContext(TaskComponentContext);
  React.useEffect(() => {
    if (props.defaultValue){
      let value = props.defaultValue;


      const varList = getDefaultVarsFromStr(props.defaultValue)
      if (varList && varList.length > 0) {
        varList.forEach((defaultVar) => {
          const nameVar = getVariableDefaultValue(defaultVar);

          if (getFormValue && getFormValue(nameVar)) {
            value = value.replaceAll(defaultVar, getFormValue(nameVar));
          }

          if  (task?.process_data[nameVar]?.value){
            value = value.replaceAll(defaultVar, task?.process_data[nameVar]?.value);
          }

          if  (task?.process_data && task?.process_data[nameVar]){
            value = value.replaceAll(defaultVar, task?.process_data![nameVar] as any as  string ?? "");

          }

        });
      }


      setTitle(value)
    }

  }, [props, task]);

  return <div id={props.id} dangerouslySetInnerHTML={{__html: title ?? ""}}/>

};

export default TitleComponent;
