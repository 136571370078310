import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AuthReducer, AuthStates} from './Types';
import {Config, UserData} from '../../types/User';

const initialState: AuthReducer = {
    user: {
        permissions: [],
        permissionsMapper: {},
        id: 0,
        groups: [],
        isAdmin: false,
        domains: []
    },
    state: AuthStates.IDLE,
};

const slice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        login(state, action: PayloadAction<{ username: string, password: string }>) {
            state.state = AuthStates.AUTHENTICATING;
        },
        login_as(state, action: PayloadAction<{ username: string }>) {
            state.state = AuthStates.AUTHENTICATING;
        },
        updateUser: (state, action: PayloadAction<UserData>) => {
            state.user = action.payload;
        },
        setUser(state, action: PayloadAction<{ user: UserData, state: AuthStates, path?: string }>) {
            const isAdmin = action.payload.user.groups.some(
                (g) => g.name === "Administrator"
            );
            state.path = action.payload.path;
            state.state = action.payload.state;
            state.user = {...action.payload.user, isAdmin};
        },

        changePath: (state, action: PayloadAction<string | undefined>) => {
            state.path = action.payload;
        },
        setMessage(state, action) {
            state.user = {...state.user, message: action.payload}
        },
        recoverPasswordWithCode(state, action: PayloadAction<{ username: string, code: string, password: string }>) {
            state.state = AuthStates.START_RECOVER_PASSWORD
        },
        recoveredPassword(state) {
            state.state = AuthStates.PASSWORD_RECOVERED
        },
        setRejectedState(state, {payload}: PayloadAction<{ rejectedState: AuthStates }>) {
            state.state = payload.rejectedState ?? AuthStates.AUTHENTICATION_FAILED
        },
        forgotPassword(state, action: PayloadAction<{ username: string }>) {
            state.state = AuthStates.START_FORGOT_PASSWORD;
        },
        setForgotPassword(state) {
            state.state = AuthStates.FORGOT_PASSWORD_SEND
        },
        me(state, payload: PayloadAction<boolean | undefined>) {
            state.state = AuthStates.AUTHENTICATING;
        },
        changeState(state, action: PayloadAction<AuthStates>) {
            state.state = action.payload;
        },
        changeStateMfa(state, action: PayloadAction<AuthStates>) {
            state.mfaStates = action.payload;
        },
        verificationCode: (state, action: PayloadAction<string>) => {
            state.state = AuthStates.AUTHENTICATING;
        },
        verificationCodeActivate: (state, action: PayloadAction<string>) => {
            state.mfaStates = AuthStates.ACTIVATING_MFA_EMAIL;
        },
        generateCode: (state) => {
            state.mfaStates = AuthStates.GENERATING_CODE;
        },
        generateLinkToUser(state, action: PayloadAction<string>) {
            state.linkStates = AuthStates.GENERATING_LINK_TO_LOGIN;
        },
        setLinkGenerated(state, action: PayloadAction<string>) {
            state.linkAccess = {link: action.payload};
            state.linkStates = AuthStates.LINK_TO_LOGIN_GENERATED;
        },
        loginWithLink(state, action: PayloadAction<string>) {
            state.state = AuthStates.AUTHENTICATING;
        },
        changeStateLnk(state, action: PayloadAction<AuthStates>) {
            state.linkStates = action.payload;
        },
        setConfig(state, {payload}: PayloadAction<Config>) {
            state.user.config = payload
        }
    }
});

export const actionsAuth = slice.actions;
export default slice.reducer;
