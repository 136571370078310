import * as React from 'react';
import {useState} from 'react';
import Video from "yet-another-react-lightbox/plugins/video";
import Lightbox from "yet-another-react-lightbox";
import Counter from "yet-another-react-lightbox/plugins/counter";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import NextJsImage from "../RenderImageViewer/NextJsImage";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/counter.css";
import {Slide} from "yet-another-react-lightbox/dist/types";
import {faSearchPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import useTranslate from "../../../hooks/useTranslate";

type Props = {
    imageBase64: string
    imageName: string
};


const ContainerOverlay = styled.div`

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Color de fondo semitransparente */
  color: white; /* Color del texto */
  display: flex;
  justify-content: center;
  align-items: center;
`

const ChatBotImageComponent = ({imageBase64, imageName}: Props) => {

    const [imageViewerOpen, setImageViewerOpen] = useState(false)
    const [image, setImage] = useState("")
    const [hovered, setHovered] = useState(false);
    const {t} = useTranslate("common")

    React.useEffect(() => {

        if (imageBase64) {
            setImage(`data:image/png;base64,${imageBase64}`)
        }

    }, [imageBase64]);

    return (
        <div>
            <div className="pointer position-relative" onMouseEnter={() => setHovered(true)}
                 onMouseLeave={() => setHovered(false)} onClick={() => {
                setImageViewerOpen(true)
            }}>
                {hovered && <ContainerOverlay>
                    <FontAwesomeIcon icon={faSearchPlus} size={"2x"}/>
                    <span>{t("preview")}</span>
                </ContainerOverlay>}
                <img style={{width: '200px', height: '200px'}} src={image} alt={imageName}/>
            </div>


            <Lightbox
                plugins={[Counter, Fullscreen, Video]}
                counter={{style: {top: "unset", bottom: 0}}}
                open={imageViewerOpen}
                close={() => setImageViewerOpen(false)}
                slides={[
                    image
                ] as any as Slide[]}
                render={{slide: NextJsImage}}
            />
        </div>
    );
};

export default ChatBotImageComponent