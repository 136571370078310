import * as React from 'react';
import {useContext, useState} from 'react';
import CustomTableComponent, {Action, Column} from "../../../Shared/CustomTableComponent/CustomTableComponent";
import CreatableSelectComponent from "../../../Shared/FieldComponents/CreatableSelectComponent";
import {uuidV4} from "../../../../utils";
import {StringOption} from "../../../../types/Select";
import useTranslate from "../../../../hooks/useTranslate";
import {ReportGeneratorContext} from "../../ReportGeneratorComponent";
import {AnalyticQueryAction} from "../../../../types/Analytic";
import {OnChangeActionParams} from "../ActionComponent";

type Props = {
  action: AnalyticQueryAction
  onChangeAction: (actions: OnChangeActionParams[]) => void
};


const ActionsForGroupByComponent = ({action, onChangeAction}: Props) => {

  const {
    getActionColumns,
  } = useContext(ReportGeneratorContext);

  const [extraAction, setExtraAction] = useState<{ [key: string]: { [key: string]: number | string } }>({})

  const {t} = useTranslate("common")

  const getOptions = (): StringOption[] => {
    return [
      {label: t("round"), value: "round"}
    ]
  }

  const getColumns = (): Column[] => {
    return [
      {header: t("action"), name: "name"},
    ]
  }

  React.useEffect(() => {

    const actions: OnChangeActionParams[] = []

    if (Object.keys(extraAction).length > 0 && Object.keys(extraAction).every(key => Object.keys(extraAction[key]).every(subKey => typeof extraAction[key][subKey] === "number"))) {

      Object.keys(extraAction).forEach(key => {
        actions.push({action, actionKey: key as keyof AnalyticQueryAction, value: extraAction[key], noGetColumns: true})
      })

    } else {
      getOptions().forEach(option => {
        actions.push({action, actionKey: option.value as keyof AnalyticQueryAction, value: {}, noGetColumns: true})
      })
    }

    onChangeAction(actions)

  }, [extraAction]);


  const actions: Action[] = [
    {
      custom: true, customComponent: (column: { name: string }) => {
        return <div className="flex-grow-1">
          <CreatableSelectComponent id={uuidV4()} name={"columns"}
                                    // menuPosition={"fixed"}
                                    isMulti
                                    value={Object.keys(extraAction).length > 0 ? Object.keys(extraAction[column.name]).map(nColumn => ({
                                      label: t(nColumn),
                                      value: nColumn
                                    })) : null}
                                    placeholder={t("columns")} onChange={(options: StringOption[]) => {

            if (options.length > 0) {
              const prevObj = {...extraAction[column.name]}
              const newObj: { [key: string]: number | string } = {}
              options.forEach(evt => {
                if (prevObj[evt.value]) {
                  newObj[evt.value] = prevObj[evt.value]
                } else {
                  newObj[evt.value] = extraAction[column['name']][Object.keys(extraAction[column['name']])[0]] ?? 0
                }
              })
              setExtraAction(prevState => ({...prevState, [column.name]: newObj}))
            } else {
              setExtraAction(prevState => ({...prevState, [column.name]: {}}))
            }
          }} options={[{label: t("all"), value: "all"}, ...getActionColumns(action)]}/>
        </div>
      }
    }, {
      custom: true, customComponent: (column: { name: string }) => {
        return <div className="flex-grow-1">
          <input className="form-control" placeholder={t("value")}
                 value={Object.keys(extraAction).length > 0 ? extraAction[column['name']][Object.keys(extraAction[column['name']])[0]] ?? 0 : 0}
                 onChange={event => {
                   if (column['name'] && extraAction[column['name']]) {
                     const newObj = {...extraAction}
                     Object.keys(newObj[column['name']]).forEach(key => {
                       newObj[column['name']][key] = event.currentTarget.value.length > 0 ? parseInt(event.currentTarget.value) ?? 0 : ""
                     })

                     setExtraAction(newObj)
                   }
                 }} type={"number"}/>
        </div>
      }
    }
  ]

  return (
    <div>
      <div className="d-flex gap-2 mb-3">
        <div className="col-12 col-lg-6">
          <b>{t("actions")}</b>
          <CreatableSelectComponent isMulti onChange={(value: StringOption[]) => {
            if (value.length > 0) {

              const prevObj = {...extraAction}
              const newObj: { [key: string]: { [key: string]: number | string } } = {}

              value.forEach(evt => {
                if (prevObj[evt.value]) {
                  newObj[evt.value] = prevObj[evt.value]
                } else {
                  newObj[evt.value] = {}
                }
              })

              setExtraAction(newObj)
            } else {
              setExtraAction({})
            }


          }} id={uuidV4()} name={"actions"} menuPosition={"fixed"} options={getOptions()}/>
        </div>
      </div>
      <CustomTableComponent columns={getColumns()} actionsColumnClass="d-flex align-items-center gap-2"
                            actionsColumnLabel={t("actions")}
                            data={Object.keys(extraAction).reduce((arr: { name: string }[], key) => {
                              arr.push({name: key})
                              return arr
                            }, [])} actions={actions}/>
    </div>
  );
};

export default ActionsForGroupByComponent