import * as React from 'react';
import {useContext, useMemo, useState} from 'react';
import useTranslate from "../../../../hooks/useTranslate";
import styled from "styled-components";
import {Controller, useForm} from "react-hook-form";
import {StringOption} from "../../../../types/Select";
import ActionDropdownColumns from "../ActionDropdownComponent/ActionDropdownColumns";
import ColumnsActionsWrapperComponent from "../../ColumnsActionsWrapperComponent";
import {DropdownItem} from "reactstrap";
import SearchSuggestionOption from "../../SearchSuggestionOption";
import {getColumnType} from "../utils";
import {ReportContext} from "../../NewReportGeneratorComponent";

type Props = {
    columns: StringOption[]
    color: string
    index: number
    onChange: (value: string) => void
    value: string
};

const baseOp = (t: (field: string) => string) => [
    {label: `${t("seconds")}`, value: "S"},
    {label: `${t("day")}s`, value: "D"},
    {label: `${t("week")}s`, value: "W"}
]

const Container = styled.div`
  //display: grid;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  //grid-template-columns: repeat(4, minmax(2em, em));
  gap: 10px;
  //align-items: end;
`


type Form = {
    column1: string,
    column2?: string,
    operation: string,
    op: string

}

const TransformDateCalculator = ({columns, color, onChange, index, value}: Props) => {

    const {t} = useTranslate('report')
    const [op, setOp] = useState(baseOp(t))
    const {control, watch, getValues, setValue} = useForm<Form>({
        defaultValues: {
            op: 'D',
            operation: '@add'
        }
    })
    const {state} = useContext(ReportContext)
    const dateOperationActions = useMemo(() => [
        {label: "+", value: '@add'},
        {label: "-", value: '@sub'},
        {label: t("diff"), value: '@diff'},
    ], []);


    // Callback version of watch.  It's your responsibility to unsubscribe when done.
    React.useEffect(() => {
        const subscription = watch((form) => {
                if (form.column1) {
                    onChange(`${form.operation} ${form.column1} ${form.column2 ?? 1} ${form.op}`)
                }

                if (form.column2) {
                    if (form.column2) {
                        const type = getColumnType({
                            index: index,
                            columnsType: state.columnsType,
                            name: form.column2
                        })


                        if (type === "DATE") {
                            setOp([...op, {label: t("months"), value: "M"},
                                {label: `${t("year")}s`, value: "Y"}])
                        } else {
                            setOp(baseOp(t))
                        }
                    }
                }
            }
        )
        return () => subscription.unsubscribe()
    }, [watch])

    React.useEffect(() => {

        if (value) {
            const form = getValues()
            if (!form.column1 && !form.column2) {
                const [operation, column1, column2, op] = value.split(" ")
                setValue("operation", operation)
                setValue("column1", column1)
                setValue("column2", column2)
                setValue("op", op)
            }
        }

    }, [value]);

    return (
        <div className="d-flex flex-column gap-3">

            <Container>
                <Controller render={({field}) => (
                    <ActionDropdownColumns color={color} columns={columns.map(column => column.value)}
                                           label={watch('column1') ?? t("common:column")}
                                           onChange={field.onChange}/>
                )} name={"column1"} control={control}/>

                <Controller render={({field}) => (
                    <ActionDropdownColumns color={color} columns={[]}
                                           label={`${dateOperationActions.find(it => it.value === watch('operation'))?.label}`}
                                           onChange={field.onChange}>
                        <ColumnsActionsWrapperComponent>
                            {search => {
                                return <>
                                    {dateOperationActions.filter(column => column.value.toLowerCase().includes(search.toLowerCase())).map((column, indexColumn) => {
                                        return <DropdownItem onClick={() => {
                                            field.onChange(column.value)
                                        }} key={column + "_" + indexColumn}>
                                            {column.label}
                                        </DropdownItem>
                                    })}

                                    {search ?
                                        <SearchSuggestionOption search={search} onChange={() => {
                                        }} toggle={() => {
                                            field.onChange(search)
                                        }}/> : columns.length === 0 ?
                                            <DropdownItem disabled>{t("common:empty")}</DropdownItem> : null
                                    }
                                </>
                            }}
                        </ColumnsActionsWrapperComponent>
                    </ActionDropdownColumns>
                )} name={"operation"} control={control}/>

                <Controller render={({field}) => (
                    <ActionDropdownColumns color={color} columns={[]}
                                           label={watch('column2') ?? `${t("number")} ${t("common:or")} ${t("common:column")}`}
                                           onChange={field.onChange}>
                        <ColumnsActionsWrapperComponent>
                            {search => {
                                return <>
                                    {columns.filter(column => column.value.toLowerCase().includes(search.toLowerCase())).map((column, indexColumn) => {
                                        return <DropdownItem onClick={() => {
                                            field.onChange(column.value)
                                        }} key={column + "_" + indexColumn}>
                                            {column.label}
                                        </DropdownItem>
                                    })}

                                    {search ?
                                        <SearchSuggestionOption search={search} onChange={() => {
                                        }} toggle={() => {
                                            field.onChange(search)
                                        }}/> : columns.length === 0 ?
                                            <DropdownItem disabled>{t("common:empty")}</DropdownItem> : null
                                    }
                                </>
                            }}
                        </ColumnsActionsWrapperComponent>
                    </ActionDropdownColumns>
                )} name={"column2"} control={control}/>

                <Controller render={({field}) => (
                    <ActionDropdownColumns color={color} columns={[]}
                                           label={`${op.find(it => it.value === watch('op'))?.label}`}
                                           onChange={field.onChange}>
                        <ColumnsActionsWrapperComponent>
                            {search => {
                                return <>
                                    {op.filter(column => column.value.toLowerCase().includes(search.toLowerCase())).map((column, indexColumn) => {
                                        return <DropdownItem onClick={() => {
                                            field.onChange(column.value)
                                        }} key={column + "_" + indexColumn}>
                                            {column.label}
                                        </DropdownItem>
                                    })}

                                    {search ?
                                        <SearchSuggestionOption search={search} onChange={() => {
                                        }} toggle={() => {
                                            field.onChange(search)
                                        }}/> : columns.length === 0 ?
                                            <DropdownItem disabled>{t("common:empty")}</DropdownItem> : null
                                    }
                                </>
                            }}
                        </ColumnsActionsWrapperComponent>
                    </ActionDropdownColumns>
                )} name={"op"} control={control}/>

            </Container>
        </div>
    );
};

export default TransformDateCalculator