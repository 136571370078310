import * as React from 'react';
import {useContext, useRef, useState} from 'react';
import {ActionButton, ReportContext} from "../NewReportGeneratorComponent";
import {Controller, useFieldArray, useForm} from "react-hook-form";
import useTranslate from "../../../hooks/useTranslate";
import {State} from "../../../types/State";
import {AnalyticQueryAction} from "../../../types/Analytic";
import SummarizeDropdownComponent from "../SummarizeDropdownComponent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlay, faPlusCircle, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import ReportSummarizeColumnsComponent from "./NewReportSummarizeComponent/ReportSummarizeColumnsComponent";
import ActionDropdownColumns from "./ActionDropdownComponent/ActionDropdownColumns";
import CustomTableComponent from "../../Shared/CustomTableComponent/CustomTableComponent";
import {ReportSummarizeForm} from "./NewReportSummarizeComponent/ReportSummarizeComponent";
import styled from "styled-components";
import {analyticAggList} from "../../../utils/analyticsUtils";

type Props = {
    queryAction?: AnalyticQueryAction,
    indexAction?: number
    color?: string
}

const FilterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  gap: 5px;

  @media (max-width: 968px) {
    flex-direction: column;
  }
`

const CompareInput = styled.input`
  min-height: 27px !important;
  height: 27px;
width: 80px;
`

const NewDateAnalysisActionComponent = ({indexAction, queryAction, color}: Props) => {
    const {state, getData, query, setQueryAction} = useContext(ReportContext)
    let onMount = useRef(false)

    const {
        control, watch, getValues, setValue
    } = useForm<ReportSummarizeForm>({
        defaultValues: {conditions: [], date_in_rows: false, compare_operation: false}
    });
    const aggMethods = useFieldArray({
        control,
        name: "conditions"
    });
    const {t} = useTranslate("report")

    const indexColumnsMethods = useFieldArray({
        control,
        name: "index_columns"
    });

    const [showResults, setShowResults] = useState(false)
    const [results, setResults] = useState<any[]>([])
    const [loading, setLoading] = useState(State.IDLE)

    React.useEffect(() => {
        const subscription = watch((obj, {name, type}) => {

            const filters = obj["conditions"] ?? []
            const index_columns = obj["index_columns"] ?? []

            if (filters.length > 0) {

                const agg = filters.reduce((acc: { [key: string]: string[] }, filter) => {
                    if (filter?.column && filter?.agg) {
                        if (!acc[filter.column]){
                            acc[filter.column] = []
                        }
                        acc[filter.column].push(filter.agg)
                    }
                    return acc
                }, {})

                if (typeof indexAction !== 'undefined' && queryAction && indexAction >= 0) {

                    const actionParams: AnalyticQueryAction = {
                        ...queryAction,
                        compare_operation: obj["compare_operation"],
                        date_in_rows: obj["date_in_rows"],
                        agg
                    }

                    actionParams["index_columns"] = index_columns.map(column => column?.name ?? "")
                    actionParams["analysis_by"] = obj["analysis_by"] ?? ""
                    actionParams["compare_with"] = obj["compare_with"] ?? ""
                    actionParams["compare_quantity"] = obj["compare_quantity"]

                    setQueryAction({
                        ...actionParams
                    }, indexAction)

                }
            }
        });
        return () => subscription.unsubscribe();
    }, [watch, query]);

    const handleGetData = () => {
        const filters = getValues("conditions") ?? []
        const columns = getValues("columns") ?? []

        getData({
            setLoading,
            setResults,
            query: {
                source: {...query.source, with: state.model?.name ?? ""},
                actions: [
                    {
                        type: "group_by",
                        agg: filters.reduce((acc: { [key: string]: string[] }, filter) => {
                            if (!acc[filter.column]){
                                acc[filter.column] = []
                            }
                            acc[filter.column].push(filter.agg)
                            return acc
                        }, {}),
                        columns: columns.map(column => column.name)
                    }
                ],
                preview: true
            }
        })
    }

    React.useEffect(() => {

        if (query.actions.length > 0 && !onMount.current) {

            const action = queryAction ?? query.actions[0][0]

            onMount.current = true
            if (['date_analysis'].includes(action.type)) {

                if (action.agg) {

                    const agg: { column: string, agg: string }[] = []
                    Object.keys(action.agg).forEach(key => {
                        action.agg![key].forEach(aggKey => {
                            agg.push({
                                column: key,
                                agg: aggKey as string
                            })
                        })
                    })
                    aggMethods.fields.forEach((field, index) => {
                        aggMethods.remove(index)
                    })
                    agg.forEach((item) => {
                        aggMethods.append(item)
                    })
                }

                if (action.index_columns) {
                    const columns = action.index_columns.map((column: string) => ({
                        name: column
                    }))
                    indexColumnsMethods.fields.forEach((field, index) => {
                        indexColumnsMethods.remove(index)
                    })
                    columns.forEach((item: { name: string }) => {
                        indexColumnsMethods.append(item)
                    })
                }


                // Only case on actions because with set some fields and override natural values
                setValue("analysis_by", action.analysis_by ?? "")
                setValue("compare_with", action.compare_with ?? "")
                setValue("compare_quantity", action.compare_quantity ?? 0)
                setValue("date_in_rows", action.date_in_rows ?? false)
                setValue("compare_operation", action.compare_operation ?? false)

            }
        }

    }, [query, queryAction, indexColumnsMethods.fields]);

    return (
        <div className="d-flex flex-column">
            <div className="d-flex flex-column align-items-start gap-2">
                <FilterContainer>
                    <span className="text-center">
                            {t("analysis_of")}
                            </span>

                    <div className="d-flex align-items-center gap-3">
                        {aggMethods.fields.length > 0 && <div className="d-flex flex-wrap gap-2">
                            {aggMethods.fields.map((filter, index) =>
                                <SummarizeDropdownComponent key={filter.id} index={index} model={state.model}
                                                            getValues={getValues} color={color}
                                                            aggList={analyticAggList}
                                                            agg={watch(`conditions.${index}`)}
                                                            queryAction={queryAction} indexAction={indexAction}
                                                            update={aggMethods.update} watch={watch} control={control}
                                                            remove={aggMethods.remove}/>
                            )}</div>}

                        <ActionButton className="btn" color={color ?? "white"} textColor={"white"} onClick={() => {
                            aggMethods.append({
                                agg: "",
                                column: ""
                            })
                        }}>

                            <FontAwesomeIcon icon={faPlusCircle}/>
                            {aggMethods.fields.length === 0 ? t("add_summarize") : ""}
                        </ActionButton>
                    </div>

                    <span>{t("common:by")}</span>


                    <Controller render={({field}) => {
                        return <ActionDropdownColumns color={color} onChange={value => {
                            field.onChange(value)
                        }} columns={["year", "month", "week"]}
                                                      label={field.value ? t(field.value) : t("analysis_by")}/>
                    }} control={control} name={"analysis_by"}/>

                    <span>{t("with")}</span>

                    <Controller render={({field}) => {
                        return <CompareInput type="number" className="form-control" value={field.value || undefined}
                                             onChange={e => {
                                                 field.onChange(e.target.value ? parseInt(e.target.value) : 0)
                                             }} placeholder={t("compare_quantity")}/>
                    }} control={control} name={"compare_quantity"}/>

                    <Controller render={({field}) => {

                        const quantity = (watch("compare_quantity") ?? 0)
                        let extraLetter = ((quantity > 1 || quantity < 0) ? "s" : "")


                        return <ActionDropdownColumns color={color} onChange={value => {
                            field.onChange(value)
                        }} columns={{
                            year: ["year"],
                            month: ["year", "month"],
                            week: ["year", "week"]
                        }[watch("analysis_by") ?? ""] ?? []}
                                                      label={field.value ? `${t(field.value)}${extraLetter}` : t("compare_with")}/>
                    }} control={control} name={"compare_with"}/>


                    <span>{(watch("compare_quantity") ?? 1) > 0 ? t("after") : t("before")},</span>

                    <span>{t("group_by").toLowerCase()} {t("common:by")}</span>

                    {indexColumnsMethods.fields.length > 0 && <div className="d-flex flex-wrap gap-2">
                        {indexColumnsMethods.fields.map((filter, index) =>
                            <ReportSummarizeColumnsComponent key={filter.id} index={index} model={state.model}
                                                             queryAction={queryAction} indexAction={indexAction}
                                                             watch={watch} color={color}
                                                             formName={"index_columns"}
                                                             control={control} getValues={getValues}
                                                             remove={indexColumnsMethods.remove}/>
                        )}</div>}

                    <ActionButton className="btn" color={color ?? "white"} textColor={"white"} onClick={() => {
                        indexColumnsMethods.append({
                            name: ""
                        })
                    }}>
                        <FontAwesomeIcon icon={faPlusCircle}/>
                        {indexColumnsMethods.fields.length === 0 ? `${t("common:add")} ${t("common:column")}` : ""}
                    </ActionButton>

                    <span>({t("common:optional")})</span>

                    {!queryAction && <div>
                        <FontAwesomeIcon className="pointer" icon={faPlay} onClick={() => {
                            setShowResults(true)
                            handleGetData()
                        }}/></div>}


                </FilterContainer>

                <FilterContainer>

                    <Controller render={({field}) => {
                        return <div className="position-relative form-check">
                            <input
                                name="date_in_rows"
                                id="date_in_rows"
                                type="checkbox"
                                className="form-check-input"
                                checked={field.value}
                                onChange={evt => {
                                    field.onChange(evt.target.checked)
                                    if (evt.target.checked) {
                                        setValue("compare_operation", false)
                                    }
                                }}
                            />
                            <label htmlFor="date_in_rows" className="form-label form-check-label mb-0">
                                {t("date_in_rows")}
                            </label>
                        </div>
                    }} control={control} name={"date_in_rows"}/>


                    {!watch('date_in_rows') && <Controller render={({field}) => {
                        return <div className="position-relative form-check">
                            <input
                                name="compare_operation"
                                id="compare_operation"
                                type="checkbox"
                                className="form-check-input"
                                checked={field.value}
                                onChange={evt => {
                                    field.onChange(evt.target.checked)
                                }}
                            />
                            <label htmlFor="compare_operation" className="form-label form-check-label mb-0">
                                {t("compare_operation")}
                            </label>
                        </div>
                    }} control={control} name={"compare_operation"}/>}


                </FilterContainer>
            </div>
            {showResults && <div className="p-3">
                <div className="d-flex gap-2 align-items-center card-header mb-2">
                    <span>{t("common:preview")}</span>
                    <FontAwesomeIcon icon={faTimesCircle} className="pointer"
                                     onClick={() => {
                                         setShowResults(false)
                                     }}/>
                </div>

                <CustomTableComponent
                    columns={results && results[0] ? Object.keys(results[0]).map(item => ({
                        name: item,
                        header: item
                    })) : []}
                    data={results?.length > 0 ? results : []}
                    loading={loading === State.PENDING}
                />
            </div>}
        </div>
    );
};

export default NewDateAnalysisActionComponent