import { get, post } from "../../network";
import { Response } from "../../types/Response";
import { ConfigData } from "../../types/ConfigData";

export async function loadConfigService(): Promise<Response<ConfigData>> {
  try {
    const res = await get(`/api/domain/v1/app/config`);
    res.item = (res?.items ?? [])[0];
    if (res.item) {
      res.item = { config: JSON.parse(res.item.config), app: res.item.id };
    }
    return res;
  } catch {
    return { success: false };
  }
}

export async function saveConfigService(config: ConfigData): Promise<Response> {
  try {
    return await post(`/api/domain/v1/app/config`, config);
  } catch {
    return { success: false };
  }
}
