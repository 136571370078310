import React, {useReducer} from 'react';
import {Condition, Model, SbxModelField, SbxResponse} from '../../../../types/Sbx';

import {Button, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import {SubmitHandler, useForm, UseFormGetValues} from 'react-hook-form';
import {
    cloudScriptRun,
    findAllByModel,
    findByModel,
    insertSbxModelService,
    updateSbxModel
} from '../../../../services/backend/SbxService';
import {State} from '../../../../types/State';
import {
    debounceTime,
    evalConditionExpression,
    getAllDataByProvider,
    getDefaultVarsFromStr,
    getVariableDefaultValue,
    isDefaultVarExpression,
    IsJsonString,
    success,
    toast,
    uuidV4
} from '../../../../utils';
import TableInputComponent from './TableInputComponent';
import useTranslate from '../../../../hooks/useTranslate';
import SpinnerComponent from '../../SpinnerComponent';
import {actionsModal, ModalTypes} from '../../../../store/Modal/Slice';
import {useDispatch, useSelector} from 'react-redux';
import 'react-dates/lib/css/_datepicker.css';
import {TableFormColumn} from '../../../TaskComponent/TableForm/TableTaskComponent';
import {DataRelatedAction, SbxCrmDataInfo} from '../../../../types/User';
import DataComponentList from '../../../DataComponent/DataComponentList';
import TabContents, {Tab} from '../../TabContents';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowAltCircleLeft} from '@fortawesome/free-solid-svg-icons';
import {ListProvider} from '../../../../types/Task';
import {TabsNames} from '../../../Layouts/AdminLayout/SideBarContent';
import cogoToast from "cogo-toast";
import {ProviderType} from "../../../../types/ProviderType";
import {SelectSubType} from "../../../../types/Field";
import {uploadFile} from "../../../../services/backend/ContentService";
import {Find} from "sbxcorejs";
// type Props = {
//   headers: Column[]
//   item?: { [key: string]: any }
//   objModel: Model[] | Column[];
//   model: string;
//   toggle: () => void;
//   isOpen: boolean;
//   insert?: boolean;
//   cacheProvider?: {[key: string]: any}
//   setTableItems?: (items: any) => void;
//   tableItems?: any[];
// };

interface IProps {
    data: IPropsTableEditModal;
    open: boolean;
}

export interface IPropsTableEditModal {
    configName: TabsNames;
    headers: TableFormColumn[];
    item?: { [key: string]: any };
    model: (Model | TableFormColumn)[];
    row_model: string;
    toggleHelper?: () => void;
    insert?: boolean;
    process?: SbxCrmDataInfo;
    isDetailView?: boolean;
    cacheProvider?: { [key: string]: any };
    setTableItems?: (items: any) => void;
    tableItems?: any[];
    type: ModalTypes.TABLE_EDIT_MODAL;
    identifierKey?: string,
    pre_data?: IPropsTableEditModal;
    getFormValue?: UseFormGetValues<any>;
    onSuccess?:(params: any, response: any) => void;
    defaultValues?: { [key: string]: any };
    formState?: { [key: string]: any }; //Form values in process execution
}

enum Types {
    SET_STATE = 'SET_STATE',
    SET_FORM_VALUES = 'SET_FORM_VALUES',
    SET_MULTI_FORM_VALUES = 'SET_MULTI_FORM_VALUES',
    SET_REFERENCE_FIELDS = 'SET_REFERENCE_FIELDS',
    SET_ALL_REFERENCE_FIELDS = 'SET_ALL_REFERENCE_FIELDS',
}

export interface TableState {
    form: { [key: string]: string | number },
    reference_fields: { [key: string]: any },
    loading: State,
    selectLoading: TableLoadingState[],
    model: (Model | TableFormColumn)[]
}

export enum TableLoadingState {
    IDLE = "IDLE",
    PENDING = "PENDING",
    PENDING_MODELS = "PENDING_MODELS",
    RESOLVED_MODELS = "RESOLVED_MODELS",
    PENDING_PROVIDER = "PENDING_PROVIDER",
    RESOLVED_PROVIDER = "RESOLVED_PROVIDER",
    RESOLVED = "RESOLVED"
}

const buttonColors = ['success', 'danger', 'primary', 'info', 'secondary', 'warning'];

function reducer(state: TableState, {
    type,
    payload
}: { type: Types, payload: { name: string, value: any } | { name: string, value: any }[] }) {
    switch (type) {
        case Types.SET_STATE:
            payload = (payload as { name: keyof TableState, value: any });
            return {...state, [payload.name]: payload.value};
        case Types.SET_FORM_VALUES:
            payload = (payload as { name: keyof TableState, value: any });
            return {...state, form: {...state.form, [payload.name]: payload.value}};
        case Types.SET_MULTI_FORM_VALUES:
            (payload as { name: keyof TableState, value: any }[]).forEach(data => {
                state[data.name] = data.value;
            });
            return {...state};
        case Types.SET_REFERENCE_FIELDS:
            payload = (payload as { name: keyof TableState, value: any });
            return {...state, reference_fields: {...state.reference_fields, [payload.name]: payload.value}};
        case Types.SET_ALL_REFERENCE_FIELDS:
            payload = (payload as { name: keyof TableState, value: any });
            return {...state, reference_fields: {...state.reference_fields, ...payload.value}};
        default:
            throw new Error();
    }
}

const cacheTableData: { [key: string]: string | number } = {}

const getMissingFieldsMessage = (fields: TableFormColumn[]) => {
    cogoToast.error(<div>
        <b>Debe completar los siguientes campos</b>
        <ul>
            {fields.map(field => <li key={field.name}>{field.label}</li>)}
        </ul>
    </div>, {position: "bottom-left"})
}

export const getTableInput = ({
                                  header,
                                  getFormValue,
                                  form,
                                  model
                              }: {
    header: TableFormColumn,
    getFormValue?: (name: string) => string,
    form?: { [key: string]: string | number },
    model: Model[]
}) => {

    if (header.visible_when && !header.hide) {
        let condition = header.visible_when

        const varList = getDefaultVarsFromStr(header.visible_when)
        if (varList && varList.length > 0) {
            varList.forEach((defaultVar) => {
                const nameVar = getVariableDefaultValue(defaultVar);
                if (getFormValue && getFormValue(nameVar)) {
                    condition = condition.replace(defaultVar, `'${getFormValue(nameVar)}'`)
                } else {
                    if (form && form[nameVar]) {
                        condition = condition.replace(defaultVar, `'${form[nameVar]}'`)
                    }
                }

            });
        }
        if (!isDefaultVarExpression(condition)) {
            if (!eval(condition)) {
                return null
            }
        } else {
            return null
        }
    }

    if (header.type === 'list_provider' || header.type === "time") {
        return header;
    }

    if (header.sub_type === "Date") {
        return {...header, type: SbxModelField.DATE}
    }

    if (header.sub_type === "Document") {
        return {...header, type: "Document"}
    }

    // const input = localState.model.find(model => model.name === header.name) ?? (model as Model[])?.find(model => model.name === header.name);

    const input = model.find(nModel => nModel.name === header.name)

    if (input) {
        return input;
    } else {
        if (header.parent || header.calculated) {
            return {...header, type: header.sub_type?.toUpperCase() as SbxModelField ?? SbxModelField.STRING};
        }
    }

    return null;
};

const TableEditModal = (
    {
        open, data: {
        headers,
        item,
        row_model,
        model,
        setTableItems,
        tableItems,
        toggleHelper,
        insert,
        defaultValues,
        process,
        isDetailView,
        formState,
        getFormValue,
        configName, onSuccess
    }
    }: IProps) => {

    const initialState: TableState = {
        form: {...defaultValues},
        reference_fields: {},
        loading: State.IDLE,
        selectLoading: [],
        model: []
    };

    const {register, handleSubmit, setValue, watch, control, getValues, reset} = useForm<any>();
    const [localState, localDispatch] = useReducer(reducer, initialState);
    const modalState = useSelector((state: any) => state.ModalReducer);
    const {t} = useTranslate('common');
    const dispatch = useDispatch();

    React.useEffect(() => {
        // if (modalState[ModalTypes.TABLE_EDIT_MODAL].pre_data) {
        reset()
        // }
    }, [modalState[ModalTypes.TABLE_EDIT_MODAL].pre_data]);

    React.useEffect(() => {
        dispatchState({name: 'form', value: {...localState.form, ...defaultValues}});
    }, [defaultValues]);

    const dispatchForm = ({name, value}: { name: string, value: any }) => {
        localDispatch({type: Types.SET_FORM_VALUES, payload: {name, value}});
    };

    const toggle = (helper = true) => {
        if (modalState[ModalTypes.TABLE_EDIT_MODAL].pre_data) {

            dispatchState({name: 'form', value: {}})

            dispatch(actionsModal.openModal({
                ...modalState[ModalTypes.TABLE_EDIT_MODAL].pre_data
            }));
        } else {
            dispatch(actionsModal.closeModal({type: ModalTypes.TABLE_EDIT_MODAL}));
            if (helper) {
                toggleHelper && toggleHelper();
            }

        }
    };

    // const dispatchMultiForm = (forms: { name: keyof TableState, value: any }[]) => {
    //   localDispatch({type: Types.SET_MULTI_FORM_VALUES, payload: forms});
    // };

    const dispatchState = ({name, value}: { name: string, value: any }) => {
        localDispatch({type: Types.SET_STATE, payload: {name, value}});
    };

    const dispatchReferenceFields = ({name, value}: { name: string, value: any }) => {
        localDispatch({type: Types.SET_REFERENCE_FIELDS, payload: {name, value}});
    };

    const dispatchAllReferenceFields = ({value}: { value: any }) => {
        localDispatch({type: Types.SET_ALL_REFERENCE_FIELDS, payload: {name: "", value}});
    };

    React.useEffect(() => {

        for (const formKey in localState.form) {
            if (localState.form.hasOwnProperty(formKey) && typeof localState.form[formKey] === 'string' && !isDefaultVarExpression(localState.form[formKey])) {
                setValue(formKey, localState.form[formKey]);
            }
        }
    }, [localState.form]);

    const getProviderData = async (providers: { provider_id: string, header?: TableFormColumn, search?: string }[]) => {

        dispatchState({
            name: 'selectLoading',
            value: [...localState.selectLoading, TableLoadingState.PENDING_PROVIDER]
        });

        const promises = providers.map(provider => ({
            provider_id: provider.provider_id,
            header: provider.header,
            formState: {...formState, ...item},
            getFormValue,
            search: provider.search
        }));

        const reference_fields: { [key: string]: string } = {}
        const response: any[] = await Promise.all(promises.map(item => getAllDataByProvider(item)));

        for (const res of response) {
            if (res?.success && (res as ListProvider).row_model) {

                if (!reference_fields[res.row_model] || (reference_fields[res.row_model] && res.items.length > reference_fields[res.row_model].length)) {
                    reference_fields[res.row_model] = res.items.map((item: any) => ({
                        ...item,
                        provider_type: res.item?.provider_type ?? ProviderType.SBX
                    }));
                }
            } else {
                if (res?.success && res.item?.name) {
                    reference_fields[res.item.name] = res.items.map((item: any) => ({
                        ...item,
                        provider_type: res.item.provider_type ?? ProviderType.SBX
                    }));
                }
            }
        }


        dispatchAllReferenceFields({value: reference_fields})
        dispatchState({
            name: 'selectLoading',
            value: [...localState.selectLoading, TableLoadingState.RESOLVED_PROVIDER].filter(states => states !== TableLoadingState.PENDING_PROVIDER)
        });

    }

    const getSearchableData = async (header: TableFormColumn, search: string) => {
        if (header.type === 'list_provider' && header.table_column_type === SelectSubType.SEARCHEABLE) {
            dispatchState({
                name: 'selectLoading',
                value: [...localState.selectLoading, TableLoadingState.PENDING_PROVIDER]
            });

            const reference_fields = {...localState.reference_fields}

            const provider = {header, provider_id: (header as TableFormColumn)?.list_provider ?? '', search}
            const response: any = await debounceTime(getAllDataByProvider, provider, 700)


            if (response?.success && (response as ListProvider).row_model) {

                if (!reference_fields[response.row_model] || (reference_fields[response.row_model] && response.items.length > reference_fields[response.row_model].length)) {
                    reference_fields[response.row_model] = response.items.map((item: any) => ({
                        ...item,
                        provider_type: response.item?.provider_type ?? ProviderType.SBX
                    }));
                }
            } else {
                if (response?.success && response.item?.name) {
                    reference_fields[response.item.name] = response.items.map((item: any) => ({
                        ...item,
                        provider_type: response.item.provider_type ?? ProviderType.SBX
                    }));
                }
            }

            dispatchAllReferenceFields({value: reference_fields})
            dispatchState({
                name: 'selectLoading',
                value: [...localState.selectLoading, TableLoadingState.RESOLVED_PROVIDER].filter(states => states !== TableLoadingState.PENDING_PROVIDER)
            });
        }
    }

    React.useEffect(() => {

        const getAllData = async (rows: { model: string, where?: Condition[] }[]) => {
            dispatchState({
                name: 'selectLoading',
                value: [...localState.selectLoading, TableLoadingState.PENDING_MODELS]
            });
            const promises = [];


            for (const row of rows) {
                const model_ref = (model as Model[]).find(model => model.reference_type_name === row.model)?.name;
                let fetchModels = [];

                if (model_ref) {
                    const header = headers.find(header => header.name === model_ref);
                    const columns = header?.sub_columns ?? header?.format_rules?.columns_labels ?? null
                    if (header && columns && columns[0].compound_name) {
                        const varList = getDefaultVarsFromStr(columns[0].compound_name);
                        if (varList && varList.length > 0) {

                            varList.forEach(strVar => {
                                const nameVar = getVariableDefaultValue(strVar);
                                if (columns && columns[0]) {
                                    const sub_references = nameVar.split('.');
                                    if (sub_references.length > 1) {
                                        fetchModels.push(`${sub_references[0]}`);
                                    } else {
                                    }
                                }
                            });

                        }
                    } else {
                        if (header && columns && columns[0]?.name?.split('.').length > 1) {
                            const reference = columns[0]?.name?.split('.')[0];
                            fetchModels.push(reference);
                        }
                    }


                }
                // console.log("fetch models", fetchModels)
                promises.push({where: row.where ?? [], row_model: row.model, fetch: fetchModels});
            }


            const reference_fields: { [key: string]: string } = {}

            const response = await Promise.all(promises.map(item => findAllByModel(item)));

            // console.log("fetched prev", allFetchModels)

            for (const res of response) {
                if (res?.success) {
                    if (res?.fetched_results && Object.keys(res.fetched_results)?.length > 0) {
                        for (const fetched in res.fetched_results) {
                            for (const item of (res?.results || [])) {

                                let fetchModel: string = fetched

                                if (res.model.some((model: Model) => model.reference_type_name === fetched)) {
                                    const refModel = res.model.find((model: Model) => model.reference_type_name === fetched)?.name
                                    if (refModel) {
                                        fetchModel = refModel
                                    }
                                }


                                const nItem: any = item;
                                nItem[fetchModel] = res.fetched_results[fetched][nItem[fetchModel]];
                            }
                        }
                    }


                    reference_fields[res.row_model] = res.results;
                }
            }


            dispatchAllReferenceFields({value: reference_fields})
            dispatchState({
                name: 'selectLoading',
                value: [...localState.selectLoading, TableLoadingState.RESOLVED_MODELS].filter(states => states !== TableLoadingState.PENDING_MODELS)
            });
        };

        dispatchState({name: 'model', value: model});

        const references: { model: string, where?: Condition[] }[] = [];
        const providers: { header?: TableFormColumn, provider_id: string }[] = []

        for (const header of headers) {
            if (header.type === 'list_provider' && header.table_column_type !== SelectSubType.SEARCHEABLE) {

                // getProviderData((header as TableFormColumn)?.list_provider ?? '', header);
                if (!providers.some(provider => provider.header && provider.provider_id === (header as TableFormColumn)?.list_provider)) {
                    providers.push({header, provider_id: (header as TableFormColumn)?.list_provider ?? ''})
                }
            }
        }

        for (const field of model) {

            const header = headers.find(header => header.reference === ((field as Model).reference_type_name ?? field.name));

            if (field.type === SbxModelField.REFERENCE) {
                if (header && header.table_column_type === SelectSubType.SEARCHEABLE) {


                    if (item) {
                        const model = field.reference_type_name ?? field.name
                        const key = item[field.name]?._KEY ?? item[field.name];
                        references.push({
                            model, where: [
                                {
                                    'ANDOR': 'AND',
                                    'GROUP': [
                                        {'ANDOR': 'AND', 'VAL': key, 'FIELD': '_KEY', 'OP': '='}]
                                }
                            ]
                        })

                    }
                } else {
                    if (!providers.some(provider => provider.header?.name === (field.reference_type_name ?? field.name))) {
                        references.push({model: field.reference_type_name ?? field.name});
                    }
                }
            }

            if (field.type === 'list_provider' && (field as TableFormColumn)?.list_provider) {
                // getProviderData((field as TableFormColumn)?.list_provider ?? '');
                if (!providers.some(provider => provider.provider_id === (field as TableFormColumn)?.list_provider)) {
                    providers.push({provider_id: (field as TableFormColumn)?.list_provider ?? ''});
                }
            }
        }


        if (references.length > 0) {
            getAllData(references);
        }

        if (!headers.some(header => header.type === 'list_provider' && header.table_column_type === SelectSubType.SEARCHEABLE)) {
            getProviderBySearch({})
        }

    }, [model, headers]);


    const getProviderBySearch = ({search}: { search?: string }) => {
        const providers: { header?: TableFormColumn, provider_id: string }[] = []

        for (const header of headers) {
            if (header.type === 'list_provider') {
                if (!providers.some(provider => provider.header && provider.provider_id === (header as TableFormColumn)?.list_provider)) {
                    providers.push({header, provider_id: (header as TableFormColumn)?.list_provider ?? ''})
                }
            }
        }

        if (providers.length > 0) {
            getProviderData(providers)
        }
    }

    const onSubmit: SubmitHandler<any> = async data => {


        if (row_model) {
            // if exist a var in our local reducer provided by json metadata, we'll extract de info and place it in the metadata
            Object.keys(localState.form).forEach(key => {
                if (key.split('.').length > 1) {
                    const keys = key.split('.');
                    data[keys[0]] = {
                        ...data[keys[0]],
                        [keys[1]]: localState.form[key]
                    };
                    delete localState.form[key];
                }

                if (localState.form[key] && isDefaultVarExpression(localState.form[key])) {
                    delete localState.form[key];
                }
            });
            let params = {...data, ...localState.form};
            Object.keys(params).forEach(key => {
                if (!(data[key] instanceof File)) {
                    if (typeof params[key] === 'object' && !(params[key] instanceof Date)) {
                        params[key] = JSON.stringify(params[key]);
                    } else {
                        if (params[key] && isDefaultVarExpression(params[key])) {
                            delete params[key];
                        }
                    }
                }

            });

            const missingFields = headers.filter((header) => !header.group && header.required).filter(header => !params[header.name]);

            if (missingFields.length > 0) {
                getMissingFieldsMessage(missingFields)
                return;
            }


            for await (const header of headers) {
                if (header.column && params[header.column] && header.skip) {
                    delete params[header.column]
                }

                if (header.type === "Document") {


                    if (params[header.name]?.path) {
                        const response: SbxResponse = await uploadFile({data: {
                            file_name: `data_${row_model}_${params[header.name].path}`,
                            path: `data/${row_model}/${header.label ?? ""}`,
                            file: data[header.name] as File}
                        })


                        if (response?.success && response?.item?.key_id) {
                            params = {...params, [header.name]: response?.item?.key_id}
                        }
                    } else {
                        if (IsJsonString(params[header.name])) {
                            const file = JSON.parse(params[header.name])
                            if (file?.file_key) {
                                params = {...params, [header.name]: file.file_key}
                            }
                        }
                    }
                }
            }

            if (item) {


                dispatchState({name: 'loading', value: State.PENDING});


                const response: SbxResponse = await updateSbxModel({
                    token: '',
                    data: {row_model, rows: [{_KEY: item._KEY, ...params}]}
                });
                if (response.success) {

                    onSuccess && onSuccess({row_model, rows: [{_KEY: item._KEY, ...params}]}, response)
                    toast({message: t('success_update_message')});
                    dispatchState({name: 'loading', value: State.RESOLVED});
                    toggle(true);
                } else {
                    toast({message: t('rejected_message'), type: 'error'});
                    dispatchState({name: 'loading', value: State.REJECTED});
                }
            } else {
                if (insert) {

                    let uniqueRows = headers.filter(header => header.unique)
                    if (uniqueRows.length > 0) {

                        uniqueRows = uniqueRows.filter(header => {
                            if (typeof header.unique === "boolean") {
                                return header.unique
                            }

                            return evalConditionExpression({
                                expression: header.unique as string,
                                form: {...params},
                                fields: headers.map(header => header.name)
                            })
                        })

                        if (uniqueRows.length > 0) {
                            dispatchState({name: 'loading', value: State.PENDING});


                            const promises = uniqueRows.map(row => {

                                let query = new Find(row_model, 0);
                                query.andWhereIsEqualTo(row.name ?? "", params[row.name]);
                                query = query.compile();

                                return findByModel({row_model, where: (query as any).where}).then(res => ({
                                    ...res,
                                    row_name: row.name
                                }));
                            })

                            const responsePromise = await Promise.all(promises);
                            if (responsePromise.length > 0) {
                                const row_results = responsePromise.filter(res => res.success && res.results.length > 0)

                                if (row_results.length > 0) {

                                    cogoToast.error(<div>
                                        <ul>
                                            {row_results.map((result: any) => {

                                                const header = headers.find(header => header.name === result.row_name)

                                                return <li key={result._KEY} className="" style={{fontSize: '16px'}}>
                                                    {t('currently_exists')} {header?.label ?? ""} {params[result.row_name]}
                                                </li>
                                            })}
                                        </ul>
                                    </div>, {position: "bottom-left"})

                                    dispatchState({name: 'loading', value: State.RESOLVED});
                                    return
                                }
                            }
                        }
                    }

                    
                    dispatchState({name: 'loading', value: State.PENDING});
                    const response = await insertSbxModelService({row_model, rows: [params]});
                    if (response.success) {
                        onSuccess && onSuccess({row_model, rows: [params]}, response)
                        reset()
                        toast({message: t('success_add_message')});
                        dispatchState({name: 'loading', value: State.RESOLVED});
                        toggle(true);
                    } else {
                        toast({message: t('rejected_message'), type: 'error'});
                        dispatchState({name: 'loading', value: State.REJECTED});
                    }
                }
            }
        } else {
            if (Object.keys(cacheTableData).length > 0) {
                Object.keys(data).forEach(key => {
                    if (!data[key] && cacheTableData[key]) {
                        data[key] = cacheTableData[key]
                    }
                })
            }

            if (item?.id && tableItems) {

                let missingFields: TableFormColumn[] = []
                const items = tableItems.map(tableItem => {
                    if (item.id === tableItem.id) {
                        if (Object.keys(localState.form)) {
                            data = {...data, ...localState.form};
                        }

                        if (missingFields.length === 0) {
                            missingFields = headers.filter((header) => !header.group && header.required).filter(header => !data[header.name]);
                        }

                        tableItem = {...tableItem, ...data};
                    }

                    return tableItem;
                });

                if (missingFields.length > 0) {
                    getMissingFieldsMessage(missingFields)
                    return;
                }

                if (setTableItems) {
                    setTableItems(items);
                }
            } else {
                if (setTableItems) {
                    tableItems ||= [];
                    let newData = {...data, id: uuidV4()};
                    if (Object.keys(localState.form)) {
                        newData = {...newData, ...localState.form};
                    }

                    model.forEach(modelItem => {
                        if (modelItem.type === 'constant') {
                            newData[modelItem.name] = modelItem.constant;
                        }
                    });

                    const missingFields = headers.filter((header) => !header.group && header.required).filter(header => !newData[header.name]);

                    if (missingFields.length > 0) {
                        getMissingFieldsMessage(missingFields)
                        return;
                    }


                    setTableItems([...tableItems, newData]);
                }
            }
            toggle();
        }

    }


    const onError = (errors: any, e: any) => {
        let fields = headers.filter((header) => !header.group && Object.keys(errors).includes(header.name));

        if (fields && fields!?.length > 0) {
            const form = getValues();

            const missingFields = headers.filter((header) => !header.group && header.required).filter(header => !form[header.name] && !fields.some(field => field.name === header.name));

            if (missingFields.length > 0) {
                fields = fields.concat(missingFields)
            }

            cogoToast.error(<div>
                <b>Debe completar los siguientes campos</b>
                <ul>
                    {fields.map(field => <li key={field.name}>{field.label}</li>)}
                </ul>
            </div>, {position: "bottom-left"})
        } else {
            toast({type: "error", message: "Debe completar los campos faltantes."});
        }
    };

    const renderInputs = (headerList: TableFormColumn[], isGroupInput = false) => {
        return [...headerList].sort((a, b) => ((a?.sort_index ?? 0) - (b?.sort_index ?? 0))).map((header, index) => {

            const input = getTableInput({
                header,
                model: (localState.model ?? model) as Model[],
                form: localState.form,
                getFormValue
            });

            return input?.name ?
                <TableInputComponent inputType={input.type as 'list_provider' | SbxModelField | "time"}
                                     key={input.name + "_" + (configName ?? index)}
                                     // header={{...header, read_only: header.unique && !insert ? true : header.read_only}}
                                        header={header}
                                     dispatchForm={dispatchForm}
                                     getSearchableData={getSearchableData}
                                     register={register} index={index}
                                     tableState={localState}
                                     dispatchReferenceFields={dispatchReferenceFields}
                                     taskFormState={formState}
                                     cacheTableData={cacheTableData}
                                     watch={watch} control={control}
                                     getValue={getValues}
                                     getFormValues={getFormValue}
                                     isGroupInput={isGroupInput}
                                     setValue={setValue}
                                     item={item}/> : null;
        });
    };

    const getInputLayout = () => {
        if (headers.some(header => header.group)) {

            const layout = headers.reduce((objLayout: { [group: string]: TableFormColumn[] }, header) => {
                if (header.group) {
                    objLayout[header.group] ||= [];
                    objLayout[header.group].push(header);
                }
                return objLayout;
            }, {});


            return <div className="">
                {Object.keys(layout).map(groupKey => {
                    return <fieldset className="border p-2 mb-2" key={groupKey}>
                        <legend className="w-auto">{groupKey}</legend>
                        <div className="row">
                            {renderInputs(layout[groupKey], true)}
                        </div>
                    </fieldset>;
                })}
            </div>;
        }

        return <Row>
            {renderInputs(headers)}
        </Row>;
    };

    const relatedData = () => {

        if (process && process.related_objects && item) {

            const tabs: Tab[] = [];

            Object.keys(process.related_objects).forEach(model => {

                let mainModel = ""
                if (process.related_objects) {
                    const nameColumn = process.related_objects[model]?.columns.find(column => column.reference === row_model)?.column
                    if (nameColumn) {
                        mainModel = nameColumn
                    }
                }


                tabs.push(
                    {
                        label: <> {(process['related_objects'] as any)[model].label} </>,
                        component: <DataComponentList
                            configName={configName}
                            dataModelName={model}
                            dataType={(process['related_objects'] as any)[model].related_object_type}
                            dataProcess={(process['related_objects'] as any)[model]} key={model}
                            mainModel={mainModel}
                            isDetailView={(process['related_objects'] as any)[model].related_object_type === 'file'}
                            keyFilter={item._KEY} filterObj={`${mainModel}._KEY`}/>
                    }
                );
            });

            return <TabContents tabs={tabs}/>;
        }

        return null;
    };

    const relatedActionClick = (action: DataRelatedAction) => {
        dispatchState({name: 'loading', value: State.PENDING});
        return {
            CS: async () => await cloudScriptRun({
                key: action.key,
                params: {...getValues(), _KEY: item?._KEY ?? ""}
            }).then(res => {
                dispatchState({name: 'loading', value: State.RESOLVED});
                if (res?.message) {
                    success(res.message)
                }
            })
        }[action.action]()
    }

    return (
        <Modal isOpen={open} size={'xl'}>
            <ModalHeader toggle={() => toggle(false)}>
                <div className="d-flex align-items-center gap-2">
                    {modalState[ModalTypes.TABLE_EDIT_MODAL].pre_data &&
                        <FontAwesomeIcon icon={faArrowAltCircleLeft} className="pointer" onClick={() => {
                            dispatch(actionsModal.openModal({
                                ...modalState[ModalTypes.TABLE_EDIT_MODAL].pre_data
                            }));
                        }}/>}
                    {insert ? t('new') : t('edit')}
                </div>
            </ModalHeader>

            {localState.loading === State.PENDING ?
                <div className="d-flex justify-content-center align-items-center m-5">
                    <SpinnerComponent/>
                </div> :

                <Form onSubmit={handleSubmit(onSubmit, onError)}>
                    <ModalBody>

                        {isDetailView && process?.description && <div className="d-flex flex-column mb-2">
                            {/*<h3>{t("account:description")}</h3>*/}
                            <h5>{process.description}</h5>
                        </div>}
                        {getInputLayout()}
                        {relatedData()}

                    </ModalBody>
                    <ModalFooter>

                        {process?.related_actions && process.related_actions.map((action, index) => {
                            return <Button color={action.action_type ?? buttonColors[index]}
                                           onClick={() => relatedActionClick(action)} key={action.key ?? index}>
                                {action.label ?? ""}
                            </Button>
                        })}

                        {!isDetailView && <Button color={'primary'}>
                            {t("finish")}
                        </Button>}
                    </ModalFooter>
                </Form>
            }
        </Modal>
    );
};

export default TableEditModal;
