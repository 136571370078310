import * as React from 'react';
import {useState} from 'react';
import {Report} from "../../types/Analytic";
import {IsJsonString, labelRange, removeDuplicateFromArrayObj, truncateArray, uuidV4} from "../../utils";
import {
  formatDateOptions
} from "../ReportGeneratorComponent/ActionsComponent/TransformComponent/DateTransformComponent/DateTransformFormComponent";
import {State} from "../../types/State";
import {Control, Controller, UseFormGetValues, UseFormRegister, UseFormSetValue} from "react-hook-form";
import {Switch} from "antd";
import CreatableSelectComponent from "../Shared/FieldComponents/CreatableSelectComponent";
import {StringOption} from "../../types/Select";
import MultiSelectComponent from "../Shared/FieldComponents/MultiSelectComponent";
import CustomTableComponent from "../Shared/CustomTableComponent/CustomTableComponent";
import TabContents, {Tab} from "../Shared/TabContents";
import EditorComponent from "../Shared/EditorComponent/EditorComponent";
import {InputField, MetaOptions} from "../ReportGeneratorComponent/SaveReportModal";
import useTranslate from "../../hooks/useTranslate";
import {ModelsResponse} from "../../types/Sbx";
import {ReportReducerState} from "../ReportGeneratorComponent/ReportGeneratorComponent";

type Props = {
  field: InputField,
  control: Control<any, object>
  register: UseFormRegister<any>
  models: ModelsResponse[]
  report?: Report
  reportState: ReportReducerState
  getValues: UseFormGetValues<any>
  setValue: UseFormSetValue<any>
  loading: State
};

type MetadataField = 'x_axis' | 'y_axis' | 'split_by' | 'total' | 'update'| 'key' | 'ttl'

const SaveModalFieldComponent = ({
                                   field,
                                   register,
                                   control,
                                   models,
                                   report,
                                   reportState,
                                   getValues,
                                   loading, setValue
                                 }: Props) => {

  const {t} = useTranslate("common");

  const onChangeBy = ({values, sub_column, input}: { input: InputField, values: string[], sub_column?: string }) => {

    const name = input.isArray ? input.name.split(".")[0] : input.name as keyof Report;

    switch (name as keyof Report) {
      case 'filter':
        return JSON.stringify(values.map(value => ({table_name: value, label: value})));
      case 'custom_column':
        let prevValues = getValues(name) ? JSON.parse(getValues(name)) : []

        if (prevValues.length > 0) {
          prevValues = prevValues.filter((value: { type: string }) => value.type !== sub_column)
        }

        return JSON.stringify([...prevValues, ...values.map(item => ({column: item, type: sub_column}))]);
      default:
        return JSON.stringify(values);
    }
  };
  const [currentTab, setCurrentTab] = useState('')
  const getFieldLabel = (field: string, value: string | number) => {
    return {range: t(labelRange[value as number])}[field.split(".")[field.split(".").length - 1]]
  }

  const getReportObjectDefaultValue = (objectField: keyof Report, field: string) => {


    if (report && report.hasOwnProperty(objectField)) {
      let obj: any = report[objectField]

      if (obj && typeof obj === 'string' && IsJsonString(obj)) {
        obj = JSON.parse(obj)
      }
      if (obj && typeof obj === 'object' && obj.hasOwnProperty(field) && (obj[field] || typeof obj[field] === "number"|| typeof obj[field] === "boolean")) {
        // console.log("obj[field]", obj[field])
        return obj[field]
      }
    }

    return null
  }

  const getMetaOptions = ({option, modelName}: {
    option: MetaOptions, modelName?: string
  }) => {
    return {
      report_columns: reportState.columns.map(column => ({label: column, value: column})),
      models: [...models.map(column => ({label: column.name, value: column.name})), {label: "Ninguno", value: ""}],
      model: modelName ? models.find(model => model.name === modelName)?.properties?.map(property => (
        {label: property.name, value: property.name}
      )) : [],
      truncate: truncateArray.map(truncate => ({label: t(truncate.name), value: truncate.value})),
      time_format: formatDateOptions
    }[option]
  }

  const getMetaInput = ({
                          inputName,
                          type,
                          option, sub_type, isArray
                        }: { inputName: keyof Report, type: MetadataField, option?: MetaOptions, sub_type?: string, isArray?: boolean }) => {
    // const getMetaInput = (inputName: keyof Report, type: MetadataField) => {
    switch (type) {
      case "x_axis":
        return {
          name: `${inputName}.x_axis`,
          type: 'select',
          label: 'X axis',
          placeholder: 'X axis...',
          defaultValue: getReportObjectDefaultValue(inputName, type),
          // defaultValueSelect: report?. ? {label: report?.type, value: report?.type} : null,
          defaultValueSelect: getReportObjectDefaultValue(inputName, type) ? {
            label: getReportObjectDefaultValue(inputName, type),
            value: getReportObjectDefaultValue(inputName, type)
          } : null,
          options: reportState.columns.map(column => ({label: column, value: column}))
        }
      case "y_axis":
        return {
          name: `${inputName}.y_axis`,
          type: 'select',
          label: 'Y axis',
          defaultValue: getReportObjectDefaultValue(inputName, type),
          placeholder: 'Y axis...',
          defaultValueSelect: getReportObjectDefaultValue(inputName, type) ? {
            label: getReportObjectDefaultValue(inputName, type),
            value: getReportObjectDefaultValue(inputName, type)
          } : null,
          options: reportState.columns.map(column => ({label: column, value: column}))
        }
      case "split_by":
        return {
          name: `${inputName}.split_by`,
          type: 'select',
          label: 'Split By',
          defaultValue: getReportObjectDefaultValue(inputName, type),
          placeholder: 'Split By...',
          // defaultValueSelect: report?. ? {label: report?.type, value: report?.type} : null,
          defaultValueSelect: getReportObjectDefaultValue(inputName, type) ? {
            label: getReportObjectDefaultValue(inputName, type),
            value: getReportObjectDefaultValue(inputName, type)
          } : null,
          options: reportState.columns.map(column => ({label: column, value: column}))
        }
      case "total":
        return {
          name: `${inputName}.total`,
          type: 'boolean',
          label: 'Total',
          defaultValue: getReportObjectDefaultValue(inputName, type)
        }
      case "update":
        return {
          name: `${inputName}.update`,
          type: 'boolean',
          label: 'Update',
          defaultValue: getReportObjectDefaultValue(inputName, type)
        }
      case "ttl":
        return {
          name: `${inputName}.ttl`,
          type: 'number',
          label: 'Ttl',
          defaultValue: getReportObjectDefaultValue(inputName, type)
        }
      case "key":
        return {
          name: `${inputName}.key`,
          type: 'string',
          label: 'Key',
          defaultValue: getReportObjectDefaultValue(inputName, type)
        }
      default:
        return {
          name: `${inputName}.${type}`,
          type: sub_type ?? 'select',
          isArray,
          defaultValue: getReportObjectDefaultValue(inputName, type),
          placeholder: (type ?? "") + '...',
          isLoading: loading === State.PENDING,
          defaultValueSelect: getReportObjectDefaultValue(inputName, type) ? {
            label: getReportObjectDefaultValue(inputName, type),
            value: getReportObjectDefaultValue(inputName, type)
          } : null,
          options: getMetaOptions({
            option: (option === "model" && !getValues("truncate.model")) ? "report_columns" : (option ?? "report_columns"),
            modelName: getValues("truncate.model") ?? ""
          })
        }
    }
  }


  const getMultiSelectValue = ({input}: { input: InputField }) => {
    const name = input.isArray ? input.name.split(".")[0] : input.name as keyof Report;
    const sub_name = input.isArray ? input.name.split(".")[1] : ""

    let preValue: any[] = []

    if (report && report[name as keyof Report] && IsJsonString(report[name as keyof Report] as string)) {
      preValue = {
        custom_column: JSON.parse(report[name as keyof Report] as string).map((column: { type: string, column: string }) => ({
          label: column.column,
          value: column.column,
          type: column.type
        })).filter((evt: { type: string }) => evt.type === sub_name)
      }[name] ?? []
    }

    if (getValues(name) && IsJsonString(getValues(name))) {
      const arrayValues =  {
        filter: JSON.parse(getValues(name)).map((column: { table_name: string, label: string }) => ({
          label: column.table_name,
          value: column.table_name
        })),
        custom_column: JSON.parse(getValues(name)).map((column: { type: string, column: string }) => ({
          label: column.column,
          value: column.column,
          type: column.type
        })).filter((evt: { type: string }) => evt.type === sub_name)
      }[name] ?? JSON.parse(getValues(name)).map((item: StringOption) => ({label: item, value: item}))

      return [...arrayValues]
    }



    if (preValue.length === 0 && input.defaultValueSelect){
      return input.defaultValueSelect
    }

    return preValue
  }

  const getField = ({
                      isSubField = false,
                      input,
                      sub_column
                    }: { input: InputField, isSubField?: boolean, sub_column?: string }) => {


    const name = input.isArray ? input.name.split(".")[0] : input.name as keyof Report;

    const defaultField = <div
      className={`d-flex flex-column ${(isSubField || currentTab === "0") ? "" : "col-12 col-lg-6"}`} key={input.name}>
      {input.label && !isSubField && <label className=" fw-bold">{input.label}</label>}
      <input type={input.type ?? 'text'} defaultValue={input.defaultValue} placeholder={input.placeholder ?? ''}
             className="form-control" {...register(name as keyof Report, {required: input.required ?? false})}/>
    </div>

    // console.log("input", input)
    // console.log(getValues(input.name as keyof Report))

    const tab = {
      boolean: <div
        className={`d-flex align-items-center ${(isSubField || currentTab === "0") ? "" : "col-12 col-lg-2"}`}
        key={input.name}>
        {input.label && !isSubField && <label className="me-2 fw-bold">{input.label}</label>}
        <Controller
          control={control}
          name={name}
          defaultValue={input.defaultValue ?? false}
          rules={{required: input.required}}
          render={({field: {onChange, value}}) => (
            <Switch key={input.name} defaultChecked={input.defaultValue ?? false} checked={value} checkedChildren={t('common:yes')} unCheckedChildren="No"
                    onChange={evt => {
                      onChange(evt)
                    } }
            />
          )}
        />
      </div>,
      "multi-select": <div
        className={`d-flex flex-column ${(isSubField || currentTab === "0") ? "" : "col-12 col-lg-6"}`}
        key={input.name}>
        {input.label && !isSubField && <label className=" fw-bold">{input.label}</label>}
        <Controller
          control={control}
          name={name}
          defaultValue={input.defaultValue}
          rules={{required: input.required ?? false}}
          render={({field: {onChange}}) => (
            <CreatableSelectComponent id={name} name={'select_' + name} defaultValue={input.defaultValueSelect ? removeDuplicateFromArrayObj(input.defaultValueSelect as StringOption[], "value") : null}
                                      options={input.options ?? []} key={input.name} isMulti
                                      value={getMultiSelectValue({input})}
                                      placeholder={input.placeholder ?? ''} menuPosition={"fixed"}
                                      onChange={evt => {
                                        onChange(onChangeBy({
                                          input,
                                          sub_column,
                                          values: evt.map((event: StringOption) => event.value)
                                        }))
                                      }}
            />
          )}
        />
      </div>,

      select: <div className={`d-flex flex-column ${(isSubField || currentTab === "0") ? "" : "col-12 col-lg-6"}`}
                   key={input.name}>
        {input.label && !isSubField && <label className=" fw-bold">{input.label}</label>}
        <Controller
          control={control}
          name={name}
          defaultValue={input.defaultValue}
          rules={{required: input.required ?? false}}
          render={({field: {onChange}}) => (
            <CreatableSelectComponent id={name} name={'select_' + name} menuPosition={"fixed"}
                                      value={(getValues(input.name as keyof Report) || typeof getValues(input.name as keyof Report) === "number") ? {
                                        label: getFieldLabel(input.name, getValues(input.name as keyof Report) as string) ?? getValues(input.name as keyof Report),
                                        value: getValues(input.name as keyof Report)
                                      } : input.defaultValueSelect ? input.defaultValueSelect : null }
                                      loading={input.isLoading}
                                      disabled={input.isDisabled || input.isLoading}
                                      options={input.options ?? []} defaultValue={input.defaultValueSelect && (input.defaultValueSelect as StringOption)?.value ? input.defaultValueSelect : Array.isArray(input.defaultValueSelect) ? removeDuplicateFromArrayObj(input.defaultValueSelect as StringOption[], "value") : null}
                                      placeholder={input.placeholder ?? ''}
                                      onChange={evt => (evt.value || typeof evt.value === "number" || typeof evt.value === "boolean") && onChange(evt.value)}
            />
          )}
        />
      </div>,
      object: <div className={`d-flex flex-column ${currentTab === "0" ? "" : "col-12 col-lg-6"}`} key={input.name}>

        {input.label && !input.json && <label className=" fw-bold">{input.label}</label>}
        {(input.actionsList && input.actionsList.length > 0 ) && <div className="d-flex flex-column mb-2">
          <span>Actions:  </span>
          <MultiSelectComponent placeholder={"Actions..."} defaultValue={input.defaultValueSelect ? removeDuplicateFromArrayObj(input.defaultValueSelect as StringOption[], "value") : null}
                                menuPosition={"fixed"} onChange={input.setActions} disabled={input.isDisabled}
                                id={uuidV4()} name={"action" + input.name} options={input.actionsList}/>
        </div>}

        {(input.actions && input.actions.length > 0) && !isSubField && <span className="link-primary fw-bold underline pointer text-end" onClick={() => {
          if (!isSubField){
            setValue(name, null)
          }
        }}>{t("delete_all")}</span>}
        <CustomTableComponent useLocalPage actionsColumnClass={"flex-grow-1"} columns={[
          {name: "label", header: "Name"},
        ]} data={input.actions ?? []} actions={[{
          custom: input.sub_field, customComponent: column => {
            if (column.value && !!getMetaInput({inputName: input.name as keyof Report, type: column.value})) {
              return getField({
                input: getMetaInput({
                  inputName: input.name as keyof Report,
                  type: column.value,
                  isArray: input.isArray,
                  sub_type: column.sub_type,
                  option: column.options
                }) as InputField, isSubField: true, sub_column: column.value
              })
            }
            return <div/>
          }
        }]}/>
      </div>
    }[input.type as string] as (JSX.Element | undefined) ?? defaultField


    if (input.json) {

      const defaultReportValue = getReportObjectDefaultValue(input.name as keyof Report, input.json)
      // console.log(defaultReportValue)

      const defaultValue = defaultReportValue ? typeof defaultReportValue === "object" ? JSON.stringify(
        defaultReportValue,
        null,
        '\t'
      ) : IsJsonString(defaultReportValue as string) ? JSON.stringify(
        JSON.parse(defaultReportValue as string),
        null,
        '\t'
      ) : null : null

      const value = getValues(`${name}.${input.json}`)

      const tabs: Tab[] = [
        {label: 'Truncate', component: tab},
        {
          label: input.jsonLabel, component:
            <Controller
              control={control}
              name={`${name}.${input.json}`}
              defaultValue={defaultValue || "{}"}
              rules={{required: input.required}}
              render={({field: {onChange}}) => (
                <EditorComponent
                  enableSnippets
                  showActions
                  value={value || ""}
                  onChange={onChange}
                />
              )}
            />

        },
      ]

      return <div className={"col-12 col-lg-6"}>
        {input.label && <label className=" fw-bold">{input.label}</label>}
        <TabContents
          reloadInfo
          getCurrentTab={setCurrentTab}
          tabs={tabs}/>
      </div>
    }

    return tab;
  };


  return getField({input: field})
};

export default SaveModalFieldComponent