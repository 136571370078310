import * as React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCog} from "@fortawesome/free-solid-svg-icons";


const BuildingFeatureComponent = () => {
    return (
        <div className="d-flex justify-content-center align-items-center">
            <span className="font-size-xl fw-bold ">We are working on this feature...</span> {" "}
            <FontAwesomeIcon icon={faCog} pulse size={"2x"}/>
        </div>
    );
};

export default BuildingFeatureComponent