import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {State} from "../types/State";

export enum CustomState {
  CONFIG_MANAGER_TABLE_STATE = "CONFIG_MANAGER_TABLE_STATE",
  CONFIG_BUILDER_MODAL_CONFIRM = "CONFIG_BUILDER_MODAL_CONFIRM",
}
interface IState {
  state: State;
  customState: Record<CustomState, State>;
}

const initialState: IState = {
  state: State.IDLE,
  customState: Object.values(CustomState).reduce((acc, key) => ({ ...acc, [key]: State.IDLE }), {}) as IState['customState'],
}

const slice = createSlice({
  name: "globalStateReducer",
  initialState: initialState,
  reducers: {
    changeActive(state, action: PayloadAction<State>) {
      state.state = action.payload;
    },
    changeCustomState(state, { payload: { type, status } }: PayloadAction<{ type: CustomState, status: State }>) {
      state.customState[type] = status;
    },
  }
});

export const stateActions = slice.actions;
export default slice.reducer;
