import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {State} from "../../types/State";
import {ModelsResponse, SbxModelField} from "../../types/Sbx";
import {AnalyticQuery, BaseAnalyticQuery, Source, SourceFrom} from "../../types/Analytic";
import {uuidV4} from "../../utils";

export enum ReportState  {
    PENDING_FETCHING_MODELS = "PENDING_FETCHING_MODELS",
    RESOLVED_FETCHING_MODELS = "RESOLVED_FETCHING_MODELS",
    PENDING_FETCHING_COLUMNS = "PENDING_FETCHING_COLUMNS",
    RESOLVED_FETCHING_COLUMNS = "RESOLVED_FETCHING_COLUMNS",
}




type InitialState = {
    state: (State | ReportState)[];
    model?: ModelsResponse
    results: any[]
    baseQuery: BaseAnalyticQuery
    fetchOptions: string[]
    fetch: string[]
    deepFetch: { [key: string]: ModelsResponse}
    models: ModelsResponse[]
    columns: string[][]
    columnsType:  { [key: string]: Partial<SbxModelField> }[]
    baseColumns: string[]
    showResults: boolean,
    isMergeAction: boolean,
    loadingActions: number[]
    mainReportQuery?: AnalyticQuery
}

export let baseQuery: Source = {
    action: 'get',
    with: '',
    merge_type: 'inner',
    in: 0,
    from: SourceFrom.SBX_DATA,
    filters: []
}


export const initialState: InitialState = {
    state: [State.IDLE],
    results: [],
    fetch: [],
    fetchOptions: [],
    showResults: false,
    columnsType: [],
    columns: [],
    baseColumns: [],
    isMergeAction: false,
    deepFetch: {},
    loadingActions: [],
    models: [],
    baseQuery: {
        source: {...baseQuery, temporal_id: uuidV4(), filters: []},
        actions: [],
        preview: true
    }
}

const slice = createSlice({
    name: "report",
    initialState,
    reducers: {
        setModel(state, action: PayloadAction<ModelsResponse>) {
            state.model = {
                ...action.payload,
                properties: [...action.payload.properties].sort((a, b) => a.name.localeCompare(b.name))
            }
        },
        setModels(state, action: PayloadAction<ModelsResponse[]>) {
            state.models = action.payload;
        },
        setBaseQuery(state, action: PayloadAction<BaseAnalyticQuery>) {
            state.baseQuery = {...action.payload};
        },
        setResults(state, action: PayloadAction<any[]>) {
            state.results = action.payload;
        },
        setShowResults(state, action: PayloadAction<boolean>) {
            state.showResults = action.payload;
        },
        setColumns(state, action: PayloadAction<string[][]>) {
            if (action.payload){
                state.columns = [...action.payload].map(subColumns => {
                    return [...subColumns].sort((a, b) => a.localeCompare(b))
                });
            }
        },
        setColumnsByIndex(state, action: PayloadAction<{
            columns: string[][],
            index: number,
            types: { [key: string]: Partial<SbxModelField> }[]
        }>) {
            if (action.payload) {
                const newColumns = [...action.payload.columns].map(subColumns => {
                    return [...subColumns].sort((a, b) => a.localeCompare(b))
                });

                const index = action.payload.index;

                newColumns.slice(index).forEach((columns, index) => {
                    if (state.columns[index]) {
                        state.columns[index] = columns;
                    }
                })

                state.columnsType = action.payload.types
            }
        },
        setColumnsType(state, action: PayloadAction<{ [key: string]: Partial<SbxModelField> }[]>) {
            state.columnsType = action.payload;
        },
        setFetch(state, action: PayloadAction<string[]>) {
            state.fetch = action.payload;
        },
        setFetchOptions(state, action: PayloadAction<string[]>) {
            state.fetchOptions = action.payload;
        },
        setDeepFetch(state, action: PayloadAction<{ [key: string]: ModelsResponse }>) {
            state.deepFetch = action.payload;
        },
        setBaseColumns(state, action: PayloadAction<string[]>) {
            state.baseColumns = [...action.payload].sort((a, b) => a.localeCompare(b));
        },
        setState(state, action: PayloadAction<{
            addState?: State | ReportState,
            removeState?: State | ReportState
        }>) {
            if (action.payload.addState) {
                state.state = [...state.state, action.payload.addState];
            }
            if (action.payload.removeState) {
                state.state = state.state.filter(state => state !== action.payload.removeState);
            }
        },
        setAddLoadingActionIndex(state, action: PayloadAction<{
            index: number
        }>) {
            if (state.loadingActions) {
                state.loadingActions = [...state.loadingActions, action.payload.index]
            }
        },
        setRemoveLoadingActionIndex(state, action: PayloadAction<{
            index: number
        }>) {
            if (state.loadingActions) {
                state.loadingActions = state.loadingActions.filter(index => index !== action.payload.index)
            }
        },
        resetState: () => {
            return {...initialState}
        }
    }
})


export const actionsReport = slice.actions;
export default slice.reducer;
