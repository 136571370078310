import React, {useReducer} from 'react';
import SelectComponent from '../FieldComponents/SelectComponent';
import {
  capitalize,
  convertDateToNumberDate,
  getReportQuery,
  queryAddSourceRangeFilter,
  removeDuplicateFromArrayObj,
  uuidV4
} from '../../../utils';
import useTranslate from '../../../hooks/useTranslate';
//@ts-ignore
import ReactExport from 'react-data-export';
import {Button} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';
import {State} from '../../../types/State';
import DateRangeComponent from "../FieldComponents/DateRangeComponent";
import useDeviceDetect from "../../../hooks/useDeviceDetect";
import {BaseAnalyticQuery, FilterReport, Report} from "../../../types/Analytic";
import RenderMonthElement from "../RenderMonthElement";
import {MultiReport} from "../../analytics/AnalyticsPageComponent";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export interface FilterTableReport {
    label: string,
    table_name?: string
    table_name_value?: string
    sort?: 'asc' | 'desc'
    default_value?: string;
    defaultSelectValue?: { value: any, label: string }
    isCustom?: boolean
    value?: { value: any, label: string } | null
    onChange?: (evt: { value: any, label: string }) => void;
    options?: { value: string | number, label: string }[]
    sortOptions?: boolean
    type?: 'date_range'
    start_date?: string,
    end_date?: string
    field?: string
    hide?: boolean
}

type Props = {
    filterList: FilterTableReport[]
    data: any[]
    excelData?: any[]
    isLoading: boolean;
    disabled?: boolean
    isMultiData?: boolean;
    exportExcel?: boolean;
    setLoading?: (state: State) => void;
    excelHeaders?: { value: string, label: string }[]
    onClear?: () => void
    report?: Report
    query?: BaseAnalyticQuery
    setData: (data: any, filters?: { [filter: string]: Option }) => void
    launchQuery?: (query: Report) => void
}

enum Types {
    SET_STATE,
    SET_MULTI_STATE
}

interface Option {
    label: string;
    value: string | number;
}

interface InitialReducerState {
    filters: { [filter: string]: Option };
    dateRange: { [filter: string]: { startDate: null | Date, endDate: null | Date } };
    filterOptions: { [filter: string]: Option[] };
    baseQuery?: BaseAnalyticQuery
}

const initialState: InitialReducerState = {
    filters: {},
    dateRange: {},
    filterOptions: {}
};

function reducer(state: InitialReducerState, {
    type,
    payload
}: { type: Types, payload: { name: string, value?: any } | { name: string, value: any }[] }) {
    switch (type) {
        case Types.SET_STATE:
            return {
                ...state,
                [(payload as { name: string, value: any }).name]: (payload as { name: string, value: any }).value
            };
        case Types.SET_MULTI_STATE:
            (payload as { name: keyof InitialReducerState, value: any }[]).forEach(data => {
                state = {...state, [data.name]: data.value};
            });
            return {...state};
        default:
            throw new Error();
    }
}


const FilterTableDataComponent = ({
                                      filterList,
                                      data,
                                      excelData,
                                      isLoading,
                                      disabled,
                                      setData,
                                      exportExcel,
                                      excelHeaders,
                                      query,
                                      report,
                                      launchQuery,
                                      isMultiData, setLoading, onClear
                                  }: Props) => {

    const {t} = useTranslate('common');
    const [stateLocal, dispatchLocal] = useReducer(reducer, initialState);
    const dispatchForm = ({name, value}: { name: keyof InitialReducerState, value: any }) => {
        dispatchLocal({type: Types.SET_STATE, payload: {value, name}});
    };
    const {isMobile} = useDeviceDetect();
    React.useEffect(() => {

        const objFilterOptions: { [filter: string]: { label: string, value: string | number }[] } = {};

        const getItemFilterOption = (item: { [key: string]: any }) => {
            filterList.forEach(filter => {
                if (filter.table_name && item[filter.table_name]) {

                    if (filter.table_name_value && item[filter.table_name][filter.table_name_value]) {
                        objFilterOptions[filter.table_name] ||= [];
                        objFilterOptions[filter.table_name].push({
                            label: item[filter.table_name][filter.table_name_value].toString(),
                            value: item[filter.table_name][filter.table_name_value]
                        });
                    } else {
                        objFilterOptions[filter.table_name] ||= [];
                        objFilterOptions[filter.table_name].push({
                            label: item[filter.table_name].toString(),
                            value: item[filter.table_name]
                        });
                    }


                }
            });
        };


        if (data && data.length > 0 && filterList.length > 0) {

            if (isMultiData) {
                data.forEach(report => {
                    report.data.forEach((item: any) => {
                        getItemFilterOption(item);
                    });
                });
            } else {
                data.forEach(item => {
                    getItemFilterOption(item);
                });
            }


            Object.keys(objFilterOptions).forEach(filter => {
                objFilterOptions[filter] = removeDuplicateFromArrayObj(objFilterOptions[filter], 'value');

                const filterInfo = filterList.find(nFilter => nFilter.table_name === filter);
                if (filterInfo && filterInfo.sort) {
                    objFilterOptions[filter] = sortOptions(objFilterOptions[filter], filterInfo.sort);
                }

            });


            dispatchForm({name: 'filterOptions', value: objFilterOptions});
        }


    }, [data]);

    React.useEffect(() => {
        if (!stateLocal.baseQuery && query) {
            dispatchForm({name: "baseQuery", value: query})
        }
    }, [query]);


    React.useEffect(() => {

        const setFilters = async () => {
            if (Object.keys(stateLocal.filters).length > 0) {
                let newData = [];

                const isFilterItem = (item: { [key: string]: any }) => {
                    return Object.keys(stateLocal.filters).every(filter => {
                        if (!!item[filter] && typeof item[filter] === 'object' && item[filter]['_KEY']) {
                            return stateLocal.filters[filter].value === item[filter]['_KEY'];
                        } else {

                            const deepTableValue = filterList.find(nFilter => nFilter.table_name === filter)?.table_name_value
                            if (deepTableValue && item[filter][deepTableValue]) {
                                return stateLocal.filters[filter].value === item[filter][deepTableValue];
                            }

                            return stateLocal.filters[filter].value === item[filter];
                        }
                    });
                };

                if (isMultiData) {
                    if (setLoading) {
                        setLoading(State.PENDING);
                    }
                    for (const dataF of data) {
                        if (dataF.handleQuery && stateLocal.filters) {
                            const report = await getReportQuery({
                                report: dataF,
                                filtersToAdd: Object.keys(stateLocal.filters),
                                parentFilters: filterList
                            });

                            if (report) {
                                newData.push({...dataF, data: report.data});
                            } else {
                                newData.push(dataF);
                            }
                        } else {
                            newData.push(dataF);
                        }
                    }

                    newData = newData.map((report: MultiReport) => {
                        report = {...report, data: report.data.filter(item => isFilterItem(item))};
                        return report;
                    });
                    if (setLoading) {
                        setLoading(State.RESOLVED);
                    }
                } else {
                    newData = data.reduce((arr, item) => {
                        if (isFilterItem(item)) {
                            arr.push(item);
                        }
                        return arr;
                    }, []);
                }


                setData(newData, stateLocal.filters);
            } else {
                setData(data);
            }
        };

        setFilters();

    }, [stateLocal.filters, data]);

    const sortOptions = (options: Option[], sort?: 'asc' | 'desc') => {
        if (options && options.length > 0) {
            options = options.map(option => ({label: capitalize(option.label), value: option.value}));
            if (typeof options[0].value === 'number') {
                const nSort = (a: { value: string | number }, b: {
                    value: string | number
                }) => (!sort || sort === 'asc') ? (a.value as number) - (b.value as number) : (b.value as number) - (a.value as number);
                return options.sort(nSort);
            } else {
                const nSort = (a: { value: string | number }, b: {
                    value: string | number
                }) => (!sort || sort === 'asc') ? (a.value as string).localeCompare(b.value as string) : (b.value as string).localeCompare(a.value as string);
                return options.sort(nSort);
            }
        } else {
            return [];
        }
    };

    React.useEffect(() => {

        if (filterList.length > 0 && Object.keys(stateLocal.filterOptions).length > 0) {
            let filters: { [x: string]: string | Option; } = {};

            filterList.forEach(filter => {
                if (filter.table_name && filter.default_value) {
                    const firstValue = stateLocal.filterOptions[filter.table_name][0];
                    if (firstValue) {
                        filters = {...filters, [filter.table_name]: firstValue};
                    }
                }
            });

            dispatchForm({
                name: 'filters',
                value: {...stateLocal.filters, ...filters}
            });
        }

    }, [stateLocal.filterOptions]);


    const getFilter = (filter: FilterTableReport) => {
        let item: JSX.Element | null = null

        if (filter.isCustom) {
            item = <SelectComponent id={uuidV4()} sortOptions={filter.sortOptions} name={filter.label}
                                    disabled={isLoading || disabled} loading={isLoading} value={filter.value}
                                    onChange={filter.onChange} defaultValue={filter.defaultSelectValue ?? null}
                                    options={filter.options ?? []} placeholder={filter.label}/>
        } else {
            if (filter.table_name || filter.type) {
                const filterTypes: { [key: string]: JSX.Element } = {
                    date_range: filter.field ?
                        <DateRangeComponent renderMonthElement={RenderMonthElement} id={uuidV4()} block={false} value={{
                            startDate: stateLocal.dateRange[filter.field]?.startDate ?? null,
                            endDate: stateLocal.dateRange[filter.field]?.endDate ?? null
                        }} isOutsideRange={() => false}
                                            onChange={date => {
                                                dispatchForm({
                                                    name: 'dateRange',
                                                    value: {
                                                        ...stateLocal.dateRange,
                                                        [filter.field as string]: date
                                                    }
                                                })


                                                // stateLocal.report ? IsJsonString(stateLocal.report.query) ? JSON.parse(stateLocal.report.query) : undefined : undefined
                                                if (date.startDate && date.endDate && launchQuery && query && report) {
                                                    launchQuery({
                                                        ...report,
                                                        query: JSON.stringify(queryAddSourceRangeFilter({
                                                            rangeField: {
                                                                ...(filter as FilterReport),
                                                                end_date: (filter as FilterReport).date_type === "numberDate" ? convertDateToNumberDate(date.endDate) : date.endDate.toISOString(),
                                                                start_date: (filter as FilterReport).date_type === "numberDate" ? convertDateToNumberDate(date.startDate) : date.startDate.toISOString(),
                                                            },
                                                            query: stateLocal.baseQuery ? {...stateLocal.baseQuery} : {...query}
                                                        }))
                                                    })
                                                }
                                            }} orientation={isMobile ? 'vertical' : 'horizontal'}/> :
                        <div/>,
                    default: filter.table_name ?
                        <SelectComponent id={filter.table_name} value={stateLocal.filters[filter.table_name] ?? null}
                                         disabled={isLoading || disabled} loading={isLoading}
                                         onChange={evt => dispatchForm({
                                             name: 'filters',
                                             value: {...stateLocal.filters, [filter.table_name as string]: evt}
                                         })}
                                         sortOptions
                                         name={filter.table_name}
                                         options={stateLocal?.filterOptions[filter.table_name] ?? []}
                                         placeholder={filter.label}/> : <div/>
                }

                item = filter.type ? filterTypes[filter.type] : filterTypes["default"]


            }
        }

        return <div key={uuidV4()} className={`col-12 col-lg-3 col-xxl-${filter.type ? "4" : "2"}`}>
            {item}
        </div>
    }

    return (
        <div>
            <div className="d-flex flex-column">
                <div className="d-flex align-items-center justify-content-between">
                    {filterList.length > 0 && <span>{t('FILTER_BY')}: </span>}
                    {(Object.keys(stateLocal.filters).length > 0 || filterList.some(filter => filter.isCustom && filter.value)) && !isLoading &&
                        <u className="pointer text-primary"
                           onClick={() => {
                               dispatchForm({
                                   name: 'filters',
                                   value: {}
                               })

                               if (onClear) {
                                   onClear()
                               }
                           }}>{t('clear')}</u>}
                </div>

                <div
                    className={`d-flex flex-wrap gap-2 my-2 align-items-center ${filterList.length === 0 ? "justify-content-end" : ""}`}>

                    {filterList.filter(filter => !filter.hide && filter.table_name && stateLocal?.filterOptions[filter.table_name] && stateLocal?.filterOptions[filter.table_name]!.length > 0).map(filter => (getFilter(filter)))}

                    {exportExcel && excelHeaders && excelHeaders.length > 0 && data.length > 0 &&
                        <div className="">
                            <ExcelFile element={<Button color={'secondary'} disabled={isLoading}
                                                        className={'me-2'}>{isLoading &&
                                <FontAwesomeIcon icon={faSpinner} className="me-1" pulse/>}Export to excel</Button>}>
                                <ExcelSheet data={excelData} name="data">
                                    {excelHeaders.map((header, index) => (
                                        <ExcelColumn label={header.label} value={header.value} key={index}/>
                                    ))}
                                </ExcelSheet>
                            </ExcelFile>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default FilterTableDataComponent;
