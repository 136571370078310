import * as React from 'react';
import {useContext, useEffect, useRef, useState} from 'react';
import {
    AnalyticQuery,
    AnalyticQueryAction,
    merge_type,
    TimeWindowAction,
    TruncateReport
} from "../../../types/Analytic";
import ModalComponent from "../../Shared/Modal/ModalComponent/ModalComponent";
import NewReportGeneratorComponent, {ActionButton, ReportContext} from "../NewReportGeneratorComponent";
import {DropDownSimple} from "../../LayoutComponenents/DropDownComponent";
import {DropdownItem} from "reactstrap";
import useTranslate from "../../../hooks/useTranslate";
import innerJoin from "../../../public/assets/icons/inner_join.svg"
import leftJoin from "../../../public/assets/icons/left-join.svg"
import rightJoin from "../../../public/assets/icons/right-join.svg"
import outerJoin from "../../../public/assets/icons/outer_join.svg"
import Image from "next/image";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDatabase} from "@fortawesome/free-solid-svg-icons";
import {Switch} from "antd";
import styled from "styled-components";
import ActionDropdownColumns from "./ActionDropdownComponent/ActionDropdownColumns";

type Props = {
    action: AnalyticQueryAction
    index: number
    color?: string
}

const getMergeTypeImage = () => {
    return {
        inner: innerJoin,
        left: leftJoin,
        right: rightJoin,
        outer: outerJoin,
    } as { [key: string]: string }
}

const joinList = [
    {label: "Inner Join", value: "inner"},
    {label: "Left Join", value: "left"},
    {label: "Right Join", value: "right"},
    {label: "Outer Join", value: "outer"}
]

const ContainerInputs = styled.div`
  display: grid;
  gap: 1em;
  grid-template-columns: auto 1fr;

  @media (max-width: 968px) {
    grid-template-columns: 1fr;
  }
`

const EventInputs = styled.div`
  display: grid;
  grid-template-columns: 8em repeat(3, auto) 6em;
  gap: 5px;
  align-items: center;
  justify-content: start;

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`

const SecondInput = styled.input`
  min-height: 27px !important;
  height: 27px;

`

const NewMergeActionComponent = ({action, index, color}: Props) => {
    const [openModal, setOpenModel] = useState(false);
    const toggle = () => setOpenModel(prevState => !prevState);
    const [indexColumns, setIndexColumns] = useState<string[]>([])
    const {setQueryAction, getActionColumns, getColumns, query} = useContext(ReportContext)
    const {t} = useTranslate("report")
    const [mergeAction, setMergeAction] = useState<AnalyticQueryAction | null>(null)
    const onMount = useRef(false)


    useEffect(() => {
        const getIndexColumn =async () => {
            if (action.source?.with && action.main_column?.length && action.index_column?.length && indexColumns.length === 0 ) {
                onMount.current = true

                const columns = await getColumns({
                    source: action.source,
                    actions: action.actions ?? []
                } as AnalyticQuery)
                setIndexColumns(columns?.items!?.length > 0 ? (columns?.items as string[]) : columns?.history[0] as string[] ?? [])
            }
        }

        if (!onMount.current){
            getIndexColumn()
        }

    }, [action, indexColumns]);


    React.useEffect(() => {
        if (!action.subtype || action.subtype !== 'concat') {
            setMergeAction({
                ...action,
            })
        }
    }, [action]);



    const isMergeConcat = action.subtype === 'concat'

    return (
        <div>
            {action.source?.with!?.length > 0 ?<div className="d-flex flex-wrap align-items-center gap-1">
                <div className="position-relative form-check">
                    <input
                        name="check"
                        id="exampleCheck"
                        type="checkbox"
                        checked={isMergeConcat}
                        onChange={evt => {

                            if (evt.target.checked) {

                                const newAction = {
                                    ...action, subtype: 'concat',
                                    index_column: "",
                                    main_column: "",
                                    merge_type: "",
                                    drop_duplicates: false
                                }

                                if (newAction.time_window) {
                                    delete newAction.time_window
                                }
                                setQueryAction({
                                    ...newAction as AnalyticQueryAction
                                }, index)
                            } else {

                                if (mergeAction) {
                                    setQueryAction({
                                        ...mergeAction as AnalyticQueryAction
                                    }, index)
                                }else {
                                    const newAction = {...action}
                                    delete newAction.subtype
                                    setQueryAction({
                                        ...newAction
                                    }, index)
                                }

                            }


                        }}
                        className="form-check-input"
                    />
                    <label htmlFor="exampleCheck" className="form-label form-check-label mb-0">
                        {t("concat")}
                    </label>
                </div>
            </div> : null}
            <ContainerInputs>


                <div className="d-flex flex-wrap align-items-center gap-1">

                    {action.source?.with!?.length > 0 && action.source?.with ? <>
                        <div className="d-flex align-items-center gap-2">


                            <ActionButton disabled className="btn" color={color ?? "white"} onClick={() => {
                                toggle()
                            }}>
                                {t("current_report")}
                                <FontAwesomeIcon icon={faDatabase} className="pointer white"/>
                            </ActionButton>

                            {!isMergeConcat && <ActionDropdownColumns
                                disabled={action.subtype === 'concat'}
                                onChange={(value) => {
                                    setQueryAction({
                                        ...action,
                                        main_column: value
                                    }, index)
                                }} columns={getActionColumns(action, index).map(column => column.label)} showType
                                index={index}
                                color={color}
                                label={(action.main_column!?.length > 0 ? action.main_column : t("common:column") ?? t("common:column")) ?? ""}/>}

                        </div>

                        <div className="pointer">

                            <DropDownSimple outline color={"transparent"}
                                            disabled={isMergeConcat}
                                            headerToggle={<Image
                                                priority
                                                src={getMergeTypeImage()[action.merge_type as string] ?? innerJoin}
                                                height={32}
                                                width={32}
                                                alt="Follow us on Twitter"
                                            />}>

                                {joinList.map(join => (
                                    <DropdownItem className="d-flex align-items-center gap-2" onClick={() => {
                                        const nAction = {
                                            ...action,
                                            merge_type: join.value as merge_type
                                        }

                                        if (["left", "right"].includes(join.value)) {
                                            nAction.drop_duplicates = true
                                        }

                                        setQueryAction(nAction, index)
                                    }} key={join.value}>
                                        <Image
                                            priority
                                            src={getMergeTypeImage()[join.value as string] ?? innerJoin}
                                            height={32}
                                            width={32}
                                            alt="Follow us on Twitter"
                                        />

                                        {join.label}
                                    </DropdownItem>
                                ))}
                            </DropDownSimple>


                        </div>
                    </> : null}

                    <div className="d-flex align-items-center gap-2">
                        <ActionButton className="btn" color={color ?? "white"} onClick={() => {
                            toggle()
                        }}>
                            <FontAwesomeIcon icon={faDatabase} className="pointer white"/>
                            {action.source?.with!?.length > 0 ? action.source?.with ?? t("pick_your_table") : t("pick_your_table")}
                        </ActionButton>

                        {action.source?.with && !isMergeConcat && <>
                            <ActionDropdownColumns
                            disabled={action.subtype === 'concat'}
                                onChange={(value) => {
                                setQueryAction({
                                    ...action,
                                    index_column: value
                                }, index)
                            }} columns={indexColumns} showType index={index} color={color}
                                                   label={(action.index_column!?.length > 0 ? action.index_column
                                                       : t("common:column") ?? t("common:column")) ?? ""}/>
                        </>
                        }
                    </div>

                    {action.merge_type && !isMergeConcat && ["left", "right"].includes(action.merge_type) &&
                        <div className="d-flex align-items-center gap-1">
                            <label className="">{t("drop_duplicate")}</label>
                            <Switch
                                checkedChildren={t('common:yes')}
                                unCheckedChildren="No"
                                checked={action.drop_duplicates}
                                onChange={checked => {
                                    setQueryAction({
                                        ...action,
                                        drop_duplicates: checked
                                    }, index)
                                }}/>
                        </div>}
                </div>






                {action.source?.with && !isMergeConcat ?
                    <EventInputs>
                        <div className="d-flex align-items-center gap-1">
                            <label className="">{t("event")}</label>
                            <Switch
                                disabled={action.subtype === 'concat'}
                                checkedChildren={t('common:yes')}
                                unCheckedChildren="No"
                                checked={!!(action.time_window as TimeWindowAction)}
                                onChange={checked => {

                                    const nAction = {...action}

                                    if (checked) {
                                        nAction.time_window = {
                                            main_column: "",
                                            index_column: "",
                                            type: "before",
                                            time: 0
                                        }
                                    } else {
                                        delete nAction.time_window
                                    }

                                    setQueryAction({
                                        ...nAction
                                    }, index)
                                }}/>
                        </div>

                        {!!(action.time_window as TimeWindowAction) && <>


                            <ActionDropdownColumns onChange={
                                (value) => {
                                    if (action.time_window as TimeWindowAction) {
                                        setQueryAction({
                                            ...action,
                                            time_window: {
                                                ...action.time_window as TimeWindowAction,
                                                main_column: value
                                            }
                                        }, index)
                                    }
                                }
                            } columns={getActionColumns(action, index).map(column => column.label)} showType index={index}
                                                   color={color}
                                                   label={((action?.time_window as TimeWindowAction)?.main_column!?.length > 0 ? (action.time_window as TimeWindowAction).main_column : t("main-column") ?? t("main-column")) ?? ""}/>

                            <ActionDropdownColumns onChange={
                                (value) => {
                                    if (action.time_window as TimeWindowAction) {
                                        setQueryAction({
                                            ...action,
                                            time_window: {
                                                ...action.time_window as TimeWindowAction,
                                                type: value
                                            }
                                        }, index)
                                    }
                                }
                            } columns={['before', 'after', 'around']} index={index} color={color}
                                                   label={(action?.time_window as TimeWindowAction)?.type ? t((action.time_window as TimeWindowAction).type) : t("common:type")}/>


                            <ActionDropdownColumns onChange={
                                (value) => {
                                    if (action.time_window as TimeWindowAction) {
                                        setQueryAction({
                                            ...action,
                                            time_window: {
                                                ...action.time_window as TimeWindowAction,
                                                index_column: value
                                            }
                                        }, index)
                                    }
                                }
                            } columns={indexColumns} showType index={index} color={color}
                                                   label={((action?.time_window as TimeWindowAction)?.index_column!?.length > 0 ? (action.time_window as TimeWindowAction).index_column : t("index-column") ?? t("index-column")) ?? ""}/>


                            <SecondInput type="number" defaultValue={(action.time_window as TimeWindowAction).time || 0} style={{
                                minHeight: '27px', height: '27px'
                            }} placeholder={t("seconds")} className="form-control" onChange={evt => {
                                if (action.time_window as TimeWindowAction) {
                                    setQueryAction({
                                        ...action,
                                        time_window: {
                                            ...action.time_window as TimeWindowAction,
                                            time: parseInt(evt.target.value ?? "0")
                                        }
                                    }, index)
                                }
                            }}/>
                        </>}

                    </EventInputs>
                    : null}

                <ModalComponent noFooter isOpen={openModal} toggle={toggle} title={'Merge'} size={'auto'}>
                    <NewReportGeneratorComponent
                        isMergeAction
                        mainReportQuery={query}
                        reportQuery={{
                            source: action.source,
                            actions: (action.actions ?? [])?.map(action => ([action])),
                            preview: true,
                            truncate: action.truncate ?? ""
                        } as AnalyticQuery}
                        saveQuery={async savedQuery => {
                            toggle()

                            let params: AnalyticQuery = {
                                source: {...savedQuery.source},
                                actions: [...savedQuery.actions],
                            }


                            if (((savedQuery.truncate as TruncateReport)?.model || (savedQuery.truncate as TruncateReport)?.field)) {


                                params = {
                                    ...params,
                                    truncate: savedQuery.truncate
                                }
                            }else {
                                params = {
                                    ...params,
                                    truncate: ""
                                }
                            }

                            setQueryAction({
                                ...action,
                                ...params
                            }, index)





                            const columns = await getColumns(savedQuery)
                            setIndexColumns(columns?.items!?.length > 0 ? (columns?.items as string[]) : columns?.history[0] as string[] ?? [])


                        }}/>
                </ModalComponent>
            </ContainerInputs>
        </div>

    );
};

export default NewMergeActionComponent