import {FocusEventHandler} from "react";
import {Editor, Monaco} from "@monaco-editor/react";


interface Props {
  value: string;
  onChange: (value: string | undefined, ev: any) => void;
  required?: boolean;
  readonly?: boolean;
  disabled?: boolean;
  className?: string;
  defaultValue?: string;
  onBlur?: FocusEventHandler<any>
  id?: string;
  variables?: { label: string, text: string, description: string }[]
}

const otherSuggestions = (e: Monaco) => [
  {
    label: 'if',
    kind: e.languages.CompletionItemKind.Struct,
    insertText: 'if() {}',
    description: 'if()...',
    insertTextRules: e.languages.CompletionItemInsertTextRule.InsertAsSnippet,
  }, {
    label: 'ifelse',
    kind: e.languages.CompletionItemKind.Struct,
    insertText: 'if () {\n' +
      '} else {\n}',
    description: 'if() else...',
    insertTextRules: e.languages.CompletionItemInsertTextRule.InsertAsSnippet,
  }
];
const CodeEditorComponent = ({
                               value,
                               onChange,
                               readonly,
                               className,
                               defaultValue,
                               variables = []
                             }: Props) => {
  function startMonaco(e: Monaco) {
    e.languages.registerCompletionItemProvider("javascript", {
      provideCompletionItems: (model, position) => {
        const wordBeforePosition = model.getWordUntilPosition({
          lineNumber: position.lineNumber,
          column: position.column - 1,
        });

        const wordUntilPosition = model.getWordUntilPosition(position);
        if (wordBeforePosition.word.trim() === '' || wordUntilPosition.word.trim() === '') {
          const keywords = otherSuggestions(e).concat([...variables.map(variable => {
            return {
              label: "__" + variable.label,
              kind: e.languages.CompletionItemKind.Variable,
              insertText: variable.text,
              description: variable.description,
              insertTextRules: e.languages.CompletionItemInsertTextRule.InsertAsSnippet,
            }
          })]);

          const suggestions = keywords.map(id => ({
            label: id.label,
            kind: id.kind,
            description: id.description,
            documentation: id.description,
            insertText: id.insertText,
            detail: id.description,
            insertTextRules: id.insertTextRules,
            range: {
              startLineNumber: position.lineNumber,
              startColumn: wordUntilPosition.startColumn,
              endLineNumber: position.lineNumber,
              endColumn: wordUntilPosition.endColumn - 1,
            },
          }));
          return {suggestions};
        }
      },
    })

  }

  return (
    <Editor className={className}
            height="20vh"
            options={{readOnly: readonly}}
            beforeMount={startMonaco}
            defaultLanguage="javascript"
            value={value}
            onChange={onChange}
            defaultValue={defaultValue}/>
  )
}

export default CodeEditorComponent;