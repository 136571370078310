import moment from 'moment';
import React from 'react';
import {capitalize, iterator} from "../../utils";

interface Iprops {
    month: moment.Moment;
    onMonthSelect: (month: moment.Moment, data: string) => void;
    onYearSelect: (month: moment.Moment, data: string) => void;
}

const years = iterator(1950, 2099);


const RenderMonthElement = ({month, onMonthSelect, onYearSelect}: Iprops) => {

    // const {languages} = useTranslate('report');
    //
    // console.log(languages)
    return (
        <div  style={{display: 'flex', justifyContent: 'center'}}>
            <select
                className="form-select form-select-sm"
                style={{width: '8em'}}
                value={month.month()}
                onChange={(e) => onMonthSelect(month, e.target.value)}>
                {moment.months().map((label, value) => (
                    <option value={value}>{capitalize(label)}</option>
                ))}
            </select>
            <select
                className="form-select form-select-sm"
                style={{width: '6em'}} value={month.year()} onChange={(e) => onYearSelect(month, e.target.value)}>
                {years.map(item => (
                    <option selected={item === moment().year()} value={item}>{item}</option>
                ))}
            </select>
        </div>
    );
};
export default RenderMonthElement;
