import React, {
  createContext,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from "react";
import { State } from "../../../types/State";
import {
  findAllModels,
  findByModel,
} from "../../../services/backend/SbxService";

import {
  Condition,
  Model,
  ModelsResponse,
  SbxModelField,
  SbxResponse,
} from "../../../types/Sbx";
import {
  convertDateToDDMMMYYYY,
  convertTableRowsToCSVString,
  debounceTime,
  DEFAULT_SIZE,
  downloadTextToFile,
  IsJsonString,
  JSONtoString,
  removeDuplicateFromArray,
  toast,
  toMap,
} from "../../../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileCsv,
  faPollH,
  faSearch,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import CustomTableComponent, { Column } from "../CustomTableComponent/CustomTableComponent";
import useTranslate from "../../../hooks/useTranslate";
import SpinnerComponent from "../SpinnerComponent";
import reducer, { actions, initialState, IState, StateLocal } from "./Reducer";
import {
  getColumnFormat,
  getConditions,
  getEndResult,
  getFetch,
  getValueConditions,
  resultMapper,
  validateQueryValues,
} from "./Utils";
import useAsyncEffect from "../../../hooks/useAsyncEffect";
import TabContents from "../TabContents";
import EditorComponent from "../EditorComponent/EditorComponent";
import { Source } from "../../../types/Analytic";
import QueryBuilderComponent from "./QueryBuilderComponent";
import { AnyAction } from "redux";
import useOnInit from "../../../hooks/useOnInit";
import useIgnoreFirstEffect from "../../../hooks/useIgnoreFirstEffect";

export function validateQuery(value: string, callback: (query: any) => void) {
  if (IsJsonString(value)) {
    return callback(JSON.parse(value) as any);
  }
}

export interface Query {
  where: Condition[];
  row_model: string;
  fetch?: string[];
}

interface IProps {
  getResult?: (columns: Column[], rows: any[], allColumns: Column[]) => void;
  showTable?: boolean;
  getSizeColumns?: (size: number) => void;
  query?: Query;
  disableModel?: boolean;
  getQuery?: React.Dispatch<React.SetStateAction<Query | undefined>>;
  showFetch?: boolean;
  setStateLoading?: (state: string) => void;
}

export const QueryContext = createContext<{
  defaultFetchedModels: string[];
  reverseFetchedModels: string[];
  deepFetchedModels: string[];
  updateFetchQuery: (data: {
    models: { label: string; value: any }[];
    isDeepFetch?: boolean;
    isReverseFetch?: boolean;
  }) => void;
  showFetch?: boolean;
  fetchedModels: string[];
  state: IState;
  dataType?: Source;
  disableModel?: boolean;
  dispatch: React.Dispatch<AnyAction>;
  setDefaultFetchedModels: React.Dispatch<React.SetStateAction<string[]>>;
  getReverseFetch: (data: ModelsResponse[], model_name?: string) => void;
  includeColumns: Column[];
  t: (v: string) => string;
  getModels: () => void;
}>({
  dispatch(value: AnyAction): void {},
  fetchedModels: [],
  getReverseFetch(
    data: ModelsResponse[],
    model_name: string | undefined,
  ): void {},
  setDefaultFetchedModels(
    value: ((prevState: string[]) => string[]) | string[],
  ): void {},
  showFetch: false,
  state: initialState,
  t(v: string): string {
    return "";
  },
  includeColumns: [],
  defaultFetchedModels: [],
  reverseFetchedModels: [],
  deepFetchedModels: [],
  getModels: () => {},
  updateFetchQuery: () => null,
});

let cacheModels: ModelsResponse[] = [];

const QueryComponent = ({
  getResult,
  showTable = true,
  getSizeColumns,
  query,
  getQuery,
  showFetch,
  disableModel,
}: IProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [includeColumns, setIncludeColumn] = useState<Column[]>([]);
  const [fetchedModels, setFetchedModels] = useState<string[]>([]);
  const [defaultFetchedModels, setDefaultFetchedModels] = useState<string[]>(
    [],
  );
  const [reverseFetchedModels, setReverseFetchedModels] = useState<string[]>(
    [],
  );
  const [deepFetchedModels, setDeepFetchedModels] = useState<string[]>([]);
  const [columns, setColumns] = useState<Column[]>([]);
  const [columnsTable, setColumnsTable] = useState<Column[]>([]);
  const [size, setSize] = useState(DEFAULT_SIZE);
  const [mapper, setMapper] = useState<any[]>([]);
  const [currentTap, setCurrentTap] = useState("0");
  const { model, rows, conditions, totalItems } = state;
  const { t } = useTranslate("common");
  // const isMounted = useIsMount()

  const getReverseFetch = (data: ModelsResponse[], model_name?: string) => {
    let possibleFetch: { [key: string]: string[] } = {};
    let possibleReverseFetch: { [key: string]: string[] } = {};
    let byCode = data.reduce((obj: any, it: any) => {
      obj[it.id + ""] = it;
      return obj;
    }, {});

    data.forEach((model: ModelsResponse) => {
      model["properties"].forEach((field: Model) => {
        if (field["type"] == SbxModelField.REFERENCE) {
          if (!possibleFetch[model["name"]]) {
            possibleFetch[model["name"]] = [];
          }
          possibleFetch[model["name"]].push(field["name"]);
          if (byCode[field["reference_type"] + ""]) {
            let model_name = byCode[field["reference_type"] + ""]["name"];
            if (!possibleReverseFetch[model_name]) {
              possibleReverseFetch[model_name] = [];
            }
            possibleReverseFetch[model_name].push(
              field["name"] + "." + model["name"],
            );
          }
        }
      });
    });

    const row_model = model_name ?? query?.row_model ?? model?.value.name ?? "";

    setReverseFetchedModels(
      Object.values(possibleReverseFetch)
        .flat()
        .filter((fetch) =>
          row_model ? fetch.split(".")[1] === row_model : true,
        ),
    );
  };

  const getModels = useCallback(async () => {
    dispatch(actions.changeState(State.PENDING));
    // if (!dataType) {
    let res: SbxResponse<ModelsResponse>;
    if (cacheModels.length > 0) {
      res = { items: cacheModels, success: true };
    } else {
      res = await findAllModels();
    }

    if (res.success) {
      if (cacheModels.length === 0 && res.items) {
        cacheModels = res.items;
      }
      dispatch(actions.setModels(res.items ?? []));
      getReverseFetch(res.items ?? []);
      dispatch(actions.changeState(State.RESOLVED));
    } else {
      dispatch(actions.changeState(State.REJECTED));
    }
    return res;
  }, [dispatch]);

  const getPropertiesByReferences = useCallback(() => {
    if (model) {
      // modelId -> object to save original name of column table
      const modelId: { [id: string]: string } = {};

      const ids = model.value.properties.reduce((obj: number[], c) => {
        if (c.type === SbxModelField.REFERENCE) {
          if (c.reference_type) {
            modelId[c.reference_type] = c.name;
            obj.push(c.reference_type);
          }
        }
        return obj;
      }, []);

      dispatch(actions.changeState(StateLocal.FETCH_REFERENCES_PENDING));
      const modelFetched = toMap(
        state.rows.filter((r) => ids.includes(r.id)),
        "id",
      ) as {
        [key: string]: ModelsResponse;
      };
      if (Object.keys(modelFetched).length > 0) {
        const deepFetch: { [fetch: string]: string[] } = {};

        Object.keys(modelFetched).forEach((id) => {
          const properties = modelFetched[id].properties.filter(
            (property) => property.type === SbxModelField.REFERENCE,
          );
          if (properties.length > 0) {
            deepFetch[modelId[id]] = properties.map(
              (property) => property.name,
            );
          }
        });

        const fetch = Object.keys(deepFetch).reduce(
          (arr: string[], mainModel) => {
            deepFetch[mainModel].forEach((fetchModel) => {
              arr.push(`${mainModel}.${fetchModel}`);
            });
            return arr;
          },
          [],
        );

        setDeepFetchedModels(fetch);
      } else {
        setDeepFetchedModels([]);
      }
      dispatch(actions.changeFetchReferences(modelFetched));
      return modelFetched;
    }
  }, [model]);

  useEffect(() => {
    const eC = getColumns();
    setColumns(eC);
    if (getSizeColumns) {
      getSizeColumns(eC.length);
    }
    if (model) {
      setFetchedModels(getFetch(model, rows, includeColumns));
    }
  }, [model, includeColumns]);

  useAsyncEffect(async () => {
    setIncludeColumn([]);
    setColumns([]);
    setColumnsTable([]);
    getPropertiesByReferences();
  }, [getPropertiesByReferences]);

  useAsyncEffect(async () => {
    await getModels();
  }, []);

  useEffect(() => {
    if (query && !model) {
      const { row_model } = query;

      const m = rows
        .map((m) => ({
          label: m.name,
          value: m,
        }))
        .find((mdl) => mdl.value.name === row_model);

      if (m) {
        dispatch(actions.changeModel(m));
      }

      setDefaultFetchedModels(query.fetch ?? []);
    }
  }, [query, rows]);

  useEffect(() => {
    
    debounceTime(() => {
      dispatch(actions.changeQuery(JSONtoString(query, true) ?? "{}"));
    }, null, 500)
    // dispatch(actions.changeQuery(JSONtoString(query, true) ?? "{}"));
  }, [query]);

  useOnInit(
    async () => {
      dispatch(actions.changeBuild(State.BUILDING));
      if (model && query?.where.length) {
        const con = getConditions(model.value, query, state, t);
        await onResultQuery(
          { size, condition: getValueConditions(con), noChangeState: true },
          includeColumns,
        );
        dispatch(actions.setConditions(con));
      } else if (model && !query?.where.length) {
        await onResultQuery({ size }, includeColumns);
      }
      dispatch(actions.changeBuild(State.RESOLVED));
    },
    [state, query],
    [query ? state.state === StateLocal.FETCH_REFERENCES_RESOLVED : true],
  );

  useEffect(() => {
    if (currentTap === "1" && model && query) {
      validateQuery(state.queryTab ?? "", (json) => {
        debounceTime(
          () => {
            dispatch(
              actions.setConditions(getConditions(model.value, json, state, t)),
            );
          },
          {},
          10000,
        );
      });
    }
  }, [state.queryTab, currentTap, query]);

  useIgnoreFirstEffect(async () => {
    if (getQuery && state.build === State.RESOLVED) {
      const where = getValueConditions(conditions);

      getQuery((prevQuery) => ({
        where,
        row_model: model?.value.name ?? "",
        fetch:
          prevQuery && showFetch
            ? prevQuery.fetch
            : getFetch(model, rows, includeColumns),
      }));
    }
  }, [model, conditions, showFetch]);

  const updateFetchQuery = ({
    isDeepFetch,
    models,
    isReverseFetch,
  }: {
    models: { label: string; value: any }[];
    isDeepFetch?: boolean;
    isReverseFetch?: boolean;
  }) => {
    const fetch = query?.fetch ?? getFetch(model, rows, includeColumns) ?? [];

    let fetchItems: string[] = [];
    // Check what array of elements is changing, delete from current array and add the new state for it
    if (isDeepFetch) {
      fetchItems = fetch.filter((item) =>
        item.includes(".")
          ? !fetchedModels.some(
              (fetchModel) => item.split(".")[0] === fetchModel,
            )
          : true,
      );
    } else if (isReverseFetch) {
      fetchItems = fetch.filter((item) =>
        item.includes(".") ? item.split(".")[1] !== query?.row_model : true,
      );
    } else {
      fetchItems = fetch.filter((item) => item.includes("."));
    }

    if (models.length > 0) {
      fetchItems = removeDuplicateFromArray([
        ...fetchItems,
        ...models.map((item) => item.value),
      ]);
    }

    const queryD = {
      where: getValueConditions(conditions),
      row_model: model?.value.name ?? "",
      fetch: fetchItems,
    };

    if (getQuery) getQuery(queryD);

    setDefaultFetchedModels(fetchItems);
    dispatch(actions.changeQuery(JSON.stringify(queryD, null, "\t")));
  };

  const onResultQuery = async (
    params: {
      page?: number;
      size?: number;
      condition?: Condition[];
      noChangeState?: boolean;
    },
    columnsToFetch: Column[],
  ) => {
    const { page = 1, size = DEFAULT_SIZE, condition } = params;

    dispatch(actions.changeState(StateLocal.SEARCHING));
    const fetchArray: string[] = getFetch(model, rows, columnsToFetch);
    let parameters = {
      where: validateQueryValues(condition),
      row_model: model?.value.name ?? "",
      fetch: fetchArray,
      page,
      size,
    };
    if (currentTap === "1") {
      try {
        parameters = {
          ...JSON.parse(state.queryTab ?? ""),
          page,
          size,
        };
      } catch (e) {
        toast({
          type: "error",
          message: "Errors in the query, please verify json data",
        });
      }
    }

    const res = await findByModel(parameters);
    if (res.success) {
      dispatch(
        actions.changeResult({
          results: res.items,
          fetched_map: res.fetched_results,
          model: res.model ?? [],
          totalItems: res.total_items,
          noChangeState: params.noChangeState,
        }),
      );
      setSize(size);
    } else {
      dispatch(actions.changeState(State.REJECTED));
    }
  };

  const onFinish = async () => {
    if (model?.value && getResult && query) {
      dispatch(actions.changeState(StateLocal.GETTING_RESULT));
      // const rows = await getEndResult(query, state, columns, includeColumns);
      dispatch(actions.changeState(StateLocal.RESOLVED_RESULT));
      getResult(columnsTable, [], columns);
    }
  };

  async function onGetData(params?: { size?: number; page?: number }) {
    await onResultQuery(
      { ...params, condition: getValueConditions(conditions) },
      includeColumns,
    );
  }

  const getColumns = () => {
    const c =
      model?.value.properties.map((m) => ({
        name: m.name,
        header: m.name,
        data: m,
        type: m.type,
      })) ?? [];
    const eColumns = [...c, ...includeColumns];
    return eColumns.sort((a, b) => a.name.localeCompare(b.name));
  };

  async function addColumn(field: Model, fetchName: string) {
    let array = new Array(...includeColumns);
    const identify = getColumnFormat(fetchName, field.name);
    array.push({
      type: field.type,
      name: identify,
      header: identify,
      data: field,
    });
    if (
      field.type === SbxModelField.REFERENCE &&
      !state.fetched_map[field.reference_type_name]
    ) {
      await onResultQuery({ condition: getValueConditions(conditions) }, array);
    }
    setIncludeColumn(array);
  }

  function removeColumn(field: Model, fetchName: string) {
    const identify = getColumnFormat(fetchName, field.name);
    let array = new Array(...includeColumns).filter((c) => c.name !== identify);
    setIncludeColumn(array);
  }

  const loadingModels =
    state.state === State.PENDING || state.build === State.BUILDING;
  const isSearching = state.state === StateLocal.SEARCHING;
  const isGetting = state.state === StateLocal.GETTING_RESULT;

  async function onExportData() {
    if (!query) return;
    dispatch(actions.changeState(StateLocal.SEARCHING));
    const nRows = await getEndResult(
      query,
      state,
      columns,
      includeColumns,
      rows,
    );
    const text = convertTableRowsToCSVString(columnsTable, nRows);
    downloadTextToFile(
      text,
      "csv",
      `File ${convertDateToDDMMMYYYY(new Date())}`,
    );
    dispatch(actions.changeState(State.RESOLVED));
  }

  useEffect(() => {
    setMapper(
      resultMapper(columns, state, removeColumn, addColumn, includeColumns, t),
    );
  }, [columns, state.results]);

  return (
    <QueryContext.Provider
      value={{
        defaultFetchedModels,
        reverseFetchedModels,
        updateFetchQuery,
        deepFetchedModels,
        state,
        showFetch,
        fetchedModels,
        getModels: () => {
          cacheModels = [];
          getModels();
        },
        dispatch,
        setDefaultFetchedModels,
        getReverseFetch,
        disableModel,
        includeColumns,
        t,
      }}
    >
      {loadingModels ? (
        <div className="p-3 d-flex justify-content-center">
          <SpinnerComponent />
        </div>
      ) : (
        <>
          <TabContents
            reloadInfo={true}
            direction="end"
            getCurrentTab={setCurrentTap}
            tabs={[
              {
                label: t("query_builder"),
                component: <QueryBuilderComponent />,
              },
              {
                label: t("query_editor"),
                component: (
                  <EditorComponent
                    width="100%"
                    enableSnippets
                    value={state.queryTab}
                    onChange={(value) => {
                      validateQuery(value ?? "", (query) => {
                        if (getQuery) {
                          getQuery(query);
                        }
                      });
                      dispatch(actions.changeQuery(value ?? ""));
                    }}
                  />
                ),
              },
            ]}
          />

          {showTable && (
            <>
              <div className="d-flex justify-content-end my-3">
                <button
                  disabled={isSearching || !model}
                  onClick={() => onGetData()}
                  className="btn btn-primary btn-sm"
                >
                  <FontAwesomeIcon
                    spin={isSearching}
                    icon={isSearching ? faSpinner : faSearch}
                    className="me-1"
                  />{" "}
                  {t("search")}
                </button>

                <button
                  disabled={isSearching || !model}
                  onClick={onExportData}
                  className="btn btn-success btn-sm ms-2"
                >
                  <FontAwesomeIcon
                    spin={isSearching}
                    icon={isSearching ? faSpinner : faFileCsv}
                    className="me-1"
                  />{" "}
                  {t("export")}
                </button>
                {getResult && (
                  <button
                    disabled={isSearching || !state.results.length || isGetting}
                    onClick={() => onFinish()}
                    className="btn btn-primary btn-sm ms-2"
                  >
                    <FontAwesomeIcon icon={faPollH} className="me-1" />{" "}
                    {t("get_results")}
                  </button>
                )}
              </div>
            </>
          )}
          {model && showTable && (
            <CustomTableComponent
              totalData={totalItems}
              onShowSizeChange={(page, size) => onGetData({ size })}
              onChangePage={(page, size) => onGetData({ page, size })}
              columnsSetting
              getColumns={setColumnsTable}
              loading={isSearching}
              columns={columns}
              data={mapper}
            />
          )}
        </>
      )}
    </QueryContext.Provider>
  );
};

export default QueryComponent;
