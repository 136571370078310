import React, {useReducer} from "react";
import SelectComponent from "./FieldComponents/SelectComponent";
import {Account} from "../../types/Account";
import {capitalize, debounceTime} from "../../utils";
import {findByLike} from "../../services/backend/AccountService";
import {Response} from "../../types/Response";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {State} from "../../types/State";
import {ValueType} from "react-select";
import MultiSelectComponent from "./FieldComponents/MultiSelectComponent";

interface IProps {
  id: string;
  required?: boolean;
  name: string;
  value?: ValueType<any, any>;
  invalid?: boolean;
  readonly?: boolean;
  disabled?: boolean;
  placeholder?: string;
  multi?: boolean;
  onChange?: (value: ValueType<any, any> | ValueType<any, any>[]) => void;
}

const identify = "account_selector";

interface IState {
  accounts: { label: string, value: string, data: Account }[];
  state: State;
}

const initialState: IState = {
  accounts: [],
  state: State.IDLE
}

const slice = createSlice({
  name: identify,
  initialState,
  reducers: {
    setAccounts: (state, action: PayloadAction<Account[]>) => {
      state.accounts = action.payload.map(account => {
        return {
          value: account.account_id ?? "",
          label: account.account_id + " - " + capitalize(account.company.company_name ?? ""),
          data: account
        }
      })
      state.state = State.RESOLVED;
    },
    changeState: (state, action: PayloadAction<State>) => {
      state.state = action.payload;
    }
  }
});
const reducer = slice.reducer;
const actions = slice.actions;

const AccountSelectComponent = (props: IProps) => {

  const [{accounts, state}, dispatch] = useReducer(reducer, initialState)

  async function searchAccounts(value: string) {
    if (value) {
      dispatch(actions.changeState(State.PENDING));
      const res: Response<Account> = await debounceTime(findByLike, {search: value, size: 50}, 500);
      if (res.items) dispatch(actions.setAccounts(res.items));
    }
  }

  return (
    <div>
      {props.multi && (!props.value || Array.isArray(props.value)) ? (
        <MultiSelectComponent
          {...props}
          loading={state === State.PENDING}
          options={accounts}
          onInputChange={searchAccounts}
        />
      ) : (
        <SelectComponent
          {...props}
          sortOptions={false}
          loading={state === State.PENDING}
          options={accounts}
          onInputChange={searchAccounts}
        />
      )}
    </div>
  )
}


export default AccountSelectComponent;
