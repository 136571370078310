import * as React from 'react';
import {useContext} from 'react';
import CreatableSelectComponent from "../../Shared/FieldComponents/CreatableSelectComponent";
import {State} from "../../../types/State";
import {actionsReport, ReportState} from "../Slice";
import {StringOption} from "../../../types/Select";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlay} from "@fortawesome/free-solid-svg-icons";
import {ReportContext} from "../NewReportGeneratorComponent";
import styled from "styled-components";
import useTranslate from "../../../hooks/useTranslate";

const ContainerFetch = styled.div`
  display: grid;
  grid-template-columns: 40em 5em;
  align-items: center;
  gap: 4px

`

type Props = {
    setLoading: (loading: State) => void
    loading: State
    setShowResults: (showResults: boolean) => void
    setResults: (results: any) => void
};
const FetchDataComponent = ({setResults, loading, setLoading, setShowResults}: Props) => {
    const {t} = useTranslate("report")
    const {state, dispatch, getData, setQuery, query} = useContext(ReportContext)

    const handleGetData = () => {
        getData({
            setLoading,
            setResults,
            query: {
                source: {...query.source, with: state.model?.name ?? ""},
                actions: [],
                preview: true
            }
        })
    }

    return state.model ? (
        <>
            {query.source.from.toLowerCase().includes('sbx') &&

                <div className="d-flex flex-column gap-2">
                    <span className="card-header">{t("common:fetch")}</span>
                    <ContainerFetch>
                        <CreatableSelectComponent isMulti closeMenuOnSelect={false}
                                                  loading={loading === State.PENDING || state.state.includes(ReportState.PENDING_FETCHING_MODELS)}
                                                  disabled={loading === State.PENDING || state.state.includes(ReportState.PENDING_FETCHING_MODELS)}
                                                  id={""} name={""}
                                                  value={query.source?.fetch ? query.source.fetch.map(fetch => ({
                                                      label: fetch,
                                                      value: fetch
                                                  })) : null}
                                                  defaultValue={query.source?.fetch ? query.source.fetch.map(fetch => ({
                                                      label: fetch,
                                                      value: fetch
                                                  })) : null} onChange={evt => {

                            dispatch(actionsReport.setBaseQuery({
                                ...state.baseQuery,
                                source: {
                                    ...state.baseQuery.source,
                                    fetch: evt.map((item: StringOption) => item.value)
                                }
                            }))

                            setQuery({
                                ...query,
                                source: {...query.source, fetch: evt.map((item: StringOption) => item.value)}
                            })
                        }} options={state.fetchOptions?.map(model => ({label: model, value: model}))}
                                                  placeholder={t("select_fetch_model")}/>

                        <FontAwesomeIcon className="pointer " icon={faPlay} onClick={() => {
                            setShowResults(true)
                            handleGetData()
                        }}/>
                    </ContainerFetch>
                </div>
            }

            {
                query.source.from.toLowerCase().includes('google') &&
                <div className="d-flex flex-column gap-2">
                    <span className="card-header">{t("common:metrics")}</span>
                    <ContainerFetch>
                        <CreatableSelectComponent isMulti closeMenuOnSelect={false}
                                                  loading={loading === State.PENDING || state.state.includes(ReportState.PENDING_FETCHING_MODELS)}
                                                  disabled={loading === State.PENDING || state.state.includes(ReportState.PENDING_FETCHING_MODELS)}
                                                  id={""} name={""}
                                                  defaultValue={query.source?.metrics ? query.source.metrics.map(fetch => ({
                                                      label: fetch.name,
                                                      value: fetch.name
                                                  })) : null} onChange={evt => {


                            setQuery({
                                ...query,
                                source: {...query.source, metrics: evt.map((item: StringOption) => ({name: item.value}))}
                            })
                        }} options={state.fetchOptions?.map(model => ({label: model, value: model}))}
                                                  placeholder={t("select_metric")}/>

                        <FontAwesomeIcon className="pointer " icon={faPlay} onClick={() => {
                            setShowResults(true)
                            handleGetData()
                        }}/>
                    </ContainerFetch>
                </div>
            }


        </>
    ) : null;
};

export default FetchDataComponent