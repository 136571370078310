import React, {useState} from "react";
import {IAceEditorProps} from "react-ace";
import {faAlignLeft} from "@fortawesome/free-solid-svg-icons";
import useTranslate from "../../../hooks/useTranslate";
import ButtonComponent from "../ButtonComponent";
import {AnyData} from "../../../types/AnyData";

const Editor = (props: IAceEditorProps) => {
  if (typeof window !== "undefined") {
    const Ace = require("react-ace").default;
    require("brace/mode/javascript");
    require("brace/theme/github");

    return <Ace {...props} />;
  }

  return null;
};
const EditorComponent = (props: IAceEditorProps & { showActions?: boolean }) => {
  const {t} = useTranslate('config');

  const [editorValue, setEditorValue] = useState(props.value)


  const format = (config?: AnyData | string) => {
    setEditorValue(JSON.stringify(
      config,
      null,
      '\t'
    ));
  }

  return (
    <div>

      {props.showActions && <div className="mb-2">
        <ButtonComponent
          color="primary" label={t("common:format")} icon={faAlignLeft}
          onClick={() => format(editorValue ? JSON.parse(editorValue) : "")}/>
      </div>}

      <Editor
        {...props}
        // annotations={[{ row: 0, column: 2, type: "error", text: "Some error." }]}
        mode={"javascript"}
        name={"editor_component"}
        theme={"github"}
        // height={"100%"}
        fontSize={13}
        setOptions={{
          tabSize: 2,
        }}
      />
    </div>
  );
};

export default EditorComponent;
