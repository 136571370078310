import {
  Condition,
  GroupCondition,
  GroupConditionsValues,
  Model,
  ModelsResponse,
  SbxConditionType,
  SbxModelField
} from "../../../types/Sbx";
import PopoverComponent from "../PopoverComponent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDatabase, faKey, faPlusCircle, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import {capitalize, filterArrayString, uuidV4} from "../../../utils";
import {Button, Col, FormGroup, Input, Row} from "reactstrap";
import FieldInput, {getOptions} from "./FieldInput";
import React, {useState} from "react";
import {Column} from "../CustomTableComponent/CustomTableComponent";
import {IState} from "./Reducer";
import {Query} from "./QueryComponent";
import {plainToClass} from "class-transformer";
import {findByAll} from "../../../services/backend/SbxService";
import {SourceFrom} from "../../../types/Analytic";
import {AnyData} from "../../../types/AnyData";



function getDateFromKeys(key: string, map: { [key: string]: any }, row: any): any {
  let data;
  try {
    if (key) {
      const keys = key.split("-");
      const base = keys.shift() ?? "";
      data = map[row[base]];
      if (keys.length && data) {
        data = getDateFromKeys(keys.join("-"), map, data);
      }
    }
  } catch (e) {
  }
  return data;

}

export function resultMapper(
  columns: Column[],
  state: IState,
  removeColumn: (field: any, str: string) => void,
  addColumn: (field: any, str: string) => void,
  includeColumns: Column[],
  t: (str: string) => string
) {
  const ref = Object.keys(state.fetched_map ?? {}).reduce((obj: AnyData, key) => {
    obj = {...obj, ...state.fetched_map[key]};
    return obj;
  }, {});

  const allColumns = [...columns, ...includeColumns];
  return state.results.map((r, index) => {
    const keys = allColumns.reduce((obj: any, c) => {
      const fetchName = state.rows.find(r => r.id === c.data?.reference_type)
      if (fetchName?.properties && c.type === SbxModelField.REFERENCE) {
        let data = {...getDateFromKeys(c.name, ref, r)};
        if (Object.values(data).length && fetchName.properties) {
          delete data._META;
          const idPop = "pop_" + index + c.name;
          Object.keys(data).forEach(key => {
            const field = fetchName.properties.find(f => f.name === key);
            const identify = getColumnFormat(c.name, field?.name ?? "");
            const exist = includeColumns.some(c => c.name === identify);
            if (exist) {
              obj[identify] = data[key];
            }
          })
          obj[c.name] = <PopContent idPop={idPop}
                                    index={index}
                                    column={c}
                                    t={t}
                                    addColumn={addColumn}
                                    key={idPop}
                                    removeColumn={removeColumn}
                                    fetchName={fetchName}
                                    includeColumns={includeColumns}
                                    data={data}/>
        } else {
          obj[c.name] = <FontAwesomeIcon className="ms-3" icon={faDatabase}/>;
        }
      }
      return obj;
    }, {});
    return {
      ...r,
      ...keys,
    }
  })
}

interface IProps {
  removeColumn: (field: any, str: string) => void;
  addColumn: (field: any, str: string) => void;
  includeColumns: Column[];
  t: (str: string) => string;
  idPop: string;
  column: Column;
  index: number;
  data: any;
  fetchName: ModelsResponse;
}

const PopContent = (
  {
    includeColumns,
    t,
    removeColumn,
    addColumn,
    idPop,
    column,
    index,
    data,
    fetchName,
  }: IProps) => {

  const [filter, setFilter] = useState("");

  return (
    <PopoverComponent
      key={idPop}
      zIndex={3000}
      label={<FontAwesomeIcon icon={faDatabase}/>}
      trigger="click"
      title={capitalize(column.name)}
      placement="bottom"
      id={idPop}>
      <div style={
        {
          maxHeight: "80vh",
          overflowY: "auto",
          overflowX: "hidden",
          width: "600px",
        }}>
        <Row>
          <Col lg={12}>
            <FormGroup>
              <Input placeholder={t("search") + "..."} value={filter} onChange={e => setFilter(e.target.value)}/>
            </FormGroup>
          </Col>
          {filterArrayString(Object.keys(data), filter)
            .sort()
            .map(key => {
              const field = fetchName.properties.find(f => f.name === key);
              const identify = getColumnFormat(column.name, field?.name ?? "");
              const exist = includeColumns.some(c => c.name === identify);
              return (
                <Col key={key} sm={12} lg={Object.values(data).length >= 15 ? 6 : 12}>
                  <div>
                    <b className="me-1">{key}</b><br/>
                    <small>
                      {!field?.type &&
                        <FontAwesomeIcon className="text-warning me-2" icon={faKey}/>}
                      ({field?.type || "_KEY"})
                    </small>
                    {field?.type && (
                      <>
                        {exist ? (
                          <Button
                            onClick={() => removeColumn(field, column.name ?? "")}
                            size="sm" color="link" className="text-decoration-none">
                            <small className="text-danger">
                              <FontAwesomeIcon
                                icon={faTimesCircle}/> {t("remove")}
                            </small>
                          </Button>
                        ) : (
                          <Button
                            onClick={() => {
                              addColumn(field, column.name ?? "")
                            }}
                            size="sm" color="link" className="text-decoration-none">
                            <small> <FontAwesomeIcon icon={faPlusCircle}/> {t("column")}</small>
                          </Button>
                        )}
                      </>
                    )}
                  </div>
                  <FieldInput
                    type={field?.type}
                    id={"read" + column.name + index}
                    value={data[key]}
                    onChange={() => null}
                    disabled/>
                </Col>
              )
            })}
        </Row>
      </div>
    </PopoverComponent>
  )
}


export function getConditions(model: ModelsResponse, query: Query, state: IState, t: (str: string) => string) {
  const fieldOptions = (model.properties
    .map(m => ({label: m.name, value: m})) ?? []);
  const {where} = query;

  if (where.length) {
    const gc: GroupConditionsValues[] = where.map(group => {
      return {
        ANDOR: group.ANDOR,
        GROUP: group.GROUP.map((condition) => {
          let field = condition.FIELD;
          let subField: string | null = null;

          if (field.includes(".")) {
            const cField = field.split(".");
            subField = cField.pop() ?? null;
            field = cField.shift() ?? "";
          }

          const FIELD = fieldOptions.find(f => f.value.name === field) ?? null;

          let subFieldOptions = state.modelFetched[`${FIELD?.value.reference_type}`]?.properties
            .map(m => ({label: m.name, value: m})) ?? [];
          subFieldOptions = [{label: "key", value: plainToClass(Model, {name: "_KEY"})}, ...subFieldOptions];

          let SUB_FIELD = subFieldOptions.find(sf => sf.value.name === subField) ?? null;

          if (FIELD?.value.type === SbxModelField.REFERENCE && !SUB_FIELD) {
            SUB_FIELD = subFieldOptions.find(sb => sb.value.name === "_KEY") ?? null;
          }

          let VAL: any = '';
          switch (condition.OP) {
            case SbxConditionType.IN:
              VAL = condition.VAL;
              break;
            case SbxConditionType.LIKE:
              VAL = condition.VAL?.toString().replace(new RegExp("%", "g"), "");
              break;
            default:
              VAL = condition.VAL;
              break;
          }
          const format = condition.VAL && VAL ? condition.VAL.toString().replace(VAL.toString(), "word") : null;
          const options = getOptionsByField(t, SUB_FIELD ? SUB_FIELD.value.type : FIELD?.value.type);
          const OP = options.find(op => op.value.format && format ? op.value.format === format?.trim() : op.value.condition === condition.OP) ?? null;
          return {
            OP,
            VAL,
            SUB_FIELD,
            FIELD,
            key: uuidV4(),
            ANDOR: condition.ANDOR
          }
        })
      }
    });
    return gc;
  }
  return [];
}

//validate options by field type
export function getOptionsByField(t: (str: string) => string, type?: SbxModelField | "list_provider", dataType?: SourceFrom) {
  const defaultC = [
    {
      label: t("EQUAL_TO"),
      value: {
        condition: SbxConditionType.EQUAL_TO
      }
    },
    {
      label: t("EXIST"),
      value: {
        condition: SbxConditionType.EXIST
      }
    },
    {
      label: t("NO_EXIST"),
      value: {
        condition: SbxConditionType.NO_EXIST
      }
    },
    {
      label: t("GREATER_THAN"),
      value: {
        condition: SbxConditionType.GREATER_THAN
      }
    },
    {
      label: t("SMALLER_THAN"),
      value: {
        condition: SbxConditionType.SMALLER_THAN
      }
    },
    {
      label: t("DIFFERENT_OF"),
      value: {
        condition: SbxConditionType.DIFFERENT_OF
      }
    },
    {
      label: t("START_LIKE"),
      value: {
        condition: SbxConditionType.LIKE, format: "word%"
      }
    },
    {
      label: t("END_LIKE"),
      value: {
        condition: SbxConditionType.LIKE, format: "%word"
      }
    },
    {
      label: t("CONTAIN_LIKE"),
      value: {
        condition: SbxConditionType.LIKE, format: "%word%"
      }
    },
    {
      label: t("DATA_IN"),
      value: {
        condition: SbxConditionType.IN
      }
    },
  ];

  //exclude conditions from array
  function exception(e: SbxConditionType[]) {
    return defaultC.filter(c => !e.find(ce => ce === c.value.condition));
  }

  function show(e: SbxConditionType[]) {
    return defaultC.filter(c => e.find(ce => ce === c.value.condition));
  }


  if (dataType === SourceFrom.SBX_EVENT) {
    return show([SbxConditionType.EQUAL_TO])
  }

  switch (type) {
    case SbxModelField.TEXT:
    case SbxModelField.STRING:
      return exception([SbxConditionType.SMALLER_THAN, SbxConditionType.GREATER_THAN]);
    case SbxModelField.BOOLEAN:
    case SbxModelField.REFERENCE:
      return exception([SbxConditionType.SMALLER_THAN, SbxConditionType.GREATER_THAN, SbxConditionType.LIKE]);
    case SbxModelField.INT:
    case SbxModelField.FLOAT:
    case SbxModelField.DATE:
      return exception([SbxConditionType.LIKE]);
    default:
      return defaultC;
  }
}

export async function getEndResult(query: Query, state: IState, columns: Column[], includeColumns: Column[], rows: ModelsResponse[]) {
  if (!query) return [];
  const {modelResult, model} = state;
  const fetchArray: string[] = getFetch(model, rows, includeColumns);
  const res = await findByAll({...query, fetch: fetchArray});
  if (!res?.success) return [];
  const {results, fetched_results: fetched_map} = res;
  return results.map((r: any) => {
    const keys = columns.reduce((obj: any, c) => {
      const fetchName: Model | undefined = modelResult.find((m: any) => m.name === c.name);
      if (fetchName?.reference_type_name && c.type === SbxModelField.REFERENCE) {
        let data = fetched_map[fetchName.reference_type_name];
        data = data ? data[r[c.name]] : null;
        if (data && fetchName.reference_model) {
          Object.keys(data)
            .sort()
            .forEach(key => {
              let field = fetchName.reference_model[key];
              const identify = getColumnFormat(fetchName.reference_type_name, field?.name ?? "");
              const exist = includeColumns.some(c => c.name === identify);
              if (exist) {
                obj[identify] = data[key];
              }
            })
        }
      }
      return obj;
    }, {});
    return {...r, ...keys}
  })
}


export function getColumnFormat(ref: string, name: string) {
  return `${ref}-${name}`;
}

export function getValueConditions(conValues: GroupConditionsValues[]) {
  return conValues.reduce((groups: Condition[], data) => {
    let group: Condition = {
      ANDOR: data.ANDOR,
      GROUP: data.GROUP.reduce((groupConditions: GroupCondition[], conditions) => {
        if (conditions.OP && conditions.FIELD) {
          let FIELD = conditions.FIELD.value.name
          if (conditions.FIELD.value.type === SbxModelField.REFERENCE &&
            conditions.SUB_FIELD && conditions.SUB_FIELD.value.name !== "_KEY") {
            FIELD += "." + conditions.SUB_FIELD.value.name;
          }
          let c: GroupCondition = {
            VAL: conditions.VAL && conditions.OP.value.format ? (conditions.OP.value.format).replace("word", conditions.VAL.toString()) : conditions.VAL,
            OP: conditions.OP.value.condition,
            ANDOR: conditions.ANDOR,
            FIELD
          };
          if (!((conditions.OP.value.condition !== SbxConditionType.EXIST &&
            conditions.OP.value.condition !== SbxConditionType.NO_EXIST) && !conditions.VAL)) {
            groupConditions.push(c);
          }
        }
        return groupConditions;
      }, [])
    }
    if (group.GROUP.length) {
      groups.push(group)
    }
    return groups;
  }, []);
}

export function getFetch(model: {
  label: string,
  value: ModelsResponse
} | null, rows: ModelsResponse[], fetchColumns: Column[], initial = 0, c = [] as string[], ids = [] as number[]) {

  model?.value.properties
    .filter(p => p.type === SbxModelField.REFERENCE &&
      (initial ? (fetchColumns.some(e => e.name.includes(p.name)) && !ids.some(id => id === p.reference_type)) : true))
    .forEach(p => {
      function pushToC(value: string) {
        c.push(`${initial ? model?.label.concat(".") : ""}` + value);
      }

      if (rows?.length) {
        const newModel = rows.find(r => r.id === p.reference_type);
        if (newModel && !ids.some(id => id === newModel.id)) {
          pushToC(p.name);
          ids.push(p.reference_type);
          const columnToFetch = fetchColumns.find(c => c.name.includes(p.name));
          if (columnToFetch && initial < 1) {
            getFetch({value: newModel, label: newModel.name}, rows, fetchColumns, initial + 1, c, ids);
          }
        }
      }
    });

  return c;
}


export function validateQueryValues(conditions?: Condition[]): Condition[] | undefined {
  if (conditions) {
    let con = JSON.stringify(conditions);
    getOptions().forEach(v => {
      con = con.split(v.value).join(v.toValue.toISOString());
    })
    return JSON.parse(con);
  } else {
    return conditions;
  }
}
