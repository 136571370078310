import {ModuleType, PermissionsData} from "../../types/Permissions";
import {UserInfo, UserPermission} from "../../types/UserInfo";
import {Group} from "../../types/Group";

export const permissionTypeSlice = {
  GET_PERMISSIONS: "permission/getPermissions",
  ADD_PERMISSIONS: "permission/addModulePermissions",
  DELETE_PERMISSIONS: "permission/deleteModulePermissions",
  UPDATE_MODULE_PERMISSIONS: "permission/editModulePermission",
  GET_USERS: "permission/getAllUsers",
  GET_USER: "permission/getUserById",
  GET_GROUPS: "permission/getAllGroups",
  SET_PERMISSION_OR_GROUP_TO_USER: "permission/assignPermissionOrGroupToUser",
  SET_PERMISSION_TO_GROUP: "permission/assignPermissionToGroup",
  REMOVE_PERMISSION_FROM_USER: "permission/removePermissionFromUserOrGroup",
  UPDATE_MEMBERSHIP_FROM_USER_OR_GROUP: "permission/permissionUpdateMembershipFromUserOrGroup",
  GET_GROUP_BY_ID: "permission/getGroupById",
  CREATE_GROUP: "permission/saveGroup",
  CREATE_USER: "permission/createUser",
  UPDATE_GROUP: "permission/updateGroup",
  CREATE_MEMBERSHIP: "permission/createMembership",
  DELETE_GROUP_BY_ID: "permission/removeGroup",
  REMOVE_MEMBERSHIP: "permission/removeMembership",
  ARRAY_MEMBERSHIP: "permission/arrayMembership",
  CHANGE_PASSWORD:"permission/changePassword"
}

export enum PermissionStates {
  PERMISSIONS_PENDING = "PERMISSIONS_PENDING",
  PERMISSIONS_RESOLVED = "PERMISSIONS_RESOLVED",
  PERMISSIONS_REJECTED = "PERMISSIONS_REJECTED",
  PERMISSION_PENDING = "PERMISSION_PENDING",
  PERMISSION_RESOLVED = "PERMISSION_RESOLVED",
  PERMISSION_REJECTED = "PERMISSION_REJECTED",
  PERMISSION_DELETED = "PERMISSION_DELETED",
  USERS_PENDING = "USERS_PENDING",
  USERS_RESOLVED = "USERS_RESOLVED",
  USERS_REJECTED = "USERS_REJECTED",
  USER_PENDING = "USER_PENDING",
  USER_RESOLVED = "USER_RESOLVED",
  USER_REJECTED = "USER_REJECTED",
  GROUPS_PENDING = "GROUPS_PENDING",
  GROUPS_RESOLVED = "GROUPS_RESOLVED",
  GROUPS_REJECTED = "GROUPS_REJECTED",
  GROUP_PENDING = "GROUP_PENDING",
  GROUP_RESOLVED = "GROUP_RESOLVED",
  GROUP_DELETED = "GROUP_DELETED",
  GROUP_REJECTED = "GROUP_REJECTED",
  IDLE = "IDLE"
}

export interface PermissionState {
  permissions: PermissionsData [];
  state: PermissionStates;
  modules: ModuleType[];
  users: UserInfo[];
  groups: Group[];
  user?: UserPermission;
  group?: Group;
  multi_state: PermissionStates[];
  updatingTablePermissions: boolean;
}


export interface AssignToPermissionOrGroup {
  name?:string;
  userId?: number;
  permissionId?: number;
  groupId?: number;
  metadata?: string;
  type: "group" | "user";
}
