import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IPropsConfirmModal } from "../../components/Shared/Modal/ConfirmationModal";
import { IPropsPermissionModal } from "../../components/Shared/Modal/AddOrEditPermissionModal";
import { IPropsGroupModal } from "../../components/Shared/Modal/GroupModal";
import { IPropsCreateProvider } from "../../components/Shared/Modal/CreateDataProviderModal";
// import {IPropsConfirmModal} from "../../components/Shared/Modal/ConfirmationModal";
// import {IPropsPermissionModal} from "../../components/Shared/Modal/AddOrEditPermissionModal";
import { IPropsFormModal } from "../../components/Shared/Modal/FormModal/UpsertFormModal";
import { IPropsRuleModal } from "../../components/Shared/Modal/RuleCreatorModal";
import { IPropsTableEditModal } from "../../components/Shared/Modal/TableEditModal/TableEditModal";
import { IPropsModelModal } from "../../components/Shared/Modal/UpdateModelModal";
import { IPropsUpsertTaskModal } from "../../components/Shared/Modal/UpsertTaskModal";
import { IPropsUpsertSequenceModal } from "../../components/Shared/Modal/UpsertSequenceModal";
import { IPropsUpsertEventModal } from "../../components/Shared/Modal/UpsertEventModal";
import { IPropsFormPreviewModal } from "../../components/Shared/Modal/FormPreviewModal";
import { IPropsUpsertGatewayModal } from "../../components/Shared/Modal/UpsertGatewayModal";
import { IPropsGroupUserModal } from "../../components/Shared/Modal/GroupAddUserModal";
import { IPropsDynamicModal } from "../../components/Shared/Modal/ConfigModal/DynamicModal";
import { IPropsUserModal } from "../../components/Shared/Modal/UserModal";
import { IPropsChangePasswordModal } from "../../components/Shared/Modal/ChangePasswordModal";
import { IPropsDynamicComponentModal } from "../../components/Shared/Modal/DynamicComponentModal";
import { IPropsAuthenticationModal } from "../../components/AuthComponent/AuthenticationModuleModal";

// import {IPropsGroupModal} from "../../components/Shared/Modal/GroupModal";

export enum ModalTypes {
  CONFIRM = "CONFIRM",
  UPSERT_FORM = "UPSERT_FORM",
  CREATE_DATA_PROVIDER = "CREATE_DATA_PROVIDER",
  UPSERT_PERMISSION = "UPSERT_PERMISSION",
  CREATE_GROUP = "CREATE_GROUP",
  UPDATE_GROUP = "UPDATE_GROUP",
  UPDATE_GROUP_USER = "UPDATE_GROUP_USER",
  RULE_CREATOR = "RULE_CREATOR",
  TABLE_EDIT_MODAL = "TABLE_EDIT_MODAL",
  SBX_UPDATE_MODEL = "SBX_UPDATE_MODEL",
  UPSERT_TASK_MODAL = "UPSERT_TASK_MODAL",
  UPSERT_SEQUENCE_MODAL = "UPSERT_SEQUENCE_MODAL",
  UPSERT_EVENT_MODAL = "UPSERT_EVENT_MODAL",
  UPSERT_GATEWAY_MODAL = "UPSERT_GATEWAY_MODAL",
  FORM_PREVIEW_MODAL = "FORM_PREVIEW_MODAL",
  UPDATE_DYNAMIC_CONFIG = "UPDATE_DYNAMIC_CONFIG",
  CREATE_USER = "CREATE_USER",
  CHANGE_PASSWORD = "CHANGE_PASSWORD",
  DYNAMIC_COMPONENT_MODAL = "DYNAMIC_COMPONENT_MODAL",
  AUTHENTICATION_COMPONENT_MODAL = "AUTHENTICATION_COMPONENT_MODAL",
}

type dataModal<Data> =
  | IPropsConfirmModal
  | IPropsPermissionModal
  | IPropsGroupModal
  | IPropsGroupUserModal
  | IPropsCreateProvider
  | IPropsFormModal
  | IPropsRuleModal
  | IPropsTableEditModal
  | IPropsModelModal
  | IPropsUpsertTaskModal
  | IPropsUpsertSequenceModal
  | IPropsUpsertEventModal
  | IPropsFormPreviewModal
  | IPropsUpsertGatewayModal
  | IPropsDynamicModal
  | IPropsUserModal
  | IPropsChangePasswordModal
  | IPropsDynamicComponentModal<Data>
  | IPropsAuthenticationModal;

const slice = createSlice({
  name: "modal",
  initialState: {},
  reducers: {
    openModal(state: any, action: PayloadAction<dataModal<any>>) {
      state[action.payload.type!] = {
        open: true,
        data: action.payload,
        pre_data: (action.payload as any)?.pre_data ?? null,
      };
    },
    closeModal(
      state: any,
      action: PayloadAction<{ type: ModalTypes; identifierKey?: string }>,
    ) {
      state[action.payload.type] = {
        data: action.payload.identifierKey
          ? { identifierKey: action.payload.identifierKey }
          : null,
        open: false,
      };
    },
  },
});

export const actionsModal = slice.actions;

export default slice.reducer;
