import * as React from 'react';
import {useRef, useState} from 'react';
import {Alias, Report, TruncateReport} from "../../types/Analytic";
import DateRangeComponent from "../Shared/FieldComponents/DateRangeComponent";
import RenderMonthElement from "../Shared/RenderMonthElement";
import {
    convertDateToNumberDate,
    convertNumberDateToDate,
    getObjValueInDeep,
    IsJsonString,
    iterator,
    uuidV4
} from "../../utils";
import {generateQuarterRanges, getRangeValue} from "../../utils/analyticsUtils";
import useTranslate from "../../hooks/useTranslate";
import useDeviceDetect from "../../hooks/useDeviceDetect";
import {MultiReport} from "../analytics/AnalyticsPageComponent";

import CreatableSelectComponent from "../Shared/FieldComponents/CreatableSelectComponent";
import {StringOption} from "../../types/Select";
import useAsyncEffect from "../../hooks/useAsyncEffect";
import {findAllByModel} from "../../services/backend/SbxService";
import {State} from "../../types/State";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import {CSSObject} from "@emotion/serialize";

type Props = {
    truncate: TruncateReport
    report?: Report | MultiReport
    setTruncate?: (truncate: string, launch?: boolean, report?: Report) => void
    launchQuery?: (query: Report) => void
    truncateRange: {
        endDate: Date | null,
        startDate: Date | null
    }
    getItemsFromTableColumn?: (column: string) => string[]
    setTruncateRange: (range: {
        endDate: Date | null,
        startDate: Date | null
    }) => void
    disabled?: boolean
    rangeType: string
    setRangeType: (range: string) => void
};

const dateOptions = [
    "dates_range", "months_range", "weeks_range", "quartiles"
]

const dataByModel: { [model: string]: StringOption[] } = {}

const TruncateByTypeComponent = ({
                                     truncate,
                                     report,
                                     launchQuery,
                                     setTruncate,
                                     truncateRange, disabled,
                                     setTruncateRange, getItemsFromTableColumn, rangeType, setRangeType
                                 }: Props) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => {
        setDropdownOpen((prevState) => !prevState);
    }

    const {t} = useTranslate('common')
    const {isMobile} = useDeviceDetect();
    const [loading, setLoading] = useState(State.IDLE)
    const [yearSelected, setYearSelected] = useState(new Date().getFullYear())
    const onMount = useRef(false)
    const [references, setReferences] = useState<StringOption[]>([])
    const [truncateValue, setTruncateValue] = useState<StringOption | StringOption[] | null>(null)
    const [showAll, setShowAll] = useState(false)
    const prevRangeDate = useRef<{
        endDate: Date | null,
        startDate: Date | null
    } | null>(null)


    const handleRange = (range: number | string) => {
        if (report) {
            const newReport: Report = {...report}
            let newTruncate = ""
            let launch = false
            if (truncate) {
                if (range === 4) {

                } else {
                    if (truncate) {

                        newTruncate = JSON.stringify({
                            ...truncate,
                            range: truncate.relative ? truncate.range : getRangeValue(range as number)
                        })

                        if (newReport.alias) {
                            if (IsJsonString(newReport.alias as string)) {
                                const alias: Alias = JSON.parse(newReport.alias as string)
                                alias.update = true
                                newReport.alias = JSON.stringify(alias)
                            }
                        }
                    }
                }
            }

            if (launchQuery && ((typeof range === "string" && /\d/.test(range)) || typeof range === "number")) {
                launch = true
            }

            if (setTruncate) {
                if (setTruncate) {
                    setTruncate(newTruncate, launch, newReport)
                }
            }
        }

    }

    useAsyncEffect(async () => {
        if (!onMount.current) {
            if (truncate.type === "reference" && truncate.label) {
                const model = truncate.field?.split(".").at(-1)
                if (model) {

                    if (dataByModel[model] && dataByModel[model].length > 0) {
                        setReferences(dataByModel[model].sort((a: StringOption, b: StringOption) => a.label.localeCompare(b.label)))
                    } else {
                        setLoading(State.PENDING)
                        const response = await findAllByModel({row_model: model, where: [], fetch: []})
                        if (response?.success) {
                            const options = response.items.map((item: { [key: string]: any }) => {
                                return {
                                    value: item?._KEY ?? "",
                                    label: getObjValueInDeep(item, truncate.label as string) ?? ''
                                }
                            })
                            const referenceOptions = options.sort((a: StringOption, b: StringOption) => a.label.localeCompare(b.label))
                            dataByModel[model] = referenceOptions
                            setReferences(referenceOptions)
                            setLoading(State.RESOLVED)
                            onMount.current = true
                        } else {
                            setLoading(State.REJECTED)
                        }
                    }


                }
            }

            if (truncate.type === "text" && !truncate.model && truncate.field && getItemsFromTableColumn) {
                const items = getItemsFromTableColumn(truncate.field)
                setReferences(items.map(item => {
                    return {
                        value: item,
                        label: item
                    }
                }))
            }
        }

    }, [truncate]);

    const handleTruncateValue = (value: string | string[]) => {
        const newTruncate = JSON.stringify({
            ...truncate,
            value
        })

        if (setTruncate) {
            setTruncate(newTruncate, true, report)
        }
    }

    React.useEffect(() => {

        if (truncateRange.endDate) {
            prevRangeDate.current = truncateRange
            // prevEndDate.current = parseInt(convertDateToNumberDate(truncateRange.endDate))
        }

    }, [truncateRange]);

    React.useEffect(() => {

        if (truncate?.value) {
            if (Array.isArray(truncate.value) && references.length > 0) {
                const truncateValues = truncate.value.map((item) => {
                    const option = references.find((ref) => ref.value === item)

                    return {
                        value: item,
                        label: option?.label ?  option?.label : item === "" ? t("all") : item
                    }
                })
                if (showAll){
                    truncateValues.unshift({
                        value: "",
                        label: t("all")
                    })
                }

                setTruncateValue(truncateValues)
            } else {
                setTruncateValue({
                    value: truncate.value,
                    label: truncate.value
                })
            }
        }else{
            if(truncate.type === "text"){
                setTruncateValue(null)
            }
        }

    }, [truncate.value, references, showAll]);
    const getRangeByType = () => {
        switch (rangeType) {
            case "months_range":
                return <div className="d-flex align-items-center ">
                    <input type="month" className="form-control border-0 rounded-0"
                           value={truncateRange.startDate ? moment(truncateRange.startDate).format("YYYY-MM") : moment(new Date()).format("YYYY-MM")}
                           onChange={evt => {
                               if (evt.currentTarget.value) {

                                   const [year, month] = evt.currentTarget.value.split('-')

                                   const startDate = new Date(parseInt(year), parseInt(month) - 1, 1)
                                   setTruncateRange({startDate, endDate: null})
                               }


                               // const endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0)
                           }} placeholder={t("report:initial_month")}/>
                    <input type="month" className="form-control border-0 rounded-0"
                           value={truncateRange.endDate ? moment(truncateRange.endDate).format("YYYY-MM") : moment(new Date()).format("YYYY-MM")}
                           onChange={evt => {
                               if (evt.currentTarget.value) {
                                   const [year, month] = evt.currentTarget.value.split('-')
                                   const endDate = new Date(parseInt(year), parseInt(month), 0)
                                   setTruncateRange({startDate: truncateRange.startDate ?? null, endDate})

                                   if (endDate) {
                                       const startDate = convertDateToNumberDate(truncateRange.startDate ?? new Date())
                                       const end = convertDateToNumberDate(endDate)
                                       const range = `${startDate}-${end}`
                                       handleRange(range)
                                   }
                               }


                           }}
                           placeholder={t("report:end_month")}/>
                </div>
            case "weeks_range":
                return <div className="d-flex align-items-center ">
                    <input type="week" className="form-control border-0 rounded-0"
                           value={moment(truncateRange.startDate ?? new Date()).format("YYYY-[W]ww")} onChange={evt => {
                        if (evt.currentTarget.value) {
                            const startDate = moment(evt.currentTarget.value.replace("-", "")).toDate()
                            const endDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + 6)
                            setTruncateRange({startDate, endDate})

                            if (startDate && endDate) {
                                const start = convertDateToNumberDate(startDate)
                                const end = convertDateToNumberDate(endDate)
                                const range = `${start}-${end}`
                                handleRange(range)
                            }
                        }
                    }} placeholder={t("report:initial_week")}/>
                </div>
            case "dates_range":
                return <DateRangeComponent
                    minimumNights={0}
                    placeholderStart={t("startDate")}
                    placeholderEnd={t("endDate")}
                    renderMonthElement={RenderMonthElement}
                    id={uuidV4()}
                    onFocusedChange={(focused, focusName) => {
                        if (focused) {
                            if (focusName === "startDate" && truncateRange.endDate && truncateRange.startDate) {
                                setTruncateRange({
                                    ...truncateRange,
                                    endDate: null
                                })
                            }

                        } else {
                            if (prevRangeDate.current) {
                                setTruncateRange(prevRangeDate.current)
                            }
                        }


                    }}
                    value={truncateRange}
                    isOutsideRange={() => false}
                    onChange={date => {

                        setTruncateRange(date)
                        if (date.startDate && date.endDate) {
                            prevRangeDate.current = date

                            const startDate = convertDateToNumberDate(new Date(date.startDate))
                            const endDate = convertDateToNumberDate(new Date(date.endDate))

                            const range = `${startDate}-${endDate}`
                            handleRange(range)
                        }
                    }} orientation={isMobile ? 'vertical' : 'horizontal'}/>
            case "quartiles":
                return <div className="d-flex align-items-center gap-1">
                    <CreatableSelectComponent name={'quartiles'}
                                              styles={{
                                                  control(base: CSSObject, props: any): CSSObject {
                                                      return {
                                                          ...base,
                                                          width: "100px"
                                                      }
                                                  }
                                              }}
                                              onChange={evt => {
                                                  setYearSelected(evt.value)
                                              }}
                                              value={{
                                                    label: yearSelected,
                                                    value: yearSelected
                                              }}
                                              options={iterator(2016, (new Date().getFullYear() + 10))
                                                  .map(iter => ({label: iter, value: iter}))} />

                    <CreatableSelectComponent name={'quartiles'}
                                              onChange={evt => {
                                                  const [numberStartDate, numberEndDate] = evt.value.split("-")
                                                  if (numberStartDate && numberEndDate) {
                                                      const startDate = convertNumberDateToDate(numberStartDate)
                                                      const endDate = convertNumberDateToDate(numberEndDate)
                                                      setTruncateRange({startDate, endDate})
                                                      handleRange(evt.value)
                                                  }
                                              }}
                                              styles={{
                                                  control(base: CSSObject, props: any): CSSObject {
                                                      return {
                                                          ...base,
                                                          width: "250px"
                                                      }
                                                  }
                                              }}
                                              sortOptions={false}
                                              options={generateQuarterRanges(yearSelected).map(item => {
                                                  return {
                                                        label: t(`report:${item.name}`),
                                                        value: item.value
                                                  }
                                              })} />
                </div>
            default:
                return null
        }
    }

    const getTruncateByType = () => {
        switch (truncate.type) {
            case "text":
                return <div className="d-flex flex-grow-1">
                    <CreatableSelectComponent placeholder={t("search")}
                                              styles={{
                                                  control: (baseStyles) => ({
                                                      ...baseStyles,
                                                      width: "250px",
                                                  }),
                                              }}
                                              value={truncateValue ?? null}
                                              isClearable disabled={loading === State.PENDING}
                                              loading={loading === State.PENDING} name={""} options={references}
                                              onChange={(evt) => {
                                                  handleTruncateValue(evt ? (evt?.value ?? "").toString() : null)
                                              }}/>
                </div>
            case "reference":
                return <div className="d-flex flex-grow-1">
                    <CreatableSelectComponent name={""} disabled={loading === State.PENDING || disabled}
                                              className="flex-grow-1 d-flex"
                                              sortOptions={false}
                                              styles={{
                                                  control: (baseStyles) => ({
                                                      ...baseStyles,
                                                      width: "250px",
                                                  }),
                                              }}
                                              loading={loading === State.PENDING} options={references.length > 0 ? [{
                        label: t("all"),
                        value: ""
                    }, ...references] : []}
                                              value={truncateValue ?? null} isMulti onChange={(evt) => {


                        const lastItem = evt[evt.length - 1]
                        if (lastItem?.value === "") {
                            handleTruncateValue([])
                            setShowAll(true)
                        } else {
                            setShowAll(false)
                            handleTruncateValue(evt.filter((item: StringOption) => item.value).map((item: StringOption) => item.value))
                        }

                    }}/>
                </div>
            default:
                return <div className="d-flex flex-column  ">
                    <Dropdown isOpen={dropdownOpen} toggle={toggle} direction={'down'}>
                        <DropdownToggle outline color="transparent" className="px-0">
                            <u className="text-capitalize d-flex align-items-center gap-1">
                                <span>{t(`report:${rangeType}`)} </span>
                                <FontAwesomeIcon icon={faChevronDown}/></u>
                        </DropdownToggle>

                        <DropdownMenu>
                            {dateOptions?.sort((a, b) => a.localeCompare(b)).map(item => (
                                <DropdownItem key={item} onClick={() => {
                                    setRangeType(item)
                                    setTruncateRange({startDate: null, endDate: null})
                                }}>{t(`report:${item}`)}</DropdownItem>
                            ))}
                        </DropdownMenu>
                    </Dropdown>

                    {getRangeByType()}
                </div>
        }
    }

    return getTruncateByType()
};

export default TruncateByTypeComponent