import {conditions, Container, FieldTypesRule} from "./Types";
import useTranslate from "../../hooks/useTranslate";
import ValidateFieldRequired from "../Shared/ValidateFieldRequired";
import Select from "react-select";
import React from "react";

const FieldValidator = (props: Container) => {
  
  const {t} = useTranslate("common");
  
  
  switch (props.type) {
    case FieldTypesRule.NUMBER:
    case FieldTypesRule.TEXT:
      return <input
        placeholder={props.placeholder}
        className="form-control"
        onChange={e => props.onChange && props.onChange(e.target.value)}
        required={props.required}
        readOnly={props.readonly}
        type={props.type}
        value={props.value || ""}/>
    
    case FieldTypesRule.SELECT:
      return <ValidateFieldRequired
        value={props.value ? "value" : ""}
        disabled={props.disabled || props.readonly}
        required={props.required}>
        <Select
          placeholder={props.placeholder}
          isMulti={props.multi}
          isDisabled={props.disabled || props.readonly}
          isLoading={props.loading}
          options={props.options}
          value={props.value || null}
          onInputChange={(e, t) => {
            if (!props.onChange || t.action !== 'input-change') return;
            
            if (e) {
              props.onChange({
                value: e,
                label: e
              })
            } else if (!e && !props.value) {
              props.onChange(null);
            }
          }}
          onChange={(e: any) => props.onChange && props.onChange(e)}/>
      </ValidateFieldRequired>
    
    case FieldTypesRule.CONDITION:
      const {
        value,
        onChange,
        include = [],
        exclude = [],
        options = [...conditions, {label: "CONTAIN_LIKE", value: "%word%.includes(%value%)"}],
        disabled,
        readonly,
        required = true,
        loading,
        placeholder
      } = props;
      
      const nOptions = options
        .concat(include)
        .filter(op => !exclude.some(ex => ex === op.value))
        .map(e => ({...e, label: t(e.label)}));
      
      return <ValidateFieldRequired
        value={value ? "value" : ""}
        required={required || readonly}
        disabled={disabled}>
        <Select
          placeholder={placeholder}
          isLoading={loading}
          isDisabled={readonly || disabled}
          options={nOptions}
          value={value || null}
          onChange={(e: any) => onChange && onChange(e)}
        />
      </ValidateFieldRequired>
  }
}


export default FieldValidator;
