import * as React from 'react';
import {useReducer, useState} from 'react';
import TruncateFormComponent from "./TruncateFormComponent";
import {baseTextTruncate, baseTruncate, initialState, truncateSlice} from "./TruncateSlice";
import {AnalyticQuery, CacheTruncate, Source, SourceFrom, TruncateReport} from "../../../types/Analytic";
import useAsyncEffect from "../../../hooks/useAsyncEffect";
import {getSchema} from "../../../services/backend/AnalyticsService";
import {ModelsResponse} from "../../../types/Sbx";
import {State} from "../../../types/State";
import {DragDropContext, Draggable, Droppable, DroppableStateSnapshot, DropResult} from "react-beautiful-dnd-next";
import {faCalendar, faPencil, faSpinner} from "@fortawesome/free-solid-svg-icons";
import useTranslate from "../../../hooks/useTranslate";
import {DragAndDropActionList} from "../../Shared/DragAndDropComponent/DragAndDropComponent";
import {convertDateToNumberDate, IsJsonString, uuidV4} from "../../../utils";
import {Controller, useFieldArray, useForm} from "react-hook-form";
import TruncateCacheFormComponent from "./TruncateCacheFormComponent";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TabContents, {Tab} from "../../Shared/TabContents";
import EditorComponent from "../../Shared/EditorComponent/EditorComponent";

const {reducer, actions} = truncateSlice

const baseQuery: Source = {
    action: 'get',
    with: '',
    merge_type: 'inner',
    in: 0,
    from: SourceFrom.SBX_DATA
}

export const baseCache: CacheTruncate = {
    domain: "0",
    append_at: "start",
    from: convertDateToNumberDate(new Date()),
    to: convertDateToNumberDate(new Date()),
    append_format: '',
    name: "",
    append_index: "",
    append_type: "columns",
    force_save: false,
    force_reload: false
}

type Props = {
    setReportTruncate: (truncate: string) => void
    truncate?: string
    analyticQuery: AnalyticQuery;
    columns: string[]
}

const name = "truncate"
const itemKeyProp = "id"

const TruncateGeneratorComponent = ({setReportTruncate, truncate, analyticQuery, columns}: Props) => {
    const {t} = useTranslate('common');
    const [localState, dispatch] = useReducer(reducer, initialState);
    const [loading, setLoading] = useState(State.IDLE)
    const useFormMethods = useForm<TruncateReport>({
        defaultValues: {
            ...baseTruncate,
            cache: {...baseCache},
            temporal_id: uuidV4()
        }
    })

    const {fields, append, remove, update} = useFieldArray({
        control: useFormMethods.control,
        name: "others"
    });

    const [actionList] = useState<DragAndDropActionList[]>([
        {label: t('date'), value: 'date', icon: faCalendar},
        {label: t('text'), value: 'text', icon: faPencil},
    ]);

    const onDragEnd = (result: DropResult) => {

        if (!result.destination) {
            return;
        }
        let truncateState = {...useFormMethods.getValues()}

        switch (result.draggableId) {
            case "date": {
                append({...baseTruncate, temporal_id: uuidV4(), range: truncateState.range})
            }
                break;
            case "text": {
                append({...baseTextTruncate, temporal_id: uuidV4(), value: truncateState.value})
                useFormMethods.setValue("cache", {...baseCache})
            }

                break;
            case "cache": {
                truncateState.cache = {...baseCache, temporal_id: uuidV4()}
                useFormMethods.setValue("cache", truncateState.cache)
            }
                break;
            default:
                break;
        }
    }

    const getTruncateComponent = (truncate: TruncateReport | CacheTruncate) => {
        return <TruncateFormComponent mainTruncate={useFormMethods.getValues()} remove={remove} update={update}
                                      truncate={useFormMethods.getValues().temporal_id !== truncate.temporal_id ? truncate as TruncateReport : useFormMethods.getValues()}
                                      key={truncate.temporal_id} columns={columns} reset={useFormMethods.reset}
                                      models={localState.models} control={useFormMethods.control}
                                      isLoading={loading === State.PENDING}
                                      isExtraTruncate={useFormMethods.getValues().temporal_id !== truncate.temporal_id}/>
    }

    useAsyncEffect(async () => {
        setLoading(State.PENDING)
        const resSchema: { [key: string]: ModelsResponse[] } = await getSchema([baseQuery, {
            ...baseQuery,
            from: SourceFrom.SBX_EVENT
        }])

        if (resSchema?.hasOwnProperty("sbx-data") && resSchema?.hasOwnProperty("sbx-event")) {
            dispatch(actions.setModels({
                models: [...Object.values(resSchema["sbx-data"]), ...Object.values(resSchema["sbx-event"])]
            }))
            setLoading(State.RESOLVED)
        } else {
            setLoading(State.REJECTED)
        }
    }, [])

    React.useEffect(() => {
        let json: { [key: string]: string } = {}
        if (truncate && IsJsonString(truncate)) {
            json = JSON.parse(truncate)
        } else {
            if (!truncate && analyticQuery.truncate && (analyticQuery.truncate as TruncateReport).field) {
                json = analyticQuery.truncate as any as { [key: string]: string }
            }
        }

        if (Object.keys(json).length > 0) {
            Object.keys(json).forEach(key => {
                useFormMethods.setValue(key as keyof TruncateReport, json[key])
            })
        }


    }, [truncate, analyticQuery]);

    React.useEffect(() => {
        const subscription = useFormMethods.watch((value, {name, type}) => {
            setReportTruncate(JSON.stringify(value))
        });
        return () => subscription.unsubscribe();
    }, [useFormMethods.watch()]);

    const defaultReportValue = useFormMethods.getValues("update_fields")

    const defaultValue = defaultReportValue ? typeof defaultReportValue === "object" ? JSON.stringify(
        defaultReportValue,
        null,
        '\t'
    ) : IsJsonString(defaultReportValue as string) ? JSON.stringify(
        JSON.parse(defaultReportValue as string),
        null,
        '\t'
    ) : null : null

    const tabs: Tab[] = [
        {
            label: t("truncate"), component: <DragDropContext
                onDragEnd={onDragEnd}>
                <div className="d-flex ">
                    <div className="border border-primary">
                        <div className="card-header">
                            <span>{t("action_list")}</span>
                        </div>

                        <Droppable droppableId={`${name}_type_list`} isDropDisabled={loading === State.PENDING}>
                            {provided => (
                                <div key={`${name}_droppable_action_list`}
                                     ref={provided.innerRef} {...provided.droppableProps}>
                                    {actionList.filter(action => action.visible_when ? action.visible_when(action) : action.value === useFormMethods.getValues("type")).map((action, index) => {
                                        return <Draggable key={name + "_" + action.value + "_" + index}
                                                          draggableId={action.value}
                                                          index={index}>
                                            {provided => (
                                                <div key={action.value}
                                                     className="p-2 d-flex flex-column align-items-center"
                                                     style={{border: "1px solid ghostwhite"}}
                                                     ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                    {action.icon && <FontAwesomeIcon icon={action.icon} size={"2x"}/>}
                                                    <span>{action.label}</span>
                                                </div>
                                            )}
                                        </Draggable>
                                    })}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </div>
                    <div className="d-flex flex-column flex-grow-1 px-2 card"
                         style={{borderLeft: "3px solid ghostwhite"}}>
                        <div
                            className="card-header d-flex align-items-center justify-content-between gap-1 border-bottom p-2">
                            <span>{t("truncate")}</span>
                        </div>
                        <Droppable droppableId="actions" isDropDisabled={loading === State.PENDING}>
                            {(provided, snapshot: DroppableStateSnapshot) => (
                                <div key={"actions_list"} className="d-flex flex-column gap-2 px-2 overflow-auto py-4"
                                     style={{
                                         minHeight: '50vh',
                                         maxHeight: '70vh',
                                         border: snapshot.isDraggingOver ? "5px dotted #5e72e4" : "",
                                         margin: snapshot.isDraggingOver ? "5px" : ""
                                     }}
                                     ref={provided.innerRef} {...provided.droppableProps}>


                                    {loading === State.PENDING ? <div className="text-center">
                                        {loading === State.PENDING ?
                                            <div className="d-flex align-items-center gap-2 justify-content-center">
                                                <FontAwesomeIcon pulse icon={faSpinner} size={"2x"}/>
                                                <span className="fw-bold">{t("loading")} {t("columns")}...</span>
                                            </div>

                                            :

                                            <span
                                                className="text-lightgray">{t("select_source_before_action")}</span>
                                        }
                                    </div> : ([{...useFormMethods.getValues()}, ...fields].length === 0 || snapshot.isDraggingOver) &&
                                        <div className="text-center">
                                            <span className="text-lightgray">{t("drag_and_drop_action_here")}</span>
                                        </div>}

                                    {loading !== State.PENDING && [{...useFormMethods.getValues()}, ...fields].length > 0 && [{...useFormMethods.getValues()}, ...fields].map((item, index) => (
                                        <Draggable
                                            draggableId={"draggable_id_" + (itemKeyProp && item.hasOwnProperty(itemKeyProp) ? (item as any)[itemKeyProp as any] : name) + '_' + index}
                                            index={index}
                                            key={"draggable_key_" + (itemKeyProp && item.hasOwnProperty(itemKeyProp) ? (item as any)[itemKeyProp as any] : name) + '_' + index}>
                                            {provided => (
                                                <div
                                                    key={"item_" + (itemKeyProp && item.hasOwnProperty(itemKeyProp) ? (item as any)[itemKeyProp as any] : name) + '_' + index}
                                                    ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                    {getTruncateComponent(item as TruncateReport)}
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </div>
                </div>
            </DragDropContext>
        },
        {
            label: "Update fields", component: <Controller
                control={useFormMethods.control}
                name={`update_fields`}
                defaultValue={defaultValue || "{}"}
                render={({field: {onChange, value}}) => (
                    <EditorComponent
                        enableSnippets
                        showActions
                        value={(value as string) || ""}
                        onChange={onChange}
                    />
                )}
            />
        }
    ]

    return (
        <div className="d-flex flex-wrap gap-2">
            <div className="flex-grow-1">
                <TabContents
                    reloadInfo
                    tabs={tabs}/>
            </div>

            <div className="d-flex flex-column flex-grow-1 mb-2">
                <div className="mt-2">
                    <div className="d-flex flex-column ">
                        <div className="d-flex justify-content-end">
                            <u className="text-link pointer" onClick={() => {
                                useFormMethods.setValue("cache", {...baseCache})
                            }}>{t("clear")}</u>
                        </div>
                        <TruncateCacheFormComponent models={localState.models} control={useFormMethods.control}
                                                    register={useFormMethods.register}
                                                    getValue={useFormMethods.getValues}
                                                    isLoading={loading === State.PENDING}
                                                    analyticQuery={analyticQuery}
                                                    cacheTruncate={useFormMethods.getValues().cache as CacheTruncate}
                                                    truncate={useFormMethods.getValues()}
                                                    setCache={cache => useFormMethods.setValue("cache", cache)}/>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default TruncateGeneratorComponent