import * as React from 'react';
import useTranslate from "../../../hooks/useTranslate";
import {useContext, useState} from "react";
import {StringOption} from "../../../types/Select";
import {State} from "../../../types/State";
import useAsyncEffect from "../../../hooks/useAsyncEffect";
import {findAllModels} from "../../../services/backend/SbxService";
import SelectComponent from "../../Shared/FieldComponents/SelectComponent";
import {AnalyticQueryAction} from "../../../types/Analytic";
import {ReportContext} from "../NewReportGeneratorComponent";

type Props = {
    action: AnalyticQueryAction
    index: number
};

let modelsCache: StringOption[] = []


const NewSaveActionComponent = ({action, index}: Props) => {

    const {setQueryAction} = useContext(ReportContext)

    const {t} = useTranslate("common");
    const [models, setModels] = useState<StringOption[]>([])
    const [loading, setLoading] = useState(State.IDLE)

    useAsyncEffect(async () => {

        if (modelsCache.length > 0){
            setModels(modelsCache)
        }else{
            setLoading(State.PENDING)
            const res = await findAllModels();
            if (res?.success && res.items) {

                setModels(res.items.filter(model => model.name.startsWith("report_")).map(model => ({
                    label: model.name,
                    value: model.name.replace("report_", "")
                })))
                modelsCache = res.items.filter(model => model.name.startsWith("report_")).map(model => ({
                    label: model.name,
                    value: model.name.replace("report_", "")
                }))


                setLoading(State.RESOLVED)
            } else {
                setLoading(State.REJECTED)
            }
        }

    }, [])


    return (
        <div className="d-flex flex-column">
            <span>{t("save")}</span>
            <SelectComponent id={"save_options"} disabled={loading === State.PENDING}
                             loading={loading === State.PENDING}
                             defaultValue={action.with ? {label: "report_"+action.with, value: action.with}  : null}
                             name={"save_options"} onChange={evt => {

                // action.with = evt.value
                setQueryAction({
                    ...action,
                    with: evt.value
                }, index)
            }} options={models}/>
        </div>
    );
};

export default NewSaveActionComponent