import * as React from 'react';
import {useState} from 'react';
import useTranslate from "../../hooks/useTranslate";

type Props = {
    children: (search: string) => React.ReactNode
};
const ColumnsActionsWrapperComponent = ({children}: Props) => {
    const [search, setSearch] = useState("")
    const {t} = useTranslate("common")
    return (
        <>
            <div className="input-group">
                <input className="form-control border-0 border-bottom mb-2" type="search" placeholder={t("search")}
                       value={search} onChange={evt => {
                    setSearch(evt.currentTarget.value)
                }}/>

            </div>
            <>{children(search)}</>
        </>
    );
};

export default ColumnsActionsWrapperComponent