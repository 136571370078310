'use client';

import { useEffect } from 'react';

const useScrollIsDown = (
    id: string,
    callback: (isDown: boolean, isTop: boolean, ref: HTMLElement) => void,
    ...deps: any[]
) => {
    useEffect(() => {
        const element = document.getElementById(id);
        if (element) {
            element.addEventListener('scroll', (event) => {
                const { scrollHeight, scrollTop, clientHeight } = event.target as any;
                if (Math.abs(scrollHeight - clientHeight - scrollTop) < 1) {
                    callback(true, false, element);
                } else {
                    callback(false, scrollTop === 0, element);
                }
            });
        }

        return () => {
            if (element) {
                element.removeEventListener('scroll', () => {});
            }
        };
    }, [id, deps]);
};

export default useScrollIsDown;
