import React, { useEffect, useReducer, useState } from "react";
import { getSbxModelFields } from "../../../services/backend/SbxService";
import { Model, SbxModelField, SbxResponse } from "../../../types/Sbx";
import { Contact, SbxCrmDataColumn } from "../../../types/User";
import {
  containsNumbers,
  convertDateToDDMMMYYYY,
  evalExpression,
  getAllDataByProvider,
  getColumnValueFromRules,
  getCompoundName,
  getObjValueInDeep,
  getSortedOptionsByRules,
  getUnderscoreLabel,
  getVariableDefaultValue,
  isDefaultVarExpression,
  IsJsonString,
  isVarExpression,
  ProviderOptionsResponse,
  toast,
  uuidV4,
} from "../../../utils";
import IField from "../../../types/FormBuilder/IField";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Task } from "../../../types/Task";
import { useDispatch } from "react-redux";
import { actionsModal, ModalTypes } from "../../../store/Modal/Slice";
import { State } from "../../../types/State";
import { FieldType, SubType } from "../../../types/FieldType";
import {
  ColumnsLabels,
  Field,
  FieldFormatRules,
  SelectSubType,
} from "../../../types/Field";
import {
  Control,
  Controller,
  useForm,
  UseFormGetValues,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import CustomTableComponent, {
  Action,
  CustomTableColumnType,
} from "../../Shared/CustomTableComponent/CustomTableComponent";
import useTranslate from "../../../hooks/useTranslate";
import useIgnoreFirstEffect from "../../../hooks/useIgnoreFirstEffect";
import { ProviderType } from "../../../types/ProviderType";
import CrmOptionsInputComponent from "../FormRenderInput/CrmOptionsInputComponent";
import { getProviderById } from "../../../services/backend/DataProviderService";

type Props = {
  field: IField;
  task?: Task | null;
  // formState: {
  //   [key: string]: any
  // }
  getValue?: UseFormGetValues<any>;
  defaultValue: () => any;
  setValueInput?: UseFormSetValue<any>;
  // dispatchTaskForm: ({name, value, type}: { name: string, value: any, type?: string }) => void;
};

export type TableFormColumn = {
  list_provider_type?: ProviderType;
  name: string;
  column?: string;
  label: string;
  unique?: boolean | string;
  parent?: string;
  hide?: boolean;
  sub_columns?: ColumnsLabels[] | null;
  read_only?: boolean;
  type?: "list_provider" | "constant" | "time" | "currency" | "Document";
  sub_type?: SbxModelField | string | SubType;
  constant?: string;
  value?: string[];
  sort_index?: number;
  column_reference?: SbxCrmDataColumn;
  calculated?: boolean; // Check if a field is calculated
  summarize?: boolean; // Check if a field is summarized
  default?: string | number; // Could be ${now} ${currentUser}, calculate field like "${income_y1/kilos_y1}"
  format_rules?: FieldFormatRules;
  searchable_limit?: boolean;
  list_provider?: string;
  group?: string;
  provider_type?: "DATABASE";
  render_type?: "radio_button" | "field";
  extra_values?: string[]; // An use case is when exist and calculate input and others inputs required the same value but the inputs are read only or hide. So set main input and set the extra inputs at the same time
  value_type?: "number";
  reference?: string;
  filter?: {
    default: "{first_value}";
    key_column_filter: string;
    main_column_filter: string;
  };
  table_column_type?: SelectSubType.SEARCHEABLE;
  placeholder?: string;
  table_type?: CustomTableColumnType;
  search_by?: string[]; // Ej. find by model search by ["code", "name"]
  search_by_type?: { [key: string]: string }; //"search_by_type": {"id": "number","address": "text"}
  skip?: boolean; // No save a column in database
  visible_when?: string; // Ej. visible_when="${is_admin} === 'true'"
  required?: boolean;
};

enum Types {
  SET_STATE = "SET_STATE",
  SET_MULTI_STATE = "SET_MULTI_STATE",
}

export interface TableFormState {
  tableHeaders: TableFormColumn[];
  data: any[];
  copyData: any[];
  filterProperties: string[];
  model: (Model | TableFormColumn)[];
  row_model: string;
  totalItems: number;
  max_row?: number;
  no_allow_new_rows_on_edit?: boolean;
  isNewTable: boolean;
  isLoading: State;
  isInlineTable: boolean;
}

const initialState: TableFormState = {
  filterProperties: [],
  model: [],
  data: [],
  copyData: [],
  tableHeaders: [],
  totalItems: 0,
  row_model: "",
  isNewTable: false,
  isLoading: State.IDLE,
  isInlineTable: false,
};

function reducer(
  state: TableFormState,
  {
    type,
    payload,
  }: {
    type: Types;
    payload: { name: string; value: any } | { name: string; value: any }[];
  },
) {
  switch (type) {
    case Types.SET_STATE:
      return {
        ...state,
        [(payload as { name: string; value: any }).name]: (
          payload as { name: string; value: any }
        ).value,
      };
    case Types.SET_MULTI_STATE:
      (payload as { name: keyof TableFormState; value: any }[]).forEach(
        (data) => {
          state = { ...state, [data.name]: data.value };
        },
      );
      return { ...state };
    default:
      throw new Error();
  }
}

const cacheProvider: { [key: string]: any } = {};

type TaskContextProps = {
  task?: Task | null;
  watch?: UseFormWatch<any>;
  control?: Control<any, object>;
  setValue?: UseFormSetValue<any>;
  getFormValue?: UseFormGetValues<any>;
};

export const TableTaskContext = React.createContext<TaskContextProps>({});

export const TableTaskComponent = ({
  field,
  defaultValue,
  task,
  getValue,
  setValueInput,
}: Props) => {
  const { setValue, watch, getValues, control, register } = useForm<any>();
  const [localState, localDispatch] = useReducer(reducer, initialState);
  const [taskProcessData, setTaskProcessData] = useState<{
    [key: string]: string;
  }>({});
  const dispatch = useDispatch();
  const [providerItems, setProviderItems] = useState<any[]>([])

  const [search, setSearch] = useState("");
  const dispatchForm = ({
    name,
    value,
  }: {
    name: keyof TableFormState;
    value: any;
  }) => {
    localDispatch({ type: Types.SET_STATE, payload: { name, value } });
  };
  const { t } = useTranslate("common");
  const dispatchMultiForm = (
    forms: { name: keyof TableFormState; value: any }[],
  ) => {
    localDispatch({ type: Types.SET_MULTI_STATE, payload: forms });
  };

  React.useEffect(() => {
    if (!!task?.process_data && Object.keys(task.process_data).length > 0) {
      const obj: { [key: string]: string } = {};
      Object.keys(task.process_data).forEach((key) => {
        if (task.process_data[key]?.value) {
          obj[key] = task.process_data[key].value;
        }
      });

      if (obj[field.name] && IsJsonString(obj[field.name])) {
        // let list = JSON.parse(obj[field.name]);
        // if (list.length > 0 && field.format_rules_definition && field.format_rules_definition.table_row_accessor_key) {
        //   list = list.map((item: any) => {
        //     return {
        //       ...item,
        //       _KEY: item[field.format_rules_definition.table_row_accessor_key as string]
        //     }
        //   })
        //
        //   obj[field.name] = JSON.stringify(list);
        // }

      }

      setTaskProcessData(obj);
    }
  }, [task, field]);

  const getTableProviderData = async () => {
    const getProviderData = async (
      provider_id: string,
      column: ColumnsLabels,
    ) => {
      dispatchForm({ name: "isLoading", value: State.PENDING });

      let form = { ...taskProcessData, ...getValues() };
      if (column.format_rules?.dependencies && form[field.name]) {
        let arr = [];

        if (
          IsJsonString(form[field.name]) &&
          Array.isArray(JSON.parse(form[field.name]))
        ) {
          arr = JSON.parse(form[field.name]);
        } else if (form[field.name] && Array.isArray(form[field.name])) {
          arr = form[field.name];
        }

        if (arr.length > 0) {
          arr.forEach((item: { [key: string]: string }) => {
            Object.keys(item).forEach((key) => {
              if (form[key]) {
                form[key].push(item[key]);
              } else {
                form[key] = [item[key]];
              }
            });
          });
        }
      }

      const response = await getAllDataByProvider({
        provider_id,
        formState: form,
        getFormValue: getValue,
      });

      if (response && response.success) {
        if (response.items && response.items?.length > 0) {
          if (!cacheProvider[provider_id]) {
            cacheProvider[provider_id] = response.items;
          } else {
            cacheProvider[provider_id] = [
              ...cacheProvider[provider_id],
              ...response.items,
            ];
          }
        }
        dispatchForm({ name: "isLoading", value: State.RESOLVED });
      } else {
        toast({ message: "Ocurrió un error", type: "error" });
        dispatchForm({ name: "isLoading", value: State.REJECTED });
        return;
      }
    };

    if (
      field.format_rules_definition?.columns_labels &&
      field.format_rules_definition?.table_type !== "inline"
    ) {
      for (const column of field.format_rules_definition?.columns_labels) {
        if (column.type === "list_provider") {
          // getAllDataByProvider((column as TableFormColumn).list_provider as string);
          getProviderData(
            (column as TableFormColumn).list_provider as string,
            column,
          );
        }
      }
    }
  };

  useEffect(() => {
    let subscription: any | null = null;
    if (watch) {
      subscription = watch((value, { name, type }) => {
        getTableProviderData();
      });
    }
    return () => subscription?.unsubscribe();
  }, [watch, field, taskProcessData]);

  const getValueFromField = ({
    data,
    property,
  }: {
    data: any;
    property?: string;
  }) => {
    const type = localState?.model.find((model) => model.name === property)
      ?.type;
    const header = localState?.tableHeaders?.find(
      (header) => header.name === property,
    );
    if (!type) {
      return null;
    }

    switch (type) {
      case SbxModelField.REFERENCE: {
        if (header && header.sub_columns) {
          const column = header.sub_columns[0];
          return data[column.name] ?? "";
        } else {
          return null;
        }
      }
      case "list_provider":
        if (header?.list_provider_type === ProviderType.DATABASE) {
          if (data) {
            return data;
          }
        } else {
          if (header?.list_provider && cacheProvider[header.list_provider]) {
            const columns = (header.format_rules?.columns_labels ?? [
              header,
            ]) as ColumnsLabels[];
            const column = getColumnValueFromRules({
              columns,
              isProvider: true,
            });
            const item = cacheProvider[header.list_provider].find(
              (providerItem: any) =>
                getObjValueInDeep(providerItem, column) === data,
            );

            if (item) {
              return getCompoundName({ columns, item });
            }
          } else {
            if (data) {
              return data;
            }
          }

          if (header?.render_type === "field") {
            return data;
          }
        }

        return "";
      case SbxModelField.DATE:
        return convertDateToDDMMMYYYY(new Date(data)) ?? "";
      case SbxModelField.BOOLEAN:
        return data ? "Si" : "No";
      default:
        return data;
    }
  };

  const getFields = async ({ page = 1, size = 10 }) => {
    let headers: ColumnsLabels[] = [];
    if (field.list_provider) {
      const response: SbxResponse<Contact> = await getSbxModelFields({
        provider: { ...field.list_provider, page, size },
      });

      if (response?.success && response.model) {
        headers =
          field?.format_rules_definition?.columns_labels ??
          response.model.map((header) => ({
            name: header.name,
            label: getUnderscoreLabel(header.name),
          }));



        dispatchMultiForm([
          { name: "model", value: response.model },
          { name: "row_model", value: field.list_provider.name },
          { name: "data", value: response.results },
          { name: "totalItems", value: response.row_count },
        ]);
      }
    } else {
      if (
        field?.format_rules_definition?.columns_labels?.some(
          (columnLabel) =>
            columnLabel.format_rules?.columns_labels &&
            columnLabel.format_rules?.columns_labels?.length > 1,
        )
      ) {
        headers = field?.format_rules_definition?.columns_labels.reduce(
          (arr: ColumnsLabels[], columnLabel) => {
            if (
              columnLabel.type === "list_provider" &&
              columnLabel.format_rules?.columns_labels
            ) {
              columnLabel.format_rules?.columns_labels.forEach(
                (subColumnLabel) => {
                  if (subColumnLabel.type === "list_provider") {
                    arr.push({
                      ...subColumnLabel,
                      list_provider: columnLabel.list_provider,
                    });
                  } else {
                    arr.push({ ...subColumnLabel });
                  }
                },
              );
            } else {
              arr.push(columnLabel);
            }
            return arr;
          },
          [],
        );
      } else {
        headers = field?.format_rules_definition?.columns_labels?.map(
          (header) => ({
            ...header,
            table_type:
              header.type === "list_provider"
                ? "Custom"
                : header.type ?? SbxModelField.STRING,
          }),
        );
      }

      dispatchMultiForm([
        {
          name: "model",
          value:
            headers?.map((header) => ({
              ...header,
              type: header.type ?? SbxModelField.STRING,
            })) ?? [],
        },
        { name: "tableHeaders", value: headers },
        { name: "isNewTable", value: true },
        {
          name: "isInlineTable",
          value: field?.format_rules_definition?.table_type === "inline",
        },
      ]);
    }

    if (field.field_type === FieldType.TABLE && field.format_rules_definition) {
      dispatchMultiForm([
        {
          name: "no_allow_new_rows_on_edit",
          value: field.format_rules_definition.no_allow_new_rows_on_edit,
        },
        { name: "max_row", value: field.format_rules_definition.max_row },
      ]);
    }

    if (headers?.length > 0) {
      headers = headers.filter((header) => header.label);

      dispatchMultiForm([
        { name: "tableHeaders", value: headers },
        {
          name: "filterProperties",
          value: headers?.map((header: ColumnsLabels) => header.name) ?? [],
        },
      ]);
    }
  };

  React.useEffect(() => {
    getFields({});
    return () => {};
  }, []);

  const paginationChange = (page: number) => {
    getFields({ page });
  };

  React.useEffect(() => {
    if (
      defaultValue() &&
      IsJsonString(defaultValue()) &&
      JSON.parse(defaultValue()).length > 0
    ) {

      getTableProviderData().then((res) => {
        if (IsJsonString(defaultValue())) {
          const arrItems = JSON.parse(defaultValue());
          dispatchForm({
            name: "data",
            value: arrItems.map((item: any) => ({ ...item, id: uuidV4() })),
          });
        }

      });
    } else {
      if (
        defaultValue() &&
        Array.isArray(defaultValue()) &&
        defaultValue().length > 0
      ) {
        dispatchForm({
          name: "data",
          value: defaultValue().map((item: any) => ({ ...item, id: uuidV4() })),
        });
      }
    }
  }, [taskProcessData]);

  const setTableItems = (items: any[]) => {
    // console.log('items', items)
    dispatchForm({
      name: "data",
      value: items,
    });

    dispatchForm({
      name: "copyData",
      value: items,
    });

    if (field.format_rules_definition?.table_type !== "inline") {
      setValue(field.name, items);
      if (setValueInput) {
        setValueInput(field.name, items);
      }
      // dispatchTaskForm({
      //   name: field.name, value: items
      // });
    }
  };

  const showAddButton = () => {
    const isAvailableLength = !!localState.max_row
      ? localState.data.length < localState.max_row
      : true;

    return (
      localState.isNewTable &&
      !field.read_only &&
      isAvailableLength &&
      field.format_rules_definition?.table_type !== "inline"
    );
  };

  const actions: Action[] = [
    {
      label: (
        <span>
          <FontAwesomeIcon className="me-1" icon={faEdit} />
          Editar
        </span>
      ),
      type: "primary",
      visible:
        (!field.read_only || localState.no_allow_new_rows_on_edit) &&
        field.format_rules_definition?.table_type !== "inline",
      onAction: (row) => {
        dispatch(
          actionsModal.openModal({
            configName: "data",
            type: ModalTypes.TABLE_EDIT_MODAL,
            model: localState?.model,
            tableItems: localState.data,
            setTableItems,
            toggleHelper: () => {
              getFields({});
            },
            formState: { ...taskProcessData },
            getFormValue: getValue,
            item: row,
            headers: localState.tableHeaders,
            row_model: localState.row_model,
          }),
        );
      },
    },
  ];

  if (!field.format_rules_definition?.no_allow_delete_rows_on_edit) {
    actions.push({
      label: (
        <span>
          <FontAwesomeIcon className="me-1" icon={faTrash} />
          Eliminar
        </span>
      ),
      type: "danger",
      visibleRow: (row) => !!(localState.data || row.id),
      onAction: (row) => {
        const filterData = [...localState.data].filter(
          (item) => item.id !== row.id,
        );
        setTableItems(filterData);

        if (setValueInput) {
          const newData: any[] = [];
          // console.log('filter', filterData)
          filterData.forEach((item) => {
            const newObj: any = {};
            Object.keys({ ...item }).forEach((key) => {
              if (typeof item[key] === "object" && item[key].props) {
                const value = getValues(`${item.id}_${key}`);
                if (value) {
                  newObj[key] = value;
                }
              } else {
                newObj[key] = item[key];
              }
            });

            newData.push(newObj);
          });

          // console.log('new data', newData)
          setValue(field.name, newData);
          setValueInput(field.name, newData);
        }
      },
    });
  }

  useIgnoreFirstEffect(() => {
    let subscription = watch((obj, { name, type }) => {
      if (name) {
        // console.count("subscription")
        // Check when an item change to update table list

        const value = obj[name];
        let list: any[] = obj["list"] ? obj["list"] : [];
        const id = name.split("_")[0] ?? "";
        const fieldName = name.split("_").slice(1).join("_") ?? "";
        const item = localState.data.find((nItem) => nItem.id === id);

        if (item) {

          if (list.some((listItem) => listItem.id === id)) {
            list = list.map((listItem) => {
              if (listItem.id === id) {
                listItem[fieldName] = value;
              }

              return listItem;
            });
          } else {
            const nItem: { [key: string]: string | number } = {};

            Object.keys(item)
              .filter((key) => typeof item[key] !== "object")
              .forEach((key) => {
                if(field.format_rules_definition?.remove_table_props && field.format_rules_definition?.remove_table_props.includes(key)){

                }else{
                  nItem[key] = item[key];
                }
              });


            list.push({ ...nItem, [fieldName]: value });
          }

          let taskProcessList: any[] = []
          if (taskProcessData[field.name] && IsJsonString(taskProcessData[field.name])) {
            if (Array.isArray(JSON.parse(taskProcessData[field.name]))) {
              taskProcessList = JSON.parse(taskProcessData[field.name]);
            }

          }

          list = list.map(listItem => {
            if (taskProcessList.length > 0){
              if (field.format_rules_definition && field.format_rules_definition.table_row_accessor_key ){
                const nItem = taskProcessList.find(taskItem => taskItem[field.format_rules_definition.table_row_accessor_key as string]
                    === listItem[field.format_rules_definition.table_row_accessor_key as string])
                if (nItem && nItem._KEY){
                  listItem["_KEY"] = nItem._KEY
                }
              }
            }

            return listItem
          })

          // console.log('list', list)
          setValue("list", list);
          if (setValueInput) {
            setValueInput(field.name, list);
          }

          localState.tableHeaders
            .filter((header) => header.summarize)
            .forEach((header) => {
              setValue(header.name, getTotalColumn(header, list));
            });
        }
      }
    });

    return () => subscription?.unsubscribe();
  }, [watch, localState.data, providerItems, taskProcessData]);

  React.useEffect(() => {
    // console.count("field format rules")
    const getInlineFields = async () => {
      if (field.format_rules_definition?.columns_labels) {
        dispatchForm({ name: "isLoading", value: State.PENDING });
        const listProviders =
          field.format_rules_definition?.columns_labels?.filter(
            (column) => column.type === "list_provider",
          );
        if (listProviders.length > 0) {
          const promisesListProvider = listProviders.map((provider) =>
            getProviderById(parseInt(provider.list_provider as string)),
          );
          const promisesDataProvider = listProviders.map((provider) =>
            getAllDataByProvider({
              provider_id: provider.list_provider as string,
            }),
          );

          const res = await Promise.all(promisesDataProvider)
            .then((res) => res as ProviderOptionsResponse[])
            .then((res) => res);
          const resList = await Promise.all(promisesListProvider)
            .then((res) => res as ProviderOptionsResponse[])
            .then((res) => {
              return res.map((provider) => provider.item);
            });

          const items = res.reduce((arr: any[], response) => {
            const provider: TableFormColumn | undefined = listProviders.find(
              (column) =>
                column.list_provider ===
                response?.provider_response?.id?.toString(),
            ) as TableFormColumn;

            if (provider) {
              const fieldList = taskProcessData[field.name];
              let list: any[] = [];

              if (fieldList && IsJsonString(fieldList)) {
                if (Array.isArray(JSON.parse(fieldList))) {
                  list = JSON.parse(fieldList);
                }
              }



              if (
                list.length > 0 &&
                response.items &&
                response.items.length > 0 &&
                list.length !== response.items.length
              ) {

                response.items = response.items.filter((item: any) => {
                  return list.some((fieldItem: any) => {
                    return fieldItem._KEY === item._KEY;
                  });
                });

                setProviderItems(response.items);
              }else{
                if (response.items && response.items.length > 0) {
                  setProviderItems(response.items);
                }
              }

              getSortedOptionsByRules({
                options: response.items,
                condition_order_by:
                  field.format_rules_definition.condition_order_by ?? [],
                sort_type: field.format_rules_definition?.sort_type,
              }).forEach((item: any, index: number) => {
                // console.log('item', item)
                const obj: {
                  [key: string]: string | number | JSX.Element | null;
                } = { id: item._KEY ?? index.toString() };

                  if (item._KEY) {
                    obj["_KEY"] = item._KEY;
                  }



                for (const column_label of field.format_rules_definition
                  .columns_labels) {
                  if (
                    column_label.format_rules &&
                    column_label.format_rules.columns_labels
                  ) {
                    for (const subColumnLabel of column_label.format_rules
                      .columns_labels) {
                      if (subColumnLabel.type === "image") {
                        const src = getObjValueInDeep(
                          item,
                          subColumnLabel.name,
                        );
                        obj[subColumnLabel.name] = (
                          <div>
                            {src && (
                              <img
                                src={src}
                                className="img-fluid"
                                style={{ maxHeight: "60px" }}
                                alt={subColumnLabel.name}
                              />
                            )}
                          </div>
                        );
                      } else {
                        if (column_label.sub_type === "SELECT") {
                          let defaultValue = "";
                          const item = list.find(
                            (item: any) =>
                              item.id === obj.id && item[subColumnLabel.name],
                          );

                          if (item && item[subColumnLabel.name]) {
                            defaultValue = item[subColumnLabel.name];
                          }

                          obj[subColumnLabel.name] = (
                            <TableTaskContext.Provider
                              value={{
                                control,
                                watch,
                                setValue,
                                task,
                                getFormValue: getValues,
                              }}
                            >
                              <CrmOptionsInputComponent
                                externalDefaultValue
                                field={
                                  {
                                    ...column_label,
                                    default_value: defaultValue,
                                    name: obj.id + "_" + subColumnLabel.name,
                                    single_value: true,
                                    list_provider: resList.find(
                                      (provider) =>
                                        provider.id ===
                                        parseInt(
                                          column_label.list_provider as string,
                                        ),
                                    ),
                                    format_rules_definition:
                                      (column_label.format_rules as FieldFormatRules) ??
                                      undefined,
                                  } as any as Field
                                }
                                contextForm={TableTaskContext}
                              />
                            </TableTaskContext.Provider>
                          );
                        } else {
                          if (subColumnLabel.name?.includes(".")) {
                            const keyName = `${
                              subColumnLabel.name.split(".")[0]
                            }._KEY`;
                            obj[subColumnLabel.name.split(".")[0]] =
                              getObjValueInDeep(item, keyName) ??
                              item[subColumnLabel.name.split(".")[0]] ??
                              "";
                          }

                          obj[subColumnLabel.name] =
                            subColumnLabel.compound_name
                              ? getCompoundName({
                                  item,
                                  custom_compound_name:
                                    subColumnLabel.compound_name,
                                })
                              : getObjValueInDeep(item, subColumnLabel.name);

                          if (
                            obj[subColumnLabel.name] &&
                            !subColumnLabel.name?.includes(".")
                          ) {
                            setValue(
                              obj.id + "_" + subColumnLabel.name,
                              subColumnLabel.key_from_value
                                ? item._KEY
                                : obj[subColumnLabel.name],
                            );
                            // console.log("item key", item._KEY)
                            // console.log('\n\n\n\n')
                            // setValue(obj.id + "_" + subColumnLabel.name, obj[subColumnLabel.name])
                          }
                        }
                      }
                    }
                  } else {
                    const calculateFields =
                      field.format_rules_definition.columns_labels.filter(
                        (column) =>
                          column.calculated &&
                          column.default &&
                          column.default.includes(column_label.name),
                      );

                    if (calculateFields.length === 0) {
                      if (!column_label.name?.includes(".")) {
                        console.log('list', list)
                        const item = list.find(
                          (item: any) =>
                          {
                            console.log('item', item)
                            return (item.id === obj.id || item._KEY === obj.id || (field.format_rules_definition?.table_row_accessor_key &&  item[field.format_rules_definition?.table_row_accessor_key] === obj.id)) && item[column_label.name]
                          },
                        );

                        if (item) {
                          setValue(
                            obj.id + "_" + column_label.name,
                            item[column_label.name],
                          );
                        }
                      }
                    }



                    const isCheckbox = column_label.type === "checkbox";


                    obj[column_label.name] = (
                        <div className={`${isCheckbox ? "d-flex justify-content-center" : ""}`}>
                      <Controller
                        name={obj.id + "_" + column_label.name}
                        control={control}
                        defaultValue={isCheckbox ? false :""}
                        render={({ field: { onChange, value } }) => {
                          
                          return (
                            <input
                              type={column_label.type ?? "text"}
                              className={isCheckbox ? "": "form-control"}
                              readOnly={column_label.read_only}
                              checked={isCheckbox ? value : false}
                              onChange={(event) => {

                                if (isCheckbox){
                                  onChange(event.currentTarget.checked)
                                }else{
                                  if (calculateFields.length > 0) {
                                    calculateFields.forEach((calculateField) => {
                                      let defaultValue = calculateField.default;

                                      if (defaultValue) {
                                        // Get value for each variable of the expression.
                                        defaultValue =
                                            getVariableDefaultValue(defaultValue);
                                        const varList =
                                            defaultValue.split(/[\/,*+-]+/);
                                        varList.forEach((variable) => {
                                          variable = variable.trim();
                                          let value = "";

                                          if (variable === column_label.name) {
                                            value = event.currentTarget.value;
                                          } else {
                                            value = getValues(
                                                obj.id + "_" + variable,
                                            );
                                          }
                                          if (
                                              !containsNumbers(variable) &&
                                              value &&
                                              defaultValue
                                          ) {
                                            defaultValue =
                                                defaultValue.replaceAll(
                                                    variable,
                                                    value as string,
                                                );
                                          }
                                        });

                                        setValue(
                                            obj.id + "_" + calculateField.name,
                                            evalExpression(defaultValue) ??
                                            defaultValue,
                                        );
                                      }
                                    });
                                  }

                                  onChange(event);
                                }


                              }}
                              value={value}
                              // value={column_label.default ? control._formValues[obj.id+"_"+getVariableDefaultValue(column_label.default).split(/[\/,*+-]+/)[0].trim()]  : value}
                            />
                          );
                        }}
                      />
                        </div>
                    );
                  }
                }

                arr.push(obj);
              });
            }
            return arr;
          }, []);


          setTableItems(items);
          dispatchForm({ name: "isLoading", value: State.RESOLVED });
        }
      }
    };

    if (field.format_rules_definition?.table_type === "inline") {
      getInlineFields();
    }
  }, [field.format_rules_definition, taskProcessData]);

  const getSummarizeByColumn = (column: string, list?: any[]) => {
    return (
      (list ?? getValues("list"))?.reduce(
        (total: number, item: { [key: string]: number | string }) => {
          let value = 0;

          if (typeof item[column] === "string") {
            value = parseFloat(item[column] as string);
          } else {
            value = item[column] as number;
          }

          if (!isNaN(value)) {
            total += value;
          }

          return total;
        },
        0.0,
      ) ?? 0
    );
  };

  const getDefaultValueColumn = ({
    defaultValue,
    type,
    row,
    list,
  }: {
    defaultValue: string;
    type: "column" | "row";
    row?: { [key: string]: number | string };
    list?: any[];
  }) => {
    if (
      defaultValue &&
      isDefaultVarExpression(defaultValue as string) &&
      isVarExpression(defaultValue as string)
    ) {
      let default_value = getVariableDefaultValue(defaultValue);
      // Split the default value to get the variables -> ["income_y1", "kilos"]
      // let new_value = default_value;

      const varList = default_value.split(/[\/,*+-]+/);
      varList.forEach((variable) => {
        variable = variable.trim();
        // Get value for each variable of the expression.
        if (!containsNumbers(variable)) {
          const value = {
            column: getSummarizeByColumn(variable, list),
            row: row
              ? row[variable]
                ? parseFloat(row[variable] as string)
                : 0
              : 0,
          }[type];

          default_value = default_value.replace(variable, value);
        }
      });

      return evalExpression(default_value);
    }

    return "";
  };

  const getTotalColumn = (header: TableFormColumn, list?: any[]) => {
    let listTotal = list ?? getValues("list");

    if (listTotal) {
      let value = 0;

      if (
        header.default &&
        typeof header.default === "string" &&
        isDefaultVarExpression(header.default as string) &&
        isVarExpression(header.default as string)
      ) {
        value = getDefaultValueColumn({
          defaultValue: header.default,
          type: "column",
          list: listTotal,
        });
      } else {
        value = getSummarizeByColumn(header.name, listTotal);
      }

      return (
        (
          {
            currency:
              "$" +
              new Intl.NumberFormat("es-ES", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }).format(value as number),
          } as { [key: string]: string }
        )[header.sub_type ?? ""] ?? value
      );
    }

    return 0;
  };

  return (
    <div className="d-flex flex-column grid-full-column mb-2">
      <label
        className="form-control-label"
        htmlFor={field.name + "_" + field.id}
      >
        {field.label}
      </label>

      <div className={`${localState.isInlineTable ? "card p-3" : ""}`}>
        {localState.isInlineTable && (
          <div className="mx-3">
            <input
              type="text"
              placeholder={t("search")}
              className="form-control"
              onChange={(event) => {
                setSearch(event.currentTarget.value);
              }}
            />
          </div>
        )}

        <div
          className={`${localState.isInlineTable ? "overflow-auto" : ""}`}
          style={localState.isInlineTable ? { maxHeight: "500px" } : {}}
        >
          <CustomTableComponent
            actions={actions}
            pagination={
              field.list_provider
                ? field.list_provider && localState.data.length > 0
                : false
            }
            showSizeChanger={false}
            onChangePage={(page) => paginationChange(page)}
            columns={
              localState.tableHeaders
                ?.filter((header) => !header.hide)
                .map((header) => ({
                  header: header.label,
                  name: header.name,
                  type: header.table_type ?? "Custom",
                  customShowColumn: (value) =>
                    getValueFromField({
                      data: value,
                      property: header.name,
                    }),
                })) ?? []
            }
            data={
              localState.data
                ? [...localState.data].filter((item) => {
                    if (search) {
                      const nItem = { ...item };
                      Object.keys(nItem).forEach((key) => {
                        if (
                          typeof nItem[key] === "object" &&
                          nItem[key].props
                        ) {
                          delete nItem[key];
                        }
                      });
                      return JSON.stringify(nItem)
                        .toLowerCase()
                        .includes(search.toLowerCase());
                    }

                    return true;
                  }) ?? []
                : []
            }
            loading={localState.isLoading === State.PENDING}
          />
        </div>

        {localState.isInlineTable && (
          <div className="d-flex flex-column">
            {(localState.tableHeaders ?? [])
              .filter((header) => header.summarize)
              .map((header) => {
                return (
                  <div key={header.name}>
                    <span className="fw-bold">{header.label}:</span>
                    <input
                      type="text"
                      className="border-0"
                      defaultValue={getTotalColumn(header) ?? 0}
                      {...register(header.name)}
                    />
                  </div>
                );
              })}
          </div>
        )}
      </div>

      {showAddButton() && !localState.no_allow_new_rows_on_edit && (
        <div className="my-2 text-right">
          <Button
            size="sm"
            onClick={() => {
              dispatch(
                actionsModal.openModal({
                  configName: "data",
                  type: ModalTypes.TABLE_EDIT_MODAL,
                  row_model: localState.row_model,
                  tableItems: localState.data,
                  setTableItems,
                  getFormValue: getValue,
                  toggleHelper: () => {
                    getFields({});
                  },
                  formState: { ...taskProcessData },
                  headers: localState.tableHeaders,
                  model: localState.model,
                }),
              );
            }}
            color={"success"}
          >
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        </div>
      )}
    </div>
  );
};
