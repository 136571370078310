import { useState } from "react";
import useAsyncEffect from "./useAsyncEffect";

const useMemoAsync = (callback: () => any, deps: any[]) => {
  const [result, setResult] = useState();

  useAsyncEffect(async () => {
    const res = await callback();
    setResult(res);
  }, [...deps]);

  return result as any;
};

export default useMemoAsync;
