import React, {useContext, useRef, useState} from 'react';
import {AnalyticQueryAction} from "../../../../types/Analytic";
import FilterActionComponent from "../FilterActionComponent";
import PopoverComponent from "../../../Shared/PopoverComponent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown, faAngleLeft, faInfoCircle, faPlusCircle, faTrash} from "@fortawesome/free-solid-svg-icons";
import useTranslate from "../../../../hooks/useTranslate";
import ButtonComponent from "../../../Shared/ButtonComponent";
import {Controller, useFieldArray, useForm} from "react-hook-form";
import styled from "styled-components";
import SuggestedTransformInput from "./SuggestedTransformInput";
import {ReportGeneratorContext} from "../../ReportGeneratorComponent";

type Props = {
    action: AnalyticQueryAction
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;

  @keyframes fadeInDown {
    0% {
      opacity: 0;
      transform: translateY(-1.25em);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  animation-name: fadeInDown;
  animation-duration: 0.5s;

`

type Transformation = {
    name: string
    index: number
    transformation: string
}

const DataTransformComponent = ({action}: Props) => {
    const {t} = useTranslate('common');

    const {
        getActionColumns
    } = useContext(ReportGeneratorContext);
    const [transformation, setTransformation] = useState('')
    const [showFilters, setShowFilters] = useState(false)
    const [showTransformations, setShowTransformations] = useState(false)
    const {control, register, watch} = useForm<{ transformations: Transformation[], name: string }>();
    const divRef = useRef<HTMLDivElement>(null);
    const {fields, append, remove, replace} = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: "transformations", // unique name for your Field Array
    });
    // const [transformations, setTransformations] = useState<>([])


    React.useEffect(() => {
        if (action.transformation && !action.columns) {
            // setTransformation((action.transformation as string).replaceAll('pdf.', ''));
            setTransformation(typeof action.transformation !== "string" ? action.transformation.toString() : action.transformation);
        } else {
            if (action.columns && action.columns.length > 0) {
                const newColumns = action.columns.map((column: { [key: string]: any }) => {


                    const obj: { [key: string]: string } = {}
                    Object.keys(column).forEach(key => {
                        obj.name = key
                        obj.transformation = column[key]
                    })

                    return obj
                })

                replace(newColumns)
            }
        }
    }, [action]);


    // Callback version of watch.  It's your responsibility to unsubscribe when done.
    React.useEffect(() => {
        const subscription = watch((obj) => {
            if (obj.transformations && obj.transformations!?.length > 0) {

                const newColumns = obj.transformations.sort((a, b) => (b?.index ?? 0) - (a?.index ?? 0)).reduce((arr: {
                    [key: string]: string
                }[], transformation) => {
                    if (transformation?.transformation && transformation?.name) {
                        arr.push({
                            [transformation.name]: transformation.transformation
                        })
                    }

                    return arr
                }, [])

                if (transformation) {
                    newColumns.unshift({[obj.name ?? action.name ?? ""]: transformation})
                }

                action.columns = newColumns
                delete action.transformation
                delete action.name
            } else {
                // delete action.transformation
                if (obj.name) {
                    action.name = obj.name
                }

                if (transformation) {
                    action.transformation = transformation
                }

                delete action.columns
            }
        });
        return () => subscription.unsubscribe();
    }, [watch, transformation, action]);


    return <div>

        <div ref={divRef}
             className="d-flex align-items-lg-center gap-3 flex-column flex-lg-row pb-2 ">
            <div className="d-flex flex-column">
                <span>{t('name')}</span>
                <input type="text" className="form-control" defaultValue={action.name || ''}
                       placeholder={t('name')}
                       {...register("name")}/>

            </div>
            <div className="d-flex flex-column flex-grow-1 position-relative">
                                <span>{t('report:transformation')}
                                    <PopoverComponent buttonClassName={"m-0 p-0"}
                                                      label={<FontAwesomeIcon className="text-primary m-0"
                                                                              icon={faInfoCircle}/>}
                                                      trigger="hover" placement="top"
                                                      id={"Transformation" + "_pop_info"}>
                                        <span>{t("custom-message:prefix_pdf")}</span></PopoverComponent></span>


                <SuggestedTransformInput transformation={transformation}
                                         setTransformation={setTransformation}
                                         columns={getActionColumns(action).map(option => option.options).flat()}
                />

            </div>
        </div>

        <div className="d-flex justify-content-end mb-2">
            <ButtonComponent icon={faPlusCircle} label={`${t("add")} ${t("transformation")}`} onClick={() => {
                setShowTransformations(true)
                append({
                    name: "sample_name_" + Math.max(0, fields.length),
                    index: Math.max(1, fields.length),
                    transformation: ""
                })
            }}/>
        </div>

        <div className="text-end mb-2 gap-2 text-primary">
            <span className="pointer underline mr-1"
                  onClick={() => setShowTransformations(prevState => !prevState)}>{`${t(showTransformations ? "hide" : "show")} ${t("transformations")}`}
            </span>

            <span>
                <FontAwesomeIcon icon={showTransformations ? faAngleDown : faAngleLeft}/>
            </span>
        </div>


        {showTransformations && <Container>
            {fields.map((field, index) => (
                <div className="d-flex align-items-center gap-3" key={field.id}>

                    <div className="d-flex flex-column">
                        <span>{t('name')}</span>
                        <input type="text" className="form-control" {...register(`transformations.${index}.name`)} />
                    </div>

                    <div className="d-flex flex-column flex-grow-1 position-relative">
                                <span>{t('report:transformation')}
                                    <PopoverComponent buttonClassName={"m-0 p-0"}
                                                      label={<FontAwesomeIcon className="text-primary m-0"
                                                                              icon={faInfoCircle}/>}
                                                      trigger="hover" placement="top"
                                                      id={"Transformation" + "_pop_info"}>
                                        <span>{t("custom-message:prefix_pdf")}</span></PopoverComponent></span>

                        <Controller
                            render={({field}) => <SuggestedTransformInput
                                columns={getActionColumns(action).map(option => option.options).flat()}
                                transformation={field.value}
                                setTransformation={field.onChange}/>
                            }
                            name={`transformations.${index}.transformation`}
                            control={control}
                        />

                    </div>


                    {/*<input type="text" className="form-control"*/}
                    {/*       placeholder="transformation..." {...register(`transformations.${index.tsx}.transformation`)} />*/}

                    <ButtonComponent label={t("remove")} color={"danger"} onClick={() => remove(index)} icon={faTrash}/>
                </div>

            ))}</Container>}


        <hr className="w-100"/>

        <div className="text-end gap-2 text-primary">
            <span className="pointer underline mr-1"
                  onClick={() => setShowFilters(prevState => !prevState)}>{`${t(showFilters ? "hide" : "show")} ${t("filters")}`}
            </span>

            <span>
                <FontAwesomeIcon icon={showFilters ? faAngleDown : faAngleLeft}/>
            </span>
        </div>


        {showFilters && <FilterActionComponent action={action}/>}
    </div>
};

export default DataTransformComponent;