import React, {useState} from 'react';
import ReportComponent from './ReportComponent';
import FilterTableDataComponent from '../Shared/FilterTableDataComponent/FilterTableDataComponent';
import {State} from '../../types/State';
import {MultiReport} from "../analytics/AnalyticsPageComponent";

type Props = {
    reports: MultiReport[]
    columns: number;
    isLoading: boolean
    filtersByParent: boolean
    setLoading: (state: State) => void;
    filters: { table_name: string, label: string }[];
}

const MultiReportsComponent = ({reports, columns, isLoading, filters, setLoading, filtersByParent}: Props) => {

    const [localReports, setLocalReports] = useState(reports);
    const [copyLocalReports, setCopyLocalReports] = useState(reports);
    const [tab, setTab] = useState('0')
    React.useEffect(() => {
        setLocalReports(reports);
        setCopyLocalReports(reports);
    }, [reports]);


    return (
        <div className="d-flex flex-column">
            <FilterTableDataComponent filterList={filters}
                                      isMultiData
                                      setLoading={setLoading}
                                      data={copyLocalReports}
                                      isLoading={isLoading}
                                      setData={(data) => setLocalReports(data)}/>
            <div className="row">
                {localReports.map((report, index) => (
                    <div key={report.name + '_' + index}
                         className={`py-3 col-12 col-lg-${(12 / columns) * report.ncolumn}`}>
                        <h3 className="text-center">{report.name}</h3>
                        <ReportComponent showConfig data={report.data} report={report} tab={tab} setTab={setTab}
                                         reportProps={{
                                             filters: !filtersByParent ? report.filters : [],
                                             columns: report.columns
                                         }}
                                         isLoading={isLoading}/>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default MultiReportsComponent;
