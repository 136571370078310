import React from 'react';
import useTranslate from '../../../hooks/useTranslate';
import CreatableSelectComponent from "../FieldComponents/CreatableSelectComponent";

type Props = {
  updateFetchModel: ({models, isDeepFetch}: {
    models: { label: string, value: any }[], isDeepFetch?: boolean, isReverseFetch?: boolean
  }) => void
  defaultFetch: string[]
  fetchModels: string[]
  reverseFetchModels: string[]
  isLoading: boolean
  deepModelsFetch: string[]
  model: string;

}

const QueryFetchComponent = ({
                               defaultFetch,
                               fetchModels,
                               updateFetchModel,
                               isLoading,
                               deepModelsFetch,
                               model,
                               reverseFetchModels
                             }: Props) => {

  const {t} = useTranslate('common');



  const getFetchValue = () => {
    return defaultFetch?.filter(item => !item.includes('.')).map(item => ({label: item, value: item})) ?? [];
  };

  const getDeepFetchValue = () => {
      // console.log("defaultFetch", defaultFetch)
    return defaultFetch?.filter(item => item.includes('.') ? fetchModels.some(fetchModel => item.split(".")[0] === fetchModel)  && item.split(".")[1] !== model : false).map(item => ({label: item, value: item})) ?? [];
  };

  const getReverseFetchValue = () => {
    return defaultFetch?.filter(item => item.includes('.') ? item.split('.')[1] === model : false ).map(item => ({
      label: item,
      value: item
    })) ?? [];
  };


  const getAddAllButton = (defaultModels: { label: string; value: string; }[], models: string[], isDeepFetch=false, isReverseFetch=false) => {
      return defaultModels.length !== models.length && <span className="text-link pointer underline text-end" onClick={() => {
          updateFetchModel({isDeepFetch, isReverseFetch, models: models.map(fetch => ({label: fetch, value: fetch}))})
      }}>{t('add')} {t('all')}</span>
  }

  return (
    <div className="row">
      <div className="d-flex flex-column col-xs-12 col-lg-6">
        <label>{t('fetch')}: </label>
        <CreatableSelectComponent isMulti id={'fetch_models'}
                                  value={getFetchValue()}
                                  onChange={(models) => updateFetchModel({models})}
                                  disabled={fetchModels.length === 0}
                                  name={'fetch_models'}
                                  options={fetchModels.map(model => ({
                                    label: model,
                                    value: model
                                  })) ?? []}/>
          {getAddAllButton(getFetchValue(), fetchModels)}
      </div>

      {model && <div className="d-flex flex-column col-xs-12 col-lg-6">
        <label>{t('deep_fetch')}: </label>
        <CreatableSelectComponent isMulti id={'deep_fetch_models'}
                                  onChange={models => updateFetchModel({models, isDeepFetch: true})}
                                  value={getDeepFetchValue()}
                                  loading={isLoading}
                                  disabled={fetchModels.length === 0 || isLoading}
                                  name={'deep_fetch_models'}
                                  options={deepModelsFetch.map(fetch => ({label: fetch, value: fetch}))}/>
          {getAddAllButton(getDeepFetchValue(), deepModelsFetch, true, false)}
      </div>}

      <div className="d-flex flex-column col-xs-12 col-lg-6">
        <label>{t('reverse_fetch')}: </label>
        <CreatableSelectComponent isMulti id={'deep_fetch_models'}
                                  onChange={models => updateFetchModel({isReverseFetch: true, models})}
                                  value={getReverseFetchValue()}
                                  loading={isLoading}
                                  disabled={reverseFetchModels.length === 0 || isLoading || !model}
                                  name={'reverse_fetch_models'}
                                  options={reverseFetchModels.map(fetch => ({label: fetch, value: fetch}))}/>
          {getAddAllButton(getReverseFetchValue(), reverseFetchModels, false, true)}
      </div>
    </div>

  );
};

export default QueryFetchComponent;
