import * as React from 'react';
import {useContext} from 'react';
import {AnalyticQueryAction} from "../../../types/Analytic";
import CreatableSelectComponent from "../../Shared/FieldComponents/CreatableSelectComponent";
import {ReportContext} from "../NewReportGeneratorComponent";
import {StringOption} from "../../../types/Select";

type Props = {
    index: number
    action: AnalyticQueryAction
};
const NewSelectActionComponent = ({action, index}: Props) => {
    const {getActionColumns, setQueryAction, getLoadingAndDisabledAction} = useContext(ReportContext)

    return (
        <div>
            <CreatableSelectComponent isMulti closeMenuOnSelect={false}
                                      className="text-start"
                                      onChange={value => {
                                          setQueryAction({
                                                  ...action,
                                                  columns: value.map((item: StringOption) => item.value)
                                              }, index
                                          )
                                      }} menuPosition={'absolute'}
                                      id={'action_type_' + action.type + '_' + index} name={''}
                                      value={(action?.columns as string[])?.map(column => ({
                                          label: column,
                                          value: column
                                      }))}
                                      disabled={getLoadingAndDisabledAction({action, index})}
                                      loading={getLoadingAndDisabledAction({action, index})}
                                      options={getActionColumns(action, index)}/>
        </div>
    );
};

export default NewSelectActionComponent