import React, {useState} from 'react';
import {Field} from '../../../types/Field';
import {Button} from 'reactstrap';
import {SBXFile} from '../../../types/Sbx';
import useAsyncEffect from '../../../hooks/useAsyncEffect';
import {deleteSbxFileService, downloadFileService, getFile} from '../../../services/UtilsService';
import {useDropzone} from 'react-dropzone';
import Image from 'next/image';
import {bytesToSize, error, getIconFile, success} from '../../../utils';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSearch, faSpinner, faTimesCircle, faTrash} from '@fortawesome/free-solid-svg-icons';
import useTranslate from '../../../hooks/useTranslate';
import {State} from '../../../types/State';
import {useDispatch} from 'react-redux';
import {actionsModal, ModalTypes} from '../../../store/Modal/Slice';
import {openPreview} from "./MultiFilesInputComponent";

type Props = {
  field: Field
  getValue: () => string
  loadingTask?: State
  setValueField: (val: any) => void
}

const cacheFile: { [key: string]: SBXFile | File } = {}

const FileComponent = ({field, setValueField, getValue, loadingTask}: Props) => {
  const [loading, setLoading] = useState<State>(State.IDLE);
  const {open, acceptedFiles, getRootProps, getInputProps,} = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true
  });
  const dispatch = useDispatch();
  const {t} = useTranslate('common');
  const [file, setFile] = useState<SBXFile | File | null>(null);

  useAsyncEffect(async () => {
    const keyFile = getValue();

    if (keyFile) {
      setLoading(State.PENDING);
      const response = await getFile(keyFile);
      if (response?.success) {
        const fileName = (response as SBXFile).name.includes("_") ? (response as SBXFile).name.split("_")?.at(-1) ?? (response as SBXFile).name : (response as SBXFile).name
        if (fileName) {
          (response as SBXFile).name = fileName
        }
        setFile(response as SBXFile);
        setLoading(State.RESOLVED);
      } else {
        setLoading(State.REJECTED);
      }
    }

  }, []);

  React.useEffect(() => {
    if (acceptedFiles.length > 0) {
      if (acceptedFiles[0].name) {
        cacheFile[field.name] = acceptedFiles[0]
      }

      setFile(acceptedFiles[0]);
    }
  }, [acceptedFiles]);

  React.useEffect(() => {
    if (file) {
      setValueField({label: field.label, file});
    }
  }, [file]);

  React.useEffect(() => {
    if (loadingTask && loadingTask === State.REJECTED && field?.name && cacheFile[field.name]) {
      setFile(cacheFile[field.name])
    }
  }, [loadingTask]);

  const deleteFile = () => {

    if ((file as SBXFile).url) {
      dispatch(actionsModal.openModal(
        {
          type: ModalTypes.CONFIRM,
          onConfirm: async () => {
            dispatch(actionsModal.closeModal({type: ModalTypes.CONFIRM}));
            setLoading(State.PENDING);
            const response = await deleteSbxFileService((file as SBXFile).file_key);
            if (response?.success) {
              success(t('success-delete'));
              setFile(null);
              setLoading(State.RESOLVED);
              setValueField(null);
            } else {
              setLoading(State.REJECTED);
              error(t('error-delete'));
            }
          },
          message: <p>{t('custom-message:warn-delete')} <b>{file?.name}</b>?</p>,
          title: <span><FontAwesomeIcon className="me-2" icon={faTrash}/>{t('delete')} </span>,
        }));
    } else {
      setFile(null);
      setValueField(null);
    }

  };

  return <div className={"d-flex flex-column"}>
    <div className="d-flex align-items-center rounded p-1 border overflow-auto">
      {!field.read_only &&
        <div {...getRootProps()} className="me-2">
          <input {...getInputProps()} accept={field?.format_rules_definition?.file_type ? field?.format_rules_definition?.file_type.map(type => "." +type).join(", ") : ""} />
          <Button color={'primary'} type={'button'} onClick={open}>
            {t('select_file')}
          </Button>
        </div>
      }

      {file ? <div className="d-flex justify-content-between flex-grow-1">
          <div className="d-flex align-items-center">
            <div className="me-2">
              <Image
                src={getIconFile(file?.name?.split('.')[1] ?? '')}
                alt="dropzone-background"
                width={40}
                height={40}
              />
            </div>
            <div className={`d-flex flex-column ${(file as SBXFile)?.url ? 'underline pointer' : ''}`} key={file.name}
                 onClick={(file as SBXFile)?.url ? async () => downloadFileService((file as SBXFile).url, file.name) : undefined}>
              <span className="fw-bold">{file.name}</span>
              <small className="text-gray">{bytesToSize(file.size)}</small>
            </div>
          </div>

          <div className="d-flex align-items-center justify-content-end gap-3 mx-2">
            <FontAwesomeIcon title="Preview pdf file" icon={faSearch} className="pointer" size="2x"
                             onClick={() => openPreview(file as SBXFile, dispatch)}/>
            <FontAwesomeIcon icon={faTimesCircle} className="pointer" onClick={deleteFile}
                             color={'red'}/>
          </div>
        </div>

        :

        loading === State.PENDING ? <div className="w-100 text-center"><FontAwesomeIcon icon={faSpinner} pulse/></div> :
          <span>{t('custom-message:no-selected-file')}</span>
      }
    </div>
  </div>;

};

export default FileComponent;
