import * as React from 'react';
import {useContext, useState} from 'react';
import {ActionButton, ReportContext} from "./NewReportGeneratorComponent";
import NewTruncateReportActionComponent from "./NewTruncateReportActionComponent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendar, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import useTranslate from "../../hooks/useTranslate";
import {IsJsonString} from "../../utils";
import {TruncateReport} from "../../types/Analytic";

const ReportTimeDataComponent = () => {
    const {query, setQuery} = useContext(ReportContext)
    // const [truncate, setTruncate] = useState<TruncateReport | null>(null)
    // const onMount = React.useRef(false);
    const [visualize, setVisualize] = useState<{
        truncate: boolean,
    }>({
        truncate: false,
    })

    const {t} = useTranslate("common");

    React.useEffect(() => {
        if (query.truncate && (IsJsonString(query.truncate as string) && (query.truncate as TruncateReport)?.model || (query.truncate as TruncateReport)?.field)) {
            setVisualize(prevState => ({
                truncate: true,
            }))
        }
    }, [query]);

    return (
        <div className="card py-3">
            <div className="card-header mb-3">
                {t("report:final_actions")}
            </div>
            <div
                className="d-flex  align-items-center flex-wrap overflow-auto  px-3 gap-3">
                {!visualize.truncate && <ActionButton key={'truncate'} className="btn "
                                                      color={'#1b85b8'}
                                                      textColor={'white'}
                                                      onClick={() => {
                                                          setVisualize(prevState => ({
                                                              truncate: !prevState.truncate,
                                                          }))
                                                      }}>
                    <FontAwesomeIcon icon={faCalendar}/>
                    <span>{t("report:range_limit")}</span>
                </ActionButton>}
            </div>


            {visualize.truncate &&
                <div>
                    <div
                        className="card-header d-flex justify-content-between align-items-center flex-grow-1"
                        style={{color: "#1b85b8"}}>
                        <div className="d-flex align-items-center gap-2">

                            <FontAwesomeIcon icon={faCalendar} className="pointer"/>
                            <span>{t("report:range_limit")}</span>
                            <FontAwesomeIcon icon={faTimesCircle} className="pointer"
                                             onClick={() => {
                                                 setVisualize(prevState => ({
                                                     truncate: !prevState.truncate,
                                                 }))
                                                 setQuery(prevState => ({
                                                     ...prevState,
                                                     truncate: ""
                                                 }))
                                             }}/>
                        </div>
                    </div>

                    <NewTruncateReportActionComponent setTruncate={truncate1 => {
                        setQuery(prevState => ({
                            ...prevState,
                            truncate: truncate1
                        }))
                    }}/>
                </div>

            }

        </div>

    );
};

export default ReportTimeDataComponent