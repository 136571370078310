import {
    addTokenToAnalyticQuery,
    convertAnalyticFilterToSbx,
    convertDateToNumberDate,
    convertNumberDateToDate,
    IsJsonString,
    replaceConstVarWith,
    StaticConstVar,
    toast,
    uuidV4
} from './index';
import {
    AggAnalytic,
    AnalyticQuery,
    AnalyticQueryAction,
    BaseAnalyticQuery,
    ColumnsMetadata,
    Report,
    SourceFrom,
    TimeWindowAction,
    TruncateReport
} from "../types/Analytic";
import {Query} from "../components/Shared/QueryComponent/QueryComponent";
import {Condition, SbxModelField} from "../types/Sbx";
import {differenceInWeeks} from "date-fns";
import {executeAnalyticJson} from "../services/backend/AnalyticsService";
import {Column, CustomTableColumnType} from "../components/Shared/CustomTableComponent/CustomTableComponent";
import {getFilesDataByKeys} from "../services/UtilsService";
import {Content} from "../types/Folder/Content";
import {TrackEventMonitor} from "../classes/TrackEvent";

export const jsonClaims = (year: number, varietyKey?: string) => {
    const fromClaimDate = new Date(year, 0, 1);
    const toClaimDate = new Date(year, 12, 0);

    if (fromClaimDate.toISOString() && toClaimDate.toISOString()) {
        const json = {
            'actions': [
                {
                    'filter': '*',
                    'name': 'date_time',
                    'transformation': ' pd.to_datetime(pdf.request_claim_claim_date) ',
                    'type': 'transform'
                },
                {
                    'filter': '*',
                    'name': 'year',
                    'transformation': 'pdf.date_time.dt.strftime(\'%Y\')',
                    'type': 'transform'
                },
                {
                    'filter': '*',
                    'name': 'month',
                    'transformation': 'pdf.date_time.dt.strftime(\'%m\')',
                    'type': 'transform'
                },
                {
                    'filter': '*',
                    'name': 'total_claim',
                    'transformation': 'pdf.request_claim_cart_box_item_base_price * pdf.request_claim_total_units_to_be_claimed',
                    'type': 'transform'
                },
                {
                    'type': 'group_by',
                    'columns': [
                        'year',
                        'month',
                        'grower_company_name',
                        'product_group_common_name'
                    ],
                    'agg': {
                        'total_claim': [
                            'sum'
                        ],
                        'request_claim__KEY': [
                            'nunique'
                        ],
                        'request_claim_cart_box_item_product_group': [
                            'list'
                        ],
                        'variety_variety_name': [
                            'list'
                        ],
                        'request_claim_cart_box_item_variety': [
                            'list'
                        ]
                    }
                }
            ],
            'source': {
                'action': 'merge',
                'from': 'sbx-data',
                'in': 129,
                'with': 'request_claim',
                'filters': [
                    {
                        'logic_operator': 'and',
                        'filter_operator': '>',
                        'field': 'claim_date',
                        'value': fromClaimDate.toISOString()
                    },
                    {
                        'logic_operator': 'and',
                        'filter_operator': '<',
                        'field': 'claim_date',
                        'value': toClaimDate.toISOString()
                    },
                    {
                        'logic_operator': 'and',
                        'filter_operator': '=',
                        'field': 'state',
                        'value': 'Accepted'
                    },
                    {
                        'logic_operator': 'and',
                        'filter_operator': 'is not null',
                        'field': 'cart_box',
                        'value': null
                    }
                ],
                'fetch': [
                    'cart_box',
                    'cart_box_item'
                ],
                'sources': [
                    {
                        'in': 129,
                        'with': 'grower',
                        'from': 'sbx-data',
                        'main_column': 'request_claim_cart_box_grower',
                        'index_column': 'grower__KEY',
                        'merge_type': 'inner'
                    },
                    {
                        'in': 129,
                        'with': 'product_group',
                        'from': 'sbx-data',
                        'main_column': 'request_claim_cart_box_item_product_group',
                        'index_column': 'product_group__KEY',
                        'merge_type': 'inner'
                    },
                    {
                        'in': 129,
                        'with': 'variety',
                        'from': 'sbx-data',
                        'main_column': 'request_claim_cart_box_item_variety',
                        'index_column': 'variety__KEY',
                        'merge_type': 'inner'
                    }
                ]
            }
        };

        if (varietyKey) {
            json.source.filters.push({
                'logic_operator': 'and',
                'filter_operator': '=',
                'field': 'cart_box_item.variety',
                'value': varietyKey
            });
        }

        return json;
    }

    return {};
};

export const jsonClaimsV2 = (year: number, month: number) => {


    const fromClaimDate = new Date(year, month, 1);
    const toClaimDate = new Date(year, month + 1, 0);

    return {
        'actions': [
            {
                "filter": "*",
                "name": "date_time",
                "transformation": " pd.to_datetime(pdf.request_claim_claim_date) ",
                "type": "transform",
                "temporal_id": "1mi5w-kdq0n-CsK7E-ISGrR"
            },
            {
                "filter": "*",
                "name": "year",
                "transformation": "pdf.date_time.dt.strftime(\"%Y\")",
                "type": "transform",
                "temporal_id": "tQu3N-LAxh0-krtk3-Xiz19"
            },
            {
                "filter": "*",
                "name": "total_claim",
                "transformation": "pdf.request_claim_cart_box_item_base_price * pdf.request_claim_total_units_to_be_claimed",
                "type": "transform",
                "temporal_id": "EeEIe-SrBMr-EbM9K-qyH0M"
            },
            {
                "filter": "*",
                "name": "stems_sold",
                "transformation": "pdf.request_claim_cart_box_item_quantity * pdf.request_claim_cart_box_item_stems_per_bunch_stems_per_bunch",
                "type": "transform",
                "temporal_id": "EeEIe-SrBMr-EbM9K-qyH0M"
            },
            {
                "filter": "request_claim_cart_box_item_uom_uom == 'bunch'",
                "name": "request_claim_total_units_to_be_claimed",
                "transformation": "pdf.request_claim_total_units_to_be_claimed * pdf.request_claim_cart_box_item_stems_per_bunch_stems_per_bunch",
                "type": "transform",
                "temporal_id": "EeEIe-SrBMr-EbM9K-qyH01"
            },
            {
                "type": "group_by",
                "columns": [
                    "year",
                    "request_claim_cart_box_item_grower",
                    "request_claim_cart_box_grower_company_name",
                    "request_claim_cart_box_item_product_group_common_name",
                    "request_claim_cart_box_item_product_group__KEY"
                ],
                "agg": {
                    "request_claim__KEY": [
                        "nunique"
                    ],
                    "request_claim_cart_box__KEY": [
                        "list"
                    ],
                    "request_claim_cart_box_price": [
                        "list"
                    ],
                    "total_claim": [
                        "sum"
                    ],
                    "stems_sold": [
                        "sum"
                    ],
                    "request_claim_total_units_to_be_claimed": [
                        "sum"
                    ]
                },
                "temporal_id": "RYWet-RWe7H-xi2lJ-xTr90"
            },
            {
                "filter": "*",
                "name": "pk",
                "transformation": "pdf.request_claim_cart_box_item_grower.str.cat(pdf.request_claim_cart_box_item_product_group__KEY)",
                "type": "transform",
                "temporal_id": "2PQ1U-dE8BT-HzMkZ-8cY2H"
            },
            {
                "type": "merge",
                "main_column": "pk",
                "index_column": "pk2",
                "merge_type": "inner",
                "source": {
                    "in": 129,
                    "with": "cart_box",
                    "from": "sbx-data",
                    "action": "get",
                    "filters": [
                        {
                            "logic_operator": "and",
                            "filter_operator": ">",
                            "field": "purchase.customer_date",
                            "value": fromClaimDate.toISOString()
                        },
                        {
                            "logic_operator": "and",
                            "filter_operator": "<",
                            "field": "purchase.customer_date",
                            "value": toClaimDate.toISOString()
                        }
                    ],
                    "rev_fetch": [
                        "cart_box_item.cart_box"
                    ]
                },
                "actions": [
                    {
                        "type": "group_by",
                        "columns": [
                            "cart_box_cart_box_item_product_group",
                            "cart_box_cart_box_item_grower",
                            "cart_box__KEY"
                        ],
                        "agg": {
                            "cart_box_subtotal": [
                                "max"
                            ]
                        }
                    },
                    {
                        "filter": "*",
                        "name": "pk2",
                        "transformation": "pdf.cart_box_cart_box_item_grower.str.cat(pdf.cart_box_cart_box_item_product_group)",
                        "type": "transform"
                    },
                    {
                        "type": "group_by",
                        "columns": [
                            "pk2"
                        ],
                        "agg": {
                            "cart_box_subtotal_max": [
                                "sum"
                            ]
                        }
                    },
                    {
                        "type": "rename",
                        "renamed_columns": {
                            "cart_box_subtotal_max_sum": "total_sold"
                        }
                    }
                ],
                "temporal_id": "eSd6G-lG771-1Y9Mo-RnPtx"
            },
            {
                "filter": "*",
                "name": "claim_lost_percentage",
                "transformation": "(pdf.total_claim_sum / pdf.total_sold)",
                "type": "transform",
                "temporal_id": "YTFtT-XUOgV-Luk3P-RL5tK"
            }
        ],
        'source': {
            'action': 'get',
            'from': 'sbx-data',
            'in': 129,
            'with': 'request_claim',
            'filters': [
                // {
                //   'logic_operator': 'and',
                //   'filter_operator': '>',
                //   'field': 'cart_box.eta_date',
                //   'value': parseInt(convertDateToNumberDate(fromClaimDate))
                // },
                // {
                //   'logic_operator': 'and',
                //   'filter_operator': '<',
                //   'field': 'cart_box.eta_date',
                //   'value': parseInt(convertDateToNumberDate(toClaimDate))
                // },
                {
                    'logic_operator': 'and',
                    'filter_operator': '>=',
                    'field': 'settled_date',
                    'value': parseInt(convertDateToNumberDate(fromClaimDate))
                },
                {
                    'logic_operator': 'and',
                    'filter_operator': '<=',
                    'field': 'settled_date',
                    'value': parseInt(convertDateToNumberDate(toClaimDate))
                },
                {
                    'logic_operator': 'and',
                    'filter_operator': '=',
                    'field': 'state',
                    'value': 'Accepted'
                },
                {
                    'logic_operator': 'and',
                    'filter_operator': '=',
                    'field': 'arrived',
                    'value': true
                },
                {
                    'logic_operator': 'and',
                    'filter_operator': 'is not null',
                    'field': 'cart_box',
                    'value': null
                }
            ],
            'fetch': [
                "cart_box",
                "cart_box_item",
                "cart_box.grower",
                "cart_box_item.product_group",
                "cart_box_item.stems_per_bunch",
                "cart_box_item.uom"
            ]
        }
        // ,
        // "alias": {
        //   "key": "ibf_claim_dashboard_v1",
        //   "update": false,
        //   "ttl": 86400
        // }
    } as BaseAnalyticQuery;
};

export const jsonMostSoldVarieties = (year: number, month: number) => {

    const fromDate = new Date(year, month, 1);
    const toDate = new Date(year, month + 1, 0);

    return {
        'actions': [
            {
                'type': 'subset',
                'columns': [
                    'cart_box_item_product_group_common_name',
                    'cart_box_item_quantity',
                    'cart_box_item_price',
                    'cart_box_item_cart_box_eta_date',
                    'cart_box_item_variety_variety_name'
                ]
            },

            {
                'filter': '*',
                'name': 'charge_time',
                'transformation': 'pd.to_datetime( pdf.cart_box_item_cart_box_eta_date.astype(\'string\'), format=\'%Y%m%d\' ) ',
                'type': 'transform'
            },
            {
                'filter': '*',
                'name': 'year',
                'transformation': 'pdf.charge_time.dt.strftime(\'%Y\')',
                'type': 'transform'
            },
            {
                'filter': '*',
                'name': 'month',
                'transformation': 'pdf.charge_time.dt.strftime(\'%m\')',
                'type': 'transform'
            },
            {
                'filter': '*',
                'name': 'total_price',
                'transformation': 'pdf.cart_box_item_price * pdf.cart_box_item_quantity',
                'type': 'transform'
            },
            {
                'type': 'group_by',
                'columns': [
                    'cart_box_item_product_group_common_name',
                    'year',
                    'month',
                    'cart_box_item_variety_variety_name'
                ],
                'agg': {
                    'cart_box_item_quantity': [
                        'sum'
                    ],
                    'total_price': [
                        'sum'
                    ]
                }
            }
        ],
        'source': {
            'action': 'get',
            'from': 'sbx-data',
            'in': 129,
            'with': 'cart_box_item',
            'filters': [
                {
                    'logic_operator': 'and',
                    'filter_operator': 'is not null',
                    'field': 'cart_box.purchase',
                    'value': null
                },
                {
                    'logic_operator': 'and',
                    'filter_operator': 'is not null',
                    'field': 'cart_box.charge_capture',
                    'value': null
                },
                {
                    'logic_operator': 'and',
                    'filter_operator': 'is null',
                    'field': 'cart_box.charge_error',
                    'value': null
                },
                {
                    'logic_operator': 'and',
                    'filter_operator': '>',
                    'field': 'cart_box.eta_date',
                    'value': parseInt(convertDateToNumberDate(fromDate))
                },
                // {
                //   'logic_operator': 'and',
                //   'filter_operator': 'LIKE',
                //   'field': 'product_group.common_name',
                //   'value': 'rose'
                // },
                {
                    'logic_operator': 'and',
                    'filter_operator': '<',
                    'field': 'cart_box.eta_date',
                    'value': parseInt(convertDateToNumberDate(toDate))
                }
            ],
            'fetch': [
                'cart_box',
                'product_group',
                'variety'
            ]
        },
        // "alias": {
        //   "key": "ibf_most_sold_v1",
        //   "update": false,
        //   "ttl": 36800
        // }
    } as BaseAnalyticQuery;
};

export const jsonEventNames = (startDate: number, endDate: number) => {
    return {
        'actions': [
            {
                'filter': 'cart_box_event_active == 0.0',
                'name': 'cart_box_event_active',
                'transformation': '\'inactive\'',
                'type': 'transform'
            },
            {
                'filter': 'cart_box_event_active == 1.0',
                'name': 'cart_box_event_active',
                'transformation': '\'active\'',
                'type': 'transform'
            },
            {
                'filter': '*',
                'name': null,
                'transformation': 'pd.concat([pdf, pd.get_dummies(pdf.cart_box_event_active)], axis=1)',
                'type': 'transform'
            },
            {
                'type': 'group_by',
                'columns': [
                    'cart_box_purchase__KEY',
                    'cart_box_purchase_total',
                    'cart_box_customer_company_name',
                    'cart_box_customer_tier_sbx',
                    'cart_box_customer_business',
                    'cart_box_customer__KEY'
                ],
                'agg': {
                    'cart_box_price': [
                        'sum'
                    ],
                    'active': [
                        'sum'
                    ],
                    'inactive': [
                        'sum'
                    ],
                    'cart_box_event__KEY': [
                        'nunique'
                    ]
                }
            },
            {
                'type': 'group_by',
                'columns': [
                    'cart_box_customer_company_name',
                    'cart_box_customer_tier_sbx',
                    'cart_box_customer_business',
                    'cart_box_customer__KEY',
                    'active_sum',
                    'inactive_sum'
                ],
                'agg': {
                    'cart_box_price_sum': [
                        'sum'
                    ],
                    'cart_box_event__KEY_nunique': [
                        'sum'
                    ],
                    'cart_box_purchase_total': [
                        'sum'
                    ],
                    'cart_box_purchase__KEY': [
                        'nunique'
                    ]
                }
            },
            {
                'type': 'sort',
                'columns': [
                    'cart_box_purchase_total_sum'
                ],
                'ascending': false
            }
        ],
        'source': {
            'action': 'get',
            'from': 'sbx-data',
            'in': 129,
            'with': 'cart_box',
            'fetch': [
                'purchase',
                'event',
                'customer'
            ],
            'filters': [
                {
                    'logic_operator': 'and',
                    'filter_operator': '>',
                    'field': 'eta_date',
                    'value': startDate
                },
                {
                    'logic_operator': 'and',
                    'filter_operator': '<',
                    'field': 'eta_date',
                    'value': endDate
                },
                {
                    'logic_operator': 'and',
                    'filter_operator': 'is not null',
                    'field': 'event',
                    'value': null
                }
            ]
        }
    } as BaseAnalyticQuery;
};

export function easyExampleUtils(year: number, toYear: number) {
    return {
        'actions': [
            {
                'filter': '*',
                'name': 'eta_time',
                'transformation': ' pd.to_datetime( pdf.cart_box_eta_date.astype(\'string\'), format=\'%Y%m%d\' ) ',
                'type': 'transform'
            }, {
                'filter': '*',
                'name': 'eta_year',
                'transformation': 'pdf.eta_time.dt.strftime(\'%Y\')',
                'type': 'transform'
            }
            , {
                'filter': '*',
                'name': 'eta_month',
                'transformation': 'pdf.eta_time.dt.strftime(\'%m\')',
                'type': 'transform'
            },
            {
                'filter': '*',
                'name': 'price_x_quantity',
                'transformation': 'pdf.cart_box_item_price * pdf.cart_box_item_quantity',
                'type': 'transform'
            },
            {
                'agg': {
                    'cart_box_item_quantity': ['sum'],
                    'price_x_quantity': ['sum'],
                    'cart_box_customer': ['list']
                },
                'columns': [
                    'cart_box_item_product_group',
                    'cart_box_item_uom',
                    'cart_box_item_stems_per_bunch',
                    'eta_year',
                    'eta_month'
                ],
                'type': 'group_by'
            },
            {
                'type': 'merge',
                'main_column': 'cart_box_item_product_group',
                'index_column': 'product_group__KEY',
                'merge_type': 'inner',
                'source': {
                    'in': 129,
                    'with': 'product_group',
                    'from': 'sbx-data',
                    'action': 'get'
                }
            }
            ,
            {
                'type': 'merge',
                'main_column': 'cart_box_item_uom',
                'index_column': 'uom__KEY',
                'merge_type': 'inner',
                'source': {
                    'in': 129,
                    'with': 'uom',
                    'from': 'sbx-data',
                    'action': 'get'
                }
            }
            ,
            {
                'type': 'merge',
                'main_column': 'cart_box_item_stems_per_bunch',
                'index_column': 'stems_per_bunch__KEY',
                'merge_type': 'inner',
                'source': {
                    'in': 129,
                    'with': 'stems_per_bunch',
                    'from': 'sbx-data',
                    'action': 'get'
                }
            },
            {
                'type': 'subset',
                'columns': [
                    'cart_box_item_product_group',
                    'cart_box_item_uom',
                    'cart_box_item_stems_per_bunch',
                    'eta_year',
                    'eta_month',
                    'cart_box_item_quantity_sum',
                    'price_x_quantity_sum',
                    'stems_per_bunch_stems_per_bunch',
                    'uom_uom',
                    'product_group_common_name',
                    'product_group_botanical_name',
                    'cart_box_customer_list'

                ]
            }
        ],
        'source': {
            'action': 'merge',
            'from': 'sbx-data',
            'in': 129,
            'merge_type': 'inner',
            'sources': [
                {
                    'from': 'sbx-data',
                    'in': 129,
                    'index_column': 'cart_box__KEY',
                    'main_column': 'cart_box_item_cart_box',
                    'merge_type': 'inner',
                    'with': 'cart_box',
                    'filters': [
                        {
                            'logic_operator': 'and',
                            'filter_operator': '>',
                            'value': year,
                            'field': 'eta_date'
                        }, {
                            'logic_operator': 'and',
                            'filter_operator': '<',
                            'value': toYear,
                            'field': 'eta_date'
                        }
                    ]
                }
            ],
            'with': 'cart_box_item',
            'filters': [
                {
                    'logic_operator': 'and',
                    'filter_operator': 'IS NOT NULL',
                    'field': 'cart_box.purchase',
                    'value': null
                },
                {
                    'logic_operator': 'and',
                    'filter_operator': '>',
                    'field': 'cart_box.eta_date',
                    'value': year
                },
                {
                    'logic_operator': 'and',
                    'filter_operator': '<',
                    'field': 'cart_box.eta_date',
                    'value': toYear
                }
            ]
        }
    } as BaseAnalyticQuery;
}

export const jsonProductsSold = (startDate: number, endDate: number, varietyKey?: string) => {
    // format 20210101

    const json = {
        'actions': [
            {
                'filter': '*',
                'name': 'charge_time',
                'transformation': 'pd.to_datetime( pdf.cart_box_item_cart_box_charge_date.astype(\'string\'), format=\'%Y%m%d\' ) ',
                'type': 'transform'
            }, {
                'filter': '*',
                'name': 'year',
                'transformation': 'pdf.charge_time.dt.strftime(\'%Y\')',
                'type': 'transform'
            }
            , {
                'filter': '*',
                'name': 'month',
                'transformation': 'pdf.charge_time.dt.strftime(\'%m\')',
                'type': 'transform'
            },
            {
                'type': 'group_by',
                'columns': [
                    'product_group_common_name',
                    'year',
                    'month',
                    'variety__KEY',
                    'variety_variety_name'
                ],
                'agg': {
                    'cart_box_item__KEY': [
                        'nunique'
                    ],
                    'cart_box_item_price': [
                        'sum'
                    ]
                }
            }
        ],
        'source': {
            'action': 'merge',
            'from': 'sbx-data',
            'in': 129,
            'with': 'cart_box_item',
            'filters': [
                {
                    'logic_operator': 'and',
                    'filter_operator': 'is not null',
                    'field': 'cart_box.purchase',
                    'value': null
                },
                {
                    'logic_operator': 'and',
                    'filter_operator': 'is not null',
                    'field': 'cart_box.charge_capture',
                    'value': null
                },
                {
                    'logic_operator': 'and',
                    'filter_operator': 'is',
                    'field': 'cart_box.charge_error',
                    'value': null
                },
                {
                    'logic_operator': 'and',
                    'filter_operator': '>',
                    'field': 'cart_box.charge_date',
                    'value': 20230401
                }
                ,
                {
                    'logic_operator': 'and',
                    'filter_operator': '<',
                    'field': 'cart_box.charge_date',
                    'value': 202304010
                }
            ],
            'fetch': ['cart_box'],
            'sources': [
                {
                    'in': 129,
                    'with': 'product_group',
                    'from': 'sbx-data',
                    'main_column': 'cart_box_item_product_group',
                    'index_column': 'product_group__KEY',
                    'merge_type': 'inner'
                },
                {
                    'in': 129,
                    'with': 'variety',
                    'from': 'sbx-data',
                    'main_column': 'cart_box_item_variety',
                    'index_column': 'variety__KEY',
                    'merge_type': 'inner'
                }
            ]
        }
    };

    if (varietyKey) {
        (json.source.filters as any).push({
            'logic_operator': 'and',
            'filter_operator': '=',
            'field': 'variety',
            'value': varietyKey
        });
    }

    return json as BaseAnalyticQuery;
};

export const jsonInventory = ({startDate, endDate}: { startDate: Date | null, endDate: Date | null }) => {

    let auxStartDate = 0;
    let auxEndDate = 0;

    if (!startDate) {
        let now = new Date();
        auxStartDate = parseInt(convertDateToNumberDate(now));
        now.setDate(now.getDate() + 7);
        auxEndDate = parseInt(convertDateToNumberDate(now));
    }

    return {
        'actions': [
            {
                'type': 'group_by',
                'columns': [
                    'inventory_price',
                    'inventory_quantity',
                    'inventory_week',
                    'inventory_masterlist_length',
                    'inventory_grower_company_name',
                    'inventory_end_date',
                    'inventory_variety_variety_name',
                    'product_group_common_name'
                ],
                'agg': {
                    'inventory__KEY': [
                        'nunique'
                    ]
                }
            }
        ],
        'source': {
            'action': 'merge',
            'from': 'sbx-data',
            'in': 129,
            'with': 'inventory',
            'filters': [
                {
                    'logic_operator': 'and',
                    'filter_operator': '>=',
                    'field': 'week',
                    'value': startDate ? convertDateToNumberDate(startDate) : auxStartDate
                },
                {
                    'logic_operator': 'and',
                    'filter_operator': '<=',
                    'field': 'week',
                    'value': endDate ? convertDateToNumberDate(endDate) : auxEndDate
                }
            ],
            'fetch': [
                'grower',
                'variety',
                'masterlist'
            ],
            'sources': [
                {
                    'in': 129,
                    'with': 'product_group',
                    'from': 'sbx-data',
                    'main_column': 'inventory_masterlist_product_group',
                    'index_column': 'product_group__KEY',
                    'merge_type': 'inner'
                }
            ]
        }
    } as any
};

export const jsonMarketing = ({startDate, endDate}: { startDate?: Date, endDate?: Date }) => {

    const auxStartDate = new Date();
    const auxEndDate = new Date();

    auxStartDate.setDate(auxStartDate.getDate() - 7);

    return {
        'actions': [
            {
                'type': 'merge',
                'main_column': 'ibf_utm_click_meta_extra_customer_key',
                'index_column': 'customer__KEY',
                'merge_type': 'inner',
                'source': {
                    'in': 129,
                    'with': 'customer',
                    'from': 'sbx-data',
                    'action': 'get',
                    'filters': []
                },

            },
            {
                'filter': '*',
                'name': 'utm_date_temp',
                'transformation': 'pd.to_datetime(pdf.ibf_utm_click_info_event_key_event_stamp, unit=\'ms\').dt.tz_localize(\'UTC\')',
                'type': 'transform'
            },
            {
                'filter': '*',
                'name': 'utm_date_year',
                'transformation': 'pdf.utm_date_temp.dt.strftime(\'%Y%m%d\')',
                'type': 'transform'
            },
            {
                'filter': '*',
                'name': 'checkout_date_temp',
                'transformation': 'pd.to_datetime(pdf.ibf_checkout_confirmation_info_event_key_event_stamp, unit=\'ms\').dt.tz_localize(\'UTC\')',
                'type': 'transform'
            },
            {
                'filter': '*',
                'name': 'checkout_date_year',
                'transformation': 'pdf.checkout_date_temp.dt.strftime(\'%Y%m%d\')',
                'type': 'transform'
            },
            {
                'filter': '*',
                'name': 'sameday',
                'transformation': '\'N\'',
                'type': 'transform'
            },
            {
                'filter': 'checkout_date_year == utm_date_year',
                'name': 'sameday',
                'transformation': '\'Y\'',
                'type': 'transform'
            },
            {
                'filter': '*',
                'name': 'ibf_utm_click_payload_props_campaign_code',
                'transformation': 'pdf.ibf_utm_click_payload_props_campaign_code.astype(\'string\')',
                'type': 'transform'
            },
            {
                'filter': '*',
                'name': 'ibf_utm_click_payload_props_utm_campaign',
                'transformation': 'pdf.ibf_utm_click_payload_props_utm_campaign.astype(\'string\')',
                'type': 'transform'
            },
            {
                'filter': '*',
                'name': 'ibf_utm_click_payload_props_utm_source',
                'transformation': 'pdf.ibf_utm_click_payload_props_utm_source.astype(\'string\')',
                'type': 'transform'
            },
            {
                'filter': '*',
                'name': 'ibf_utm_click_payload_props_utm_medium',
                'transformation': 'pdf.ibf_utm_click_payload_props_utm_medium.astype(\'string\')',
                'type': 'transform'
            },
            {
                'type': 'subset',
                'columns': [
                    'ibf_checkout_confirmation_payload_props_total',
                    'ibf_utm_click_meta_extra_customer_key',
                    'ibf_checkout_confirmation_info_event_key_event_time',
                    'sameday',
                    'customer_tier_sbx',
                    'customer_business',
                    'customer_storefront_type',
                    'ibf_utm_click_info_event_key_event_time',
                    'ibf_utm_click_payload_props_campaign_code',
                    'ibf_utm_click_payload_props_utm_campaign',
                    'ibf_utm_click_payload_props_utm_medium',
                    'ibf_utm_click_payload_props_utm_source'
                ]
            },
            {
                'agg': {
                    'ibf_checkout_confirmation_payload_props_total': [
                        'list'
                    ],
                    'ibf_utm_click_meta_extra_customer_key': [
                        'list'
                    ],
                    'ibf_checkout_confirmation_info_event_key_event_time': [
                        'nunique'
                    ]
                },
                'columns': [
                    'sameday',
                    'customer_tier_sbx',
                    'customer_storefront_type',
                    'customer_business',
                    'ibf_utm_click_info_event_key_event_time',
                    'ibf_utm_click_payload_props_campaign_code',
                    'ibf_utm_click_payload_props_utm_campaign',
                    'ibf_utm_click_payload_props_utm_medium',
                    'ibf_utm_click_payload_props_utm_source'
                ],
                'type': 'group_by'
            },
            {
                'filter': '*',
                'name': 'count_event',
                'transformation': '0',
                'type': 'transform'
            },
            {
                'filter': 'sameday == \'Y\'',
                'name': 'count_event',
                'transformation': 'pdf.ibf_checkout_confirmation_info_event_key_event_time_nunique',
                'type': 'transform'
            },
            {
                'filter': 'sameday == \'Y\'',
                'name': 'customer_Y',
                'transformation': 'pdf.ibf_utm_click_meta_extra_customer_key_list',
                'type': 'transform'
            },
            {
                'filter': 'sameday == \'N\'',
                'name': 'customer_N',
                'transformation': 'pdf.ibf_utm_click_meta_extra_customer_key_list',
                'type': 'transform'
            },
            {
                'filter': 'sameday == \'Y\'',
                'name': 'total',
                'transformation': 'pdf.ibf_checkout_confirmation_payload_props_total_list',
                'type': 'transform'
            },
            {
                "agg": {
                    "total": [
                        "list"
                    ],
                    "customer_Y": [
                        "list"
                    ],
                    "customer_N": [
                        "list"
                    ],
                    "count_event": [
                        "sum"
                    ]
                },
                "columns": [
                    "customer_tier_sbx",
                    "customer_storefront_type",
                    "customer_business",
                    "ibf_utm_click_info_event_key_event_time",
                    "ibf_utm_click_payload_props_campaign_code",
                    "ibf_utm_click_payload_props_utm_campaign",
                    "ibf_utm_click_payload_props_utm_medium",
                    "ibf_utm_click_payload_props_utm_source"
                ],
                "type": "group_by"
            }
        ],
        'source': {
            'action': 'merge',
            'from': 'sbx-event',
            'in': 129,
            'merge_type': 'inner',
            // 'pagination': true,
            'sources': [
                {
                    'from': 'sbx-event',
                    'in': 129,
                    'index_column': 'ibf_checkout_confirmation_meta_extra_customer_key',
                    'main_column': 'ibf_utm_click_meta_extra_customer_key',
                    'merge_type': 'outer',
                    // 'pagination': true,
                    'filters': [
                        {
                            'logic_operator': 'and',
                            'filter_operator': '=',
                            'field': 'fromDate',
                            'value': startDate?.toISOString() ?? auxStartDate.toISOString()
                        },
                        {
                            'logic_operator': 'and',
                            'filter_operator': '=',
                            'field': 'toDate',
                            'value': endDate?.toISOString() ?? auxEndDate.toISOString()
                        }
                    ],
                    'with': 'ibf_checkout_confirmation'
                }
            ],
            'filters': [
                {
                    'logic_operator': 'and',
                    'filter_operator': '=',
                    'field': 'fromDate',
                    'value': startDate?.toISOString() ?? auxStartDate.toISOString()
                },
                {
                    'logic_operator': 'and',
                    'filter_operator': '=',
                    'field': 'toDate',
                    'value': endDate?.toISOString() ?? auxEndDate.toISOString()
                }
            ],
            'with': 'ibf_utm_click'
        }
    } as BaseAnalyticQuery;
};

export const jsonPurchaseByYear = (year: number) => {

    return {
        'actions': [
            {
                'filter': '*',
                'name': 'date_time',
                'transformation': ' pd.to_datetime(pdf.cart_box_purchase_customer_date) ',
                'type': 'transform'
            },
            {
                'filter': '*',
                'name': 'year',
                'transformation': 'pdf.date_time.dt.strftime(\'%Y\')',
                'type': 'transform'
            }
            ,
            {
                'filter': '*',
                'name': 'month',
                'transformation': 'pdf.date_time.dt.strftime(\'%m\')',
                'type': 'transform'
            },
            {
                'type': 'group_by',
                'columns': [
                    'month',
                    'year'
                ],
                'agg': {
                    'cart_box_purchase_total': [
                        'list'
                    ],
                    'cart_box_purchase__KEY': [
                        'list'
                    ]
                }
            }
        ],
        'source': {
            'action': 'get',
            'from': 'sbx-data',
            'in': 129,
            'with': 'cart_box',
            'filters': [
                {
                    'logic_operator': 'and',
                    'filter_operator': 'is not null',
                    'field': 'purchase',
                    'value': null
                },
                {
                    'logic_operator': 'and',
                    'filter_operator': 'is not null',
                    'field': 'charge_capture',
                    'value': null
                },
                {
                    'logic_operator': 'and',
                    'filter_operator': 'is',
                    'field': 'charge_error',
                    'value': null
                },
                {
                    'logic_operator': 'and',
                    'filter_operator': '>=',
                    'field': 'charge_date',
                    'value': parseInt(`${year}0101`)
                },
                {
                    'logic_operator': 'and',
                    'filter_operator': '<=',
                    'field': 'charge_date',
                    'value': parseInt(`${year}1231`)
                }
            ],
            'fetch': ['purchase']
        }
    } as BaseAnalyticQuery;
};

export const jsonPurchaseByReOrder = (year: number) => {
    return {
        'actions': [
            {
                'filter': '*',
                'name': 'date_time',
                'transformation': 'pd.to_datetime( pdf.cart_box_item_cart_box_charge_date.astype(\'string\'), format=\'%Y%m%d\' )',
                'type': 'transform'
            },
            {
                'filter': '*',
                'name': 'year',
                'transformation': 'pdf.date_time.dt.strftime(\'%Y\')',
                'type': 'transform'
            },
            {
                'filter': '*',
                'name': 'month',
                'transformation': 'pdf.date_time.dt.strftime(\'%m\')',
                'type': 'transform'
            },
            {
                'filter': '*',
                'name': 'total_item',
                'transformation': 'pdf.cart_box_item_price * pdf.cart_box_item_quantity',
                'type': 'transform'
            },
            {
                'type': 'group_by',
                'columns': [
                    'year',
                    'month',
                    'customer_company_name',
                    'customer_tier_sbx',
                    'customer_storefront_type'
                ],
                'agg': {
                    'customer__KEY': [
                        'nunique'
                    ],
                    'purchase__KEY': [
                        'list'
                    ],
                    'purchase_total': [
                        'list'
                    ]
                }
            }
        ],
        'source': {
            'action': 'merge',
            'from': 'sbx-data',
            'in': 129,
            'with': 'cart_box_item',
            'filters': [
                {
                    'logic_operator': 'and',
                    'filter_operator': 'is not null',
                    'field': 'repurchase_cartbox_item',
                    'value': null
                },
                {
                    'logic_operator': 'and',
                    'filter_operator': 'is not null',
                    'field': 'cart_box.purchase',
                    'value': null
                },
                {
                    'logic_operator': 'and',
                    'filter_operator': '>=',
                    'field': 'cart_box.charge_date',
                    'value': `${year}0101`
                },
                {
                    'logic_operator': 'and',
                    'filter_operator': '<=',
                    'field': 'cart_box.charge_date',
                    'value': `${year}1231`
                }
            ],
            'fetch': [
                'cart_box'
            ],
            'sources': [
                {
                    'in': 129,
                    'with': 'customer',
                    'from': 'sbx-data',
                    'main_column': 'cart_box_item_cart_box_customer',
                    'index_column': 'customer__KEY',
                    'merge_type': 'inner'
                },
                {
                    'in': 129,
                    'with': 'purchase',
                    'from': 'sbx-data',
                    'main_column': 'cart_box_item_cart_box_purchase',
                    'index_column': 'purchase__KEY',
                    'merge_type': 'inner'
                },
            ]
        }
    } as BaseAnalyticQuery;
};

export const jsonSearchTerm = ({startDate, endDate}: { startDate: Date, endDate: Date }) => {
    return {
        'actions': [
            {
                'filter': '*',
                'name': 'date_temp',
                'transformation': 'pd.to_datetime(pdf.ibf_product_search_info_event_key_event_stamp, unit=\'ms\').dt.tz_localize(\'UTC\')',
                'type': 'transform'
            }, {
                'filter': '*',
                'name': 'search',
                'transformation': 'pdf.ibf_product_search_payload_props_search.astype(\'string\')',
                'type': 'transform'
            },
            {
                'filter': '*',
                'name': 'date_year',
                'transformation': 'pdf.date_temp.dt.strftime(\'%Y%m\')',
                'type': 'transform'
            },

            {
                'agg': {
                    'ibf_product_search_info_event_key_event_time': [
                        'nunique'
                    ]
                },
                'columns': [
                    'customer_tier_sbx',
                    'customer_guest',
                    'customer_business',
                    'search'
                ],
                'type': 'group_by'
            },
            {
                'type': 'sort',
                'columns': ['ibf_product_search_info_event_key_event_time_nunique'],
                'ascending': false
            }


        ],
        'source': {
            'action': 'merge',
            'from': 'sbx-event',
            'in': 129,
            'merge_type': 'inner',
            'sources': [
                {
                    'from': 'sbx-data',
                    'in': 129,
                    'index_column': 'customer__KEY',
                    'main_column': 'ibf_product_search_meta_extra_customer_key',
                    'merge_type': 'inner',
                    'with': 'customer'
                }
            ],
            'filters': [
                {
                    'logic_operator': 'and',
                    'filter_operator': '=',
                    'field': 'fromDate',
                    'value': startDate.toISOString()
                },
                {
                    'logic_operator': 'and',
                    'filter_operator': '=',
                    'field': 'toDate',
                    'value': endDate.toISOString()
                }
            ],
            'with': 'ibf_product_search'
        }
    } as BaseAnalyticQuery;
};

export const analyticAggList = [
    "nunique",
    "sum",
    "count",
    "list",
    "std",
    "max",
    "min",
    "mean",
    "first",
    "last",
    "cumsum",
    "mediam",
    "@business_day"
]

export const basicAnalyticAggList = [
    "nunique",
    "sum",
    "count",
    "std",
    "max",
    "min",
    "mean",
    "first",
    "last",
    "mediam"
]

export const analyticAggPivotList = [
    "nunique",
    "sum",
    "mean",
    "min",
    "max",
    "first",
    "last",
    "count"
]

export const removeTemporalIdFromQuery = (json: BaseAnalyticQuery) => {

    const actions = json.actions ? [...json.actions] : [];

    let query = {...json}

    if (query.source?.temporal_id) {
        query = {...query, source: {...query.source, temporal_id: ""}}
    }

    if (query.source?.sources && query.source.sources.length > 0) {

        query = {
            ...query,
            source: {
                ...query.source,
                sources: query.source.sources.map(source => {
                    if (source.temporal_id) {
                        source = {...source, temporal_id: ""}
                    }

                    return source
                })
            }
        }
    }


    return {
        ...query
        , actions: actions.reduce((arr: AnalyticQueryAction[], action) => {
            if (action.temporal_id) {
                // delete action.temporal_id;
                action = {...action, temporal_id: ""}
            }

            if (action.dependency_action_id) {
                delete action.dependency_action_id;
            }

            if ((action.type === 'select' || action.type === 'sort' || action.type === 'rename')
                && (action.columns?.length === 0 || (action.renamed_columns && Object.keys(action.renamed_columns).length === 0))) {
            } else {
                arr.push(action);
            }

            return arr;
        }, [])
    };
};

export const getQuerySources = (json: BaseAnalyticQuery) => {
    const querySources: { [key: string]: Query } = {}
    if (json.source && json.source.temporal_id) {
        querySources[json.source.temporal_id] = {
            row_model: json.source.with,
            fetch: json.source.fetch,
            where: json.source.filters ? [{
                GROUP: convertAnalyticFilterToSbx(json.source.filters),
                ANDOR: "AND"
            }] as Condition[] : []
        }
    }

    if (json.source.sources && json.source.sources.length > 0) {
        for (const source of json.source.sources) {
            if (source.temporal_id) {
                querySources[source.temporal_id] = {
                    row_model: source.with,
                    fetch: source.fetch,
                    where: source.filters ? [{
                        GROUP: convertAnalyticFilterToSbx(source.filters),
                        ANDOR: "AND"
                    }] as Condition[] : []
                }
            }
        }
    }

    return querySources
}

export const addTemporalIdToQuery = (json: BaseAnalyticQuery) => {

    const actions = [...json.actions].map((action) => {

        action.temporal_id = uuidV4()

        return action
    });


    actions.forEach(action => {
        if (action.transformation && typeof action.transformation === "string" && action.transformation.includes("@date_to_formateddate")) {
            // Debug this


            const transformationVar = action.transformation.split(" ")[1]

            const dependency_action = actions.find(nAction => {

                if (nAction.name && transformationVar === nAction.name) {
                    return nAction
                }
            })

            if (dependency_action?.temporal_id) {
                action.dependency_action_id = dependency_action.temporal_id
            }
        }
    })

    let query = {...json}


    query.source.temporal_id = uuidV4()


    if (query.source?.sources && query.source.sources.length > 0) {
        query.source.sources = query.source.sources.map(source => {
            // if (!source.temporal_id) {
            source.temporal_id = uuidV4()
            // }

            return source
        })
    }


    return {
        ...query, actions
    };
};

export const actionsValidationFromQuery = (json: BaseAnalyticQuery, domain = 0) => {
    let query = {...json}

    query.actions = query.actions.map(action => {
        if (action.hasOwnProperty("in") && action.in === 0 && domain > 0) {
            action.in = domain
        } else if (action.hasOwnProperty("domain") && action.domain === 0 && domain > 0) {
            action.domain = domain
        }
        if (action.type === "merge") {
            action = (sourcesValidationFromQuery(action as unknown as BaseAnalyticQuery, domain) as AnalyticQueryAction)
            if (action.actions) {
                // action = getAnalyticQueryFlat(action as AnalyticQuery)
                (action.actions) = action.actions ? action.actions.flat() : []
            }
        }


        if (action.type === "merge") {
            action = (sourcesValidationFromQuery(action as unknown as BaseAnalyticQuery, domain) as AnalyticQueryAction)
        }


        return action
    })


    return query
}

export const sourcesValidationFromQuery = (json: BaseAnalyticQuery | AnalyticQuery, domain = 0) => {

    let query = {...json};


    if (domain > 0) {
        if (query.source.in === 0) {
            query.source.in = domain
        }

        if (query.source.sources && query.source.sources.length > 0) {
            query.source.sources = query.source.sources.map(source => {

                if (source.in === 0) {
                    source.in = domain
                }


                return source
            })
        }
    }

    if (query.source && query.source.from === SourceFrom.SBX_EVENT && query.source?.filters && query.source.filters.length > 0) {
        const fromDate = query.source.filters.find(filter => filter.field === "fromDate")?.value
        const toDate = query.source.filters.find(filter => filter.field === "toDate")?.value

        if (fromDate && toDate) {

            const diff = differenceInWeeks(new Date(toDate as string), new Date(fromDate as string))
            if (diff > 2) {
                query.source.pagination = true
            }
        }
    }

    if (query.source?.sources && query.source.sources.length > 0) {
        query.source.sources = query.source.sources.filter(source => source.with);

        query.source.sources = query.source.sources.map(source => {
            if (source.filters && source.filters.length > 0) {
                const fromDate = source.filters.find(filter => filter.field === "fromDate")?.value
                const toDate = source.filters.find(filter => filter.field === "toDate")?.value

                if (fromDate && toDate) {
                    const diff = differenceInWeeks(new Date(toDate as string), new Date(fromDate as string))
                    if (diff > 2) {
                        source.pagination = true
                    }
                }
            }

            return source
        })

    }

    if (query.source?.filters && query.source.filters.length > 0) {
        query.source.filters = query.source.filters.map(filter => {

            if (filter.filter_operator === 'IS' && filter.value === null) {
                filter.filter_operator = 'is null';
            }

            if (filter.filter_operator === 'IS NOT' && filter.value === null) {
                filter.filter_operator = 'is not null';
            }

            return filter;
        });
    }


    return query;
};

export const removeInvalidActionsFromQuery = (json: BaseAnalyticQuery) => {

    let query = {...json}

    if (query.actions && query.actions.length > 0) {
        // console.log(query.actions)

        const drillDownActionIndex = query.actions.findIndex(action => action.type === "group_by" && action.subtype === "drill_down")
        const drillDownAction = query.actions[drillDownActionIndex] as AnalyticQueryAction ?? undefined

        query.actions = query.actions.filter((action, index) => {
            // console.log("action", action)
            // console.log("\n\n\n")
            if (index > drillDownActionIndex && drillDownAction && action.subtype !== "drill_down" && (action.type !== "filter" || (action.type === "filter" && !drillDownAction.hierarchy?.some(hierarchy => action.filter?.includes(hierarchy))))) {
                if (action.hasOwnProperty("drill_down_execution_index")) {
                    if (action.drill_down_execution_index !== -1) {
                        const column = drillDownAction.hierarchy && typeof action.drill_down_execution_index === "number" && drillDownAction.hierarchy[action.drill_down_execution_index] ? drillDownAction.hierarchy[action.drill_down_execution_index] : null
                        // console.log("column", column)
                        // console.log((drillDownAction.columns as string[])[drillDownAction.columns.length - 1])
                        if (!drillDownAction.columns?.includes(column) || (drillDownAction.columns && (drillDownAction.columns as string[])[drillDownAction.columns.length - 1] !== column)) {
                            return false
                        }
                    }
                } else {
                    return false
                }
            }


            switch (action.type) {
                case "sort":
                    return action.columns && action.columns.length > 0;
                case "select":
                    return action.columns && action.columns.length > 0;
                case "limit":
                    return action.top! > 0;
                case "group_by":

                    if (action.subtype === "drill_down") {
                        return action.agg && Object.keys(action.agg).length > 0 && action.hierarchy && action.hierarchy.length > 0;
                    }

                    return action.agg && Object.keys(action.agg).length > 0 && action.columns && action.columns.filter((column: string) => column).length > 0;
                case "pivot":
                    return action.agg && Object.keys(action.agg).length > 0 && action.columns && action.columns.length > 0 && action.index_columns && action.index_columns.length > 0;
                case "rename":
                    return action.renamed_columns && Object.keys(action.renamed_columns).length > 0;
                case "transform":
                    return (((action.transformation || action.transformation === 0) && action.name) || (action.columns && action.columns.length > 0)) || (action.filter && action.filter.length > 0);
                case "filter":
                    return action.filter && action.filter?.length > 0
                case 'default_values':
                    return action.columns && Object.keys(action.columns).length > 0
                case 'merge':

                    let timeWindowCondition = true

                    if (!!action.time_window){
                        if (!((action.time_window as TimeWindowAction).type && (action.time_window as TimeWindowAction).time >= 0 && (action.time_window as TimeWindowAction).main_column && (action.time_window as TimeWindowAction).index_column)){
                            timeWindowCondition = false
                        }
                    }

                    if (action.subtype && action.subtype === "concat") {
                        return !!action.source?.with
                    }



                    return action.main_column && action.index_column && action.source?.with && timeWindowCondition
                case "ml":
                    if (action.subtype === "forecast") {
                        return action.forecast_to && action.forecast_from && action.x && action.y
                    }

                    if (action.subtype === "binary_classifier") {
                        return action.columns && action.columns.length > 0 && action.target
                    }

                    if (action.subtype === "monetary_segmentation") {
                        return action.customer_id && action.invoice_id && action.invoice_date && action.invoice_total
                    }

                    if (action.subtype === "segmentation") {
                        return action.columns && action.columns.length > 0 && typeof action.n_segments === "number"
                    }

                    return true
                case "date_analysis":
                    return action.analysis_by && typeof action.compare_quantity !== "undefined"
                        && action.compare_with && action.agg && Object.keys(action.agg).length > 0
                case "save":
                    return action.with
                case "async_ml":
                    return action.model_name && action.options?.target && action.options?.type
                default:
                    return true;
            }
        })


    }

    return query;
};

export function analyticQueryValidation(json: BaseAnalyticQuery) {
    if (json.source.from === SourceFrom.JSON || isValidAnalyticQuery(json)) {
        return addTokenToAnalyticQuery(json);
    }

    return null;
}

export const getQueryData = async ({limit = 10, analyticQuery}: {
    limit: number | null,
    analyticQuery: BaseAnalyticQuery
}) => {
    if (analyticQuery && isValidAnalyticQuery(analyticQuery)) {

        const query = {...analyticQuery}

        let actions = [...query.actions].filter(action => action.type !== 'save');

        let res = null

        // if (actions.length > 0 && actions[actions.length - 1].subtype && actions[actions.length - 1].subtype! === "drill_down") {
        //   const action = actions[actions.length - 1]
        //   if (action.columns && action.hierarchy) {
        //     action.columns.unshift(action.hierarchy[0])
        //   }
        // }


        if (typeof limit !== "number") {
            res = await executeAnalyticJson({...query});
        } else {
            res = await executeAnalyticJson({
                ...query,
                actions: [...actions, {type: 'limit', top: limit} as AnalyticQueryAction]
            });
        }


        if (res?.success && res.items) {
            toast({message: 'Query was made successfully'});
            return res.items
        } else {
            toast({
                type: 'error',
                message: Array.isArray((res?.error as any)?.message) ? (res?.error as any)?.message[0] : (res?.error as any)?.message ?? 'An error occurred'
            });
            return []
        }
    } else {
        return []
    }
};

export const getRangeValue = (range: number) => {
    switch (range) {
        case 10:
            const today = convertDateToNumberDate(new Date())
            return `${today}-${today}`

        default:
            return range
    }
}

export const getAnalyticQueryFlat = (query: AnalyticQuery): BaseAnalyticQuery => {
    return {
        ...query,
        actions: query.actions ? query.actions.flat().map(action => {

            if (action.type === "merge"){
                action = {...action, actions: (action.actions ?? [])?.flat()}
            }

            return action
        }) : []
    } as BaseAnalyticQuery
}

export const getAnalyticQueryGrouped = (query: BaseAnalyticQuery, v2 = false) => {
    return {
        ...query,
        actions: query.actions.reduce((arr: AnalyticQueryAction[][], item: AnalyticQueryAction) => {

            if (item.dependency_action_id && !v2) {
                const index = arr.findIndex(action => action.find(subAction => subAction.temporal_id === item.dependency_action_id))
                if (arr[index]) {
                    arr[index].push(item)
                }

            } else {
                arr.push([item])
            }

            return arr
        }, [])
    } as AnalyticQuery
}

export const isValidAnalyticQuery = (query: BaseAnalyticQuery | AnalyticQuery) => query.source.with && query.source.from

export const jsFormatFromPyFormat: { [pyFormat: string]: string } = {
    "%Y": "yyyy",
    "%y": "yyyy",
    "%m": "MM",
    "%d": "dd",
    "%H": "HH",
    "%M": "mm",
    "%S": "ss",
    "%a": "EEE",
    "%A": "EEEE",
    "%b": "MMM",
    "%B": "MMMM",
    "%p": "a"
}

export const convertDatePyFormatToJsFormat = (date: string) => {
    let jsFormat = date
    for (const pyFormat in jsFormatFromPyFormat) {
        jsFormat = jsFormat.replaceAll(pyFormat, jsFormatFromPyFormat[pyFormat])
    }

    return jsFormat
}

export const dateTransformList: { label: string, value: string, type?: SbxModelField, format: string  }[] = [
    {"label": "complete", "value": "%Y-%m-%d", "format": "yyyy-MM-dd"},
    {"label": "complete-no-character", "value": "%Y%m%d", "format": "yyyyMMdd", type: SbxModelField.INT},
    {
        "label": "date_hour",
        "value": "%Y-%m-%dT%H:%M:%S",
        "format": "yyyy-MM-dd'T'HH:mm:ss"
    },
    {"label": "year_month", "value": "%Y-%m", "format": "yyyy-MM"},
    {label: 'year-month-no-character', value: '%Y%m', "format": "yyyyMM" , type: SbxModelField.INT},
    {"label": "month_day", "value": "%m-%d", "format": "MM-dd"},
    {"label": "month_day_no_character", "value": "%m%d", "format": "MMdd"},
    {"label": "day", "value": "%d", "format": "dd", type: SbxModelField.INT},
    {"label": "week_day_short", "value": "%a", "format": "EEE"},
    {"label": "week_day", "value": "%A", "format": "EEEE"},
    {"label": "month_short", "value": "%b", "format": "MMM"},
    {"label": "month", "value": "%B", "format": "MMMM"},
    {"label": "month_number", "value": "%m", "format": "MM"},
    {"label": "year", "value": "%Y", "format": "yyyy", type: SbxModelField.INT},
    {"label": "hour", "value": "%H", "format": "HH"},
    {"label": "meridiam", "value": "%p", "format": "a"}
]

export const checkCustomColumns = async ({report, columns, data}: {report: Report, columns: Column[], data: any[]}) => {

    let nData = [...data]
    let nColumns = [...columns]
    if (report.custom_column) {

        nColumns = nColumns.map((column) => {
            if (column) {
                delete column.type;
                delete column.value;
            }
            return column;
        });




        if (JSON.parse(report.custom_column)?.length > 0) {
            const custom_columns: {
                column: string;
                type: CustomTableColumnType;
                value?: any;
            }[] = JSON.parse(report.custom_column);


            if (report?.columns_metadata && IsJsonString(report.columns_metadata as string)) {
                const columnsMetadata = JSON.parse(report.columns_metadata as string) as ColumnsMetadata
                if (columnsMetadata?.head_sub_head?.head_by) {

                    const headBy = columnsMetadata.head_sub_head.head_by

                    nData.forEach(item => {
                        if (item[headBy] && !nColumns.some(column => column.name === item[headBy])) {
                            const customColumn = custom_columns.find(column => column.column === item[headBy])
                            if (customColumn) {
                                nColumns.push({
                                    name: item[headBy],
                                    header: item[headBy],
                                    type: customColumn?.type ?? "String"
                                })
                            }
                        }
                    })
                    custom_columns.forEach(customColumn => {
                        if (!nColumns.some(column => column.name === customColumn.column)) {
                            nColumns.push({
                                name: customColumn.column,
                                header: customColumn.column,
                                type: customColumn.type
                            })
                        }
                    })
                }
            }


            nColumns = nColumns.map((column) => {
                const custom_column = custom_columns.find(
                    (cColumn) => cColumn.column === column.name,
                );
                if (custom_column) {
                    column.type = custom_column.type;
                    if (custom_column.value) {
                        column.value = custom_column.value;
                    }
                }
                return column;
            });
        }
    }

    if (nColumns.some((column) => column.type === "Document")) {
        const documentColumns = nColumns
            .filter((column) => column.type === "Document")
            .map((column) => column.name);

        // if (documents?.success) {

        let docsKey: { [columns: string]: string[] } = {};

        data.forEach((item) => {
            documentColumns.forEach((column) => {
                if (!docsKey[column]) {
                    docsKey[column] = [];
                }
                if (
                    item[column] &&
                    IsJsonString(item[column]) &&
                    Array.isArray(JSON.parse(item[column]))
                ) {
                    docsKey[column] = [...docsKey[column], ...JSON.parse(item[column])];
                }

                if (item[column] && Array.isArray(item[column])) {
                    docsKey[column] = [...docsKey[column], ...item[column]];
                } else {

                    if (
                        item[column] &&
                        typeof item[column] === "string" &&
                        item[column].length > 0
                    ) {

                        if (item[column].includes("'")){
                            const temp = item[column].replaceAll("'", `"`)
                            if (IsJsonString(temp) && Array.isArray(JSON.parse(temp))) {
                                docsKey[column] = [...docsKey[column], ...JSON.parse(temp)];
                            }else{
                                docsKey[column] = [...docsKey[column], item[column]];
                            }
                        }else{
                            docsKey[column] = [...docsKey[column], item[column]];
                        }
                    }
                }


            });
        });

        // Change this service to a service by batches 3 in 3
        const contents: any[] = []
// Here start the batch
        const batches: string[][]= [[]]
        let batchIndex = 0
        Object.values(docsKey).flat().forEach((key) => {
            if (batches[batchIndex].length === 2) {
                batchIndex++
                batches[batchIndex] = [key]
            }else{
                batches[batchIndex].push(key)
            }
        })

        // console.log('batch', batches)
        // const documents2= await getFilesDataByKeys(Object.values(docsKey).flat());

        for await (const batch of batches) {
            const documentsBatch = await getFilesDataByKeys(batch);
            if (documentsBatch.item?.contents){
                contents.push(...documentsBatch.item?.contents)
            }
        }

        // console.log('documents', contents)


        nData = nData.map((item) => {
            Object.keys(docsKey).forEach((column) => {
                const temp: Content[] = [];
                let itemKeys: string[] = [];

                if (
                    item[column] &&
                    IsJsonString(item[column]) &&
                    Array.isArray(JSON.parse(item[column]))
                ) {
                    itemKeys = JSON.parse(item[column]);
                }

                if (item[column] && Array.isArray(item[column])) {
                    itemKeys = item[column];
                } else {
                    if (
                        item[column] &&
                        typeof item[column] === "string" &&
                        item[column].length > 0
                    ) {

                        if (item[column].includes("'")){
                            const temp = item[column].replaceAll("'", `"`)
                            if (IsJsonString(temp) && Array.isArray(JSON.parse(temp))) {
                                itemKeys = [...JSON.parse(temp)];
                            }else{
                                itemKeys = [item[column]];
                            }
                        }else{
                            itemKeys = [item[column]];
                        }


                    }
                }

                if (itemKeys.length > 0) {
                    itemKeys.forEach((docKey) => {
                        const document = contents.find(
                            (content: Content) => content.key === docKey,
                        );
                        if (document) {
                            temp.push(document);
                        }
                    });

                    item = { ...item, [column]: temp };
                }
            });
            return item;
        });
    }

    if (report.custom_column && JSON.parse(report.custom_column)?.length > 0) {
        const custom_columns: {
            column: string;
            type: CustomTableColumnType;
            value?: any;
        }[] = JSON.parse(report.custom_column);


        const total_column_by_row = custom_columns.find(column => column.column === "total_column_by_row")

        if (total_column_by_row?.column && nColumns.length > 0 && !nColumns.some(column => column.name === "total_column_by_row")) {
            nColumns = [...nColumns, {
                name: total_column_by_row.column,
                header: total_column_by_row.column,
                type: total_column_by_row.type
            }]
        }
    }

    return {columns: nColumns, data: nData}
}

export const getColumnsReport = async (
    report: Report,
    data: any[],
    columns: Column[],
) => {

    const allColumns = [...columns];

    if (report.sort && IsJsonString(report.sort)) {
        // JSON.parse(report.sort).length > 0
        const sort = JSON.parse(report.sort);

        if (Array.isArray(sort) && sort.length > 0) {
            columns = JSON.parse(report.sort).map((key: string) => {
                const prevColumn = columns.find((column) => column.name === key);

                return (
                    prevColumn ?? {
                        name: key,
                        header: key,
                    }
                );
            });
        } else {
            if (report.query && !report.hasOwnProperty("drillDownLevel")) {
                report.drillDownLevel = 0;
            }

            if (typeof sort === "object" && Object.keys(sort).length > 0) {
                if (typeof report.drillDownLevel === "number") {
                    let sortList = sort[report.drillDownLevel];

                    if (sortList?.length === 0) {
                        report = sortByDrillDown(report);

                        if (IsJsonString(report.sort)) {
                            sortList = JSON.parse(report.sort);
                        }
                    }

                    if (sortList && sortList.length > 0) {
                        columns = sortList.map((key: string) => {
                            const prevColumn = columns.find((column) => column.name === key);

                            return (
                                prevColumn ?? {
                                    name: key,
                                    header: key,
                                }
                            );
                        });
                    }
                }
            }
        }
    } else {
        if (report.query) {
            if ( !report.hasOwnProperty("drillDownLevel")) {
                report.drillDownLevel = 0;
            }

            report = sortByDrillDown(report);

            if (report.sort) {
                columns = JSON.parse(report.sort).map((key: string) => {
                    const prevColumn = columns.find((column) => column.name === key);

                    return (
                        prevColumn ?? {
                            name: key,
                            header: key,
                        }
                    );
                });
            }
        }
    }



    const customColumns
        = await checkCustomColumns({report, columns, data})


    if (customColumns?.columns) {
        columns = customColumns.columns
    }

    if (customColumns?.data) {
        data = customColumns.data
    }

    if ((columns?.length === 0 || report.show_all_columns) && data[0]) {

        columns = Object.keys(data[0])
            .filter((key) => !columns.some((column) => column.name === key))
            .reduce((arr: Column[], key) => {

                const prevColumn = columns.find((column) => column.name === key);

                arr.push(prevColumn ?? {
                    name: key,
                    header: key,
                });

                return arr;
            }, columns);


        const customColumns = await checkCustomColumns({report, columns, data})
        if (customColumns?.columns) {
            columns = customColumns.columns
        }

        if (customColumns?.data) {
            data = customColumns.data
        }
    }

    if (report.columns_metadata && JSON.parse(report.columns_metadata as string)) {
        const columns_metadata = JSON.parse(report.columns_metadata as string);


        columns = columns.map((column) => {
            const metadata: string[] = [];

            Object.keys(columns_metadata).forEach((type) => {
                if ((Array.isArray(columns_metadata[type])) && columns_metadata[type].includes(column.name)) {
                    metadata.push(type);
                }
            });

            column = {...column, metadata_type: metadata};


            return column;
        });
    }

    if (report.columns_to_summarize && JSON.parse(report.columns_to_summarize)) {
        const columns_to_summarize = JSON.parse(report.columns_to_summarize);

        columns = columns.map((column) => ({
            ...column,
            isTotalColumn: columns_to_summarize.includes(column.name),
        }));


        if (columns_to_summarize.includes("total_column_by_row")){
            if (columns.some(column => column.name === "total_column_by_row")){
                columns = columns.map(column => {
                    if (column.name === "total_column_by_row"){
                        return {...column, isTotalColumn: true}
                    }
                    return column
                })
            }else{
                if (!columns.some(column => column.name === "total_column_by_row")){
                    columns = [...columns, {name: "total_column_by_row", header: "total_column_by_row", isTotalColumn: true}]
                }
            }

        }else{
            // console.log('columns', columns)
            if (columns.some(column => column.name === "total_column_by_row")){
                columns = columns.map(column => {
                    if (column.name === "total_column_by_row"){
                        return {...column, isTotalColumn: false}
                    }
                    return column
                })
            }

        }
    }

    // console.log("{columns, data, allColumns}", {columns, data, allColumns})

    return { columns, data, allColumns, allColumnsCopy: [] };
};

export const checkDynamicTruncate = (truncate: TruncateReport) => {
    if (truncate.relative) {
        return truncate;
    }

    const today = new Date();
    let startRange = convertDateToNumberDate(today);
    let endRange = "";

    if (truncate?.dynamic_from) {
        const [num, period] = truncate.dynamic_from.split(" ");

        switch (period) {
            case "day":
            {
                today.setDate(today.getDate() - parseInt(num));
            }
                break;
            case "week":
            {
                today.setDate(today.getDate() - parseInt(num) * 7);
            }
                break;
            case "month":
            {
                today.setMonth(today.getMonth() - parseInt(num));
            }
                break;
            case "year": {
                today.setFullYear(today.getFullYear() - parseInt(num));
            }
        }

        startRange = convertDateToNumberDate(today);

        // console.log("start range", startRange)
    }

    if (truncate?.dynamic_range) {
        const [num, period] = truncate.dynamic_range.split(" ");

        switch (period) {
            case "days":
            {
                today.setDate(today.getDate() + parseInt(num));
            }
                break;
            case "weeks":
            {
                today.setDate(today.getDate() + parseInt(num) * 7);
            }
                break;
            case "months":
            {
                today.setMonth(today.getMonth() + parseInt(num));
            }
                break;
            case "years": {
                today.setFullYear(today.getFullYear() + parseInt(num));
            }
        }

        endRange = convertDateToNumberDate(today);
        // console.log("endRange", endRange)
        if (truncate.dynamic_start) {
            const constVar = StaticConstVar.find(
                (timeVar) =>
                    timeVar.includes(period.replace("s", "")) &&
                    timeVar.includes("start"),
            );
            if (constVar) {
                startRange = replaceConstVarWith({
                    constVar,
                    date_type: "numberDate",
                    year: convertNumberDateToDate(startRange).getFullYear(),
                }).toString();
            }
        }
    } else {
        if (startRange && !endRange) {
            endRange = convertDateToNumberDate(today);
        }
    }

    if (startRange && endRange) {
        truncate.range = `${startRange}-${endRange}`;
    }

    return truncate;
};

export const sortByDrillDown = (report: Report) => {
    const query = IsJsonString(report.query)
        ? JSON.parse(report.query)
        : report.query;



    const action = query.actions.find((it: any) => it?.subtype === "drill_down");
    if (action?.subtype && action.subtype === "drill_down" && action.hierarchy) {
        let sort: string[] = [];
        if (typeof report.drillDownLevel === "number"){
            sort = sort.concat(action.hierarchy.slice(0, report.drillDownLevel + 1));
            sort = sort.concat(action.columns);
            const sortObj = action.agg_hierarchy ?? action.agg_herarchy;


            if  (sortObj && sortObj[report.drillDownLevel] && Object.keys(sortObj[report.drillDownLevel]).length > 0){
                sort = sort.concat(Object.keys(sortObj[report.drillDownLevel]));
            }

        }else{
            sort = sort.concat(action.hierarchy);

            sort = sort.concat(action.columns);

            const sortObj = action.agg_hierarchy ?? action.agg_herarchy;

            if (sortObj) {
                sort = sort.concat(
                    (Object.values(sortObj) as AggAnalytic[])
                        .map((it) => Object.keys(it))
                        .flat(),
                );
            }
        }

        if (action.agg) {
            sort = sort.concat(Object.keys(action.agg).filter((it) => it));
        }


        report = { ...report, sort: JSON.stringify(sort) };
    }

    return report;
};

export const sendSbxEvent = ({ props, name, extra = {} }: {props: {[key: string]: any}, name: string, extra?: {[key: string]: any}}) => {

    const propsParams = {
        ...props,
    };

    TrackEventMonitor.newEvent({
        props: {
            ...propsParams,
        },
        metadata: { name, ...extra },
    });
};

export function generateQuarterRanges(year: number) {
    const quarters: { label: string, name: string, start: string, end: string }[] = [
        { label: "First quarter, Q1: January – March", name: "first_quarter", start: `${year}0101`, end: `${year}0331` },
        { label: "Second quarter, Q2: April – June",name: "second_quarter" , start: `${year}0401`, end: `${year}0630` },
        { label: "Third quarter, Q3: July – September", name: "third_quarter" ,start: `${year}0701`, end: `${year}0930` },
        { label: "Fourth quarter, Q4: October – December", name: "fourth_quarter" ,start: `${year}1001`, end: `${year}1231` },
    ];

    // Adjust for leap years in Q1
    if (year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0)) {
        quarters[0].end = `${year}0331`; // Leap year February adjustment
    } else {
        quarters[0].end = `${year}0331`; // Regular year
    }

    return quarters.map(q => ({
        label: q.label,
        name: q.name,
        value: `${q.start}-${q.end}`
    }));
}