import * as React from 'react';
import {useContext} from 'react';
import {Field} from "../../../types/Field";
import FileComponent from "./FileComponent";
import MultiFilesInputComponent from "./MultiFilesInputComponent";
import {CrmInputContext} from "./CrmRenderInput";
import {TaskComponentContext} from "../../../context/TaskContext";

type Props = {
  field: Field,
};

const CrmFileInputComponent = ({field}: Props) => {

  const {setValue, getFormValue} = useContext(TaskComponentContext);
  const {getDefaultValue} = useContext(CrmInputContext)



  function setValueField(val: any) {
    if (getFormValue && setValue) {
      const newFiles = getFormValue("files")
      if (!val) {
        if (newFiles) {
          delete newFiles[field.name];
          setValue("files", newFiles)
        }
      } else {
        setValue("files", {...newFiles, [field.name]: val})
      }
    }

  }

  const getField = () => {
    if (field.single_value) {
      return (
        <div>
          {/*<LabelField field={field}/>*/}
          <FileComponent
            field={field}
            key={field.id}
            setValueField={setValueField}
            getValue={() => getDefaultValue(field)}/>
        </div>
      );
    } else {
      return (
        <div>
          {/*<LabelField field={field}/>*/}
          <MultiFilesInputComponent

            field={field} getFiles={() => getDefaultValue(field)}
            setValueField={setValueField}
            key={field.id}/>
        </div>
      );
    }
  }

  return getField()
};

export default CrmFileInputComponent