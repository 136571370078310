import 'react-dates/initialize';
import CurrencyComponent from "./CurrencyComponent";
import DateComponent from "./DateComponent";
import DocumentComponent from "./DocumentComponent";
import EmailComponent from "./EmailComponent";
import LargeTextComponent from "./LargeTextComponent";
import MultiFileComponent from "./MultiFileComponent";
import MultiSelectComponent from "./MultiSelectComponent";
import NumberComponent from "./NumberComponent";
import PasswordComponent from "./PasswordComponent";
import PhoneComponent from "./PhoneComponent";
import SelectComponent from "./SelectComponent";
import SingleFileComponent from "./SingleFileComponent";
import SmallTextComponent from "./SmallTextComponent";
import TimeComponent from "./TimeComponent";
import DateRangeComponent from "./DateRangeComponent";
import CheckboxComponent from "./CheckboxComponent";
import RadioButtonComponent from "./RadioButtonComponent";
import IField from "../../../types/FormBuilder/IField";
import ToDoListComponent from "./ToDoListComponent";
import CalendarPickerComponent from "./CalendarPicker/CalendarPickerComponent";

export const fields: any = [
  {
    sub_type: "TIME",
    single_value: true,
    field_type: "SMALL_TEXT",
    field: TimeComponent,
    defaultValue: "",
    errorDescription: "Debe escoger un tiempo valido."
  },
  {
    sub_type: 'CURRENCY',
    single_value: true,
    field_type: 'SMALL_TEXT',
    field: CurrencyComponent,
    defaultValue: "",
    errorDescription: "Datos no validos."
  },
  {
    sub_type: 'DOCUMENT',
    single_value: true,
    field_type: "SMALL_TEXT",
    field: DocumentComponent,
    defaultValue: "",
    errorDescription: "Datos no validos."
  },
  {
    sub_type: 'NUMBER',
    single_value: true,
    field_type: "SMALL_TEXT",
    field: NumberComponent,
    defaultValue: "",
    errorDescription: "Datos no validos."
  },
  {
    sub_type: 'PASSWORD',
    single_value: true,
    field_type: "SMALL_TEXT",
    field: PasswordComponent,
    defaultValue: "",
    errorDescription: "Datos no validos."
  },
  {
    sub_type: 'EMAIL',
    single_value: true,
    field_type: 'SMALL_TEXT',
    field: EmailComponent,
    defaultValue: "",
    errorDescription: "Email no valido."
  },
  {
    sub_type: 'PHONE',
    single_value: true,
    field_type: 'SMALL_TEXT',
    field: PhoneComponent,
    defaultValue: "",
    errorDescription: "Datos no validos."
  },
  {
    sub_type: 'SELECT',
    single_value: false,
    field_type: 'OPTIONS',
    field: MultiSelectComponent,
    defaultValue: null,
    errorDescription: "Datos no validos."
  },
  {
    sub_type: 'SELECT',
    single_value: true,
    field_type: 'OPTIONS',
    field: SelectComponent,
    defaultValue: null,
    errorDescription: "Datos no validos."
  },
  {
    field_type: "SMALL_TEXT",
    single_value: true,
    field: SmallTextComponent,
    defaultValue: "",
    errorDescription: "Datos no validos."
  },
  {
    field_type: 'LARGE_TEXT',
    single_value: true,
    field: LargeTextComponent,
    defaultValue: "",
    errorDescription: "Datos no validos."
  },
  {
    field_type: 'FILE',
    single_value: true,
    field: SingleFileComponent,
    defaultValue: null,
    errorDescription: "Datos no validos."
  },
  {
    field_type: 'FILE',
    single_value: false,
    field: MultiFileComponent,
    defaultValue: null,
    errorDescription: "Datos no validos."
  },
  {
    field_type: 'DATE',
    single_value: true,
    field: DateComponent,
    defaultValue: new Date(),
    errorDescription: "Fecha invalida."
  },
  {
    field_type: 'DATE_RANGE',
    single_value: true,
    field: DateRangeComponent,
    defaultValue: {startDate: new Date(), endDate: null},
    errorDescription: "Fecha invalida."
  },
  {
    field_type: 'OPTIONS',
    sub_type: "TOGGLE",
    single_value: false,
    field: CheckboxComponent,
    defaultValue: null,
    errorDescription: "datos invalidos."
  },
  {
    field_type: 'OPTIONS',
    sub_type: "TOGGLE",
    single_value: true,
    field: RadioButtonComponent,
    defaultValue: null,
    errorDescription: "datos invalidos."
  }, {
    field_type: 'OPTIONS',
    sub_type: "TO_DO_LIST",
    single_value: false,
    field: ToDoListComponent,
    defaultValue: null,
    errorDescription: "datos invalidos."
  },
  {
    field_type: 'APPOINTMENT_PICKER',
    single_value: false,
    field: CalendarPickerComponent,
    defaultValue: null,
    errorDescription: "datos invalidos."
  },
];

export const getFieldByField = (field: IField): any => {
  const component = fields.find((f: any) => (
    field.sub_type === f.sub_type &&
    field.field_type === f.field_type &&
    field.single_value === f.single_value
  ));
  return (component ? component : {defaultValue: ""})
}
