import * as React from 'react';
import {useContext} from 'react';
import CreatableSelectComponent from "../../Shared/FieldComponents/CreatableSelectComponent";
import {State} from "../../../types/State";
import {actionsReport} from "../Slice";
import useTranslate from "../../../hooks/useTranslate";
import {ReportContext} from "../NewReportGeneratorComponent";
import {ModelsResponse} from "../../../types/Sbx";

type Props = {
    loading: State
    models: ModelsResponse[]
};
const ModelDataComponent = ({loading, models}: Props) => {
    const {t} = useTranslate("report")
    const {state, dispatch, setQuery, query} = useContext(ReportContext)

    const setEventName = (model: string) => {
        dispatch(actionsReport.setBaseQuery({
            ...state.baseQuery,
            source: {
                ...state.baseQuery.source,
                with:  model
            }
        }))
        setQuery({
            ...query,
            truncate: "",
            source: {
                ...query.source,
                with: model
            }
        })
    }

    return (
        <>
            <div className="d-flex flex-column gap-2">
                {!query.source.from.toLowerCase().includes('google') && <>
                    <span className="card-header ">{t("common:model")}</span>
                    <div style={{width: "300px"}}>
                        <CreatableSelectComponent loading={loading === State.PENDING} menuPosition={"fixed"}
                                                  value={query.source.with ? {
                                                      label: query.source.with,
                                                      value: query.source.with
                                                  } : null}
                                                  disabled={loading === State.PENDING} id={""} name={""}
                                                  onCreateOption={evt => {
                                                      if (evt) {
                                                          setEventName(evt)
                                                      }
                                                  }}
                                                  onChange={evt => {
                                                      if (evt.value) {
                                                          const item = models.find(model => (model.id ?? model.name) === evt.value)

                                                          setEventName(item?.name ?? evt.value)
                                                      }


                                                  }} options={models?.map(model => ({
                            label: model.name,
                            value: model.id ?? model.name
                        }))}
                                                  placeholder={t("select_init_data")}/>
                    </div>
                </>}


                {
                    query.source.from.toLowerCase().includes('google') && <>
                        <span className="card-header ">{t("dimensions")}</span>
                        <div style={{width: "300px"}}>
                            <CreatableSelectComponent loading={loading === State.PENDING} menuPosition={"fixed"}
                                                      value={query.source.with ? {
                                                          label: query.source.with,
                                                          value: query.source.with
                                                      } : null}
                                                      disabled={loading === State.PENDING} id={""} name={""}
                                                      onChange={evt => {
                                                          if (evt.value) {
                                                              const item = models.find(model => (model.id ?? model.name) === evt.value)

                                                              dispatch(actionsReport.setBaseQuery({
                                                                  ...state.baseQuery,
                                                                  source: {
                                                                      ...state.baseQuery.source,
                                                                      with: item?.name ?? evt.value
                                                                  }
                                                              }))

                                                              setQuery({
                                                                  ...query,
                                                                  source: {
                                                                      ...query.source,
                                                                      with: item?.name ?? evt.value
                                                                  }
                                                              })
                                                          }


                                                      }} options={models?.map(model => ({
                                label: model.name,
                                value: model.id ?? model.name
                            }))}
                                                      placeholder={t("select_init_data")}/>
                        </div>
                    </>
                }
            </div>
        </>
    );
};

export default ModelDataComponent