import * as React from 'react';
import {useContext, useState} from 'react';
import {
    Controller,
    UseControllerProps,
    UseFieldArrayRemove,
    UseFieldArrayUpdate,
    UseFormGetValues,
    UseFormWatch
} from "react-hook-form";
import {ModelsResponse} from "../../types/Sbx";
import {DropdownItem} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faTimes, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import {ActionButton, ReportContext} from "./NewReportGeneratorComponent";
import {AnalyticQueryAction} from "../../types/Analytic";
import useTranslate from "../../hooks/useTranslate";
import DropdownActionComponent from "./NewActionsReportGeneratorComponent/DropdownActionComponent";
import {ReportSummarizeForm} from "./NewActionsReportGeneratorComponent/NewReportSummarizeComponent/ReportSummarizeComponent";
import {getColumnName} from "./NewActionsReportGeneratorComponent/utils";
import ColumnsActionsWrapperComponent from "./ColumnsActionsWrapperComponent";
import SearchSuggestionOption from "./SearchSuggestionOption";

type Props = {
    index: number
    remove: UseFieldArrayRemove,
    model?: ModelsResponse
    control: UseControllerProps<any>["control"]
    watch: UseFormWatch<ReportSummarizeForm>
    update: UseFieldArrayUpdate<ReportSummarizeForm, "conditions">
    indexAction?: number
    queryAction?: AnalyticQueryAction
    getValues: UseFormGetValues<ReportSummarizeForm>
    aggList: string[]
    color?: string
    baseName?: string
    loading?: boolean
    agg: {agg: string, column: string}
};

const SummarizeDropdownComponent = ({
                                        index,
                                        remove,
                                        model,
                                        control,
                                        update,
                                        watch,
                                        indexAction, loading,
                                        queryAction, agg, baseName = "conditions",
                                        getValues, color, aggList
                                    }: Props) => {
    const {state, getActionColumns} = useContext(ReportContext)
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const {t} = useTranslate("report")

    const toggle = () => {
        setDropdownOpen((prevState) => !prevState);
    }

    const [columns, setColumns] = useState<string[]>([])

    React.useEffect(() => {

        let nColumns: string[] = []

        if (typeof indexAction !== 'undefined' && queryAction) {
            nColumns = getActionColumns(queryAction, indexAction).map(column => column.value)
        } else {
            nColumns = [...state.baseColumns]
        }

        const columnsReport = getValues(`columns`)

        if (columnsReport && columnsReport.length > 0) {
            nColumns = nColumns.filter(column => !columnsReport.some(columnReport => columnReport!.name === column))
        }

        setColumns([...nColumns].sort((a, b) => a.localeCompare(b)))

    }, [queryAction, indexAction, state]);

    React.useEffect(() => {
        const subscription = watch((obj, {name, type}) => {
            if (obj[baseName as keyof typeof obj] && (obj[baseName as keyof typeof obj] as any[])!.length > 0 && (!(obj[baseName as keyof typeof obj] as any)[index]!?.agg || !(obj[baseName as keyof typeof obj] as any)![index]!?.column)) {

                setDropdownOpen(true)
            }
        });
        return () => subscription.unsubscribe();
    }, [watch, index]);

    const renderToggleMenu = (agg: { column: string, agg: string }) => {


        // console.log('agg', agg)
        // if (model && model.properties.length > 0) {
        if (agg.agg.length === 0) {
            return <>
                <DropdownItem header>{t("common:metrics")}</DropdownItem>
                {aggList.sort((a, b) => a.localeCompare(b)).map(agg => {
                    return <Controller key={agg} render={({field, fieldState, formState}) => {
                        return <DropdownItem onClick={() => {
                            field.onChange(agg)
                        }}>
                            {agg}
                        </DropdownItem>
                    }} control={control} name={`${baseName}.${index}.agg`}/>
                })}
            </>
        }


        // const field = model.properties.find(property => property.name === filter.field)

        return <div className="d-flex flex-column px-2 gap-2">
            <div className="d-flex align-items-center border-bottom py-2 gap-3 justify-content-between">

                <ActionButton className="btn" color={color ?? "white"} onClick={() => {
                    update(index, {...agg, agg: ""})
                }}>
                    <FontAwesomeIcon icon={faArrowLeft} className="pointer white"/>
                </ActionButton>

                <DropdownItem header>{model?.name ?? ""}</DropdownItem>

                <ActionButton className="btn" color={'transparent'} onClick={() => {
                    toggle()
                }}>
                    <FontAwesomeIcon icon={faTimes} className="pointer" color={'#141414'}/>
                </ActionButton>

            </div>

            <ColumnsActionsWrapperComponent>
                {search => {
                    return <>
                        {loading && <DropdownItem disabled header>
                            {t('common:loading')} {t("common:columns")}
                        </DropdownItem>}
                        {
                            columns.filter(column => column.toLowerCase().includes(search.toLowerCase())).map(property =>
                                <Controller key={property} control={control}
                                            render={({field, fieldState, formState}) => {
                                                return <DropdownItem
                                                    className="d-flex gap-2 align-items-center"
                                                    onClick={() => {
                                                        field.onChange(property)
                                                        toggle()
                                                    }}>

                                                    {getColumnName({
                                                        name: property,
                                                        columnsType: state.columnsType,
                                                        index: indexAction ?? 0
                                                    })}

                                                </DropdownItem>
                                            }} name={`${baseName}.${index}.column`}/>)
                        }
                        {search && <Controller
                            key={"search"} control={control}
                            render={({field}) => {
                                return <SearchSuggestionOption search={search} onChange={field.onChange}
                                                               toggle={toggle}/>
                            }} name={`${baseName}.${index}.column`}
                        />}
                    </>
                }}
            </ColumnsActionsWrapperComponent>


        </div>


        // }

        // return <span/>
    }

    const getFilterName = () => {
        // const agg = watch(`${baseName}.${index}`)
        return <span className="d-flex align-items-center gap-2">
            {agg.column ? <span>{agg.agg} of {agg.column}</span> : "New summarize " + (index + 1)}
            {agg && <FontAwesomeIcon icon={faTimesCircle} className="pointer" onClick={() => {
                remove(index)
            }}/>}
        </span>
    }


    return <DropdownActionComponent dropdownStyle={{background: color ?? "#5e72e4", borderColor: color ?? "#5e72e4"}}
                                    isOpen={dropdownOpen} basicConfigMenu={agg.agg.length > 0}
                                    toggle={toggle} header={getFilterName()}>
        {renderToggleMenu(agg)}
    </DropdownActionComponent>

};

export default SummarizeDropdownComponent