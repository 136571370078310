import * as React from 'react';
import {useContext, useMemo, useState} from 'react';
import {DropdownItem} from "reactstrap";
import {Controller, UseControllerProps, UseFieldArrayRemove, UseFieldArrayUpdate, UseFormWatch} from "react-hook-form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import {ReportContext} from "../../NewReportGeneratorComponent";
import {AnalyticQueryAction} from "../../../../types/Analytic";
import useTranslate from "../../../../hooks/useTranslate";
import {format} from "date-fns";
import ButtonComponent from "../../../Shared/ButtonComponent";
import DropdownActionComponent from "../DropdownActionComponent";
import ColumnsActionsWrapperComponent from "../../ColumnsActionsWrapperComponent";
import {getColumnName} from "../utils";
import SearchSuggestionOption from "../../SearchSuggestionOption";
import {dateTransformList} from "../../../../utils/analyticsUtils";

type Props = {
    index: number
    remove: UseFieldArrayRemove,
    control: UseControllerProps<any>["control"]
    action: AnalyticQueryAction
    indexAction?: number
    color?: string
    watch: UseFormWatch<{
        transforms: {
            name: string
            transform: string
        }[]
    }>
    update: UseFieldArrayUpdate<{
        transforms: {
            name: string
            transform: string
        }[]
    }, "transforms">
};


const DateTransformMenuComponent = ({index, remove, control, update, watch, action, indexAction, color}: Props) => {

    const {getActionColumns, state} = useContext(ReportContext)
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => {
        setDropdownOpen((prevState) => !prevState);
    }

    const {t} = useTranslate("common")


    React.useEffect(() => {
        const subscription = watch((obj, {name, type}) => {
            if (obj["transforms"] && obj["transforms"].length > 0 && obj["transforms"][index] && !obj["transforms"][index]!.name) {
                setDropdownOpen(true)
            }
        });
        return () => subscription.unsubscribe();
    }, [watch, index]);

    const dateList = useMemo(() => {
        const today = new Date()
        return [...dateTransformList.map(item => ({
            ...item,
            label_render: <span></span>
        }))].map(item => {
            item.label_render =
                <span> <b>{t(`report:${item.label}`)}:</b> {format(today, item.format ?? "") ?? ""}</span>

            return item
        })
    }, [])



    const getFilterName = () => {
        const filter = watch(`transforms.${index}`)

        const format = filter?.transform.split(" ").at(-1)
        const label = dateList.find(date => date.value === format)?.label ??  ""

        return <span className="d-flex align-items-center gap-2">
            {filter.name && filter.transform ? `${t("convert")} ${filter.name} ${t("to_1")} '${label ? t(`report:${label}`) : format}'` : `${t("report:new_transformacion_date")} ` + (index + 1)}
            {filter && <FontAwesomeIcon icon={faTimesCircle} className="pointer" onClick={() => {
                remove(index)
            }}/>}
        </span>
    }


    const renderToggleMenu = (transform: {
        name: string
        transform: string
    }) => {

        if (typeof indexAction !== "undefined") {

            if (!transform.name) {
                return <>
                    <DropdownItem header>{t("models")}</DropdownItem>
                    <DropdownItem divider/>
                    <ColumnsActionsWrapperComponent>
                        {search => {
                            return <>
                                {getActionColumns(action, indexAction).filter(column => column.label.toLowerCase().includes(search.toLowerCase())).map(column => {
                                    return <Controller
                                        key={column.value} control={control}
                                        render={({field}) => {
                                            return <DropdownItem
                                                className="d-flex gap-2 align-items-center"
                                                onClick={() => {
                                                    field.onChange(column.value)
                                                }}>

                                                {
                                                    getColumnName({
                                                        name: column.label,
                                                        index: indexAction,
                                                        columnsType: state.columnsType
                                                    })
                                                }
                                            </DropdownItem>
                                        }} name={`transforms.${index}.name`}/>
                                })}

                                {search && <Controller
                                    key={"search"} control={control}
                                    render={({field}) => {
                                        return <SearchSuggestionOption search={search} onChange={field.onChange} toggle={toggle} />
                                    }} name={`transforms.${index}.name`}
                                />}
                            </>
                        }}
                    </ColumnsActionsWrapperComponent>

                </>
            }

            return <>
                <DropdownItem header className="d-flex gap-2 align-items-center">
                    <FontAwesomeIcon icon={faArrowLeft} className="pointer" onClick={() => {
                        update(index, {...transform, name: ""})
                    }}/>
                    {t("report:transform")}
                </DropdownItem>
                <DropdownItem divider/>

                <DropdownItem disabled>
                    {t("report:date_example")}: {t("today")}
                </DropdownItem>


                {dateList.map(date => (
                    <Controller
                        key={date.value} control={control}
                        render={({field}) => {
                            return <DropdownItem
                                className="d-flex gap-2 align-items-center"
                                onClick={() => {

                                    field.onChange(`@date_to_formateddate ${transform.name} ${date.value}`)
                                    toggle()
                                    // field.onChange(`${field.value}.${property.name}`)
                                }}>
                                {date.label_render}
                            </DropdownItem>
                        }} name={`transforms.${index}.transform`}/>
                ))}

                <Controller
                    key={'custom'} control={control}
                    render={({field}) => {
                        return <DropdownItem>
                            <input className=" form-control" defaultValue={field.value.split(" ").at(-1) || ""} onChange={evt => {
                                field.onChange(`@date_to_formateddate ${transform.name} ${evt.target.value}`)
                            }} placeholder={t("custom_format")}/>
                        </DropdownItem>
                    }} name={`transforms.${index}.transform`}/>


                <div className="d-flex justify-content-end px-3">
                    <ButtonComponent label={t("save")} color={"primary"} onClick={() => {
                        toggle()
                    }}/>
                </div>
            </>

        }

        return <span/>
    }

    return (
        <DropdownActionComponent basicConfigMenu dropdownStyle={{background: color ?? "#5e72e4", borderColor: color ?? "#5e72e4"}}  isOpen={dropdownOpen} toggle={toggle} header={getFilterName()}>
            {renderToggleMenu(watch(`transforms.${index}`))}
        </DropdownActionComponent>
    );
};

export default DateTransformMenuComponent