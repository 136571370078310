import React from 'react';
import {Source} from '../../../types/Analytic';
import {UpdateSource} from './SourceComponent';
import SourceItemComponent from './SourceItemComponent';

type Props = {
  sources: Source[]
  updateSource: (sources: UpdateSource[]) => void
  currentSource: Source
  setCurrentSource: (source: Source) => void
}

const SourcesListComponent = ({sources, updateSource, setCurrentSource, currentSource}: Props) => {
  return (
    <>
      {sources.length > 0 &&
        sources.map((source, index) => (
          <SourceItemComponent source={source} key={source.temporal_id+"_"+index} currentSource={currentSource} setCurrentSource={setCurrentSource} sources={sources} updateSource={updateSource}/>
        ))
      }
    </>
  );
};

export default SourcesListComponent;
