import * as React from 'react';
import {useMemo, useRef} from 'react';
import {Offcanvas, OffcanvasBody, OffcanvasHeader} from "reactstrap";
import useTranslate from "../../hooks/useTranslate";
import {ActionType, AnalyticQueryAction, Report} from "../../types/Analytic";
import ButtonComponent from "../Shared/ButtonComponent";
import {
    faFilter,
    faLayerGroup,
    faMousePointer,
    faSort,
    faSuperscript,
    faSyncAlt
} from "@fortawesome/free-solid-svg-icons";
import {ActionButton, ReportContext} from "../NewReportGeneratorComponent/NewReportGeneratorComponent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {removeDuplicateFromArray, uuidV4} from "../../utils";
import {useFieldArray, useForm} from "react-hook-form";
import NewActionsReportGeneratorComponent
    from "../NewReportGeneratorComponent/NewActionsReportGeneratorComponent/NewActionsReportGeneratorComponent";
import {baseQuery, initialState} from "../NewReportGeneratorComponent/Slice";
import {Column} from "../Shared/CustomTableComponent/CustomTableComponent";
import {getColumnsReport} from "../../utils/analyticsUtils";

type Props = {
    isOpen: boolean;
    toggle: () => void;
    results: any[];
    setResults: (results: any[]) => void;
    allColumns:  Column[];
    setCurrentColumns: (columns: Column[]) => void;
    allResults: any[];
    setFilters: (filters: AnalyticQueryAction[]) => void;
    filters: AnalyticQueryAction[];
    report?: Report
};

type Form = {
    filters: AnalyticQueryAction[]
    columns: string[][]
}

const operationFunc: { [key: string]: (arr: any[]) => number } = {
    sum: (arr: any[]) => arr.reduce((a: any, b: any) => a + b),
    avg: (arr: any[]) => arr.reduce((a: any, b: any) => a + b) / arr.length,
    count: (arr: any[]) => arr.length,
    max: (arr: any[]) => arr.reduce((a: any, b: any) => Math.max(a, b)),
    min: (arr: any[]) => arr.reduce((a: any, b: any) => Math.min(a, b)),
    nunique: (arr: any[]) => arr.filter((value, index, self) => self.indexOf(value) === index).length,
    std: (arr: any[]) => Math.sqrt(arr.reduce((a: any, b: any) => a + b) / arr.length),
    first: (arr: any[]) => arr[0],
    last: (arr: any[]) => arr[arr.length - 1],
    cumsum: (arr: any[]) => arr.reduce((a: any, b: any) => a + b),
    mediam: (arr: any[]) => arr.sort((a, b) => a - b)[Math.floor(arr.length / 2)],
}

const ReportFiltersByQueryComponent = ({
                                           isOpen,
                                           toggle,
                                           results,
                                           allResults,
                                           setResults,
                                           setFilters,
    setCurrentColumns, allColumns, report,
                                           filters
                                       }: Props) => {

    const {t} = useTranslate('common');
    const {control, setValue, watch, getValues, reset} = useForm<Form>()
    const {fields, append, remove} = useFieldArray({
        name: 'filters',
        control
    })
    const actionList = useMemo(() => [
        {
            label: t('report:select_columns'),
            value: 'select',
            icon: faMousePointer,
            color: "#55c2da",
            textColor: "white"
        },
        {label: t('report:filter'), value: 'filter', icon: faFilter, color: "#4681f4", textColor: "white"},
        {label: t('report:sort'), value: 'sort', icon: faSort, color: "#5783db", textColor: "white"},
        {label: t('report:limit'), value: 'limit', icon: faSuperscript, color: "#33b249", textColor: "white"},
        {label: t('report:group_by'), value: 'group_by', icon: faLayerGroup, color: "#5adbb5", textColor: "white"}
    ], [])
    let onMount = useRef(false)
    const watchFieldArray = watch("filters");
    const controlledFields = fields.map((field, index) => {
        return {
            ...field,
            ...watchFieldArray[index]
        };
    });

    const getAndSetColumns = (filters: AnalyticQueryAction[]) => {
        const allColumns: string[][] = []
        if (filters.length > 0) {
            let index = 0

            while (index <= filters.length - 1) {

                const filterSliced = filters.slice(0, index)

                if (filterSliced.length > 0) {
                    let selectedColumns: string[] = []
                    filterSliced.forEach((filter, index) => {
                        switch (filter.type) {
                            case "select": {
                                const columns = filter.columns as string[]
                                if (columns) {
                                    selectedColumns = [...columns]
                                }
                            }
                                break;
                            case 'group_by': {
                                const columns = filter.columns as string[]
                                const agg_columns = Object.keys(filter.agg ?? {}).reduce((arr: string[], keyAgg) => {
                                    if (filter.agg![keyAgg]) {
                                        (filter.agg![keyAgg] as string[]).forEach((operation: string) => {
                                            arr.push(`${keyAgg}_${operation}`)
                                        })
                                    }

                                    return arr;
                                }, [])

                                if (columns) {
                                    selectedColumns = [...columns, ...agg_columns]
                                }
                            }
                                break;
                            default: {
                                if (selectedColumns.length > 0) {
                                    allColumns.push(removeDuplicateFromArray(selectedColumns))
                                } else {
                                    allColumns.push(Object.keys(results[0]))
                                }
                            }

                                break;

                        }
                    })

                    allColumns.push(selectedColumns)
                } else {
                    allColumns.push(Object.keys(allResults[0]))
                }
                index++
            }

            setValue('columns', allColumns)
        }
    }

    const getDefaultAction = (actionType: ActionType) => {
        let baseAction: AnalyticQueryAction = {type: actionType, temporal_id: uuidV4()};
        const action: { [action_type: string]: AnalyticQueryAction } = {
            limit: {
                ...baseAction,
                top: 10
            },
            sort: {
                ...baseAction,
                columns: [],
                ascending: true
            },
            select: {
                ...baseAction,
                columns: []
            },
            group_by: {
                ...baseAction,
                agg: {},
                columns: []
            },
            filter: {
                ...baseAction,
                filter: ""
            },
        }

        if (action[actionType]) {
            append(action[actionType])
        }

        getAndSetColumns(getValues("filters"))
    };

    const getActionColumns = (action: AnalyticQueryAction, index: number) => {
        const columns = getValues('columns')

        if (columns && columns[index]) {
            return columns[index]?.map((column) => ({label: column, value: column}))
        }

        return []
    }

    const setQueryAction = (action: AnalyticQueryAction, index: number) => {
        setValue(`filters.${index}`, action)
    }

    const applyFilters = async () => {
        let nResults = [...allResults]
        let columns = [...allColumns]
        const filters = getValues("filters")
        filters.forEach((filter) => {
            switch (filter.type) {
                case "select": {
                    const newColumns = filter.columns as string[]
                    if (newColumns) {
                        nResults = nResults.map((result) => {
                            return newColumns.reduce((obj: any, column: string) => {
                                obj[column] = result[column]
                                return obj
                            }, {})
                        })
                        columns = columns.filter((column) => newColumns.includes(column.name))
                    }
                }
                    break;
                case "filter": {

                    let nFilter =(filter.filter as string).toLowerCase()

                    if(nFilter.includes("contains")){
                        nFilter = nFilter.replaceAll(".str.contains", '.includes')
                    }


                    (nFilter ? nFilter?.split(/!==|===|>|<|>=|<=|==|\(|\)|&|\||\.includes/g) : []).forEach((item) => {

                        if (allResults.length > 0 && Object.keys(allResults[0]).includes(item.trim())) {
                            nFilter = nFilter.replaceAll(item, `item["${item.trim()}"]`)
                        }
                    })



                    nFilter = nFilter.split(/(\(|\)|&|\|)/).reduce((arr: string[], item) => {
                        if (["!=", "=="].some(op => item.includes(op))) {
                            const [var1, condition, var2] = item.split(/(!=|==)/)

                            if (var1 === var2) {
                                const op = condition === "==" ? "!==" : "==="
                                arr.push(...[`${var1.trim()} ${op} ""`, `& ${var1.trim()} ${op} null`, ` & ${var1.trim()} ${op} undefined`])
                            }else{
                                arr.push(item)
                            }
                        } else {
                            arr.push(item)
                        }

                        return arr

                    }, []).join("")


                    nResults = nResults.map(item => {
                        item = JSON.parse(JSON.stringify(item).toLowerCase())
                        return item
                    }).filter((item) => {
                        try {
                            return eval(nFilter as string) ?? false
                        } catch (e) {
                            return false
                        }
                    })
                }
                    break;
                case "sort": {
                    const sortColumns = filter.columns as string[]
                    if (sortColumns) {
                        nResults = nResults.sort((a, b) => {
                            for (let prop of sortColumns) {
                                const comparison = filter.ascending ? 1 : -1;
                                if (a[prop] < b[prop]) return -1 * comparison;
                                if (a[prop] > b[prop]) return 1 * comparison;
                            }
                            return 0;
                        });
                    }
                }
                    break;
                case "limit": {
                    nResults = nResults.slice(0, filter.top as number)
                }
                    break;
                case 'group_by': {
                    const columns = filter.columns as string[]
                    let obj: { [key: string]: any[] } = {}
                    nResults.forEach(result => {
                        let key = ""

                        columns.forEach(column => {
                            key += result[column]
                        })

                        if (!obj[key]) {
                            obj[key] = []
                        }

                        obj[key].push([...columns, ...Object.keys(filter.agg ?? {})].reduce((obj: any, column: string) => {
                            obj[column] = result[column]
                            return obj
                        }, {}))
                    })


                    nResults = Object.keys(obj).map((key) => {
                        const item = obj[key][0]

                        Object.keys(filter.agg ?? {}).forEach((keyAgg) => {
                            if (filter.agg![keyAgg]) {
                                (filter.agg![keyAgg] as string[]).forEach((operation: string) => {
                                    if (!item[`${keyAgg}_${operation}`]) {
                                        item[`${keyAgg}_${operation}`] = 0
                                    }

                                    const values = obj[key].map((item: any) => item[keyAgg])
                                    if (operationFunc[operation]) {
                                        item[`${keyAgg}_${operation}`] = operationFunc[operation](values)
                                        delete item[keyAgg]
                                    }
                                })
                            }

                        })


                        return item


                    })
                }
                    break
                default:
                    break;
            }
        })


        if  (columns.length !== allColumns.length){
            setCurrentColumns(columns)
        }else{
            let columns = allResults.length > 0 ? Object.keys(allResults[0]).reduce((columns: Column[], column) => {
                const localColumn = columns.find(nColumn => nColumn.name === column)
                if (localColumn) {
                    columns.push(localColumn)
                } else {
                    columns.push({name: column, header: column})
                }
                return columns
            }, []) : []

            if (report && columns.length > 0) {

                const response = await getColumnsReport(report, allResults, [])
                if (response?.columns.length > 0) {
                    columns = response.columns.reduce((newColumns: Column[], column) => {
                        const nColumn = columns.find(nColumn => nColumn.name === column.name)
                        if (nColumn) {
                            newColumns.push(nColumn)
                        }

                        return newColumns
                    }, [])


                }
            }

            setCurrentColumns(allColumns.length > 0 ? allColumns : columns)
        }

        setResults(nResults)
    }

    React.useEffect(() => {
        const filtersInputs = getValues("filters")
        if (filters.length > 0 && filtersInputs.length === 0 && !onMount.current) {
            setValue('filters', filters)
            getAndSetColumns(filters)
            onMount.current = true
        }
    }, [filters]);

    // Callback version of watch.  It's your responsibility to unsubscribe when done.
    React.useEffect(() => {
        const subscription = watch((form) => {
                if (form['filters']) {
                    setFilters(form['filters'] as AnalyticQueryAction[] ?? [])
                }
            }
        )
        return () => subscription.unsubscribe()
    }, [watch])


    return (
        <div>
            <Offcanvas style={{minWidth: "50vw"}} isOpen={isOpen}>
                <OffcanvasHeader toggle={toggle} className="d-flex justify-content-end border-bottom">
                    <div className="d-flex justify-content-end gap-1">
                        <ButtonComponent label={t("clear")} onClick={() => {

                            setResults(allResults)
                            setCurrentColumns(allColumns)
                            setFilters([])
                            reset({
                                filters: []
                            })
                        }} color={"danger"} icon={faSyncAlt}/>

                        <ButtonComponent label={t("apply")} onClick={applyFilters} color={"success"} icon={faFilter}/>
                    </div>
                </OffcanvasHeader>
                <OffcanvasBody>
                    <div className="d-flex flex-column">
                        <div className="card-header mb-2">
                            <b>{t("actions")}</b>
                        </div>
                        <div className="d-flex flex-wrap gap-2">
                            {actionList.map((item, index) => (
                                <ActionButton key={item.value} className="btn "
                                              color={item.color}
                                              textColor={item.textColor}
                                              onClick={() => {
                                                  getDefaultAction(item.value as ActionType)
                                              }}>
                                    <FontAwesomeIcon icon={item.icon}/>
                                    <span>{item.label}</span>
                                </ActionButton>
                            ))}
                        </div>


                        <div className="my-3 ">
                            <ReportContext.Provider value={{
                                state: initialState,
                                query: {
                                    source: {...baseQuery, temporal_id: uuidV4(), filters: []},
                                    actions: [],
                                    preview: true
                                },
                                dispatch: (action: any) => {
                                },
                                setQuery: (query) => {
                                },
                                setQueryAction,
                                getData: () => {
                                },
                                getAndSetAllColumns: async () => {
                                },
                                getColumns: async () => ({history: [], items: [], types: {data: {}, history: []}}),
                                getLoadingAndDisabledAction: ({action, index}) => false,
                                getActionColumns
                            }}>
                                <NewActionsReportGeneratorComponent isReportGenerator={false} actions={controlledFields} removeAction={remove}
                                                                    actionList={actionList}/>
                            </ReportContext.Provider>
                        </div>

                    </div>
                </OffcanvasBody>
            </Offcanvas>
        </div>
    );
};

export default ReportFiltersByQueryComponent