import React, {FormEvent, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../../store/Reducers';
import {useRouter} from 'next/router';
import {capitalize} from '../../../utils';
import useTranslate from '../../../hooks/useTranslate';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSearch} from '@fortawesome/free-solid-svg-icons';
import useAsyncEffect from '../../../hooks/useAsyncEffect';
import {actionHistory} from '../../../store/SearchReducer';
import useRouterLoadingEvents from '../../../hooks/useRouterLoadingEvents';
import {State} from '../../../types/State';
import {routerActions} from '../../../store/RouterReducer';
import {START_PAGE_ON} from "../../../pages";
import {authReducer} from '../../../store/Selectors';


const initialData = {parent: "", child: "", icon: "", active: ""}

const HeadDynamicComponent = () => {

  const {routes, breadcrumb, activeView} = useSelector((s: RootState) => s.RouterReducer);
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const {text} = useSelector((state: RootState) => state.SearchReducer);
  const {user} = useSelector(authReducer);
  const {t} = useTranslate("routes");
  const [{parent, child, active}, setData] = useState(initialData)
  const router = useRouter();
  const [routerChanged] = useRouterLoadingEvents();
  const [loading] = useRouterLoadingEvents();

  useAsyncEffect(() => {
    if (routerChanged) {
      dispatch(actionHistory.validateData());
    }
  }, [dispatch, routerChanged])

  function onsubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    dispatch(actionHistory.changeText(search))
  }

  useEffect(() => {
    if (loading === State.PENDING) {
      dispatch(routerActions.changeBreadcrumb([]));
    }
  }, [loading]);

  useEffect(() => {
    setSearch(text);
  }, [text]);

  useEffect(() => {
    let parent = "";
    let child: any;
    let icon = "";
    const path = (router.pathname.split("?").shift() + "");

    routes.forEach(p => {
      p.views?.forEach(c => {
        const sPath = ((c.root !== "/" ? c.root : "") + c.path)
        if (sPath === path || sPath === path+"/") {
          child = c;
        } else if (path.includes(sPath) && !child && sPath.length > 0) {
          child = c;
        }

      });

      if (child && !parent) {
        parent = p.name;
        icon = p.icon;
      }

    });

    if (!parent && !child) {
      routes.forEach(p => {
        if (!child) {
          child = p.views?.find(c => {
            return c.root && c.root !== "/" && path.includes(c.root);
          });
          if (child) {
            parent = p.name;
            icon = p.icon;
          }
        }
      })
    }

    setData({parent, child: child?.name || "", icon, active})
    return () => {
      setData(initialData);
    }
  }, [routes, router]);

  function onRouterEvent(e: any, path?: string) {
    e.preventDefault();
    router.push(path ?? (user.config?.sbx_crm.startPage?user.config?.sbx_crm.startPage:START_PAGE_ON))
  }

  return (
    <div className="app-page-title py-2">
      <div className="page-title-wrapper">
        <div className="page-title-heading">
          <div>
            <div className="page-title-subheading opacity-10">
              {breadcrumb && breadcrumb.length ? (
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb align-items-center">
                    <li className="breadcrumb-item mt-1">
                      <button
                        className="btn btn-light btn-sm pt-2 "
                        onClick={() => router.back()}>
                        <i aria-hidden="true" className="fa fa-arrow-left"/>
                      </button>
                    </li>
                    <li className="breadcrumb-item mt-1">
                      <a className="pointer-event" href="#" onClick={onRouterEvent}>
                        <i aria-hidden="true" className={"fa fa-home"}/>
                      </a>
                    </li>
                    {breadcrumb.map((bread, index) => {
                      return (
                        <li className={"breadcrumb-item mt-1 " + (!bread.active ? "text-dark" : "")} key={bread.path+"_"+index}>
                          <a className={!bread.active ? "pointer" : ""}
                             onClick={() => bread.path && router.push(bread.path)}>{bread.label}</a>
                        </li>
                      )
                    })}
                  </ol>
                </nav>
              ) : (
                <nav className="" aria-label="breadcrumb">
                  <ol className="breadcrumb align-items-center">
                    <li className="breadcrumb-item mt-1">
                      <button
                        className="btn btn-light btn-sm pt-2 "
                        onClick={() => router.back()}>
                        <i aria-hidden="true" className="fa fa-arrow-left"/>
                      </button>
                    </li>
                    <li className="breadcrumb-item mt-1">
                      <a href="#" className="pointer-event text-dark" onClick={onRouterEvent}>
                        <i aria-hidden="true" className={"fa fa-home"}/>
                      </a>
                    </li>
                    <li className="breadcrumb-item mt-1">
                      <a className="pointer text-dark">{capitalize(t(parent))}</a>
                    </li>
                    {!!child && <li className={"breadcrumb-item mt-1 " + (!active ? " active " : "")}>
                      <a href="#" className={!active ? "" : "pointer-event"}
                         onClick={e => {
                           e.preventDefault();
                           if (active) onRouterEvent(e, `/${parent}/${child}`);
                         }}>{capitalize(t(child))}</a>
                    </li>}
                    {!!active && <li className=" breadcrumb-item mt-1 active" aria-current="page">
                      {capitalize(activeView || active)}
                    </li>}
                  </ol>
                </nav>
              )}
            </div>
          </div>
        </div>
        <div className="page-title-actions">
          <div className="d-inline-block pe-3">
            <form key="1000" onSubmit={onsubmit}>
              <div
                className="position-relative form-control form-control-sm rounded-pill d-flex align-items-center w-100">
                <input
                  value={search}
                  onChange={event => setSearch(event.target.value)}
                  placeholder={t("common:search")}
                  type="text"
                  className="form-search"/>
                <button className="btn btn-link text-dark p-0 m-0">
                  <FontAwesomeIcon
                    size={"1x"}
                    icon={faSearch}/>
                </button>
              </div>
              <button className="d-none"/>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}


export default HeadDynamicComponent;
