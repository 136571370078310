import * as React from 'react';
import {useContext} from 'react';
import {ActionButton, ReportContext} from "./NewReportGeneratorComponent";
import {getAnalyticQueryFlat} from "../../utils/analyticsUtils";
import {BaseAnalyticQuery, TruncateReport} from "../../types/Analytic";
import {actionsReport} from "./Slice";
import {State} from "../../types/State";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlay, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import useTranslate from "../../hooks/useTranslate";
import CustomTableComponent from "../Shared/CustomTableComponent/CustomTableComponent";

type Props = {};
const VisualizeResultsComponent = (props: Props) => {
    const {dispatch, getData, query, state} = useContext(ReportContext)
    const {t} = useTranslate("common")


    return (

        <div style={{zIndex: 2100}}>
            {state.showResults && <div className="overlay" /> }
            <ActionButton className="btn" color={state.showResults ? "red" : "#55c2da"} textColor={"white"} style={{zIndex: 1100}}
                          onClick={() => {
                              if (state.showResults) {
                                  dispatch(actionsReport.setShowResults(false))

                              } else {

                                  let nQuery = getAnalyticQueryFlat({...query}) as BaseAnalyticQuery

                                  if (!nQuery.truncate || !(nQuery.truncate as TruncateReport)?.model || nQuery.truncate === "{}") {
                                      delete nQuery.truncate
                                  }

                                  nQuery.actions = nQuery.actions.flat().filter(action => action.type !== "async_ml")

                                  getData({
                                      query: nQuery,
                                      setLoading: (nState) => {
                                          const newState: {
                                              addState?: State,
                                              removeState?: State
                                          } = {}

                                          newState.addState = nState

                                          if (nState !== State.PENDING) {
                                              newState.removeState = State.PENDING
                                          }

                                          dispatch(actionsReport.setState(newState))
                                      },
                                      setResults: (results) => dispatch(actionsReport.setResults(results))
                                  })

                                  dispatch(actionsReport.setShowResults(true))
                              }
                          }}>
                <span>{t(state.showResults ? "close" : "report:visualize")}</span>
                <FontAwesomeIcon icon={state.showResults ? faTimesCircle : faPlay}/>
            </ActionButton>


            <div className="position-relative fw-normal d-flex flex-grow-1 bg-white border rounded shadow">
                {/*state.showResults ? {top: 0, visibility: "visible", opacity: 1} : {top: "110%", visibility: "hidden", opacity: 0}*/}
                <div style={{
                    zIndex: 2100,
                    right: 0, width: "90vw", ...{
                        ...(state.showResults ? {
                            top: 0,
                            visibility: "visible",
                            // opacity: 1
                        } : {top: "110%", visibility: "hidden", opacity: 0})
                    }
                }}
                     className="position-absolute  bg-white shadow">


                    <div className="card p-3">
                        <div className="card-header ">
                            {t("preview")}
                        </div>


                        <CustomTableComponent
                            columns={state.results && state.results[0] ? Object.keys(state.results[0]).map(item => ({
                                name: item,
                                header: item
                            })) : []}
                            data={state.results?.length > 0 ? state.results : []}
                            loading={state.state.includes(State.PENDING)}
                        />
                    </div>

                </div>

            </div>


        </div>

    );
};

export default VisualizeResultsComponent