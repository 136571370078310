import React, {useContext, useState} from 'react';
import {Button} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus, faTrash} from '@fortawesome/free-solid-svg-icons';
import CustomTableComponent, {Action} from '../../Shared/CustomTableComponent/CustomTableComponent';
import ActionRenameModal from './ActionRenameModal';
import {AnalyticQuery, AnalyticQueryAction} from '../../../types/Analytic';
import useTranslate from '../../../hooks/useTranslate';
import {ReportGeneratorContext} from '../ReportGeneratorComponent';
import {State} from '../../../types/State';
import {OnChangeActionParams} from "./ActionComponent";

type Props = {
  onChangeAction: (actions: OnChangeActionParams[]) => void
  action: AnalyticQueryAction
}

const ActionRenameComponent = ({onChangeAction, action}: Props) => {

  const {reportState: {analyticQuery, isLoading, actionLoading}, dispatchForm, getActionColumns} = useContext(ReportGeneratorContext);
  const [renameColumns, setRenameColumns] = useState<{ current_name: string, new_name: string }[]>([]);
  const [showRenameModal, setShowRenameModal] = useState(false);
  const {t} = useTranslate('common');

  React.useEffect(() => {

    if (action.renamed_columns){
      const columnsR = Object.keys(action.renamed_columns).map(column => {
        return {
          current_name: column,
          new_name: action.renamed_columns ? action.renamed_columns[column] : ""
        }
      })

      setRenameColumns(columnsR)
    }

  }, [action]);

  const deleteColumn = (current_name: string, new_name: string) => {
    setRenameColumns(prevState => prevState.filter(nColumn => nColumn.new_name !== new_name))
    const newAction = {...action};
    if (newAction.renamed_columns) {
      newAction.renamed_columns = Object.keys(newAction.renamed_columns).reduce((obj: { [current_column: string]: string }, columnKey) => {
        if (columnKey !== current_name && newAction.renamed_columns) {
          obj[columnKey] = newAction.renamed_columns[columnKey];
        }
        return obj;
      }, {});
    }

    const actions = analyticQuery.actions.map(queryAction => {

      let subIndex = queryAction.findIndex(subAction => subAction.temporal_id === action.temporal_id)

      queryAction[subIndex] = newAction

      return queryAction
    })

    // getColumns({...analyticQuery, actions})
    const newQuery: AnalyticQuery = {...analyticQuery, actions};
    dispatchForm({name: 'analyticQuery', value: newQuery});
  }

  const renameTableActions: Action[] = [
    {
      label: <span><FontAwesomeIcon icon={faTrash}/> Remove</span>,
      type: 'danger',
      onAction: (column: {current_name: string, new_name: string}) => {
        deleteColumn(column.current_name, column.new_name)
      }
    }
  ];

  return (
    <div className="d-flex flex-column gap-2">
      <div className="text-end">
        <Button color="primary" size={'sm'} onClick={() => setShowRenameModal(true)}>
          <FontAwesomeIcon icon={faPlus}/> {t('add')}
        </Button>
      </div>

      <CustomTableComponent useLocalPage columns={[{name: 'current_name', header: 'Current column name'}, {
        name: 'new_name',
        header: 'New column name'
      }]} data={renameColumns} actions={renameTableActions}/>
      {showRenameModal &&
      <ActionRenameModal isOpen={showRenameModal} isLoading={(action.temporal_id && actionLoading[action.temporal_id] === State.PENDING) || isLoading === State.PENDING} renameColumns={renameColumns} deleteColumnAction={deleteColumn} setColumns={(newColumns) => {
        setRenameColumns(newColumns);
        onChangeAction([{
          action,
          actionKey: 'renamed_columns',
          value: newColumns.reduce((obj: { [key: string]: string }, column) => {
            obj[column.current_name] = column.new_name;
            return obj;
          }, {})
        }]);
      }} columns={getActionColumns(action)} toggle={() => setShowRenameModal(false)}/>}
    </div>
  );
};

export default ActionRenameComponent;
