import * as React from 'react';
import {DropDownSimple} from "../../../LayoutComponenents/DropDownComponent";
import ActionDropdownBodyComponent from "./ActionDropdownBodyComponent";

type Props = {
    onChange: (value: string) => void,
    columns: string[],
    showType?: boolean,
    language?: "common" | "report"
    label: string | React.ReactElement,
    index?: number,
    toggle?: () => void,
    loading?: boolean
    disabled?: boolean
    color?: string
    children?: React.ReactElement
    showCaret?: boolean
};
const ActionDropdownColumns = ({
                                   columns,
                                   color,
                                   index,
                                   toggle,
                                   language,
                                   showType,
                                   label,
                                   onChange, showCaret,
                                   loading, children, disabled
                               }: Props) => {

    return <DropDownSimple dropdownStyle={{backgroundColor: color ?? "black", color: "white"}} disabled={disabled}
                           dropdownMenuStyle={{overflow: 'auto', maxHeight: '30vh'}} caret={showCaret}
                           headerToggle={label ?? ""}>
        {children ? children :
            <ActionDropdownBodyComponent onChange={onChange} disabled={disabled} toggle={toggle} index={index} showType={showType}
                                         loading={loading} language={language} columns={columns}/>}
    </DropDownSimple>
};

export default ActionDropdownColumns