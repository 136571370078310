import * as React from 'react';
import {useContext, useRef} from 'react';
import {AnalyticQueryAction} from "../../../../types/Analytic";
import {useFieldArray, useForm} from "react-hook-form";
import DateTransformMenuComponent from "./DateTransformMenuComponent";
import {faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import useTranslate from "../../../../hooks/useTranslate";
import {ActionButton, ReportContext} from "../../NewReportGeneratorComponent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

type Props = {
    index: number
    action: AnalyticQueryAction
    color?: string
};

type DateForm = {
    transforms: {
        name: string
        transform: string
    }[]
}

const NewDateTransformActionComponent = ({action, index, color}: Props) => {

    const {setQueryAction, query} = useContext(ReportContext)
    const {control, watch, setValue} = useForm<DateForm>()
    const {t} = useTranslate("report")
    const {fields, update, remove, append} = useFieldArray({
        control,
        name: "transforms"
    })

    let onMount = useRef(false)

    React.useEffect(() => {
        const subscription = watch((obj, {name, type}) => {
            if (obj['transforms'] && obj['transforms'].length > 0) {

                const transforms = obj['transforms']
                    .filter(transform => transform && transform.name && transform.transform)
                    .map(transform => ({
                        [transform?.name ?? ""]: transform?.transform
                    }))

                setQueryAction({
                    ...action,
                    columns: transforms
                }, index)
            }
        });
        return () => subscription.unsubscribe();
    }, [watch, action, index, query]);

    React.useEffect(() => {

        if (fields.length === 0 && action.columns && action.columns.length > 0 && !onMount.current) {
            setValue("transforms", action.columns.map((column: { [key: string]: any }) => ({
                name: Object.keys(column)[0],
                transform: column[Object.keys(column)[0]]
            })))

            onMount.current = true
        }

    }, [action, index, fields]);

    return (
        <div className="d-flex  gap-2">

            {fields.map((field, indexField) => (
                <DateTransformMenuComponent index={indexField} key={"date_transform_"+field.name+"_"+indexField}
                                            remove={remove} control={control} action={action}
                                            color={color}
                                            watch={watch} update={update} indexAction={index}/>
            ))}


            <ActionButton className="btn" color={color ?? "white"} textColor={"white"} onClick={() => {
                append({
                    name: "",
                    transform: ""
                })
            }}>

                <FontAwesomeIcon icon={faPlusCircle}/>
                <span>{`${t("common:add")} ${t("operation")}`}</span>
            </ActionButton>
        </div>
    );
};

export default NewDateTransformActionComponent