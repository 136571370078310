import * as React from 'react';
import {faFileExport} from "@fortawesome/free-solid-svg-icons";
//@ts-ignore
import ReactExport from 'react-data-export';
import useTranslate from "../../hooks/useTranslate";
import ButtonComponent from "./ButtonComponent";
import {generateAndDownloadExcel, generateAndDownloadExcelWithSubheaders} from "../../utils";

type Props = {
    excelData: any[]
    isLoading?: boolean;
    disabled?: boolean
    color?: string;
    fileName?: string;
    excelHeaders: { value: string, label: string,  isTotalColumn?: boolean }[]
    hasSubHeader?: boolean
    customLabel?: string
};


const ExcelButtonComponent = ({excelData, excelHeaders, isLoading, color, disabled, fileName, hasSubHeader, customLabel}: Props) => {


    const {t} = useTranslate('common');
    return (
        <div className="">

            <ButtonComponent color={"secondary"} disabled={disabled}
                             label={customLabel && customLabel?.length > 0 ? customLabel : t("common:export_to_excel")} icon={faFileExport}
                             loading={isLoading} onClick={() => {
                                 if (hasSubHeader) {
                                     generateAndDownloadExcelWithSubheaders(excelData, excelHeaders, fileName)
                                 }else {
                                     generateAndDownloadExcel(excelData, excelHeaders, fileName)
                                 }

            }}/>
        </div>
    );
};

export default ExcelButtonComponent