import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {EventCalendar} from "../../types/Calendar";
import {State} from "../../types/State";
import {Appointment} from "../../types/Appointment";
import {convertNumberAndTimeToDate} from "../../utils";
import {ProcessModel} from "../../types/models/processModel/Process";
import {SalesAdresseeAccount} from "../../types/Account";

export interface IStateCalendar {
  events: EventCalendar[];
  sales: SalesAdresseeAccount[];
  state: State;
  event?: EventCalendar;
  process: ProcessModel[]
}

export const initialState: IStateCalendar = {
  state: State.IDLE,
  events: [],
  process: [],
  sales: []
}

const slice = createSlice({
  initialState,
  name: "calendarReducer",
  reducers: {
    addEvent: (state, action: PayloadAction<EventCalendar>) => {
      const e = Object.assign([], state.events);
      e.push(action.payload);
      state.events = e;
      state.state = State.RESOLVED;
    },
    setState: (state, action: PayloadAction<State>) => {
      state.state = action.payload
    },

    updateEvents: (state, action: PayloadAction<Appointment[]>) => {
      state.events = action.payload.map(appo => {
        return {
          allDay: appo.all_day,
          start: convertNumberAndTimeToDate(appo.start_date, appo.start_time),
          end: appo.due_date ? convertNumberAndTimeToDate(appo.due_date, appo.end_time ?? "00:00") : undefined,
          title: appo.summary,
          data: appo,
        }
      });
      state.state = State.RESOLVED;
    },
    chaneEvent: (state, action: PayloadAction<EventCalendar>) => {
      state.event = action.payload;
    },
    setProcessModels: (state, action: PayloadAction<ProcessModel[]>) => {
      state.process = action.payload;
      state.state = State.RESOLVED;
    },
    setSales: (state, action: PayloadAction<SalesAdresseeAccount[]>) => {
      state.sales = action.payload;
      state.state = State.RESOLVED;
    }
  }
})

export const actions = slice.actions;
export default slice.reducer;
