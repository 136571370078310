import {IconDefinition} from "@fortawesome/fontawesome-common-types";
import {Field} from "../Field";
import Form from "../FormType";

export default class IField extends Field {
  [key: string]: any;

  icon?: IconDefinition;
  input?: JSX.Element;
  component?: any;
  key?: string;
  labelProps?: any;
  detail_form?: Form;
  defaultValue?: any;
  field?: JSX.Element;
}
