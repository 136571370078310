import React, {useState} from "react";
import {actionsModal, ModalTypes} from "../../../../store/Modal/Slice";
import ModalComponent from "../ModalComponent/ModalComponent";
import {useDispatch} from "react-redux";
import {Response} from "../../../../types/Response";
import {AnyData} from "../../../../types/AnyData";
import UpsertModel, {FieldValType, UpsertProps} from "./UpsertModel";
import {State} from "../../../../types/State";

export interface IPropsModelModal {
  type: ModalTypes.SBX_UPDATE_MODEL;
  title?: React.ReactNode;
  header?: React.ReactNode;
  rowModel: string;
  fields: FieldValType[];
  row?: AnyData;
  key?: string;
  callback?: (row: AnyData, values: AnyData, type: "update" | "create") => void;
  onFinish?: (res: Response, obj?: { [key: string]: any }) => void;
}


interface IProps {
  open: boolean;
  data: IPropsModelModal;
}

const UpdateModelModal = (props: IProps) => {
  const {data: {title, rowModel, header}, open} = props;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const closeModal = () => dispatch(actionsModal.closeModal({type: ModalTypes.SBX_UPDATE_MODEL}));

  return (
    <ModalComponent
      isLoading={loading}
      size="lg"
      toggle={closeModal}
      title={title}
      header={header}
      form={rowModel}
      type="submit"
      isOpen={open}>
      <UpsertModel
        {...(props.data as any as UpsertProps)}
        keyMap={props.data.key}
        onFinish={(res, req) => {
          props.data.onFinish && props.data.onFinish(res, req);
          closeModal();
        }}
        onLoading={state => setLoading(state === State.PENDING)}
      />
    </ModalComponent>
  )
}


export default UpdateModelModal;
