import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AssignToPermissionOrGroup, PermissionState, PermissionStates} from "./Types";
import {ModuleType, PermissionsData} from "../../types/Permissions";
import {UserInfo, UserPermission} from "../../types/UserInfo";
import {getModulesWithPermissions} from "../../utils";
import {Group} from "../../types/Group";
import {Data} from '../../components/Shared/Modal/UserModal';

const initialState: PermissionState = {
  state: PermissionStates.IDLE,
  permissions: [],
  modules: [],
  users: [],
  groups: [],
  multi_state: [],
  updatingTablePermissions: false
}

const slice = createSlice({
  name: "permission",
  initialState,
  reducers: {
    getPermissions: (state) => {
      state.state = PermissionStates.PERMISSIONS_PENDING;
    },
    setPermissions: (state, action: PayloadAction<PermissionsData[]>) => {
      state.state = PermissionStates.PERMISSIONS_RESOLVED;
      state.permissions = action.payload;
      state.modules = getModulesWithPermissions(action.payload);
    },
    addModulePermissions(state, action: PayloadAction<ModuleType>) {
      state.state = PermissionStates.PERMISSION_PENDING;
    },
    deleteModulePermissions(state, action: PayloadAction<number | number[]>) {
      state.state = PermissionStates.PERMISSION_PENDING;
    },
    editModulePermission(state, action: PayloadAction<{ toDelete: number[], toSave: PermissionsData[] }>) {
      state.state = PermissionStates.PERMISSION_PENDING;
    },
    getAllUsers(state) {
      state.state = PermissionStates.USERS_PENDING;
      state.multi_state.push(PermissionStates.USERS_PENDING)
    },
    setUsers(state, action: PayloadAction<UserInfo[]>) {
      state.users = action.payload;
      state.state = PermissionStates.USERS_RESOLVED;
      state.multi_state = state.multi_state.filter(state => state !== PermissionStates.USERS_PENDING)
    },
    getUserById(state, action: PayloadAction<number>) {
      state.state = PermissionStates.USER_PENDING;
    },
    setUser(state, action: PayloadAction<UserPermission | undefined>) {
      state.state = PermissionStates.USER_RESOLVED;
      state.user = action.payload;
    },
    getAllGroups(state) {
      state.state = PermissionStates.GROUPS_PENDING;
      state.multi_state.push(PermissionStates.GROUPS_PENDING)
    },
    setGroups(state, action: PayloadAction<any[]>) {
      state.groups = action.payload;
      state.state = PermissionStates.GROUPS_RESOLVED;
      state.multi_state = state.multi_state.filter(state => state !== PermissionStates.GROUPS_PENDING)
    },
    assignPermissionOrGroupToUser(state, action: PayloadAction<AssignToPermissionOrGroup>) {
      state.state = PermissionStates.USER_PENDING;
    },
    permissionUpdateMembershipFromUserOrGroup(state, action: PayloadAction<AssignToPermissionOrGroup>) {
      state.state = PermissionStates.USER_PENDING;
    },
    assignPermissionToGroup(state, action: PayloadAction<{ permissionId: number, groupId: number }>) {
      state.state = PermissionStates.GROUP_PENDING;
    },
    removeMembership(state, action: PayloadAction<{ userId: number, groupId: number }>) {
      state.state = PermissionStates.USER_PENDING;
    },
    arrayMembership(state, action: PayloadAction<{ groupId: number, userUnCheck: number[], userCheck: number[] }>) {
      state.state = PermissionStates.USER_PENDING;
    },
    createMembership(state, action: PayloadAction<{ userId: number, groupId: number }>) {
      state.state = PermissionStates.USER_PENDING;
    },
    removePermissionFromUserOrGroup(state, action: PayloadAction<number>) {
      if (state.user) {
        state.state = PermissionStates.USER_PENDING;
      } else {
        state.state = PermissionStates.GROUP_PENDING;
      }
    },
    saveGroup(state, action: PayloadAction<{ name: string, label: string }>) {
      state.state = PermissionStates.GROUP_PENDING;
    },
    createUser(state, action: PayloadAction<{ data: Data, recoverPassword: boolean }>) {
      state.state = PermissionStates.USER_PENDING;
    },
    updateGroup(state, action: PayloadAction<{ name: string, label: string, id: number }>) {
      state.state = PermissionStates.GROUP_PENDING;
    },
    updateUserGroup(state, action: PayloadAction<{ id: number, users: any }>) {
      state.state = PermissionStates.GROUP_PENDING;
    },
    removeGroup(state, action: PayloadAction<number>) {
      state.state = PermissionStates.GROUP_PENDING;
    },
    getGroupById(state, action: PayloadAction<number>) {
      state.state = PermissionStates.GROUP_PENDING;
    },
    setGroup(state, action: PayloadAction<Group | undefined>) {
      state.state = PermissionStates.GROUP_RESOLVED;
      state.group = action.payload;
    },
    changeState(state, action: PayloadAction<PermissionStates>) {
      state.state = action.payload;
    },
    setUpdatingTablePermissions(state, action: PayloadAction<boolean>) {
      state.updatingTablePermissions = action.payload;
    }
  }
});

export const actionsPermissions = slice.actions;
export default slice.reducer;
