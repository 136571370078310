import * as React from 'react';
import {Fragment, useContext, useMemo, useRef, useState} from 'react';
import {AnalyticQueryAction, Source, SourceFrom} from "../../../../types/Analytic";
import ActionDropdownColumns from "../ActionDropdownComponent/ActionDropdownColumns";
import {ActionButton, ReportContext} from "../../NewReportGeneratorComponent";
import useTranslate from "../../../../hooks/useTranslate";
import {useFieldArray, useForm} from "react-hook-form";
import SummarizeDropdownComponent from "../../SummarizeDropdownComponent";
import {basicAnalyticAggList} from "../../../../utils/analyticsUtils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown, faAngleLeft, faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import {ReportSummarizeForm} from "../NewReportSummarizeComponent/ReportSummarizeComponent";
import SummarizeColumnsComponent from "../NewReportSummarizeComponent/SummarizeColumnsComponent";
import {removeDuplicateFromArray} from "../../../../utils";

type Props = {
    action: AnalyticQueryAction,
    indexAction: number
    color?: string
};

// const Input = styled.input`
//   min-height: 27px !important;
//   height: 27px;
//   width: 80px;
// `

const BehaviorValidatorActionComponent = ({action, color, indexAction}: Props) => {
    const [showFilters, setShowFilters] = useState(false)
    const {setQueryAction, getActionColumns, query} = useContext(ReportContext)
    let onMount = useRef(false)
    let onMountData = useRef(false)
    const {
        control, watch, getValues
    } = useForm<ReportSummarizeForm>({
        defaultValues: {conditions: [], index_columns: []}
    });

    const [sbxEvents, setSbxEvents] = useState<string[]>([])

    React.useEffect(() => {
        if (sbxEvents.length > 0 && action && !onMountData.current && (!action.time_index && !action.event_index && !action.session_id && !action.time_window)) {
            const actionParams: AnalyticQueryAction = {
                ...action
            }

            const firstEvent = sbxEvents[0]

            if (!actionParams.time_index){
                actionParams.time_index = firstEvent + "_info_event_key_event_stamp"
            }

            if (!actionParams.event_index){
                actionParams.event_index = firstEvent + "_info_event_key_event_name"
            }

            if (!actionParams.session_id){
                actionParams.session_id = firstEvent + "_payload_props_session_id"
            }

            setQueryAction({
                ...actionParams,
                time_window: null
            }, indexAction)

            onMountData.current = true

        }

    }, [sbxEvents, action]);

    const getEventColumnsBySource = (source: Source) => {
        let events: string[] = []

        if (source?.from === SourceFrom.SBX_EVENT){
            events.push(source.with)
        }

        if (source?.sources && source.sources.length > 0){
            source.sources.forEach(nSource => {
                if (nSource.from === SourceFrom.SBX_EVENT){
                    events.push(nSource.with)
                }
            })
        }


        return events
    }

    const getEventsByMergeAction = (action: AnalyticQueryAction) => {
        let events: string[] = []
        if (action.type === "merge" && action.source?.with) {
            events = events.concat(getEventColumnsBySource(action.source))
        }

        if(action.actions){
            action.actions.flat().forEach(nAction => {
                events = events.concat(getEventsByMergeAction(nAction as AnalyticQueryAction))
            })
        }

        return events
    }

    React.useEffect(() => {

        if (query.source?.with){
            let events: string[] = [...getEventColumnsBySource(query.source)]


            if (query.actions && query.actions.length > 0){
                query.actions.flat().forEach(action => {
                    events = events.concat(getEventsByMergeAction(action as AnalyticQueryAction))
                })
            }


            setSbxEvents(removeDuplicateFromArray(events))
        }


    }, [query]);

    const aggMethods = useFieldArray({
        control,
        name: "conditions"
    });

    const indexColumnsMethods = useFieldArray({
        control,
        name: "index_columns"
    });

    React.useEffect(() => {

        const subscription = watch((obj, {name, type}) => {

            const filters = obj["conditions"] ?? []
            const index_columns = obj["index_columns"] ?? []
            let agg: { [key: string]: string[] } = {}
            if (filters.length > 0) {
                agg = filters.reduce((acc: { [key: string]: string[] }, filter) => {
                    if (filter?.column && filter?.agg) {
                        if (!acc[filter.column]) {
                            acc[filter.column] = []
                        }
                        acc[filter.column].push(filter.agg)
                    }
                    return acc
                }, {})
            }

            if (typeof indexAction !== 'undefined' && action && indexAction >= 0) {

                const actionParams: AnalyticQueryAction = {
                    ...action,
                    agg,
                    index_columns: index_columns.map(column => column?.name ?? "")
                }

                if (actionParams.aggs) {
                    // actionParams.agg = {...actionParams.aggs, ...actionParams.agg}
                    delete actionParams.aggs
                }

                setQueryAction({
                    ...actionParams
                }, indexAction)

            }
        });
        return () => subscription.unsubscribe();
    }, [watch, query, action, indexAction]);

    React.useEffect(() => {

        if (indexColumnsMethods.fields.length === 0 && aggMethods.fields.length === 0 && !onMount.current && action) {

            if (action) {
                const fieldsActions = {
                    'index_columns': {
                        append: indexColumnsMethods.append,
                        remove: indexColumnsMethods.remove,
                        fields: indexColumnsMethods.fields
                    },
                    'conditions': {
                        append: aggMethods.append,
                        remove: aggMethods.remove,
                        fields: aggMethods.fields
                    }
                }

                onMount.current = true

                // Some actions have agg and others have aggs by user manipulation
                const aggs = action.agg ?? action.aggs

                if (aggs) {
                    aggMethods.fields.forEach((field, index) => {
                        aggMethods.remove(index)
                    })
                    Object.keys(aggs).forEach(key => {
                        if (aggs && aggs[key] && Array.isArray(aggs![key])) {
                            (aggs![key] as string[]).forEach((agg: string) => {
                                aggMethods.append({
                                    column: key,
                                    agg
                                })
                            })
                        }
                    })
                }

                Object.keys(fieldsActions).forEach((key) => {
                    if (action[key as keyof typeof action]) {
                        const columns = (action[key as keyof typeof action] as string[])!.map((column: string) => ({
                            name: column
                        }))

                        fieldsActions[key as keyof typeof fieldsActions].fields.forEach((field, index) => {
                            fieldsActions[key as keyof typeof fieldsActions].remove(index)
                        })

                        columns.forEach((item) => {
                            fieldsActions[key as keyof typeof fieldsActions].append(item as ({ name: string; } | { name: string; }[]) & ({ column: string; agg: string; } | { column: string; agg: string; }[]))
                        })
                    }
                })
            }

        }

    }, [query, action, aggMethods.fields, indexColumnsMethods.fields]);
    const {t} = useTranslate("report")
    const listSelectors = useMemo(() => {
        return [
            {
                label: t("time_index"),
                fieldName: "time_index",
                onChange: (value: string) => {
                    setQueryAction({
                        ...action,
                        time_index: value
                    }, indexAction)
                }
            },
            {
                label: t("event_index"),
                fieldName: "event_index",
                onChange: (value: string) => {
                    setQueryAction({
                        ...action,
                        event_index: value
                    }, indexAction)
                }
            },
            {
                label: t("time_window"),
                fieldName: "time_window",
                onChange: (value: string) => {
                    setQueryAction({
                        ...action,
                        time_window: value,
                        session_id: null
                    }, indexAction)
                }
            },
            {
                label: t("session_id"),
                fieldName: "session_id",
                onChange: (value: string) => {
                    setQueryAction({
                        ...action,
                        session_id: value,
                        time_window: null
                    }, indexAction)
                }
            }
        ]
    }, [action, indexAction])

    const renderSelector = ({label, onChange, fieldName}: {
        label: string,
        onChange: (value: string) => void,
        fieldName: string
    }) => {
        return <div className="d-flex align-items-center gap-1">
            <span>
                {label}
            </span>
            <ActionDropdownColumns
                onChange={onChange} columns={getActionColumns(action, indexAction).map(column => column.label)} showType
                index={indexAction}
                color={color}
                label={((action[fieldName as keyof typeof action] as string)!?.length > 0 ? (action[fieldName as keyof typeof action] as string) : t("common:column") ?? t("common:column")) ?? ""}/>
        </div>
    }


    return (

        <div className="d-flex flex-column gap-3">

            <div className="d-flex flex-wrap gap-2">

                <div className="d-flex align-items-center gap-1">
            <span>
                {t("behavior_from")}
            </span>
                    <ActionDropdownColumns
                        onChange={() => {}} disabled columns={[]} showType
                        index={indexAction}
                        color={color}
                        label={sbxEvents.length > 0 ? sbxEvents[0] : t("common:column") ?? t("common:column") ?? ""}/>
                </div>
                <div className="d-flex align-items-center gap-1">
            <span>
                {t("common:to")}
            </span>
                    <ActionDropdownColumns
                        onChange={() => {}} disabled columns={[]} showType
                        index={indexAction}
                        color={color}
                        label={sbxEvents.length > 0 ?( sbxEvents.at(-1) ?? '') : t("common:column") ?? t("common:column") ?? ""}/>
                </div>
            </div>

            <div className="d-flex flex-column">
                <b>
                    {t("index_columns")}
                </b>
                <div className="d-flex align-items-center gap-3">
                    <SummarizeColumnsComponent
                        control={control}
                        formName={"index_columns"}
                        color={color}
                        action={action}
                        indexAction={indexAction}
                        watch={watch}
                        getValues={getValues}
                        methods={indexColumnsMethods}
                    />
                </div>
            </div>
            <div className="d-flex flex-column">
                <b>
                    {t("aggregation_functions")}

                </b>
                <div className="d-flex align-items-center gap-1">
                    {aggMethods.fields.length > 0 && <div className="d-flex flex-wrap gap-2">
                        {aggMethods.fields.map((filter, index) =>
                            <SummarizeDropdownComponent key={filter.id} index={index} agg={watch(`conditions.${index}`)}
                                                        getValues={getValues} color={color}
                                                        aggList={basicAnalyticAggList}
                                                        queryAction={action} indexAction={indexAction}
                                                        update={aggMethods.update} watch={watch} control={control}
                                                        remove={aggMethods.remove}/>
                        )}</div>}


                    <ActionButton className="btn" color={color ?? "white"} textColor={"white"} onClick={() => {
                        aggMethods.append({
                            agg: "",
                            column: ""
                        })
                    }}>

                        <FontAwesomeIcon icon={faPlusCircle}/>
                        {t("add_summarize")}
                    </ActionButton>
                </div>
            </div>


            <hr className="w-100"/>

            <div className="text-end gap-2 fw-bold" style={{color: color ?? "black"}}>
            <span className="pointer underline mr-1"
                  onClick={() => setShowFilters(prevState => !prevState)}>{`${t(showFilters ? "common:hide" : "common:show")} ${t("advance_actions")}`}
            </span>

                <span>
                <FontAwesomeIcon icon={showFilters ? faAngleDown : faAngleLeft}/>
            </span>
            </div>

            { showFilters ?
                listSelectors.map((selector, index) => {
                    return <Fragment key={`${selector.fieldName}_${index}`}>
                        {renderSelector(selector)}
                    </Fragment>
                }) : null
            }
        </div>

    );
};

export default BehaviorValidatorActionComponent