import * as React from 'react';
import {useContext, useMemo, useState} from 'react';
import useTranslate from "../../hooks/useTranslate";
import {IsJsonString, uuidV4} from "../../utils";
import {Report, TruncateReport} from "../../types/Analytic";
import {ContainerInputs, ReportType} from "./SaveReportComponent";
import {Control, Controller, UseFormSetValue, UseFormWatch} from "react-hook-form";
import {
    formatDateOptions
} from "../ReportGeneratorComponent/ActionsComponent/TransformComponent/DateTransformComponent/DateTransformFormComponent";
import CreatableSelectComponent from "../Shared/FieldComponents/CreatableSelectComponent";
import {State} from "../../types/State";
import {ModelsResponse} from "../../types/Sbx";
import {StringOption} from "../../types/Select";
import {OptionProps} from "react-select";
import useAsyncEffect from "../../hooks/useAsyncEffect";
import {findAllModels} from "../../services/backend/SbxService";
import {ReportContext} from "./NewReportGeneratorComponent";

type Props = {
    reports: Report[]
    getInput: (input: any) => React.ReactNode
    watch: UseFormWatch<ReportType>
    setValue: UseFormSetValue<ReportType>
    control: Control<ReportType>
};
let modelsCache: ModelsResponse[] = []
const NewTruncateReportComponent = ({reports, getInput, watch, setValue, control}: Props) => {
    const {t} = useTranslate("common");

    const [models, setModels] = useState<ModelsResponse[]>([])
    // const [fieldOptions, setFieldOptions] = useState<StringOption[]>([])
    const {state, query, getColumns, report} = useContext(ReportContext)
    const [loading, setLoading] = useState(State.IDLE)
    const [noRequired, setNoRequired] = useState(false)
    const [closeMenuOnSelect, setCloseMenuOnSelect] = useState(false)
    const [sbxOptions, setSbxOptions] = useState<StringOption[]>([])
    const [baseOptions, setBaseOptions] = useState<StringOption[]>([])

    const CustomOption = (props: OptionProps<any, any>) => {
        return <div className="p-2 d-flex gap-2 align-items-center" >
            <span className="text-capitalize pointer" {...props.innerProps}>{props.label}</span>
            {!closeMenuOnSelect && props.data.value && !props.data.label.toLowerCase().includes("create") && props.data.data &&
                <span className="pointer">
                &#8594;
            </span>}
        </div>
    }

    useAsyncEffect(async () => {
        if (modelsCache.length > 0) {
            setModels(modelsCache)
        } else {
            setLoading(State.PENDING)
            const res = await findAllModels();
            if (res?.success && res.items) {
                setModels(res.items.sort((a, b) => a.name.localeCompare(b.name)))
                modelsCache = res.items.sort((a, b) => a.name.localeCompare(b.name))
                setLoading(State.RESOLVED)
            } else {
                setLoading(State.REJECTED)
            }
        }

    }, [])

    useAsyncEffect(async () => {

        const truncate = watch("truncate")

        if ((truncate as TruncateReport).model) {
            setSbxOptions(models.find(model => model.name === (truncate as TruncateReport)?.model)?.properties?.map(property => (
                {label: property.name, value: property.name}
            )) ?? [])
        } else {
            let reportColumns: StringOption[] = []

            const columns = await getColumns(query)
            if  (columns?.history){
                reportColumns = columns.history[columns.history.length - 1].map((column) => ({label: column, value: column, data: null}))
            }

            const base = [{label: t("report:none"), value: ""}, ...models.map(model => ({
                label: model.name,
                value: model.name,
                data: model
            })).sort((a, b) => a.label.localeCompare(b.label)), ...reportColumns]
            setBaseOptions(base)
            setSbxOptions(base)
        }
    }, [models, query]);

    const inputsTruncate = useMemo(() => [
        {
            id: uuidV4(),
            name: "truncate.format",
            type: "select",
            label: t("format"),
            required: !noRequired,
            disabled: noRequired,
            options: formatDateOptions
        }
    ], [reports, noRequired])

    React.useEffect(() => {
        if (report?._KEY ){

            if(report?.truncate && IsJsonString(report.truncate as string)){
                const truncate = JSON.parse(report.truncate as string) as TruncateReport

                if (!truncate?.field && !truncate.type){
                    setNoRequired(true)
                }
            }

            if (!report.truncate){
                setNoRequired(true)
            }


        }
    }, [report]);


    React.useEffect(() => {
        const subscription = watch((obj) => {

            if (obj.truncate) {

                if (!(obj.truncate as TruncateReport)?.model) {
                    setSbxOptions(baseOptions.sort((a, b) => a.label.localeCompare(b.label)))
                }
            } else {
            }
        });
        return () => subscription.unsubscribe();
    }, [watch, state, baseOptions]);

    const getTruncateValue = () => {

        const truncate = watch("truncate")

        return {
            label: !(truncate as TruncateReport)?.field ? t("select_placeholder"):  `${(truncate as TruncateReport)?.model ? (truncate as TruncateReport)?.model + "." : ""}${(truncate as TruncateReport)?.field ?? t("select_placeholder")}`,
            value: ""
        }
    }

    return (
        <>
            <h4>{t("report:truncate_field_search")}:</h4>

            <div className="position-relative form-check mb-3">
                <input
                    name="check"
                    id="exampleCheck"
                    type="checkbox"
                    checked={noRequired}
                    onChange={event => {
                        setNoRequired(event.target.checked)
                    }}
                    className="form-check-input"
                />
                <label htmlFor="exampleCheck" className="form-label form-check-label mb-0">
                    {t("report:check_dont_filter_by_date")}
                </label>
            </div>
            <ContainerInputs>

                <div>
                    <span>
                        {t("model")}
                    </span>
                    <Controller render={({field}) => {
                        return <CreatableSelectComponent closeMenuOnSelect={closeMenuOnSelect}
                                                         components={{
                                                             Option: props => CustomOption(props)
                                                         }}
                                                         value={getTruncateValue()}
                                                         disabled={State.PENDING === loading || noRequired}
                                                         loading={State.PENDING === loading} name={'truncate.model'}
                                                         options={[{
                                                             label: <div className="d-flex align-items-center gap-2">
                                                                 {field.value &&
                                                                     <span className="pointer" onClick={() => {
                                                                         setValue("truncate.model", "")
                                                                         setValue("truncate.field", "")
                                                                         setCloseMenuOnSelect(false)
                                                                     }}>
                                                                     &#8592;
                                                                 </span>}
                                                                 <span>{field.value ? field.value : "Models"}</span>
                                                             </div>,
                                                             options: sbxOptions
                                                         }]}
                                                         onChange={evt => {
                                                             if (!field.value && evt.data) {
                                                                 field.onChange(evt?.value)
                                                                 setValue("truncate.field", "")
                                                             } else {
                                                                 let text = evt.value

                                                                 if (text.split(".").length > 2) {

                                                                     field.onChange(text.split(".")[0])
                                                                     setValue("truncate.field", text.split(".").slice(1,).join("."))
                                                                 } else {
                                                                     setValue("truncate.field", text)
                                                                     if (!evt.data) {
                                                                         field.onChange("")
                                                                     }
                                                                 }
                                                             }



                                                             if (evt?.data?.properties && evt.data.properties.length > 0) {
                                                                 setSbxOptions([...evt.data.properties.map((prop: ModelsResponse) => {
                                                                     return {
                                                                         label: prop.name,
                                                                         value: prop.name,
                                                                         data: prop
                                                                     }
                                                                 })].sort((a, b) => a.label.localeCompare(b.label)))
                                                                 setCloseMenuOnSelect(true)
                                                             }
                                                         }}/>
                    }} name={"truncate.model"} rules={{required: !noRequired && !watch("truncate.field")}} control={control}/>
                </div>
                {inputsTruncate.map(input => {
                    return <div key={input.id} className="d-flex flex-column">
                        <span>{input.label ?? input.name}</span>
                        {getInput(input)}
                    </div>
                })}
            </ContainerInputs>


        </>
    );
};

export default NewTruncateReportComponent