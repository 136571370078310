import * as React from 'react';
import {useContext} from 'react';
import {AnalyticQueryAction} from "../../../types/Analytic";
import useTranslate from "../../../hooks/useTranslate";
import {ReportContext} from "../NewReportGeneratorComponent";
import CreatableSelectComponent from "../../Shared/FieldComponents/CreatableSelectComponent";
import {StringOption} from "../../../types/Select";
import RadioButtonComponent from "../../Shared/FieldComponents/RadioButtonComponent";

type Props = {
    index: number
    action: AnalyticQueryAction
};

const NewSortActionComponent = ({action, index}: Props) => {
    const {getActionColumns, setQueryAction, getLoadingAndDisabledAction} = useContext(ReportContext)

    const {t} = useTranslate("common")

    return (
        <div className="d-flex flex-column">
            <div className="d-flex align-items-center">
                <span className="fw-bold me-2">{t("ascending")}:</span>
                <RadioButtonComponent containerClassName="d-flex align-items-center"
                                      disabled={getLoadingAndDisabledAction({action, index})}
                                      className="d-flex align-items-center me-2" onChange={event => {
                    setQueryAction({
                        ...action,
                        ascending: event?.value
                    }, index)
                }}
                                      id={'action_type_' + action.type + '_ascending' + index} name={''}
                                      value={{value: action.ascending, label: 'Yes'}} options={[
                    {label: 'Yes', value: true}, {label: 'No', value: false}
                ]}/>
            </div>
            <div className="d-flex flex-column">
                <label>{t("common:columns")}: </label>
                <CreatableSelectComponent
                    className="text-start"
                    isMulti menuPosition={'fixed'} onChange={evt => {
                    const columns = evt.map((event: StringOption) => event.value);
                    setQueryAction({
                        ...action,
                        columns
                    }, index)
                }} id={'action_type_' + action.type + '_' + index} name={''}
                    disabled={getLoadingAndDisabledAction({action, index})}
                    loading={getLoadingAndDisabledAction({action, index})}
                    value={(action?.columns as string[])?.map(column => ({label: column, value: column}))}
                    options={getActionColumns(action, index)}/>
            </div>
        </div>
    );
};

export default NewSortActionComponent