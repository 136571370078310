import React, {useContext, useState} from 'react';
import CustomTableComponent, {Action, Column} from '../../Shared/CustomTableComponent/CustomTableComponent';
import {uuidV4} from '../../../utils';
import {State} from '../../../types/State';
import {ReportGeneratorContext} from '../ReportGeneratorComponent';
import {AnalyticQueryAction} from '../../../types/Analytic';
import {ObjectString} from '../../../types/utils';
import CreatableSelectComponent from "../../Shared/FieldComponents/CreatableSelectComponent";

type Props = {
  action: AnalyticQueryAction
}

const columnsTable: Column[] = [
  {name: 'label', header: 'Column'},
];


const ActionDefaultValueComponent = ({action}: Props) => {

  const {getActionColumns, reportState: {isLoading, actionLoading}} = useContext(ReportGeneratorContext);

  const [defaultColumns, setDefaultColumns] = useState<{ label: string, value: string }[]>([]);

  const actions: Action[] = [
    {
      custom: true,
      customComponent: (column: { label: string, value: string }) => <div key={uuidV4()} style={{width: '20em'}}
                                                                          className="d-flex flex-column">
        <input type="text" defaultValue={action.columns ? (action.columns as ObjectString)[column.value] : ''}
               className="form-control" placeholder={'Ej: 20200101'}
               onChange={event => {
                 (action.columns as ObjectString)[column.value] = event.currentTarget.value;
               }}/>
      </div>
    }

  ];

  const onChangeColumns = (evt: { label: string, value: string }[]) => {
    setDefaultColumns(evt);
    if (evt.length === 0) {
      action.columns = {};
    } else {
      action.columns = evt.reduce((obj: { [key: string]: any }, column) => {
        obj[column.value] = (action.columns as ObjectString)[column.value] ?? "";
        return obj;
      }, {});
    }

  };

  React.useEffect(() => {

    if (action.columns as ObjectString){
      setDefaultColumns(Object.keys(action.columns as ObjectString).map(column => ({
        label: column,
        value: column
      })))
    }

  }, [action.columns]);

  return (
    <div>
      <div className="d-flex flex-column gap-2">
        <b>Columns:</b>
        <CreatableSelectComponent isMulti
                                  disabled={(action.temporal_id && actionLoading[action.temporal_id] === State.PENDING) || isLoading === State.PENDING}
                                  loading={(action.temporal_id && actionLoading[action.temporal_id] === State.PENDING) || isLoading === State.PENDING}
                                  id={uuidV4()}
                                  name={'columns'}
                                  menuPosition={'fixed'}
                                  value={Object.keys(action.columns as ObjectString).map(column => ({
                                    label: column,
                                    value: column
                                  }))}
                                  onChange={onChangeColumns}
                                  options={getActionColumns(action)}/>
      </div>
      <CustomTableComponent columns={columnsTable} data={defaultColumns} actions={actions}
                            actionsColumnClass={'d-flex justify-content-center'}
                            actionsColumnLabel={'Default values'}/>
    </div>
  );
};

export default ActionDefaultValueComponent;
