import * as React from 'react';
import {useContext, useState} from 'react';
import {State} from "../../../types/State";
import {actionsReport, ReportState} from "../Slice";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faDownload, faTimes} from "@fortawesome/free-solid-svg-icons";
import {Model, ModelsResponse} from "../../../types/Sbx";
import CustomTableComponent from "../../Shared/CustomTableComponent/CustomTableComponent";
import {ReportContext} from "../NewReportGeneratorComponent";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import {SourceFrom} from "../../../types/Analytic";
import useTranslate from "../../../hooks/useTranslate";
import {getSchema} from "../../../services/backend/AnalyticsService";
import VisualizeResultsComponent from "../VisualizeResultsComponent";
import useAsyncEffect from "../../../hooks/useAsyncEffect";
import ButtonComponent from "../../Shared/ButtonComponent";
import {useForm} from "react-hook-form";
import cogoToast from "cogo-toast";
import {toast} from "../../../utils";
import FetchDataComponent from "./FetchDataComponent";
import ModelDataComponent from "./ModelDataComponent";


const eventProperties = [
    {
        "name": "frequency",
        "type": "NUMBER"
    },
    {
        "name": "pages",
        "type": "NUMBER"
    },
    {
        "name": "fromDate",
        "type": "DATE"
    },
    {
        "name": "toDate",
        "type": "DATE"
    }
]

const ReportDataComponent = () => {
    const {state, dispatch, setQuery, query} = useContext(ReportContext)
    const [showResults, setShowResults] = useState(false)
    const [loading, setLoading] = useState(State.IDLE)
    const [results, setResults] = useState<any[]>([])
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [models, setModels] = useState<ModelsResponse[]>([])
    const [from, setFrom] = useState("")
    const {t} = useTranslate("report")

    const {register, handleSubmit} = useForm<{
        with: string
        csv_source: string
        csv_delimiter: string
    }>()

    const toggle = () => {
        setDropdownOpen((prevState) => !prevState);
    }

    useAsyncEffect(async () => {
        if (query.source.from !== from) {
            setLoading(State.PENDING)
            const response = await getSchema([{
                ...query.source
            }]);
            setLoading(State.RESOLVED)
            dispatch(actionsReport.setModels(response ? response[query.source.from] ?? [] : []))
            setModels(response ? response[query.source.from] ?? [] : [])
            setFrom(query.source.from)

            if (query.source.from === SourceFrom.GOOGLE_ANALYTICS && response && response['google_analytics_metrics']) {
                dispatch(actionsReport.setFetchOptions(response['google_analytics_metrics']))
            }
        }

    }, [query, from]);

    React.useEffect(() => {

        if (models && models.length > 0 && state.baseQuery.source.with) {
            const model = models.find(model => model.name === state.baseQuery.source.with)
            if (model) {
                dispatch(actionsReport.setModel(model))
            } else {
                dispatch(actionsReport.setModel({
                    name: state.baseQuery.source.with,
                    id: 0,
                    properties: from === SourceFrom.SBX_EVENT ? eventProperties as Model[] : []
                }))
            }
        } else {
            if (state.baseQuery.source.with && state.baseQuery.source.csv_source && state.baseQuery.source.csv_delimiter) {
                dispatch(actionsReport.setModel({
                    name: state.baseQuery.source.with,
                    id: 0,
                    properties: []
                }))
            }
        }

    }, [state.baseQuery, models]);


    const updateQueryFrom = (dataType: SourceFrom) => {
        dispatch(actionsReport.resetState())

        setQuery({
            ...query,
            source: {...query.source, from: dataType, with: "", filters: []},
            actions: []
        })

        dispatch(actionsReport.setBaseQuery({
            ...state.baseQuery,
            source: {...state.baseQuery.source, from: dataType, with: "", filters: []},
            actions: []
        }))

    }

    const dropdownData = () => {
        return <Dropdown isOpen={dropdownOpen} toggle={toggle} direction={'down'}>
            <DropdownToggle outline color="transparent" className="px-0">
                <u className="text-capitalize d-flex align-items-center gap-1">
                    <span className="font-size-xlg ">{query.source.from.split("-").map((word, index) => {
                        if (index === 0) {
                            return word.toUpperCase()
                        }
                        return word
                    }).join(" ")}</span>
                    <FontAwesomeIcon icon={faChevronDown}/></u>
            </DropdownToggle>

            <DropdownMenu>
                <DropdownItem onClick={() => updateQueryFrom(SourceFrom.SBX_DATA)}>SBX Data</DropdownItem>
                <DropdownItem onClick={() => updateQueryFrom(SourceFrom.SBX_EVENT)}>SBX Events</DropdownItem>
                <DropdownItem onClick={() => updateQueryFrom(SourceFrom.SBX_WORKFLOW)}>SBX Workflow</DropdownItem>
                <DropdownItem onClick={() => updateQueryFrom(SourceFrom.SBX_CRM_USER)}>SBX CRM User</DropdownItem>
                <DropdownItem onClick={() => updateQueryFrom(SourceFrom.CSV)}>CSV</DropdownItem>
                <DropdownItem onClick={() => updateQueryFrom(SourceFrom.GOOGLE_ANALYTICS)}>Google
                    Analytics</DropdownItem>
            </DropdownMenu>

        </Dropdown>
    }

    const onError = (errors: any, e: any) => {
        const fields = [{label: t('common:name'), name: "with"}, {label: t("source"), name: "csv_source"}, {
            label: t("separator"),
            name: "csv_delimiter"
        }].filter((field) =>
            Object.keys(errors).includes(field.name),
        );

        if (fields && fields!?.length > 0) {
            cogoToast.error(
                <div>
                    <b>{t("custom-message:should_complete_following_fields")}</b>
                    <ul>
                        {fields.map((field, index) => (
                            <li key={index}>{field.label}</li>
                        ))}
                    </ul>
                </div>,
                {position: "bottom-left"},
            );
        } else {
            toast({type: "error", message: t("custom-message:should_complete_following_fields")});
        }
    };

    return (
        <div className="d-flex flex-column pb-2">
            <div className="card-header d-flex align-items-center  justify-content-between gap-2"
                 style={{background: "#e4fbfb40"}}>
                <span className="d-flex align-items-center gap-2">{t("source")}: {dropdownData()}</span>

                {state.model && <VisualizeResultsComponent/>}
            </div>

            {query.source.from === SourceFrom.CSV ?
                <div className="d-flex flex-wrap gap-2 mt-2 px-2 align-items-center">

                    <div className="d-flex flex-column">
                        <span>{t('common:name')}</span>
                        <input type="text" className="form-control"
                               defaultValue={query.source.with || ""}
                               disabled={state.state.includes(ReportState.PENDING_FETCHING_COLUMNS)}
                               placeholder={t('common:name')} {...register('with', {required: true})}/>
                    </div>

                    <div className="d-flex flex-column">
                        <span>{t('separator')}</span>
                        <input type="text" className="form-control"
                               defaultValue={query.source.csv_delimiter || ""}
                               disabled={state.state.includes(ReportState.PENDING_FETCHING_COLUMNS)}
                               placeholder={";"} {...register('csv_delimiter', {required: true})}/>
                    </div>

                    <div className="d-flex flex-column">
                        <span className="text-capitalize">{t('source')}</span>
                        <input type="text" className="form-control" style={{width: '300px'}}
                               defaultValue={query.source.csv_source || ""}
                               disabled={state.state.includes(ReportState.PENDING_FETCHING_COLUMNS)}
                               placeholder={`Ej: www.sheetdrive.com/documents/myfile.csv`} {...register('csv_source', {required: true})}/>
                    </div>

                    <div>
                        <p></p>
                        <ButtonComponent label={`${t("common:get")} ${t("data")}`}
                                         disabled={state.state.includes(ReportState.PENDING_FETCHING_COLUMNS)}
                                         loading={state.state.includes(ReportState.PENDING_FETCHING_COLUMNS)}
                                         icon={faDownload} onClick={() => {
                            handleSubmit((data) => {
                                dispatch(actionsReport.setBaseQuery({
                                    ...state.baseQuery,
                                    source: {
                                        ...state.baseQuery.source,
                                        ...data
                                    }
                                }))
                                setQuery({
                                    ...query,
                                    source: {
                                        ...query.source,
                                        ...data
                                    }
                                })


                            }, onError)()
                        }}/>
                    </div>

                </div> :
                <div className="d-flex flex-column flex-lg-row align-items-lg-center gap-3 mt-2 px-2">
                    <ModelDataComponent loading={loading} models={models}/>
                    <FetchDataComponent setResults={setResults} setLoading={setLoading} loading={loading}
                                        setShowResults={setShowResults}/>
                </div>}

            {showResults && <div className="py-3">
                <div className="text-end">
                    <FontAwesomeIcon icon={faTimes} className="pointer"
                                     onClick={() => {
                                         setShowResults(false)
                                     }}/>
                </div>

                <CustomTableComponent
                    columns={results && results[0] ? Object.keys(results[0]).map(item => ({
                        name: item,
                        header: item
                    })) : []}
                    data={results?.length > 0 ? results : []}
                    loading={loading === State.PENDING}
                />
            </div>}
        </div>
    );
};

export default ReportDataComponent