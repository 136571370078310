import React, {CSSProperties, Dispatch, useContext, useEffect, useMemo, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import useTranslate from '../../../hooks/useTranslate';
import Image from 'next/image';
import {bytesToSize, error, getIconFile, getVariableDefaultValue, IsJsonString, success} from '../../../utils';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSearch, faSpinner, faTimesCircle, faTrash} from '@fortawesome/free-solid-svg-icons';
import {Field} from '../../../types/Field';
import {SBXFile} from '../../../types/Sbx';
import useAsyncEffect from '../../../hooks/useAsyncEffect';
import {deleteSbxFileService, downloadFileService, getFile} from '../../../services/UtilsService';
import {State} from '../../../types/State';
import {useDispatch} from 'react-redux';
import {actionsModal, ModalTypes} from '../../../store/Modal/Slice';
import {AnyAction} from "redux";
import {Document, Page, pdfjs} from 'react-pdf';
import {TaskComponentContext} from "../../../context/TaskContext";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


export async function openPreview(file: SBXFile | File, dispatch: Dispatch<AnyAction>) {
    let mimeType = ''
    let url = ''

    if ((file as SBXFile).url) {
        url = (file as SBXFile).url
        mimeType = (file as SBXFile).mime_type
    } else {
        const data = new Blob([file as File], {type: (file as File).type});
        url = window.URL.createObjectURL(data);
        mimeType = (file as File).type
    }


    if (url && mimeType) {

        // const base64 = await fileToBase64(fileResponse) as string;

        dispatch(actionsModal.openModal({
            type: ModalTypes.DYNAMIC_COMPONENT_MODAL,
            title: "Preview ".concat(file.name),
            noFooter: true,
            size: "lg",
            component: (
                <div className="d-flex justify-content-center">
                    {mimeType.includes("pdf") ?
                        <div style={{width: "100%", maxHeight: "80vh"}} className="overflow-auto">
                            <Document file={url}>
                                <Page pageNumber={1}/>
                            </Document>
                        </div> :
                        <img src={url} alt={url} style={{width: "100%", height: "80vh"}}/>
                    }
                </div>
            )
        }))
    }
}

export const baseStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
};

const focusedStyle = {
    borderWidth: 3,
    minHeight: '8em',
    outline: 'none',
    transition: 'border .20s ease-in-out',
    borderRadius: 10,
    padding: '10px',
    borderColor: '#CEDFFB',
    borderStyle: 'dashed',
    backgroundColor: '#F6F9FF',
};


type Props = {
    field: Field;
    setValueField: (val: any) => void
    getFiles: () => any
    loadingTask?: State
}

const cacheFiles: { [key: string]: File[] | SBXFile[] } = {}
const MultiFilesInputComponent = ({field, setValueField, getFiles, loadingTask}: Props) => {

    const {t} = useTranslate('custom-message');
    const [loading, setLoading] = useState<State>(State.IDLE);
    const [files, setFiles] = useState<File[] | SBXFile[]>([]);
    const dispatch = useDispatch();
    const {watch} = useContext(TaskComponentContext);
    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragActive,
        acceptedFiles,
        open
    } = useDropzone({
        noClick: true,
        noKeyboard: true,
        noDrag: field.read_only
    });

    useEffect(() => {
        let subscription: any = null
        if (watch) {
            subscription = watch((obj, {name, type}) => {
                if (field.format_rules_definition?.default_value && field.format_rules_definition?.dependencies && field.format_rules_definition.dependencies.length > 0) {
                    if (name && field.format_rules_definition.dependencies.includes(name)) {
                        const value = obj[getVariableDefaultValue(field.format_rules_definition.default_value)];

                        if (value) {
                            if (IsJsonString(value) && Array.isArray(JSON.parse(value))) {
                                getDefaultFiles(JSON.parse(value) as string[])
                            } else {
                                if (Array.isArray(value)) {
                                    getDefaultFiles(value as string[])
                                }
                            }

                        } else {
                            setFiles([])
                        }
                    }

                }
            });
        }
        return () => subscription?.unsubscribe();
    }, [watch, field]);


    const getDefaultFiles = async (defaultFiles: string[] | string) => {
        if (defaultFiles && defaultFiles.length > 0) {

            if (!Array.isArray(defaultFiles)) {
                defaultFiles = [defaultFiles]
            }

            setLoading(State.PENDING);
            const response: SBXFile[] = await Promise.all(defaultFiles.map((file: string) => getFile(file))) as SBXFile[];
            const newFiles: SBXFile[] = [];
            response.forEach(item => {
                if (item.success) {
                    newFiles.push(item);
                }
            });

            if (newFiles.length > 0) {
                setFiles(newFiles.map(file => {
                    if (file.name.includes("process_")) {
                        const fileName = file.name.includes("_") ? file.name.split("_")?.at(-1) ?? file.name : file.name
                        if (fileName) {
                            file.name = fileName
                        }
                    }
                    return file;
                }));
                setLoading(State.RESOLVED);
            } else {
                setLoading(State.REJECTED);
            }
        }
    };

    useAsyncEffect(async () => {
        await getDefaultFiles(getFiles());
    }, []);

    React.useEffect(() => {
        if (acceptedFiles.length > 0) {
            setFiles((prevState) => [...(prevState as File[]), ...(acceptedFiles as File[])]);
        }
    }, [acceptedFiles]);


    React.useEffect(() => {

        if (loadingTask && loadingTask === State.REJECTED && field?.name && cacheFiles[field.name]) {
            setFiles(cacheFiles[field.name])
        }

    }, [loadingTask]);


    React.useEffect(() => {
        if (files.length > 0) {
            setValueField(files.map(file => ({label: field.label, file})));
            cacheFiles[field.name] = files
        } else {
            setValueField(null)
        }
    }, [files]);

    const style = useMemo(() => ({
        ...(isFocused ? focusedStyle : baseStyle)
    }), [
        isFocused
    ]);

    const deleteFile = (file: File | SBXFile) => {
        if ((file as SBXFile).url) {
            dispatch(actionsModal.openModal(
                {
                    type: ModalTypes.CONFIRM,
                    onConfirm: async () => {
                        dispatch(actionsModal.closeModal({type: ModalTypes.CONFIRM}));
                        setLoading(State.PENDING);
                        // setFiles([])
                        const response = await deleteSbxFileService((file as SBXFile).file_key);
                        if (response?.success) {
                            success(t('success-delete'));
                            const newFiles = (files as SBXFile[]).filter((nFile) => nFile.file_key !== (file as SBXFile).file_key)
                            setFiles(newFiles)
                            setLoading(State.RESOLVED)
                        } else {
                            setLoading(State.REJECTED);
                            error(t('error-delete'));
                        }


                    },
                    message: <p>{t('warn-delete')} <b>{file?.name}</b>?</p>,
                    title: <span><FontAwesomeIcon className="me-2" icon={faTrash}/>{t('common:delete')} </span>,
                }));
        } else {
            setFiles(prevState => (prevState as File[]).filter((nItem) => nItem.name !== file.name));
        }
    };

    return (
        <div className="d-flex flex-column">
            <div {...getRootProps({style})}>
                <input {...getInputProps()}
                       accept={field?.format_rules_definition?.file_type ? field?.format_rules_definition?.file_type.map(type => "." + type).join(", ") : ""}/>
                <div className="d-flex flex-column">
                    {loading === State.PENDING ?
                        <div className="d-flex fw-bold align-items-center">
                            <FontAwesomeIcon icon={faSpinner} pulse
                                             className="me-2"/> {t("common:loading")} {t("common:files")}...
                        </div>
                        :
                        isDragActive ?
                            <div className="d-flex flex-column align-items-center justify-content-center"
                                 style={focusedStyle}>
                                <span style={{color: '#B5B6B8'}}
                                      className="font-size-xlg">{t('drag-and-drop-files')}</span>
                            </div>
                            :
                            <>
                                {!field.read_only &&
                                    <span className="border mb-2 p-2 rounded"
                                          onClick={open}>{`${t('drag-and-drop-files')} ${t("common:or")} ${t("common:click_here")}`} </span>}
                                <div className="overflow-auto gap-2  d-flex flex-column border-bottom"
                                     style={{maxHeight: '18em'}}>
                                    {files.length > 0 ? files.map((file, index) => (
                                            <div
                                                className="d-flex align-items-center justify-content-between  p-2 border-bottom "
                                                key={file.name + '_' + index}>
                                                <div className="d-flex align-items-center">
                                                    <div className="me-2">
                                                        <Image
                                                            src={getIconFile(file?.name?.split('.')[1] ?? '')}
                                                            alt="dropzone-background"
                                                            width={20}
                                                            height={20}
                                                        />
                                                    </div>
                                                    <div
                                                        className={`d-flex flex-column ${(file as SBXFile)?.url ? 'underline pointer' : ''}`}
                                                        key={file.name}
                                                        onClick={(file as SBXFile)?.url ? async () => downloadFileService((file as SBXFile).url, file.name) : undefined}>
                                                        <span className="fw-bold font-size-md">{file.name}</span>
                                                        <small className="text-gray">{bytesToSize(file.size)}</small>
                                                    </div>
                                                </div>

                                                <div className="d-flex align-items-center justify-content-end gap-3">
                                                    <FontAwesomeIcon title="Preview pdf file" icon={faSearch}
                                                                     className="pointer" size="2x"
                                                                     onClick={() => openPreview(file as SBXFile, dispatch)}/>

                                                    {field.name && <FontAwesomeIcon icon={faTimesCircle} className="pointer"
                                                                                    onClick={() => deleteFile(file)}
                                                                                    color={'red'}/>}
                                                </div>
                                            </div>
                                        )) :

                                        <div className="d-flex" onClick={open}>
                                            <span className="fw-bold">No hay archivos.</span>
                                        </div>

                                    }
                                </div>
                            </>
                    }
                </div>
            </div>
        </div>
    );
};

export default MultiFilesInputComponent;
