import {UserData, UserPermissions} from "../../types/User";
import {Condition, SbxResponse} from "../../types/Sbx";
import {Report, ReportAdmin, ReportFolder} from "../../types/Analytic";
import {findByModel, FindByModelParams} from "./SbxService";
import {Permissions} from "../../types/Permissions";
import {getFetchedResults, IsJsonString} from "../../utils";
import {Find} from "sbxcorejs";

export const validateReportsByUser = (user: UserData, reports: Report[]) => {
    const reportPermissions = user?.permissions
        .filter((permission) => permission.module_name === Permissions.REPORT)
        .reduce(
            (
                obj: {
                    [key: string]: UserPermissions;
                },
                permission,
            ) => {
                obj[permission.permission] = permission;
                return obj;
            },
            {},
        );

    let allowKeys = reportPermissions["READ"].metadata.reduce(
        (arr: string[], meta) => {
            if (
                IsJsonString(meta) &&
                JSON.parse(meta).allow &&
                JSON.parse(meta).allow.every(
                    (item: string | number) => typeof item === "string",
                )
            ) {
                const allow = JSON.parse(meta).allow;
                if (allow.includes("all")) {
                    arr.push("all");
                } else {
                    arr = arr.concat(allow);
                }
            }
            return arr;
        },
        [],
    );

    allowKeys = reportPermissions["EXECUTE"]?.metadata?.reduce(
        (arr: string[], meta) => {
            if (
                IsJsonString(meta) &&
                JSON.parse(meta).allow &&
                JSON.parse(meta).allow.every(
                    (item: string | number) => typeof item === "string",
                )
            ) {
                const allow = JSON.parse(meta).allow;
                if (allow.includes("all")) {
                    arr.push("all");
                } else {
                    arr = arr.concat(allow);
                }
            }
            return arr;
        },
        allowKeys,
    ) ?? [];

    return reports.filter((report) =>
        allowKeys.length === 0 || allowKeys.includes("all")
            ? true
            : allowKeys.includes(report._KEY ?? ""),
    );
}

export const getCrmReports = async (user: UserData) => {
    // dispatchForm({name: 'isLoading', value: State.PENDING});
    const response: SbxResponse<Report> = await findByModel({
        row_model: "sbx_crm_report",
    });
    if (response?.success && response.results) {
        // let allowKeys = ["READ", "EXECUTE"]
        response.results = validateReportsByUser(user, response.results);

        return response.results.filter(
            (report) => report.active && (!report.parent_group || report.group),
        );
    } else {
    }
};

export const getCrmKpiReports = async (user: UserData) => {
    const response: SbxResponse<Report> = await findByModel({
        row_model: "sbx_crm_kpi",
    });
    if (response?.success && response.results) {
        // const reportPermissions = user?.permissions.filter(permission => permission.module_name === Permissions.REPORT).reduce((obj: {
        //     [key: string]: UserPermissions
        // }, permission) => {
        //     obj[permission.permission] = permission
        //     return obj;
        // }, {})
        //
        // let allowKeys = reportPermissions["READ"].metadata.reduce((arr: string[], meta) => {
        //     if (IsJsonString(meta) && JSON.parse(meta).allow && JSON.parse(meta).allow.every((item: string | number) => typeof item === "string")) {
        //         const allow = JSON.parse(meta).allow
        //         if (allow.includes("all")) {
        //             arr.push("all")
        //         } else {
        //             arr = arr.concat(allow)
        //         }
        //     }
        //     return arr;
        // }, [])
        //
        // allowKeys = reportPermissions["EXECUTE"].metadata.reduce((arr: string[], meta) => {
        //     if (IsJsonString(meta) && JSON.parse(meta).allow && JSON.parse(meta).allow.every((item: string | number) => typeof item === "string")) {
        //         const allow = JSON.parse(meta).allow
        //         if (allow.includes("all")) {
        //             arr.push("all")
        //         } else {
        //             arr = arr.concat(allow)
        //         }
        //     }
        //     return arr;
        // }, allowKeys)

        // response.results = response.results.filter(report => (allowKeys.length === 0 || allowKeys.includes("all")) ? true : allowKeys.includes(report._KEY ?? ""))

        return response.results.filter((kpi) => kpi.active);
    } else {
    }
};

export const getReportFolders = async (keys?: string[]) =>{

    let where: Condition[] | { keys: string[] } = []

    if (keys && keys.length > 0) {
        where = {
            keys: keys ?? []
        }
    }

    const params: {[key: string]: any} = {
        row_model: "sbx_crm_analytic_folder"
    }

    if (where?.keys){
        params["where"] = where
    }

    return await findByModel(params as FindByModelParams) as SbxResponse<ReportFolder>;
}

export const getAnalyticFolderById = async (folder_key: string) =>{

    return  await findByModel({
        row_model: "sbx_crm_analytic_folder",
        where: {
            keys: [folder_key]
        }
    }) as SbxResponse<ReportFolder>;
}

export const getReportsByFolder = async (analytic_folder_key: string) =>{
    let query = new Find("sbx_crm_report_folder", 0);
    const fetch = ['report']
    query.andWhereIsEqualTo("folder", analytic_folder_key);
    const response =  await findByModel({
        row_model: "sbx_crm_report_folder",
        where: query.compile().where,
        fetch
    }) ;

    return getFetchedResults(response, fetch) as SbxResponse<ReportAdmin>;
}

export const getFoldersByReport = async (report_key: string) =>{
    let query = new Find("sbx_crm_report_folder", 0);
    const fetch = ['folder']
    query.andWhereIsEqualTo("report", report_key);
    const response =  await findByModel({
        row_model: "sbx_crm_report_folder",
        where: query.compile().where,
        fetch
    }) ;

    return getFetchedResults(response, fetch) as SbxResponse<ReportAdmin>;
}