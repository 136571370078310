import * as React from 'react';
import {Control, Controller} from "react-hook-form";
import DateRangeComponent from "../../Shared/FieldComponents/DateRangeComponent";
import RenderMonthElement from "../../Shared/RenderMonthElement";
import {Field} from "../../../types/Field";
import useDeviceDetect from "../../../hooks/useDeviceDetect";
import {FieldType} from "../../../types/FieldType";
import DateComponent from "../../Shared/FieldComponents/DateComponent";
import {useContext, useEffect, useState} from "react";
import {CrmInputContext} from "./CrmRenderInput";
import {TaskComponentContext} from "../../../context/TaskContext";


type Props = {
  field: Field,
  control?: Control<any, object>
};

const CrmDateInputComponent = ({field, control}: Props) => {

  const {isMobile} = useDeviceDetect();
  const [defaultValue, setDefaultValue] = useState<string>('');
  const {getDefaultValue} = useContext(CrmInputContext)
  const {formState} = useContext(TaskComponentContext);

  useEffect(() => {
    setTimeout(() => {
      setDefaultValue(getDefaultValue(field) || "");
    }, 1000)
  }, [formState]);


  function formatValues(field: Field, value: any, structure?: boolean) {
    switch (field.field_type) {
      case FieldType.DATE_RANGE:
        if (structure) {
          return `${value.startDate?.toISOString() ?? ''},${value.endDate?.toISOString() ?? ''}`;
        } else {
          const [s, e] = (value ?? ',').split(',');
          return {startDate: s ? new Date(s) : null, endDate: e ? new Date(e) : null};
        }
      case FieldType.DATE:
        if (structure) {
          return value?.toISOString() ?? null;
        } else {
          return value ? new Date(value) : null;
        }
      default:
        return value;
    }
  }

  const getDateField = () => {
    const input: { [key: string]: JSX.Element } = {
      [FieldType.DATE_RANGE]: <Controller
        control={control}
        name={field.name}
        defaultValue={defaultValue}
        rules={{
          required: field.required && !field.read_only
        }}
        render={({field: {onChange, value}}) => (
          <DateRangeComponent
            id={field.id.toString()}
            value={formatValues(field, value)}
            onChange={e => onChange(formatValues(field, e, true))}
            disabled={field.read_only}
            required={field.required}
            renderMonthElement={RenderMonthElement}
            isOutsideRange={() => false}
            orientation={isMobile ? 'vertical' : 'horizontal'}
          />
        )}
      />,
      [FieldType.DATE]: <Controller
        control={control}
        name={field.name}
        defaultValue={getDefaultValue(field) ?? ''}
        rules={{
          required: field.required && !field.read_only
        }}
        render={({field: {onChange, value}}) => (
          <DateComponent
            id={field.id.toString()}
            value={formatValues(field, value)}
            onChange={e => onChange(formatValues(field, e, true))}
            disabled={field.read_only}
            required={field.required}
            renderMonthElement={RenderMonthElement}
            orientation={isMobile ? 'vertical' : 'horizontal'}
            isDayBlockedDate={field?.format_rules_definition?.blocked_from_date}
            isOutsideRange={() => false}
          />
        )}
      />
    }

    return input[field.field_type] ?? null
  }

  return getDateField();
};

export default CrmDateInputComponent