import React, {useContext, useState} from 'react';
import {AnalyticQueryAction} from '../../../../types/Analytic';
import SourceMergeColumnsComponent from '../../SourceComponent/SourceMergeColumnsComponent';
import ButtonComponent from '../../../Shared/ButtonComponent';
import {faPlus} from '@fortawesome/free-solid-svg-icons';
import ActionMergeSourceModal from './ActionMergeSourceModal';
import {State} from '../../../../types/State';
import {getAnalyticJsonColumns} from '../../../../services/backend/AnalyticsService';
import {removeDuplicateFromArray} from '../../../../utils';
import {ReportGeneratorContext} from '../../ReportGeneratorComponent';
import {UpdateSource} from '../../SourceComponent/SourceComponent';

type Props = {
  action: AnalyticQueryAction
}

const ActionMergeComponent = ({action}: Props) => {

  const [openModal, setOpenModel] = useState(false);
  const {dispatchForm, getActionColumns} = useContext(ReportGeneratorContext);
  const toggle = () => setOpenModel(prevState => !prevState);
  const [indexColumns, setIndexColumns] = useState<string[]>([]);

  const getSourceColumns = async () => {
    if (action.source && action.actions) {
      dispatchForm({name: 'isLoading', value: State.PENDING});
      const data = await getAnalyticJsonColumns({source: action.source, actions: action.actions.flat()});
      if (data?.items && data.items.length > 0) {
        data.items = removeDuplicateFromArray(data.items.filter(column => column));
        setIndexColumns(data.items);
        dispatchForm({name: 'isLoading', value: State.RESOLVED});
      } else {
        setIndexColumns([]);
        dispatchForm({name: 'isLoading', value: State.REJECTED});
      }
    }
  };

  return (
    <div className="d-flex align-items-end flex-wrap gap-2">
      {action.source &&
        <SourceMergeColumnsComponent indexColumns={indexColumns.map(column => ({label: column, value: column}))}
                                     source={action.source} mainColumns={getActionColumns(action)} sourceMainColumn={action.main_column ?? ""}
                                     sourceIndexColumn={action.index_column ?? ""} sourceMergeType={action.merge_type ?? ""}
                                     updateSource={(sources: UpdateSource[]) => {

                                       if (action.source && action.actions) {

                                         for (const source of sources) {
                                           if (action.source.temporal_id === source.sourceId && source.sourceKey && action.hasOwnProperty(source.sourceKey)) {
                                             (action as any)[source.sourceKey] = source.sourceValue;
                                           }
                                         }
                                       }

                                     }}/>}

      <ButtonComponent label={`${action.source ? 'Update' : 'Add new'} source`} onClick={toggle} icon={faPlus}/>

      {openModal && action.source && action.actions &&
        <ActionMergeSourceModal open={openModal} toggle={() => {
          toggle();
          if (action.source?.with) {
            getSourceColumns();
          }
        }} action={action}/>}
    </div>
  );
};

export default ActionMergeComponent;
