import * as React from 'react';
import {useState} from 'react';
import Lightbox from "yet-another-react-lightbox";
import Counter from "yet-another-react-lightbox/plugins/counter";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/counter.css";
import NextJsImage from "./NextJsImage";
import useAsyncEffect from "../../../hooks/useAsyncEffect";
import {downloadFile} from "../../../services/backend/ContentService";
import {faEye} from "@fortawesome/free-solid-svg-icons";
import ButtonComponent from "../ButtonComponent";
import useTranslate from "../../../hooks/useTranslate";
import Video from "yet-another-react-lightbox/plugins/video";
import {Slide} from "yet-another-react-lightbox/dist/types";


type Props = {
    images: string[]
};

// const imageSizes = [16, 32, 48, 64, 96, 128, 256, 384];
// const deviceSizes = [640, 750, 828, 1080, 1200, 1920, 2048, 3840];

const RenderImageViewer = ({images}: Props) => {
    const [imageUrls, setImageUrls] = useState<Slide[]>([])
    const [imageViewerOpen, setImageViewerOpen] = useState(false)
    const {t} = useTranslate('common');
    useAsyncEffect(async () => {
        const arrayList: Slide[] = []


        if (imageViewerOpen) {
            for (const image of images) {
                const response = await downloadFile(image);
                if (response?.success && response.url) {
                    if (response.mime_type?.includes("video")) {
                        arrayList.push({
                            type: "video",
                            width: 1280,
                            height: 720,
                            sources: [
                                {
                                    src: response.url ?? "",
                                    type: response.mime_type ?? "video/mp4"
                                }
                            ]
                        },)
                    } else {
                        arrayList.push(response.url)
                    }
                }
            }
            setImageUrls(arrayList)
        }


    }, [imageViewerOpen])

    return (
        <div>
            <ButtonComponent icon={faEye} label={`${t("view")} ${t("list_images")}`}
                             onClick={() => {
                                 setImageViewerOpen(true)
                                 // dispatch(actionsModal.openModal({type: ModalTypes.RENDER_IMAGE_VIEWER_MODAL, images: Array.isArray(images) ? images : [images]}))
                             }}/>
            <Lightbox
                plugins={[Counter, Fullscreen, Video]}
                counter={{style: {top: "unset", bottom: 0}}}

                open={imageViewerOpen}
                close={() => setImageViewerOpen(false)}
                slides={[...imageUrls]}
                render={{slide: NextJsImage}}
            />
        </div>

    );
};

export default RenderImageViewer