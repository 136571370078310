import React, { FormEvent, useEffect, useState } from "react";
import { TaskProcess } from "../../../../types/Task";
import { fields } from "./config";
import CreateFieldPreview from "../CreateFieldModal/CreateFieldPreview";
import ModalComponent from "../ModalComponent/ModalComponent";
import { useDispatch, useSelector } from "react-redux";
import { actionsModal, ModalTypes } from "../../../../store/Modal/Slice";
import useTranslate from "../../../../hooks/useTranslate";
import { Col, Row } from "reactstrap";
import { ProcessModel } from "../../../../types/models/processModel/Process";
import {
  createOrUpdateProcessModelService,
  getModelProcessDataFields,
  getTemplates, saveWhatsappTask,
} from "../../../../services/backend/ProcessService";
import { RootState } from "../../../../store/Reducers";
import {
  capitalize,
  filterFields,
  splitDataArrayFromString,
  toast,
} from "../../../../utils";
import { FieldType } from "../../../../types/FieldType";
import IField from "../../../../types/FormBuilder/IField";
import { Attachment } from "../../../../types/FormBuilder/Attachment";
import AttachmentType from "../../../../types/Workflow/AttachmentType";
import { StringOption } from "../../../../types/Select";
import SpinnerComponent from "../../SpinnerComponent";
import useAsyncEffect from "../../../../hooks/useAsyncEffect";
import { TemplateWhatsapp } from "../../../../types/Template";
import { parseStringToJson } from "../../../../utils/taskUtils";

export interface IProps {
  open: boolean;
  data: IPropsUpsertTaskModal;
}

export interface IPropsUpsertTaskModal {
  type: ModalTypes.UPSERT_TASK_MODAL;
  task: TaskProcess;
  currentProcessModel: ProcessModel;
  onFinish: (task: ProcessModel) => void;
}

const options: any = {
  task_type: [
    { label: "USER", value: "USER" },
    { value: "SCRIPT", label: "SCRIPT" },
    { value: "SERVICE", label: "SERVICE" },
    { label: "WHATSAPP", value: "WHATSAPP" },
  ],
  // activity_type: [{label: 'TASK', value: 'TASK'}, {label: 'TASK_SCRIPT', value: 'TASK_SCRIPT'}],
  assignment_type: [
    { label: "USER", value: "USER" },
    { label: "CURRENT_USER", value: "CURRENT_USER" },
    { value: "GROUP", label: "GROUP" },
    { value: "COMPLEX", label: "COMPLEX" },
  ],
  finish_assignments: [
    {
      label: "First User finish the assignment",
      value: true,
    },
    { label: "All Users finish the assignment", value: false },
  ],
  template_id: [],
  variables: {},
  phone: [],
  only_notification: [
    { label: "Yes", value: "Y" },
    { label: "No", value: "N" }
  ]
};

const UpsertTaskModal = (props: IProps) => {
  const {
    FormReducer: { forms },
    PermissionReducer: { users, groups },
  } = useSelector((state: RootState) => state);
  const {user: {config}} = useSelector((state: RootState) => state.AuthReducer);
  const [task, setTask] = useState<any>({});
  const [processDataFields, setProcessDataFields] = useState<StringOption[]>(
    [],
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [fieldsLoading, setLoadingFields] = useState<boolean>(true);
  const [templates, setTemplates] = useState<TemplateWhatsapp[]>([]);

  const dispatch = useDispatch();
  const { t } = useTranslate("common");

  const newOptions = {
    ...options,
    attachments: forms.map((form) => ({
      label: `${form.id} ${form.name}`,
      value: form,
    })),
    users: users.map((user) => ({ label: `${user.email}`, value: user })),
    groups: groups.map((group) => ({ label: `${group.name}`, value: group })),
    variables: processDataFields,
    phone: processDataFields,
  };

  const setDataFields = (tt: any) => {
    filterFields(fields, tt).forEach((field) => {
      const val = tt[field.name] ?? field.default_value;
      let default_value = val;
      if (field.field_type === FieldType.OPTIONS) {
        default_value =
          newOptions[field.name]?.find((opt: any) => {
            switch (field.name) {
              case "attachments":
                return (
                  opt.value.id ===
                  (val && val.length ? val[0].attachment_id : null)
                );
              default:
                return opt.value === val;
            }
          }) ?? null;
      }

      setTask((e: any) => ({ ...e, [field.name]: default_value }));
    });

    let groups: any = null,
      users: any = null;
    let whatsappRule: any = null;
    if (tt.rule) {
      if (tt.rule.includes("users")) {
        users = newOptions.users.filter((u: any) =>
          splitDataArrayFromString(tt.rule).some((i) => i === u.value.id),
        );
      }
      if (tt.rule.includes("groups")) {
        groups = newOptions.groups.filter((g: any) =>
          splitDataArrayFromString(tt.rule).some((i) => i === g.value.id),
        );
      }
      if (tt.rule.includes("whatsapp")) {
        whatsappRule = parseStringToJson(tt.rule);
        const template = newOptions.template_id.find(
          (temp: any) => temp.value === whatsappRule.template_id,
        );
        if (template) {
          whatsappRule.template_id = { label: template.label, value: template.value };
          if (whatsappRule.hasOwnProperty("variables")){
            whatsappRule.variables = JSON.parse(whatsappRule.variables);
          } else {
            whatsappRule.variables = {};
          }
          whatsappRule.only_notification = options.only_notification.find((opt: { label: string; value: string }) => opt.value === whatsappRule.only_notification);
          whatsappRule.phone = newOptions.phone.find((opt: { label: string; value: string }) => opt.value === whatsappRule.phone);
          newOptions.variables = newOptions.variables.map((variable: { label: string; value: string; template: {[key:string]: string} }) => variable.template = whatsappRule.variables);
        }
      }
    }

    setTask((e: any) => ({
      ...e,
      ...whatsappRule,
      users,
      groups,
      assignment_type: newOptions.assignment_type.find(
        (opt: any) => opt.value === tt.assignment_type,
      ),
      rule: tt.rule,
    }));
  };

  useAsyncEffect(async () => {
    const token = config?.sbx_crm?.omnichannel?.agents?.tenant_api_key ?? "";
    if (token){
      const templateRes = await getTemplates(token);
      setTemplates(templateRes);
    }

  }, [props.data.task]);

  useEffect(() => {
    if (templates.length > 0) {
      options.template_id = templates
        .filter((temp) => temp.status === "approved")
        .map((temp: { id: any; name: any }) => ({
          label: `${temp.id}. ${temp.name}`,
          value: temp.id,
        }));
      newOptions.template_id = options.template_id;
    }

    setDataFields(props.data.task);
  }, [templates, props.data.task, newOptions.phone]);

  function getNewTks(tsk: any) {
    let newTsk: any = { ...tsk, ...task };
    filterFields(fields, newTsk).forEach((field) => {
      const value = task[field.name];
      const tt = typeof value;
      if (field.name === "attachments") {
        if (value?.value) {
          let attach: Attachment = {
            attachment_id: value.value.id,
            attachment_type: AttachmentType.FORM,
            domain_id: props.data.currentProcessModel.domain_id?.toString(),
            target_type:
              task.activity_type?.value === "TASK_SCRIPT"
                ? "TASK"
                : task.activity_type?.value ?? "TASK",
          };

          if (tsk?.attachments) {
            const attachment = tsk?.attachments[0];

            if (attachment?.id) {
              attach = {
                ...attach,
                id: attachment.id,
                target_type: attachment.target_type,
              };
            }
          }

          newTsk[field.name] = [attach];
        }
      } else {
        newTsk[field.name] = (tt === "object" ? value.value : value) ?? "";
      }
    });

    // TODO: find a better way to do this @frank
    // This should fix any case when we are sending the label,value options as a value.
    Object.keys(newTsk).forEach((k) => {
      if (
        typeof newTsk[k] === "object" &&
        newTsk[k]?.hasOwnProperty("label") &&
        newTsk[k]?.hasOwnProperty("value")
      ) {
        newTsk[k] = newTsk[k].value;
      }
    });

    delete newTsk.users;
    delete newTsk.groups;
    delete newTsk.type;
    return newTsk as TaskProcess;
  }

  async function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    let newProcessModel = { ...props.data.currentProcessModel };

    setLoading(true);
    if (props.data.task.id) {
      newProcessModel.tasks = newProcessModel.tasks.map((tsk) => {
        if (tsk.id === props.data.task.id) {
          return getNewTks(tsk);
        }
        return tsk;
      });
    } else {
      let tsks = new Array(...(newProcessModel.tasks ?? []));
      tsks.push(getNewTks({}));
      newProcessModel.tasks = tsks;
    }

    const res = await createOrUpdateProcessModelService(newProcessModel);

    if (newProcessModel.tasks.length &&  config?.sbx_crm?.omnichannel?.agents?.tenant_api_key) {
      const token = config?.sbx_crm?.omnichannel?.agents?.tenant_api_key ?? "";
      const callback_base_url = config?.sbx_crm?.omnichannel?.agents?.callback_base_url ?? "";
      const task_wp = newProcessModel.tasks.filter(task => task.task_type === 'WHATSAPP' && task.id === props.data.task.id);
      if (task_wp.length) {
        for (const task of task_wp) {
          const whatsappRule = parseStringToJson(task.rule!);

          const key = `${newProcessModel.domain_id}_${task.process_id}_${task.id}`;
          const data = {
            key: key,
            callback_url: `/api/v2/${newProcessModel.domain_id}/wf/task_callback/${key}`,
            template_id: whatsappRule.template_id
          }
          await saveWhatsappTask(data, token, callback_base_url);
        }
      }
    }

    if (res?.success) {
      props.data.onFinish(res.item);
      toggle();
    } else {
      toast({
        type: "error",
        message: "An error ocurred to save the process. Try again",
      });
      setLoading(false);
    }
  }

  const toggle = () => {
    dispatch(actionsModal.closeModal({ type: ModalTypes.UPSERT_TASK_MODAL }));
  };

  function onChangeValues(field: IField, value: any) {
    let nTask = {
      ...task,
      [field.name]: value,
    };
    const fieldFiltered = filterFields(fields, nTask);

    function findField(fieldName: string) {
      return fieldFiltered.find((f) => f.name === fieldName);
    }

    const ds_rule = fieldFiltered.find((f) => f.name === "rule");
    const ds_act = fieldFiltered.find((f) => f.name === "activity_type");

    switch (field.name) {
      case "task_type":
        nTask = {
          ...nTask,
          activity_type: nTask.task_type?.value === "WHATSAPP" ? "TASK" : ds_act?.default_value ?? "",
          assignment_type:
            nTask.task_type?.value === "USER"
              ? nTask.assignment_type
              : "COMPLEX",
          rule: nTask.task_type?.value === "USER" ? nTask.rule : "",
        };
        break;

      case "assignment_type":
        const assRule =
          nTask.assignment_type?.value === "CURRENT_USER"
            ? ""
            : ds_rule?.default_value ?? "";
        nTask = {
          ...nTask,
          rule: assRule,
        };
        break;
      case "users":
      case "groups":
        const nVal = value?.map((v: any) => v.value.id) ?? [];

        nTask = {
          ...nTask,
          rule:
            ds_rule?.default_value?.replace("[]", JSON.stringify(nVal)) ?? "",
        };
        break;
      case "output_variable":
      case "output_script":
      case "template_id":
      case "phone":
      case "only_notification":
      case "variables":
        const element = parseStringToJson(nTask.rule);

        if (field.name === "template_id") {
          element[field.name] = value.value;
          const activeT = templates.find((temp) => temp.id === value.value) || {variables: {}};
          const objVariables = Object.keys(activeT.variables).reduce((acc: {[key: string]: string}, key) => {
            acc[key] = "";
            return acc;
          }, {});

          nTask = {
            ...nTask,
            variables: objVariables,
          };
          newOptions.variables = newOptions.variables.map((variable: { label: string; value: string; template: {[key:string]: string} }) => variable.template = objVariables);
        }
        else if (field.name === 'variables') {
          element[field.name] = JSON.stringify(value);
        }
        else if (field.name === 'phone' || field.name === 'only_notification') {
          element[field.name] = value.value;
        }
        else {
          element[field.name] = value;
        }

        nTask = {
          ...nTask,
          rule:
            'process["__whatsapp_config"] = ' + JSON.stringify(element) ?? "",
        };
        break;
    }

    if (nTask.rule === ds_rule?.default_value) {
      if (findField("groups")) {
        const nVal = task.groups?.map((v: any) => v.value.id) ?? [];
        nTask = {
          ...nTask,
          rule:
            ds_rule?.default_value?.replace("[]", JSON.stringify(nVal)) ?? "",
        };
      } else if (findField("users")) {
        const nVal = task.users?.map((v: any) => v.value.id) ?? [];
        nTask = {
          ...nTask,
          rule:
            ds_rule?.default_value?.replace("[]", JSON.stringify(nVal)) ?? "",
        };
      }
    }

    setTask(nTask);
  }

  useAsyncEffect(async () => {
    if (props.data?.task?.process_id) {
      setLoadingFields(true);
      const response = await getModelProcessDataFields(
        props.data.task.process_id,
      );
      if (response?.success) {
        const items = response.items?.map(
          (item: { label: string; name: string }) => ({
            label: item.label,
            value: item.name,
          }),
        );
        if (task.template_id){
          const whatsappRule = parseStringToJson(task.rule);
          const template = newOptions.template_id.find(
              (temp: any) => temp.value === whatsappRule.template_id,
          );
          if (template) {
            for (const item of items) {
              item.template = whatsappRule.variables
            }
          }

        }
        setProcessDataFields(items);
      }
      setLoadingFields(false);
    }
  }, [props.data]);

  return (
    <ModalComponent
      isLoading={loading}
      size="lg"
      title={props.data.task.id ? t("update") : t("new")}
      isOpen={props.open}
      type="submit"
      form="formUpsertModal"
      toggle={toggle}
    >
      <div>
        {fieldsLoading ? (
          <div className="d-flex justify-content-center p-5 align-items-center">
            <SpinnerComponent />
          </div>
        ) : (
          <form id="formUpsertModal" onSubmit={onSubmit}>
            <Row>
              {filterFields(fields, task).map((field, id) => {
                return (
                  <Col
                    key={field.name}
                    md={(field as any).editor ? 12 : 6}
                    sm={12}
                  >
                    <CreateFieldPreview
                      options={newOptions[field.name]}
                      onChange={(e) => onChangeValues(field, e)}
                      value={task[field.name]}
                      field={{
                        ...field,
                        id,
                        variables: processDataFields.map((str) => ({
                          label: capitalize(str.label),
                          text: str.value,
                          description: str.value,
                        })),
                      }}
                    />
                  </Col>
                );
              })}
            </Row>
          </form>
        )}
      </div>
    </ModalComponent>
  );
};

export default UpsertTaskModal;
