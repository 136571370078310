import {
  conditions,
  ConditionType,
  Container,
  FieldTypesRule,
  GroupCondition,
  Operator,
  RuleCondition,
  ValuesData,
} from "./Types";
import { removeBracketsFromString } from "../../utils";

export function getSize(containers: Container[], field: Container) {
  let size = Math.trunc(12 / containers.length);
  size = containers.length > 4 ? 3 : size;
  size = field.type === "condition" ? 2 : size;
  return size;
}

export function existCondition(containers: Container[]) {
  return containers.some((c) => c.type === FieldTypesRule.CONDITION);
}

export function existField(containers: Container[]) {
  return containers.some(
    (c) =>
      (c.type === FieldTypesRule.TEXT ||
        c.type === FieldTypesRule.NUMBER ||
        c.type === FieldTypesRule.SELECT) &&
      c.isValidator,
  );
}

export function existComparator(containers: Container[]) {
  return containers.some(
    (c) =>
      (c.type === FieldTypesRule.TEXT ||
        c.type === FieldTypesRule.NUMBER ||
        c.type === FieldTypesRule.SELECT) &&
      c.isComparator,
  );
}

export function sortContainers(containers: Container[]) {
  return containers
    .map((c) => {
      let labelSort = "a";
      if (c.type === FieldTypesRule.CONDITION) {
        labelSort = "c";
      }

      if (c.isValidator) {
        labelSort = "d";
      }

      if (c.isComparator) {
        labelSort = "b";
      }

      return { ...c, labelSort };
    })
    .sort((a, b) => a.labelSort.localeCompare(b.labelSort));
}

export function addRule(rules: GroupCondition[], value: ValuesData) {
  let array = new Array(...rules);
  let rule: any = {};
  Object.keys(value).forEach((key) => {
    const val = value[key];
    switch (val.type) {
      case FieldTypesRule.CONDITION:
        rule.condition = val.value.value;
        if (rule.condition.includes("%word%")) {
          rule.condition = rule.condition.replace("%word%", rule.field);
        }

        break;
      default:
        switch (val.label) {
          case "b":
            rule.field =
              val.type === FieldTypesRule.SELECT
                ? val.value.value || ""
                : val.value;
            rule.label =
              val.type === FieldTypesRule.SELECT
                ? val.value.label || ""
                : undefined;
            break;
          case "d":
            switch (val.type) {
              case FieldTypesRule.TEXT:
                rule.value = val.value;
                break;
              case FieldTypesRule.SELECT:
                rule.value = val.value.value;
                break;
              case FieldTypesRule.NUMBER:
                rule.value = parseInt(rule.value);
            }
            break;
        }
        break;
    }
  });
  rule.operator = Operator.OR;
  if (rule.condition.includes("%value%") && rule.value) {
    rule.condition = rule.condition.replace("%value%", `'${rule.value}'`);
  }
  array.push(rule);
  return array;
}

export function generateValues(containers: Container[], values: ValuesData) {
  let valuesT: ValuesData = {};
  sortContainers(containers).forEach((c: Container, index) => {
    const validate = c.value !== values[index]?.value;
    const val = validate ? c.value : valuesT[index]?.value;
    switch (c.type) {
      case "text":
      case "number":
        valuesT[index] = { value: val ?? "", type: c.type, label: c.labelSort };
        break;
      case "condition":
      case "select":
        valuesT[index] = {
          value: val ?? null,
          type: c.type,
          label: c.labelSort,
        };
    }
  });

  return valuesT;
}

export function onChangeValues<T>(rules: T[], index: number, value: T) {
  try {
    let array = new Array(...rules);
    array[index] = value;
    return array;
  } catch (e) {
    return rules;
  }
}

export function onDeleteRule(rules: GroupCondition[], index: number) {
  let array = new Array(...rules);
  return array.filter((r, i) => i !== index);
}

function validateStringValue(value?: string | number) {
  try {
    switch (typeof value) {
      case "number":
        return `${value}`;
      case "string":
        return `"${value}"`;
      case "undefined":
        return "";
      default:
        return `"${value}"`;
    }
  } catch (e) {
    return "";
  }
}

export function rulesToStringJS(rules: GroupCondition[]) {
  let str: string = "";
  const lastIndex = rules.length - 1;
  rules.forEach((rule, index) => {
    const value =
      rule.condition === ConditionType.NO_EXIST ||
      rule.condition === ConditionType.EXIST
        ? undefined
        : rule.value;
    str += rule.condition.includes("includes")
      ? `${rule.condition} ${index !== lastIndex ? ` ${rule.operator} ` : ""}`
      : `${rule.field} ${rule.condition} ${validateStringValue(value)}${
          index !== lastIndex ? ` ${rule.operator} ` : ""
        }`;
  });
  return str;
}

export function stringGroupRulesToArray(
  rules: string,
  replaceFieldLabel?: { [key: string]: string },
): RuleCondition[] {
  const rulesArray = rules.split("(");
  return rulesArray
    .filter((r) => !!r)
    .map((r) => {
      const operator = r.includes("&&") ? Operator.AND : Operator.OR;
      const stringRules = r.split(")").shift() ?? "";

      return {
        operator,
        group: stringRuleToArray(stringRules, replaceFieldLabel),
      };
    });
}

export function arrayGroupRulesToString(rules: RuleCondition[]): string {
  let str: string = "";
  rules.forEach((rule, index) => {
    str += `(${rulesToStringJS(rule.group)})${
      index !== rules.length - 1 ? ` ${rule.operator} ` : ""
    }`;
  });
  return str;
}

export function stringRuleToArray(
  rule: string,
  replaceFieldLabel?: { [key: string]: string },
) {
  let rules: GroupCondition[] = [];

  const getValues = (validator: string) => {
    const condition =
      [
        ...conditions,
        { label: "CONTAIN_LIKE", value: "%word%.includes(%value%)" },
      ].find((c) => {
        const result = validator.split(c.value) as string[];
        return result.length === 2 && !result.some((e) => e.includes("null"));
      })?.value ?? ("" as any);

    if (condition) {
      const sp = validator.split(condition);
      const value = (sp.pop()?.replaceAll('"', "") ?? "").split(" ").join("");
      const field = (sp.shift() ?? "").split(" ").join("");
      return {
        value:
          condition === ConditionType.NO_EXIST ||
          condition === ConditionType.EXIST
            ? undefined
            : value,
        field,
        condition,
      };
    }

    const results = validator
      .split(".includes")
      .map((v) => removeBracketsFromString(v).replaceAll("'", ""));

    if (results.length > 1) {
      return {
        value: results[1],
        field: results[0],
        condition: `${results[0]}.includes('${results[1]}')`,
      };
    }

    return { field: "", value: "", condition: "" };
  };

  function setRule(v: string, operator: Operator) {
    const { field, condition, value } = getValues(v);
    let rule: GroupCondition = {
      field,
      value,
      condition,
      operator,
      label: replaceFieldLabel ? replaceFieldLabel[field] : undefined,
    };
    rules.push(rule);
  }

  rule.split(Operator.OR).forEach((v) => {
    if (v.includes(Operator.AND)) {
      v.split(Operator.AND).forEach((v2) => {
        setRule(v2, Operator.AND);
      });
    } else {
      setRule(v, Operator.OR);
    }
  });

  return rules;
}
