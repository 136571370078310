// @Author: Frank Carpio

import {
  findAllByModel,
  insertModelRow,
} from "../../services/backend/SbxService";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileExcel,
  faSave,
  faSpinner,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { actionsModal, ModalTypes } from "../../store/Modal/Slice";
import React, { useEffect, useMemo, useState } from "react";
import {
  convertTableRowsToCSVString,
  getDataFromKey,
  handleFileUpload,
  toMap,
} from "../../utils";
import CustomTableComponent, {
  Column,
} from "../Shared/CustomTableComponent/CustomTableComponent";
import useTranslate from "../../hooks/useTranslate";
import { SbxCrmDataColumn, SbxCrmDataInfo } from "../../types/User";
import useMemoAsync from "../../hooks/useMemoAsync";
import { getProviderByIdWidthOptions } from "../../services/backend/DataProviderService";
import { getQuery } from "../ListProviderComponent/DetailComponent";

interface SbxModelComponentProps {
  sbxModel: string;
  config: SbxCrmDataInfo;
  onSuccess?: (params: any, response: any) => void;
}

const downloadFile = (text: string, filename: string) => {
  const a = window.document.createElement("a");
  a.setAttribute("id", filename);
  a.setAttribute(
    "href",
    "data:text/csv; charset=utf-8," + encodeURIComponent(text),
  );
  a.setAttribute("download", `${filename}.csv`);
  a.click();
};
export default function SbxModelComponent(props: SbxModelComponentProps) {
  const { sbxModel, config } = props;
  const { t } = useTranslate("common");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [rows, setRows] = useState<any[]>([]);
  const [file, setFile] = useState<File | null>(null);
  const [loadingReference, setLoadingReference] = useState(false);

  const columns = useMemo(
    () =>
      config.columns.reduce((acc: Column[], ref) => {
        if (!ref.hidden && ref.type !== "Document") {
          acc.push({
            header: ref.label,
            name: ref.column,
            data: ref,
            style: { minWidth: "max-content" },
          });
        }
        return acc;
      }, []),
    [config],
  );
  const references = useMemo(
    () =>
      config.columns.filter(
        (ref) =>
          ((ref.type === "reference" && ref.reference) ||
            ref.type === "list_provider") &&
          !ref.hidden,
      ),
    [config],
  );

  const fetchModelsMap = useMemoAsync(async () => {
    setLoadingReference(true);
    const response: { items: any[]; row_model: string }[] = [];

    for await (const ref of references) {
      switch (ref.type) {
        case "reference":
          const [fetchRef, key] = ref.column_reference.column.split(".") ?? [];
          const res = await findAllByModel({
            row_model: ref.reference,
            fetch: fetchRef && key ? [fetchRef] : [],
          });
          const rowsModel = (res.items ?? []).map((item: any) => {
            let keyToSearch = "";
            if (key) {
              keyToSearch = `${fetchRef}.${item[fetchRef]}.${key}`;
            } else {
              keyToSearch = fetchRef;
            }

            return {
              _KEY: item._KEY,
              label: getDataFromKey(
                key ? res.fetched_results : item,
                keyToSearch,
              ),
            };
          });
          response.push({ ...res, items: rowsModel });

          break;

        case "list_provider":
          const resProvider = await getProviderByIdWidthOptions(
            ref.list_provider as number,
          );
          switch (resProvider.item?.provider_type) {
            case "SBX":
              let queryString = resProvider.item?.query ?? "{}";
              const query = getQuery(queryString);
              query.where = [];
              const resListProvider = await findAllByModel(query).then(
                (res) => {
                  return {
                    items:
                      res.items?.map((item: any) => {
                        const label = ref.format_rules.columns_labels
                          .map((rule) => {
                            return getDataFromKey(item, rule.name);
                          })
                          .join(" ");
                        return {
                          _KEY: item._KEY,
                          label,
                        };
                      }) ?? [],
                    row_model: ref.list_provider?.toString() ?? "",
                  };
                },
              );
              response.push(resListProvider);
              break;

            case "DATABASE":
              response.push({
                items:
                  resProvider.item?.options?.map((opt) => ({
                    _KEY: opt.id.toString(),
                    label: opt.name,
                  })) ?? [],
                row_model: ref.list_provider?.toString() ?? "",
              });
              break;
          }
      }
    }

    setLoadingReference(false);
    return response.reduce(
      (acc: Record<string, Record<string, any>>, res, index) => {
        acc[res.row_model] = toMap(res.items, "_KEY");
        return acc;
      },
      {},
    );
  }, [config]);

  useEffect(() => {
    if (file) {
      handleFileUpload(file, (arr) => {
        if (arr?.length) {
          const mapColumns = toMap(columns, "header");
          const headers = Object.keys(arr[0])
            .map((key) => mapColumns[key].data)
            .filter((column) => column) as SbxCrmDataColumn[];

          const _rows = arr.map((row) => {
            //apply rules from column setup
            return headers.reduce((obj: any, column) => {
              //validate column value in row
              obj = { ...obj, [column.column]: row[column.label] };

              return obj;
            }, {});
          });
          setRows(_rows);
        }
      });
    }
  }, [file]);

  function exportTemplate() {
    const text = convertTableRowsToCSVString(columns, rows);
    downloadFile(text, config.label);
  }

  function downloadTemplateKey(column: SbxCrmDataColumn) {
    switch (column.type) {
      case "reference":
        const arrayReference = Object.values(
          fetchModelsMap[column.reference],
        ) as any[];
        const header1 = Object.keys(
          arrayReference[0] ?? { message: "no data" },
        ).map((key) => ({
          name: key,
          header: key,
        }));
        const csvText = convertTableRowsToCSVString(header1, arrayReference);
        downloadFile(csvText, column.label);

        break;

      case "list_provider":
        const array = Object.values(
          fetchModelsMap[column.list_provider?.toString() ?? ""],
        ) as any[];
        const header2 = Object.keys(array[0] ?? { message: "no data" }).map(
          (key) => ({
            name: key,
            header: key,
          }),
        );
        const csvText2 = convertTableRowsToCSVString(header2, array);
        downloadFile(csvText2, column.label);
    }
  }

  async function saveRows() {
    setLoading(true);
    if (rows.length) {
      const res = await insertModelRow(rows, sbxModel);
      if (res.success) {
        if (props.onSuccess) {
          props.onSuccess({ rows, sbxModel }, res);
        }
        dispatch(
          actionsModal.closeModal({
            type: ModalTypes.DYNAMIC_COMPONENT_MODAL,
          }),
        );
      }
    }
    setLoading(false);
  }

  const rowsResultMapped = useMemo(() => {
    return rows.map((row) => {
      return Object.keys(row).reduce((obj: any, key) => {
        const column = columns.find((column) => column.name === key);

        switch (column?.data.type) {
          case "list_provider":
            obj[key] = getDataFromKey(
              fetchModelsMap,
              `${column.data.list_provider}.${row[key]}.label`,
            );
            break;
          case "reference":
            obj[key] = getDataFromKey(
              fetchModelsMap,
              `${column.data.reference}.${row[key]}.label`,
            );
            break;
          default:
            obj[key] = row[key];
        }
        return obj;
      }, {});
    });
  }, [rows, fetchModelsMap]);

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <b className="my-2"> {sbxModel.toUpperCase().split("_").join(" ")}</b>
        <FontAwesomeIcon
          className="pointer no-selectable"
          icon={faTimes}
          onClick={() =>
            dispatch(
              actionsModal.closeModal({
                type: ModalTypes.DYNAMIC_COMPONENT_MODAL,
              }),
            )
          }
        />
      </div>

      {/*body*/}
      <div>
        <div className="form-group">
          <div className="py-2">
            <label className="btn btn-primary btn-sm" htmlFor="file-upload-sbx">
              {t("select-file")}
            </label>
            <b className="ms-2">{file?.name}</b>
            <input
              disabled={loading}
              id="file-upload-sbx"
              className="d-none"
              type="file"
              accept=".csv"
              onChange={(e) => {
                const files = e.target.files;
                if (files) {
                  setFile(files[0]);
                }
              }}
            />
          </div>
        </div>
        <div className="d-flex justify-content-end">
          <button
            disabled={loadingReference}
            onClick={exportTemplate}
            className="btn btn-success"
          >
            <FontAwesomeIcon
              spin={loadingReference}
              icon={loadingReference ? faSpinner : faFileExcel}
            />{" "}
            {t("export")} {t("template")}
          </button>

          {references.map((column) => {
            return (
              <button
                disabled={loadingReference}
                className="btn btn-primary mx-1"
                key={column.label}
                onClick={() => downloadTemplateKey(column)}
              >
                {t("export")} keys {column.label}
              </button>
            );
          })}
        </div>
        <CustomTableComponent columns={columns} data={rowsResultMapped} />
      </div>

      {/*footer*/}
      <div className="d-flex justify-content-end pt-3  border-top">
        <button
          disabled={loading}
          onClick={saveRows}
          className="btn btn-primary"
        >
          <FontAwesomeIcon
            spin={loading}
            icon={loading ? faSpinner : faSave}
            className="mr-1"
          />
          {t("save")}
        </button>
      </div>
    </>
  );
}
