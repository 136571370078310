import React, {useContext, useState} from 'react';
import {AnalyticQuery, AnalyticQueryAction} from '../../../types/Analytic';
import {Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEllipsisV, faSyncAlt, faTrash} from '@fortawesome/free-solid-svg-icons';
import {ReportGeneratorContext} from '../ReportGeneratorComponent';
import ActionGroupByComponent from './ActionGroupByComponent/ActionGroupByComponent';
import useTranslate from "../../../hooks/useTranslate";
import {Tooltip} from "antd";
import ForecastActionComponent from "./MLActionComponent/ForecastActionComponent";
import MonetarySegmentationActionComponent from "./MLActionComponent/MonetarySegmentationActionComponent";
import BinaryClassifierActionComponent from "./MLActionComponent/BinaryClassifierActionComponent";
import SegmentationActionComponent from "./MLActionComponent/SegmentationActionComponent";
import DataTransformComponent from "./TransformComponent/DataTransformComponent";
import DateTransformFormComponent from "./TransformComponent/DateTransformComponent/DateTransformFormComponent";
import SortActionComponent from "./SortActionComponent";
import SelectActionComponent from "./SelectActionComponent";
import LimitActionComponent from "./LimitActionComponent";
import ActionRenameComponent from "./ActionRenameComponent";
import FilterActionComponent from "./FilterActionComponent";
import ActionDefaultValueComponent from "./ActionDefaultValueComponent";
import ActionMergeComponent from "./ActionMergeComponent/ActionMergeComponent";
import SaveActionComponent from "./SaveActionComponent";
import SelectComponent from "../../Shared/FieldComponents/SelectComponent";
import {NumberOption} from "../../../types/Select";

type Props = {
    action: AnalyticQueryAction
    index: number
    getColumnsByAction: ({action, nQuery}: { action?: AnalyticQueryAction, nQuery?: AnalyticQuery }) => void
}

export interface OnChangeActionParams {
    action: AnalyticQueryAction,
    value: number | boolean | string | string[] | { [key: string]: any },
    actionKey: keyof AnalyticQueryAction,
    noGetColumns?: boolean
}

const ActionComponent = ({action, index, getColumnsByAction}: Props) => {

    const {
        reportState: {analyticQuery, actionColumns},
        dispatchForm,
        dispatchMultiForm, getAllActionColumns
    } = useContext(ReportGeneratorContext);
    const {t} = useTranslate('report');

    const [hierarchy, setHierarchy] = useState<NumberOption[]>([])
    const [hierarchyValue, setHierarchyValue] = useState<NumberOption | null>(null)

    React.useEffect(() => {

        if (analyticQuery?.actions && analyticQuery.actions.length > 0) {
            const action = analyticQuery.actions.flat().find(action => action.subtype === 'drill_down')
            if (action?.hierarchy) {
                setHierarchy( [{label: t("common:all"), value: -1},
                    ...action.hierarchy.map((h, i) => ({label: h, value: i}))])
            }
        }

    }, [analyticQuery]);

    React.useEffect(() => {
        if (hierarchy.length > 0){
            setHierarchyValue(hierarchy.find(option => option.value === action.drill_down_execution_index) ?? null)
        }
    }, [action, hierarchy]);

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen(prevState => !prevState)


    const removeAction = (action: AnalyticQueryAction) => {
        const actionList = analyticQuery.actions.filter(nAction => {
            return !nAction.some(subAction => subAction.dependency_action_id === action.temporal_id || subAction.temporal_id === action.temporal_id)
        });

        const newQuery = {
            ...analyticQuery, actions: actionList
        };

        const newActionColumns = {...actionColumns}

        if (action.temporal_id && newActionColumns[action.temporal_id]) {
            delete newActionColumns[action.temporal_id]
        }

        dispatchMultiForm([
            {
                name: 'analyticQuery', value: newQuery
            },
            {name: 'actionColumns', value: newActionColumns}
        ])
    };

    const onChangeAction = (actions: OnChangeActionParams[], isHandleColumns = false) => {

        let queryActions = [...analyticQuery.actions];

        actions.forEach(({action, actionKey, value}) => {
            let subIndexOP = -1

            const queryActionIndex = queryActions.findIndex((nAction) => {
                const subIndex = nAction.findIndex(subAction => subAction.temporal_id === action.temporal_id)
                if (subIndex !== -1) {
                    subIndexOP = subIndex
                }
                return subIndex >= 0
            });

            if (queryActionIndex >= 0 && subIndexOP >= 0) {
                let queryAction = queryActions[queryActionIndex][subIndexOP];
                queryAction = {...queryAction, [actionKey]: value};
                queryActions[queryActionIndex][subIndexOP] = queryAction;

                if (isHandleColumns) {
                    getAllActionColumns({...analyticQuery, actions: queryActions}, queryActionIndex + 1)
                }

            }


        })


        const newQuery: AnalyticQuery = {...analyticQuery, actions: queryActions};
        dispatchForm({name: 'analyticQuery', value: newQuery});


    };

    const getSubTypeAction = (action: AnalyticQueryAction): {
        [actions: string]: { [subTypeAction: string]: Element | JSX.Element }
    } => {
        return {
            ml: {
                forecast: <ForecastActionComponent action={action}/>,
                monetary_segmentation: <MonetarySegmentationActionComponent action={action}/>,
                segmentation: <SegmentationActionComponent action={action}/>,
                binary_classifier: <BinaryClassifierActionComponent action={action}/>,
            },
            transform: {
                data_transform: <DataTransformComponent action={action}/>,
                date_formatter: <DateTransformFormComponent action={action}/>,
                date_operations: <DateTransformFormComponent action={action} isOperationAction/>,
            },
            group_by: {
                drill_down: <ActionGroupByComponent action={action} isDrillDown
                                                    onChangeAction={actions => onChangeAction(actions, true)}/>
            }
        }
    }

    const getTransformSubtypeByType = (action: AnalyticQueryAction) => {
        if (action.subtype) {
            return getSubTypeAction(action)[action.type][action.subtype]
        } else {
            if (action.transformation) {
                const transformation: string = action.transformation as string
                if ((action.transformation as string).includes("pdf.")) {
                    return getSubTypeAction(action)[action.type]["data_transform"]
                } else {
                    if (transformation.includes("@add") || transformation.includes("@diff") || transformation.includes("@sub")) {
                        return getSubTypeAction(action)[action.type]["date_operations"]
                    } else if (transformation.includes("@formateddate_to_date") || transformation.includes("@numberdate_to_date") || transformation.includes("@date_to_formateddate")) {
                        return getSubTypeAction(action)[action.type]["date_formatter"]
                    }
                }

            }

        }

        if (getSubTypeAction(action)[action.type]) {
            return getSubTypeAction(action)[action.type]["data_transform"]
        }

        return <div/>


    }

    const getAction = (action: AnalyticQueryAction, index: number) => {

        let contentAction = null

        switch (action.type) {

            case "sort": {
                contentAction = <SortActionComponent index={index} onChangeAction={onChangeAction} action={action}/>
            }
                break;
            case "select": {
                contentAction =
                    <SelectActionComponent onChangeAction={actions => onChangeAction(actions, true)} action={action}
                                           index={index}/>
            }
                break
            case "limit": {
                contentAction = <LimitActionComponent onChangeAction={onChangeAction} action={action}/>
            }
                break
            case "rename": {
                contentAction =
                    <ActionRenameComponent action={action} onChangeAction={actions => onChangeAction(actions, true)}/>
            }
                break
            case "group_by": {
                contentAction = action.subtype ? getSubTypeAction(action)[action.type][action.subtype] :
                    <ActionGroupByComponent action={action}
                                            onChangeAction={actions => onChangeAction(actions, true)}/>
            }
                break
            case "pivot": {
                contentAction = <ActionGroupByComponent action={action} isPivot
                                                        onChangeAction={actions => onChangeAction(actions, true)}/>
            }
                break;
            case "transform": {
                contentAction = getTransformSubtypeByType(action) ?? <div/>
            }
                break;
            case "filter": {
                contentAction = <FilterActionComponent action={action}/>
            }
                break
            case "default_values": {
                contentAction = <ActionDefaultValueComponent action={action}/>
            }
                break;
            case "merge": {
                contentAction = <ActionMergeComponent action={action}/>
            }
                break;
            case "ml": {
                contentAction = action.subtype ? getSubTypeAction(action)[action.type][action.subtype] : <div/>
            }
                break;
            case "save": {
                contentAction = <SaveActionComponent action={action}/>
            }

                break

        }


        // const actionComponent: { [action: string]: Element | JSX.Element } = {
        //     sort: <SortActionComponent index.tsx={index.tsx} onChangeAction={onChangeAction} action={action}/>,
        //     select: <SelectActionComponent onChangeAction={actions => onChangeAction(actions, true)} action={action}
        //                                    index.tsx={index.tsx}/>,
        //     limit: <LimitActionComponent onChangeAction={onChangeAction} action={action}/>,
        //     rename: <ActionRenameComponent action={action} onChangeAction={actions => onChangeAction(actions, true)}/>,
        //     group_by: action.subtype ? getSubTypeAction(action)[action.type][action.subtype] : <ActionGroupByComponent action={action}
        //                                       onChangeAction={actions => onChangeAction(actions, true)}/>,
        //     pivot: <ActionGroupByComponent action={action} isPivot
        //                                    onChangeAction={actions => onChangeAction(actions, true)}/>,
        //     transform: getTransformSubtypeByType(action) ?? <div/>,
        //     filter: <FilterActionComponent action={action}/>,
        //     default_values: <ActionDefaultValueComponent action={action}/>,
        //     merge: <ActionMergeComponent action={action}/>,
        //     ml: action.subtype ? getSubTypeAction(action)[action.type][action.subtype] : <div/>,
        //     save: <SaveActionComponent action={action}/>,
        //     default: <div/>
        // }

        // let contentAction = actionComponent["default"]
        //
        // if (actionComponent[action.type]){
        //     contentAction = actionComponent[action.type]
        // }

        return <div className="card shadow p-2" key={"content_" + action.type + "_" + index}>
            <div className="card-header border-bottom mb-2 py-0 d-flex justify-content-between">
                <div>
                    {t(action.subtype ?? action.type)}
                </div>

                <div className="d-flex align-items-center">
                    {/*<ButtonComponent color={"default"} sizeIcon={"2x"} icon={faArrowsAltV} label={""}/>*/}

                    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                        <DropdownToggle color={'default'}>
                            <FontAwesomeIcon icon={faEllipsisV}/>
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem key={index} onClick={() => getColumnsByAction({})}>
                                <FontAwesomeIcon className="me-3" icon={faSyncAlt}/>
                                <span>{t("refresh-column")}</span>
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>


                </div>

            </div>
            <div className="d-flex flex-column">
                <>
                    {contentAction}
                </>

                {action.subtype !== "drill_down" && analyticQuery.actions.flat().some(action => action.subtype === "drill_down") &&
                    <div className="mt-3 border-top d-flex align-items-start flex-column">
                        <b>{t("drill_down_execution_level")}</b>
                        <div className="w-50">
                            <SelectComponent menuPosition={"fixed"} id={""} name={""} value={hierarchyValue}
                                             sortOptions={false}
                                             options={hierarchy} onChange={evt => {
                                action.drill_down_execution_index = evt.value
                                setHierarchyValue(evt)
                            }}/>
                        </div>
                    </div>}
                <div className="text-end mt-4">
                    <Tooltip title={t("common:remove")}>
                        <Button color={'danger'} onClick={() => action.temporal_id ? removeAction(action) : undefined}>
                            <FontAwesomeIcon icon={faTrash}/> {t("common:remove")} {t("common:action")}
                        </Button>
                    </Tooltip>
                </div>
            </div>

        </div>;
    };

    return getAction(action, index);
};

export default ActionComponent;
