import React from 'react';
import NumberFormat, {NumberFormatValues} from "react-number-format";
import TextProps from "./Models/TextProps";

interface IProps extends TextProps {
  value: string;
  onChange?: (value: string) => void;
}

const DocumentComponent = (props: IProps) => {

  const {
    id,
    name,
    value,
    required,
    onChange,
    className,
    style,
    invalid,
    placeholder,
    defaultValue, disabled
  } = props;

  return <NumberFormat id={id}
                       required={required}
                       name={name}
                       style={style}
                       defaultValue={defaultValue}
                       className={`form-control ${className || ''} ${invalid ? 'invalid' : ''}`}
                       placeholder={placeholder}
                       value={value}
                       thousandSeparator="."
                       decimalSeparator=","
                       readOnly={disabled}
                       decimalScale={0}
                       allowNegative={false}
                       onValueChange={(values: NumberFormatValues) => onChange ? onChange(values.value) : null}
  />;
};

export default DocumentComponent;
