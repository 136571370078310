import * as React from 'react';
import {useState} from 'react';
import {ActionType} from "../../types/Analytic";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ButtonComponent from "../Shared/ButtonComponent";
import {faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";
import {ActionButton} from "./NewReportGeneratorComponent";
import {IconDefinition} from "@fortawesome/fontawesome-common-types";
import useTranslate from "../../hooks/useTranslate";


type ActionList = ({ label: string; value: string; icon: IconDefinition; color: string; textColor: string; } | {
    label: string;
    value: string;
    icon: IconDefinition;
    color: string;
    textColor?: undefined;
})

type Props = {
    basicActionsList: ActionList[]
    advanceActionsList: ActionList[],
    mlActionsList: ActionList[],
    getDefaultAction: (actionType: ActionType, index?: number) => void
};
const NewActionListReportGenerator = ({
                                          basicActionsList,
                                          advanceActionsList,
                                          mlActionsList,
                                          getDefaultAction
                                      }: Props) => {
    const {t} = useTranslate("common")
    const [showAdvanceActions, setShowAdvanceActions] = useState(false)
    const [showMlActions, setShowMlActions] = useState(false)


    return (
        <div className="card">

            <div className="d-flex flex-column my-3 px-3">
                <div className="card-header mb-3">
                    {t("common:actions")}
                </div>
                <div className="d-flex flex-wrap gap-3 align-items-center">
                    {basicActionsList.map((item, index) => (
                        <ActionButton key={item.value} className="btn "
                                      color={item.color}
                                      textColor={item.textColor}
                                      onClick={() => getDefaultAction(item.value as ActionType)}>
                            <FontAwesomeIcon icon={item.icon}/>
                            <span>{item.label}</span>
                        </ActionButton>
                    ))}
                </div>

                <div className="pt-4 d-flex justify-content-end gap-3">
                    <ButtonComponent label={t("report:view_advanced_actions")}
                                     icon={!showAdvanceActions ? faEye : faEyeSlash} outline
                                     color={"info"}
                                     onClick={() => setShowAdvanceActions(prevState => !prevState)}/>

                    <ButtonComponent label={t("report:view_machine_learning_actions")}
                                     icon={!showMlActions ? faEye : faEyeSlash} outline
                                     color={"info"}
                                     onClick={() => setShowMlActions(prevState => !prevState)}/>
                </div>
            </div>

            {showAdvanceActions &&
                <div className="d-flex flex-column my-3 px-3">
                    <div className="card-header mb-3">
                        {t("report:advance_actions")}
                    </div>
                    <div className="d-flex flex-wrap gap-3 align-items-center">
                        {advanceActionsList.map((item) => (
                            <ActionButton key={item.value} className="btn "
                                          color={item.color}
                                          textColor={item.textColor}
                                          onClick={() => getDefaultAction(item.value as ActionType)}>
                                <FontAwesomeIcon icon={item.icon}/>
                                <span>{item.label}</span>
                            </ActionButton>
                        ))}
                    </div>
                </div>
            }

            {showMlActions && <div className="d-flex flex-column my-3 px-3">
                <div className="card-header mb-3">
                    Machine learning {t("common:actions")}
                </div>
                <div className="d-flex flex-wrap gap-3 align-items-center">
                    {mlActionsList.map((item, index) => (
                        <ActionButton key={item.value} className="btn "
                                      color={item.color}
                                      textColor={item.textColor}
                                      onClick={() => getDefaultAction(item.value as ActionType)}>
                            <FontAwesomeIcon icon={item.icon}/>
                            <span>{item.label}</span>
                        </ActionButton>
                    ))}
                </div>
            </div>}

        </div>
    );
};

export default NewActionListReportGenerator