import React, {useContext, useState} from 'react';
import {Button} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSave, faSpinner} from '@fortawesome/free-solid-svg-icons';
import {Source, SourceFrom} from '../../../types/Analytic';
import ReportQueryPreview from './ReportQueryPreview';
import {ReportGeneratorContext} from '../ReportGeneratorComponent';
import {State} from '../../../types/State';
import QueryComponent, {Query} from '../../Shared/QueryComponent/QueryComponent';
import {convertSbxFilterToAnalytic, success} from '../../../utils';
import {UpdateSource} from '../SourceComponent/SourceComponent';
import useTranslate from "../../../hooks/useTranslate";
import {Tooltip} from "antd";
import {Andor, GroupCondition, SbxConditionType} from "../../../types/Sbx";
import {useForm} from "react-hook-form";
import RenderReportQueryComponent from "./RenderReportQueryComponent";
import {getOptionsByField} from "../../Shared/QueryComponent/Utils";

type Props = {
    source: Source,
    updateSource: (sources: UpdateSource[]) => void
}

type ReportData = {
    queryData: {
        row_model: string,
        where: GroupCondition[]
    }
}

const ReportPreviewComponentWrapper = ({updateSource, source}: Props) => {

    const {
        reportState: {analyticQuery, isLoading, queriesSource, showPreviewQuery},
        dispatchForm,
        dispatchMultiForm, getColumns, getAllSourcesColumns,
        togglePreviewQuery
    } = useContext(ReportGeneratorContext);


    const {setValue, getValues} = useForm<ReportData>()

    const [sbxQuery, setSbxQuery] = useState<Query | undefined>(source.temporal_id ? queriesSource[source.temporal_id] : undefined)

    React.useEffect(() => {

        if (source.from === SourceFrom.SBX_DATA) {
            if (source.temporal_id && queriesSource && queriesSource[source.temporal_id]) {
                if (queriesSource[source.temporal_id].where.some(where => where.GROUP.some(group => group.OP === "is not null" || group.OP === "is null"))) {
                    const query = {
                        ...queriesSource[source.temporal_id],
                        where: queriesSource[source.temporal_id].where.map(where => {

                            where.GROUP = where.GROUP.map(group => {

                                if (group.OP === "is not null") {
                                    group.OP = SbxConditionType.EXIST
                                }

                                if (group.OP === "is null") {
                                    group.OP = SbxConditionType.NO_EXIST
                                }

                                return group
                            })

                            return where;
                        })
                    }
                    setSbxQuery(query)

                } else {
                    // setQueryComponent(renderWithFilterSection(source.from, queriesSource[source.temporal_id]))
                    setSbxQuery(queriesSource[source.temporal_id])

                }
            } else {
                setSbxQuery(undefined)
            }
        }
    }, [queriesSource, source]);

    const saveAction = async () => {

        switch (source.from) {
            case SourceFrom.SBX_DATA:
                if (sbxQuery) {
                    await handleSource(sbxQuery)
                }
                break;
            case SourceFrom.SBX_EVENT:
            case SourceFrom.SBX_CRM_USER:
            case SourceFrom.SBX_WORKFLOW:
                const data = getValues("queryData")
                if (data.where.some(where => where.OP === SbxConditionType.LIKE)) {
                    data.where = data.where.map(where => {
                        const option = getOptionsByField(t).find(option => option.value.condition === where.OP)
                        if (where.VAL) {
                            where.VAL = option?.value?.format ? (option?.value?.format).replace("word", where.VAL.toString()) : where.VAL
                        }
                        return where
                    })
                }
                if (data) {
                    await handleSource({
                        row_model: data.row_model,
                        where: data.where ? [{ANDOR: Andor.AND, GROUP: data.where}] : []
                    } as Query)
                }

                break;
        }
    }

    const getQueryComponent = () => {
        switch (source.from) {
            case SourceFrom.SBX_DATA:
                return <QueryComponent query={sbxQuery ?? undefined} showFetch showTable={false}
                                       getQuery={setSbxQuery}/>
            default:
                return <RenderReportQueryComponent type={source.from} source={source}
                                                   setQueryData={data => setValue("queryData", data)}/>
        }
    }


    const handleSource = (sbxQuery: Query) => {
        dispatchForm({name: "sourceColumnsLoading", value: State.PENDING})
        const sources: { sourceKey: keyof Source, sourceValue: any, sourceId: string }[] = [{
            sourceKey: 'with',
            sourceValue: sbxQuery.row_model,
            sourceId: source.temporal_id ?? ''
        }];
        sources.push({
            sourceId: source.temporal_id ?? '',
            sourceKey: 'filters',
            sourceValue: sbxQuery?.where && sbxQuery.where?.length > 0 ? convertSbxFilterToAnalytic(sbxQuery.where[0].GROUP) : []
        });

        if (source.with && source.with !== sbxQuery.row_model) {
            sources.push({
                sourceKey: 'fetch',
                sourceId: source.temporal_id ?? '',
                sourceValue: []
            });
        } else {
            sources.push({
                sourceKey: 'fetch',
                sourceId: source.temporal_id ?? '',
                sourceValue: sbxQuery.fetch ?? []
            });
        }


        // updateSource(sources, source);

        updateSource(sources);
        dispatchMultiForm([
            {name: 'queriesSource', value: {...queriesSource, [source.temporal_id ?? '']: sbxQuery}},
            {name: "sourceColumnsLoading", value: State.RESOLVED}
        ])

    }
    const {t} = useTranslate('report');

    return (
        <div className="card">
            <div className="card-header d-flex justify-content-end">
                <Tooltip title={t("common:save")} placement={"bottom"}>
                    <Button color="success" size={'sm'} disabled={isLoading === State.PENDING}
                            onClick={async () => {
                                await saveAction()
                                if (analyticQuery.actions.length === 0) {
                                    await getColumns({source})
                                }

                                if (analyticQuery.source.sources!?.length > 0) {
                                    await getAllSourcesColumns(analyticQuery);
                                }


                                // toggleCanvas();
                                success(t("changes_saved") + "!")
                            }}>
                        <FontAwesomeIcon pulse={isLoading === State.PENDING} className="mr-1"
                                         icon={isLoading === State.PENDING ? faSpinner : faSave}/>
                        <span>{t("common:save")}</span>
                    </Button>


                </Tooltip>
            </div>

            <div className="card-body">
                {getQueryComponent()}
            </div>

            {showPreviewQuery && <ReportQueryPreview query={analyticQuery} toggleCanvas={togglePreviewQuery}/>}
        </div>
    );
};

export default ReportPreviewComponentWrapper;
