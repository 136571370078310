import * as React from 'react';
import {useContext, useState} from 'react';
import {IsJsonString} from "../../utils";
import {ModelsAndFields, TruncateReport} from "../../types/Analytic";
import {ReportContext} from "./NewReportGeneratorComponent";
import {
    Control,
    SubmitErrorHandler,
    useFieldArray,
    useForm,
    UseFormRegister,
    UseFormSetValue,
    UseFormWatch
} from "react-hook-form";
import TruncateRowComponent from "./TruncateRowComponent";
import ButtonComponent from "../Shared/ButtonComponent";
import useTranslate from "../../hooks/useTranslate";
import {faAngleDown, faAngleLeft, faPlusCircle, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import cogoToast from "cogo-toast";

// let modelsCache: ModelsResponse[] = []
const defaultTruncate: TruncateReport = {
    name: "",
    model: "",
    field: "",
    format: "",
    type: "date",
    range: 0
}

export type PropsForm = {
    truncate: TruncateReport,
    others: TruncateReport[]
}

export type ExtraPropsForm = { models_and_fields: ModelsAndFields[] }

const NewTruncateReportActionComponent = ({setTruncate}: { setTruncate: (truncate: TruncateReport) => void }) => {
    const {t} = useTranslate("common");
    const {report, query} = useContext(ReportContext)
    const [showOthers, setShowOthers] = useState(false)
    let onMount = React.useRef(false);
    const {control, watch, setValue, unregister, handleSubmit, register} = useForm<PropsForm>({
        defaultValues: {
            truncate: {
                ...(query.truncate ? ((query.truncate as TruncateReport).model || (query.truncate as TruncateReport).field) ? query.truncate : report?.truncate
                    ? IsJsonString(report?.truncate as string)
                        ? JSON.parse(report?.truncate as string)
                        : defaultTruncate : defaultTruncate : defaultTruncate)
            },
            others: query.truncate ? ((query.truncate as TruncateReport).model || (query.truncate as TruncateReport).field) && (query.truncate as TruncateReport).others ? (query.truncate as TruncateReport).others : [] : []
        }
    });
    const {fields: fieldsOthers, append: appendOthers, remove: removeOthers} = useFieldArray({
        control,
        name: "others",
    });

    React.useEffect(() => {
        const subscription = watch((obj) => {
                setTruncate({
                    ...obj.truncate,
                    others: obj.others ?? []
                } as TruncateReport)
            }
        )
        return () => subscription.unsubscribe()
    }, [watch])

    React.useEffect(() => {

        if  (!onMount.current){


            if(query.truncate && ((query.truncate as TruncateReport).model || (query.truncate as TruncateReport).field) && (query.truncate as TruncateReport).others && (query.truncate as TruncateReport).others!.length > 0){
                setShowOthers(true)
            }
            onMount.current = true
        }

    }, [query]);

    const onError: SubmitErrorHandler<PropsForm> = (error) => {
        if (!error.hasOwnProperty("truncate") && !error.hasOwnProperty("others")) return null

        cogoToast.error(<div>
            <b>{t("custom-message:field_required")}s:</b>
            <ul>
                {error.truncate && Object.keys(error.truncate).map(field => <li key={field}>{t(field)}</li>)}


                {error?.others && (error?.others as {
                    [key: string]: any
                }[] ?? []).map((field, index) => Object.keys(field).map(key => <li
                    key={key + "_" + index}>{t(key)} {t("report:row")} - N°{index + 1}</li>))}

            </ul>
        </div>, {position: "bottom-left"})

    };

    return (
        <div className="p-3">
            <div className="d-flex flex-column mt-2 align-items-end">
                <ButtonComponent label={`${t("new")} ${t("report:range_limit")}`} icon={faPlusCircle} onClick={() => {
                    handleSubmit(() => {
                        appendOthers({...defaultTruncate})
                        setShowOthers(true)
                    }, onError)()
                }}/>
            </div>

            <div className="d-flex flex-column gap-3 pb-3">
                <div className="d-flex align-items-start gap-4">
                    <TruncateRowComponent name={'truncate'} unregister={unregister}
                                          register={register as UseFormRegister<(PropsForm | ExtraPropsForm)>}
                                          watch={watch as UseFormWatch<PropsForm | ExtraPropsForm>}
                                          setValue={setValue as UseFormSetValue<(PropsForm | ExtraPropsForm)>}
                                          control={control as Control<(PropsForm | ExtraPropsForm), any>}/>
                </div>
            </div>


            {fieldsOthers.length > 0 && <div className="text-end gap-2 fw-bold" style={{color: '#1b85b8'}}>
            <span className="pointer underline mr-1"
                  onClick={() => setShowOthers(prevState => !prevState)}>{`${t(showOthers ? "hide" : "show")}`}
            </span>
                <span>
                <FontAwesomeIcon icon={showOthers ? faAngleDown : faAngleLeft}/>
            </span>
            </div>}

            {fieldsOthers.map((field, index) => (
                <div className={`pb-3 ${showOthers ? "d-flex flex-column" : "d-none"}`}
                     key={field.id}>
                    <div className="d-flex flex-column">
                        <span>
                        <b>
                            {t("report:row")}:
                        </b> {index + 1}
                    </span>
                    </div>

                    <div className="d-flex">


                        <TruncateRowComponent name={`others.${index}`} unregister={unregister}
                                              register={register as UseFormRegister<(PropsForm | ExtraPropsForm)>}
                                              watch={watch as UseFormWatch<PropsForm | ExtraPropsForm>}
                                              setValue={setValue as UseFormSetValue<(PropsForm | ExtraPropsForm)>}
                                              control={control as Control<(PropsForm | ExtraPropsForm), any>}/>


                        <div className="d-flex flex-column">
                            <p></p>
                            <ButtonComponent label={t("remove")} color={'danger'} icon={faTrash} onClick={() => {
                                removeOthers(index)
                            }}/>
                        </div>

                    </div>
                </div>

            ))}
        </div>
    );
};

export default NewTruncateReportActionComponent