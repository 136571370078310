import React, {useContext, useState} from 'react';
import {State} from "../../../types/State";
import {ReportGeneratorContext} from "../ReportGeneratorComponent";
import {AnalyticQueryAction} from "../../../types/Analytic";
import {OnChangeActionParams} from "./ActionComponent";
import CreatableSelectComponent from "../../Shared/FieldComponents/CreatableSelectComponent";

type Props = {
    action: AnalyticQueryAction
    index: number
    onChangeAction: (actions: OnChangeActionParams[]) => void
}

const SelectActionComponent = ({action, index, onChangeAction}: Props) => {
    const {reportState: {isLoading, actionLoading}, getActionColumns} = useContext(ReportGeneratorContext);
    const [inputValue, setInputValue] = useState("")
    const handleSelectAction = (evt: { label: string, value: string }[]) => {
        if (action.temporal_id) {
            const columns = evt.map(event => event.value);
            onChangeAction([{actionKey: 'columns', action, value: columns}]);
        }
    };


    

    return (
        <div>
            <CreatableSelectComponent isMulti onInputChange={value => setInputValue(value)} inputValue={inputValue}
                                      closeMenuOnSelect={false}
                                      // className="text-start z-index.tsx-1000"
                                      className="text-start"
                                      styles={{
                                          groupHeading: (base) => ({
                                              ...base,
                                              // border: `2px dotted ${colourOptions[2].color}`,
                                              // color: 'white',
                                              // background: "#5e72e4",
                                              padding: '5px',
                                              fontSize: '12px',
                                              // margin: 0
                                          }),
                                      }}
                                      disabled={(action.temporal_id && actionLoading[action.temporal_id] === State.PENDING) || State.PENDING === isLoading}
                                      loading={(action.temporal_id && actionLoading[action.temporal_id] === State.PENDING) || State.PENDING === isLoading}
                                      onChange={value => {
                                          handleSelectAction(value)
                                          const inputVal = inputValue
                                          setTimeout(() => {
                                              setInputValue(inputVal)
                                          }, 50)
                                      }} menuPosition={'fixed'}
                                      id={'action_type_' + action.type + '_' + index} name={''}
                                      value={(action?.columns as string[])?.map(column => ({
                                          label: column,
                                          value: column
                                      }))}
                                      options={getActionColumns(action)}/>
        </div>
    );
};

export default SelectActionComponent;
