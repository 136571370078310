import * as React from 'react';
import {useMemo} from 'react';
import useTranslate from "../../../../hooks/useTranslate";
import {Control, Controller, useFieldArray, UseFieldArrayRemove, useForm, useWatch} from "react-hook-form";
import {ActionButton} from "../../NewReportGeneratorComponent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlusCircle, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import ActionDropdownColumns from "../ActionDropdownComponent/ActionDropdownColumns";
import ColumnsActionsWrapperComponent from "../../ColumnsActionsWrapperComponent";
import {DropdownItem} from "reactstrap";
import SearchSuggestionOption from "../../SearchSuggestionOption";
import {StringOption} from "../../../../types/Select";

type caseText =  "upper" | "lower" | "capitalize"

type Form = {
    columns: { name: string }[]
    operation: string
    separator?: string
    case?: caseText
    searchValue?: string
    replaceValue?: string
};
type Props = {
    color: string
    columns: StringOption[]
    value: string
    onChange: (value: string) => void
}

const Display = ({control, index, t, remove}: {
    index: number,
    control: Control<Form, any>,
    t: (value: string) => string,
    remove: UseFieldArrayRemove
}) => {
    const data = useWatch({
        control,
        name: `columns.${index}`
    });

    return <div className="d-flex align-items-center gap-1">
        <span>{data?.name ? data.name : `${t("common:column")} ${index + 1}`}</span>
        <FontAwesomeIcon icon={faTimesCircle} className="pointer" onClick={() => {
            remove(index)
        }}/>
    </div>;
};

const TransformStringCalculator = ({color, columns, onChange, value}: Props) => {
    const {t} = useTranslate('report')
    const {control, watch, register, getValues, setValue} = useForm<Form>({
        defaultValues: {
            operation: '@concat',
            case: "upper",
            separator: "' '",
            searchValue: '',
            replaceValue: '',
            columns: []
        }
    })
    const {append, fields, remove, replace} = useFieldArray({
        control,
        name: "columns"
    })

    const operations = useMemo(() => [
        {label: t("concat"), value: "@concat"},
        {label: `${t("common:convert")}`, value: "@case"},
        {label: `${t("replace")} ${t("common:text")}`, value: "@replace"},
    ], [])

    const separators = useMemo(() =>
            [
                {label: `"' '" (${t('space')})`, value: "' '"},
                {label: `',' (${t('comma')})`, value: "','"},
                {label: `';' (${t('semicolon')})`, value: "';'"},
                {label: `':' (${t('double_point')})`, value: "':'"},
                {label: `'-' (${t('middle_dash')})`, value: "'-'"},
                {label: `'_' (${t('underscore')})`, value: "'_'"}
            ]
        , [])

    const handleComplementFields = () => {
        const operation = watch("operation")
        switch (operation) {
            case "@concat":
                return <>
                    <span>{t("common:by")}</span>
                    <Controller render={({field}) => {
                        return <ActionDropdownColumns columns={[]} color={color}
                                                      label={watch("separator") ?? t("separator")}
                                                      onChange={(value) => {
                                                          field.onChange(value)
                                                      }}>
                            <ColumnsActionsWrapperComponent>
                                {search => {
                                    return <>
                                        {separators.filter(column => column.value.toLowerCase().includes(search.toLowerCase())).map((column, indexColumn) => {
                                            return <DropdownItem onClick={() => {
                                                field.onChange(column.value)
                                            }} key={column + "_" + indexColumn}>
                                                {column.label}
                                            </DropdownItem>
                                        })}

                                        {search ?
                                            <SearchSuggestionOption search={search} onChange={() => {
                                            }} toggle={() => {
                                                field.onChange(`'${search}'`)
                                            }}/> : separators.length === 0 ?
                                                <DropdownItem disabled>{t("common:empty")}</DropdownItem> : null
                                        }
                                    </>
                                }}
                            </ColumnsActionsWrapperComponent>
                        </ActionDropdownColumns>
                    }} name={`separator`} control={control}/>
                </>
            case "@case":
                return <>
                    <span>{t("common:to_1")}</span>
                    <Controller render={({field}) => {
                        return <ActionDropdownColumns columns={[
                            'upper',
                            'lower',
                            'capitalize'
                        ]} color={color} label={t(watch('case') ?? "")} onChange={(value) => {
                            field.onChange(value)
                        }}/>
                    }} name={`case`} control={control}/>
                </>
            case "@replace":
                return <>
                    <div className="d-flex align-items-center gap-1">
                        <span>{t("replace")}</span>
                        <input type="text" className="form-control"
                               placeholder={`${t('common:value')}`} {...register("searchValue")}/>
                    </div>
                    <div className="d-flex align-items-center gap-1">
                        <span className="text-capitalize">{t("common:by")}</span>
                        <input type="text" className="form-control"
                               placeholder={`${t('common:value')}`} {...register("replaceValue")}/>
                    </div>
                </>
            default:
                return null
        }
    }

    const handleShowColumnButton = () => {
        const operation = watch("operation")
        switch (operation) {
            case "@concat":
                return true
            default:
                return fields.length < 1
        }
    }

    React.useEffect(() => {
        const subscription = watch((form) => {
                switch (form.operation) {
                    case "@concat":
                        if (form.columns && form.columns!?.length > 0) {
                            onChange(`${form.operation} ${form.columns.map(it => it?.name).join(" ")} ${form.separator}`)
                        }
                        break;
                    case "@case":
                        if (form.columns && form.columns!?.length > 0) {
                            onChange(`${form.operation} ${form.columns[0]?.name} ${form.case}`)
                        }
                        break;

                    case "@replace":
                        if (form.columns && form.columns!?.length > 0) {
                            onChange(`${form.operation} ${form.columns[0]?.name} '${form.searchValue}' '${form.replaceValue}'`)
                        }
                        break;
                    default:
                        break;
                }
            }
        )
        return () => subscription.unsubscribe()
    }, [watch])

    React.useEffect(() => {

        if (value) {
            const form = getValues()
            if (form.columns.length === 0) {
                const dataSplit = value.split(" ")
                const operation = dataSplit[0]
                setValue("operation", operation ?? "")
                switch (operation) {
                    case "@concat":
                        setValue("separator", dataSplit[dataSplit.length - 1] ?? "' '")
                        setValue("columns", dataSplit.length > 0 ? dataSplit.slice(1, dataSplit.length - 1).map(it => ({name: it})) ?? [] : [])
                        break;
                    case "@case":
                        setValue("case", dataSplit[2] as caseText)
                        setValue("columns", [{name: dataSplit[1] ?? ""}])
                        break;
                    case "@replace":
                        setValue("columns", [{name: dataSplit[1] ?? ""}])
                        setValue("searchValue", dataSplit[2]?.replaceAll("'", "") ?? "")
                        setValue("replaceValue", dataSplit[3]?.replaceAll("'", "") ?? "")
                        break;
                    default:
                        break;
                }
            }
        }

    }, [value]);


    return (
        <div className="d-flex flex-wrap align-items-center gap-2">
            <Controller render={({field}) => {
                return <ActionDropdownColumns columns={[]} color={color}
                                              label={operations.find(op => op.value === watch("operation"))?.label ?? ""}
                                              onChange={() => {
                                              }}>
                    <ColumnsActionsWrapperComponent>
                        {search => {
                            return <>
                                {operations.filter(column => column.value.toLowerCase().includes(search.toLowerCase())).map((column, indexColumn) => {
                                    return <DropdownItem onClick={() => {
                                        field.onChange(column.value)
                                        replace([])
                                    }} key={column + "_" + indexColumn}>
                                        {column.label}
                                    </DropdownItem>
                                })}

                                {search ?
                                    <SearchSuggestionOption search={search} onChange={() => {
                                    }} toggle={() => {
                                        field.onChange(`'${search}'`)
                                        replace([])
                                    }}/> : operations.length === 0 ?
                                        <DropdownItem disabled>{t("common:empty")}</DropdownItem> : null
                                }
                            </>
                        }}
                    </ColumnsActionsWrapperComponent>
                </ActionDropdownColumns>
            }} name={`operation`} control={control}/>

            {fields.map((fieldArray, index) => {
                return <div key={fieldArray.id}>
                    <Controller render={({field}) => {
                        return <ActionDropdownColumns columns={[]} color={color}
                                                      showCaret={false}
                                                      label={<Display control={control} remove={remove} index={index}
                                                                      t={t}/>}
                                                      onChange={() => {
                                                      }}>

                            <ColumnsActionsWrapperComponent>
                                {search => {
                                    return <>
                                        {columns.filter(column => column.value.toLowerCase().includes(search.toLowerCase())).map((column, indexColumn) => {
                                            return <DropdownItem onClick={() => {
                                                field.onChange(column.value)
                                            }} key={column + "_" + indexColumn}>
                                                {column.label}
                                            </DropdownItem>
                                        })}

                                        {search ?
                                            <SearchSuggestionOption search={search} onChange={() => {
                                            }} toggle={() => {
                                                field.onChange(`'${search}'`)
                                            }}/> : columns.length === 0 ?
                                                <DropdownItem disabled>{t("common:empty")}</DropdownItem> : null
                                        }
                                    </>
                                }}
                            </ColumnsActionsWrapperComponent>
                        </ActionDropdownColumns>
                    }} name={`columns.${index}.name`} control={control}/>
                </div>
            })}

            {handleShowColumnButton() && <ActionButton className="btn" color={color} onClick={() => append({name: ""})}>
                <FontAwesomeIcon icon={faPlusCircle}/>
                {t("common:add")} {t("common:column")}
            </ActionButton>}

            {handleComplementFields()}

        </div>
    );
};

export default TransformStringCalculator