import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/Reducers";
import { DataProviderStates } from "../../store/DataProvider/Types";
import SpinnerComponent from "../Shared/SpinnerComponent";
import { Button, Col, FormGroup, Label, Row } from "reactstrap";
import {
  faCheck,
  faPencil,
  faPlus,
  faSave,
  faSpinner,
  faTimes,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomTableComponent from "../Shared/CustomTableComponent/CustomTableComponent";
import { actionsDataProvider } from "../../store/DataProvider/Slice";
import { DataProvider, Option, OptionData } from "../../types/DataProvider";
import useIgnoreFirstEffect from "../../hooks/useIgnoreFirstEffect";
import { ProviderType } from "../../types/ProviderType";
import { actionsModal, ModalTypes } from "../../store/Modal/Slice";
import EditString from "../Shared/EditString";
import useTranslate from "../../hooks/useTranslate";
// @ts-ignore
import locale from "react-json-editor-ajrm/locale/en";
import QueryComponent, { Query } from "../Shared/QueryComponent/QueryComponent";
import { routerActions } from "../../store/RouterReducer";
import TabContents from "../Shared/TabContents";
import { Switch } from "antd";
import { IsJsonString } from "../../utils";

const initialNewOpt = {
  label: "",
  value: "",
};

export const parseQueryString = (query: string) => {
  if (IsJsonString(query)) {
    let dp = JSON.parse(query || "{}") as Query | any;

    dp.where = dp.WHERE ?? dp.where ?? [];
    dp.row_model = dp.ROW_MODEL ?? dp.row_model;
    dp.fetch = dp.fetch ?? dp.FETCH;

    if (!dp.row_model) {
      dp = undefined;
    }

    return dp;
  }

  return undefined;
};

export function getQuery(query: string) {
  if (IsJsonString(query)) {
    return parseQueryString(query);
  } else {
    if (query?.includes("${")) {
      const newQuery = checkAndImproveQuery(query);
      if (IsJsonString(newQuery)) {
        return parseQueryString(newQuery);
      }
    }
  }

  return undefined;

  // console.log("dep", dp);
}

function checkAndImproveQuery(jsonString: string): string {
  // Corregir el error en el JSON original
  const json = jsonString.replace(/\${([^}]+)}/g, '"${$1}"');

  // Transformar el JSON corregido
  const jsonObject = JSON.parse(json);
  return JSON.stringify(jsonObject, null, 2);
}

const DetailComponent = () => {
  const dispatch = useDispatch();
  const { t } = useTranslate("common");
  const { dataProvider, state } = useSelector(
    (state: RootState) => state.DataProviderReducer,
  );
  const [newOpt, setNew] = useState(false);
  const [opt, setOpt] = useState<Option>(initialNewOpt);

  //Save new option
  function onSaveOpt(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const option = {
      label: opt.label.trim(),
      value: opt.value.trim(),
    };

    if (opt.id) {
      dispatch(
        actionsDataProvider.deleteOptionFromDataProviderById({
          optionId: opt.id,
          providerId: dataProvider?.id || 0,
          providerType: dataProvider?.provider_type || "",
        }),
      );
    }

    dispatch(
      actionsDataProvider.addOptionToProvider({
        option,
        providerId: dataProvider?.id || 0,
      }),
    );
    setNew(false);
    setOpt(initialNewOpt);
  }

  useEffect(() => {
    if (dataProvider) {
      dispatch(routerActions.changeActive(dataProvider.name));
    }
  }, [dispatch, dataProvider]);

  useIgnoreFirstEffect(() => {
    if (state === DataProviderStates.OPTION_RESOLVED) {
      setNew(false);
    }
  }, [state]);

  useEffect(() => {
    if (state === DataProviderStates.OPTION_DELETED) {
      dispatch(actionsModal.closeModal({ type: ModalTypes.CONFIRM }));
    }
  }, [state]);

  useEffect(() => {
    if (!newOpt) {
      setOpt(initialNewOpt);
    }
  }, [newOpt]);

  function onChange(e: ChangeEvent<HTMLInputElement>) {
    setOpt({ ...opt, [e.target.name]: e.target.value });
  }

  const onUpdate = (data: OptionData) => {
    setNew((e) => !e);
    setOpt({
      label: data.label,
      value: data.value,
      id: data.id,
    });
  };

  function onDelete(data: OptionData) {
    function onConfirm() {
      dispatch(
        actionsDataProvider.deleteOptionFromDataProviderById({
          optionId: data.id,
          providerId: dataProvider?.id || 0,
          providerType: dataProvider?.provider_type || "",
        }),
      );
    }

    dispatch(
      actionsModal.openModal({
        type: ModalTypes.CONFIRM,
        onConfirm,
        message: (
          <p>
            ¿{`Estas seguro de eliminar la opción `}
            <b>{data.label}</b>?
          </p>
        ),
        title: (
          <span>
            <FontAwesomeIcon className="me-2" icon={faTrash} />
            {t("delete")}
          </span>
        ),
        state: DataProviderStates.OPTION_PENDING,
      }),
    );
  }

  function onUpdateData(data: DataProvider) {
    dispatch(actionsDataProvider.updateDataProvider(data));
  }

  const savingOption = state === DataProviderStates.OPTION_PENDING;
  const loadingProvider = state === DataProviderStates.PROVIDER_PENDING;

  return (
    <>
      {/*Loading component*/}
      {state === DataProviderStates.DATA_PENDING ? (
        <div className="d-flex justify-content-center">
          <SpinnerComponent />
        </div>
      ) : (
        dataProvider && (
          //Provider [providerType]
          <>
            <EditString
              loading={loadingProvider}
              title={dataProvider.name}
              onEdit={(name: string) => onUpdateData({ ...dataProvider, name })}
            />
            <Row>
              {(dataProvider.provider_type === ProviderType.SBX ||
                dataProvider.provider_type ===
                  ProviderType.SBX_SEQUENTIAL_QUERY) && (
                <Col md={12}>
                  <div className="text-end mb-3">
                    <button
                      disabled={loadingProvider}
                      onClick={() => onUpdateData(dataProvider)}
                      className="btn btn-primary btn-sm"
                    >
                      <FontAwesomeIcon
                        spin={loadingProvider}
                        icon={loadingProvider ? faSpinner : faCheck}
                      />
                      {" " + t("save")}
                    </button>
                  </div>
                  <div>
                    <span className="fw-bold">Select SBX provider type: </span>
                    <Switch
                      checked={
                        dataProvider.provider_type ===
                        ProviderType.SBX_SEQUENTIAL_QUERY
                      }
                      checkedChildren={ProviderType.SBX_SEQUENTIAL_QUERY}
                      unCheckedChildren={ProviderType.SBX}
                      onChange={(checked) => {
                        onUpdateData({
                          ...dataProvider,
                          provider_type: checked
                            ? ProviderType.SBX_SEQUENTIAL_QUERY
                            : ProviderType.SBX,
                        });
                      }}
                    />
                  </div>
                  <TabContents
                    tabs={[
                      {
                        label: t("principal_query"),
                        component: (
                          <QueryComponent
                            showFetch
                            query={getQuery(dataProvider.query)}
                            getQuery={(q) => {
                              dispatch(
                                actionsDataProvider.setDataProvider({
                                  ...dataProvider,
                                  query: JSON.stringify(
                                    typeof q === "function"
                                      ? q(dataProvider.query)
                                      : q,
                                  ),
                                }),
                              );
                            }}
                          />
                        ),
                      },
                      {
                        label: t("default_query"),
                        component: (
                          <QueryComponent
                            showFetch
                            query={getQuery(dataProvider.default_query)}
                            getQuery={(q) => {

                              dispatch(
                                actionsDataProvider.setDataProvider({
                                  ...dataProvider,
                                  default_query: JSON.stringify(
                                    (typeof q === "function"
                                      ? q(dataProvider.default_query)
                                      : q) as Query,
                                  ),
                                }),
                              );
                            }}
                          />
                        ),
                      },
                    ]}
                  />
                </Col>
              )}
              {dataProvider.provider_type === ProviderType.DATABASE && (
                <Col md={12}>
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <h5>
                      <b>Options:</b>
                    </h5>
                    <Button
                      disabled={savingOption}
                      onClick={() => setNew((e) => !e)}
                      color={newOpt ? "light" : "primary"}
                      size="sm"
                    >
                      <FontAwesomeIcon icon={newOpt ? faTimes : faPlus} />
                      {" " + (newOpt ? t("cancel") : t("add"))}
                    </Button>
                  </div>
                  {/*To add new option to data provider*/}
                  {newOpt && (
                    <form onSubmit={onSaveOpt}>
                      <Row>
                        <Col md={5}>
                          <FormGroup>
                            <Label>Etiqueta:</Label>
                            <input
                              value={opt.label}
                              onChange={onChange}
                              disabled={savingOption}
                              required
                              className="form-control"
                              type="text"
                              name="label"
                              placeholder="ej: M"
                            />
                          </FormGroup>
                        </Col>
                        <Col md={5}>
                          <FormGroup>
                            <Label>Valor:</Label>
                            <input
                              value={opt.value}
                              onChange={onChange}
                              disabled={savingOption}
                              required
                              className="form-control form-control"
                              type="text"
                              name="value"
                              placeholder="ej: Masculino"
                            />
                          </FormGroup>
                        </Col>
                        <Col md={2}>
                          <FormGroup>
                            <Label>&nbsp;</Label>
                            <br />
                            <button className="btn btn-primary btn-sm mt-2">
                              <FontAwesomeIcon icon={faSave} />
                              {" " + t("save")}
                            </button>
                          </FormGroup>
                        </Col>
                      </Row>
                    </form>
                  )}
                  {/*Table from current options*/}
                  <CustomTableComponent
                    data={dataProvider.options}
                    actions={[
                      {
                        type: "info",
                        label: <FontAwesomeIcon icon={faPencil} />,
                        title: t("update"),
                        onAction: onUpdate,
                      },
                      {
                        type: "danger",
                        label: <FontAwesomeIcon icon={faTrash} />,
                        title: t("delete"),
                        onAction: onDelete,
                      },
                    ]}
                    columns={[
                      { name: "label", header: "Etiqueta", type: "String" },
                      { name: "value", header: "Valor", type: "String" },
                    ]}
                  />
                </Col>
              )}
            </Row>
          </>
        )
      )}
    </>
  );
};

export default DetailComponent;
