import * as React from 'react';
import {useContext, useState} from 'react';
import DropdownActionComponent from "../DropdownActionComponent";
import {Controller, UseControllerProps, UseFieldArrayRemove, UseFieldArrayUpdate, UseFormWatch} from "react-hook-form";
import {AnalyticQueryAction} from "../../../../types/Analytic";
import {DropdownItem} from "reactstrap";
import useTranslate from "../../../../hooks/useTranslate";
import {ActionButton, ReportContext} from "../../NewReportGeneratorComponent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faTimes, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import ButtonComponent from "../../../Shared/ButtonComponent";
import {getColumnName} from "../utils";
import ColumnsActionsWrapperComponent from "../../ColumnsActionsWrapperComponent";
import SearchSuggestionOption from "../../SearchSuggestionOption";

type Props = {
    index: number
    watch: UseFormWatch<{
        columns: {
            column: string
            new_value: string
        }[]
    }>
    update: UseFieldArrayUpdate<{
        columns: {
            column: string
            new_value: string
        }[]
    }, "columns">
    control: UseControllerProps<any>["control"]
    queryAction: AnalyticQueryAction,
    indexAction: number
    remove: UseFieldArrayRemove,
    color?: string
};
const RenameMenuActionComponent = ({
                                       index,
                                       queryAction,
                                       indexAction,
                                       watch,
                                       control,
                                       update, color,
                                       remove
                                   }: Props) => {
    const {getActionColumns, state} = useContext(ReportContext)

    const [dropdownOpen, setDropdownOpen] = useState(false)

    const toggle = () => setDropdownOpen(prevState => !prevState)

    const {t} = useTranslate("common")

    const getHeaderName = () => {
        const rename = watch(`columns.${index}`)

        return <span className="d-flex align-items-center gap-2">
           {rename.column ? `${rename?.column} = '${rename?.new_value}'` : `${t("new")} ${t("value")} ` + (index + 1)}
            {rename && <FontAwesomeIcon icon={faTimesCircle} className="pointer" onClick={() => {
                remove(index)
            }}/>}
        </span>

    }

    React.useEffect(() => {
        const subscription = watch((obj) => {
            if (obj["columns"] && obj["columns"].length > 0 && typeof obj["columns"][index]!?.new_value === undefined || typeof obj["columns"]![index]!?.new_value === null) {
                setDropdownOpen(true)
            }
        });
        return () => subscription.unsubscribe();
    }, [watch, index]);

    const renderToggleMenu = (defaultValue: {
        column: string
        new_value: string
    }) => {

        if (!defaultValue.column) {
            const columns = watch("columns")

            return <>
                <DropdownItem header>{t("columns")}</DropdownItem>
                <ColumnsActionsWrapperComponent>
                    {search => {
                        return <>
                            {getActionColumns(queryAction, indexAction).filter(column => !columns.some(nColumn => nColumn.column === column.value) && column.label.toLowerCase().includes(search.toLowerCase())).map(column => {
                                return <Controller key={column.value} render={({field}) => {
                                    return <DropdownItem onClick={() => {
                                        field.onChange(column.value)
                                    }}>
                                        {
                                            getColumnName({
                                                name: column.label,
                                                index: indexAction,
                                                columnsType: state.columnsType
                                            })
                                        }
                                    </DropdownItem>
                                }} control={control} name={`columns.${index}.column`}/>
                            })}
                            {search && <Controller
                                key={"search"} control={control}
                                render={({field}) => {
                                    return <SearchSuggestionOption search={search} onChange={field.onChange} toggle={toggle} />
                                }} name={`columns.${index}.column`}
                            />}
                        </>
                    }}
                </ColumnsActionsWrapperComponent>

            </>
        }

        return <div className="d-flex flex-column px-2 gap-2">
            <div className="d-flex align-items-center border-bottom py-2 gap-2">

                <FontAwesomeIcon icon={faArrowLeft} className="pointer" onClick={() => {
                    update(index, {...defaultValue, column: ""})
                }}/>

                <span
                    className="text-center text-primary w-100 text-capitalize">{defaultValue.column}</span>

                <ActionButton className="btn" color={'transparent'} onClick={() => {
                    toggle()
                }}>
                    <FontAwesomeIcon icon={faTimes} className="pointer" color={'#141414'}/>
                </ActionButton>
            </div>

            <Controller render={
                ({field: {onChange, value}}) => {
                    return <input type="text" placeholder={defaultValue.column || ""} className="form-control"
                                  value={value || ""}
                                  onChange={evt => onChange(evt.currentTarget.value)}/>
                }
            } name={`columns.${index}.new_value`} control={control}/>


            <div className="border-top pt-2 justify-content-end d-flex">
                <ButtonComponent color={color} label={t("save")} onClick={() => {
                    toggle()
                }}/>
            </div>
        </div>


    }

    return (
        <div>
            <DropdownActionComponent header={getHeaderName()} basicConfigMenu dropdownStyle={{backgroundColor: color, borderColor: color}}
                                     isOpen={dropdownOpen}
                                     toggle={toggle}>
                {renderToggleMenu(watch(`columns.${index}`))}
            </DropdownActionComponent>

        </div>
    );
};

export default RenameMenuActionComponent