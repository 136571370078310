import * as React from 'react';
import {createContext, Dispatch, SetStateAction, useEffect, useMemo, useReducer, useRef, useState} from 'react';
import {
    faCalendar,
    faChalkboard,
    faChartLine,
    faCodeBranch,
    faDatabase,
    faDiagnoses,
    faExchangeAlt,
    faFilter,
    faFont,
    faHandHoldingUsd,
    faHashtag,
    faLayerGroup,
    faMousePointer,
    faPenAlt,
    faPowerOff,
    faQuestion,
    faSave,
    faSitemap,
    faSort,
    faSuperscript,
    faTable,
    faUser
} from "@fortawesome/free-solid-svg-icons";
import reducer, {actionsReport, baseQuery, initialState, ReportState} from "./Slice";
import {State} from "../../types/State";
import ReportDataComponent from "./ReportDataComponent/ReportDataComponent";
import {ModelsResponse, SbxModelField} from "../../types/Sbx";
import useAsyncEffect from "../../hooks/useAsyncEffect";
import {getAnalyticJsonColumns} from "../../services/backend/AnalyticsService";
import useTranslate from "../../hooks/useTranslate";
import {ActionType, AnalyticQuery, AnalyticQueryAction, BaseAnalyticQuery, Report} from "../../types/Analytic";
import {debounceTime, IsJsonString, toast, uuidV4} from "../../utils";
import NewActionsReportGeneratorComponent
    from "./NewActionsReportGeneratorComponent/NewActionsReportGeneratorComponent";
import styled from "styled-components";
import {StringOption} from "../../types/Select";
import {fetchModelByReferences, insertSbxModelService, updateModelRow} from "../../services/backend/SbxService";
import NewReportMenuComponent from "./NewReportMenuComponent";
import NewReportManageDataComponent from "./NewReportManageDataComponent";
import {getAnalyticQueryFlat, getQueryData} from "../../utils/analyticsUtils";
import ReportTimeDataComponent from "./ReportTimeDataComponent";
import {useSelector} from "react-redux";
import {authReducer} from "../../store/Selectors";
import NewActionListReportGenerator from "./NewActionListReportGenerator";
import {sourceBase} from "../ReportGeneratorComponent/ReportGeneratorComponent";


type ContextProps = {
    dispatch: React.Dispatch<any>
    state: typeof initialState,
    query: AnalyticQuery,
    mainQuery?: AnalyticQuery,
    getData: (params: {
        limit?: number | null,
        query: BaseAnalyticQuery,
        setLoading: (state: State) => void,
        setResults: (results: any[]) => void
    }) => void,
    setQueryAction: (action: AnalyticQueryAction, index: number, nQuery?: AnalyticQuery) => void,
    setQuery: Dispatch<SetStateAction<AnalyticQuery>>
    saveQuery?: (query: AnalyticQuery) => void
    // getColumns: (query: AnalyticQuery | BaseAnalyticQuery) => (ColumnResponse | undefined)
    getColumns: (query: AnalyticQuery | BaseAnalyticQuery) => Promise<ColumnResponse | undefined>
    getActionColumns: (action: AnalyticQueryAction, index: number) => StringOption[]
    getAndSetAllColumns?: ({index, pQuery}: {
        index?: number,
        pQuery?: AnalyticQuery
    }) => Promise<void>
    report?: Report
    getLoadingAndDisabledAction: ({action, index}: { action?: AnalyticQueryAction, index?: number }) => boolean
};

const defaultResponse = {history: [], items: [], types: {data: {}, history: []}}

export const ReportContext = createContext<ContextProps>({
    state: initialState,
    query: {
        source: {...baseQuery, temporal_id: uuidV4(), filters: []},
        actions: [],
        preview: true
    },
    dispatch: (action: any) => {
    },
    setQuery: (query) => {
    },
    setQueryAction: (action, index) => {
    },
    getData: () => {
    },
    getAndSetAllColumns: async () => {
    },
    getColumns: async () => (defaultResponse),
    getLoadingAndDisabledAction: ({action, index}) => false,
    getActionColumns: (action: AnalyticQueryAction, index: number) => []
})

export const renderTextByType = (type: Partial<SbxModelField>) => {
    return ({
        [SbxModelField.INT]: "enter_number",
        [SbxModelField.FLOAT]: "enter_number",
        [SbxModelField.STRING]: "enter_text",
        [SbxModelField.TEXT]: "enter_text",
    } as { [key in Partial<SbxModelField>]: string })[type]
}

export const renderType = (type: Partial<SbxModelField>) => {
    return ({
        [SbxModelField.INT]: "number",
        [SbxModelField.FLOAT]: "number",
        [SbxModelField.STRING]: "text",
        [SbxModelField.TEXT]: "text",
    } as { [key in Partial<SbxModelField>]: string })[type]
}

export const renderIconByType = () => {
    return {
        [SbxModelField.INT]: faHashtag,
        "NUMBER": faHashtag,
        [SbxModelField.FLOAT]: faHashtag,
        [SbxModelField.REFERENCE]: faDatabase,
        [SbxModelField.STRING]: faFont,
        [SbxModelField.TEXT]: faFont,
        [SbxModelField.DATE]: faCalendar,
        [SbxModelField.BOOLEAN]: faQuestion
    }
}

export type ColumnResponse = {
    history: string[][],
    items: string[],
    types: { data: { [key: string]: Partial<SbxModelField> }, history: { [key: string]: Partial<SbxModelField> }[] }
}

const cacheQuery: { [query: string]: ColumnResponse } = {}

type Props = {
    saveQuery?: (query: AnalyticQuery) => void
    reportQuery?: AnalyticQuery
    mainReportQuery?: AnalyticQuery
    report?: Report
    isMergeAction?: boolean
}

export const ActionButton = styled.button<{ color: string, textColor?: string }>`
    color: ${props => props.textColor ? props.textColor : "white"};
    background: ${props => props.color};
    border-color: ${props => props.color};
    display: flex;
    align-items: center;
    gap: 4px;

    &:hover {
        color: #FFFFFF;
    }
`

const recalculateColumnsActions = ["select", "group_by", "rename", "pivot", "merge",
    "binary_classifier", "segmentation", "monetary_segmentation", "forecast"]

const NewReportGeneratorComponent = ({saveQuery, reportQuery, report, isMergeAction, mainReportQuery}: Props) => {
    let [state, dispatch] = useReducer(reducer, {
        ...initialState,
        isMergeAction: isMergeAction ?? false,
        mainReportQuery
    })
    const {user} = useSelector(authReducer)
    const prevModelName = useRef("")
    const [query, setQuery] = useState<AnalyticQuery>({
        source: {...baseQuery, temporal_id: uuidV4(), filters: []},
        actions: [],
        preview: true
    })
    const prevQueryState = useRef<string>("");
    let onMount = useRef(false)
    const {t} = useTranslate("common")
    const [temporalKey, setTemporalKey] = useState("")

    React.useEffect(() => {


        if (reportQuery && query.source.with.length === 0 && !onMount.current) {

            setQuery(reportQuery)

            dispatch(actionsReport.setBaseQuery({
                ...state.baseQuery,
                source: {...reportQuery.source}
            }))

            onMount.current = true
        }


    }, [reportQuery, query]);

    const basicActionsList = useMemo(() => [
        {
            label: t('report:select_columns'),
            value: 'select',
            icon: faMousePointer,
            color: "#55c2da",
            textColor: "white"
        },
        {label: t('report:filter'), value: 'filter', icon: faFilter, color: "#4681f4", textColor: "white"},
        {label: t('report:sort'), value: 'sort', icon: faSort, color: "#5783db", textColor: "white"},
        {label: t('report:limit'), value: 'limit', icon: faSuperscript, color: "#33b249", textColor: "white"},
        {label: t('report:rename'), value: 'rename', icon: faPenAlt, color: "#dd7973", textColor: "white"},
        {
            label: t('report:date_formatter'),
            value: 'date_formatter',
            icon: faCalendar,
            color: "#80669d",
            textColor: "white"
        },
        {label: t('report:group_by'), value: 'group_by', icon: faLayerGroup, color: "#5adbb5", textColor: "white"},
        {label: t('save'), value: 'save', icon: faSave, color: "#154360"},
    ], [])

    const mlActionsList = useMemo(() => [
        {
            label: t('report:binary_classifier'),
            value: 'binary_classifier',
            icon: faSitemap,
            color: "#FFA07A",
            textColor: "white"
        },
        {label: t('report:forecast'), value: 'forecast', icon: faChartLine, color: "#8E44AD", textColor: "white"},
        {
            label: t('report:monetary_segmentation'),
            value: 'monetary_segmentation',
            icon: faHandHoldingUsd,
            color: "#5B2C6F",
            textColor: "white"
        },
        {
            label: t('report:segmentation'),
            value: 'segmentation',
            icon: faCodeBranch,
            color: "#B7950B",
            textColor: "white"
        },
        {label: `SBX AI - SmartML`, value: 'async_ml', icon: faChalkboard, color: "#2C3E50", textColor: "white"},
    ], [])

    const advanceActionsList = useMemo(() => [
        {
            label: t('report:data-transformation'),
            value: 'data_transform',
            icon: faExchangeAlt,
            color: "#5dbea3",
            textColor: "white"
        },
        {label: t('report:default_values'), value: 'default_values', icon: faPowerOff, color: "#BA4A00"},
        {label: t('report:merge'), value: 'merge', icon: faDatabase, color: "#a881af", textColor: "white"},
        {label: t('report:pivot'), value: 'pivot', icon: faTable, color: "#ffbd03"},
        {label: t("report:drill_down"), value: 'drill_down', icon: faTable, color: "#ED0800"},
        {
            label: t('report:date_analysis'),
            value: 'date_analysis',
            icon: faCalendar,
            color: " #809bce ",
            textColor: "white"
        },

        {label: t("report:custom"), value: 'custom', icon: faUser, color: "#000000"},
        {label: t('report:behavior_validator'), value: 'behavior_validator', icon: faDiagnoses, color: "#C15B54"},
        // {label: t("report:drill_down"), value: 'drill_down', icon: faTable, color: "#BDB2FF"},
        // {label: t("report:drill_down"), value: 'drill_down', icon: faTable, color: "#FAD1FA"},
        // {label: t("report:drill_down"), value: 'drill_down', icon: faTable, color: "#FEC868"},

    ], [])

    const getColumns = async (query: AnalyticQuery | BaseAnalyticQuery, index?: number) => {
        if (!query.source.with) return

        if (query.actions.length > 0){
            if (typeof index === 'undefined') {
                dispatch(actionsReport.setState({
                    addState: ReportState.PENDING_FETCHING_COLUMNS
                }))
            }

            const response = await getAnalyticJsonColumns(getAnalyticQueryFlat(query as AnalyticQuery))
            if (response?.success && (response.history || response?.items)) {
                if (typeof index === 'undefined') {
                    dispatch(actionsReport.setState({
                        removeState: ReportState.PENDING_FETCHING_COLUMNS
                    }))
                }

                cacheQuery[JSON.stringify(query)] = response as any as ColumnResponse
                return response as any as ColumnResponse
            } else {
                dispatch(actionsReport.setState({
                    removeState: ReportState.PENDING_FETCHING_COLUMNS
                }))

                if (response?.message) {
                    toast({
                        message: `Error: ${response.message}`,
                        type: "error"
                    })
                }

                return state.columns && state.columns.length > 0 ? {
                    history: [...state.columns, state.columns[state.columns.length - 1]], items: [],
                    types: {
                        data: {},
                        history: state.columnsType
                    }
                } : defaultResponse as ColumnResponse
            }
        }


    }

    const customDivRef = useRef<HTMLDivElement | null>(null);
    const [isClickedOutside, setIsClickedOutside] = useState(false);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (customDivRef.current && !customDivRef.current.contains(event.target as Node)) {
                setIsClickedOutside(true);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const getAndSetAllColumns = async ({index, pQuery}: { index?: number, pQuery?: AnalyticQuery }) => {

        const nQuery = {...(pQuery ?? query)}

        if (index !== undefined) {
            nQuery.actions = [...nQuery.actions]
            nQuery.actions = nQuery.actions.slice(0, index)
        }

        const columns: ColumnResponse | undefined = await getColumns(nQuery, index)

        if (columns && columns.history) {
            if (index !== undefined) {
                dispatch(actionsReport.setColumnsByIndex({
                    index,
                    columns: columns.history,
                    types: columns.types.history
                }))
            } else {
                dispatch(actionsReport.setColumns(columns.history))
                dispatch(actionsReport.setColumnsType(columns.types.history))
            }
        }
    }

    const createTemporalReport = async (newQueryReport: AnalyticQuery) => {

        if (!report && newQueryReport.source.with && !isMergeAction) {
            const newQuery = {
                name: "temporary_report_" + (new Date().getTime()),
                query: JSON.stringify(getAnalyticQueryFlat({...newQueryReport})),
                active: true,
                truncate: query.truncate ? JSON.stringify(query.truncate) : null,
                created_by: user.id
            }


            const response = temporalKey ? await updateModelRow([{
                ...newQuery,
                _KEY: temporalKey
            }], "sbx_crm_report") : await insertSbxModelService({
                row_model: "sbx_crm_report",
                rows: [newQuery]
            });


            if (response?.success) {
                if (!temporalKey && response.keys) {
                    setTemporalKey(response.keys[0])
                }
            }
        }
    }

    useAsyncEffect(async () => {

        if (JSON.stringify(query.actions) !== prevQueryState.current && (!prevQueryState.current || (prevQueryState.current && IsJsonString(prevQueryState.current) && query.actions.length !== JSON.parse(prevQueryState.current).length))) {
            await getAndSetAllColumns({})
            prevQueryState.current = JSON.stringify(query.actions)
        }
        debounceTime(createTemporalReport, query, 1000)

    }, [query]);

    useAsyncEffect(async () => {
        const columns: ColumnResponse | undefined = await getColumns(state.baseQuery)

        if (columns) {
            if (columns.items) {
                dispatch(actionsReport.setBaseColumns(columns.items))
            }

            if (columns.types.history) {
                dispatch(actionsReport.setColumnsType(columns.types.history))
            }
        }

    }, [state.baseQuery]);

    useAsyncEffect(async () => {
        if (state.model && prevModelName.current !== state.model.name) {
            const fetchOptions = state.model.properties.reduce((arr: { id: number, name: string }[], item) => {
                if (item.type === SbxModelField.REFERENCE) {
                    arr.push({name: item.name, id: item.reference_type})
                }
                return arr;
            }, [])

            if (fetchOptions.length > 0) {
                dispatch(actionsReport.setState({
                    addState: ReportState.PENDING_FETCHING_MODELS
                }))
                const ids = fetchOptions.map(item => item.id)
                const deepFetch = await fetchModelByReferences(ids)

                const options = fetchOptions.reduce((arr: string[], item) => {
                    arr.push(item.name)

                    if (deepFetch[item.id]) {
                        deepFetch[item.id].properties.forEach(prop => {
                            if (prop.type === SbxModelField.REFERENCE) {
                                arr.push(`${item.name}.${prop.name}`)
                            }
                        })
                    }
                    return arr;
                }, [])

                dispatch(actionsReport.setDeepFetch(Object.keys(deepFetch).reduce((obj: {
                    [key: string]: ModelsResponse
                }, key) => {
                    const alias = fetchOptions.filter(item => item.id === deepFetch[key].id)?.map(item => item.name) ?? []

                    obj[deepFetch[key].name] = {
                        ...deepFetch[key],
                        alias
                    }
                    return obj
                }, {})))
                dispatch(actionsReport.setFetchOptions(options))
                dispatch(actionsReport.setState({
                    removeState: ReportState.PENDING_FETCHING_MODELS
                }))
            }

            prevModelName.current = state.model.name
        }
    }, [state.model])

    React.useEffect(() => {
        if (isClickedOutside) {
            dispatch(actionsReport.setShowResults(false))
            dispatch(actionsReport.setResults([]))
            setIsClickedOutside(false)
        }
    }, [isClickedOutside]);

    const getData = async ({limit = 10, query, setLoading, setResults}: {
        limit?: number | null,
        query: BaseAnalyticQuery,
        setLoading: (state: State) => void,
        setResults: (results: any[]) => void
    }) => {
        setLoading(State.PENDING)
        const items = await getQueryData({limit, analyticQuery: query})
        if (items?.length > 0) {
            setResults(items)
            setLoading(State.RESOLVED)
        } else {
            setLoading(State.REJECTED)
        }
    }

    const getActionColumns = (action: AnalyticQueryAction, index: number) => {
        if (state.columns.length === 0) return []

        if (!state.columns[index]) return []

        return state.columns[index].map((item) => ({
            label: item,
            value: item
        })).sort((a, b) => a.label.localeCompare(b.label)) as StringOption[]
    }

    const setQueryAction = async (action: AnalyticQueryAction, index: number) => {

        let nQuery = {...query}

        if (nQuery.actions.length > 0 && nQuery.actions[index]) {

            const subIndex = nQuery.actions[index]?.findIndex(item => item.temporal_id === action.temporal_id)

            if (subIndex !== -1) {
                nQuery = {
                    ...nQuery, actions: [...nQuery.actions].map((itemAction, itemIndex) => {
                        if (itemIndex === index) {
                            return itemAction.map((item, subItemIndex) => {
                                if (subItemIndex === subIndex) {
                                    return action
                                }
                                return item
                            })
                        }

                        return itemAction
                    })
                }
            }

            setQuery(nQuery)


            if ((recalculateColumnsActions.includes(action.type) || (action.subtype && recalculateColumnsActions.includes(action.subtype))) && (nQuery.actions.length - 1 !== index)) {
                dispatch(actionsReport.setAddLoadingActionIndex({index: (index + 1)}))
                await getAndSetAllColumns({pQuery: nQuery})
                dispatch(actionsReport.setRemoveLoadingActionIndex({index: (index + 1)}))
            }
        }
    }

    const getLoadingAndDisabledAction = ({action, index}: { action?: AnalyticQueryAction, index?: number }) => {

        if (typeof index !== 'undefined' && state.loadingActions!.length > 0) {
            return state.state.includes(ReportState.PENDING_FETCHING_COLUMNS) && state.loadingActions!.includes(index)
        }

        return state.state.includes(ReportState.PENDING_FETCHING_COLUMNS)
    }

    const getDefaultAction = (actionType: ActionType, actionIndexSelected?: number) => {

        let baseAction: AnalyticQueryAction = {type: actionType, temporal_id: uuidV4()};

        const action: { [action_type: string]: AnalyticQueryAction } = {
            limit: {
                ...baseAction,
                top: 10
            },
            sort: {
                ...baseAction,
                columns: [],
                ascending: true
            },
            select: {
                ...baseAction,
                columns: []
            },
            rename: {
                ...baseAction,
                renamed_columns: {}
            },
            group_by: {
                ...baseAction,
                agg: {},
                columns: []
            },
            drill_down: {
                ...baseAction,
                type: "group_by",
                subtype: "drill_down",
                agg: {},
                hierarchy: [],
                columns: []
            },
            pivot: {
                ...baseAction,
                agg: {},
                index_columns: [],
                columns: []
            },
            date_analysis: {
                ...baseAction,
                agg: {},
                analysis_by: "year",
                compare_with: "year",
                compare_quantity: 0,
                index_columns: [],
                columns: []
            },
            data_transform: {
                ...baseAction,
                type: "transform",
                subtype: "data_transform",
                filter: "*",
                name: "",
                transformation: ""
            },
            date_formatter: {
                ...baseAction,
                type: "transform",
                filter: "*",
                subtype: "date_formatter",
                name: "",
                transformation: ""
            },
            date_operations: {
                ...baseAction,
                type: "transform",
                subtype: "date_operations",
                filter: "*",
                name: "",
                transformation: ""
            },
            filter: {
                ...baseAction,
                filter: ""
            },
            default_values: {
                ...baseAction,
                columns: {}
            },
            merge: {
                ...baseAction,
                main_column: "",
                index_column: "",
                merge_type: "inner",
                source: sourceBase,
                actions: []
            },
            forecast: {
                ...baseAction,
                type: "ml",
                subtype: "forecast",
                x: "",
                y: "",
                forecast_from: "",
                forecast_to: "",
                seasonal: 12,
                domain: 0
            },
            monetary_segmentation: {
                ...baseAction,
                type: "ml",
                subtype: "monetary_segmentation"
            },
            segmentation: {
                ...baseAction,
                type: "ml",
                subtype: "segmentation",
                columns: [],
                n_segments: 0,
                id: ''

            },
            binary_classifier: {
                ...baseAction,
                type: "ml",
                subtype: "binary_classifier",
                columns: [],
                target: ""
            },
            async_ml: {
                ...baseAction,
                type: "async_ml",
                subtype: "train",
                domain: 0,
                options: {
                    target: "",
                    type: "regression",
                },
                model_name: "",
            },
            save: {
                ...baseAction,
                with: "",
                in: 0
            },
            custom: {
                ...baseAction
            },
            behavior_validator: {
                ...baseAction,
                type: "behavior_validator",
                time_window: ""
            }
        }

        const nQuery = {...query};

        let actions = [...nQuery.actions]

        const newAction = [action[actionType]];

        if (typeof actionIndexSelected === 'number') {
            actions.splice(actionIndexSelected + 1, 0, newAction);
        } else {
            actions = [...nQuery.actions, newAction];
        }

        setQuery({...nQuery, actions})
    };

    return (
        <div className="card d-flex flex-column gap-2 p-3" key={query.source.temporal_id ?? ""}
             style={{minHeight: "10vh", background: "#dcdcdc"}}>

            <ReportContext.Provider
                value={{
                    state,
                    dispatch,
                    saveQuery,
                    getData,
                    getActionColumns,
                    getAndSetAllColumns,
                    setQuery,
                    setQueryAction,
                    query,
                    getLoadingAndDisabledAction,
                    getColumns,
                    report,
                    mainQuery: mainReportQuery
                }}>
                <NewReportMenuComponent/>
                <div className="border card gap-3 ">
                    <ReportDataComponent/>

                    <NewReportManageDataComponent getDefaultAction={getDefaultAction}/>
                </div>


                {state.model &&
                    <>
                        <NewActionsReportGeneratorComponent actions={getAnalyticQueryFlat(query)?.actions ?? []}
                                                            removeAction={index => {
                                                                setQuery({
                                                                    ...query,
                                                                    actions: query.actions.filter((_, i) => i !== index)
                                                                })
                                                            }}
                                                            actionListWrapper={(actionIndexSelected) => {
                                                                return <NewActionListReportGenerator basicActionsList={basicActionsList} getDefaultAction={actionType => getDefaultAction(actionType, actionIndexSelected)}
                                                                                                     advanceActionsList={advanceActionsList}
                                                                                                     mlActionsList={mlActionsList}/>
                                                            }}
                                                            actionList={[...basicActionsList, ...advanceActionsList, ...mlActionsList]}/>

                        <NewActionListReportGenerator basicActionsList={basicActionsList} getDefaultAction={getDefaultAction}
                                                      advanceActionsList={advanceActionsList}
                                                      mlActionsList={mlActionsList}/>
                        <ReportTimeDataComponent/>
                    </>
                }


            </ReportContext.Provider>


        </div>
    );
};

export default NewReportGeneratorComponent