import React, {CSSProperties, useCallback, useMemo} from 'react';
import {useDropzone} from 'react-dropzone';
import useTranslate from '../../../hooks/useTranslate';
import {useDispatch} from 'react-redux';
import {actionsModal, ModalTypes} from '../../../store/Modal/Slice';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faUpload} from '@fortawesome/free-solid-svg-icons';
import {Col} from 'reactstrap';
import {FileTypeIcon} from '../ContentEngine';


const baseStyle: CSSProperties = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

type Props = {
  onSubmit: (files: File[]) => void;
}

const DropzoneComponent = ({onSubmit}: Props) => {

  const dispatch = useDispatch();

  const {t} = useTranslate('common');

  const onDrop = useCallback((acceptedFiles: any) => {

    dispatch(actionsModal.openModal(
      {
        closeModal: true,
        type: ModalTypes.CONFIRM,
        onConfirm: () => onSubmit(acceptedFiles),
        message: <div><p>¿Está usted seguro de subir los archivos seleccionados?</p>
          {/*message: <div><p>¿{t('upload_warning')}?</p>*/}
          <div className="row">
            {acceptedFiles.map((file: File) => {
              return <Col xs={4} className=" text-center mt-2">
                <div
                  className="pointer shadow--hover text-center p-2">
                  <FileTypeIcon name={file.name}/>
                </div>
                <span title={file.name} className="text-blue pointer">{file.name}</span>
              </Col>;
            })}
          </div>
        </div>,
        title: <span><FontAwesomeIcon className="me-2" icon={faUpload}/>Subir archivo </span>
      }));
  }, []);

  const {getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject} = useDropzone({onDrop});

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]);


  return (
    <div className="d-flex flex-column">
      <div {...getRootProps({style})} >
        <input {...getInputProps()} />
        {
          isDragActive ?
            <p>{t('drop_here')}</p> :
            <p>{t('drag_and_drop')}</p>
        }
      </div>
      {/*<div className="row">*/}
      {/*  {files.map(file => {*/}
      {/*    return <Col lg={2} md={3} sm={6} className=" text-center mt-2">*/}
      {/*      <div*/}
      {/*        className="pointer shadow--hover text-center p-2">*/}
      {/*        <FileTypeIcon name={file.name}/>*/}
      {/*      </div>*/}
      {/*      <span title={file.name} className="text-blue pointer">{file.name}</span>*/}
      {/*    </Col>;*/}
      {/*  })}*/}
      {/*</div>*/}
    </div>
  );
};

export default DropzoneComponent;