import React, {useContext} from 'react';
import {uuidV4} from '../../../utils';
import {Source, SourceFrom} from '../../../types/Analytic';
import {mergeTypeOptions, UpdateSource} from './SourceComponent';
import {ReportGeneratorContext} from '../ReportGeneratorComponent';
import SourceMergeColumnsComponent from './SourceMergeColumnsComponent';
import useTranslate from "../../../hooks/useTranslate";
import CreatableSelectComponent from "../../Shared/FieldComponents/CreatableSelectComponent";

type SelectOption = {
  label: string,
  value: string | number
}


const fromOptions: SelectOption[] = [
  {label: 'SBX Data', value: SourceFrom.SBX_DATA},
  {label: 'SBX Workflow', value: SourceFrom.SBX_WORKFLOW},
  {label: 'SBX Crm User', value: SourceFrom.SBX_CRM_USER},
  {label: 'SBX Event', value: SourceFrom.SBX_EVENT}
];


const getLabelOption = (value: string, array: SelectOption[]) => {
  return array.find(item => item.value === value)?.label ?? value;
};


type Props = {
  source: Source
  updateSource: (sources: UpdateSource[]) => void
  isMerge?: boolean
}

const SourceQuerySelectorComponent = ({source, updateSource, isMerge}: Props) => {
  const {dispatchForm, getSourceColumns, reportState: {queriesSource}} = useContext(ReportGeneratorContext);

  const {t} = useTranslate('common');

  return (
    <div className="d-flex flex-wrap gap-1 flex-column flex-sm-row mb-2 ">
      <div className="col-xs-6 col-sm-3 col-md-4 col-xxl-3">
        <label>{t("from")}: </label>
        <CreatableSelectComponent menuPosition={'fixed'} id={uuidV4()} name={''} value={{
          label: getLabelOption(source.from, fromOptions),
          value: source.from
        }} onChange={evt => {
          const sourceUpdateInfo: UpdateSource[] = [
            {sourceKey: 'from', sourceValue: evt.value, sourceId: source.temporal_id},
            {sourceKey: 'sources', sourceValue: null, sourceId: source.temporal_id},
            {sourceKey: 'filters', sourceValue: [], sourceId: source.temporal_id},
            {sourceKey: 'fetch', sourceValue: null, sourceId: source.temporal_id},
            {sourceKey: 'with', sourceValue: '', sourceId: source.temporal_id},
            {sourceKey: 'override_auth', sourceValue: null, sourceId: source.temporal_id},
            {sourceKey: 'merge_type', sourceValue: 'inner', sourceId: source.temporal_id},
            {sourceKey: 'merge_type', sourceValue: 'inner', sourceId: source.temporal_id},
          ];
          const newSources = {...queriesSource}
          if (source.temporal_id && newSources[source.temporal_id]){
            delete newSources[source.temporal_id]
          }
          dispatchForm({name: 'queriesSource', value: newSources});
          if (!isMerge) {
            // dispatchMultiForm([
            //   {name: "copyColumns", value: []},
            //   {name: "columns", value: []},
            // ])
          } else {
            sourceUpdateInfo.push({sourceKey: 'index_column', sourceValue: null, sourceId: source.temporal_id});
            sourceUpdateInfo.push({sourceKey: 'main_column', sourceValue: null, sourceId: source.temporal_id});
          }

          updateSource(sourceUpdateInfo);
        }} options={fromOptions} placeholder={'From'}/>
      </div>

      {source.with && <div className="col-xs-6 col-sm-3 col-md-4 col-xxl-3 ">
        <label>{t("model")}: </label>
        <input type="text" className="form-control pointer" disabled value={source.with || ''}/>
      </div>}

      <div className="col-xs-6 col-sm-3 col-md-4 col-xxl-3">
        <label>{t("report:merge-type")}: </label>
        <CreatableSelectComponent menuPosition={'fixed'}
                                  id={uuidV4()} name={''}
                                  value={source.merge_type ? {
                                    label: source.merge_type,
                                    value: source.merge_type
                                  } : null} onChange={evt => updateSource([{
          sourceKey: 'merge_type',
          sourceValue: evt.value,
          sourceId: source.temporal_id
        }])}
                                  options={mergeTypeOptions}
                                  placeholder={'MergeType'}/>
      </div>


      {isMerge &&
        <SourceMergeColumnsComponent source={source} indexColumns={getSourceColumns(source).filter(column =>  column.value.includes((source.with.includes("@sbx") ? source.with.replace("@sbx", "") : source.with)))} isMergeSource={isMerge}
                                     mainColumns={getSourceColumns(source)} updateSource={updateSource}/>

      }
    </div>
  );
};

export default SourceQuerySelectorComponent;
