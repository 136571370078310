import {get, post, put} from '../../network';
import {Cookies} from 'react-cookie';
import {User} from '../../types/User';
import {findByModel} from "./SbxService";
import {Andor} from "../../types/Sbx";
import {Upser} from '../../pages/admin/users/admin_users';
import {AnyData} from "../../types/AnyData";
import {toast} from "../../utils";

export function me(headers?: any) {
  return get('/api/v2/security/auth/me', {}, headers);
}

export function logout(redirect = true, href = "") {
  const cookies = new Cookies();
  cookies.remove('crm_config_accounts')
  cookies.set('crm_token', '', {expires: new Date(0)});
  cookies.set('permissionsMapper', '', {expires: new Date(0)});
  if (redirect) {
    localStorage.clear();
    window.location.reload();
  } else {
    localStorage.removeItem("crm_token");
  }

}

export function login(params: { username: string, password: string }) {
  return post('/api/v2/security/auth/login', params);
}

export function loginAs(params: { username: string }) {
  return post('/api/v2/security/auth/login_as', params);
}

export function changePassword(params: { username: string, password: string, current_password: string }) {
  return post('/api/v2/security/auth/change_password', params);
}

export function getConfigChangePassword() {
  return get('/api/v2/security/config/password_rules');
}

export function forgotPasswordService(params: { username: string }) {
  return post('/api/v2/security/auth/forgot_password', params);
}

export function updateUserService(params: User | Upser) {
  return put('/api/v2/security/auth/update', params);
}

export async function callbackToUpdateUser(row: AnyData, values: AnyData, type: "update" | "create") {
  switch (type) {

    case "update":
      const data = {
        email: values?.email ?? row?.email,
        first_name: values?.first_name,
        last_name: values?.last_name,
        enabled: Boolean(values.enabled),
        id: row?.user_id
      };

      const toEdit = Object.keys(data).some((key) => key !== "id" && (data as AnyData)[key] !== row[key]);

      if (toEdit) {
        console.log("Editing...");
        const res = await updateUserService(data);
        if (!res.success) {
          toast({
            type: "error",
            message: res?.message || `Error to update user`
          });
        }
      }
      break;

    default:
      break;
  }
}

export function changePasswordWithCodeService(params: { username: string, password: string, code: string }) {
  return post('/api/v2/security/auth/change_password_with_code', params);
}


export async function getCrmUserService(userId: number) {
  return findByModel({
    row_model: "sbx_crm_user",
    where: [
      {
        ANDOR: Andor.AND,
        GROUP: [
          {
            FIELD: "user_id",
            VAL: userId,
            OP: "=",
            ANDOR: Andor.AND
          }
        ]
      }
    ]
  }).then(res => {
    res.item = res.results[0];
    return res;
  }).catch(err => ({success: false}))
}


export function mfaSendCodeService(templateKey: string) {
  return post(`/api/v2/security/auth/mfa/generate_code/${templateKey}`);
}

export function verifyCodeService(code: string) {
  return post(`/api/v2/security/auth/mfa/verify_code/${code}`, {});
}


export function verifyCodeToActivateService(code: string) {
  return post(`/api/v2/security/auth/mfa/verify_code/activate/${code}`, {});
}

export function deactivateMfaService(password: string) {
  return post(`/api/v2/security/auth/mfa/verify_code/deactivate`, {password});
}

export function generateLinkToUserService(email: string) {
  return post('/api/v2/security/auth/link', {email});

}

export function loginWithLinkService(link: string) {
  return post('/api/v2/security/auth/link/verify', {link});

}
