import {AnyData} from "../../types/AnyData";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {FormEvent} from "react";
import {
  faCheckCircle,
  faEnvelopeOpenText,
  faList,
  faObjectGroup,
  faSortNumericDown,
  faTable
} from "@fortawesome/free-solid-svg-icons";
import {IconDefinition, IconProp} from "@fortawesome/fontawesome-svg-core";
import {PropsType} from "../../types/PropsType";

export interface IOption {
  type: string,
  default_value: string[] | number[] | AnyData[] | AnyData | string | number | boolean,
  icon: IconDefinition | IconProp,
  label: string,
}

interface IProps {
  id: string;
  value: { name: string, option?: IOption },
  onChange: (data: { name: string, option?: IOption }) => void;
  submit: (e: FormEvent<HTMLFormElement>, value: { name: string, option?: IOption }) => void;
}


export const optionsProperties: IOption[] = [
  {
    default_value: "",
    icon: faEnvelopeOpenText,
    label: "Text",
    type: PropsType.STRING,
  },
  {
    default_value: 0,
    icon: faSortNumericDown,
    label: "Number",
    type: PropsType.NUMBER
  },
  {
    label: "Boolean",
    type: PropsType.BOOLEAN,
    icon: faCheckCircle,
    default_value: false
  },
  {
    default_value: ["default"],
    icon: faList,
    label: "Array text",
    type: PropsType.ARRAY_STRING
  },
  {
    default_value: [0],
    icon: faList,
    label: "Array number",
    type: PropsType.ARRAY_NUMBER
  },
  {
    default_value: {},
    icon: faObjectGroup,
    label: "Object",
    type: PropsType.OBJECT
  },
  {
    default_value: [{}],
    icon: faTable,
    label: "Table",
    type: PropsType.TABLE
  }
]


const NewPropertyComponent = ({value, onChange, submit, id}: IProps) => {
  return <form id={id} onSubmit={e => submit(e, value)}>
    <div className="form-group">
      <label htmlFor="name_property_props">Name</label>
      <input className="form-control" placeholder="name" required type="text" value={value.name ?? ""}
             onChange={event => onChange({...value, name: event.target.value.trim()})}/>
    </div>
    <hr/>
    <div className="row justify-content-center">
      {optionsProperties.map(vv =>
        <div key={vv.label.trim()}
             className="col-6">
          <label
            htmlFor={vv.label.trim()}
            className="p-5 shadow-sm rounded bg-light d-flex justify-content-between align-items-center mb-3 pointer">
            <span><FontAwesomeIcon className="text-primary" icon={vv.icon}/> {vv.label}</span>
            <input required
                   checked={value.option?.type === vv.type}
                   onChange={() => onChange({
                     ...value,
                     option: vv
                   })}
                   className="form-check"
                   id={vv.label.trim()}
                   name="props_to_new_field"
                   type="radio"/>
          </label>
        </div>
      )}
    </div>
  </form>
}


export default NewPropertyComponent;