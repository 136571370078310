import {useCallback, useRef} from "react";
import useAsyncEffect from "./useAsyncEffect";

const useOnInit = (func: (...args: any[]) => void, deps: Array<any>, validateDeps: any[]) => {

  const start = useRef<boolean>(true);

  const callBack = useCallback(() => {
    if (start.current) {
      const isValid = validateDeps.reduce((previousValue, currentValue) => {
        if (previousValue && !currentValue) previousValue = false;
        return previousValue;
      }, true);

      if (isValid) {
        func(...deps);
        start.current = false;
      }
    }
  }, deps);

  useAsyncEffect(callBack, [callBack])
}

export default useOnInit;