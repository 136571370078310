import * as React from 'react';
import {useContext, useRef, useState} from 'react';
import styled from "styled-components";
import {ActionButton, ReportContext} from "../../NewReportGeneratorComponent";
import {useFieldArray, useForm} from "react-hook-form";
import {faArrowRight, faPlay, faPlusCircle, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {State} from "../../../../types/State";
import CustomTableComponent from "../../../Shared/CustomTableComponent/CustomTableComponent";
import SummarizeDropdownComponent from "../../SummarizeDropdownComponent";
import {AnalyticQueryAction} from "../../../../types/Analytic";
import useTranslate from "../../../../hooks/useTranslate";
import {debounceTime, uuidV4} from "../../../../utils";
import {analyticAggList, analyticAggPivotList, basicAnalyticAggList} from "../../../../utils/analyticsUtils";
import SummarizeColumnsComponent from "./SummarizeColumnsComponent";
import ActionDropdownColumns from "../ActionDropdownComponent/ActionDropdownColumns";
import {ReportState} from "../../Slice";

const FilterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  gap: 5px;

  @media (max-width: 968px) {
    flex-direction: column;
  }
`

type Props = {
    queryAction?: AnalyticQueryAction,
    indexAction?: number
    isPivotTable?: boolean
    isDrillDown?: boolean
    color?: string
    isReportGenerator?: boolean
}

export type ReportSummarizeForm = {
    conditions: { column: string, agg: string }[],
    agg_hierarchy: {column: string, agg: string, name: string}[],
    columns: { name: string }[],
    index_columns: { name: string }[]
    hierarchy_columns: { name: string }[]
    analysis_by?: string
    compare_with?: string
    compare_quantity?: number
    date_in_rows?: boolean
    compare_operation?: boolean
}

const ContainerInputs = styled.div`
  display: grid;
  gap: 0.5em;
  grid-template-columns: repeat(5, auto);
  align-items: center;

  @media (max-width: 968px) {
    grid-template-columns: 1fr;
  }
`

const ReportSummarizeComponent = ({
                                      indexAction,
                                      queryAction,
                                      isPivotTable,
                                      color,
                                      isReportGenerator,
                                      isDrillDown
                                  }: Props) => {
    const {state, getData, query, setQueryAction, setQuery, getAndSetAllColumns} = useContext(ReportContext)
    let onMount = useRef(false)

    const {
        control, watch, getValues
    } = useForm<ReportSummarizeForm>({
        defaultValues: {conditions: []}
    });
    const aggMethods = useFieldArray({
        control,
        name: "conditions"
    });
    const {t} = useTranslate("report")

    const aggHierarchyMethods = useFieldArray({
        control,
        name: "agg_hierarchy"
    })

    const columnsMethods = useFieldArray({
        control,
        name: "columns"
    });

    const indexColumnsMethods = useFieldArray({
        control,
        name: "index_columns"
    });

    const hierarchyColumnsMethods = useFieldArray({
        control,
        name: "hierarchy_columns"
    });

    const [showResults, setShowResults] = useState(false)
    const [results, setResults] = useState<any[]>([])
    const [loading, setLoading] = useState(State.IDLE)

    React.useEffect(() => {

        const subscription = watch((obj, {name, type}) => {

            const filters = obj["conditions"] ?? []
            const columns = obj["columns"] ?? []
            const index_columns = obj["index_columns"] ?? []
            const hierarchy_columns = obj["hierarchy_columns"] ?? []
            const agg_hierarchy = obj["agg_hierarchy"] ?? []

            if ((columns.length > 0) && filters.length > 0) {
                const agg = filters.reduce((acc: { [key: string]: string[] }, filter) => {
                    if (filter?.column && filter?.agg) {
                        if (!acc[filter.column]) {
                            acc[filter.column] = []
                        }
                        acc[filter.column].push(filter.agg)
                    }
                    return acc
                }, {})
                const actionColumns = columns.map(column => column?.name ?? "").filter(column => column)

                if (typeof indexAction !== 'undefined' && queryAction && indexAction >= 0) {

                    const actionParams: AnalyticQueryAction = {
                        ...queryAction,
                        columns: actionColumns ?? [],
                        agg
                    }

                    if (isPivotTable) {
                        actionParams["index_columns"] = index_columns.map(column => column?.name ?? "")
                    }

                    if (isDrillDown) {
                        actionParams["hierarchy"] = hierarchy_columns.map(column => column?.name ?? "")

                        if (actionParams["hierarchy"] && actionParams["hierarchy"]?.length > 0) {
                            const aggHierarchyList: {
                                [key: string]: string[]
                            }[] = new Array(actionParams["hierarchy"]?.length).fill({})

                            actionParams["hierarchy"]?.forEach((hierarchy, index) => {

                                const aggsByHierarchy = agg_hierarchy.filter(aggHierarchy => aggHierarchy?.name === hierarchy)

                                aggHierarchyList[index] = aggsByHierarchy.reduce((acc: {
                                    [key: string]: string[]
                                }, filter) => {
                                    if (filter?.column && filter?.agg) {
                                        if (!acc[filter.column]) {
                                            acc[filter.column] = []
                                        }
                                        acc[filter.column].push(filter.agg)
                                    }
                                    return acc
                                }, {})
                            })

                            if (aggHierarchyList.length > 0) {
                                actionParams['agg_hierarchy'] = aggHierarchyList
                            }
                        }


                        if (actionParams["agg_herarchy"]){
                            delete actionParams["agg_herarchy"]
                        }
                    }


                    setQueryAction({
                        ...actionParams
                    }, indexAction)

                } else {
                    let nQuery = {...query}
                    let action: AnalyticQueryAction[] = [
                        {
                            type: "group_by",
                            agg: {},
                            columns: [],
                            baseQuery: true,
                            temporal_id: uuidV4()
                        }
                    ]

                    if (nQuery.actions[0] && nQuery.actions[0][0].baseQuery) {
                        action = nQuery.actions[0]
                    }

                    action[0] = {
                        ...action[0],
                        agg,
                        columns: actionColumns
                    }

                    // action[0].agg = agg
                    // action[0].columns = actionColumns

                    if (nQuery.actions.some(action => action[0].baseQuery)) {
                        nQuery.actions[0] = action
                    } else {
                        nQuery.actions.unshift(action)
                    }

                    setQuery(nQuery)

                    if (getAndSetAllColumns) {
                        debounceTime(getAndSetAllColumns, {}, 100).then(res => {
                        });
                    }
                }
            }
        });
        return () => subscription.unsubscribe();
    }, [watch, query, queryAction, indexAction]);

    const handleGetData = () => {
        const filters = getValues("conditions") ?? []
        const columns = getValues("columns") ?? []

        getData({
            setLoading,
            setResults,
            query: {
                source: {...query.source, with: state.model?.name ?? ""},
                actions: [
                    {
                        type: "group_by",
                        agg: filters.reduce((acc: { [key: string]: string[] }, filter) => {

                            if (!acc[filter.column]) {
                                acc[filter.column] = []
                            }
                            acc[filter.column].push(filter.agg)
                            return acc
                        }, {}),
                        columns: columns.map(column => column.name)
                    }
                ],
                preview: true
            }
        })
    }

    React.useEffect(() => {

        if (columnsMethods.fields.length === 0 && !onMount.current && (queryAction || query.actions.length > 0)) {
            const action = queryAction ??
            (query && query.actions[0] && query.actions[0][0]?.type ==="group_by" ? query.actions[0][0] : undefined)

            if (action) {
                const agg_hierarchy = {
                    append: aggHierarchyMethods.append,
                    fields: aggHierarchyMethods.fields,
                    remove: aggHierarchyMethods.remove
                }

                if (action) {
                    const fieldsActions = {
                        'columns': {
                            append: columnsMethods.append,
                            remove: columnsMethods.remove,
                            fields: columnsMethods.fields
                        },
                        'index_columns': {
                            append: indexColumnsMethods.append,
                            remove: indexColumnsMethods.remove,
                            fields: indexColumnsMethods.fields
                        },
                        'hierarchy': {
                            append: hierarchyColumnsMethods.append,
                            fields: hierarchyColumnsMethods.fields,
                            remove: hierarchyColumnsMethods.remove
                        },
                    }

                    onMount.current = true
                    if (['group_by', 'pivot', 'date_analysis'].includes(action.type)) {

                        if (action.agg) {

                            aggMethods.fields.forEach((field, index) => {
                                aggMethods.remove(index)
                            })
                            Object.keys(action.agg).forEach(key => {
                                if (action.agg && action.agg[key] && Array.isArray(action.agg![key])) {
                                    (action.agg![key] as string[]).forEach((agg: string) => {
                                        aggMethods.append({
                                            column: key,
                                            agg
                                        })
                                    })
                                }
                            })
                        }

                        Object.keys(fieldsActions).forEach((key) => {
                            if (action[key as keyof typeof action]) {
                                const columns = (action[key as keyof typeof action] as string[])!.map((column: string) => ({
                                    name: column
                                }))

                                fieldsActions[key as keyof typeof fieldsActions].fields.forEach((field, index) => {
                                    fieldsActions[key as keyof typeof fieldsActions].remove(index)
                                })

                                columns.forEach((item: { name: string }) => {
                                    fieldsActions[key as keyof typeof fieldsActions].append(item)
                                })
                            }
                        })


                        const aggHierarchy = action.agg_herarchy ?? action.agg_hierarchy ?? []

                        if (aggHierarchy.length > 0 && action.hierarchy && action.hierarchy.length > 0) {

                            action.hierarchy.forEach((hierarchy, index) => {
                                const aggs = aggHierarchy[index]
                                Object.keys(aggs).forEach(column => {
                                    (aggs[column] as string[]).forEach((agg: string) => {
                                        agg_hierarchy.append({
                                            column,
                                            agg,
                                            name: hierarchy
                                        })
                                    })
                                })
                            })
                        }
                    }
                }
            }

        }

    }, [query, queryAction, columnsMethods.fields, indexColumnsMethods.fields, aggHierarchyMethods.fields]);



    const getAggByHierarchy = () => {

        if (aggHierarchyMethods.fields.length === 0) {
            return null
        }

        const aggs = aggHierarchyMethods.fields.reduce((acc: {
            [hierarchy: string]: { agg: string, column: string, index: number }[]
        }, field, fieldIndex) => {

            if (!acc[field.name]) {
                acc[field.name] = []
            }

            acc[field.name].push({
                agg: field.agg,
                column: field.column,
                index: fieldIndex
            })

            return acc
        }, {})

        return Object.keys(aggs).map((hierarchy, indexAgg) => {
           return <div key={`${hierarchy}_${indexAgg}`} className="d-flex align-items-center flex-wrap gap-2">
               <div className="text-capitalize d-flex align-items-center gap-2">
                   <FontAwesomeIcon icon={faTimesCircle} className="pointer" color={"red"} onClick={() => {
                       aggs[hierarchy].forEach(agg => {
                           aggHierarchyMethods.remove(agg.index)
                       })
                   }}/>
                   <span>
                       {hierarchy}
                   </span>
                   <FontAwesomeIcon icon={faArrowRight}/>
               </div>



               {aggs[hierarchy].map((agg) => {
                   return <SummarizeDropdownComponent key={`${agg.column}_${agg.agg}_${agg.index}`}
                                               agg={watch(`agg_hierarchy.${agg.index}`)}
                                               index={agg.index}
                                               getValues={getValues} color={color} loading={loading === State.PENDING || state.state.includes(ReportState.PENDING_FETCHING_COLUMNS)}
                                               aggList={basicAnalyticAggList}
                                               baseName={`agg_hierarchy`}
                                               queryAction={queryAction}
                                               indexAction={indexAction}
                                               update={(updateIndex, value) => {
                                                   aggHierarchyMethods.update(updateIndex, {
                                                       name: hierarchy,
                                                       ...value
                                                   })
                                               }} watch={watch}
                                               control={control}
                                               remove={deleteIndex => {
                                                   aggHierarchyMethods.remove(deleteIndex)
                                               }}/>
               })}


                <ActionButton className="btn" color={color ?? "white"} textColor={"white"}
                              onClick={() => {

                                  aggHierarchyMethods.append({
                                      column: "",
                                      agg: "",
                                      name: hierarchy
                                  })
                              }}>

                    <FontAwesomeIcon icon={faPlusCircle}/>
                    {t("add_summarize")}
                </ActionButton>
            </div>
        })
    }

    return (
        <div className="d-flex flex-column">
            <div className="d-flex flex-column align-items-start gap-3">

                {isDrillDown && <FilterContainer>
                    {isDrillDown && <div className="d-flex flex-column border-bottom pb-3">
                        <b>{t("hierarchy")}</b>
                        <ContainerInputs>
                            <SummarizeColumnsComponent
                                control={control}
                                model={state.model}
                                isDrillDown
                                label={t("add_hierarchy")}
                                formName={"hierarchy_columns"}
                                color={color}
                                action={queryAction}
                                indexAction={indexAction}
                                watch={watch}
                                getValues={getValues}
                                methods={hierarchyColumnsMethods}
                            />
                        </ContainerInputs>
                    </div>}
                </FilterContainer>}
                <FilterContainer>
                    <div className="d-flex align-items-center gap-3">
                        { aggMethods.fields.length > 0 && <div className="d-flex flex-wrap gap-2">
                            {aggMethods.fields.map((filter, index) =>
                                <SummarizeDropdownComponent key={filter.id} index={index} model={state.model}
                                                            getValues={getValues} color={color}
                                                            agg={watch(`conditions.${index}`)}
                                                            aggList={isReportGenerator ? (isPivotTable ? analyticAggPivotList : analyticAggList) : basicAnalyticAggList}
                                                            queryAction={queryAction} indexAction={indexAction}
                                                            update={aggMethods.update} watch={watch} control={control}
                                                            remove={aggMethods.remove}/>
                            )}</div>}


                        <ActionButton className="btn" color={color ?? "white"} textColor={"white"} onClick={() => {
                            aggMethods.append({
                                agg: "",
                                column: ""
                            })
                        }}>
                            <FontAwesomeIcon icon={faPlusCircle}/>
                            {aggMethods.fields.length === 0 ? t("add_summarize") : ""}
                        </ActionButton>
                    </div>

                    <>
                        <span className="text-center">{t("common:by")}</span>
                        <SummarizeColumnsComponent
                            control={control}
                            model={state.model}
                            formName={"columns"}
                            color={color}
                            action={queryAction}
                            indexAction={indexAction}
                            watch={watch}
                            getValues={getValues}
                            methods={columnsMethods}
                        />
                    </>


                    {isPivotTable && <>
                        <span className="text-center">
                            {t("pivot")}
                            </span>

                        <ContainerInputs>
                            <SummarizeColumnsComponent
                                control={control}
                                model={state.model}
                                formName={"index_columns"}
                                color={color}
                                action={queryAction}
                                indexAction={indexAction}
                                watch={watch}
                                getValues={getValues}
                                methods={indexColumnsMethods}
                            />
                        </ContainerInputs>

                    </>}

                    {!queryAction && <div>
                        <FontAwesomeIcon className="pointer" icon={faPlay} onClick={() => {
                            setShowResults(true)
                            handleGetData()
                        }}/></div>}


                </FilterContainer>
            </div>

            {
                isDrillDown && queryAction?.hierarchy && queryAction.hierarchy.length > 0 && <div className="d-flex flex-column gap-2 mt-3 border-top">
                            <span className="fw-bold text-capitalize">
                                {t("agg_by_hierarchy")}
                            </span>

                    <ActionDropdownColumns
                        onChange={(column) => {
                            aggHierarchyMethods.append({
                                column: "",
                                agg: "",
                                name: column
                            })
                        }} columns={queryAction.hierarchy.filter(hie => watch('agg_hierarchy') ? !watch('agg_hierarchy')?.some(aggHierarchy => aggHierarchy.name === hie) : true).map(hie => hie)} showType
                        index={indexAction}
                        color={color}
                        label={`${t("select")} ${t("hierarchy")}`}/>

                    <div className="d-flex flex-column gap-2">

                        {getAggByHierarchy()}

                    </div>
                </div>
            }

            {showResults && <div className="p-3">
                <div className="d-flex gap-2 align-items-center card-header mb-2">
                    <span>{t("common:preview")}</span>
                    <FontAwesomeIcon icon={faTimesCircle} className="pointer"
                                     onClick={() => {
                                         setShowResults(false)
                                     }}/>
                </div>

                <CustomTableComponent
                    columns={results && results[0] ? Object.keys(results[0]).map(item => ({
                        name: item,
                        header: item
                    })) : []}
                    data={results?.length > 0 ? results : []}
                    loading={loading === State.PENDING}
                />
            </div>}
        </div>
    );
};

export default ReportSummarizeComponent