import * as React from 'react';
import {useContext, useState} from 'react';
import {ReportContext} from "./NewReportGeneratorComponent";
import ButtonComponent from "../Shared/ButtonComponent";
import {faExchangeAlt, faEye, faSave} from "@fortawesome/free-solid-svg-icons";
import useTranslate from "../../hooks/useTranslate";
import ReportQueryPreview from "../ReportGeneratorComponent/ReportPreviewComponentWrapper/ReportQueryPreview";
import SaveReportComponent from "./SaveReportComponent";
import NewJsonToQueryComponent from "./NewJsonToQueryComponent";


const NewReportMenuComponent = () => {
    const {query, saveQuery, report} = useContext(ReportContext)
    const {t} = useTranslate("common")

    const [openPreview, setOpenPreview] = useState(false)
    const [openSaveReport, setOpenSaveReport] = useState(false)
    const togglePreviewQuery = () => setOpenPreview(prevState => !prevState)
    const toggleSaveReport = () => setOpenSaveReport(prevState => !prevState)

    const [openTransformModal, setOpenTransformModal] = useState(false);

    const toggleTransformComponent = () => {
        setOpenTransformModal(prevState => !prevState)
    };

    return (
        <>
            <div
                className={` d-flex align-items-center justify-content-${report?.name ? 'between' : 'end'} shadow rounded-lg bg-white rounded p-3`}>

                {report?.name && <h3>{report.name}</h3>}

                <div className="d-flex align-items-center">

                    <ButtonComponent label={t("report:transform-query")} className="mx-2" color={"primary"}
                                     onClick={toggleTransformComponent}
                                     icon={faExchangeAlt}/>

                    <ButtonComponent label={t("preview_json")} className="mx-2" color={"info"}
                                     onClick={togglePreviewQuery}
                                     icon={faEye}/>
                    <ButtonComponent label={t("save")} disabled={!query.source.with} icon={faSave} onClick={() => {
                        if (saveQuery) {
                            saveQuery(query)
                        } else {
                            toggleSaveReport()
                        }
                    }}/>
                </div>


            </div>

            {openPreview && <ReportQueryPreview query={query} toggleCanvas={togglePreviewQuery}/>}
            {openSaveReport && <SaveReportComponent toggleCanvas={toggleSaveReport}/>}
            {openTransformModal && <NewJsonToQueryComponent toggle={toggleTransformComponent}/>}
        </>
    );
};

export default NewReportMenuComponent