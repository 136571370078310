import React, {useCallback, useContext, useReducer, useState} from 'react';
import {AnalyticQuery, AnalyticQueryAction} from '../../../../../types/Analytic';
import SelectComponent from '../../../../Shared/FieldComponents/SelectComponent';
import {uuidV4} from '../../../../../utils';
import {ReportGeneratorContext} from '../../../ReportGeneratorComponent';
import {Controller, useForm} from 'react-hook-form';
import {State} from '../../../../../types/State';

import useTranslate from '../../../../../hooks/useTranslate';
import {StringOption} from '../../../../../types/Select';
import {isActionDate} from '../../../ManageDataComponent/ManageDataComponent';
import CreatableSelectComponent from "../../../../Shared/FieldComponents/CreatableSelectComponent";
import {SbxModelField} from "../../../../../types/Sbx";


export const numberDateOptions = [
    {label: 'NumberDate', value: '@numberdate_to_date'},
    {label: 'TimeStamp', value: '@timestamp_to_date'},
];

export const formatDateOptions: { label: string, value: string, type?: SbxModelField  }[] = [
    {label: 'YYYYMMDD', value: '%Y%m%d', type: SbxModelField.INT},
    {label: 'YYYY-MM-DDTHHMMSS', value: '%Y-%m-%dT%H:%M:%S'},
    {label: 'YYYY', value: '%Y',  type: SbxModelField.INT},
    {label: 'YYYYMM', value: '%Y%m' , type: SbxModelField.INT},
    {label: 'MM', value: '%m'},
    {label: 'D', value: '%d', type: SbxModelField.INT},
    {label: 'MMDD', value: '%m%d'},
];
type FormatType = 'numeric' | 'formatDate' | ''

type Props = {
    action: AnalyticQueryAction
    // setShowForm: (show: boolean) => void
    isOperationAction?: boolean
}

interface ConvertData {
    base_column: string;
    new_column_to_format: string;
    from_format: 'numberdate_to_date' | 'timestamp_to_date' | 'formateddate_to_date' | string;
    to_format?: string;
    column_to_operation?: string;
    operation_with?: string;
}

enum Types {
    SET_STATE,
    SET_MULTI_STATE
}

export interface InitialReducerState {
    isLoading: State;
    format_selector_values: {
        [key: string]: { label: string, value: any }
    };
}

const initialState: InitialReducerState = {
    isLoading: State.IDLE,
    format_selector_values: {}
};

function reducer(state: InitialReducerState, {
    type,
    payload
}: { type: Types, payload: { name: string, value?: any } | { name: string, value: any }[] }) {
    switch (type) {
        case Types.SET_STATE:
            return {
                ...state,
                [(payload as { name: string, value: any }).name]: (payload as { name: string, value: any }).value
            };
        case Types.SET_MULTI_STATE:
            (payload as { name: keyof InitialReducerState, value: any }[]).forEach(data => {
                state = {...state, [data.name]: data.value};
            });
            return {...state};
        default:
            throw new Error();
    }
}

export const dateOperationActions = [
    {label: 'Add', value: '@add'},
    {label: 'Diff', value: '@diff'},
    {label: 'Subtraction', value: '@sub'},
];

export const dateOperationWith = [
    {label: 'day', value: 'D'},
    {label: 'week', value: 'W'},
    {label: 'hour', value: 'H'},
    {label: 'minute', value: 'T'},
    {label: 'second', value: 'S'},
    {label: 'millisecond', value: 'L'},
    {label: 'microsecond', value: 'U'},
    {label: 'nanosecond', value: 'N'},
];

const DateTransformFormComponent = ({action, isOperationAction}: Props) => {

    const [formatType, setFormatType] = useState<{ label: string, value: FormatType } | null>(null);
    const {t} = useTranslate('common');
    const {control, register, setValue, getValues, watch} = useForm();
    const [actionOperation, setActionOperation] = useState<null | StringOption>({label: t("report:add"), value: '@add'});
    const [isNumericDate, setIsNumericDate] = useState(action.transformation ? ((action.transformation as string)?.includes('number') || (action.transformation as string)?.includes('timestamp')) : false);
    const [stateLocal, dispatchLocal] = useReducer(reducer, initialState);
    const dispatchLocalForm = ({name, value}: { name: keyof InitialReducerState, value: any }) => {
        dispatchLocal({type: Types.SET_STATE, payload: {value, name}});
    };
    const [dependencyOperationAction, setDependencyOperationAction] = useState<AnalyticQueryAction | null>(null);
    const [dependencyAction, setDependencyAction] = useState<AnalyticQueryAction | null>(null);
    const [toFormat, setToFormat] = useState<StringOption | null>(null)
    const [formatOptions] = useState([
        {label: t('report:format-date'), value: 'formatDate'},
        {label: t('report:numeric'), value: 'numeric'},
    ]);
    const [dateOperationActions] = useState([
        {label: t("report:add"), value: '@add'},
        {label: t("report:diff"), value: '@diff'},
        {label: t("report:substraction"), value: '@sub'},
    ]);

    React.useEffect(() => {

        if (action.transformation) {
            setIsNumericDate((action.transformation as string)?.includes('number') || (action.transformation as string)?.includes('timestamp'));
            if ((action.transformation as string).includes('formateddate_to_date')) {
                setFormatType({label: 'Format Date', value: 'formatDate'});
            }
            const actions = [...analyticQuery.actions];

            let subIndexOP = -1

            const actionOPIndex = actions.findIndex(nAction => {

                const subIndex = nAction.findIndex(subAction => subAction.dependency_action_id === action.temporal_id && dateOperationActions.find(opAction => (subAction.transformation as string).split(' ')[0] === opAction.value))
                if (subIndex !== -1) {
                    subIndexOP = subIndex
                }
                return subIndex >= 0
            });


            // console.log("actionOPIndex", actionOPIndex)
            // console.log("subIndexOP", subIndexOP)
            // console.log("\n\n\n")

            if (actionOPIndex !== -1 && subIndexOP !== -1) {
                setDependencyOperationAction({
                    ...actions[actionOPIndex][subIndexOP],
                    index: actionOPIndex,
                    subIndex: subIndexOP
                });
                const operation = dateOperationActions.find(op => (actions[actionOPIndex][subIndexOP].transformation as string).split(' ')[0] === op.value);


                if (operation) {
                    setActionOperation(operation);
                }
            }

        }

    }, [action.transformation]);

    const {
        reportState: {analyticQuery}, dispatchForm, getActionColumns
    } = useContext(ReportGeneratorContext);

    React.useEffect(() => {
        /* Finding the dependency action of the current action. */
        const actions = [...analyticQuery.actions];
        let subIndexOP = -1
        let dependencyIndex = actions.findIndex(nAction => {
            const subIndex = nAction.findIndex(subAction => (subAction.dependency_action_id === action.temporal_id && (subAction.transformation as string).includes('@date')))
            if (subIndex !== -1) {
                subIndexOP = subIndex
            }
            return subIndex >= 0
        });


        if (dependencyIndex !== -1 && subIndexOP !== -1) {
            setDependencyAction({...actions[dependencyIndex][subIndexOP], index: dependencyIndex});
        } else {
            let subIndexOP = -1
            dependencyIndex = actions.findIndex(nAction => {

                    const subIndex = nAction.findIndex(subAction => subAction.dependency_action_id === action.temporal_id && isActionDate(subAction))
                    if (subIndex !== -1) {
                        subIndexOP = subIndex
                    }
                    return subIndex >= 0
                }
            );
            if (dependencyIndex !== -1 && subIndexOP !== -1) {
                setDependencyAction({
                    ...actions[dependencyIndex][subIndexOP],
                    index: dependencyIndex,
                    subIndex: subIndexOP
                });
            }
        }
    }, [action]);

    const getFormatTypeOptions = () => {
        if (formatType?.value === 'numeric') {
            return numberDateOptions;
        } else {
            if (!formatType && isNumericDate) {
                return numberDateOptions;
            }
            return formatDateOptions;
        }
    };

    const getBaseColumn = (data: ConvertData) => {
        let baseColumn = data.base_column;
        const actions = [...analyticQuery.actions];
        const actionIndex = actions.findIndex(nAction => nAction.find(subAction => subAction.temporal_id === action.temporal_id));
        if (actionOperation?.value && data.new_column_to_format) {
            if (baseColumn !== data.new_column_to_format && !baseColumn.includes('temp')) {
                baseColumn = `temp_${actionIndex}_` + baseColumn;
            }
        }

        return baseColumn
    }

    const getActionDependencyBase = (data: ConvertData) => {

        const baseColumn = getBaseColumn(data)

        let transformationDependency = `@date_to_formateddate ${baseColumn} ${data.to_format ?? data.from_format}`;
        if (actionOperation?.value && data.from_format === '@numberdate_to_date') {
            transformationDependency = `@date_to_numberdate ${baseColumn}`;
        }

        return {
            name: data.new_column_to_format,
            transformation: transformationDependency
        };


    }

    const getBaseDateActions = (data: ConvertData) => {
        let baseTransformation = '';

        if (formatType?.value === 'formatDate') {
            baseTransformation = `@formateddate_to_date ${data.base_column} ${data.from_format}`;
        } else {
            baseTransformation = `${data.from_format} ${data.base_column}`;
        }

        action.name = getBaseColumn(data as ConvertData)
        action.transformation = baseTransformation


        const dependencyActionBase = getActionDependencyBase(data as ConvertData)
        const actions = [...analyticQuery.actions];

        if (dependencyAction?.name && typeof dependencyAction.index === 'number' && dependencyAction.index !== -1) {
            actions[dependencyAction.index][0] = {
                ...actions[dependencyAction.index][0],
                ...dependencyActionBase
            };
        } else {
            const actionIndex = actions.findIndex(nAction => nAction.find(subAction => subAction.temporal_id === action.temporal_id));
            actions[actionIndex].push({
                ...action,
                ...dependencyActionBase,
                dependency_action_id: action.temporal_id,
                temporal_id: uuidV4()
            });
        }


        return actions
    }

    React.useEffect(() => {
        const subscription = watch((data) => {

            if (data.base_column && data.from_format && data.to_format && !isOperationAction && action.name !== data.base_column) {
                const newQuery: AnalyticQuery = {...analyticQuery, actions: getBaseDateActions(data as ConvertData)};
                dispatchForm({name: 'analyticQuery', value: newQuery});
            }
        });
        return () => subscription.unsubscribe();
    }, [watch, action, formatType, analyticQuery, dependencyAction, isOperationAction]);


    React.useEffect(() => {
        const subscription = watch((data) => {

            if (isOperationAction && data.column_to_operation && data.new_column_to_format && data.operation_with && data.base_column && data.from_format && dependencyOperationAction?.name !== data.base_column) {
                let baseColumn = getBaseColumn(data as ConvertData)
                const actions = getBaseDateActions(data as ConvertData)
                const actionIndex = actions.findIndex(nAction => nAction.find(subAction => subAction.temporal_id === action.temporal_id));

                const dependencyOpBase = {
                    name: baseColumn,
                    transformation: `${actionOperation?.value} ${baseColumn} ${actionOperation?.value === '@diff' ? `temp_${actionIndex}_` : ''}${data.column_to_operation} ${data.operation_with}`,
                };

                const dependencyActionBase = getActionDependencyBase(data as ConvertData)


                let baseTransformation2 = '';
                if (formatType?.value === 'formatDate') {
                    baseTransformation2 = `@formateddate_to_date ${data.column_to_operation} ${data.from_format}`;
                } else {
                    baseTransformation2 = `${data.from_format} ${data.column_to_operation}`;
                }


                if (actionOperation?.value) {

                    if (dependencyOperationAction?.name && typeof dependencyOperationAction.index === 'number' && dependencyOperationAction.index !== -1) {

                        if (actionOperation?.value === '@diff') {
                            dependencyActionBase.transformation = baseTransformation2
                            dependencyActionBase.name = `temp_${actionIndex}_` + data.column_to_operation
                            dependencyOpBase.name = data.new_column_to_format
                        }

                        actions[dependencyOperationAction.index][0] = {
                            ...actions[dependencyOperationAction.index][0],
                            ...dependencyOpBase
                        };
                    } else {
                        if (actionOperation?.value === '@diff') {
                            actions[actionIndex].push({
                                ...action,
                                name: `temp_${actionIndex}_` + data.column_to_operation,
                                transformation: baseTransformation2,
                                dependency_action_id: action.temporal_id,
                                temporal_id: uuidV4()
                            });

                            dependencyOpBase.name = data.new_column_to_format;
                        }


                        actions[actionIndex].push({
                            ...action,
                            ...dependencyOpBase,
                            dependency_action_id: action.temporal_id,
                            temporal_id: uuidV4()
                        });
                    }

                    const newQuery: AnalyticQuery = {...analyticQuery, actions};
                    dispatchForm({name: 'analyticQuery', value: newQuery});
                }
            }
        });
        return () => subscription.unsubscribe();
    }, [watch, action, formatType, actionOperation, dependencyOperationAction, isOperationAction]);


    // const onError: SubmitErrorHandler<ConvertData> = (errors) => {
    //     toast({type: 'error', message: 'Complete missing fields.'});
    // };

    const getFormatType = () => {
        return action.transformation ? isNumericDate ? {
            label: 'Numeric',
            value: 'numeric'
        } : {
            label: 'Format Date',
            value: 'formatDate'
        } : null;
    };

    const getFromFormatDefault = () => {
        if (action.transformation) {
            const numericForm = numberDateOptions.find(option => (action.transformation as string)?.includes(option.value));
            if (numericForm) {
                return numericForm;
            }

            const formatDateForm = formatDateOptions.find(option => (action.transformation as string)?.split(' ').find(str => str === option.value));

            if (formatDateForm) {
                return formatDateForm;
            }
        } else {
            return null;
        }
    };

    const getNewColumnDate = useCallback(() => {

        if (dependencyOperationAction && (dependencyOperationAction?.transformation as string)?.split(' ')[0] === '@diff') {
            setValue('new_column_to_format', dependencyOperationAction.name);
            return dependencyOperationAction.name;
        } else {
            if (dependencyAction?.name) {
                setValue('new_column_to_format', dependencyAction.name);
                return dependencyAction.name;
            }
        }

    }, [dependencyOperationAction, dependencyAction]);

    const getToFormatDefaultValue = () => {
        if (dependencyAction) {

            const format = formatDateOptions.find(format => (dependencyAction.transformation as string)?.split(' ').find(str => str === format.value));
            if (format) {
                return format;
            } else {
                if (dependencyAction.transformation && typeof dependencyAction.transformation === 'string') {
                    const formatDate = dependencyAction.transformation.split(' ')[2];

                    if (formatDate) {
                        return {label: formatDate, value: formatDate};
                    }
                }
            }
        } else {
            return null;
        }
    };

    React.useEffect(() => {
        setToFormat(getToFormatDefaultValue() as StringOption)
    }, [dependencyAction]);

    const onChangeInputSelect = (selectKey: string, value: { value: any, label: string }) => {
        dispatchLocalForm({
            name: 'format_selector_values', value: {
                ...stateLocal.format_selector_values,
                [selectKey]: value
            }
        });
    };

    const getSelectValueFromForm = ({
                                        list,
                                        column,
                                        default_value
                                    }: { default_value?: string, column?: string, list: { label: string, value: any }[] }) => {

        if (column && getValues(column)) {
            const value = getValues(column);
            return list.find(item => item.value === value) ?? {label: t(value), value};
        } else if (default_value) {
            return list.find(item => item.value === default_value) ?? {label: default_value, value: default_value};
        }

        return null;
    };

    const isDiffAction = () => {
        return !!(dependencyOperationAction?.transformation && (dependencyOperationAction?.transformation as string)?.split(' ')[0] === '@diff');
    };


    return (
        <div className="d-flex flex-column gap-3 mt-3">

            {isOperationAction && <div className="d-flex flex-column">
                <span>{t("action")}: </span>
                <SelectComponent id={uuidV4()} defaultValue={dateOperationActions[0]} value={actionOperation}
                                 name={'date_action_operation'}
                                 onChange={setActionOperation}
                                 options={dateOperationActions}/>
            </div>}

            <div className="d-flex align-items-center gap-3">
                <div className="d-flex flex-column flex-grow-1">
                    <span>{t("from")}: </span>

                    <Controller
                        control={control}
                        name={'base_column'}
                        defaultValue={action ? action.name?.includes('temp') ? (action.transformation as string)?.split(' ')[1] : action.name : ''}
                        rules={{required: true}}
                        render={({field: {onChange}}) => (
                            <SelectComponent
                                defaultValue={action?.name ? {
                                    label: action.name.includes('temp') ? (action.transformation as string).split(' ')[1] : action.name,
                                    value: action.name
                                } : null}
                                menuPosition={'fixed'} onChange={evt => {
                                onChange(evt.value);
                                setValue('new_column_to_format', evt.value);
                                // dispatchForm({name: 'new_column_to_format', value: evt.value})
                            }} id={uuidV4()} name={'from'}
                                options={getActionColumns(action)}
                            />
                        )}
                    />
                </div>

                <div className="d-flex flex-column flex-grow-1">
                    <span>{t("format")}: </span>
                    <SelectComponent defaultValue={getFormatType()} menuPosition={'fixed'} id={uuidV4()} name={'format'}
                                     onChange={setFormatType}
                                     options={formatOptions}/>
                </div>

                {(formatType?.value || !!getFormatType()) && <div className="d-flex flex-column flex-grow-1">
                    <span>{t("report:choose-from-the-format")}: </span>
                    <Controller
                        control={control}
                        name={'from_format'}
                        defaultValue={getFromFormatDefault()?.value}
                        rules={{required: true}}
                        render={({field: {onChange}}) => (
                            <SelectComponent menuPosition={'fixed'} onInputChange={value => {
                                onChange(value);
                                onChangeInputSelect('format_type', {label: value, value});
                            }} defaultValue={getFromFormatDefault()}
                                             id={uuidV4()}
                                             name={'format_type'}
                                             value={stateLocal.format_selector_values.format_type}
                                             options={[{label: 'Suggestions', options: getFormatTypeOptions()}]}
                                             onChange={evt => {
                                                 onChange(evt.value);
                                                 onChangeInputSelect('format_type', evt);
                                             }}
                            />
                        )}
                    />
                </div>}
            </div>


            <div className="d-flex align-items-center gap-3">


                {isOperationAction &&
                    <>
                        <div className="d-flex flex-column flex-grow-1">
                            <span>{t("report:choose-operation-column")} {actionOperation?.value !== '@diff' ? t("report:or-enter-number") : ''}: </span>
                            <Controller
                                control={control}
                                name={'column_to_operation'}
                                defaultValue={isDiffAction() ? (dependencyAction?.transformation as string)?.split(' ')[1] : dependencyOperationAction ? (dependencyOperationAction.transformation as string).split(' ')[2] : ''}
                                rules={{required: true}}
                                render={({field: {onChange}}) => (
                                    <CreatableSelectComponent id={uuidV4()}
                                                     defaultValue={getSelectValueFromForm({
                                                         default_value: isDiffAction() ? (dependencyAction?.transformation as string)?.split(' ')[1]
                                                             : (dependencyOperationAction?.transformation as string)?.split(' ')[3],
                                                         list: getActionColumns(action).map(option => option.options).flat()
                                                     })}
                                                     value={getSelectValueFromForm({
                                                         column: 'column_to_operation',
                                                         list: getActionColumns(action).map(option => option.options).flat()
                                                     })} menuPosition={'fixed'} name={'column_to_operation'}
                                                     onChange={event => onChange(event.value)}
                                                     // onInputChange={value => {
                                                     //     if (actionOperation?.value !== '@diff') {
                                                     //         onChange(value);
                                                     //     }
                                                     // }}
                                                     options={getActionColumns(action).map(option => option.options).flat()}/>
                                )}
                            />


                        </div>

                        <div className="d-flex flex-column flex-grow-1">
                            <span>{t("report:operation-with")}: </span>
                            <Controller
                                control={control}
                                name={'operation_with'}
                                // defaultValue={dependencyOperationAction ? (dependencyOperationAction.transformation as string).split(' ')[3] : ''}
                                // rules={{required: true}}
                                render={({field: {onChange}}) => (
                                    <CreatableSelectComponent id={uuidV4()}
                                                     defaultValue={getSelectValueFromForm({
                                                         default_value: (dependencyOperationAction?.transformation as string)?.split(' ')[3],
                                                         list: dateOperationWith.map(item => ({
                                                             label: t("report:" + item.label),
                                                             value: item.value
                                                         }))
                                                     })}
                                                     value={getSelectValueFromForm({
                                                         column: 'operation_with',
                                                         list: dateOperationWith.map(item => ({
                                                             label: t("report:" + item.label),
                                                             value: item.value
                                                         }))
                                                     })}
                                                     menuPosition={'fixed'} name={'operation_with'}
                                                     onChange={event => {
                                                         onChange(event.value)
                                                     }}
                                                     // onInputChange={value => onChange(value)}
                                                     options={dateOperationWith.map(op => ({
                                                         label: t("report:" + op.label),
                                                         value: op.value
                                                     }))}/>
                                )}
                            />
                        </div>
                    </>

                }

                <div className="d-flex flex-column flex-grow-1">
                    <span>{t("to")}: </span>
                    <input type="text"
                           defaultValue={getNewColumnDate() || ''}
                           {...register('new_column_to_format', {required: true})}
                           className="form-control"/>
                </div>

                {!isOperationAction &&
                    <div className="d-flex flex-column flex-grow-1">
                        <span>{t("format")}: </span>
                        <Controller name={'to_format'} rules={{required: true}}
                                    defaultValue={getToFormatDefaultValue()?.value}
                                    control={control} render={({field: {onChange, value}}) => (
                            <CreatableSelectComponent id={uuidV4()}
                                                      value={toFormat}
                                                      menuPosition={'fixed'} name={'to_format'}
                                                      onChange={event => {
                                                          onChange(event.value)
                                                          setToFormat(event)
                                                      }}
                                                      options={formatDateOptions}/>
                        )}/>
                    </div>
                }

            </div>

            {/*<div className="d-flex justify-content-end py-3 border-bottom gap-1">*/}


            {/*    {action.transformation && <Button color={'info'} onClick={() => setShowForm(false)}>*/}
            {/*        <FontAwesomeIcon className="me-2" icon={faArrowLeft}/>*/}
            {/*        Back*/}
            {/*    </Button>}*/}


            {/*    <Button color={'primary'} onClick={() => handleSubmit(convertDate as any, onError)()}>*/}
            {/*        <FontAwesomeIcon className="me-2" icon={faExchangeAlt}/>*/}
            {/*        {t("convert")} {t("date")}*/}
            {/*    </Button>*/}
            {/*</div>*/}

        </div>
    );
};

export default DateTransformFormComponent;
