import * as React from 'react';
import styled from "styled-components";
import {AnalyticQueryAction} from "../../../../types/Analytic";
import {useContext} from "react";
import {ReportGeneratorContext} from "../../ReportGeneratorComponent";
import useTranslate from "../../../../hooks/useTranslate";
import CreatableSelectComponent from "../../../Shared/FieldComponents/CreatableSelectComponent";

type Props = {
    action: AnalyticQueryAction
}

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 15px;

  @media (max-width: 998px) {
    grid-template-columns: 1fr;
  }
`


const MonetarySegmentationActionComponent = ({action}: Props) => {
    const {getActionColumns} = useContext(ReportGeneratorContext);
    const {t} = useTranslate('report');

    return (
        <Container>
            <div>
                <span>{t("customer_id")}</span>
                <CreatableSelectComponent name={"customer_id"} defaultValue={action.customer_id ? {label: action.customer_id, value: action.customer_id} : null} options={getActionColumns(action)} onChange={evt => {
                    action.customer_id = evt.value
                }}/>
            </div>

            <div>
                <span>{t("invoice_id")}</span>
                <CreatableSelectComponent name={"invoice_id"} defaultValue={action.invoice_id ? {label: action.invoice_id, value: action.invoice_id} : null}
                                          options={getActionColumns(action)} onChange={evt => {
                    action.invoice_id = evt.value
                }}/>
            </div>

            <div>
                <span>{t("invoice_date")} (YYYYMMDD)</span>
                <CreatableSelectComponent name={"invoice_date"} defaultValue={action.invoice_date ? {label: action.invoice_date, value: action.invoice_date} : null}
                                          options={getActionColumns(action)} onChange={evt => {
                    action.invoice_date = evt.value
                }}/>
            </div>

            <div>
                <span>{t("invoice_total")}</span>
                <CreatableSelectComponent name={"invoice_total"} defaultValue={action.invoice_total ? {label: action.invoice_total, value: action.invoice_total} : null}
                                          options={getActionColumns(action)} onChange={evt => {
                    action.invoice_total = evt.value
                }}/>
            </div>
        </Container>
    );
};

export default MonetarySegmentationActionComponent