import React from 'react';
import ModalComponent from '../../../Shared/Modal/ModalComponent/ModalComponent';
import ReportGeneratorComponent from '../../ReportGeneratorComponent';
import {AnalyticQueryAction} from '../../../../types/Analytic';

type Props = {
  open: boolean
  toggle: () => void
  action: AnalyticQueryAction
}

const ActionMergeSourceModal = ({open, toggle, action}: Props) => {

  return (
    <ModalComponent noFooter isOpen={open} toggle={toggle} title={'Action merge'} size={'auto'}>
      {action.source && action.actions &&
        <ReportGeneratorComponent isModalGenerator setAnalyticQuery={query => {
          action.source = query.source
          action.actions = query.actions
        }} analyticQuery={{source: action.source, actions: action.actions.flat()}} />}
    </ModalComponent>
  );
};

export default ActionMergeSourceModal;
