import React, { FormEvent, Fragment, useEffect, useRef, useState } from "react";
import useTranslate from "../../hooks/useTranslate";
import { AnyData } from "../../types/AnyData";
import {
  conditions,
  ConditionType,
  Container,
  FieldTypesRule,
  GroupCondition,
  Operator,
  RuleCondition,
  ValuesData,
} from "./Types";
import { Card, CardBody, CardHeader, Col, Label, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faPlus,
  faTimes,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import FieldValidator from "./FieldValidator";
import {
  addRule,
  existComparator,
  existCondition,
  existField,
  generateValues,
  getSize,
  onChangeValues,
  onDeleteRule,
  sortContainers,
} from "./RuleUtils";
import { capitalize } from "../../utils";
import { Switch } from "antd";

export interface IPropsRule<T> extends AnyData {
  formId?: string;
  rules: T[];
  containers: Container[];
  onSuccess?: (rules: T[]) => void;
}

const RuleGenerator = (props: IPropsRule<RuleCondition>) => {
  const [rules, setRules] = useState<RuleCondition[]>(props.rules);
  const { t } = useTranslate("common");

  return (
    <>
      <div>
        <button
          onClick={() =>
            setRules((e) => [...e, { group: [], operator: Operator.OR }])
          }
          className="btn btn-primary btn-sm mb-2"
          type="submit"
        >
          <FontAwesomeIcon className="me-1" icon={faPlus} />
          {t("add")} {t("group")}
        </button>
      </div>
      {rules.map((rule, index) => {
        const key = Math.random() * 1000 + index;
        return (
          <div key={key} className="d-flex  align-items-center ">
            <div className="w-85 my-1">
              <RuleGroupsComponent
                {...props}
                rules={rule.group}
                onSuccess={(rules) => {
                  setRules((e) => {
                    return [...e].map((r, i) => {
                      return {
                        ...r,
                        group: i === index ? rules : r.group,
                      };
                    });
                  });
                }}
              />
            </div>
            <div className="w-15 d-flex justify-content-center align-items-center">
              {rules.length > 1 && rules.length - 1 !== index && (
                <Switch
                  checkedChildren={"AND"}
                  unCheckedChildren="OR"
                  defaultChecked={rule.operator === Operator.AND}
                  onChange={(checked) =>
                    setRules((e) => {
                      return [...e].map((r, i) => {
                        return {
                          ...r,
                          operator:
                            i === index
                              ? checked
                                ? Operator.AND
                                : Operator.OR
                              : r.operator,
                        };
                      });
                    })
                  }
                />
              )}

              <button className="btn-light btn-sm mx-2">
                <FontAwesomeIcon
                  className="pointer"
                  icon={faTrash}
                  onClick={() =>
                    setRules((e) => e.filter((r, i) => i !== index))
                  }
                />
              </button>
            </div>
          </div>
        );
      })}

      {!rules.length && <div className="p-5">Empty rules</div>}
      <div className="d-flex justify-content-end">
        <button
          onClick={() => (props.onSuccess ? props.onSuccess(rules) : null)}
          className="btn btn-primary btn-sm"
          type="submit"
        >
          <FontAwesomeIcon className="me-1" icon={faCheck} />
          {t("save")}
        </button>
      </div>
    </>
  );
};

const RuleGroupsComponent = ({
  containers,
  onSuccess: setRules,
  formId,
  rules,
}: IPropsRule<GroupCondition>) => {
  const [values, setValues] = useState<ValuesData>({});
  const initialValues = useRef<ValuesData>();
  const { t } = useTranslate("common");
  const existC = existCondition(containers);
  const existCm = existComparator(containers);
  const existF = existField(containers);

  useEffect(() => {
    let valuesT: ValuesData = generateValues(containers, values);
    setValues(valuesT);
    if (!initialValues.current) {
      initialValues.current = valuesT;
    }
  }, [containers]);

  function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (setRules) {
      setRules(addRule(rules, values));
    }

    if (initialValues.current) {
      setValues(initialValues.current);
    }
  }

  function onChange(e: any, field: Container, index: number) {
    setValues((v) => ({ ...v, [index]: { ...v[index], value: e } }));
    if (field.onChange) {
      field.onChange(e);
    }
  }

  const fieldsSorted = sortContainers(containers);

  return (
    <Card title={t("rule_title")}>
      <CardBody>
        {existC && existF && existCm ? (
          <div>
            <form id={formId ?? "rule_form_id"} onSubmit={onSubmit}>
              <Row>
                {fieldsSorted.map((field, index) => {
                  let size = getSize(containers, field);
                  const value = values[index]?.value;
                  return (
                    <Col key={index} sm={12} lg={size}>
                      <Label>{field.label}&nbsp;</Label>
                      <FieldValidator
                        {...field}
                        readonly={fieldsSorted.reduce(
                          (bool: boolean, f, i, array) => {
                            if ((field as any).isValidator) {
                              const f = array.find(
                                (field2) =>
                                  field2.type === FieldTypesRule.CONDITION,
                              );
                              if (f) {
                                const index = array.indexOf(f);
                                const val = values[index]?.value;
                                bool =
                                  val?.value === ConditionType.NO_EXIST ||
                                  val?.value === ConditionType.EXIST;
                              } else {
                                bool = false;
                              }
                            }
                            return bool;
                          },
                          false,
                        )}
                        value={value}
                        onChange={(e: any) => onChange(e, field, index)}
                      />
                    </Col>
                  );
                })}
                <Col sm={12} lg={2} className="text-right">
                  <Label>&nbsp;</Label>
                  <br />
                  <button className="btn btn-primary btn-sm mt-2" type="submit">
                    <FontAwesomeIcon icon={faPlus} /> {t("add")}
                  </button>
                </Col>
              </Row>
              <div
                style={{ minHeight: 160, maxHeight: 320 }}
                className="bg-light p-2 my-3 overflow-y-auto"
              >
                {!!rules.length ? (
                  <Row className="align-items-center">
                    {rules.map((r, index) => {
                      return (
                        <Fragment key={index}>
                          <Col sm={12} md={3} className="my-1">
                            <Card>
                              <CardHeader className="d-flex justify-content-between pe-2">
                                <small>{capitalize(r.label || r.field)}</small>
                                <FontAwesomeIcon
                                  onClick={() => {
                                    if (setRules) {
                                      setRules(onDeleteRule(rules, index));
                                    }
                                  }}
                                  className="pointer mr-2"
                                  icon={faTimes}
                                />
                              </CardHeader>
                              <CardBody>
                                <span>
                                  {t(
                                    [
                                      ...conditions,
                                      {
                                        label: "CONTAIN_LIKE",
                                        value: "%word%.includes(%value%)"
                                          .replace("%word%", r.field)
                                          .replace("%value%", `'${r.value}'`),
                                      },
                                    ].find((c) => c.value === r.condition)
                                      ?.label ?? "",
                                  )}
                                </span>{" "}
                                {r.condition !== ConditionType.NO_EXIST &&
                                  r.condition !== ConditionType.EXIST && (
                                    <b>
                                      {capitalize(
                                        r.value?.toString() || t("empty"),
                                      )}
                                    </b>
                                  )}{" "}
                              </CardBody>
                            </Card>
                          </Col>
                          {index !== rules.length - 1 && (
                            <Col sm={12} lg={1}>
                              <Switch
                                onChange={(e) => {
                                  if (setRules) {
                                    const newRules =
                                      onChangeValues<GroupCondition>(
                                        rules,
                                        index,
                                        {
                                          ...r,
                                          operator: e
                                            ? Operator.AND
                                            : Operator.OR,
                                        },
                                      );
                                    setRules(newRules);
                                  }
                                }}
                                checkedChildren={t("AND")}
                                unCheckedChildren={t("OR")}
                                checked={r.operator === Operator.AND}
                              />
                            </Col>
                          )}
                        </Fragment>
                      );
                    })}
                  </Row>
                ) : (
                  <div
                    style={{ height: 160 }}
                    className="d-flex justify-content-center align-items-center"
                  >
                    Empty rule
                  </div>
                )}
              </div>
            </form>
          </div>
        ) : (
          <div className="text-center">
            {!existC && <p>¡{t("warn_rules_creator_condition")}!</p>}
            {!existCm && <p>¡{t("warn_rules_creator_comparator")}!</p>}
            {!existF && <p>¡{t("warn_rules_creator_comparator")}!</p>}
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export default RuleGenerator;
