import * as React from 'react';
import {useState} from 'react';
import styled from "styled-components";
import {Button, Offcanvas, OffcanvasBody, OffcanvasHeader} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars, faChartBar, faCogs, faDatabase, faRobot, faSlidersH} from "@fortawesome/free-solid-svg-icons";
import useTranslate from "../../../hooks/useTranslate";

type Props = {
    setCurrentOption: (option: string) => void
    showMenu: boolean
};

const GridOptions = styled.div`
  //display: grid;
  display: flex;
  flex-direction: column;
  //grid-template-columns: repeat(auto-fit, minmax(10em, 1fr));
  gap: 15px;
  overflow: auto;
  max-height: 60vh;
`

const ButtonOption = styled(Button)`
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  align-items: center;
  padding: 7px;
  border-radius: 0.25rem;
  font-size: 12px;
`


const reportOptions = [
    {
        label: "Source",
        name: "source",
        icon: faDatabase
    },
    {
        label: "Actions",
        name: "actions",
        icon: faCogs

    },
    {
        label: "Ml",
        name: "ml",
        icon: faRobot
    },
    {
        label: "Preview",
        name: "preview",
        icon: faChartBar
    },
    {
        label: "Truncate",
        name: "truncate",
        icon: faSlidersH
    }

]


const ReportGenerationOptions = ({setCurrentOption, showMenu}: Props) => {

    const [openCanvas, setOpenCanvas] = useState(false)

    const toggleCanvas = () => setOpenCanvas(prevState => !prevState)


    const {t} = useTranslate('report');


    const getOptions = () => {
        return <>
            <GridOptions>
                {reportOptions.map(option => (
                    <ButtonOption outline color={"primary"} key={option.name}
                                  onClick={() => setCurrentOption(option.name)}>
                        {option.icon && <FontAwesomeIcon icon={option.icon} size={"2x"}/>}
                        <span>{t(`common:${option.name}`)}</span>
                    </ButtonOption>
                ))}
            </GridOptions>
            <hr className="w-100 bg-night-sky"/>
            <GridOptions>

                {/*{getReportExecuteActions().filter(action => action.hasOwnProperty("condition") ? action.condition : true).map(action => {*/}
                {/*  return <Tooltip title={t(action.name)} key={action.name}>*/}
                {/*    <ButtonOption onClick={action.onClick} outline disabled={action.disable} color={action.color ?? "primary"}>*/}
                {/*      <FontAwesomeIcon icon={action.icon}  size={"2x"}/>*/}
                {/*      <span>{t(action.name)}</span>*/}
                {/*    </ButtonOption>*/}
                {/*  </Tooltip>*/}
                {/*})}*/}
            </GridOptions>
        </>
    }

    return (
        <div className={showMenu ? "" : "d-none"}>

            <div className="d-none d-xl-flex flex-column">
                {getOptions()}
            </div>

            <div className="d-flex d-xl-none justify-content-end">
            {/*<div className={`${showMenu ? "d-flex d-xl-none justify-content-end" : "d-flex justify-content-end"}`}>*/}
                <Button outline color="primary" onClick={toggleCanvas}>
                    <FontAwesomeIcon icon={faBars} className="mr-1"/>
                    Menu
                </Button>

                <Offcanvas isOpen={openCanvas}>
                    <OffcanvasHeader toggle={toggleCanvas}>
                        Menu
                    </OffcanvasHeader>
                    <OffcanvasBody>
                        {getOptions()}
                    </OffcanvasBody>
                </Offcanvas>
            </div>
        </div>
    );
};

export default ReportGenerationOptions