import React from "react";
import { SubMenuProps } from "./types/SideBar";
import Link from "next/link";

type Props = {
  menu: SubMenuProps;
  menuActive: string;
  setMenuActive: (menuName: string) => void;
};

const SubMenuIitemsComponent = ({ menu, menuActive, setMenuActive }: Props) => {
  if (!menu.subItems?.length) {
    return null;
  }
  const expanded = menu.name === menuActive;

  return (
    <>
      <li>
        <a
          onClick={() => {
            setMenuActive(!expanded ? menu.name : "");
          }}
        >
          <i className={"metismenu-icon"} />
          {menu.label}
          <i className="metismenu-state-icon pe-7s-angle-down caret-left" />
        </a>

        <ul
          style={{ maxHeight: expanded ? "500px" : 0 }}
          className={`collapse-list ${
            expanded ? "mm-show menu-item-active" : ""
          }`}
        >
          {menu.subItems.map((subItem) => {
            return (
              <li key={subItem.label} onClick={() => setMenuActive(menu.name)}>
                <Link title={subItem.path} href={subItem.path}>
                  <span className={subItem.active ? "mm-active" : ""}>
                    <i className="metismenu-icon" />
                    {subItem.label}
                  </span>
                </Link>
              </li>
            );
          })}
        </ul>
      </li>
    </>
  );
};

export default SubMenuIitemsComponent;
