import React from 'react';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSave, faSpinner, faTimes} from '@fortawesome/free-solid-svg-icons';
import useTranslate from '../../../../hooks/useTranslate';


export type SizeModal = "sm" | "md" | "lg" | "xl"| "xxl" | "auto" ;

export interface ModalProps {
  isOpen: boolean;
  zIndex?: number;
  toggle: () => void;
  onSuccess?: (v?: any) => void;
  disabled?: boolean;
  isLoading?: boolean;
  noFooter?: boolean;
  centered?: boolean;
  header?: React.ReactNode;
  labelSuccess?: string;
  labelCancel?: string;
  size?: SizeModal;
  title?: React.ReactNode;
  footer?: React.ReactElement;
  type?: "button" | "submit" | "reset",
  form?: string;
  children?: React.ReactNode;
  onlyDisabled?: boolean;
}

const ModalComponent: React.FC<ModalProps> = ({
                                                children,
                                                noFooter,
                                                isOpen,
                                                toggle,
                                                onSuccess,
                                                // disabled,
                                                isLoading,
                                                header,
                                                footer,
                                                size = 'md',
                                                title,
                                                labelCancel,
                                                labelSuccess,
                                                zIndex,
                                                type = "button",
                                                form = "", centered = false, onlyDisabled = false
                                              }) => {

  const {t} = useTranslate("common");

  return (
    <Modal zIndex={zIndex || 2000} size={size} isOpen={isOpen} centered={centered}>
      {header ?? (title ? <ModalHeader toggle={toggle}>{header || title}</ModalHeader> : null)}
      <ModalBody>{children}</ModalBody>
      {!noFooter ? (
        <ModalFooter>
          {footer ? (
            footer
          ) : (
            <>
              <button
                type={type}
                disabled={isLoading}
                onClick={!form && onSuccess ? onSuccess : undefined}
                form={form}
                className="btn btn-primary btn-sm me-1">
                  {
                    onlyDisabled ? <><FontAwesomeIcon icon={faSave}/>{' ' + t(labelSuccess || 'save')}</> 
                    : <><FontAwesomeIcon spin={isLoading} icon={isLoading ? faSpinner : faSave}/>{' ' + t(labelSuccess || 'save')}</>
                  }
                
              </button>
              <button
                disabled={isLoading}
                onClick={toggle} className="btn btn-light btn-sm">
                <FontAwesomeIcon
                  icon={faTimes}/>{" " + t(labelCancel || "cancel")}
              </button>
            </>
          )}
        </ModalFooter>
      ) : null}
    </Modal>
  );
};

export default ModalComponent;
