import React, {useContext} from 'react';
import {State} from "../../../types/State";
import {AnalyticQueryAction} from "../../../types/Analytic";
import {ReportGeneratorContext} from "../ReportGeneratorComponent";
import {OnChangeActionParams} from "./ActionComponent";

type Props = {
  action: AnalyticQueryAction
  onChangeAction: (actions: OnChangeActionParams[]) => void
}

const LimitActionComponent = ({action, onChangeAction}: Props) => {

  const {reportState: {isLoading, actionLoading}} = useContext(ReportGeneratorContext);

  return (
    <div><input type="number" disabled={(action.temporal_id && actionLoading[action.temporal_id] === State.PENDING) ||State.PENDING === isLoading} min={1} className="form-control"
                defaultValue={action.top} onChange={event => {
      onChangeAction([{
        action,
        actionKey: 'top',
        noGetColumns: true,
        value: event.currentTarget.value ? parseInt(event.currentTarget.value) : 1
      }]);
    }} placeholder={'Enter limit number...'}/></div>
  );
};

export default LimitActionComponent;
