import * as React from 'react';
import {useContext, useRef, useState} from 'react';
import useTranslate from "../../../../hooks/useTranslate";
import {Controller, useFieldArray, useForm} from "react-hook-form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown, faAngleLeft, faPlusCircle, faTrash} from "@fortawesome/free-solid-svg-icons";
import ButtonComponent from "../../../Shared/ButtonComponent";
import styled from "styled-components";
import {AnalyticQueryAction} from "../../../../types/Analytic";
import {ActionButton, ReportContext} from "../../NewReportGeneratorComponent";
import ReportFilterComponent from "../../ReportFilterComponent";
import TransformDataOperationsWrapper from "./TransformDataOperationsWrapper";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 15px 0;

  @keyframes fadeInDown {
    0% {
      opacity: 0;
      transform: translateY(-1.25em);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  animation-name: fadeInDown;
  animation-duration: 0.5s;

`

type Transformation = {
    name: string
    index: number
    ['@filter@']?: string
    transformation: string
}
type Props = {
    action: AnalyticQueryAction
    index: number
    color?: string
}

const ContainerInputs = styled.div`
  display: grid;
  align-items: start;
  grid-template-columns: 10em 1fr auto;
  gap: 10px;
`

const NewTransformDataActionComponent = ({action, index, color}: Props) => {
    const {t} = useTranslate('common');

    const {setQueryAction, query} = useContext(ReportContext)

    const [showFilters, setShowFilters] = useState(false)
    const {control, watch} = useForm<{ transformations: Transformation[], name: string }>();
    const {fields, append, remove, replace} = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: "transformations", // unique name for your Field Array
    });

    let onMount = useRef(false)

    React.useEffect(() => {

        if (!onMount.current && action) {

            let newColumns: Transformation[] = []

            if (action.transformation) {
                newColumns.push({
                    name: action.name ?? "",
                    index: 1,
                    transformation: typeof action.transformation !== "string" ? action.transformation.toString() : action.transformation
                })

            }

            if (action.columns && action.columns.length > 0 && fields.length === 0) {

                action.columns.forEach((column: { [key: string]: any }) => {
                    const obj: Transformation = {
                        name: "",
                        index: Math.max(1, newColumns.length + 1),
                        transformation: ""
                    }


                    Object.keys(column).forEach(key => {
                        if (key !== "@filter@") {
                            obj.name = key as string
                            obj.transformation = column[key]
                        }

                    })


                    if (column["@filter@"]) {
                        obj["@filter@"] = column["@filter@"]
                    }

                    newColumns.push(obj)
                })
            }



            if (newColumns.length > 0) {

                if (!action.columns) {
                    setQueryAction({
                        ...action,
                        name: "",
                        transformation: "",
                        columns: newColumns.map(column => {

                            const columns = {
                                [column.name]: column.transformation
                            }

                            // console.log('column.filter', column)
                            if (column['@filter@']) {
                                columns["@filter@"] = column['@filter@']
                            }

                            return columns
                        })
                    }, index)
                }


                replace(newColumns.sort((a, b) => (a?.index ?? 0) - (b?.index ?? 0)))
                onMount.current = true
            }

            if (action.filter && action.filter.length > 0 && action.filter !== "*"){
                setShowFilters(true)
            }

        }
    }, [action]);

    React.useEffect(() => {

        if (fields.length === 0 && !onMount.current && !action.transformation && (!action.columns || (action.columns && action.columns.length === 0))) {
            append({
                name: "sample_name_" + Math.max(1, fields.length + 1),
                index: Math.max(1, fields.length + 1),
                transformation: ""
            })

            onMount.current = true
        }
    }, [fields, action]);

    // Callback version of watch.  It's your responsibility to unsubscribe when done.
    React.useEffect(() => {
        const subscription = watch((obj) => {
            if (obj.transformations && obj.transformations!?.length > 0) {

                const newColumns = obj.transformations.sort((a, b) => (a?.index ?? 0) - (b?.index ?? 0)).reduce((arr: {
                    [key: string]: string
                }[], transformation) => {
                    if (transformation?.transformation && transformation?.name) {

                        const params = {
                            [transformation.name]: transformation.transformation
                        }


                        if (transformation['@filter@'] && transformation['@filter@'].length > 0) {
                            params['@filter@'] = transformation['@filter@']
                        }

                        arr.push(params)
                    }

                    return arr
                }, [])

                setQueryAction({
                    ...action,
                    columns: newColumns
                }, index)

            }
        });
        return () => subscription.unsubscribe();
    }, [watch, action, query, index]);

    return <div>

        <Container>
            {fields.map((field, indexField) => (

                <div className="d-flex flex-column border-bottom pb-3 gap-3" key={`${field.id}`}>
                    <ContainerInputs className=" ">
                        <Controller
                            render={({field}) => <div className="d-flex flex-column">
                                <span style={{color: color ?? "black"}} className="fw-bold">{t('name')}</span>
                                <input type="text" placeholder={t('name')} value={field.value || ""}
                                       className="form-control"
                                       onChange={evt => field.onChange(evt.currentTarget.value)}/>
                            </div>}

                            name={`transformations.${indexField}.name`}
                            control={control}
                        />

                        <div className="d-flex flex-column flex-grow-1 mt-3">
                            <Controller
                                render={({field}) =>
                                    <TransformDataOperationsWrapper value={field.value} onChange={field.onChange}
                                                                    index={index} action={action}
                                                                    color={color ?? 'black'}/>}
                                name={`transformations.${indexField}.transformation`}
                                control={control}
                            />
                        </div>


                        {fields.length > 1 &&
                            <ButtonComponent label={t("remove")} className={"mt-4"} color={"danger"} onClick={() => remove(indexField)}
                                             icon={faTrash}/>}
                    </ContainerInputs>


                    <div className="d-flex justify-content-start mb-2">
                        <Controller
                            render={({field: {onChange}}) => <ReportFilterComponent color={color} indexAction={index}
                                                                                    queryAction={{
                                                                                        ...action,
                                                                                        filter: field["@filter@"]
                                                                                    }}
                                                                                    setFilter={customFilter => {
                                                                                        onChange(customFilter)
                                                                                    }}/>}
                            name={`transformations.${indexField}.@filter@`}
                            control={control}
                        />
                    </div>


                </div>

            ))}</Container>

        <div className="d-flex justify-content-end mb-2">
            <ActionButton className="btn" color={color ?? "white"} onClick={() => {

                const transformations = watch('transformations')
                const lastName = transformations && transformations.length > 0 ? transformations[transformations.length - 1]?.name : ""

                append({
                    name: lastName ?? "sample_name_" + Math.max(1, fields.length + 1),
                    index: Math.max(1, fields.length + 1),
                    transformation: ""
                })
            }}>
                <FontAwesomeIcon icon={faPlusCircle}/>
                <span>{`${t("add")} ${t("report:data-transformation")}`}</span>
            </ActionButton>
        </div>

        <hr className="w-100"/>

        <div className="text-end gap-2 fw-bold" style={{color: color ?? "black"}}>
            <span className="pointer underline mr-1"
                  onClick={() => setShowFilters(prevState => !prevState)}>{`${t(showFilters ? "hide" : "show")} ${t("filters")}`}
            </span>

            <span>
                <FontAwesomeIcon icon={showFilters ? faAngleDown : faAngleLeft}/>
            </span>
        </div>

        {showFilters && <ReportFilterComponent color={color} indexAction={index} queryAction={action}/>}
    </div>
};

export default NewTransformDataActionComponent