import useRouterLoadingEvents from "../../hooks/useRouterLoadingEvents";
import {State} from "../../types/State";
import SpinnerComponent from "../Shared/SpinnerComponent";

const LoadingLayout = () => {
  const [loadingPage] = useRouterLoadingEvents();



  return (
    <>
      <div id={"to-get-responsive"}/>
      {loadingPage === State.PENDING && (
        <div
          className="vh-100 w-100 d-flex justify-content-center align-items-center"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 1000,
            background: "rgba(255,255,255,0.5)"
          }}>
          <SpinnerComponent type={"ball-grid-pulse"}/>
        </div>
      )}
    </>
  )
}


export default LoadingLayout;
