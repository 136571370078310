import React, {useContext, useState} from 'react';
import SourceComponent from '../SourceComponent/SourceComponent';
import ActionsComponent from '../ActionsComponent/ActionsComponent';
import {Button} from 'reactstrap';
import {faCode, faExchangeAlt, faPlay, faSpinner, faSyncAlt, faTimes, faTrash} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {executeAnalyticJson} from '../../../services/backend/AnalyticsService';
import {State} from '../../../types/State';
import {toast} from '../../../utils';
import {ReportGeneratorContext} from '../ReportGeneratorComponent';
import {AnalyticQueryAction, BaseAnalyticQuery} from '../../../types/Analytic';
import JsonToQueryComponent from '../JsonToQueryComponent';
import {
  dateOperationActions,
  dateOperationWith,
  formatDateOptions,
  numberDateOptions
} from '../ActionsComponent/TransformComponent/DateTransformComponent/DateTransformFormComponent';
import {actionsModal, ModalTypes} from "../../../store/Modal/Slice";
import {useDispatch} from "react-redux";
import useTranslate from "../../../hooks/useTranslate";
import {Tooltip} from "antd";
import {isValidAnalyticQuery} from "../../../utils/analyticsUtils";

/**
 * `isActionDate` returns true if the `action`'s transformation is a string and the string contains a date format or a
 * number date option
 * @param {AnalyticQueryAction} action - AnalyticQueryAction - this is the action object that is passed in from the
 * analytic query builder.
 */
export const isActionDate = (action: AnalyticQueryAction) => typeof action.transformation === 'string' &&
  (formatDateOptions.some(option => (action.transformation as string)?.split(' ').some(str => str === option.value))
    || numberDateOptions.some(option => (action.transformation as string).split(" ")[0] === (option.value))
    || (action.transformation as string)?.startsWith("@date")
    || dateOperationActions.filter(op => op.value).some(option => typeof action.transformation === 'string' && action.transformation.split(' ')[0] === option.value)
    || dateOperationWith.some(option => typeof action.transformation === 'string' && action.transformation.split(' ')[3] === option.value)

  );

const ManageDataComponent = () => {

  const {
    reportState: {analyticQuery, isLoading},
    dispatchForm,
    togglePreviewQuery,
    getAllSourcesColumns,
    getAllActionColumns,
    resetDefaultValues
    // getActionColumns
  } = useContext(ReportGeneratorContext);
  const {t} = useTranslate('report');
  const dispatch = useDispatch();
  const [openTransformModal, setOpenTransformModal] = useState(false);
  const getQueryData = async (limit = 10) => {
    if (analyticQuery && isValidAnalyticQuery(analyticQuery)) {

      dispatchForm({name: 'isLoading', value: State.PENDING});

      let actions = [...analyticQuery.actions];

      // actions = actions.map(action => {
      //
      //   if (action.type === 'transform' && action.transformation && !isActionDate(action)) {
      //     const transformation = (action.transformation as string).replaceAll(' ', '');
      //     const regex = new RegExp(`(${checkSpecialCharactersForRegex(filterMathOperator).join('|')})`);
      //     const columns = getActionColumns(action);
      //     return {
      //       ...action, transformation: transformation.split(regex).map(str => {
      //         if (columns.some(column => column.value === str)) {
      //           return 'pdf.' + str;
      //         }
      //
      //         return str;
      //       }).join(' ')
      //     };
      //   }
      //
      //   return action;
      // });

      const res = await executeAnalyticJson({
        ...analyticQuery,
        actions: [...actions.flat(), {type: 'limit', top: limit} as AnalyticQueryAction]
      } as BaseAnalyticQuery);
      if (res?.success && res.items) {
        dispatchForm({name: 'isLoading', value: State.RESOLVED});
        toast({message: 'Query was made successfully'});
        return res.items;
      } else {
        dispatchForm({name: 'isLoading', value: State.REJECTED});
        // toast({type: 'error', message: res?.error || 'An error occurred'});
        toast({type: 'error', message: (res?.error as any)?.message[0] ?? 'An error occurred'});
      }
    } else {
      // toast({message: 'Please select a valid model to make an action.', type: 'warn'});
    }
  };

  const getAllColumns = () => {
    getAllSourcesColumns(analyticQuery);
    getAllActionColumns(analyticQuery);
  };

  const toggleTransformComponent = () => setOpenTransformModal(prevState => !prevState);

  const handleData = async () => {
    const data = await getQueryData();
    if (data) {
      dispatchForm({name: 'results', value: data});
    }
  };

  return (
    <div className="d-flex flex-column">
      <div className="mb-2 gap-2 d-flex justify-content-end">
        <Tooltip title={t("run-query")}>
          <Button color="success" onClick={handleData} disabled={isLoading === State.PENDING}>
            <FontAwesomeIcon pulse={isLoading === State.PENDING}
                             icon={isLoading === State.PENDING ? faSpinner : faPlay}/>
          </Button>
        </Tooltip>

        <Tooltip title={t("preview-query")}>
          <Button color="info" onClick={togglePreviewQuery} disabled={isLoading === State.PENDING}>
            <FontAwesomeIcon pulse={isLoading === State.PENDING}
                             icon={isLoading === State.PENDING ? faSpinner : faCode}/>
          </Button>
        </Tooltip>

        <Tooltip title={t("transform-query")}>
          <Button color="secondary" onClick={toggleTransformComponent} disabled={isLoading === State.PENDING}>
            <FontAwesomeIcon pulse={isLoading === State.PENDING}
                             icon={isLoading === State.PENDING ? faSpinner : faExchangeAlt}/>
          </Button>
        </Tooltip>


        <Tooltip title={t("refresh-column")}>
          <Button color="success" onClick={getAllColumns}
                  disabled={isLoading === State.PENDING || !isValidAnalyticQuery(analyticQuery)}>
            <FontAwesomeIcon pulse={isLoading === State.PENDING}
                             icon={isLoading === State.PENDING ? faSpinner : faSyncAlt}/>
          </Button>

        </Tooltip>

        <Tooltip title={t("reset-values")}>
          <Button color="danger" onClick={() => {
            dispatch(actionsModal.openModal({
              type: ModalTypes.CONFIRM,
              onConfirm: () => {
                resetDefaultValues()
                dispatch(actionsModal.closeModal({
                  type: ModalTypes.CONFIRM
                }))
              },
              message: <>Are you sure to reset the query for default values?</>,
              title: <><FontAwesomeIcon
                icon={faTrash}/> Reset default values</>,
            }))
          }}>
            <FontAwesomeIcon icon={faTimes}/>
          </Button>
        </Tooltip>
      </div>
      <div className="row">
        <div className="col-xs-12 col-lg-6">
          <SourceComponent/>
        </div>

        <div className="col-xs-12 col-lg-6">
          <ActionsComponent/>
        </div>
      </div>

      {openTransformModal && <JsonToQueryComponent toggle={toggleTransformComponent}/>}
    </div>
  );
};

export default ManageDataComponent;
