import React, {useContext} from 'react';
import useTranslate from '../../../hooks/useTranslate';
import {IsJsonString, toast} from "../../../utils";
import {State} from "../../../types/State";
import {ReportGeneratorContext} from "../ReportGeneratorComponent";
import TabContents, {Tab} from "../../Shared/TabContents";
import PreviewChartResultComponent from "./PreviewChartResultComponent";
import {updateModelRow} from "../../../services/backend/SbxService";
import {BaseAnalyticQuery} from "../../../types/Analytic";
import ReportComponent from "../../ReportsComponent/ReportComponent";
import {getAnalyticQueryFlat, getQueryData} from "../../../utils/analyticsUtils";

const PreviewResultsComponent = () => {
    const {t} = useTranslate('custom-message');
    const {
        reportState: {analyticQuery, report, reportLoading, results}, dispatchForm, dispatchMultiForm
    } = useContext(ReportGeneratorContext);

    const getData = async ({
                               limit = 10,
                               query
                           }: { limit?: number | null, query?: BaseAnalyticQuery}) => {

        dispatchForm({name: 'reportLoading', value: {...reportLoading, ["results"]: State.PENDING}});
        let nQuery = query ?? getAnalyticQueryFlat({
            actions: [...analyticQuery.actions],
            source: {...analyticQuery.source},
            preview: true
        })

        const items = await getQueryData({limit, analyticQuery: {...nQuery as BaseAnalyticQuery}})

        // if (items?.length > 0) {
        dispatchMultiForm([{
            name: 'reportLoading',
            value: {...reportLoading, ["results"]: State.RESOLVED}
        }, {name: 'results', value: items}]);
        // } else {
        //     dispatchForm({name: 'reportLoading', value: {...reportLoading, ["results"]: State.REJECTED}});
        // }
    }


    React.useEffect(() => {
        getData({})
    }, []);

    const tabs: Tab[] = [{
        label: t("common:table-view"),
        component: <ReportComponent launchQuery={report => {
            if (IsJsonString(report.query)){
                const query = JSON.parse(report.query)
                getData({query: {...query, preview: true}})
            }

        }} query={getAnalyticQueryFlat(analyticQuery)} reportProps={{
            columns: results.length > 0 ? Object.keys(results[0]).filter(key => {
                if (typeof results[0][key] !== 'object') {
                    return true;
                } else {
                    if (!results[0][key]) {
                        return true
                    }
                }
            }).map(key => ({
                name: key,
                header: key
            })) : []
        }} data={results} isLoading={reportLoading.results === State.PENDING}/>
        // component: <CustomTableComponent data={results} loading={reportLoading.results === State.PENDING}
        //                                  columns={results.length > 0 ? Object.keys(results[0]).filter(key => {
        //                                      if (typeof results[0][key] !== 'object') {
        //                                          return true;
        //                                      } else {
        //                                          if (!results[0][key]) {
        //                                              return true
        //                                          }
        //                                      }
        //                                  }).map(key => ({
        //                                      name: key,
        //                                      header: key
        //                                  })) : []}/>
    },
        {
            label: t("common:graph-view"),
            component: <PreviewChartResultComponent type={report?.type}
                                                    setReport={async data => {


                                                        if (report?._KEY) {
                                                            dispatchMultiForm([{
                                                                name: "report",
                                                                value: {...report, ...data}
                                                            }, {
                                                                name: "reportLoading", value: {
                                                                    ...reportLoading, ["results"]: State.PENDING
                                                                }
                                                            }])
                                                            const res = await updateModelRow([{
                                                                _KEY: report._KEY,
                                                                metadata: data.metadata,
                                                                type: data.type
                                                            }], "sbx_crm_report")
                                                            if (res?.success) {
                                                                dispatchForm({
                                                                    name: "reportLoading", value: {
                                                                        ...reportLoading, ["results"]: State.RESOLVED
                                                                    }
                                                                })
                                                                toast({
                                                                    type: "success",
                                                                    message: `${t("common:report")} ${t("common:updated")}`
                                                                })
                                                            } else {
                                                                dispatchForm({
                                                                    name: "reportLoading", value: {
                                                                        ...reportLoading, ["results"]: State.REJECTED
                                                                    }
                                                                })

                                                                toast({
                                                                    type: "error",
                                                                    message: `${t("common:rejected_message")}" ${res?.error ?? ""}`
                                                                })
                                                            }
                                                        } else {
                                                            dispatchForm({name: "report", value: {...report, ...data}})
                                                        }

                                                    }}
                                                    metadata={report?.metadata && IsJsonString(report.metadata as string)
                                                        ? JSON.parse(report?.metadata as string) : ""}
                                                    isReportView
                                                    getQueryData={({limit, hierarchy}) => getData({limit})}
                                                    loading={reportLoading.results === State.PENDING}
                                                    results={results}
                                                    columns={results.length > 0 ? Object.keys(results[0]).map((column) => ({
                                                        label: column,
                                                        value: column
                                                    })) : []}/>
        }
    ]

    return (
        <div className="d-flex flex-column gap-2" style={{maxWidth: "100vw"}}>
            <div className="alert alert-info" role="alert">
                {t('report-result-info')}
            </div>

            <TabContents tabs={tabs}/>
        </div>
    );
};

export default PreviewResultsComponent;
