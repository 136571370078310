import * as React from 'react';
import {useCallback, useContext, useState} from 'react';
import {AnalyticQueryAction} from "../../../types/Analytic";
import useTranslate from "../../../hooks/useTranslate";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlusCircle, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import NewSelectActionComponent from "./NewSelectActionComponent";
import NewLimitActionComponent from "./NewLimitActionComponent";
import NewSortActionComponent from "./NewSortActionComponent";
import ReportSummarizeComponent from "./NewReportSummarizeComponent/ReportSummarizeComponent";
import ReportFilterComponent from "../ReportFilterComponent";
import NewTransformDataActionComponent from "./NewTransformDataComponent/NewTransformDataActionComponent";
import NewMergeActionComponent from "./NewMergeActionComponent";
import NewDateTransformActionComponent from "./NewDateTransformComponent/NewDateTransformActionComponent";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import NewRenameActionComponent from "./NewRenameActionComponent/NewRenameActionComponent";
import NewSaveActionComponent from "./NewSaveActionComponent";
import NewDateAnalysisActionComponent from "./NewDateAnalysisActionComponent";
import DefaultActionComponent from "./DefaultActionComponent";
import NewDefaultValueComponent from "./NewDefaultValueComponent/NewDefaultValueComponent";
import NewBinaryClassifierActionComponent from "./NewMachineLearningActions/NewBinaryClassifierActionComponent";
import NewSegmentationActionComponent from "./NewMachineLearningActions/NewSegmentationActionComponent";
import NewMonetarySegmentationComponent from "./NewMachineLearningActions/NewMonetarySegmentationComponent";
import NewForecastComponent from "./NewMachineLearningActions/NewForecastComponent";
import {NumberOption, StringOption} from "../../../types/Select";
import {ReportContext} from "../NewReportGeneratorComponent";
import ActionDropdownColumns from "./ActionDropdownComponent/ActionDropdownColumns";
import {DropdownItem, Offcanvas, OffcanvasBody, OffcanvasHeader} from "reactstrap";
import ColumnsActionsWrapperComponent from "../ColumnsActionsWrapperComponent";
import AsyncMlActionComponent from "./NewMachineLearningActions/AsyncMlActionComponent";
import BehaviorValidatorActionComponent from "./BehaviorValidatorAction/BehaviorValidatorActionComponent";
import styled from "styled-components";
import SelectComponent from "../../Shared/FieldComponents/SelectComponent";
import ButtonComponent from "../../Shared/ButtonComponent";
import {CSSObject} from "@emotion/serialize";
import {ContainerProps} from "react-select";
import cogoToast from "cogo-toast";

type Props = {
    actionList: { label: string, color: string, textColor?: string, icon: IconProp, value: string }[]
    removeAction: (index: number) => void
    actions: AnalyticQueryAction[]
    isReportGenerator?: boolean
    actionListWrapper?: (actionIndexSelected: number) => React.ReactNode
}

const customDateFromatterOp = ["@formateddate_to_date", "@date_to_formateddate"]
const IconContainer = styled.div`
        position: relative;
        width: 100%; /* Ajusta el tamaño según sea necesario */
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &:hover {
            background-color: #f8f9fa;
            margin: 5px 0;

            .text {
                opacity: 1;
            }

            .icon {
                opacity: 1;
                transform: scale(1);
                width: 25px;
                padding: 10px;
                height: 25px;
                cursor: pointer;
            }


        }
    `;

const StyledIcon = styled(FontAwesomeIcon)`
        opacity: 0;
        transform: scale(0);
        transition: opacity 0.3s, transform 0.3s;
    `;
const NewActionsReportGeneratorComponent = ({
                                                actionList,
                                                removeAction,
                                                actions,
                                                isReportGenerator = true,
                                                actionListWrapper
                                            }: Props) => {

    const {t} = useTranslate("report")
    const {setQueryAction, query, setQuery} = useContext(ReportContext)
    const [showActions, setShowActions] = useState(false)
    const [hierarchy, setHierarchy] = useState<NumberOption[]>([])
    const [hierarchyActionIdx, setHierarchyActionIdx] = useState(-1)
    const getTransformSubtypeByType = (action: AnalyticQueryAction, index: number, color?: string) => {
        if (customDateFromatterOp.some(op => (action.transformation as string)?.includes(op)) && !action.subtype) {
            action.subtype = "data_transform"
            // action.subtype = "data_transform"
        }
        if (action.subtype) {
            const actionType = ({
                date_formatter: <NewDateTransformActionComponent action={action} index={index} color={color}/>,
                data_transform: <NewTransformDataActionComponent action={action} index={index} color={color}/>,
            } as { [key: string]: React.ReactNode })[action.subtype] ?? null

            if (actionType) {
                return actionType
            } else {
                return <DefaultActionComponent action={action} indexAction={index}/>
            }
        }

        return <NewTransformDataActionComponent action={action} index={index} color={color}/>
    }
    const [actionIndexSelected, setActionIndexSelected] = useState<null | number>(null)
    const [moveActionIndexSelected, setMoveActionIndexSelected] = useState<null | number>(null)
    const [moveExistingAction, setMoveExistingAction] = useState(false)
    const getMlSubtypeByType = (action: AnalyticQueryAction, index: number, color?: string) => {
        if (action.subtype) {
            const actionType = ({
                binary_classifier: <NewBinaryClassifierActionComponent action={action} index={index}/>,
                segmentation: <NewSegmentationActionComponent action={action} index={index}/>,
                monetary_segmentation: <NewMonetarySegmentationComponent action={action} index={index}/>,
                forecast: <NewForecastComponent action={action} index={index}/>

            } as { [key: string]: React.ReactNode })[action.subtype] ?? null

            if (actionType) {
                return actionType
            } else {
                return <DefaultActionComponent action={action} indexAction={index}/>
            }
        }

        return <div/>
    }

    const getAction = useCallback((action: AnalyticQueryAction, index: number, color?: string) => {
        let contentAction = null

        switch (action.type) {

            case "sort": {
                contentAction = <NewSortActionComponent index={index} action={action}/>
            }
                break;
            case "select": {
                contentAction = <NewSelectActionComponent index={index} action={action}/>
            }
                break
            case "limit": {
                contentAction = <NewLimitActionComponent index={index} action={action}/>
            }
                break
            case "rename": {
                contentAction = <NewRenameActionComponent index={index} action={action} color={color}/>
            }
                break
            case "group_by": {
                contentAction = <ReportSummarizeComponent isReportGenerator={isReportGenerator} color={color}
                                                          isDrillDown={action.subtype === "drill_down"}
                                                          indexAction={index} queryAction={action}/>
            }
                break
            case "pivot": {
                contentAction =
                    <ReportSummarizeComponent color={color} isPivotTable indexAction={index} queryAction={action}/>
            }
                break;
            case "date_analysis": {
                contentAction = <NewDateAnalysisActionComponent color={color} indexAction={index} queryAction={action}/>
            }
                break
            case "transform": {
                contentAction = getTransformSubtypeByType(action, index, color) ?? <div/>
            }
                break;

            case "behavior_validator": {
                contentAction = <BehaviorValidatorActionComponent action={action} indexAction={index} color={color}/>
            }
                break;
            case "filter": {
                contentAction = <ReportFilterComponent color={color} indexAction={index} queryAction={action}/>
            }
                break
            case "default_values": {
                contentAction = <NewDefaultValueComponent index={index} action={action} color={color}/>
            }
                break;
            case "merge": {
                contentAction = <NewMergeActionComponent color={color} action={action} index={index}/>
            }
                break;
            case "ml": {
                contentAction = getMlSubtypeByType(action, index, color) ?? <div/>
            }
                break;

            case "async_ml": {
                contentAction = <AsyncMlActionComponent action={action} indexAction={index} color={color}/>
            }
                break;
            case "save": {
                contentAction = <NewSaveActionComponent index={index} action={action}/>
            }

                break
            default: {
                contentAction = <DefaultActionComponent action={action} indexAction={index}/>
                break;
            }

        }

        return contentAction
    }, [])

    const getActionByType = (action: AnalyticQueryAction, actionListItem: StringOption) => {
        if (action.subtype && actionListItem.value === action.subtype) {
            return true
        }

        if (actionListItem.value === action.type) {
            return true
        }

        if (action.type === "transform" && actionListItem.value === "data_transform" && !action.subtype) {
            return actionListItem
        }

        return null
    }

    React.useEffect(() => {

        if (actions.length > 0) {
            // const action = actions.flat().find(action => action.subtype === 'drill_down')
            const actionIndex = actions.flat().findIndex(action => action.subtype === 'drill_down')
            if (actionIndex !== -1) {

                const action = actions.flat()[actionIndex]
                setHierarchyActionIdx(actionIndex)
                if (action && action.hierarchy) {
                    setHierarchy([{label: t("common:all"), value: -1},
                        ...action.hierarchy.map((h, i) => ({label: h, value: i}))])
                }

            } else {
                setHierarchy([])
            }
        }

    }, [actions]);

    React.useEffect(() => {
        return () => {
            setShowActions(false)
            setMoveExistingAction(false)
            setMoveActionIndexSelected(null)
            setActionIndexSelected(null)
        }
    }, []);

    const getActionTitle = (action: AnalyticQueryAction) => {

        if (action.type === "async_ml") {
            return t(action.type)
        }

        if (action.subtype === 'date_operations') {
            action.subtype = 'data_transform'
        }


        return t(action.subtype ?? action.type)
    }



    const moveActionsQuery = ({new_index, old_index, actions}: {
        new_index: number,
        old_index: number,
        actions: AnalyticQueryAction[][]
    }) => {

        const previousActions = [...actions];

        let currentAction = previousActions[old_index];

        previousActions.splice(old_index, 1);
        previousActions.splice(new_index, 0, currentAction);

        setQuery({
            ...query,
            actions: previousActions
        })

        return previousActions
    }

    return (
        <div>
            {
                actions.map((action, index) => {

                    const nActionList = actionList.find(actionListItem => getActionByType(action, actionListItem))

                    return (
                        <div key={action.type + "_" + index + "_" + action.temporal_id}>
                            <div
                                 className={`border-bottom  card ${action.baseQuery ? "d-none" : ""}`}>
                                <div className="d-flex justify-content-between align-items-center">
                                    <div
                                        className="card-header d-flex justify-content-between align-items-center flex-grow-1"
                                        style={{
                                            color: nActionList?.color ?? "black",
                                        }}>
                                        <div className="d-flex align-items-center gap-2">
                                            {nActionList?.icon &&
                                                <FontAwesomeIcon icon={nActionList?.icon} className="pointer"/>}
                                            <span>{getActionTitle(action)}</span>
                                            <FontAwesomeIcon icon={faTimesCircle} className="pointer"
                                                             onClick={() => {
                                                                 // const actions = [...query.actions]
                                                                 //
                                                                 // if (actions.length > 0 && actions[0][0].type === "group_by") {
                                                                 //     actions.shift()
                                                                 //     setQuery(query => ({
                                                                 //         ...query,
                                                                 //         actions
                                                                 //     }))
                                                                 // }


                                                                 removeAction(index)
                                                             }}/>
                                        </div>

                                        <span>
                                        ID: {index + 1}
                                        </span>
                                    </div>
                                </div>

                                <div className="p-3" style={{backgroundColor: nActionList?.color + "30" ?? "white",}}>
                                    {getAction(action, index, nActionList?.color)}

                                    {index > hierarchyActionIdx && action.subtype !== "drill_down" && hierarchy.length > 0 &&
                                        <div className="mt-3 border-top d-flex align-items-start flex-column">
                                            <b>{t("drill_down_execution_level")}</b>
                                            <div className="w-50">
                                                <ActionDropdownColumns color={nActionList?.color ?? ""}
                                                                       onChange={() => {
                                                                       }} columns={[]}
                                                                       label={hierarchy?.find((_, index) => index === action.drill_down_execution_index)?.label ?? t("common:select_placeholder")}>
                                                    <ColumnsActionsWrapperComponent>
                                                        {search => {
                                                            return <>
                                                                {hierarchy.filter(column => column.label.toLowerCase().includes(search.toLowerCase())).map((column, indexColumn) => {
                                                                    return <DropdownItem onClick={() => {
                                                                        setQueryAction({
                                                                            ...action,
                                                                            drill_down_execution_index: indexColumn
                                                                        }, index)
                                                                    }}>
                                                                        {column.label}
                                                                    </DropdownItem>
                                                                })}
                                                            </>
                                                        }}
                                                    </ColumnsActionsWrapperComponent>
                                                </ActionDropdownColumns>
                                            </div>
                                        </div>}

                                </div>
                            </div>
                            <IconContainer>
                                <div className="d-flex align-items-center underline" onClick={() => {
                                    setShowActions(true)
                                    setActionIndexSelected(index)
                                    setMoveActionIndexSelected(null)
                                }}>
                                    <StyledIcon icon={faPlusCircle} size="1x" className="icon"/>
                                    <span className="text">
                                        {t("new_action")}
                                    </span>
                                </div>

                                <div className="d-flex align-items-center underline" onClick={() => {
                                    setShowActions(true)
                                    setActionIndexSelected(index)
                                    setMoveExistingAction(true)
                                }}>
                                    <StyledIcon icon={faPlusCircle} color={"blue"} size="1x" className="icon "/>
                                    <span className="text">
                                        {t("existing_action")}
                                    </span>
                                </div>
                            </IconContainer>
                        </div>

                    )
                })
            }

            <Offcanvas
                zIndex={3000}
                key={"actions_offcanvas_"+(query?.source?.temporal_id ?? "")}
                backdrop={false}
                direction="bottom"
                scrollable
                isOpen={showActions}
            >
                <OffcanvasHeader toggle={() => {
                    setShowActions(prevState => !prevState)
                    setActionIndexSelected(null)
                    setMoveExistingAction(false)
                }}>
                    {t("common:actions")} {actionIndexSelected !== null ? `${t("common:selected")} - ID: ${actionIndexSelected + 1}` : null}
                </OffcanvasHeader>
                <OffcanvasBody>
                    {moveExistingAction ? <div className="d-flex flex-column">
                            <span>{t("select")} {t("common:action")}</span>
                            <div className="d-flex align-items-center gap-2">
                                <SelectComponent id={"action_select"} styles={{
                                    container(base: CSSObject, props: ContainerProps<any, any, any>): CSSObject {
                                        return {
                                            ...base,
                                            width: "250px"
                                        }
                                    }
                                }} sortOptions={false}
                                                 onChange={evt => {
                                                     setMoveActionIndexSelected(evt.value)
                                                 }}
                                                 name={"action_select"} options={[...actions].map((action, index) => {
                                    return {
                                        label: `${getActionTitle(action)} - ID: ${index + 1}`,
                                        value: index
                                    }
                                }).filter(item => item.value !== actionIndexSelected)}/>

                                <div>
                                    <ButtonComponent label={t("common:apply")} color={"primary"} onClick={() => {
                                        if (moveActionIndexSelected !== null && actionIndexSelected !== null) {
                                            const previousActions = [...query.actions]

                                           const newActions = moveActionsQuery({
                                                new_index: actionIndexSelected,
                                                old_index: moveActionIndexSelected,
                                                actions: previousActions
                                            })

                                            const {hide} = cogoToast.info(<div className="d-flex align-items-center gap-2">
                                                <span>
                                                    {t("custom-message:15_seconds_to_undone")}
                                                </span>

                                                <u className="fw-bold text-link" onClick={() => {
                                                    moveActionsQuery({
                                                        new_index: moveActionIndexSelected,
                                                        old_index: actionIndexSelected,
                                                        actions: newActions
                                                    })
                                                }}>
                                                    {t("common:undone")}
                                                </u>
                                            </div>, {
                                                hideAfter: 15,
                                                position: 'bottom-left',
                                                onClick: () => {
                                                    if (hide) {
                                                        hide();
                                                    }
                                                },
                                            });


                                            // Close all and reset values
                                            setShowActions(false)
                                            setMoveExistingAction(false)
                                        }
                                    }} disabled={moveActionIndexSelected === null}/>
                                </div>
                            </div>


                        </div>
                        : typeof actionIndexSelected === 'number' ? actionListWrapper && actionListWrapper(actionIndexSelected) : null}
                </OffcanvasBody>
            </Offcanvas>
        </div>
    );
};

export default NewActionsReportGeneratorComponent