import * as React from 'react';
import {useContext, useState} from 'react';
import {Source, SourceFrom} from "../../../types/Analytic";
import {GroupCondition, ModelsResponse, SbxConditionType, SbxModelField} from "../../../types/Sbx";
import {State} from "../../../types/State";
import useAsyncEffect from "../../../hooks/useAsyncEffect";
import {getSchema} from "../../../services/backend/AnalyticsService";
import SbxEventQueryComponent from "./SbxEventQueryComponent";
import SbxWorkflowQueryComponent from "./SbxWorkflowQueryComponent";
import SbxUserQueryComponent from "./SbxUserQueryComponent";
import {getParseToken} from "../../../services/UtilsService";
import {ReportGeneratorContext} from "../ReportGeneratorComponent";
import {
    Control,
    FieldArrayWithId,
    useFieldArray,
    UseFieldArrayAppend,
    UseFieldArrayRemove,
    useForm,
    UseFormWatch
} from "react-hook-form";
import {faSyncAlt} from "@fortawesome/free-solid-svg-icons";
import useTranslate from "../../../hooks/useTranslate";
import DropdownItemsOptions from "../../Shared/DropdownItemsOptions/DropdownItemsOptions";

type FormValues = {
    row_model: string,
    where: GroupCondition[]
}

type Props = {
    type: SourceFrom
    source: Source
    setQueryData: (data: FormValues) => void
};

export const typesValidation: { [key: string]: SbxModelField | string } = {
    "NUMBER": SbxModelField.FLOAT,
    [SbxConditionType.IN]: "ARRAY_STRING"
}

type QueryContextProps = {
    fields: FieldArrayWithId<{} | ((formValues: FormValues) => FormValues) | FormValues, 'where', "id">[]
    remove: UseFieldArrayRemove
    append: UseFieldArrayAppend<FormValues, "where">
    models: ModelsResponse[]
    control?: Control<FormValues, any>
    watch?: UseFormWatch<FormValues>
    loading: boolean
};

export const RenderQueryContext = React.createContext<QueryContextProps>({
    models: [],
    append: value => {
    },
    remove: index => {
    },
    fields: [],
    loading: false,

});


let cacheSchema: { [key: string]: { [from: string]: ModelsResponse[] } } = {}

const RenderReportQueryComponent = ({source, type, setQueryData}: Props) => {

    const [models, setModels] = useState<ModelsResponse[]>([])
    const [loading, setLoading] = useState(State.IDLE)

    const {t} = useTranslate('common');
    const {
        reportState: {queriesSource},
    } = useContext(ReportGeneratorContext);

    const {control, watch} = useForm<FormValues>({
        defaultValues: {
            row_model: queriesSource && source?.temporal_id ? queriesSource[source.temporal_id]?.row_model : undefined,
            where: queriesSource && source?.temporal_id ? queriesSource[source.temporal_id]?.where[0]?.GROUP : undefined,
        }
    })

    const {fields, append, remove} = useFieldArray({
        name: "where",
        control
    });

    React.useEffect(() => {
        const subscription = watch((value) => {
            setQueryData(value as FormValues)
        });
        return () => subscription.unsubscribe();
    }, [watch]);


    const getScheme = async () => {
        setLoading(State.PENDING)

        const nSource = {...source}

        const token = localStorage.getItem('crm_token');


        if (token && (type === SourceFrom.SBX_WORKFLOW || type === SourceFrom.SBX_CRM_USER)) {
            nSource['override_auth'] = getParseToken(token);
        }
        let res: { [key: string]: ModelsResponse[] }
        if (cacheSchema[nSource.with] && cacheSchema[nSource.with][nSource.from]) {
            res = cacheSchema[nSource.with]
        } else {
            res = await getSchema([{
                ...nSource
            }]);
        }

        if (res) {
            if (res[nSource.from]?.length > 0) {
                if (!cacheSchema[nSource.with]) {
                    cacheSchema[nSource.with] = {[nSource.from]: res[nSource.from]}
                }
                setModels(res[nSource.from])
                setLoading(State.RESOLVED)
            } else {
                setLoading(State.REJECTED)
            }
        } else {
            setLoading(State.REJECTED)
        }
    }

    useAsyncEffect(async () => {
        getScheme()
    }, [type, source])

    const getComponent = () => {
        switch (type) {
            case SourceFrom.SBX_EVENT:
                return <SbxEventQueryComponent/>
            case SourceFrom.SBX_WORKFLOW:
                return <SbxWorkflowQueryComponent/>
            case SourceFrom.SBX_CRM_USER:
                return <SbxUserQueryComponent/>
            default:
                return null
        }
    }


    return <RenderQueryContext.Provider
        value={{models, loading: loading === State.PENDING, append, fields, remove, control, watch}}>
        <div className="d-flex align-items-center justify-content-end">
            {/*<ButtonComponent color={"default"} sizeIcon={"2x"} icon={faArrowsAltV} label={""}/>*/}


            <DropdownItemsOptions items={[{
                label: t("reload"), icon: faSyncAlt, onClick: () => {
                    cacheSchema = {}
                    getScheme()
                }
            }]}/>
        </div>
        {getComponent()}
    </RenderQueryContext.Provider>
};

export default RenderReportQueryComponent