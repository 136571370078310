import React, { useEffect, useState } from "react";
import SideBar from "../../LayoutComponenents/SideBar";
import routes, {Route, ViewFormat, ViewProps} from "../../../routes/routes";
import {
  SubMenuItem,
  SubMenuProps,
} from "../../LayoutComponenents/types/SideBar";
import { useRouter } from "next/router";
import { grantPermission, IsJsonString } from "../../../utils";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/Reducers";
import useTranslate from "../../../hooks/useTranslate";
import { Permissions, PermissionType } from "../../../types/Permissions";
import useIgnoreFirstEffect from "../../../hooks/useIgnoreFirstEffect";
import HeadDynamicComponent from "./HeadDynamicComponent";
import { routerActions } from "../../../store/RouterReducer";
import { AnyData } from "../../../types/AnyData";

type Props = {
  children: any;
  sidebarOpen: boolean;
};

export type TabsNames = "data" | "proceedings";
export type TabsNamesArray = "bulk_updates";

export const tabsRegisteredName: (TabsNames | TabsNamesArray)[] = [
  "data",
  "proceedings",
  "bulk_updates",
];

const SideBarContent = ({ children, sidebarOpen }: Props) => {
  const history = useRouter();
  const { t } = useTranslate("routes");
  const dispatch = useDispatch();
  const [userRoutes, setUserRoutes] = useState(routes);
  const [expanded, setExpanded] = useState("");
  const {
    user: {
      permissionsMapper,
      config,
      domains,
      permissions,
      role
    },
  } = useSelector((state: RootState) => state.AuthReducer);

  React.useEffect(() => {
    if (config && config?.sbx_crm?.routes) {
      const configRoutes = userRoutes.reduce((routes: Route[], route) => {
        const newRoute = { ...route };

        if (config?.sbx_crm?.routes) {
          const nRoute = config.sbx_crm.routes.find(
            (cRoute) => cRoute.name === newRoute.name,
          );
          if (nRoute) {
            newRoute.name = nRoute.name;
            if (nRoute.permission) {
              newRoute.permission = nRoute.permission;
            }

            newRoute.hide = nRoute.hide ?? false;

            newRoute.views = newRoute.views?.reduce(
                (views: ViewProps[], view) => {
                  if (nRoute.views) {

                    const nView = nRoute.views.find(
                        (cView) => cView.name === view.name,
                    );

                    if (nView) {
                      views.push(nView);
                    } else {
                      views.push(view);
                    }
                  } else {
                    views.push(view);
                  }

                  return views;
                },
              [],
            );
          }

          if (nRoute?.views) {
            nRoute.views.forEach(view => {
              if (!newRoute.views?.some(v => v.name === view.name)) {
                newRoute.views?.push(view)
              }
            })
          }


          routes.push(newRoute);
        }

        return routes;
      }, []);
      // setUserRoutes(config.sbx_crm.routes);

      const newRoutes = config.sbx_crm.routes.filter(
        (nRoute) =>
          !configRoutes.some((cRoute) => cRoute.name === nRoute.name) &&
          nRoute.views &&
          nRoute.views.length > 0 &&
          nRoute.views.every((view) => view.name && view.root && view.path),
      );

      setUserRoutes(
        [...configRoutes, ...newRoutes].filter((route) => !route.hide),
      );
    }
  }, [config]);

  useEffect(() => {
    if (
      config &&
      config?.sbx_crm!?.data &&
      Object.keys(config?.sbx_crm!?.data).length > 0
    ) {
      const domainsAvailable = domains.map((domain) => domain.id);

      let allowDataManagerKeys: string[] = [];

      const permission_data_manager_read = permissions.find(
        (permission) =>
          permission.module_name === Permissions.DATA_MANAGER &&
          permission.permission === PermissionType.READ,
      );

      if (
        permission_data_manager_read?.metadata &&
        Array.isArray(permission_data_manager_read?.metadata)
      ) {
        permission_data_manager_read.metadata.forEach((meta) => {
          if (
            IsJsonString(meta) &&
            JSON.parse(meta).allow &&
            JSON.parse(meta).allow.length > 0
          ) {
            const allow = JSON.parse(meta).allow;
            allowDataManagerKeys = allowDataManagerKeys.concat(
              allow.filter(
                (route: string | number) =>
                  typeof route === "string" && route !== "all",
              ),
            );
          }
        });
      }

      setUserRoutes((routes) =>
        routes
          .filter((route) =>
            route.domain
              ? domainsAvailable.some((d) =>
                  (route.domain ?? []).some((d1) => d === d1),
                )
              : true,
          )
          .map((rou) => {
            let route = Object.assign({}, rou);
            const tab = tabsRegisteredName.find((t) => route.name === t);

            if (tab) {
              let configData: AnyData = config.sbx_crm[tab];

             const tabRoute = config.sbx_crm?.routes?.find((r) => r.name === route.name)

              if(tabRoute){
                route.permission = tabRoute.permission
              }

              if (configData) {
                route.views = Object.keys(configData)
                  .filter((key) => key !== "root" && key !== "cs")
                  .filter((key) => !configData[key].tag)
                  .map((data) => {
                    if (
                      configData[data].items &&
                      configData[data].items.length > 0
                    ) {
                      return {
                        name: data,
                        label: data,
                        root: "/",
                        path: ``,
                        permission:
                          configData[data].permission ??
                          Permissions.NO_VALIDATE,
                        views: configData[data].items
                          .filter((item: Route) =>
                            Array.isArray(item.permission)
                              ? item.permission.some((permission) =>
                                  checkAllowMetadataPermission(
                                    permission,
                                    item.key ?? "",
                                  ),
                                )
                              : checkAllowMetadataPermission(
                                  item.permission,
                                  item.key ?? "",
                                ),
                          )
                          .filter((item: Route) =>
                            allowDataManagerKeys.length > 0
                              ? allowDataManagerKeys.includes(item.name)
                              : true,
                          )
                          .map((item: Route) => {
                            return {
                              name: item.name,
                              label: item.name,
                              root: "",
                              path: (item.path ?? "") + item.key,
                              permission: item.permission,
                            };
                          }),
                      };
                    } else {
                      if (Array.isArray(configData[data])) {
                        return {
                          name: data,
                          label: t(data),
                          root: configData.root
                            ? `/${configData.root}`
                            : "/config",
                          path: `/${tab}`,
                          permission: Permissions.NO_VALIDATE,
                          views: configData[data]
                            .filter((key: string) =>
                              allowDataManagerKeys.length > 0
                                ? allowDataManagerKeys.includes(key)
                                : true,
                            )
                            .map((key: string) => {
                              const name = configData[key].label ?? key;
                              return {
                                label: name,
                                root: configData.root
                                  ? `/${configData.root}`
                                  : "/config",
                                name: name,
                                path: `/${tab}/${key}`,
                                permission: Permissions.NO_VALIDATE,
                              };
                            }),
                        };
                      }
                    }

                    if (
                      allowDataManagerKeys.length > 0 &&
                      !allowDataManagerKeys.includes(data)
                    ) {
                      return {
                        name: "",
                        label: "",
                        root: "",
                        path: "",
                        views: [],
                        permission: "",
                      };
                    }

                    return {
                      name: configData[data].label,
                      root: configData[data].root
                        ? `/${configData[data].root}`
                        : "/config",
                      path: `/${tab}/${
                        configData[data].root
                          ? `${configData[data].label}/${
                              configData[data].cs ?? configData[data].name
                            }`
                          : data
                      }`,
                      permission: Permissions.NO_VALIDATE,
                    };
                  })
                  .filter(
                    (r) =>
                      r.name &&
                      grantPermission(r.permission, permissionsMapper ?? {}) &&
                      (r.views ? r.views.length > 0 : true),
                  );
              } else {
                route.views = [];
              }
            } else {
              route.views = route.views
                ?.filter((r) =>
                  grantPermission(r.permission, permissionsMapper ?? {}),
                )
                .filter((route) =>
                  route.domain
                    ? domainsAvailable.some((d) =>
                        (route.domain ?? []).some((d1) => d === d1),
                      )
                    : true,
                );
            }
            return route;
          })
          .filter((r) => grantPermission(r.permission, permissionsMapper ?? {}))
          .filter((r) => r.views?.length),
      );
    } else {
      const domainsAvailable = domains.map((domain) => domain.id);
      setUserRoutes((routes) =>
        routes
          .filter((route) => {
            if (route.domain) {
              return domainsAvailable.some((d) =>
                (route.domain ?? []).some((d1) => d === d1),
              );
            } else {
              return true;
            }
          })
          .map((rou) => {
            let route = Object.assign({}, rou);
            route.views = route.views
              ?.filter((r) =>
                grantPermission(r.permission, permissionsMapper ?? {}),
              )
              .filter((route) =>
                route.domain
                  ? domainsAvailable.some((d) =>
                      (route.domain ?? []).some((d1) => d === d1),
                    )
                  : true,
              );

            route.views = route.views?.filter((r) => {
              if (r.checkAllowMetadata) {
                if (Array.isArray(r.permission)) {
                  return r.permission.some((permission) =>
                    checkAllowMetadataPermission(permission, r.name),
                  );
                } else {
                  return checkAllowMetadataPermission(r.permission, r.name);
                }
              } else {
                return true;
              }
            });

            return route;
          })
          .filter((r) => r.views?.length),
      );
    }
  }, [config]);

  useEffect(() => {
    dispatch(routerActions.setCurrentRoutes(userRoutes));
  }, [userRoutes, dispatch]);

  const checkAllowMetadataPermission = (
    permission_name: Permissions,
    itemToCheck: string,
  ) => {
    if (
      permissions.some(
        (permission) => permission.module_name === permission_name,
      )
    ) {
      const permission = permissions.find(
        (permission) =>
          permission.module_name === permission_name &&
          permission.permission === "EXECUTE",
      );
      if (permission) {
        const metadata = permission.metadata.filter((meta) => meta);
        if (
          metadata &&
          metadata.length > 0 &&
          IsJsonString(permission.metadata[0]) &&
          JSON.parse(metadata[0])?.allow!?.length > 0
        ) {
          const allow = JSON.parse(permission.metadata[0]).allow;
          if (allow.includes("all")) {
            return true;
          } else {
            return allow.includes(itemToCheck);
          }
        } else {
          return true;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  useIgnoreFirstEffect(() => {
    const current = userRoutes.find(
      (r) =>
        r.views?.find((v) => {
          const path = `${v.root}${v.path !== "/" ? v.path : ""}`;
          return history.pathname.split("?").shift() === path;
        }),
    );
    setExpanded(current?.name ?? routes[0].name);
  }, [userRoutes, history.pathname]);

  const getPath = (view: ViewProps) =>
    `${view.root}${view.path !== "/" ? view.path : ""}`;

  const getRouteLabelByRole = (route: ViewFormat) => {

    if (role && route.config_by_role && route.config_by_role[role.toUpperCase()]){
      return route.config_by_role[role.toUpperCase()]?.label ?? route.label ?? ""
    }

    return route.label ?? ""
  }

  const getRouteHideByRole = (route: ViewFormat) => {

    if (role && route.config_by_role && route.config_by_role[role.toUpperCase()]){
      return route.config_by_role[role.toUpperCase()]?.hide ?? false
    }

    return false
  }

  return (
    <>
      <div className="app-main">
        <SideBar
          sidebarOpen={sidebarOpen}
          menu={[
            {
              label: "Menu",
              items: userRoutes.map((route) => {
                return {
                  name: route.label ?? route.name,
                  active: expanded === route.name,
                  icon: route.icon,
                  path: route.path,
                  label: route.label ?? t(route.name),
                  subItems:
                    route.views
                      ?.filter((subItem) => !subItem.hide && !getRouteHideByRole(subItem))
                      .map((subItem) => {
                        const path = getPath(subItem);
                        const sub: SubMenuProps = {
                          active: history.pathname.split("?").shift() === path,
                          path: path,
                          label: getRouteLabelByRole({...subItem, label: subItem.label ?? t(subItem.name)}) ,
                          name: subItem.label ?? subItem.name,
                          subItems: subItem.views?.map((subItemI) => {
                            const subPath = getPath(subItemI);
                            const subI: SubMenuItem = {
                              name: subItemI.label ?? subItemI.name,
                              // label: subItemI.label ?? t(subItemI.name),
                              label: getRouteLabelByRole({...subItemI, label: subItemI.label ?? t(subItemI.name)}),
                              path: subPath,
                              active:
                                history.pathname.split("?").shift() === subPath,
                            };
                            return subI;
                          }),
                        };
                        return sub;
                      }) ?? [],
                };
              }),
            },
          ]}
        />
        <div className="app-main__outer overflow-auto">
          <div className="app-main__inner">
            <HeadDynamicComponent />
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

export default SideBarContent;
