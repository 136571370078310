import * as React from 'react';
import {useContext} from 'react';
import {AnalyticQueryAction} from "../../../../types/Analytic";
import styled from "styled-components";
import CreatableSelectComponent from "../../../Shared/FieldComponents/CreatableSelectComponent";
import {StringOption} from "../../../../types/Select";
import {ReportContext} from "../../NewReportGeneratorComponent";
import useTranslate from "../../../../hooks/useTranslate";

type Props = {
    action: AnalyticQueryAction
    index: number
};


const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;

  @media (max-width: 998px) {
    grid-template-columns: 1fr;
  }
`

const NewBinaryClassifierActionComponent = ({action, index}: Props) => {

    const {getActionColumns, setQueryAction, getLoadingAndDisabledAction} = useContext(ReportContext)
    const {t} = useTranslate('common');
    return (
        <Container>
            <div className="d-flex flex-column">
                <span>{t("columns")}</span>
                <CreatableSelectComponent isMulti closeMenuOnSelect={false}
                                          className="text-start"
                                          onChange={value => {
                                              setQueryAction({
                                                      ...action,
                                                      columns: value.map((item: StringOption) => item.value)
                                                  }, index
                                              )
                                          }} menuPosition={'fixed'}
                                          id={'action_type_' + action.type + '_' + index} name={''}
                                          value={(action?.columns as string[])?.map(column => ({
                                              label: column,
                                              value: column
                                          }))}
                                          disabled={getLoadingAndDisabledAction({action, index})}
                                          loading={getLoadingAndDisabledAction({action, index})}
                                          options={getActionColumns(action, index)}/>
            </div>


            <div className="d-flex flex-column">
                <span>{t("report:target")}</span>
                <CreatableSelectComponent className="text-start"
                                          onChange={evt => {
                                              setQueryAction({
                                                      ...action,
                                                      target: evt.value
                                                  }, index
                                              )
                                          }} menuPosition={'fixed'}
                                          id={'action_type_' + action.type + '_' + index} name={''}
                                          value={action.target ? {
                                              label: action.target,
                                              value: action.target
                                          } : null}
                                          disabled={getLoadingAndDisabledAction({action, index})}
                                          loading={getLoadingAndDisabledAction({action, index})}
                                          options={getActionColumns(action, index)}/>

            </div>
        </Container>
    );
};

export default NewBinaryClassifierActionComponent