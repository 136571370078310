import React, { FocusEventHandler, useEffect, useState } from "react";
import { FormGroup } from "reactstrap";
import { getInputComponentByField } from "../../../FormBuilder/FormBuilderData/data";
import {
  getDefaultValue,
  getProviderOptions,
  getSelectOptionByConfig,
} from "../../../../utils";
import IField from "../../../../types/FormBuilder/IField";
import RenderMonthElement from "../../RenderMonthElement";
import { State } from "../../../../types/State";
import { LabelField } from "../../../TaskComponent/FormRenderInput/FormRenderInput";

const CreateFieldPreview = ({
  field,
  hiddenLabel,
  onChange,
  value,
  header,
  className,
  onChangeCurrentRow,
  getCurrentRow,
  valueNewRow,
  options,
  getOptions,
  onFocus,
  withIcon = true,
  onBlur,
}: {
  field: IField;
  hiddenLabel?: boolean;
  value?: any;
  onChange?: (data: any) => void;
  className?: string;
  onChangeCurrentRow?: (value: any) => void;
  getCurrentRow?: (row: string) => void;
  header?: any[];
  options?: any[];
  getOptions?: boolean;
  withIcon?: boolean;
  valueNewRow?: any;
  onFocus?: FocusEventHandler<any>;
  onBlur?: FocusEventHandler<any>;
}) => {
  const { component: FieldInput, icon }: any = getInputComponentByField(field);
  const [val, setVal] = useState<any>(getDefaultValue(field));
  const [localOptions, setLocalOptions] = useState<any[]>([]);
  const [, setLoading] = useState<State>(State.IDLE);

  useEffect(() => {
    const getSelectOptions = async () => {
      if (getOptions && field.list_provider?.id) {
        setLoading(State.PENDING);
        const response = await getProviderOptions({
          list_provider: field.list_provider,
        });

        if (response?.success && response.items) {
          setLoading(State.RESOLVED);
          setLocalOptions(response.items);
        } else {
          setLoading(State.REJECTED);
        }
      }
    };

    getSelectOptions();
  }, [getOptions, field]);

  const fields = field.detail_form?.fields || [];
  return (
    <FormGroup className={className} key={field.id}>
      <LabelField field={field} icon={withIcon ? icon : null} />
      <FieldInput
        {...field}
        id={field.id?.toString() ?? new Date().getTime()}
        valueNewRow={valueNewRow}
        onChange={onChange || setVal}
        value={value === undefined ? val : value}
        defaultValue={field.default_value}
        subType={field.sub_type}
        getCurrentRow={getCurrentRow}
        count={field.size}
        field={field}
        getKey={() => ""}
        getFiles={() => null}
        disabled={field.read_only}
        onFocus={onFocus}
        onBlur={onBlur}
        options={
          getOptions
            ? getSelectOptionByConfig(field, localOptions) ?? []
            : options ?? field.list_provider?.options ?? []
        }
        header={header}
        isOutsideRange={() => false}
        onChangeCurrentRow={onChangeCurrentRow}
        placeholder={field.placeholder || "Here default placeholder"}
        format_rules_definition={field.format_rules_definition || undefined}
        fields={fields.map((f) => ({
          ...f,
          startDatePlaceholderText: "Fecha inicial",
          endDatePlaceholderText: field.placeholder || "Fecha final",
        }))}
        renderMonthElement={RenderMonthElement}
      />
    </FormGroup>
  );
};

export default CreateFieldPreview;
