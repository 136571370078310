import * as React from 'react';
import {useContext, useRef, useState} from 'react';
import ReportFilterComponent from "./ReportFilterComponent";
import {ReportContext} from "./NewReportGeneratorComponent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalculator, faFilter, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import useTranslate from "../../hooks/useTranslate";
import {ActionType} from "../../types/Analytic";

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px
`

type Visualize = {
    showFilter: boolean
    showSummarize: boolean
}
const ActionButton = styled.button<{ color: string, textColor?: string }>`
  color: ${props => props.textColor ? props.textColor : "white"};
  background: ${props => props.color};
  border-color: #ffffff;
  display: flex;
  align-items: center;
  gap: 4px;

  &:hover {
    color: #FFFFFF;
  }
`
const NewReportManageDataComponent = ({getDefaultAction}: {getDefaultAction: (actionType: ActionType, index: number) => void}) => {

    const {state, setQuery, query, report} = useContext(ReportContext)
    const {t} = useTranslate("report")
    let onMount = useRef(false)
    const [visualize, setVisualize] = useState<Visualize>({
        showFilter: false,
        showSummarize: false
    })

    React.useEffect(() => {

        if (query && !onMount.current &&
            (
                (query.source.filters && query.source.filters.length > 0)
                ||
                (query.actions.length > 0 && query.actions[0][0]?.type === "group_by")
            )
        ) {


                const params = {
                    showFilter: false,
                    showSummarize: false
                }

                if (!visualize.showFilter && query.source.filters && query.source.filters.length > 0) {

                    params.showFilter = true
                }

                if (!visualize.showSummarize && query.actions.length > 0 && query.actions[0][0]?.type === "group_by") {
                    params.showSummarize = true
                }

                onMount.current = true

                if (params.showFilter || params.showSummarize) {
                    setVisualize(params)
                }


        }else{
            onMount.current = true
        }

    }, [query, visualize]);

    return (
        <div
            className={`${state.model ? `${Object.keys(visualize).every((key) => !visualize[key as keyof Visualize]) ? "d-flex  flex-row " : ""}` : "d-none"}`}>

            {visualize.showFilter ? <div className="d-flex flex-column mb-2">
                    <TitleContainer className="card-header " style={{color: "#4681f4"}}>
                        <FontAwesomeIcon icon={faFilter}/>
                        <span>{t("filter")} {t("data")}</span>
                        <FontAwesomeIcon icon={faTimesCircle} className="pointer close-icon"
                                         onClick={() => {
                                             setQuery(query => ({
                                                 ...query,
                                                 source: {
                                                     ...query.source,
                                                     filters: []
                                                 },
                                             }))
                                             setVisualize(prevState => ({
                                                 ...prevState,
                                                 showFilter: false
                                             }))
                                         }}/>
                    </TitleContainer>

                    <div className="p-3" style={{backgroundColor: "#4681f430"}}>
                        <ReportFilterComponent key={"main_filter"} color={"#4681f4"}/>
                    </div>

                </div> :

                <ActionButton className="mx-2 mb-2 btn" color={"#4681f4"} onClick={() => {

                    setVisualize(prevState => ({
                        ...prevState,
                        showFilter: true
                    }))
                }}>
                    <FontAwesomeIcon icon={faFilter} color={"white"}/>
                    <span>{t("filter")}</span>
                </ActionButton>


            }

            {visualize.showSummarize ? <>
                    {/*<TitleContainer className="card-header" style={{color: "#5adbb5"}}>*/}
                    {/*    <FontAwesomeIcon icon={faLayerGroup}/>*/}
                    {/*    <span>{t("group_by")}</span>*/}
                    {/*    <FontAwesomeIcon icon={faTimesCircle} className="pointer close-icon"*/}
                    {/*                     onClick={() => {*/}

                    {/*                         const actions = [...query.actions]*/}

                    {/*                         if (actions.length > 0 && actions[0][0].type === "group_by") {*/}
                    {/*                             actions.shift()*/}
                    {/*                         }*/}

                    {/*                         setQuery(query => ({*/}
                    {/*                             ...query,*/}
                    {/*                             actions*/}
                    {/*                         }))*/}

                    {/*                         setVisualize(prevState => ({*/}
                    {/*                             ...prevState,*/}
                    {/*                             showSummarize: false*/}
                    {/*                         }))*/}
                    {/*                     }}/>*/}
                    {/*</TitleContainer>*/}
                    {/*<div className="p-3" style={{backgroundColor: "#5adbb530"}}>*/}
                        {/*<ReportSummarizeComponent key={"main_summarize"} color="#5adbb5"*/}
                        {/*                          indexAction={query.actions && query.actions[0] && query.actions[0][0] && query.actions[0][0]?.type === "group_by" ? 0 : undefined}/>*/}
                    {/*</div>*/}
                </> :
                !(report?._KEY && query.actions.length > 0 && query.actions[0][0]?.type === "group_by") &&
                <ActionButton className="mx-2 mb-2 btn" color={"#5adbb5"} onClick={() => {
                    setVisualize(prevState => ({
                        ...prevState,
                        showSummarize: true
                    }))


                    getDefaultAction("group_by", -1)

                }}>
                    <FontAwesomeIcon icon={faCalculator} color={"white"}/>
                    <span>{t("group_by")}</span>
                </ActionButton>


            }
        </div>
    );
};

export default NewReportManageDataComponent