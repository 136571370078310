import * as React from 'react';
import {useContext} from 'react';
import {AnalyticQueryAction} from "../../../../types/Analytic";
import styled from "styled-components";
import useTranslate from "../../../../hooks/useTranslate";
import CreatableSelectComponent from "../../../Shared/FieldComponents/CreatableSelectComponent";
import {ReportGeneratorContext} from "../../ReportGeneratorComponent";
import {StringOption} from "../../../../types/Select";

type Props = {
    action: AnalyticQueryAction
}

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;

  @media (max-width: 998px) {
    grid-template-columns: 1fr;
  }
`

const BinaryClassifierActionComponent = ({action}: Props) => {

    const {getActionColumns} = useContext(ReportGeneratorContext);
    const {t} = useTranslate('common');

    return (
        <Container>
            <div>
                <span>{t("columns")}</span>
                <CreatableSelectComponent name={"binary_columns"}
                                          defaultValue={action.columns ? (action.columns as string[]).map(column => ({
                                              label: column,
                                              value: column
                                          })) : null} options={getActionColumns(action)} isMulti onChange={evt => {
                    if (action.columns) {
                        action.columns = evt.map((event: StringOption) => event.value)
                    }

                }}/>
            </div>

            <div>
                <span>{t("report:target")}</span>
                <CreatableSelectComponent name={"target"} defaultValue={action.target ? {
                    label: action.target,
                    value: action.target
                } : null} options={getActionColumns(action)} onChange={evt => {
                    action.target = evt.value
                }}/>
            </div>
        </Container>
    );
};

export default BinaryClassifierActionComponent