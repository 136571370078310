import React from "react";
import useRequired from "../../../hooks/useRequired";

import TextProps from "./Models/TextProps";
import CodeEditorComponent from "../CodeEditorComponent";

interface IProps extends TextProps {
  value: string;
  onChange?: (value: string) => void;
  editor?: boolean;
  variables?: { label: string, text: string, description: string }[]
}

const LargeTextComponent: React.FC<IProps> = ({
                                                id,
                                                name,
                                                value,
                                                invalid,
                                                required,
                                                readonly,
                                                disabled,
                                                style,
                                                placeholder,
                                                className,
                                                onChange = (e?: string) => null,
                                                editor,
                                                onFocus,
                                                onBlur,
                                                variables,
                                                defaultValue
                                              }) => {

  const error = useRequired(value, Boolean(required));

  if (editor) {
    return (
      <CodeEditorComponent
        defaultValue={defaultValue}
        className={className}
        disabled={disabled}
        variables={variables}
        readonly={readonly}
        value={value}
        onChange={(e) => onChange(e ?? "")}/>
    )
  }


  return (
    <textarea id={id}
              name={name}
              value={value}
              required={required}
              readOnly={readonly}
              disabled={disabled}
              style={style}
              onFocus={onFocus}
              defaultValue={defaultValue}
              placeholder={placeholder}
              onBlur={onBlur}
              className={`form-control ${className || ''} ${invalid || error ? 'invalid' : ''}`}
              onChange={e => onChange ? onChange(e.target.value) : null}/>
  );
}

export default LargeTextComponent;
