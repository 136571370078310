import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {renderIconByType} from "../NewReportGeneratorComponent";
import {SbxModelField} from "../../../types/Sbx";
import * as React from "react";

type GetColumnProps = { type?: string, name: string, index?: number, columnsType?: { [key: string]: any } }

export const getColumnType = ({type, name, index, columnsType}: GetColumnProps) => {
    if (typeof index !== 'undefined' && columnsType) {
        return columnsType[index] ? columnsType[index][name] ?? "" : ""
    }


    return type ?? "";
}

export const getColumnName = (props: GetColumnProps) => {

    return <div className="d-flex align-items-center gap-1">
        {getColumnType(props) && renderIconByType()[getColumnType({...props}) as SbxModelField] &&
        <FontAwesomeIcon icon={renderIconByType()[getColumnType({...props}) as SbxModelField]}/>} {props.name}</div>
}