import React, {useContext} from 'react';
import RadioButtonComponent from "../../Shared/FieldComponents/RadioButtonComponent";
import {State} from "../../../types/State";
import {AnalyticQueryAction} from "../../../types/Analytic";
import {ReportGeneratorContext} from "../ReportGeneratorComponent";
import {OnChangeActionParams} from "./ActionComponent";
import CreatableSelectComponent from "../../Shared/FieldComponents/CreatableSelectComponent";
import {StringOption} from "../../../types/Select";
import useTranslate from "../../../hooks/useTranslate";

type Props = {
  action: AnalyticQueryAction
  index: number
  onChangeAction: (actions: OnChangeActionParams[]) => void
}

const SortActionComponent = ({action, onChangeAction, index}: Props) => {
  const {reportState: {isLoading, actionLoading}, getActionColumns} = useContext(ReportGeneratorContext);
  const {t} = useTranslate('report');
  return (
    <div className="d-flex flex-column">
      <div className="d-flex align-items-center">
        <span className="fw-bold me-2">{t("ascending")}:</span>
        <RadioButtonComponent containerClassName="d-flex align-items-center" disabled={State.PENDING === isLoading}
                              className="d-flex align-items-center me-2" onChange={event => onChangeAction([{
          action,
          actionKey: 'ascending',
          value: event?.value,
          noGetColumns: true
        }])}
                              id={'action_type_' + action.type + '_ascending' + index} name={''}
                              value={{value: action.ascending, label: 'Yes'}} options={[
          {label: 'Yes', value: true}, {label: 'No', value: false}
        ]}/>
      </div>

      <div className="d-flex flex-column">
        <label>{t("common:columns")}: </label>
        <CreatableSelectComponent
            className="text-start"
          disabled={(action.temporal_id && actionLoading[action.temporal_id] === State.PENDING) || State.PENDING === isLoading}
          loading={(action.temporal_id && actionLoading[action.temporal_id] === State.PENDING) || State.PENDING === isLoading}
          isMulti menuPosition={'fixed'} onChange={evt => {
          const columns = evt.map((event: StringOption) => event.value);
          onChangeAction([{actionKey: 'columns', action, value: columns}]);
        }} id={'action_type_' + action.type + '_' + index} name={''}
          value={(action?.columns as string[])?.map(column => ({label: column, value: column}))}
          options={getActionColumns(action)}/>
      </div>
    </div>
  )
};

export default SortActionComponent;
