import * as React from 'react';
import {useState} from 'react';
import {capitalize, getReportChartData, getVarValueByType, IsJsonString, uuidV4} from "../../../utils";
import {ReportMetadata, ReportTypeChart} from "../../../types/Analytic";
import {Bar, Line, Pie} from "react-chartjs-2";
import {
    ActiveElement,
    ArcElement,
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    ChartData,
    Legend,
    LinearScale,
    LineController,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js';
import SpinnerComponent from "../../Shared/SpinnerComponent";
import useTranslate from "../../../hooks/useTranslate";
import {DragDropContext, Draggable, Droppable, DroppableStateSnapshot, DropResult} from "react-beautiful-dnd-next";
import {Controller, useForm} from "react-hook-form";
import styled from "styled-components";
import TabContents, {Tab} from "../../Shared/TabContents";
import {Switch} from "antd";
import ButtonComponent from "../../Shared/ButtonComponent";
import {faSave, faSync, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import SelectComponent from "../../Shared/FieldComponents/SelectComponent";
import {actionsModal, ModalTypes} from "../../../store/Modal/Slice";
import {useDispatch} from "react-redux";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    PointElement,
    LineController,
    LineElement,
    Legend
);
type Props = {
    results: any[]
    metadata?: ReportMetadata
    type?: ReportTypeChart | null
    columns: { label: string, value: string, isDrillDownColumn?: boolean }[]
    loading: boolean
    drillDown?: (value: string | number, name: string) => void
    isReportView?: boolean
    isClientView?: boolean
    getQueryData?: ({
                        limit,
                        hierarchy
                    }: { limit?: number | null, hierarchy?: { filter: { [column: string]: number | string }, column: string } }) => void
    setReport?: (data: { type?: ReportTypeChart | null, metadata: string }) => void
};

let options: any = {
    maintainAspectRatio: false,
    responsive: true,
    legend: {
        display: true
    },

};

const name = "report_columns"

const Container = styled.div<{ isReportView: boolean }>`
  display: grid;
  min-height: 40vh;
  grid-template-columns: ${props => props.isReportView ? "2fr 2fr" : "1fr"};
  gap: 15px;


  @media (min-width: 1101px) and (max-width: 1300px) {
    grid-template-columns: ${props => props.isReportView ? "2fr 1.7fr" : "1fr"};
  }

  @media (max-width: 1100px) {
    grid-template-columns: 1fr
  }
  //margin: 10px;
`

const SubContainer = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  gap: 15px;

  @media (max-width: 1100px) {
    grid-template-columns: 1fr 1fr
  }
  //margin: 10px;
`

const typeOptions = [{label: "Bar", value: "barchart"}, {label: "Horizontal Bar", value: "horizontal_barchart"}, {
    label: "Line",
    value: "linechart"
}, {label: "Pie", value: "piechart"}]


const PreviewChartResultComponent = ({
                                         results,
                                         metadata,
                                         type,
                                         columns,
                                         loading,
                                         isReportView,
                                         getQueryData, isClientView,
                                         setReport, drillDown
                                     }: Props) => {
    const {t} = useTranslate('custom-message');

    const {
        setValue,
        watch,
        control,
        getValues
    } = useForm<{ metadata: ReportMetadata | null, type: ReportTypeChart | null, limit: number | null }>({
        defaultValues: {
            type: type ?? "linechart",
            metadata: metadata ?? null,
            limit: 10
        }
    })


    React.useEffect(() => {

        if  (type){
            setValue("type", type)
        }

        if (metadata){
            setValue("metadata", metadata)
        }

    }, [type, metadata]);

    const dispatch = useDispatch()
    const [renderChart, setRenderChart] = useState<JSX.Element | null>(<div/>)


    const onDragEnd = (result: DropResult) => {
        if (!result.destination) return
        if (result.destination.droppableId === result.source.droppableId) return

        if (result.destination.droppableId) {
            setValue(`metadata.${result.destination.droppableId as keyof ReportMetadata}`, result.draggableId)
        }

    }

    const chartOnClick = ({
                              data,
                              elements,
                              x_axis
                          }: { data: ChartData<any, any[], unknown>, elements: ActiveElement[], x_axis: string }) => {
        if (elements[0]) {
            const {index} = elements[0];
            if (data?.labels && (data?.labels[index] || typeof data?.labels[index] === "number") && drillDown) {
                drillDown(data.labels[index] as number, x_axis)
            }
        }
    }

    const onHover = (
        event: any,
        chartElements: Array<{ datasetIndex: number; index: number }>
    ) => {

        const canvas = event.chart.canvas as HTMLCanvasElement;
        if (canvas) {
            const x_axis = getValues("metadata.x_axis")
            canvas.style.cursor = (chartElements.length && columns.find(column => column.value === x_axis && column.isDrillDownColumn)) ? 'zoom-in' : 'default';
        }

    }

    React.useEffect(() => {

        const getChart = (metadata: ReportMetadata | string, type: ReportTypeChart) => {


            let meta: ReportMetadata | string = metadata

            if (IsJsonString(meta as string)) {
                meta = JSON.parse(meta as string)
            }

            // console.log("meta", meta)
            // console.log("type", type)
            // console.log("\n\n")


            if (meta && (meta as ReportMetadata).x_axis && (meta as ReportMetadata).y_axis && type) {

                const data = getReportChartData({report_data: results, metadata: meta as ReportMetadata})
                // console.log("data", data)
                let chart: JSX.Element | null = null


                switch (type) {
                    case 'barchart':
                        chart =
                            <div style={{maxHeight: '70vh'}} key={"barchart_" + type + "_" + uuidV4()} className="card">
                                <Bar data={data}
                                     key={"barchart_" + type + "_" + uuidV4()}
                                     options={{
                                         ...options,
                                         onHover,
                                         type: 'bar',
                                         onClick(_, elements: ActiveElement[]) {
                                             chartOnClick({data, elements, x_axis: (meta as ReportMetadata).x_axis})
                                         }
                                     }}
                                     height={200}/>
                            </div>;
                        break;
                    case 'horizontal_barchart':
                        chart =
                            <div className="card" style={{maxHeight: '70vh'}}
                                 key={"horizontal_barchart_" + type + "_" + uuidV4()}><Bar data={data}
                                                                                           key={"horizontal_barchart_" + type + "_" + uuidV4()}
                                                                                           options={{
                                                                                               ...options,
                                                                                               indexAxis: 'y' as const,
                                                                                               responsive: true,
                                                                                               onHover,
                                                                                               onClick(_, elements: ActiveElement[]) {
                                                                                                   chartOnClick({
                                                                                                       data,
                                                                                                       elements,
                                                                                                       x_axis: (meta as ReportMetadata).x_axis
                                                                                                   })
                                                                                               }
                                                                                           }}
                                                                                           height={200}/>
                            </div>;
                        break;
                    case 'piechart':

                        chart = <div style={{maxHeight: '1200px', maxWidth: '1200px'}}
                                     key={"piechart_" + type + "_" + uuidV4()}
                                     className="card"><Pie key={"piechart_" + type + "_" + uuidV4()}
                                                           data={data}
                                                           options={{
                                                               ...options,
                                                               maintainAspectRatio: false,
                                                               onClick(_, elements: ActiveElement[]) {
                                                                   chartOnClick({
                                                                       data,
                                                                       elements,
                                                                       x_axis: (meta as ReportMetadata).x_axis
                                                                   })
                                                               }, onHover
                                                           }}/></div>;
                        break
                    case 'linechart':
                        let temp = data
                        temp.datasets.forEach((i: any) => {
                            i.lineTension = 0.1;
                            i.fill = false

                        })
                        chart = <div className="card" style={{maxHeight: '70vh'}}
                                     key={"linechart_" + type + "_" + uuidV4()}>
                            <Line data={temp} height={200} key={"linechart_" + type + "_" + uuidV4()}
                                  options={{
                                      ...options, onClick(_, elements: ActiveElement[]) {
                                          chartOnClick({data: temp, elements, x_axis: (meta as ReportMetadata).x_axis})
                                      }, onHover
                                  }}/>
                        </div>
                        break

                }



                if (chart) {
                    setRenderChart(chart)
                    return;
                }
            }

            setRenderChart(<div
                className="text-center text-lightgray w-100 card d-flex align-items-center justify-content-center">
                {t("chart-metadata-missing")}
            </div>)
        }

        const subscription = watch((obj, {name}) => {
            const metadata = obj["metadata"]
            const type = obj["type"]

            if (name?.includes("metadata") || name === "type") {
                getChart(metadata as ReportMetadata, type as ReportTypeChart)
            }
        });

        getChart(metadata as ReportMetadata, type as ReportTypeChart)

        return () => subscription.unsubscribe();
    }, [watch, type, metadata]);

    const axisConfig = () => {
        return <div className="d-flex flex-column gap-3">

            <div>
                <span className="fw-bold">X axis</span>
                <Droppable droppableId={`x_axis`} isDropDisabled={loading}>
                    {(provided, snapshot: DroppableStateSnapshot) => (
                        <div key={`x_axis_droppable_action_list`} style={{
                            border: snapshot.isDraggingOver ? "2px dotted #5e72e4" : "",
                            // margin: snapshot.isDraggingOver ? "1px" : "",
                            maxHeight: snapshot.isDraggingOver ? "60px" : "",
                        }} ref={provided.innerRef} {...provided.droppableProps}>
                            {snapshot.draggingOverWith &&
                                <div className="text-center">{t("common:drop_here")}
                                </div>}
                            <Draggable key={name + "_axis_x_"} draggableId={"draggable_axis_x"} index={0}
                                       isDragDisabled>
                                {provided => (
                                    <div
                                        ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                        {!snapshot.draggingOverWith &&
                                            <>
                                                <Controller control={control} render={({field}) => {
                                                    return <input type="text" className="form-control" disabled
                                                                  placeholder={snapshot.draggingOverWith ?? "X axis"} {...field}
                                                                  value={snapshot.draggingOverWith ?? field.value ?? ""}/>

                                                }} name={"metadata.x_axis"}/>
                                            </>
                                        }
                                    </div>
                                )}
                            </Draggable>
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </div>

            <div>
                <span className="fw-bold">Y axis</span>
                <Droppable droppableId={`y_axis`} isDropDisabled={loading}>
                    {(provided, snapshot: DroppableStateSnapshot) => (
                        <div key={`y_axis_droppable_action_list`} style={{
                            border: snapshot.isDraggingOver ? "2px dotted #5e72e4" : "",
                            // margin: snapshot.isDraggingOver ? "1px" : "",
                            maxHeight: snapshot.isDraggingOver ? "60px" : "",
                        }} ref={provided.innerRef} {...provided.droppableProps}>
                            {snapshot.draggingOverWith &&
                                <div className="text-center">{t("common:drop_here")}
                                </div>}
                            <Draggable key={name + "_axis_y_"} draggableId={"draggable_axis_y"} index={0}
                                       isDragDisabled>
                                {provided => (
                                    <div
                                        ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                        {!snapshot.draggingOverWith &&
                                            <>
                                                <Controller control={control} render={({field}) => {
                                                    return <input type="text" className="form-control" disabled
                                                                  placeholder={snapshot.draggingOverWith ?? "Y axis"} {...field}
                                                                  value={snapshot.draggingOverWith ?? field.value ?? ""}/>
                                                }} name={"metadata.y_axis"}/>
                                            </>
                                        }
                                    </div>
                                )}
                            </Draggable>
                            {provided.placeholder}
                        </div>
                    )}

                </Droppable>
            </div>

            <div>
                <span className="fw-bold">Split by</span>
                <Droppable droppableId={`split_by`} isDropDisabled={loading}>
                    {(provided, snapshot: DroppableStateSnapshot) => (
                        <div key={`split_by_axis_droppable_action_list`} style={{
                            border: snapshot.isDraggingOver ? "2px dotted #5e72e4" : "",
                            // margin: snapshot.isDraggingOver ? "1px" : "",
                            maxHeight: snapshot.isDraggingOver ? "60px" : "",
                        }} ref={provided.innerRef} {...provided.droppableProps}>

                            {snapshot.draggingOverWith &&
                                <div className="text-center">{t("common:drop_here")}
                                </div>}
                            <Draggable key={name + "_split_by_"} draggableId={"draggable_split_by"} index={0}
                                       isDragDisabled>
                                {provided => (
                                    <div
                                        ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                        {!snapshot.draggingOverWith &&
                                            <>
                                                <Controller control={control} render={({field}) => {
                                                    return <input type="text" className="form-control" disabled
                                                                  placeholder={snapshot.draggingOverWith ?? "Split by"} {...field}
                                                                  value={snapshot.draggingOverWith ?? field.value ?? ""}/>
                                                }} name={"metadata.split_by"}/>
                                            </>
                                        }
                                    </div>
                                )}
                            </Draggable>
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </div>

        </div>
    }

    const metadataConfig = () => {
        return <div className="d-flex flex-column gap-3">
            <div className="d-flex align-items-center gap-2">
                <span>Total</span>{' '}
                <Controller control={control} render={({field: {onChange, value}}) => {
                    return <Switch
                        unCheckedChildren={'No'}
                        defaultChecked={metadata?.total === true}
                        checkedChildren={t('common:yes')}
                        onChange={checked => onChange(checked)}
                        checked={value}/>
                }} name={"metadata.total"}/>
            </div>

            <div className="d-flex flex-column">
                <div className="d-flex align-items-center justify-content-between">
                    <span>{t("report:limit")}</span>

                    <Controller control={control} render={({field: {value, onChange}}) => {
                        return <input type="checkbox" defaultChecked={typeof value === "number"} onChange={event => {
                            onChange(event.currentTarget.checked ? 10 : null)
                        }}/>
                    }} name={"limit"}/>

                </div>
                <Controller control={control} render={({field: {value, onChange}}) => {
                    return <input type="number" className="form-control" defaultValue={10} value={value ?? 0}
                                  disabled={typeof value !== "number"}
                                  onChange={event => onChange(parseInt(event.currentTarget.value))}/>
                }} name={"limit"}/>

            </div>
        </div>
    }


    const tabs: Tab[] = [
        {label: "Axis", component: axisConfig()},
        {label: "Config", component: metadataConfig()},
    ]

    if (loading) {
        return <div className="d-flex justify-content-center">
            <SpinnerComponent/>
        </div>
    }




    return columns.length > 0 ?

        <div className="d-flex flex-column">
            {(isReportView || isClientView) && <div className="d-flex justify-content-end gap-3 mb-3">
                {isReportView && <ButtonComponent label={`${t("common:update")} ${t("common:preview")}`} icon={faSync}
                                                  color={"success"}
                                                  onClick={() => {
                                                      if (getQueryData) {
                                                          const limit = watch('limit')
                                                          if (typeof limit === "number") {
                                                              getQueryData({limit: parseInt(limit.toString())})
                                                          } else {
                                                              getQueryData({limit: null})
                                                          }
                                                      }
                                                  }}/>}

                {(isReportView || isClientView) &&
                    <ButtonComponent label={`${t("common:save")} ${t("common:changes")}`} icon={faSave}
                                     color={"primary"}
                                     onClick={() => {
                                         const metadata = getValues("metadata")
                                         const type = getValues("type")


                                         dispatch(actionsModal.openModal(
                                             {
                                                 type: ModalTypes.CONFIRM,
                                                 onConfirm: () => {
                                                     if (metadata && type && setReport) {
                                                         setReport({type, metadata: JSON.stringify(metadata)})
                                                     }
                                                 },
                                                 closeModal: true,
                                                 title: `${t("custom-message:warn-save")}?`,
                                                 message:
                                                     <div className="d-flex flex-column">

                                                         {type && <span><b>{t("common:type")}</b>: {type}</span>}
                                                         {metadata &&
                                                             <>
                                                                 <span className="fw-bold">Metadata: </span>
                                                                 <ul className="list-group list-group-flush">
                                                                     {Object.keys(metadata).map(key => (
                                                                         <li className="list-group-item list-group-item-action"
                                                                             key={key}>
                                                                             <b>{key.replace("_", " ")}:</b> {getVarValueByType({
                                                                             value: metadata[key as keyof typeof metadata] ?? "",
                                                                             t
                                                                         })}
                                                                         </li>
                                                                     ))}
                                                                 </ul>
                                                             </>}
                                                     </div>
                                             }))
                                     }}/>
                }

                {getValues("metadata") && Object.keys(getValues("metadata")!).some(key => getValues("metadata")![key as keyof ReportMetadata]) &&
                    <ButtonComponent label={t("common:clear")} icon={faTimesCircle} color={"danger"} onClick={() => {


                        dispatch(actionsModal.openModal(
                            {
                                type: ModalTypes.CONFIRM,
                                onConfirm: () => {
                                    setValue("metadata", null)
                                    if (setReport) {
                                        setReport({metadata: "", type: null})
                                    }
                                },
                                closeModal: true,
                                title: `${t("custom-message:warn-delete")}?`,
                                message:
                                    <div className="d-flex flex-column">
                                        <h4 className="fw-bold">{capitalize(t("common:this"))} {t("common:report")} {t("custom-message:will_be_updated")}</h4>
                                        {type && <span><b>{t("common:type")}</b>: {type}</span>}
                                        {getValues("metadata") &&
                                            <>
                                                <span className="fw-bold">Metadata: </span>
                                                <ul className="list-group list-group-flush">
                                                    {Object.keys(getValues("metadata")!).map(key => (
                                                        <li className="list-group-item list-group-item-action"
                                                            key={key}>
                                                            <b>{key.replace("_", " ")}:</b> {getVarValueByType({
                                                            value: getValues("metadata")![key as keyof typeof metadata] ?? "",
                                                            t
                                                        })}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </>}
                                    </div>
                            }))


                    }}/>}
            </div>
            }
            <Container isReportView={(isReportView || isClientView) ?? false}>
                {(isReportView || isClientView) &&
                    <DragDropContext
                        onDragEnd={onDragEnd}>

                        <SubContainer>
                            <div className="border border-primary bg-white">
                                <div className="card-header">
                                    <span>{t("common:fields")}</span>
                                </div>
                                <Droppable droppableId={`${name}_type_list`} isDropDisabled={loading}>
                                    {provided => (
                                        <div key={`${name}_droppable_action_list`}
                                             ref={provided.innerRef} {...provided.droppableProps}>
                                            {columns.map((action, index) => {
                                                return <Draggable key={name + "_" + action.value + "_" + index}
                                                                  draggableId={action.value}
                                                                  index={index}>
                                                    {provided => (
                                                        <div key={action.value}
                                                             className="align-items-center border d-flex flex-column m-3 p-2 rounded-3 border-secondary"
                                                             style={{border: "1px solid ghostwhite"}}
                                                             ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>

                                                            <span>{action.label}</span>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            })}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </div>

                            <div className="d-flex flex-column flex-grow-1 px-3 card py-3">

                                <Controller control={control} render={({field: {onChange, value}}) => (
                                    <SelectComponent id={"type_id"} name={"type_select"}
                                                     className="my-3"
                                                     menuPosition={"absolute"}
                                                     value={typeOptions.find(type => type.value === value)}
                                                     onChange={event => onChange(event.value)}
                                                     options={typeOptions}/>
                                )} name={"type"}/>


                                <TabContents tabs={tabs}/>

                            </div>
                        </SubContainer>

                    </DragDropContext>
                }


                {renderChart}
            </Container>
        </div>
        : <div className="d-flex justify-content-center">
            <span>{t("common:select_source_before_action")}</span>
        </div>
};

export default PreviewChartResultComponent