import {all, call, put, takeEvery} from "redux-saga/effects";
import {DataProviderStates, dataProviderTypeSlice} from "./Types";
import {actionsDataProvider} from "./Slice";
import {
  addDataProvider,
  addOptionToProvider,
  deleteOptionFromProviderById,
  deleteProviderById,
  getAllListProvider,
  getProviderById,
  getProviderByIdWidthOptions,
  updateProvider
} from "../../services/backend/DataProviderService";
import {PayloadAction} from "@reduxjs/toolkit";
import {Option} from "../../types/DataProvider";
import {toast} from "../../utils";
import {ProviderType} from "../../types/ProviderType";
import {Response} from "../../types/Response";
import {sendSbxEvent} from "../../utils/analyticsUtils";

function error() {
  toast({type: "error", message: ""})
}


function* getDataProvidersSaga() {
  try {
    const res: Response = yield getAllListProvider();
    if (res.success && res.items) {
      yield put(actionsDataProvider.setDataProviders(res.items));
      toast({type: "success", message: ""});
    } else {
      throw new Error("");
    }
  } catch (e) {
    error();
    yield put(actionsDataProvider.changeState(DataProviderStates.DATA_REJECTED));
  }
}

function* addDataProviderSaga({payload}: ReturnType<typeof actionsDataProvider.addDataProvider>) {
  try {
    const res: Response = yield addDataProvider(payload);
    if (res.success) {

      sendSbxEvent({
        props: {
          name: payload.name,
          provider_id: res.item.id,
          options: payload.options ?? [],
          query: payload.query
        },
        name: "sbx_crm_create_data_provider"
      })

      yield put(actionsDataProvider.changeState(DataProviderStates.PROVIDER_RESOLVED));
      yield put(actionsDataProvider.getDataProviders());
      toast({type: "success", message: ""});
    } else {
      throw new Error("");
    }
  } catch (e) {
    error();
    yield put(actionsDataProvider.changeState(DataProviderStates.PROVIDER_REJECTED));
  }
}

function* getDataProviderSaga({payload}: ReturnType<typeof actionsDataProvider.getDataProviderByIdAndType>) {
  try {
    let res: Response;
    switch (payload.providerType) {
      case ProviderType.DATABASE:
        res = yield getProviderByIdWidthOptions(payload.providerId);
        break;
      default:
        res = yield getProviderById(payload.providerId);
        break;
    }
    if (res.success) {
      yield put(actionsDataProvider.setDataProvider(res.item));
      toast({type: "success", message: ""});
    } else {
      throw new Error("");
    }
  } catch (e) {
    error();
    yield put(actionsDataProvider.changeState(DataProviderStates.DATA_REJECTED));
  }
}

function* deleteOptionDataProviderSaga({payload}: ReturnType<typeof actionsDataProvider.deleteOptionFromDataProviderById>) {
  try {
    const res: Response = yield call(deleteOptionFromProviderById, payload.providerId, payload.optionId)
    if (res.success) {
      yield put(actionsDataProvider.changeState(DataProviderStates.OPTION_DELETED));
      yield put(actionsDataProvider.getDataProviderByIdAndType(payload));
    } else {
      throw new Error("");
    }
  } catch (e) {
    error();
    yield put(actionsDataProvider.changeState(DataProviderStates.OPTION_REJECTED));
  }
}

function* deleteDataProviderSaga({payload}: ReturnType<typeof actionsDataProvider.deleteDataProvider>) {
  try {
    const res: Response = yield call(deleteProviderById, payload)
    if (res.success) {
      sendSbxEvent(
          {
            props: {
              provider_id: payload
            },
            name: "sbx_crm_delete_data_provider"
          }
      )

      yield put(actionsDataProvider.changeState(DataProviderStates.PROVIDER_DELETED));
      yield put(actionsDataProvider.getDataProviders());
      toast({type: "success", message: ""});
    } else {
      throw new Error("");
    }
  } catch (e) {
    error();
    yield put(actionsDataProvider.changeState(DataProviderStates.OPTION_REJECTED));
  }
}

function* updateDataProviderSaga({payload}: ReturnType<typeof actionsDataProvider.updateDataProvider>) {
  try {
    const res: Response = yield call(updateProvider, payload)
    if (res.success) {

      sendSbxEvent({
        props: {
          provider_id: payload.id,
          name: payload.name,
          query: payload.query,
          options: payload.options ?? []
        },
        name: "sbx_crm_update_data_provider"
      })
      yield put(actionsDataProvider.changeState(DataProviderStates.PROVIDER_RESOLVED));
      yield put(actionsDataProvider.setDataProvider(payload));
      toast({type: "success", message: ""});
    } else {
      throw new Error("");
    }
  } catch (e) {
    error();
    yield put(actionsDataProvider.changeState(DataProviderStates.OPTION_REJECTED));
  }
}

function* addOptionToProviderSaga({payload}: PayloadAction<{ option: Option, providerId: number }>) {
  try {
    const res: Response = yield addOptionToProvider(payload.option, payload.providerId);
    if (res.success) {
      yield put(actionsDataProvider.changeState(DataProviderStates.OPTION_RESOLVED));
      yield put(actionsDataProvider.getDataProviderByIdAndType({
        providerId: payload.providerId,
        providerType: ProviderType.DATABASE
      }));
      toast({type: "success", message: ""});
    } else {
      throw new Error("");
    }
  } catch (e) {
    error();
    yield put(actionsDataProvider.changeState(DataProviderStates.OPTION_REJECTED));
  }
}

export default function* ListProviderSaga(): any {
  return yield all([
    takeEvery(dataProviderTypeSlice.GET_DATA_PROVIDERS, getDataProvidersSaga),
    takeEvery(dataProviderTypeSlice.GET_DATA_PROVIDER, getDataProviderSaga),
    takeEvery(dataProviderTypeSlice.ADD_DATA_PROVIDER, addDataProviderSaga),
    takeEvery(dataProviderTypeSlice.ADD_OPTION_TO_PROVIDER, addOptionToProviderSaga),
    takeEvery(dataProviderTypeSlice.DELETE_OPTION_TO_PROVIDER, deleteOptionDataProviderSaga),
    takeEvery(dataProviderTypeSlice.DELETE_DATA_PROVIDER, deleteDataProviderSaga),
    takeEvery(dataProviderTypeSlice.UPDATE_DATA_PROVIDER, updateDataProviderSaga),
  ])
}
