import React, {useReducer, useState} from 'react';
import {
    AnalyticQuery,
    AnalyticQueryAction,
    BaseAnalyticQuery,
    Report,
    Source,
    SourceFrom,
    TruncateReport
} from '../../types/Analytic';
import {State} from '../../types/State';
import {Query} from '../Shared/QueryComponent/QueryComponent';
import PreviewResultsComponent from './PreviewResultsComponent/PreviewResultsComponent';
import SaveReportModal from './SaveReportModal';
import {getAnalyticJsonColumns} from '../../services/backend/AnalyticsService';
import {
    IsJsonString,
    removeDuplicateFromArray,
    toast,
    uuidV4
} from '../../utils';

import styled from "styled-components";
import SourceComponent from "./SourceComponent/SourceComponent";
import ActionsComponent from "./ActionsComponent/ActionsComponent";
import ReportGenerationOptions from "./ReportGeneratorOptions/ReportGenerationOptions";
import {
    faCode,
    faExchangeAlt,
    faEye,
    faEyeSlash,
    faSave,
    faSpinner,
    faSyncAlt,
    faTimes,
    faTrash
} from "@fortawesome/free-solid-svg-icons";
import {actionsModal, ModalTypes} from "../../store/Modal/Slice";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useRouter} from "next/router";
import {useDispatch, useSelector} from "react-redux";
import useTranslate from "../../hooks/useTranslate";
import JsonToQueryComponent from "./JsonToQueryComponent";
import {Button} from "reactstrap";
import {Tooltip} from "antd";
import ReportQueryPreview from "./ReportPreviewComponentWrapper/ReportQueryPreview";
import BuildingFeatureComponent from "./BuildingFeatureComponent";
import TruncateGeneratorComponent, {baseCache} from "./TruncateGeneratorComponent/TruncateGeneratorComponent";
import {authReducer} from "../../store/Selectors";
import {insertSbxModelService, updateModelRow} from "../../services/backend/SbxService";
import {StringOption} from "../../types/Select";
import {Response} from "../../types/Response";
import {
    addTemporalIdToQuery,
    getAnalyticQueryFlat,
    getAnalyticQueryGrouped,
    getQuerySources, isValidAnalyticQuery
} from "../../utils/analyticsUtils";

export let sourceBase: Source = {
    from: SourceFrom.SBX_DATA,
    action: 'get',
    with: '',
    merge_type: 'inner',
    in: 0,
    filters: [],
    temporal_id: uuidV4()
};

enum Types {
    SET_STATE,
    SET_RESET_STATE,
    SET_MULTI_STATE
}

type ReportLoading = "results" | "temporary"

export interface ReportReducerState {
    isLoading: State;
    sourceColumnsLoading: State
    reportLoading: {
        [component in ReportLoading]: State
    }
    analyticQuery: AnalyticQuery;
    queriesSource: { [temporal_id: string]: Query };
    actionLoading: { [temporal_id: string]: State };
    actionColumns: { [action_id: string]: string[] };
    sourceColumns: { [source_id: string]: string[] };
    results: any[];
    columns: string[];
    copyColumns: string[];
    showModal: boolean;
    showPreviewQuery: boolean;
    truncate: string
    showMenu: boolean
    report?: Report
}

const initialState: ReportReducerState = {
    isLoading: State.IDLE,
    sourceColumnsLoading: State.IDLE,
    analyticQuery: {
        source: sourceBase,
        actions: []
    },
    queriesSource: {},
    actionLoading: {},
    results: [],
    columns: [],
    copyColumns: [],
    actionColumns: {},
    sourceColumns: {},
    showModal: false,
    reportLoading: {
        results: State.IDLE,
        temporary: State.IDLE
    },
    showPreviewQuery: false,
    truncate: "",

    showMenu: true
};

function reducer(state: ReportReducerState, {
    type,
    payload
}: { type: Types, payload?: { name: string, value?: any } | { name: string, value: any }[] }) {
    switch (type) {
        case Types.SET_STATE:


            return {
                ...state,
                [(payload as { name: string, value: any }).name]: (payload as { name: string, value: any }).value
            };
        case Types.SET_MULTI_STATE:
            (payload as { name: keyof ReportReducerState, value: any }[]).forEach(data => {
                state = {...state, [data.name]: data.value};
            });
            return {...state};
        case Types.SET_RESET_STATE:
            return {...initialState}
        default:
            throw new Error();
    }
}


export const ReportGeneratorContext = React.createContext<{
    reportState: ReportReducerState,
    togglePreviewQuery: () => void,
    dispatchForm: ({name, value}: { name: keyof ReportReducerState, value: any }) => void,
    dispatchMultiForm: (forms: { name: keyof ReportReducerState, value: any }[]) => void,
    getColumns: ({analyticQuery, action}: {
        analyticQuery?: AnalyticQuery,
        action?: AnalyticQueryAction,
        source?: Source
    }) => void,
    getActionColumns: (action: AnalyticQueryAction) => any[];
    getSourceColumns: (source: Source) => any[];
    getAllSourcesColumns: (query: AnalyticQuery) => void
    getAllActionColumns: (query: AnalyticQuery | BaseAnalyticQuery, fromIndex?: number) => void
    resetDefaultValues: () => void
}>({
    reportState: initialState,
    dispatchForm: () => {
    },
    dispatchMultiForm: () => {
    },
    getColumns: () => {
    },
    togglePreviewQuery: () => {
    },
    getActionColumns: () => [],
    getSourceColumns: () => [],
    getAllSourcesColumns: () => {
    },
    getAllActionColumns: () => {
    },
    resetDefaultValues: () => {
    },
});

type Props = {
    analyticQuery?: BaseAnalyticQuery
    report?: Report
    isModalGenerator?: boolean
    setAnalyticQuery?: (query: AnalyticQuery) => void
}

const Container = styled.div<{ showMenu: boolean }>`
  display: grid;
  grid-template-columns: ${props => props.showMenu ? "8vw 80vw" : "90vw"};
  gap: 15px;
  padding: 10px;
  border: 3px solid ghostwhite;
  min-height: 70vh;
  //margin-top: 10px;
  //margin-bottom: 10px;

  @media (max-width: 1200px) {
    display: flex;
    flex-direction: column;
  }
`

const ReportGeneratorComponent = ({analyticQuery, report, isModalGenerator, setAnalyticQuery}: Props) => {

    const [stateLocal, dispatchLocal] = useReducer(reducer, initialState);
    const {t} = useTranslate('report');
    const {user} = useSelector(authReducer)
    const [currentOption, setCurrentOption] = useState<string>("source")
    const [openTransformModal, setOpenTransformModal] = useState(false);
    const toggleTransformComponent = (showJson: boolean) => {
        if (showJson) {
            togglePreviewQuery()
        }
        setOpenTransformModal(prevState => !prevState)
    };
    const [showMenu, setShowMenu] = useState(true)
    const [temporalKey, setTemporalKey] = useState("")

    const history = useRouter();

    const createTemporalReport = async (newQueryReport: AnalyticQuery) => {

        if (!report && newQueryReport.source.with) {
            const newQuery = {
                name: "temporary_report_" + (new Date().getTime()),
                query: JSON.stringify(getAnalyticQueryFlat({...newQueryReport})),
                active: true,
                truncate: stateLocal.truncate ?? "",
                created_by: user.id
            }


            const response = temporalKey ? await updateModelRow([{
                ...newQuery,
                _KEY: temporalKey
            }], "sbx_crm_report") : await insertSbxModelService({
                row_model: "sbx_crm_report",
                rows: [newQuery]
            });


            if (response?.success) {
                if (!temporalKey && response.keys) {
                    setTemporalKey(response.keys[0])
                }
            }
        }


    }
    const dispatch = useDispatch()
    const checkIsNewQuery = () => !analyticQuery || (JSON.stringify(analyticQuery) !== JSON.stringify(stateLocal.analyticQuery))
    const checkIsWorkingInAnalytics = (action: () => void) => {
        if (checkIsNewQuery()) {
            dispatch(actionsModal.openModal(
                {
                    type: ModalTypes.CONFIRM,
                    onConfirm: () => {
                        action()
                    },
                    closeModal: true,
                    message: <p>{t("custom-message:unsaved-changes-warning")}?</p>
                }))
        } else {
            action()
        }
    }

    React.useEffect(() => {
        if (report?.truncate) {
            dispatchForm({name: "truncate", value: report.truncate})
        }
        if (report) {
            dispatchForm({name: "report", value: report})
        }
        // setQueryReport(report)
    }, [report]);

    const dispatchForm = ({name, value}: { name: keyof ReportReducerState, value: any }) => {

        if (name === "analyticQuery" && !report) {

            createTemporalReport(value)
        }

        dispatchLocal({type: Types.SET_STATE, payload: {value, name}});
    };

    const dispatchMultiForm = (forms: { name: keyof ReportReducerState, value: any }[]) => {

        if (forms.some(form => form.name === "analyticQuery")) {
            forms.forEach(form => {

                if (form.name === "analyticQuery" && !report) {
                    createTemporalReport(form.value)
                }

            })
        }

        dispatchLocal({type: Types.SET_MULTI_STATE, payload: forms});
    };

    /**
     * It gets the columns from the server and updates the state
     * @param  - analyticQuery - the query that will be used to get the columns
     */
    const getColumns = async ({
                                  analyticQuery,
                                  action,
                                  source
                              }: { analyticQuery?: AnalyticQuery | BaseAnalyticQuery, action?: AnalyticQueryAction, source?: Source }) => {
        // dispatchForm({name: 'isLoading', value: State.PENDING});
        dispatchMultiForm([
            {name: 'isLoading', value: State.PENDING},
            // {name: 'sourceColumnsLoading', value: State.PENDING},
        ]);
        const query = analyticQuery ?? stateLocal.analyticQuery

        const data = await getAnalyticJsonColumns(getAnalyticQueryFlat(query as AnalyticQuery));
        if (data?.items && data.items.length > 0) {

            data.items = removeDuplicateFromArray(data.items.filter(column => column));
            const newState: { name: keyof ReportReducerState; value: any; }[] = [
                {name: 'isLoading', value: State.RESOLVED},
                {name: 'columns', value: data.items},
                // {name: 'sourceColumnsLoading', value: State.RESOLVED},
            ];

            if (action?.temporal_id) {
                const actionColumns = {
                    ...stateLocal.actionColumns,
                    [action.temporal_id]: data.items
                };

                newState.push({name: 'actionColumns', value: actionColumns});
            }

            if (source?.temporal_id) {
                const sourceColumns = {
                    ...stateLocal.sourceColumns,
                    [source.temporal_id]: data.items
                };


                newState.push({name: 'sourceColumns', value: sourceColumns});
            }

            dispatchMultiForm(newState);
        } else {
            dispatchMultiForm([
                {name: 'isLoading', value: State.REJECTED},
                // {name: 'sourceColumnsLoading', value: State.REJECTED},
            ]);

        }
    };

    const getActionColumns = (action: AnalyticQueryAction) => {
        if (action?.temporal_id && stateLocal.actionColumns[action.temporal_id]) {

            const fetch = stateLocal.analyticQuery.source.fetch ?? []
            let mainModel = stateLocal.analyticQuery.source.with

            if (mainModel.includes("@sbx")) {
                mainModel = mainModel.replace("@sbx", "_sbx")
            }

            const options: {
                [key: string]: {
                    label: string, options: StringOption[], isFetchColumn?: boolean
                }
            } = {
                [mainModel]: {label: mainModel, options: []}
            }

            if (stateLocal.analyticQuery.source?.sources && stateLocal.analyticQuery.source?.sources.length > 0) {
                stateLocal.analyticQuery.source?.sources.forEach(source => {
                    if (source.with) {
                        options[source.with] = {label: source.with, options: []}
                    }

                })
            }

            fetch.forEach(fetchColumn => {
                if (!options[fetchColumn]) {
                    options[fetchColumn] = {label: fetchColumn, options: [], isFetchColumn: true}
                }
            })

            if (!options["others"]) {
                options["others"] = {label: "others", options: []}
            }

            let columnsSaved: string[] = []


            stateLocal.actionColumns[action.temporal_id].forEach(column => {

                if (!columnsSaved.includes(column)) {
                    Object.keys(options).forEach(model => {
                        if (options[model].isFetchColumn) {
                            const nColumn = column.replace(mainModel + "_", "")
                            if (nColumn.startsWith(model)) {
                                options[model].options.push({label: column, value: column})
                            }
                        } else if (column.startsWith(model)) {

                            if (model === mainModel) {
                                const nColumn = column.replace(mainModel + "_", "")
                                if (!Object.keys(options).some(fetchModel => options[fetchModel].isFetchColumn && nColumn.startsWith(fetchModel))) {
                                    options[model].options.push({label: column, value: column})
                                }
                            } else {
                                options[model].options.push({label: column, value: column})
                            }
                        } else {
                            const nColumn = column.replace(mainModel + "_", "")
                            if (!column.startsWith(model) && !column.startsWith(mainModel) && !Object.keys(options).some(fetchModel => options[fetchModel].isFetchColumn && nColumn.startsWith(fetchModel)) && !options["others"].options.some(option => option.value === column)) {
                                options["others"].options.push({label: column, value: column})
                            }
                        }
                    })
                    columnsSaved.push(column)
                }
            })


            return Object.values(options)
        }

        return [];
    };

    React.useEffect(() => {
        if (setAnalyticQuery) {
            setAnalyticQuery(stateLocal.analyticQuery)
        }

        if (stateLocal.analyticQuery.actions && stateLocal.analyticQuery.actions.length > 0 && stateLocal.report) {
            let nReport: Report = {...stateLocal.report}
            stateLocal.analyticQuery.actions.flat().filter(action => action.type === "rename").forEach(action => {
                if (action.renamed_columns) {
                    Object.keys(action.renamed_columns).forEach(renamedColumn => {
                        if (action.renamed_columns && action.renamed_columns[renamedColumn]) {
                            nReport = JSON.parse(JSON.stringify(nReport).replaceAll(renamedColumn, action.renamed_columns[renamedColumn]))
                        }
                    })
                }
            })

            dispatchForm({name: "report", value: nReport})


        }

    }, [stateLocal.analyticQuery.source, stateLocal.analyticQuery.actions]);

    const getSourceColumns = (source: Source) => {
        if (source?.temporal_id && stateLocal.sourceColumns[source.temporal_id]) {
            return stateLocal.sourceColumns[source.temporal_id].map(column => ({label: column, value: column}));
        }

        return [];
    };

    const getAllActionColumns = async (analyticQuery: AnalyticQuery | BaseAnalyticQuery, fromIndex = 0) => {


        const baseAnalyticQuery = getAnalyticQueryFlat(analyticQuery as AnalyticQuery)

        let actionColumns: { [action_id: string]: string[] } = fromIndex === 0 ? {} : stateLocal.actionColumns ?? {};
        let actionLoading: { [action_id: string]: State } = {};

        for (const action of baseAnalyticQuery.actions) {
            const index = baseAnalyticQuery.actions.findIndex(nAction => nAction.temporal_id === action.temporal_id);

            if (action.temporal_id && !actionLoading[action.temporal_id] && ((fromIndex > 0 && index > fromIndex) || fromIndex === 0)) {
                actionLoading[action.temporal_id] = State.PENDING
            }
        }

        dispatchMultiForm([
            // {name: "isLoading", value: State.PENDING},
            {name: "actionLoading", value: {...actionLoading}},
        ])

        const columnResponse = await getAnalyticJsonColumns(baseAnalyticQuery);
        for (const action of baseAnalyticQuery.actions) {
            if (action.temporal_id && (!actionLoading[action.temporal_id] || actionLoading[action.temporal_id] === State.PENDING)) {
                actionLoading[action.temporal_id] = State.IDLE
            }

            let data: Response<string> = {
                // items: columnResponse.history[index.tsx] as string[]
                items: [] as string[],
                success: false
            }

            const index = baseAnalyticQuery.actions.findIndex(nAction => nAction.temporal_id === action.temporal_id);

            if ((fromIndex > 0 && index >= fromIndex) || fromIndex === 0) {
                //const query = {...baseAnalyticQuery, actions: baseAnalyticQuery.actions.slice(0, index.tsx)};
                // if (fromIndex > 0){
                //     const query = {...baseAnalyticQuery, actions: baseAnalyticQuery.actions.slice(0, index.tsx)};
                //     data = await getAnalyticJsonColumns(query);
                // } else{
                data.items = columnResponse?.history ? columnResponse.history[index] as string[] ?? [] : []
                // }


                if (data?.items && data.items.length > 0 && action.temporal_id) {
                    data.items = removeDuplicateFromArray(data.items.filter(column => column));

                    actionColumns = {
                        ...actionColumns,
                        [action.temporal_id]: data.items
                    };
                }
            }

        }

        dispatchMultiForm([
            {name: 'actionColumns', value: actionColumns},
            {name: "isLoading", value: State.RESOLVED},
            {name: "actionLoading", value: actionLoading},
        ])

    };

    const getAllSourcesColumns = async (analyticQuery: AnalyticQuery | BaseAnalyticQuery) => {

        let sourceColumns: { [action_id: string]: string[] } = {};
        // dispatchForm({name: "isLoading", value: State.PENDING})
        dispatchMultiForm([
            {name: "isLoading", value: State.PENDING},
            {name: "sourceColumnsLoading", value: State.PENDING},
        ])

        const query: BaseAnalyticQuery = {
            source: {...analyticQuery.source},
            actions: []
        };


        if (analyticQuery.source?.sources) {
            query.source = {...query.source, sources: analyticQuery.source.sources}
        }

        const columnResponse = await getAnalyticJsonColumns(query);

        if (analyticQuery.source.sources && analyticQuery.source.sources.length > 0) {
            for (const source of analyticQuery.source.sources) {
                // const index.tsx = analyticQuery.source.sources.findIndex(nSource => nSource.temporal_id === source.temporal_id);
                const data = {
                    items: columnResponse?.history ? columnResponse.history[0] as string[] : []
                }


                if (data?.items && data.items.length > 0 && source.temporal_id) {
                    data.items = removeDuplicateFromArray(data.items.filter(column => column));
                    sourceColumns = {
                        ...sourceColumns,
                        [source.temporal_id]: data.items
                    };
                }
            }
        } else {
            if (analyticQuery.source) {
                if (columnResponse?.items && columnResponse.items.length > 0 && analyticQuery.source.temporal_id) {
                    columnResponse.items = removeDuplicateFromArray(columnResponse.items.filter(column => column));
                    sourceColumns = {
                        ...sourceColumns,
                        [analyticQuery.source.temporal_id]: columnResponse.items
                    };
                }
            }
        }


        dispatchMultiForm([
            {name: 'sourceColumns', value: sourceColumns},
            {name: "isLoading", value: State.RESOLVED},
            {name: "sourceColumnsLoading", value: State.RESOLVED},
        ])
    };

    const togglePreviewQuery = () => dispatchForm({name: 'showPreviewQuery', value: !stateLocal.showPreviewQuery});

    const resetDefaultValues = () => {
        dispatchLocal({type: Types.SET_RESET_STATE})
    }

    React.useEffect(() => {
        if (analyticQuery?.source.with) {

            const newQuery = addTemporalIdToQuery(analyticQuery);

            dispatchMultiForm([
                {name: 'analyticQuery', value: getAnalyticQueryGrouped(newQuery)},
                {name: 'queriesSource', value: getQuerySources(newQuery)}
            ]);


            // getColumns({analyticQuery});
            getAllActionColumns(newQuery);
            getAllSourcesColumns(newQuery);
        } else {
            if (analyticQuery && isModalGenerator) {
                dispatchForm({name: 'analyticQuery', value: analyticQuery})
            }
        }
    }, [analyticQuery]);

    const getAllColumns = () => {
        getAllSourcesColumns(stateLocal.analyticQuery);
        getAllActionColumns(stateLocal.analyticQuery);
    };

    const getReportExecuteActions = () => {
        return [
            {
                name: "save-report",
                icon: stateLocal.isLoading === State.PENDING ? faSpinner : faSave,
                condition: !isModalGenerator,
                pulse: stateLocal.isLoading === State.PENDING,
                disable: (stateLocal.isLoading === State.PENDING && stateLocal.columns.length === 0),
                onClick: () => {


                    if (stateLocal.truncate) {
                        const truncate: TruncateReport = IsJsonString(stateLocal.truncate) ? JSON.parse(stateLocal.truncate) : stateLocal.truncate
                        const condition1 = !truncate.cache ? false : Object.keys(truncate.cache!).filter(key => typeof truncate.cache![key as keyof typeof truncate.cache] !== "boolean").length > 0 && Object.keys(baseCache).length !== Object.keys(truncate.cache!).length
                        const condition2 = truncate.type === "text" && (((truncate.model && !truncate.label_field) || !truncate.value) || truncate.others?.some(nTruncate => ((nTruncate.model && !nTruncate.label_field) || !nTruncate.value)))
                        if (truncate?.cache && (condition1 || condition2)) {
                            toast({type: "warn", message: t("custom-message:truncate-missing-fields")})
                            return
                        }
                    }


                    getColumns({analyticQuery: stateLocal.analyticQuery});
                    dispatchForm({name: 'showModal', value: true})
                }
            },
            {
                name: "watch-report",
                icon: faEye,
                disable: !report,
                onClick: () => {
                    checkIsWorkingInAnalytics(() => history.push('/analytics/crm-reports/' + report?._KEY))
                }
            },

            {
                name: "preview-query",
                icon: faCode,
                color: "info",
                onClick: () => {
                    // handleData()
                    togglePreviewQuery()

                }
            },
            {
                name: "transform-query",
                icon: faExchangeAlt,
                disable: stateLocal.isLoading === State.PENDING,
                onClick: () => toggleTransformComponent(false)
            },
            {
                name: "refresh-column",
                icon: faSyncAlt,
                disable: stateLocal.isLoading === State.PENDING || !isValidAnalyticQuery(getAnalyticQueryFlat(stateLocal.analyticQuery)),
                onClick: getAllColumns
            }, {
                name: "reset-values",
                icon: faTimes,
                color: "info",
                disable: stateLocal.isLoading === State.PENDING || !isValidAnalyticQuery(getAnalyticQueryFlat(stateLocal.analyticQuery)),
                onClick: () => {
                    dispatch(actionsModal.openModal({
                        type: ModalTypes.CONFIRM,
                        onConfirm: () => {
                            resetDefaultValues()
                            dispatch(actionsModal.closeModal({
                                type: ModalTypes.CONFIRM
                            }))
                        },
                        message: <>Are you sure to reset the query for default values?</>,
                        title: <><FontAwesomeIcon
                            icon={faTrash}/> Reset default values</>,
                    }))
                }
            },
            {
                name: (showMenu ? t("common:hide") : t("common:show")) + ' ' + t("common:menu"),
                icon: showMenu ? faEyeSlash : faEye,
                color: "primary",
                showName: true,
                className: "d-none d-lg-block",
                onClick: () => setShowMenu(prevState => !prevState)
            }
        ]
    }

    const getReportView = (view: string) => {
        return {
            source: <SourceComponent/>,
            actions: <ActionsComponent/>,
            preview: <PreviewResultsComponent/>,
            ml: <ActionsComponent showMlActions/>,
            truncate: <TruncateGeneratorComponent analyticQuery={stateLocal.analyticQuery}
                                                  truncate={report?.truncate as string ?? ""}
                                                  columns={stateLocal.columns}
                                                  setReportTruncate={truncate => {
                                                      dispatchForm({name: "truncate", value: truncate})
                                                  }}/>
        }[view] ?? <BuildingFeatureComponent/>
    }

    return (
        <div className="card">


            <div className="d-flex flex-column">

                <div className={`d-flex align-items-center py-3 justify-content-${report ? "between" : "end"}`}>
                    {report && <h4 className="text-primary fw-bold px-3">{report.name}</h4>}
                    <div className="d-flex gap-3 align-items-center justify-content-end my-2 px-3">
                        {getReportExecuteActions().filter(action => action.hasOwnProperty("condition") ? action.condition : true).map(action => {
                            return <Tooltip title={t(action.name)} key={action.name} className={action.className ?? ""}>
                                <Button onClick={action.onClick} outline disabled={action.disable}
                                        color={action.color ?? "primary"}>
                                    <FontAwesomeIcon pulse={action.pulse} icon={action.icon}/> {' '}
                                    {action.showName && <span>{t(action.name)}</span>}
                                </Button>
                            </Tooltip>
                        })}

                    </div>
                </div>


                <Container showMenu={showMenu}>
                    <ReportGeneratorContext.Provider
                        value={{
                            reportState: {...stateLocal, showMenu},
                            dispatchForm,
                            togglePreviewQuery,
                            dispatchMultiForm,
                            getSourceColumns,
                            getColumns,
                            getActionColumns,
                            getAllSourcesColumns,
                            getAllActionColumns,
                            resetDefaultValues
                        }}>

                        <ReportGenerationOptions setCurrentOption={setCurrentOption} showMenu={showMenu}/>

                        <div className="">
                            {getReportView(currentOption)}
                        </div>
                        {openTransformModal && <JsonToQueryComponent toggle={toggleTransformComponent}/>}
                    </ReportGeneratorContext.Provider>

                </Container>
            </div>


            {stateLocal.showModal &&
                <SaveReportModal isOpen={stateLocal.showModal} report={stateLocal.report}
                                 toggle={() => dispatchForm({name: 'showModal', value: false})}
                                 reportState={stateLocal}/>}
            {stateLocal.showPreviewQuery &&
                <ReportQueryPreview query={{
                    ...stateLocal.analyticQuery,
                    truncate: IsJsonString(stateLocal.truncate) ? JSON.parse(stateLocal.truncate) : stateLocal.truncate
                }} toggleCanvas={togglePreviewQuery}/>}

        </div>
    );
};

export default ReportGeneratorComponent;
