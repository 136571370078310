import fetchJson from '../../lib/fetchJson';
import {blobToBase64, fileToBase64} from '../../utils';
import {post} from '../../network';

// export const uploadFile = async (token: string, data: { account: string, file_name: string, path: string, file: File }) => {
//   let newData: any = Object.assign({}, data);
//   if (!token?.toLowerCase().startsWith('bearer')) {
//     token = `Bearer ${token}`;
//   }
//
//   let file1: string = await fileToBase64(data.file) as string;
//   file1 = file1.split('base64,')[1];
//
//   delete newData.file;
//   try {
//     return await fetchJson(`/api/v2/content/upload`, {
//       method: 'POST', // *GET, POST, PUT, DELETE, etc.
//       cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
//       headers: {
//         'Content-Type': 'application/json',
//         'authorization': token,
//       },
//       redirect: 'follow', // manual, *follow, error
//       referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
//       body: JSON.stringify({...newData, file_content: file1}),
//     });
//
//   } catch (error: any) {
//     console.error(error);
//     return {success: false, error: error.message};
//   }
// };

export const uploadFile = async ({data, url}: {data: {file?: File, path?: string, file_name?: string, blob?: Blob}, url?: string}) => {
  let newData: any = Object.assign({}, data);
  let file1: string = ""
  if (data.file) {
    file1 = await fileToBase64(data.file) as string;
    file1 = file1.split('base64,')[1];
  }
  if (data.blob) {
    file1 = await blobToBase64(data.blob) as string;
  }

  delete newData.file;

  if (data.blob) {
    delete newData.blob;
  }

  return post(url ?? "/api/v2/content/upload", {...newData, file_content: file1})
}

export const uploadAudioFile = async ({blob, lang}: { blob: Blob, lang: string }) => {
  const file1 = await blobToBase64(blob) as string;
  return post("/api/v2/content/upload_audio", {voice_message: file1, lang})
}


export const downloadFile = async (fileKey: string) => {
  try {
    return await fetchJson(`/api/v2/content/download?key=${fileKey}`, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    });

  } catch (error: any) {
    console.error(error);
    return {success: false, error: error.message};
  }
};
