import * as React from 'react';
import {useContext} from 'react';
import {ReportContext} from "../../NewReportGeneratorComponent";
import useTranslate from "../../../../hooks/useTranslate";
import {AnalyticQueryAction} from "../../../../types/Analytic";
import SelectComponent from "../../../Shared/FieldComponents/SelectComponent";

type Props = {
    action: AnalyticQueryAction
    index: number
};
const NewForecastComponent = ({action, index}: Props) => {

    const {getActionColumns, setQueryAction, getLoadingAndDisabledAction} = useContext(ReportContext)
    const {t} = useTranslate('common');

    return (
        <div className="d-flex align-items-center flex-wrap gap-2">

            <div className="d-flex flex-column flex-grow-1">
                <span>{t("column")} X (YYYYMM)</span>

                <SelectComponent id={'x'} name={'x'} defaultValue={action.x ? {label: action.x, value: action.x} : null}
                                 menuPosition={"fixed"}
                                 disabled={getLoadingAndDisabledAction({action, index})}
                                 loading={getLoadingAndDisabledAction({action, index})}
                                 options={getActionColumns(action, index)}
                                 onChange={evt => {
                                     setQueryAction({
                                         ...action,
                                         x: evt.value
                                     }, index)
                                 }} placeholder={"ej: purchase total"}/>

            </div>

            <div className="d-flex flex-column flex-grow-1">
                <span>{t("column")} Y</span>
                <SelectComponent id={'y'} menuPosition={"fixed"}
                                 defaultValue={action.y ? {label: action.y, value: action.y} : null} name={'y'}
                                 disabled={getLoadingAndDisabledAction({action, index})}
                                 loading={getLoadingAndDisabledAction({action, index})}
                                 options={getActionColumns(action, index)}
                                 onChange={evt => {
                                     setQueryAction({
                                         ...action,
                                         y: evt.value
                                     }, index)
                                 }} placeholder={"ej: purchase date"}/>
            </div>


            <div className="d-flex flex-column">
                <span>{t("report:forecast")} {t("from")}</span>
                <input type="text" className="form-control"
                       disabled={getLoadingAndDisabledAction({action, index})}
                       placeholder="ej: 202206" defaultValue={action.forecast_from || ''} onChange={evt => {
                    setQueryAction({
                        ...action,
                        forecast_from: evt.currentTarget.value
                    }, index)
                }}/>
            </div>

            <div className="d-flex flex-column">
                <span>{t("report:forecast")} {t("to")}</span>
                <input type="text" className="form-control"
                       disabled={getLoadingAndDisabledAction({action, index})}
                       defaultValue={action.forecast_to || ''}
                       placeholder="ej: 202207" onChange={evt => {

                    setQueryAction({
                        ...action,
                        forecast_to: evt.currentTarget.value
                    }, index)

                }}/>
            </div>
        </div>
    );
};

export default NewForecastComponent