import * as React from 'react';
import {Field} from "../../../types/Field";
import {Control, Controller} from "react-hook-form";
import {Switch} from "antd";
import useTranslate from "../../../hooks/useTranslate";
import {useContext, useEffect, useState} from "react";
import {CrmInputContext} from "./CrmRenderInput";
import {TaskComponentContext} from "../../../context/TaskContext";

type Props = {
  field: Field,
  control?: Control<any, object>
};

const CrmBoolInputComponent = ({field, control}: Props) => {
  const {t} = useTranslate('common');
  const [defaultValue, setDefaultValue] = useState<string>('');
  const {getDefaultValue} = useContext(CrmInputContext)
  const {formState} = useContext(TaskComponentContext);
  
  useEffect(() => {
    setTimeout(() => {
      setDefaultValue(getDefaultValue(field) || "");
    }, 1000)
  }, [formState]);

  return (
    <Controller
      control={control}
      name={field.name}
      defaultValue={defaultValue}
      rules={{required: field.required && !field.read_only}}
      render={({field: {onChange}}) => (
        <Switch checkedChildren={t('common:yes')} unCheckedChildren="No"
                onChange={onChange}
        />
      )}
    />
  );
};

export default CrmBoolInputComponent