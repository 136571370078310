import * as React from 'react';
import {CSSProperties, useEffect, useRef} from 'react';
import {StringOption} from "../../../types/Select";


type Props = {
    setValue: (value: string) => void
    value: string
    options: StringOption[]
    id: string | number
    style?: CSSProperties
    search?: string
    setFocusField?: (focus: boolean) => void
};

const SuggestionRuleSearchComponent = ({
                                           setValue,
                                           value,
                                           options,
                                           id,
                                           setFocusField,
                                           style,
                                           search
                                       }: Props) => {

    const suggestionsRef = useRef<HTMLDivElement | null>(null);

    const setSuggestionValue = (suggestionValue: string, id: number | string) => {

        const element: any = document.getElementById(id.toString())
        let defaultValue = (value ?? "")

        if (element) {
            const [start, end] = [element.selectionStart, element.selectionEnd];
            const words = value.split(" ");
            const cursorWordIndex = value.substr(0, end).split(" ").length - 1;
            const currentWord = words[cursorWordIndex].replace(/[^a-zA-Z]/g, '')

            let nValue = defaultValue.slice(0, start ?? 1) + ' ' + (suggestionValue ?? '') + ' ' + defaultValue.slice(end ?? 1).trim()

            if (currentWord) {
                const regexPattern = new RegExp("\\b" + currentWord + "\\b", "");
                nValue = nValue.replace(regexPattern, '');
            }
            setValue(nValue.trim())
        }
    }

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (suggestionsRef.current && !suggestionsRef.current.contains(event.target as Node)) {
                if (setFocusField) {
                    setFocusField(false)
                }
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);


    return (
        <div className="position-absolute bg-white shadow border p-2 z-index-1000" style={style ?? {}}
             ref={suggestionsRef}>
            <div className="d-flex flex-column  overflow-auto"
                 style={{maxHeight: '200px', maxWidth: '400px', minWidth: '300px'}}>

                <span className="text-end pointer px-1" onClick={() => {
                    setFocusField && setFocusField(false)
                }}>X</span>

                <ul className="list-group list-group-flush">
                    {options.filter(pField =>
                        search && search?.length > 0 ? pField.value.toLowerCase().includes(search.toLowerCase()) :
                            true)
                        .map(dataField => (
                            <li key={dataField.value} onClick={() => {
                                setSuggestionValue(dataField.value, id)
                                if (setFocusField) {
                                    setFocusField(false)
                                }
                            }}
                                className="list-group-item pointer shading">{dataField.value}</li>
                        ))}
                </ul>


            </div>

            {/*{setFocusFieldName && <div className=" text-end">*/}
            {/*    <small className="fw-bold pointer text-link mx-2" style={{textDecoration: "underline"}}*/}
            {/*           onClick={() => setFocusFieldName && setFocusFieldName("")}>{t("close")}</small>*/}
            {/*</div>}*/}
        </div>
    );
};

export default SuggestionRuleSearchComponent