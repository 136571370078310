import {capitalize} from '../../utils';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckCircle, faPen, faSpinner, faTimesCircle} from '@fortawesome/free-solid-svg-icons';
import React, {FormEvent, useState} from 'react';
import {Input} from 'reactstrap';
import useTranslate from '../../hooks/useTranslate';

interface IProps {
  title: string;
  label?: string;
  onEdit: (str: string) => void;
  loading: boolean;
  type?: "text" | "number",
  size?: string;
  direction?: "center" | "end" | "start";
  maxContentWidth?: boolean,
  submit?: boolean;
  isDuplicateProcess?: boolean;

}

const EditString = ({
                      title,
                      onEdit,
                      loading,
                      type = "text",
                      size,
                      label,
                      direction = "start",
                      maxContentWidth,
                      submit = true,
                      isDuplicateProcess
                    }: IProps) => {

  const [edit, setEdit] = useState({edit: false, value: ""})
  const {t} = useTranslate("common");

  function onSubmit(e?: FormEvent<HTMLFormElement>) {
    if (e) {
      e.preventDefault();
    }
    onEdit(edit.value ? edit.value : title);
    setEdit({edit: false, value: ""})
  }

  return (
    <>
      {!edit.edit ? (
        <div className="d-flex flex-column">
          <div className={'d-flex justify-content-' + direction}>
            {size ? <span className={`text-${size}-left`}>{label} {capitalize(title)}</span> :
              <h3>{capitalize(title)}</h3>}
            <FontAwesomeIcon
              aria-disabled={loading}
              onClick={() => setEdit({edit: true, value: title})}
              size="xs"
              spin={loading}
              className="text-primary mb-2 pointer ms-2"
              icon={loading ? faSpinner : faPen}/>
          </div>

          <div>
          {isDuplicateProcess &&  <button
            onClick={() => {
              onSubmit()
            }}
            className="btn btn-success btn-sm">
            {<FontAwesomeIcon icon={faCheckCircle} className="me-1"/>} {' ' + t('save') + " " + t("duplicate")}
          </button>}
          </div>
        </div>
      ) : (
        <form onSubmit={onSubmit}>
          <div className={(maxContentWidth ? "col-12" : "col-12 col-lg-6") + " d-flex"}>
            <div className="w-75">
              <Input
                type={type}
                required
                name="name"
                value={edit.value}
                onChange={e => setEdit(val => ({...val, value: e.target.value}))}/>
            </div>
            <div className="pt-2">
              <button
                type={submit ? "submit" : "button"}
                onClick={() => !submit && onSubmit()}
                className="btn btn-primary btn-sm mx-2">
                {<FontAwesomeIcon icon={faCheckCircle} className="me-1"/>} {" " + t("save")}
              </button>
              <button
                type="button"
                onClick={() => setEdit({edit: false, value: title})}
                className="btn btn-light  btn-sm shadow-sm">
                {<FontAwesomeIcon icon={faTimesCircle} className="me-1"/>}{" " + t("cancel")}
              </button>
            </div>
          </div>
        </form>
      )}
    </>
  )
}

export default EditString;
