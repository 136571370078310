import * as React from 'react';
import {useState} from 'react';
import useTranslate from "../../../hooks/useTranslate";
import {ModelsResponse} from "../../../types/Sbx";
import {StringOption} from "../../../types/Select";
import {toast, truncateArray} from "../../../utils";
import {
    formatDateOptions
} from "../ActionsComponent/TransformComponent/DateTransformComponent/DateTransformFormComponent";
import {TruncateReport, TruncateType} from "../../../types/Analytic";
import ButtonComponent from "../../Shared/ButtonComponent";
import {faInfoCircle, faTrash} from "@fortawesome/free-solid-svg-icons";
import {Controller, UseFieldArrayRemove, UseFieldArrayUpdate} from "react-hook-form";
import CreatableSelectComponent from "../../Shared/FieldComponents/CreatableSelectComponent";
import PopoverComponent from "../../Shared/PopoverComponent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


type Props = {
    models: ModelsResponse[]
    isLoading: boolean
    mainTruncate: TruncateReport
    isExtraTruncate?: boolean,
    control: any
    columns: string[]
    update: UseFieldArrayUpdate<TruncateReport, "others">
    remove?: UseFieldArrayRemove
    truncate?: TruncateReport
    reset?: (items: any) => void
};

type TruncateTypeForm = "options" | "text"

type Field = {
    field: string, disabled: boolean, sortOptions?: boolean, required?: boolean, type: TruncateTypeForm,
    onChange?: (value: string | number) => void, options?: { label: string, value: string | number }[], hideRemove?: boolean
}

const TruncateFormComponent = ({
                                   models,
                                   isLoading,
                                   mainTruncate,
                                   isExtraTruncate,
                                   truncate, reset,
                                   control, remove, update, columns
                               }: Props) => {

    const {t} = useTranslate("common");
    const [fieldOptions, setFieldOptions] = useState<StringOption[]>([])

    const getTruncateId = (truncateReport: TruncateReport | null): any => {

        if (mainTruncate.others && truncateReport) {
            const idx = mainTruncate.others.findIndex(nTruncate => nTruncate.temporal_id === truncateReport.temporal_id)
            if (idx !== -1) {
                return idx
            }
        }

        return null
    }

    const resetTruncate = (type: string)=>{
        if (reset) {
            reset({
                range: "",
                format: "",
                field: "",
                model: "",
                others: [],
                type: type
            })
        }
    }

    const getFormFields = (type: TruncateType) => {
        return {
            date: [
                {
                    field: "type",
                    disabled: isExtraTruncate,
                    type: "options",
                    onChange: (value: string ) => {
                        resetTruncate(value)
                    },
                    hideRemove: true,
                    options: [{label: t("text"), value: "text"}, {label: t("date"), value: "date"}]
                },
                {
                    field: "model",
                    type: "options",
                    required: true,
                    disabled: isLoading,
                    options: [...models.map(model => ({
                        label: model.name,
                        value: model.name
                    })), {label: "Ninguno", value: ""}]
                },
                {field: "field", type: "options", disabled: isLoading, options: fieldOptions},
                {
                    field: "range",
                    type: "options",
                    required: true,
                    disabled: isLoading || isExtraTruncate,
                    onChange: (value: string | number) => {
                        if (mainTruncate.others && mainTruncate.others.length > 0){
                            mainTruncate.others?.forEach((trunc, index) => {
                                update(index, {...trunc as TruncateReport, range: value})
                            })
                        }
                    },
                    options: truncateArray.map(truncate => ({label: t(truncate.name), value: truncate.value})),
                    sortOptions: false
                },
                {
                    field: "format",
                    type: "options",
                    required: true,
                    disabled: isLoading,
                    options: formatDateOptions
                },
            ],
            text: [
                {
                    field: "type",
                    disabled: isExtraTruncate,
                    type: "options",
                    options: [{label: t("text"), value: "text"}, {label: t("date"), value: "date"}]
                },
                {
                    field: "model", type: "options", disabled: isLoading, options: [...models.map(model => ({
                        label: model.name,
                        value: model.name
                    })), {label: "Ninguno", value: ""}]
                },
                {
                    field: "value",
                    type: "text",
                    required: true,
                    disabled: isExtraTruncate,
                    onChange: (value: string | number) => {
                        if (mainTruncate.others && mainTruncate.others.length > 0){
                            mainTruncate.others?.forEach((trunc, index) => {
                                update(index, {...trunc as TruncateReport, value: value as string})
                            })
                        }
                    },
                },
                {
                    field: "label_field",
                    type: "options",
                    disabled: isLoading,
                    options: fieldOptions,
                    required: truncate?.model,
                }
            ],
            reference: []
        }[type] as Field[]
    }


    React.useEffect(() => {
        if (((isExtraTruncate && truncate?.model )|| (!isExtraTruncate && mainTruncate.model))) {
            setFieldOptions(models.find(model => model.name === (isExtraTruncate ? truncate?.model : mainTruncate.model))?.properties?.map(property => (
                {label: property.name, value: property.name}
            )) ?? [])
        } else {
            setFieldOptions(columns.map(column => ({label: column, value: column})))
        }
    }, [truncate, columns, mainTruncate, models]);


    const getField = (field: Field) => {

        switch (field.type) {
            case "options":
                return <Controller control={control}
                                   name={(isExtraTruncate && truncate) ? `others.${getTruncateId(truncate)}.${field.field}` : field.field as keyof TruncateReport}
                                   render={({field: {value, onChange, name}}) => {

                                       const selectValue = field.options?.find(nField => nField.value === value)

                                       return <div><CreatableSelectComponent menuPosition={"fixed"}
                                                                             className="flex-grow-1"
                                                                             id={`id_${name}_${truncate?.temporal_id}_${(isExtraTruncate && truncate && mainTruncate) ? `others.${getTruncateId(truncate)}.${field.field}` : field.field}`}
                                                                             loading={isLoading}
                                                                             disabled={field.disabled}
                                                                             sortOptions={field.hasOwnProperty("sortOptions") ? field.sortOptions : true}
                                                                             options={field.options ?? []}
                                                                             onChange={event => {
                                                                                 if (isExtraTruncate && truncate && getTruncateId(truncate) !== -1) {
                                                                                     update(getTruncateId(truncate), {
                                                                                         ...truncate,
                                                                                         [field.field]: event.value
                                                                                     })
                                                                                 }

                                                                                 onChange(event.value)

                                                                                 if (field.onChange) {
                                                                                     field.onChange(event.value)
                                                                                 }


                                                                             }}
                                                                             name={`name_${name}_${truncate?.temporal_id}_${(isExtraTruncate && truncate && mainTruncate) ? `others.${getTruncateId(truncate)}.${field.field}` : field.field}`}
                                                                             value={selectValue?.label ? selectValue : typeof value !== undefined ? {
                                                                                 label: value,
                                                                                 value
                                                                             } : null}/>
                                           {!!value && !field.hideRemove && <div className="text-end">
                                               <span className="pointer underline text-primary" onClick={() => {
                                                   onChange(null)
                                               }}>{t("remove")}</span>
                                           </div>}
                                       </div>
                                   }}
                />
            default:
                return <Controller control={control}
                                   rules={{required: field.required ?? false}}
                                   name={(isExtraTruncate && truncate) ? `others.${getTruncateId(truncate)}.${field.field}` : field.field as keyof TruncateReport}
                                   render={({field: {value, onChange, name}}) => {
                                       return <input type="text" className="form-control" onBlur={() => {
                                           if (!value) {
                                               toast({type: "warn", message: t("custom-message:field_required")})
                                           }
                                       }} value={value} name={name} disabled={field.disabled}
                                                     onChange={event => {
                                                         onChange(event.currentTarget.value)
                                                         if (field.onChange){
                                                             field.onChange(event.currentTarget.value)
                                                         }
                                                     }}/>
                                   }}
                />
        }


    }

    return (
        <div className="card d-flex flex-column flex-grow-1 p-4"
             key={truncate?.temporal_id + "_" + truncate?.model}>

            {!isExtraTruncate && <div className="d-flex justify-content-end">
                {(mainTruncate?.range || mainTruncate.value) &&
                    <ButtonComponent label={`${t("remove")} ${t("truncate")} `} onClick={() => resetTruncate("date")}
                                     outline color={"primary"}/>}
            </div>}

            {getFormFields((truncate ?? mainTruncate).type).map(field => (
                <div className="d-flex flex-column"
                     key={(isExtraTruncate && truncate && mainTruncate) ? `others.${getTruncateId(truncate)}.${field.field}` : field.field}>
                    <div className="d-flex align-items-center gap-2">
                        <span>{t(field.field)}: </span>
                        <span className="pointer"><PopoverComponent buttonClassName={"m-0 p-0"}
                                                                    label={<FontAwesomeIcon className="text-primary m-0" icon={faInfoCircle}/>}
                                                                    trigger="hover" placement="top"
                                                                    id={field.field + "_pop_info"}>
                            <span>{t("custom-message:field_required")}</span></PopoverComponent></span>
                    </div>

                    {getField(field)}

                </div>
            ))}



            {isExtraTruncate && mainTruncate.temporal_id !== truncate?.temporal_id &&
                <div className="d-flex justify-content-end mt-2">
                    <ButtonComponent label={t("remove")}
                                     onClick={() => {
                                         if (isExtraTruncate && truncate) {
                                             const idx = getTruncateId(truncate)
                                             if (remove) {
                                                 remove(idx)
                                             }
                                         }
                                     }}
                                     icon={faTrash} disabled={isLoading} loading={isLoading}
                                     color={"danger"}/>
                </div>}
        </div>


    )
};

export default TruncateFormComponent