import * as React from 'react';
import {useContext, useMemo, useState} from 'react';
import {ModelsAndFields, SourceFrom, TruncateReport} from "../../types/Analytic";

import {
    Control,
    Controller,
    FieldPath,
    useFieldArray,
    useForm,
    UseFormRegister,
    UseFormSetValue,
    UseFormUnregister,
    UseFormWatch
} from "react-hook-form";
import {Model, ModelsResponse, SbxModelField} from "../../types/Sbx";
import {ReportContext} from "./NewReportGeneratorComponent";
import useAsyncEffect from "../../hooks/useAsyncEffect";
import {StringOption} from "../../types/Select";
import useTranslate from "../../hooks/useTranslate";
import styled from "styled-components";
import {Switch} from "antd";
import TruncateRelativeComponent from "./TruncateRelativeComponent";
import {faArrowLeft, faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import ButtonComponent from "../Shared/ButtonComponent";
import {ExtraPropsForm, PropsForm} from "./NewTruncateReportActionComponent";
import {removeDuplicateFromArrayObj} from "../../utils";
import {dateTransformList} from "../../utils/analyticsUtils";
import {format} from "date-fns";
import ColumnsActionsWrapperComponent from "./ColumnsActionsWrapperComponent";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import ActionDropdownColumns from "./NewActionsReportGeneratorComponent/ActionDropdownComponent/ActionDropdownColumns";
import SearchSuggestionOption from "./SearchSuggestionOption";
import {State} from "../../types/State";
import {fetchModelByReferences, findAllModels} from "../../services/backend/SbxService";


type TruncateFieldName = "truncate" | `others.${number}`

type Props = {
    name: TruncateFieldName
    watch: UseFormWatch<PropsForm | ExtraPropsForm>
    control: Control<(PropsForm | ExtraPropsForm), any>
    setValue: UseFormSetValue<(PropsForm | ExtraPropsForm)>
    register: UseFormRegister<(PropsForm | ExtraPropsForm)>
    unregister?: UseFormUnregister<PropsForm>
    // loading: State
    isExtraField?: boolean
};

const ContainerInputs = styled.div<{ isExtraField?: boolean }>`
    display: grid;
    align-items: end;
    grid-template-columns: ${props => props.isExtraField ? "10em auto" : "auto 10em repeat(5, auto)"};
    justify-content: flex-start;
    flex-grow: 1;
    gap: 8px;

    @media (max-width: 799px) {
        grid-template-columns: 1fr;
    }
`

const truncateType = [{
    name: "date",
},
    {
        name: "text"
    },
    {
        name: "reference",
        source: [SourceFrom.SBX_DATA]
    }
]

const TruncateRowComponent = ({
                                  name,
                                  control,
                                  watch,
                                  setValue,
                                  register,
                                  unregister,
                                  isExtraField
                              }: Props) => {
    const {t} = useTranslate("common");
    const {query, getColumns, state: {models}} = useContext(ReportContext)
    const [sbxOptions, setSbxOptions] = useState<StringOption[]>([])
    const [baseOptions, setBaseOptions] = useState<StringOption[]>([])
    const [relative, setRelative] = useState(false)
    const [modelsUsed, setModelsUsed] = useState<string[]>([])
    const [fieldSelected, setFieldSelected] = useState<Model | undefined>(undefined)
    const [loading, setLoading] = useState(State.IDLE)
    const [loadingReference, setLoadingReference] = useState(State.IDLE)
    const [isEventTruncate, setIsEventTruncate] = useState(false)
    const [isSbxModel, setIsSbxModel] = useState(true)
    const [referenceColumns, setReferenceColumns] = useState<string[]>([])
    const onMount = React.useRef(false);

    const getDefaultColumns = async () => {
        let reportColumns: StringOption[] = []

        setLoading(State.PENDING)

        const columns = await getColumns(query)
        if (columns?.history && columns.history[columns.history.length - 1]) {
            reportColumns = columns.history[columns.history.length - 1].map((column) => ({
                label: column,
                value: column,
                data: null
            }))
        }

        const modelsUsedList = query.actions.flat().reduce((arr: string[], action) => {

            if (action.type === "merge" && action.source?.with) {
                arr.push(action.source.with)
            }

            return arr;
        }, [query.source.with])

        setModelsUsed(modelsUsedList)

        const newBaseOptions = [{
            label: t("report:none"),
            value: ""
        }, ...models.filter(model => modelsUsedList.length > 0 ? modelsUsedList.includes(model.name) : true).map(model => ({
            label: model.name,
            value: model.name,
            data: query.source.from === SourceFrom.SBX_EVENT ? null : model
        })).sort((a, b) => a.label.localeCompare(b.label)), ...reportColumns]

        if (newBaseOptions.length > 0 && query?.source?.with && !newBaseOptions.some(option => option.value === query.source.with)) {
            newBaseOptions.splice(1, 0, {label: query.source.with, value: query.source.with})
        }

        setBaseOptions(newBaseOptions)
        setLoading(State.RESOLVED)
    }
    const [menuIsOpen, setMenuIsOpen] = useState(false)

    const {
        control: controlModels,
        watch: watchModels,
        register: registerModels,
        setValue: setValueModels,
    } = useForm<ExtraPropsForm>({
        defaultValues: {
            models_and_fields: watch(name)?.models_and_fields ?? []
        }
    })

    const {fields, append, remove} = useFieldArray({
        control: controlModels,
        name: "models_and_fields",
    })
    React.useEffect(() => {
        const truncateForm = watch(name)
        if ((!truncateForm.model || !truncateForm.field)) {
            setSbxOptions(baseOptions)
        }


        if (!onMount.current && modelsUsed.length > 0) {

            if (truncateForm.model && !modelsUsed.includes(truncateForm.model)) {
                setSbxOptions(baseOptions)
                setIsSbxModel(false)
            }

            onMount.current = true
        }


    }, [baseOptions, modelsUsed]);

    React.useEffect(() => {
        getDefaultColumns()
    }, []);

    React.useEffect(() => {
        if (query?.source?.from) {
            setIsEventTruncate(query.source.from === SourceFrom.SBX_EVENT)
        }
    }, [query]);

    useAsyncEffect(async () => {
        const truncateForm = watch(name)
        if ((truncateForm as TruncateReport).model && !isEventTruncate && models.some(model => model.name === (truncateForm as TruncateReport).model)) {
            setSbxOptions(models.find(model => model.name === (truncateForm as TruncateReport)?.model)?.properties?.map(property => (
                {label: property.name, value: property.name, data: property}
            )) ?? [])
        }

        if (!isExtraField) {
            if ((truncateForm as TruncateReport)?.relative) {
                setRelative((truncateForm as TruncateReport)?.relative as boolean)
            }
        }


        if (isEventTruncate) {
            setValue(`${name}.format`, '%Y-%m-%dT%H:%M:%S.000Z')
        } else {
            setValue(`${name}.format`, truncateForm?.format ?? "")
        }


    }, [models, isEventTruncate]);

    const getTruncateValue = () => {
        const truncate = watch(name)

        return (!(truncate as TruncateReport)?.field && !(truncate as TruncateReport)?.model) ? t("select_placeholder") : `${(truncate as TruncateReport)?.model ? (truncate as TruncateReport)?.model + `${(truncate as TruncateReport)?.field ? "." : ""}` : ""}${(truncate as TruncateReport)?.field ?? t("select_placeholder")}`
    }

    React.useEffect(() => {
        const subscription = watchModels((obj) => {
                if (obj.models_and_fields && obj.models_and_fields.length > 0) {
                    const fields = obj.models_and_fields.filter(field => (field?.model || field?.field) && field?.name)
                    if (fields.length > 0) {
                        setValue(`${name}.models_and_fields`, fields as ModelsAndFields[])
                    }
                } else {
                    setValue(`${name}.models_and_fields`, [])
                }
            }
        )
        return () => subscription.unsubscribe()
    }, [watchModels])

    useAsyncEffect(async () => {
        const subscription = watch(async (obj) => {

                const truncate = watch(name)

                if (truncate && truncate.type === "reference" && truncate.field?.split(".").at(-1)) {

                    const parentModel = truncate.field?.split(".").at(-2) ?? truncate.model
                    if (parentModel) {
                        setLoadingReference(State.PENDING)
                        const sbxModels = await findAllModels();
                        if (sbxModels?.success && sbxModels.items) {
                            const modelId = (sbxModels.items as ModelsResponse[]).find((model) => model.name === parentModel)?.properties.find((prop) => prop.name === truncate.field?.split(".").at(-1))?.reference_type
                            if (modelId) {
                                const modelRef = await fetchModelByReferences([modelId])
                                if (modelRef && modelRef[modelId]?.name) {
                                    setReferenceColumns(modelRef[modelId].properties.map((model) => model.name))
                                }
                                setLoadingReference(State.RESOLVED)
                            }
                        } else {
                            setLoadingReference(State.RESOLVED)
                        }

                    }
                }


            }
        )
        return () => subscription.unsubscribe()
    }, [watch, name, setLoadingReference])

    const dateList = useMemo(() => {
        const today = new Date()
        return [...dateTransformList.map(item => ({
            ...item,
            label_render: <span></span>
        }))].map(item => {
            item.label_render =
                <span> <b>{t(`report:${item.label}`)}:</b> {format(today, item.format ?? "") ?? ""}</span>

            item.label = t(`report:${item.label}`)
            return item
        })
    }, [])

    return <div className={`d-flex flex-column gap-3 ${!isExtraField ? "border-bottom flex-grow-1 " : ""} `}>
        <ContainerInputs
            isExtraField={isExtraField && ["date"].some(truncateType => watch(name).type === truncateType)}>


            <div className="d-flex flex-column">
                <span>{t("common:type")}</span>
                <Controller render={({field}) => {

                    let label = field.value
                        ? truncateType.find(option => option.name === field.name)?.name ?? field.value : undefined

                    if (label) {
                        label = t(`common:${label}`)
                    }

                    return <ActionDropdownColumns color={'#1b85b8'} onChange={() => {
                    }} columns={[]} label={
                        label ?? t("common:select_placeholder")}>
                        <ColumnsActionsWrapperComponent>
                            {search => {
                                return <>
                                    {truncateType.filter(truncate => truncate.source ? truncate.source.includes(query.source.from) : true).filter(truncate => truncate.name.toLowerCase().includes(search.toLowerCase())).map(truncate => (
                                        <DropdownItem
                                            key={`date_${truncate.name}`}
                                            className="d-flex gap-2 align-items-center"
                                            onClick={() => {
                                                field.onChange(truncate.name)
                                            }}>
                                            {t(`common:${truncate.name}`)}
                                        </DropdownItem>
                                    ))}
                                </>
                            }}
                        </ColumnsActionsWrapperComponent>
                    </ActionDropdownColumns>
                }} name={(`${name as TruncateFieldName}.type`)} rules={{required: true}}
                            control={control as Control<PropsForm, any>}/>
            </div>

            <div className="d-flex flex-column">
                <span>{t("name")}</span>
                <input type="text" style={{
                    height: "27.36px",
                    minHeight: "27.36px"
                }} placeholder={t("name")}
                       className="form-control" {...register(`${name}.name`, {required: true})}/>
            </div>

            <div className="d-flex flex-column">
                <span>{t("model")}</span>
                <Controller render={({field}) => {

                    return <Dropdown isOpen={menuIsOpen} toggle={() => {
                    }}>
                        <DropdownToggle style={{backgroundColor: '#1b85b8', color: "white"}} color={'#1b85b8'} caret
                                        id="dropdown-basic" onClick={() => setMenuIsOpen(prevState => !prevState)}>
                            {getTruncateValue() ?? t("common:select_placeholder")}
                        </DropdownToggle>
                        <DropdownMenu style={{overflow: 'auto', maxHeight: '30vh'}}>
                            {(!isEventTruncate && field.value && !watch(`${name}.field`)?.includes(".")) && isSbxModel &&
                                <div className="d-flex align-items-center p-2 gap-1 border-bottom"><ButtonComponent
                                    label={""} onClick={() => {
                                    setValue(`${name}.model`, "")
                                    setValue(`${name}.field`, "")
                                    setSbxOptions(baseOptions)
                                }} icon={faArrowLeft}/>
                                    <span>{t("model")} {field.value}</span>
                                </div>}

                            <ColumnsActionsWrapperComponent>
                                {search => {
                                    return <>
                                        {loading === State.PENDING && <DropdownItem disabled>
                                            {t('loading')} {t("columns")}
                                        </DropdownItem>}
                                        {removeDuplicateFromArrayObj(sbxOptions, 'label').filter(option => {
                                            if (watch(name)?.type === "reference") {
                                                return option?.data
                                            }

                                            return true
                                        }).filter(option => search.length > 0 ? option.label.toLowerCase().includes(search.toLowerCase()) : true).map((option, index) => {
                                            return <DropdownItem
                                                className="d-flex gap-2 align-items-center"
                                                key={`option_${option.value}_${option.label}_${index}`}
                                                onClick={() => {
                                                    setReferenceColumns([])
                                                    setIsSbxModel(true)

                                                    if ((!field.value || !isSbxModel) && option.data) {
                                                        field.onChange(option?.value)
                                                        setValue(`${name}.field`, "")
                                                    } else {
                                                        let text = option.value
                                                        if (text.split(".").length > 2) {
                                                            field.onChange(text.split(".")[0])
                                                            setValue(`${name}.field`, text.split(".").slice(1,).join("."))
                                                        } else {
                                                            if (modelsUsed.includes(text)) {
                                                                setValue(`${name}.model`, text)
                                                                if (isEventTruncate || !isSbxModel) {
                                                                    setValue(`${name}.field`, "")
                                                                }

                                                            } else {
                                                                if (!option.data) {
                                                                    field.onChange("")
                                                                }
                                                                setValue(`${name}.field`, text)
                                                            }
                                                        }
                                                        setMenuIsOpen(false)
                                                    }

                                                    if (option?.data?.properties && option.data.properties.length > 0) {
                                                        setSbxOptions([...option.data.properties.map((prop: ModelsResponse) => {
                                                            return {
                                                                label: prop.name,
                                                                value: prop.name,
                                                                data: prop
                                                            }
                                                        })].sort((a, b) => a.label.localeCompare(b.label)))
                                                    }

                                                    if (option?.data?.type) {
                                                        setFieldSelected(option.data)
                                                        if (option.data?.type === SbxModelField.DATE) {
                                                            setValue(`${name}.format`, '%Y-%m-%dT%H:%M:%S.%fZ')
                                                        }
                                                    } else {
                                                        if (!isEventTruncate) {
                                                            setValue(`${name}.format`, '')
                                                        }

                                                        setFieldSelected(undefined)
                                                    }
                                                }}>

                                                <span className="text-capitalize pointer">{option.label}</span>
                                                {option.value && (!watch(`${name}.field`)?.includes(".") || !isSbxModel) && option?.data?.id
                                                    && (!field.value || !isSbxModel) && (!option.label.toLowerCase().includes("create")
                                                        && !option.label.toLowerCase().includes("@sbx")) &&
                                                    <span className="pointer">&#8594;</span>}
                                            </DropdownItem>
                                        })}

                                        {search && <SearchSuggestionOption search={search} onChange={value => {
                                            let text = value
                                            let modelName = ""
                                            if (text.split(".").length > 1) {
                                                field.onChange(text.split(".")[0])
                                                modelName = text.split(".")[0]
                                                setValue(`${name}.field`, text.split(".").slice(1,).join("."))
                                            } else {
                                                setValue(`${name}.model`, "")
                                                setValue(`${name}.field`, text)
                                            }
                                            setSbxOptions(baseOptions)
                                            setMenuIsOpen(false)
                                            setIsSbxModel(modelsUsed.includes(modelName))
                                            setReferenceColumns([])
                                        }} toggle={() => {

                                        }}/>}
                                    </>
                                }}
                            </ColumnsActionsWrapperComponent>
                        </DropdownMenu>
                    </Dropdown>

                }} name={`${name}.model`}
                            control={control as Control<PropsForm, any>}/>
            </div>


            {!isExtraField && !isEventTruncate && ["date"].some(truncateType => watch(name).type === truncateType)
                && fieldSelected?.type !== SbxModelField.DATE
                && <>
                    <div className="d-flex flex-column">
                        <span>{t("format")}</span>
                        <Controller render={({field}) => {
                            return <ActionDropdownColumns color={'#1b85b8'} onChange={() => {
                            }} columns={[]} label={
                                field.value
                                    ? dateList.find(option => option.value === field.value)?.label ?? field.value
                                    : t("common:select_placeholder")}>
                                <ColumnsActionsWrapperComponent>
                                    {search => {
                                        return <>
                                            {dateList.filter(option => {
                                                if (option.type && fieldSelected?.type) {
                                                    return option.type === fieldSelected?.type
                                                }

                                                return true
                                            }).filter(date => date.label.toLowerCase().includes(search.toLowerCase())).map(date => (
                                                <DropdownItem
                                                    key={`date_${date.value}`}
                                                    className="d-flex gap-2 align-items-center"
                                                    onClick={() => {
                                                        field.onChange(date.value)
                                                    }}>
                                                    {date.label_render}
                                                </DropdownItem>
                                            ))}
                                        </>
                                    }}
                                </ColumnsActionsWrapperComponent>
                            </ActionDropdownColumns>
                        }} name={(`${name as TruncateFieldName}.format`)} rules={{required: true}}
                                    control={control as Control<PropsForm, any>}/>
                    </div>

                    <div className="mt-4">

                        <ButtonComponent color={"secondary"}
                                         label={`${t('add')} ${t('others')}`} icon={faPlusCircle}
                                         onClick={() => {
                                             append({model: "", field: "", name: ""})
                                         }}/>
                    </div>
                </>}

            {
                watch(name)?.type == "reference" ? <div className="d-flex flex-column">
                    <span>Label</span>
                    <Controller render={({field}) => {

                        const label = field.value ? referenceColumns.find(column => column === field.name) ?? field.value : undefined

                        return <ActionDropdownColumns loading={loadingReference === State.PENDING} color={'#1b85b8'}
                                                      onChange={() => {
                                                      }} columns={[]} label={label ?? t("common:select_placeholder")}>
                            <ColumnsActionsWrapperComponent>
                                {search => {
                                    return <>
                                        {(loading === State.PENDING || loadingReference === State.PENDING) &&
                                            <DropdownItem disabled>
                                                {t('loading')} {t("columns")}
                                            </DropdownItem>}
                                        {referenceColumns.filter(column => column.toLowerCase().includes(search.toLowerCase())).map(column => (
                                            <DropdownItem
                                                key={`reference_${column}`}
                                                className="d-flex gap-2 align-items-center"
                                                onClick={() => {
                                                    field.onChange(column)
                                                }}>
                                                {column}
                                            </DropdownItem>
                                        ))}

                                        {search && <SearchSuggestionOption search={search} onChange={value => {
                                            field.onChange(value)
                                        }} toggle={() => {

                                        }}/>}
                                    </>
                                }}


                            </ColumnsActionsWrapperComponent>

                        </ActionDropdownColumns>
                    }} name={(`${name as TruncateFieldName}.label`)} rules={{required: true}}
                                control={control as Control<PropsForm, any>}/>
                </div> : null
            }

            {watch(name)?.type == "date" ?
                <>
                <Controller render={({field}) => {
                    return <div className="my-1">
                        <label
                            htmlFor={watch(name).name + "fill_empty"}
                            className={"d-flex align-items-center w-100"}>

                            <input
                                id={watch(name).name + "fill_empty"}
                                checked={watch(name).fill_empty}
                                onChange={(evt) => {
                                    field.onChange(evt.target.checked)
                                }}
                                type="checkbox"/> <span className="ms-2">{
                            t("report:fill_missing_dates")
                        }</span>
                        </label>
                    </div>
                }} name={`${name}.fill_empty`} control={control}/>
                    <Controller render={({field}) => {
                        return <div className="my-1">
                            <label
                                htmlFor={watch(name).name + "fix_to_fill"}
                                className={"d-flex align-items-center w-100"}>

                                <input
                                    id={watch(name).name + "fix_to_fill"}
                                    checked={watch(name).fix_to_fill}
                                    onChange={(evt) => {
                                        field.onChange(evt.target.checked)
                                    }}
                                    type="checkbox"/> <span className="ms-2">{
                                t("report:fix_start_and_end")
                            }</span>
                            </label>
                        </div>
                    }} name={`${name}.fix_to_fill`} control={control}/>

                </>
                : null}


        </ContainerInputs>

        <div className="d-flex align-items-center gap-5 flex-wrap">
            {fields && fields.map((field, index) => (
                <div className="d-flex align-items-center gap-2" key={field.id}>
                    <TruncateRowComponent name={`models_and_fields.${index}` as TruncateFieldName}
                                          watch={watchModels as UseFormWatch<(PropsForm | ExtraPropsForm)>}
                                          setValue={setValueModels as UseFormSetValue<ExtraPropsForm | PropsForm>}
                                          control={controlModels as Control<ExtraPropsForm | PropsForm, any>}
                                          isExtraField
                                          register={registerModels as UseFormRegister<PropsForm | ExtraPropsForm>}/>
                    <div>
                        <ButtonComponent color={"danger"} label={`${t("remove")}`} icon={faPlusCircle} className="mt-1"
                                         onClick={() => {
                                             remove(index)
                                         }}/>
                    </div>

                </div>

            ))}
        </div>


        {!isExtraField && <div className="d-flex align-items-center gap-2">
            <span className="text-center">{t("report:relative_date")}</span>
            <Controller render={({field}) => {
                return <Switch
                    defaultChecked={field.value}
                    checkedChildren={t('common:yes')} unCheckedChildren="No"
                    onChange={checked => {
                        setRelative(checked)
                        const fields = ["range", "relative_type", "relative_value_from", "relative_value_to"]
                        if (!checked) {
                            if (unregister) {
                                unregister(fields.map(field => `${name}.${field}`) as FieldPath<PropsForm>[])
                            }
                        }
                        field.onChange(checked)
                    }}/>
            }} name={`${name}.relative`} control={control as Control<PropsForm, any>}/>
        </div>}


        {!isExtraField && <div className={"container"}>
            {
                relative ?
                    <TruncateRelativeComponent name={name} register={register as UseFormRegister<(PropsForm)>}
                        // loading={loading}
                                               watch={watch as UseFormWatch<(PropsForm)>}
                                               control={control as Control<PropsForm, any>}/>
                    : null
            }

        </div>}

    </div>
};

export default TruncateRowComponent