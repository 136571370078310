import React, {useContext, useEffect, useRef, useState} from 'react';
import {ListProviderOption, Task} from '../../../types/Task';
import {FieldType, SubType} from '../../../types/FieldType';
import {TableTaskComponent} from '../TableForm/TableTaskComponent';
import * as Sentry from "@sentry/nextjs";
import {
  evalConditionExpression,
  evalExpression,
  getCompoundName,
  getDateFromValue,
  getDefaultVarsFromStr,
  getDynamicListProviderQuery,
  getInterpretVar,
  getNextBusinessDay,
  getObjValueInDeep,
  getProviderOptions,
  getSelectOptionByConfig,
  getUnderscoreColumn,
  getVariableDefaultValue,
  isDefaultVarExpression,
  isTableOperation,
  isVarExpression,
  tableOperation,
  tableOperationAction
} from '../../../utils';
import {ValueType} from 'react-select';
import {State} from '../../../types/State';
import {getSbxModelFields} from '../../../services/backend/SbxService';
import {SbxResponse} from '../../../types/Sbx';
import {Find} from 'sbxcorejs';
import {StringOption} from '../../../types/Select';
import IField from '../../../types/FormBuilder/IField';
import {Field, StyleRulesDefinition} from '../../../types/Field';
import {useSelector} from 'react-redux';
import {authReducer, configReducer} from '../../../store/Selectors';
import useTranslate from '../../../hooks/useTranslate';
import {Switch} from 'antd';
import {Controller} from 'react-hook-form';
import {Response} from '../../../types/Response';
import {TaskComponentContext} from '../../../context/TaskContext';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faInfoCircle, faSpinner} from '@fortawesome/free-solid-svg-icons';
import SelectComponent from '../../Shared/FieldComponents/SelectComponent';
import RadioButtonComponent from '../../Shared/FieldComponents/RadioButtonComponent';
import CheckboxComponent from '../../Shared/FieldComponents/CheckboxComponent';
import MultiFilesInputComponent from './MultiFilesInputComponent';
import FileComponent from './FileComponent';
import EmailComponent from '../../Shared/FieldComponents/EmailComponent';
import LabelComponent from '../../Shared/FieldComponents/LabelComponent';
import PopoverComponent from '../../Shared/PopoverComponent';
import CurrencyComponent from '../../Shared/FieldComponents/CurrencyComponent';
import PhoneComponent from '../../Shared/FieldComponents/PhoneComponent';
import TimeComponent from '../../Shared/FieldComponents/TimeComponent';
import NumberComponent from '../../Shared/FieldComponents/NumberComponent';
import DocumentComponent from '../../Shared/FieldComponents/DocumentComponent';
import DateComponent from '../../Shared/FieldComponents/DateComponent';
import useDeviceDetect from '../../../hooks/useDeviceDetect';
import DateRangeComponent from '../../Shared/FieldComponents/DateRangeComponent';
import TitleComponent from '../../Shared/FieldComponents/TitleComponent';
import MultiSelectComponent from '../../Shared/FieldComponents/MultiSelectComponent';
import {Button} from 'reactstrap';
import RenderMonthElement from "../../Shared/RenderMonthElement";
import ToDoListComponent from "../../Shared/FieldComponents/ToDoListComponent";
import IntermediateAppointment from "./IntermediateAppointment";

type Props = {
  field: Field;
  subForm: { [key: string]: string };
  setSubForm: (form: any) => void
};

const fieldNameDefaultValue: { [key: string]: string } = {};

const FormRenderInput = ({field, setSubForm, subForm}: Props) => {

  const {
    taskState,
    task,
    formState,
    loadingTask,
    dispatchForm,
    setValue,
    getFormValue,
    register,
    form,
    control,
    watch,
    setError,
    clearErrors
  } = useContext(TaskComponentContext);

  const {t} = useTranslate('common');
  const {businessDays} = useSelector(configReducer);
  const [options, setOptions] = useState<any[]>([]);
  const [selectValue, setSelectValue] = useState<{ label: string, value: string | number } | null>(null);
  const [isLoading, setIsLoading] = useState(State.IDLE);
  const [defaultValue, setDefaultValue] = useState<string>('');
  const {isMobile} = useDeviceDetect();
  const {user} = useSelector(authReducer);
  const initial = useRef(false);

  const getValue = (field: Field, task: Task) => {

    if (field.default_value) {

      let defaultValue = getVariableDefaultValue(field.default_value);
      fieldNameDefaultValue[defaultValue] = field.name;
      if (isVarExpression(field.default_value) && taskState) {
        const form = taskState.form;

        const varList = getDefaultVarsFromStr(field.default_value);

        if (varList && varList.length > 1) {
          varList.forEach((defaultVar) => {
            const nameVar = getVariableDefaultValue(defaultVar);
            if (getFormValue && (getFormValue(nameVar) || getFormValue(nameVar) === 0) && defaultValue.includes(nameVar)) {
              defaultValue = defaultValue.replace(nameVar, getFormValue(nameVar));
            }
          });
        }
        Object.keys(form).forEach(key => {
          if (form[key] && defaultValue.includes(key)) {
            defaultValue = defaultValue.replace(key, form[key]);
          }
        });

        Object.keys(task.process_data).forEach(key => {
          if (task.process_data[key]?.value) {
            if (defaultValue?.includes(key)) {
              defaultValue = defaultValue.replace(key, task.process_data[key]?.value);
            }
          }
        });

        return evalExpression(defaultValue);
      } else {

        // Check is default values try to get a current user property
        if (field.default_value.includes('currentUser.')) {
          return getInterpretVar({strVar: field.default_value, item: user});
        } else {

          switch (field.default_value) {

            case '${now}': {
              return new Date().toISOString();
            }
            case '${next_business_day}': {
              return getNextBusinessDay(businessDays)
            }
            case '${currentUser}':
              return user?.email ?? '';

            default:
              // if (task?.process_data && task?.process_data[defaultValue]?.value) {
              //   return task?.process_data[defaultValue]?.value ?? '';
              // }

              if (isDefaultVarExpression(field.default_value)) {

                if (isTableOperation(field.default_value)) {
                  const values = defaultValue.split(/\(|\)|,/g)
                  const operation = values[0]
                  const field = values[1]
                  const column = values[2]?.trim()
                  if (operation && field && column && taskState?.form[field]) {

                    return taskState?.form[field].reduce((total: number, item: { [key: string]: number | string }) => {
                      if (item.hasOwnProperty(column)) {
                        total += tableOperationAction({
                          action: operation as tableOperation,
                          value: parseFloat(item[column].toString())
                        })
                      }
                      return total;
                    }, 0.0)
                  }
                } else {

                  if (taskState?.form && taskState.form[defaultValue]) {
                    return taskState.form[defaultValue];
                  }

                  if (getFormValue && getFormValue(defaultValue)) {
                    return getFormValue(defaultValue);
                  }
                }
              }


              if (task?.process_data && task?.process_data[defaultValue]?.value) {
                return task?.process_data[defaultValue]?.value ?? '';
              }

              if (!!task?.process_data && task?.process_data[field.name]?.value) {
                return task?.process_data[field.name]?.value;
              }

              return field.default_value ? isTableOperation(field.default_value) ? "" : field.default_value ?? '' : ''

          }
        }


      }
    } else {

      if (!!task?.process_data && task?.process_data[field.name]?.value) {
        if (field.field_type === FieldType.TABLE) {
          fieldNameDefaultValue[field.name] = field.name;
        }
        return task?.process_data[field.name]?.value;
      }
    }


    return null;
  };

  const getDataFromProcessData = (field: IField) => {
    try {
      if (task?.process_data) {
        const value = JSON.parse(task.process_data[field.name].value);
        return value.appointment_key;
      }
      throw Error("No data");
    } catch (e) {
      return undefined;
    }
  }

  function hasValue(field: IField, task: Task) {
    return getValue(field, task) != null;
  }

  React.useEffect(() => {
    if (!!task?.process_data && dispatchForm) {

      Object.keys(task.process_data).forEach(key => {
        if (fieldNameDefaultValue[key] && task.process_data[key]?.value) {
          // check if it's a table type, table is a string then convert -> array
          if (key === field.name && field.field_type === FieldType.TABLE) {
            dispatchForm({
              name: fieldNameDefaultValue[key],
              value: JSON.parse(task.process_data[key]?.value) ?? task.process_data[key]?.value
            });
          } else {
            dispatchForm({name: fieldNameDefaultValue[key], value: task.process_data[key]?.value});
          }
        }
      });
    }

  }, [fieldNameDefaultValue]);

  const getSelectLabelValue = (obj: { [key: string]: string } | ListProviderOption) => {
    if (obj && field.format_rules_definition?.columns_labels && field.format_rules_definition?.columns_labels[0]) {


      // console.log(obj)
      //
      // console.log(getCompoundName({columns: field.format_rules_definition.columns_labels, item: obj}),)
      setSelectValue({
        label: getCompoundName({columns: field.format_rules_definition.columns_labels, item: obj}),
        value: (obj as { [key: string]: string })._KEY ?? obj.value ?? ''
      });
    } else {
      if (obj?.label && obj.value) {
        setSelectValue({
          label: obj.label,
          value: obj.value
        });
      }
    }
  };

  const getDefaultSelectValue = async (queryProvider?: string) => {
    const domain_id = field?.list_provider?.domain_id;
    if (field.read_only && field.list_provider?.default_query) {
      // When the field is read only and the item has property default_query, use the default query like the main query

      field.list_provider = {...field.list_provider, query: field.list_provider?.default_query};
    }

    // Check if list provider has vars
    if (field?.list_provider?.query && !queryProvider && !field.read_only) {
      if (options.length === 0) {
        const varList = getDefaultVarsFromStr(field?.list_provider?.query);
        if (varList && varList.length > 0) {
          return;
        }
      } else {
        if (defaultValue) {

          if (field.format_rules_definition.dependencies && !field.read_only) {
          } else {
            const item = options.find(option => (option._KEY === defaultValue || option.value === defaultValue));
            if (item) {
              getSelectLabelValue(item);
            }
          }


        }
      }
    } else {
      if (defaultValue && options.length > 0) {
        const item = options.find(option => (option._KEY === defaultValue || option.value === defaultValue));
        if (item) {
          getSelectLabelValue(item);
        }
      }
    }

    if (domain_id && task) {
      let response: SbxResponse | Response | any | null = null;

      if (queryProvider && field.list_provider) {
        response = await getProviderOptions({list_provider: {...field.list_provider, query: queryProvider}});
      } else {

        const sbx_query = field?.list_provider?.query && JSON.parse(field?.list_provider?.query);
        if (sbx_query && (sbx_query['ROW_MODEL'] || sbx_query['row_model'])) {
          const model_name = sbx_query['ROW_MODEL'] ?? sbx_query['row_model'];
          let query = new Find(model_name, domain_id);
          query.andWhereIsEqualTo('_KEY', getValue(field, task));
          query = query.compile();
          const fetch = sbx_query.FETCH ?? sbx_query.fetch ?? [];

          response = await getSbxModelFields({
            findUrl: 'find_all',
            provider: {
              name: model_name,
              query: JSON.stringify({WHERE: (query as any).where, FETCH: fetch})
            }
          });
        }
      }

      if (response && response.success && response.results && response.results[0]) {
        if (field.format_rules_definition.dependencies && !field.read_only && selectValue) {
        } else {
          getSelectLabelValue(response.results[0]);
        }

      }
    }

  };

  React.useEffect(() => {

    if (options.length > 0 && defaultValue) {
      getDefaultSelectValue();
    }

  }, [options, defaultValue]);

  React.useEffect(() => {

    const getFieldOptions = async () => {
      if (field.list_provider && !field.format_rules_definition?.dependencies) {
        setIsLoading(State.PENDING);
        let formState: any = {...taskState?.form};

        if (!!task?.process_data && Object.keys(task.process_data).length > 0) {
          const obj: any = {};
          Object.keys(task.process_data).forEach(key => {
            if (task.process_data[key]?.value) {
              obj[key] = task.process_data[key].value;
            } else {
              if (task.process_data[key] && typeof task.process_data[key] !== 'undefined') {
                obj[key] = task.process_data[key];
              }
            }
          });


          formState = {...formState, ...obj};
        }


        const response = await getProviderOptions({
          list_provider: field.list_provider,
          formState
        });

        if (!response?.items?.length && response?.success) {
          //sentry log error about not found items
          try {
            console.log(`err -> items not found {}`, response);
            Sentry.withScope(function (scope) {
              scope.setLevel("error");
              Sentry.setExtra("err -> items not found {}", {
                provider: field.list_provider,
                formValues: formState,
                message: "line 324:  getting provider FormRenderInput.tsx",
                data: response,
                path: window.location.href
              })
              // The exception has the event level set by the scope (info).
              Sentry.captureException(new Error("err -> items not found {}"));
            });
          } catch (e) {
            console.error(e);
          }
        }

        if (response?.success && response?.items) {
          if (response.items.length === 1 && field.sub_type === SubType.SELECT) {
            if (response.items[0]) {
              handleChangeSelect({
                label: '',
                value: (response.items[0] as ListProviderOption)._KEY ?? ''
              }, response.items);

              if (setValue) {
                setValue(field.name, (response.items[0] as ListProviderOption)._KEY ?? (response.items[0] as ListProviderOption).value ?? '');
              }
            }
            getSelectLabelValue(response.items[0] as ListProviderOption);
          }


          setOptions(response.items);
          setIsLoading(State.RESOLVED);
        } else {
          setIsLoading(State.REJECTED);
        }
      }
    };

    if (field.field_type === FieldType.OPTIONS && task) {
      if (hasValue(field, task) && field?.format_rules_definition?.columns_labels) {

        if (!field.read_only || field.sub_type === SubType.TOGGLE) {
          getFieldOptions();
        }
        getDefaultSelectValue();
      } else {
        getFieldOptions();
      }
    }


    return () => {
    }
  }, [field]);

  const handleChangeSelect = (evt: ValueType<StringOption, boolean>, optionsArr?: any[]) => {
    if (field.format_rules_definition) {

      const list = optionsArr ?? options;

      const obj = list.find(option => (option['_KEY'] === (evt as StringOption)?.value || option['value'] === (evt as StringOption)?.value));


      if (obj) {
        dispatchObjExtraColumns(obj);
      }
    }

  };

  const dispatchObjExtraColumns = (obj: { [key: string]: string }, isDependency = false) => {
    // if field has format rules definition is to add aditional properties for the form with field name like the base.
    // EX: Columns: ["_KEY", "company.company_name"]
    /* Result:sbx_sbx_crm_sales_order_requested_by: ...
    object_sbx_sbx_crm_sales_order_requested_by__KEY: ...
    object_sbx_sbx_crm_sales_order_requested_by_company.company_name ...
     */

    if (field.format_rules_definition.columns_labels && field.format_rules_definition.columns_labels.length > 0) {
      const columns = field.format_rules_definition.columns_labels[0].value;

      columns.forEach((column: string) => {

        //
        if (isDependency && setValue) {

          if (!!obj && !!column) {
            const value = getObjValueInDeep(obj, column);
            const name = getUnderscoreColumn(field.name, column);

            if (getFormValue && getFormValue(name)) {

              setValue(name, value);
            }

          }

        } else {
          if (dispatchForm) {
            dispatchForm({
              name: getUnderscoreColumn(field.name, column),
              value: obj[column] ?? getObjValueInDeep(obj, column) ?? ''
            });
          }
        }
      });
    }


  };
  React.useEffect(() => {
    if (task) {
      setDefaultValue(getValue(field, task) || '');
    }
  }, [field]);

  // React.useEffect(() => {
  //
  //   let subscription: null | Subscription = null
  //
  //   if (watch) {
  //     subscription = watch((value, {name}) => {
  //       console.log(name)
  //       if  (task && name && field.format_rules_definition?.dependencies && field.default_value){
  //         if (field.field_type === FieldType.OPTIONS) {
  //         } else {
  //           const value = getValue(field, task)
  //           if (value){
  //             setDefaultValue(value);
  //           }
  //         }
  //       }
  //     });
  //   }
  //   return () => subscription?.unsubscribe();
  // }, [watch, task]);

  useEffect(() => {
    let subscription: any | null = null
    if (watch) {
      subscription = watch((value, {name, type}) => {
        if (name && type === "change") {
          evalFieldRequired()

          if (task && field.format_rules_definition?.dependencies && field.format_rules_definition?.dependencies.includes(name) && field.field_type !== FieldType.OPTIONS) {
            setDefaultValue(getValue(field, task) || '');
          }
        }
      });
    }
    return () => subscription?.unsubscribe();
  }, [watch, field, task]);

  const evalFieldRequired = () => {
    let formData = {...(taskState?.form ?? {})}

    if (task?.process_data) {
      formData = {...task.process_data, ...formData}
    }

    if (field.format_rules_definition?.required) {
      const required = evalConditionExpression({
        expression: field.format_rules_definition.required,
        form: formData,
        getFormValue, fields: form?.fields?.map(nField => nField.name) ?? []
      })
      field.required = !!required
    }
  }

  React.useEffect(() => {
    const getDependencyOption = async () => {
      for (const dependency of field.format_rules_definition?.dependencies!) {
        if (field.list_provider && taskState) {

          const varList = getDefaultVarsFromStr(field.list_provider.query);


          if (getFormValue && (getFormValue(dependency)) && field.list_provider && (varList && varList.length > 0 || !field.list_provider.query?.includes("${"))) {

            let formData = {...taskState.form}

            if (task?.process_data) {
              formData = {...task.process_data, ...formData}
            }

            const listProvider = getDynamicListProviderQuery({
              list_provider: field.list_provider,
              getFormValues: getFormValue,
              formState: formData
            });

            if (listProvider?.query) {
              setIsLoading(State.PENDING);
              const response = await getProviderOptions({list_provider: listProvider}).then(res => res as SbxResponse);
              if (response?.items && setValue) {
                let items = response.items;

                if (field.format_rules_definition?.default_value) {
                  if (field.format_rules_definition?.condition_order_by) {
                    const order_by = field.format_rules_definition.condition_order_by[0];
                    items = items.sort((a: any, b: any) => (getObjValueInDeep(a, order_by) > getObjValueInDeep(b, order_by)) ? -1 : 1);

                    const defaultValue = getVariableDefaultValue(field.format_rules_definition.default_value);
                    const item = items[0];
                    dispatchObjExtraColumns(item, true);

                    if (item?.hasOwnProperty(defaultValue)) {


                      setValue(field.name, item[defaultValue]);

                      setSubForm((prevState: any) => ({...prevState, [field.name]: item[defaultValue]}));

                    } else {
                      if (field.format_rules_definition?.default || field.format_rules_definition?.default === 0) {
                        setValue(field.name, field.format_rules_definition?.default);

                        setSubForm((prevState: any) => ({
                          ...prevState,
                          [field.name]: field.format_rules_definition?.default
                        }));
                      }
                    }
                  } else {
                    const defaultValue = getVariableDefaultValue(field.format_rules_definition.default_value);
                    const list = options.length > 0 ? options : response.results
                    const item = list?.find(option => JSON.stringify(option).includes(formData[defaultValue]))
                    if (item) {
                      getSelectLabelValue(item);
                      dispatchObjExtraColumns(item, true);
                    }
                  }
                } else {
                  const option = items.find(item => item._KEY === selectValue?.value);
                  const fieldName = getUnderscoreColumn(field.name, '_KEY')
                  if (fieldName && formData[fieldName]?.value && !selectValue) {
                    const option = items.find(item => item._KEY === formData[fieldName]?.value);
                    if (option) {
                      getSelectLabelValue(option);
                      dispatchObjExtraColumns(option, true);
                    }
                  } else {
                    // If the option is not found, set the first option as default
                    if (!option && items.length === 1 && items[0]) {
                      // If the current field is a dependency of other field, not auto select
                      if (form?.fields && form.fields.some(nField => (nField.format_rules_definition && nField.format_rules_definition?.default_value?.includes(field.name)) || nField.default_value?.includes(field.name))) {
                      } else {
                        getSelectLabelValue(items[0]);
                        setValue(field.name, items[0]._KEY);
                        dispatchObjExtraColumns(items[0], true);
                      }

                    }
                  }


                }

                if (items.length) {
                  setOptions(items);
                }
                setIsLoading(State.RESOLVED);
              } else {
                setIsLoading(State.REJECTED);
              }
            } else {
              setIsLoading(State.REJECTED);
            }
          }
        }
      }
    };

    if (field.format_rules_definition?.dependencies && task) {
      if (field.field_type === FieldType.OPTIONS) {
        getDependencyOption();
      } else {
        setTimeout(() => {
          setDefaultValue(getValue(field, task) || '');
        }, 1000)
      }
    }
  }, [formState]);

  useEffect(() => {
    if (field?.format_rules_definition?.columns_labels && options.length && !initial.current) {
      const val = field?.format_rules_definition?.columns_labels[0];
      if (val?.value.length) {
        const obj = options.find(option => (option['_KEY'] === (task?.process_data[field.name])?.value || option['value'] === (task?.process_data[field.name])?.value));
        if (obj) {
          initial.current = true;
          dispatchObjExtraColumns(obj);
        }
      }
    }
  }, [options])

  let input = null;

  const getOptionInput = (field: Field) => {
    if (task && register) {

      let mainInput = <Controller
        control={control}
        name={field.name}
        defaultValue={getValue(field, task)}
        rules={{required: (field.required && !field.read_only) || !!field?.format_rules_definition?.n_to_be_completed}}
        render={({field: {onChange, value}}) => {
          let Field: any;
          switch (field.sub_type) {
            case SubType.TOGGLE:
              if (field.single_value) {
                Field = RadioButtonComponent;
              } else {
                Field = CheckboxComponent;
              }
              break;
            case SubType.TO_DO_LIST:
              Field = ToDoListComponent;
              break;
            default:
              if (field.single_value) {
                Field = SelectComponent;
              } else {
                Field = MultiSelectComponent;
              }
              break;
          }
          return <Field
            id={field.name + '_' + field.id}
            name={field.name}
            value={field.single_value ? selectValue : formatValues(field, value)}
            placeholder={t('select_placeholder')}
            onChange={(evt: StringOption | StringOption[]) => {
              if (field.sub_type === SubType.TO_DO_LIST) {
                const count = (field?.format_rules_definition?.n_to_be_completed ?? options.length);
                if ((evt as Array<any>)?.length < count) {
                  console.log("Generating errors... ")
                  setError(field.name, {
                    types: {
                      label: field.label,
                      required: `Please select ${count} items to continue.`,
                    }
                  });
                } else {
                  console.log("Removing errors... ")
                  clearErrors([field.name])
                }
                onChange(formatValues(field, evt, true));
              } else if (Array.isArray(evt)) {
                onChange(formatValues(field, evt, true));
              } else {
                setSelectValue(evt);
                handleChangeSelect(evt);
                onChange(evt?.value ?? null);
              }
            }}
            sortOptions
            format_rules_definition={field.format_rules_definition}
            containerClassName={(field.style_rules_definition as StyleRulesDefinition)?.alignment === "h" ? "d-flex align-items-center gap-3" : ""}
            loading={isLoading === State.PENDING}
            disabled={isLoading === State.PENDING || field.read_only}
            options={getSelectOptionByConfig(field, options)}
          />;
        }}
      />;

      if (field.format_rules_definition?.render_type) {
        switch (field.format_rules_definition?.render_type) {
          case 'field':
            const subType = field.format_rules_definition?.sub_type?.toUpperCase();
            switch (subType) {
              case SubType.CURRENCY:
                return <div className="input-group mb-3">
                  <Controller
                    control={control}
                    name={field.name}
                    defaultValue={defaultValue}
                    rules={{
                      required: field.required && !field.read_only,
                      pattern: /^[0-9]{6,15}/i
                    }}
                    render={({field: {onChange, name, value}}) => (
                      <CurrencyComponent
                        id={field.name + '_' + field.id}
                        value={value}
                        disabled={field.read_only}
                        defaultValue={defaultValue ?? getValue(field, task) ?? ''}
                        onChange={onChange}
                        placeholder={field.placeholder ?? ''}
                        name={name}/>
                    )}/>
                </div>;
              default:
                return <div className="input-group mb-3">
                  <input className="form-control"
                         id={field.name + '_' + field.id}
                         defaultValue={defaultValue}
                         disabled={field.read_only}
                         type={field.format_rules_definition?.sub_type}
                         {...register(field.name, {required: field.required && !field.read_only})}/>
                  {isLoading === State.PENDING && <span className="input-group-text bg-white">
              <FontAwesomeIcon icon={faSpinner} pulse/>
            </span>}
                </div>;
            }


          default:
            return mainInput;
        }
      }


      return mainInput;
    }

    return null;

  };

  const getValueFieldValidation = () => field.field_type !== FieldType.FILE && field.field_type !== FieldType.TABLE && field.field_type !== FieldType.OPTIONS && field.field_type !== FieldType.DATE;

  function formatValues(field: Field, value: any, structure?: boolean) {
    switch (field.field_type) {

      case FieldType.DATE_RANGE:
        if (structure) {
          return `${value.startDate?.toISOString() ?? ''},${value.endDate?.toISOString() ?? ''}`;
        } else {
          const [s, e] = (value ?? ',').split(',');
          return {startDate: s ? new Date(s) : null, endDate: e ? new Date(e) : null};
        }

      case FieldType.DATE:

        if (structure) {
          return value?.toISOString() ?? null;
        } else {
          return value ? new Date(value) : null;
        }

      case FieldType.APPOINTMENT_PICKER:
        if (structure) {
          return value?.date ? JSON.stringify({
            ...value,
            date: value.date.toISOString(),
            time: value.time?.toISOString()
          }) : null;
        } else {
          const val = value ? JSON.parse(value) : null;
          return val ? {
            ...val,
            date: getDateFromValue(val.date),
            time: getDateFromValue(val.time)
          } : val;
        }

      case FieldType.OPTIONS:
        if (structure) {
          if (field.sub_type === SubType.TO_DO_LIST) {
            return value ? JSON.stringify(value) : null;
          }
          if (field.single_value) {
            return value?.value ?? null;
          } else {
            return value?.map((opt: StringOption) => opt.value).join(',') ?? null;
          }
        } else {
          if (field.sub_type === SubType.TO_DO_LIST) {
            return value ? JSON.parse(value) : null;
          }
          if (field.single_value) {
            return getSelectOptionByConfig(field, options).find(opt => opt.value === value) ?? null;
          } else {
            return getSelectOptionByConfig(field, options).filter(opt => (value ?? '').split(',').some((val: string) => val === opt.value)) ?? null;
          }
        }
      default:
        return value;
    }
  }

  const getField = () => {

    // Only get default value of inputs except type table and file.
    if (task && setValue && getValue(field, task) && getValueFieldValidation()) {
      setValue(field.name, getValue(field, task));
    }

    function setValueField(val: any) {
      if (dispatchForm) {
        dispatchForm({
          name: field.name,
          value: val,
          type: 'FILE'
        });
      }
    }


    if (register && dispatchForm && task) {
      switch (field.field_type) {
        case FieldType.DATE_RANGE:
          input = (
            <Controller
              control={control}
              name={field.name}
              defaultValue={defaultValue}
              rules={{
                required: field.required && !field.read_only
              }}
              render={({field: {onChange, value}}) => (
                <DateRangeComponent
                  id={field?.id ? field.id.toString() : ""}
                  value={formatValues(field, value)}
                  onChange={e => onChange(formatValues(field, e, true))}
                  disabled={field.read_only}
                  required={field.required}
                  renderMonthElement={RenderMonthElement}
                  isOutsideRange={() => false}
                  orientation={isMobile ? 'vertical' : 'horizontal'}
                />
              )}
            />
          );
          break;
        case FieldType.DATE: {


          input = (
            <Controller
              control={control}
              name={field.name}
              defaultValue={getValue(field, task) ?? ''}
              rules={{
                required: field.required && !field.read_only
              }}
              render={({field: {onChange, value}}) => (
                <DateComponent
                  id={field.id.toString()}
                  value={formatValues(field, value)}
                  onChange={e => onChange(formatValues(field, e, true))}
                  disabled={field.read_only}
                  required={field.required}
                  renderMonthElement={RenderMonthElement}
                  orientation={isMobile ? 'vertical' : 'horizontal'}
                  isDayBlockedDate={field?.format_rules_definition?.blocked_from_date}
                  isOutsideRange={() => false}
                />
              )}
            />
          );
          // input = <input className="form-control"
          //                id={field.name + '_' + field.id}
          //                disabled={field.read_only}
          //                type="date"
          //                defaultValue={defaultValue}
          //                {...register(field.name, {required: field.required && !field.read_only})}>
          // </input>;
          break;
        }
        case FieldType.BOOLEAN:
          input =
            <Controller
              control={control}
              name={field.name}
              defaultValue={defaultValue}
              rules={{required: field.required && !field.read_only}}
              render={({field: {onChange}}) => (
                <Switch checkedChildren={t('common:yes')} unCheckedChildren="No"
                        onChange={onChange}
                />
              )}
            />;

          break;
        case FieldType.SMALL_TEXT:
          switch (field.sub_type) {

            case SubType.NUMBER: {
              input = <NumberComponent
                id={field.id.toString()}
                disabled={field.read_only}
                defaultValue={defaultValue}
                register={register(field.name, {
                  required: field.required && !field.read_only,
                })}
                placeholder={field.placeholder ?? ''}
                name={field.name}/>;
              break;
            }

            case SubType.TIME:
              input = (
                <TimeComponent id={field.id.toString()}
                               disabled={field.read_only}
                               defaultValue={defaultValue}
                               register={register(field.name, {
                                 required: field.required && !field.read_only,
                               })}
                               placeholder={field.placeholder ?? ''}
                               name={field.name}/>
              );
              break;

            case SubType.EMAIL:
              input = (
                <EmailComponent
                  id={field.id.toString()}
                  disabled={field.read_only}
                  defaultValue={defaultValue}
                  register={register(field.name, {
                    required: field.required && !field.read_only,
                    pattern: /^\S+@\S+$/i,
                  })}
                  placeholder={field.placeholder ?? ''}
                  name={field.name}/>
              );
              break;

            case SubType.CURRENCY:
              input = (
                <Controller
                  control={control}
                  name={field.name}
                  defaultValue={defaultValue ?? getValue(field, task) ?? ''}
                  rules={{
                    required: field.required && !field.read_only,
                    pattern: /^[0-9]{6,15}/i
                  }}
                  render={({field: {onChange, name, value}}) => (
                    <CurrencyComponent
                      id={field.name + '_' + field.id}
                      value={value}
                      disabled={field.read_only}
                      defaultValue={defaultValue ?? getValue(field, task) ?? ''}
                      onChange={onChange}
                      placeholder={field.placeholder ?? ''}
                      name={name}/>
                  )}/>
              );
              break;
            case SubType.DOCUMENT:
              input = (
                <Controller
                  control={control}
                  name={field.name}
                  rules={{
                    required: field.required && !field.read_only,
                    pattern: /^[0-9]{6,15}/i
                  }}
                  render={({field: {onChange, name, value}}) => (
                    <DocumentComponent
                      id={field.name + '_' + field.id}
                      value={value}
                      disabled={field.read_only}
                      defaultValue={defaultValue ?? getValue(field, task) ?? ''}
                      onChange={onChange}
                      readonly={field.read_only}
                      placeholder={field.placeholder ?? ''}
                      name={name}/>
                  )}/>
              );
              break;

            case SubType.PHONE:
              input = (
                <Controller
                  control={control}
                  name={field.name}
                  rules={{
                    required: field.required && !field.read_only,
                    pattern: /^[0-9]{6,15}/i
                  }}
                  render={({field: {onChange, name, value, ref}}) => (
                    <PhoneComponent
                      id={field.name + '_' + field.id}
                      value={value}
                      indicative={field.format_rules_definition?.indicative ?? ''}
                      hideIndicative={field.format_rules_definition?.hide_indicative ?? false}
                      disabled={field.read_only}
                      defaultValue={defaultValue ?? getValue(field, task) ?? ''}
                      onChange={onChange}
                      placeholder={field.placeholder ?? ''}
                      name={name}/>
                  )}
                />
              );
              break;
            default:
              input =
                <input className="form-control"
                       id={field.name + '_' + field.id}
                       disabled={field.read_only}
                  // onChange={(evt) => dispatchForm({name: field.name, value: getEventValue(field, evt)})}
                       type="text"
                       defaultValue={defaultValue}
                       {...register(field.name, {required: field.required && !field.read_only})}
                >
                </input>;
              break;
          }
          break;
        case FieldType.LARGE_TEXT: {
          input = <textarea className="form-control"
                            id={field.name + '_' + field.id}
                            disabled={field.read_only}
                            defaultValue={defaultValue}
                            {...register(field.name, {required: field.required && !field.read_only})}
          >
          </textarea>;
          break;
        }
        case FieldType.FILE:

          if (field.single_value) {
            return (
              <div>
                <LabelField field={field}/>
                <FileComponent
                  field={field}
                  key={field.id}
                  loadingTask={loadingTask}
                  getValue={() => getValue(field, task)}
                  setValueField={setValueField}/>
              </div>
            );
          } else {
            return (
              <div>
                <LabelField field={field}/>
                <MultiFilesInputComponent
                  loadingTask={loadingTask}
                  field={field} getFiles={() => getValue(field, task)}
                  setValueField={setValueField}
                  key={field.id}/>
              </div>
            );
          }
        case FieldType.TABLE: {
          return <TableTaskComponent task={task}
                                     getValue={getFormValue}
                                     defaultValue={() => getValue(field, task)}
            // formState={taskState?.form}
                                     setValueInput={setValue}
            // dispatchTaskForm={dispatchForm}
                                     key={field.id}
                                     field={field}/>;
        }
        case FieldType.OPTIONS: {
          switch (field.sub_type) {
            case SubType.TO_DO_LIST:
              const {full_container = true} = field.format_rules_definition ?? {};
              return <div
                className={full_container ? "grid-full-column" : "d-flex flex-column"}>
                <LabelField field={field}/>
                {getOptionInput(field)}
              </div>
            default:
              input = getOptionInput(field);
              break;
          }
          break;
        }

        case FieldType.APPOINTMENT_PICKER:
          const appointmentKey = getDataFromProcessData(field);
          input = <Controller
            control={control}
            name={field.name}
            defaultValue={defaultValue}
            rules={{
              required: field.required && !field.read_only
            }}
            render={({field: {onChange, value}}) => (
              <IntermediateAppointment
                fieldProps={field}
                process={task?.process_data}
                pickerProps={{
                  id: field.id.toString(),
                  value: formatValues(field, value)?.date,
                  onChange: e => {
                    onChange(formatValues(field, {
                      date: e,
                      time: formatValues(field, value)?.time,
                      appointment_key: appointmentKey,
                      type: field.format_rules_definition.appointment_props?.type ?? "",
                      title: field.format_rules_definition.appointment_props?.title ?? ""
                    }, true))
                  },
                  required: field.required,
                }}
              />
            )}
          />
          break;
        case FieldType.LABEL:
          return <div className="grid-full-column">
            <TitleComponent id={field.id.toString()} defaultValue={field.default_value}/>
          </div>;

        default: {
          input = <input
            className="form-control"
            id={field.name + '_' + field.id}
            disabled={field.read_only}
            // onChange={(evt) => dispatchForm({name: field.name, value: getEventValue(field, evt)})}
            type="text"
            defaultValue={defaultValue}
            {...register(field.name, {required: field.required && !field.read_only})}
          >

          </input>;

          break;
        }
      }


      return <div key={field.id}
                  className={`d-flex flex-column ${field.format_rules_definition?.hide ? 'd-none' : ''}`}>
        <LabelField field={field}/>
        {input}
      </div>;
    }

    return null;
  };

  return getField();
};

export default FormRenderInput;


export const LabelField = ({field, icon}: { field: Field, icon?: any }) => {
  return (
    <LabelComponent
      icon={field.hint ? <PopoverComponent
        label={<FontAwesomeIcon icon={faInfoCircle}/>}
        id={`pop_${field.id}`}
        trigger="hover"
        placement="bottom">
            <span>
              {field.hint}
            </span>
      </PopoverComponent> : (
        <Button
          type="button"
          color={'link'}
          className={`${'ms-2 mb-2'}`}
          size="sm">
          <br/>
        </Button>
      )}>
      {icon && <FontAwesomeIcon icon={icon} className="me-1"/>}{field.label}
    </LabelComponent>
  );
};


