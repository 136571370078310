import {Dropdown, DropdownMenu, DropdownToggle} from "reactstrap";
import React, {CSSProperties, useState} from "react";

interface IProps {
    headerToggle: React.ReactNode;
    color?: "link" | "primary" | "secondary" | string
    children: JSX.Element | JSX.Element[]
    caret?: boolean
    outline?: boolean
    dropdownStyle?: CSSProperties
    dropdownMenuStyle?: CSSProperties
    disabled?: boolean
}

export const DropDownSimple = (props: IProps) => {

    const [isOpen, setOpen] = useState(false);

    return (
        <Dropdown isOpen={isOpen} disabled={props.disabled} toggle={() => setOpen(!isOpen)}>
            <DropdownToggle style={props.dropdownStyle ?? {}} outline={props.outline} color={props.color || "link"} id="dropdown-basic" caret={props.caret ?? true}>
                {props.headerToggle}
            </DropdownToggle>
            <DropdownMenu style={props.dropdownMenuStyle ?? {}}>
                {props.children}
            </DropdownMenu>
        </Dropdown>
    )
}
