import * as React from 'react';
import {useState} from 'react';
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEllipsisV} from "@fortawesome/free-solid-svg-icons";
import {IconProp} from "@fortawesome/fontawesome-svg-core";

type Props = {
    items: { onClick: () => void, label: string, icon?: IconProp }[]
};

const DropdownItemsOptions = ({items}: Props) => {

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen(prevState => !prevState)
    return (
        <div className="d-flex align-items-center justify-content-end">
            {/*<ButtonComponent color={"default"} sizeIcon={"2x"} icon={faArrowsAltV} label={""}/>*/}

            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                <DropdownToggle color={'default'}>
                    <FontAwesomeIcon icon={faEllipsisV}/>
                </DropdownToggle>
                <DropdownMenu>
                    {items.map((item, index) => (
                        <DropdownItem key={index} onClick={item.onClick}>
                            {item.icon && <FontAwesomeIcon className="me-3" icon={item.icon}/>}
                            <span>{item.label ?? ""}</span>
                        </DropdownItem>
                    ))}

                </DropdownMenu>
            </Dropdown>


        </div>
    );
};

export default DropdownItemsOptions