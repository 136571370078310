import React, {CSSProperties, useState} from "react";
import {convertDateToYYYYMMDDHHmm, filterData} from "../../../utils";
import useTranslate from "../../../hooks/useTranslate";
import {Col} from "reactstrap";
import SpinnerComponent from "../SpinnerComponent";

interface ValueType {
  _KEY: string | number;
  label: string;
  date?: string;
}

interface Opt {
  label: string,
  value: any,
  data?: any
}

interface IProps {
  id: string,
  name: string,
  value: null | ValueType[],
  options: Opt[],
  invalid?: boolean,
  required?: boolean,
  readonly?: boolean,
  disabled?: boolean,
  style?: CSSProperties,
  placeholder?: string,
  className?: string,
  onChange?: (value: null | ValueType[]) => void;
  sortOptions?: boolean;
  loading?: boolean;
  format_rules_definition?: {
    show_percentage: boolean | undefined;
    sort?: string;
    n_to_be_completed?: number;
    allow_edit?: boolean;
    show_date?: boolean;
  }
}

const ToDoListComponent = (props: IProps) => {
  const {
    onChange,
    options,
    value,
    className,
    id,
    required,
    disabled,
    format_rules_definition,
    sortOptions,
    loading = false
  } = props;
  const [filter, setFilter] = useState("");

  function getValue(value: Opt): ValueType {
    return {
      _KEY: value.value,
      date: new Date().toISOString(),
      label: value.label
    }
  }

  function handleEventChange(e: Opt) {
    if (value && onChange) {
      let newValue: any[];
      const t = value.find(o => e.value === o._KEY);
      if (t && format_rules_definition?.allow_edit) {
        newValue = value.filter(o => o._KEY !== e.value);
        if (newValue.length) {
          onChange(newValue);
        } else {
          onChange(null);
        }
      } else {
        newValue = new Array(...value);
        newValue.push(getValue(e));
        onChange(newValue);
      }
    } else if (onChange) {
      onChange([getValue(e)])
    }
  }

  const {t} = useTranslate("common");

  let opts: Opt[] = [...options];
  if (options && options.length) {
    if (format_rules_definition?.sort?.trim()) {
      opts = [...options]
        .filter(item => item.label)
        .sort((a, b) => {
          const val1 = (a.data ?? {})[format_rules_definition?.sort ?? ""]?.toString() ?? "";
          const val2 = (b.data ?? {})[format_rules_definition?.sort ?? ""]?.toString() ?? "";
          return val1?.localeCompare(val2.toString(), undefined, {numeric: true});
        });
    } else if (sortOptions) {
      opts = [...options]
        .filter(item => item.label)
        .sort((a, b) => a.label.toString().localeCompare(b.label.toString()));
    }
  }

  if (loading) {
    return (
      <div className="d-flex justify-content-center py-5">
        <SpinnerComponent/>
      </div>
    )
  }

  function getPercentage(_value: ValueType) {
    if (value?.length) {
      const totalOptions = options.length
      const sortedOption = value.sort((a, b) => new Date(a.date ?? "").getTime() - new Date(b.date ?? "").getTime());
      const indexValue = sortedOption.findIndex(i => i._KEY === _value._KEY) + 1;
      return ((indexValue / totalOptions) * 100).toFixed(2).toString();
    }

    return "";
  }

  return (
    <>
      {options.length > 15 && (
        <div className="form-group mb-2 d-flex justify-content-end">
          <Col lg={4} md={6} sm={12}>
            <input value={filter}
                   onChange={e => setFilter(e.target.value)}
                   type="text"
                   className="form-control rounded-pill"
                   placeholder={t("search").concat("...")}/>
          </Col>
        </div>
      )}
      <div id={id} style={{maxHeight: "40vh"}} className="sbx__checkbox overflow-auto shadow-sm">
        {filterData(opts, filter).map(op => {
          const exist = value ? value.find(o => o._KEY === op.value) : null;
          let percentage: string = "";
          if (exist) {
            percentage = getPercentage(exist);
          }
          return (
            <div key={op.value} className="my-1">
              <label
                htmlFor={op.value + id + "check"}
                className={className || "d-flex align-items-center w-100 form-control position-relative"}>
                <input
                  id={op.value + id + "check"}
                  checked={!!exist}
                  disabled={disabled}
                  required={required}
                  onChange={() => handleEventChange(op)}
                  type="checkbox"/> <span
                className="ms-2">{op.label}</span>
                {(format_rules_definition?.show_date || format_rules_definition?.show_percentage) &&
                  <span style={{bottom: 2, right: 2, textAlign: "right"}}
                        className="position-absolute text-lightgray ">
                    {(percentage && format_rules_definition?.show_percentage) ?
                      <small><b>{percentage.concat("%")}</b></small> : ""}
                    <br/>
                    {(exist?.date && format_rules_definition?.show_date) ? convertDateToYYYYMMDDHHmm(new Date(exist.date)) : ""}
                    </span>}
              </label>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default ToDoListComponent;