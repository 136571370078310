import React, {useContext} from 'react'
import {AnalyticQueryAction} from "../../../../types/Analytic";
import {ReportGeneratorContext} from "../../ReportGeneratorComponent";
import SelectComponent from "../../../Shared/FieldComponents/SelectComponent";
import useTranslate from "../../../../hooks/useTranslate";
import {State} from "../../../../types/State";

type Props = {
    action: AnalyticQueryAction
}

const ForecastActionComponent = ({action}: Props) => {

    const {reportState: {isLoading, actionLoading}, getActionColumns} = useContext(ReportGeneratorContext);
    const {t} = useTranslate("common");

    return (
        <div className="d-flex align-items-center flex-wrap gap-2">

            <div className="d-flex flex-column flex-grow-1">
                <span>{t("column")} X (YYYYMM)</span>

                <SelectComponent id={'x'} name={'x'} defaultValue={action.x ? {label: action.x, value: action.x} : null}
                                 menuPosition={"fixed"} options={getActionColumns(action)} onChange={evt => {

                    action.x = evt.value
                }}
                                 disabled={(action.temporal_id && actionLoading[action.temporal_id] === State.PENDING) || State.PENDING === isLoading}
                                 loading={(action.temporal_id && actionLoading[action.temporal_id] === State.PENDING) || State.PENDING === isLoading}
                                 placeholder={"ej: purchase total"}/>

            </div>

            <div className="d-flex flex-column flex-grow-1">
                <span>{t("column")} Y</span>
                <SelectComponent id={'y'} menuPosition={"fixed"}
                                 disabled={(action.temporal_id && actionLoading[action.temporal_id] === State.PENDING) || State.PENDING === isLoading}
                                 loading={(action.temporal_id && actionLoading[action.temporal_id] === State.PENDING) || State.PENDING === isLoading}
                                 defaultValue={action.y ? {label: action.y, value: action.y} : null} name={'y'}
                                 options={getActionColumns(action)} onChange={evt => {
                    action.y = evt.value
                }} placeholder={"ej: purchase date"}/>
            </div>


            <div className="d-flex flex-column">
                <span>{t("report:forecast")} {t("from")}</span>
                <input type="text" className="form-control"
                       placeholder="ej: 202206" defaultValue={action.forecast_from || ''} onChange={evt => {
                    action.forecast_from = evt.currentTarget.value
                }}/>
            </div>

            <div className="d-flex flex-column">
                <span>{t("report:forecast")} {t("to")}</span>
                <input type="text" className="form-control" defaultValue={action.forecast_to || ''}
                       placeholder="ej: 202207" onChange={evt => {
                    action.forecast_to = evt.currentTarget.value
                }}/>
            </div>
        </div>
    );
};

export default ForecastActionComponent;
