import * as React from 'react';
import ReportSummarizeColumnsComponent from "./ReportSummarizeColumnsComponent";
import {ActionButton} from "../../NewReportGeneratorComponent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight, faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import {Control, UseFieldArrayReturn, UseFormGetValues, UseFormWatch} from "react-hook-form";
import {ReportSummarizeForm} from "./ReportSummarizeComponent";
import {AnalyticQueryAction} from "../../../../types/Analytic";
import {ModelsResponse} from "../../../../types/Sbx";
import useTranslate from "../../../../hooks/useTranslate";

type Props = {
    methods: UseFieldArrayReturn<ReportSummarizeForm, any, "id">
    action?: AnalyticQueryAction,
    indexAction?: number
    watch: UseFormWatch<ReportSummarizeForm>
    getValues: UseFormGetValues<ReportSummarizeForm>
    color?: string
    isDrillDown?: boolean
    model?: ModelsResponse
    control: Control<ReportSummarizeForm, any>
    formName: 'columns' | 'index_columns' | 'hierarchy_columns'
    label?: string
};
const SummarizeColumnsComponent = ({methods, action, indexAction, isDrillDown, label,
                                       watch, getValues, color, model, control, formName}: Props) => {

    const {t} = useTranslate("report")

    return (
        <div className="d-flex align-items-center flex-wrap gap-3">
            {methods.fields.length > 0 && <div className="d-flex flex-wrap gap-2">
                {methods.fields.map((filter, index) =>
                    <div key={filter.id} className="d-flex align-items-center gap-1">
                        {isDrillDown && index > 0 && <FontAwesomeIcon icon={faArrowRight}/>}
                        <ReportSummarizeColumnsComponent index={index}
                                                         model={model}
                                                         queryAction={action}
                                                         indexAction={indexAction}
                                                         watch={watch} color={color}
                                                         formName={formName}
                                                         control={control} getValues={getValues}
                                                         remove={methods.remove}/>
                    </div>

                )}</div>}


            <ActionButton className="btn" color={color ?? "white"} textColor={"white"}
                          onClick={() => {
                              methods.append({
                                  name: ""
                              })
                          }}>

                <FontAwesomeIcon icon={faPlusCircle}/>
                {/*{methods.fields.length === 0 ? `${t("common:add")} ${t("common:column")}` : ""}*/}
                {label ?? `${t("common:add")} ${t("common:column")}`}
            </ActionButton>

        </div>
    );
};

export default SummarizeColumnsComponent