import * as React from 'react';
import {Fragment, useContext, useEffect, useRef, useState} from 'react';
import {State} from "../../types/State";
import {ActionFilterOperator, AnalyticQueryAction, SourceFilter, SourceFrom} from "../../types/Analytic";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretDown, faPlay, faPlusCircle, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import CustomTableComponent from "../Shared/CustomTableComponent/CustomTableComponent";
import {ReportContext} from "./NewReportGeneratorComponent";
import {Controller, useFieldArray, useForm} from "react-hook-form";
import FilterDropdownComponent from "./FilterDropdownComponent";
import {Switch} from "antd";
import useTranslate from "../../hooks/useTranslate";
import {getAnalyticActionFilterOption, removeBracketsFromString} from "../../utils";
import {SbxConditionType, SbxConditionTypeReport, SbxModelField} from "../../types/Sbx";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import styled from "styled-components";

// const FilterContainer = styled.div`
//   display: grid;
//   grid-template-columns: 1fr 1fr;
// `
type Props = {
    queryAction?: AnalyticQueryAction,
    indexAction?: number
    setFilter?: (filter: string) => void
    color?: string
}

const isValidCondition = (condition: SourceFilter, isQueryAction: boolean) => {
    if (isQueryAction) {

        if (condition.custom) {
            return !!condition.value
        }

        return condition.field && condition.filter_operator
    }

    return condition.field && condition.filter_operator && condition.value;
};

export const conditionOptions = (t: (str: string) => string, isQueryAction: boolean = false) => {
    return [
        {
            label: `${t("EQUAL_TO")} (${SbxConditionType.EQUAL_TO})`,
            value: isQueryAction ? "==" : SbxConditionType.EQUAL_TO
        },
        {
            label: `${t("GREATER_THAN")} (${SbxConditionType.GREATER_THAN})`,
            value: SbxConditionType.GREATER_THAN,
            type: [SbxModelField.INT, SbxModelField.FLOAT, SbxModelField.DATE]
        },
        {
            label: `${t("GREATER_OR_EQUAL_THAN")} (${ActionFilterOperator.GREATER_OR_EQUAL_THAN})`,
            value: ActionFilterOperator.GREATER_OR_EQUAL_THAN,
            type: [SbxModelField.INT, SbxModelField.FLOAT, SbxModelField.DATE]
        },
        {
            label: `${t("SMALLER_THAN")} (${SbxConditionType.SMALLER_THAN})`,
            value: SbxConditionType.SMALLER_THAN,
            type: [SbxModelField.INT, SbxModelField.FLOAT, SbxModelField.DATE]
        },
        {
            label: `${t("SMALLER_OR_EQUAL_THAN")} (${ActionFilterOperator.SMALLER_OR_EQUAL_THAN})`,
            value: ActionFilterOperator.SMALLER_OR_EQUAL_THAN,
            type: [SbxModelField.INT, SbxModelField.FLOAT, SbxModelField.DATE]
        },
        {
            label: `${t("DIFFERENT_OF")} (${SbxConditionType.DIFFERENT_OF})`,
            value: isQueryAction ? "!=" : "!==",
            type: [SbxModelField.INT, SbxModelField.FLOAT, SbxModelField.DATE]
        },
        {
            label: `${t("report:EXIST")} (${SbxConditionTypeReport.EXIST})`,
            value: isQueryAction ? "{column} == {column}" : SbxConditionTypeReport.EXIST,
        },
        {
            label: `${t("report:NO_EXIST")} (${SbxConditionTypeReport.NO_EXIST})`,
            value: isQueryAction ? "{column} != {column}" : SbxConditionTypeReport.NO_EXIST,
        },
        {
            label: t("START_LIKE"),
            value: isQueryAction ? "'{column}'.startswith('{value}')" : "LIKE",
            format: "%word",
            type: [SbxModelField.STRING]
        },
        {
            label: t("IN"),
            value: "IN",
            type: [SbxModelField.STRING, SbxModelField.REFERENCE, SbxModelField.INT]
        },
        // {
        //     label: t("range"),
        //     value: "range",
        //     type: [SbxModelField.DATE, SbxModelField.STRING]
        // },
        // {
        //     label: "Keys",
        //     value: "keys"
        // },
        {
            label: t("END_LIKE"),
            value: isQueryAction ? "'{column}'.endswith('{value}')" : "LIKE",
            format: "word%",
            type: [SbxModelField.STRING]
        },
        {
            label: t("CONTAIN_LIKE"),
            value: isQueryAction ? "{column}.str.contains({value})" : "LIKE",
            // value: isQueryAction ? "{value} in {column}" : "LIKE",
            format: "%word%",
            type: [SbxModelField.STRING, SbxModelField.REFERENCE]
        }
    ];
}

export const conditionOptionsDisplay = (t: (str: string) => string, isQueryAction: boolean = false) => [
    {
        label: SbxConditionType.EQUAL_TO,
        value: isQueryAction ? "==" : SbxConditionType.EQUAL_TO
    },
    {
        label: "Keys",
        value: "keys"
    },
    {
        label: t("range"),
        value: "range"
    },
    {
        label: "IN",
        value: "IN",
        type: [SbxModelField.STRING, SbxModelField.REFERENCE, SbxModelField.INT]
    },
    {
        label: SbxConditionType.GREATER_THAN,
        value: SbxConditionType.GREATER_THAN,
        type: [SbxModelField.INT, SbxModelField.FLOAT, SbxModelField.DATE]
    },
    {
        label: ActionFilterOperator.GREATER_OR_EQUAL_THAN,
        value: ActionFilterOperator.GREATER_OR_EQUAL_THAN,
        type: [SbxModelField.INT, SbxModelField.FLOAT, SbxModelField.DATE]
    },
    {
        label: ActionFilterOperator.SMALLER_OR_EQUAL_THAN,
        value: ActionFilterOperator.SMALLER_OR_EQUAL_THAN,
        type: [SbxModelField.INT, SbxModelField.FLOAT, SbxModelField.DATE]
    },
    {
        label: SbxConditionType.SMALLER_THAN,
        value: SbxConditionType.SMALLER_THAN,
        type: [SbxModelField.INT, SbxModelField.FLOAT, SbxModelField.DATE]
    },
    {
        label: "!==",
        value: isQueryAction ? "!=" : "!==",
        type: [SbxModelField.INT, SbxModelField.FLOAT, SbxModelField.DATE]
    },
    {
        label: `${t("report:EXIST")} (${SbxConditionTypeReport.EXIST})`,
        value: isQueryAction ? "{column} == {column}" : SbxConditionTypeReport.EXIST,
    },
    {
        label: `${t("report:NO_EXIST")} (${SbxConditionTypeReport.NO_EXIST})`,
        value: isQueryAction ? "{column} != {column}" : SbxConditionTypeReport.NO_EXIST,
    },
    {
        label: t("START_LIKE"),
        value: isQueryAction ? "'{column}'.startswith('{value}')" : "LIKE",
        format: "%word",
        type: [SbxModelField.STRING]
    }, {
        label: t("END_LIKE"),
        value: isQueryAction ? "'{column}'.endswith('{value}')" : "LIKE",
        format: "word%",
        type: [SbxModelField.STRING]
    },
    {
        label: t("CONTAIN_LIKE"),
        value: isQueryAction ? "{column}.str.contains({value})" : "LIKE",
        format: "%word%",
        type: [SbxModelField.STRING, SbxModelField.REFERENCE]
    }
];

const isInvalidFilter = (filter: string) => {
    return filter.trim().startsWith('&') || filter.trim().startsWith('|');
};

const stringOperations = [".str.contains"]

const stringOperationsTransform: { [key: string]: string } = {
    ".str.contains": "{column}.str.contains({value})"
}

const ContainerFilter = styled.div`

    display: grid;
    grid-template-columns: 1fr 10em;
    
`

const ReportFilterComponent = ({indexAction, queryAction, color, setFilter}: Props) => {

    let {
        control, watch, getValues, setValue
    } = useForm<{ filters: SourceFilter[] }>({});
    let onMount = useRef(false)

    const conditionMethods = useFieldArray({
        control,
        name: "filters"
    });

    const {t} = useTranslate("common")

    const {state, getData, setQuery, query, setQueryAction} = useContext(ReportContext)
    const [showResults, setShowResults] = useState(false)
    const [loading, setLoading] = useState(State.IDLE)
    const [results, setResults] = useState<any[]>([])
    const [dropdownOpen, setDropdownOpen] = useState(false)
    const handleGetData = () => {
        const filters = getValues("filters") ?? []
        getData({
            setLoading,
            setResults,
            query: {
                source: {...query.source, filters},
                actions: [],
                preview: true
            }
        })
    }


    useEffect(() => {
        const subscription = watch((obj) => {
            if (obj['filters']) {
                let filters = obj['filters']

                let filter = filters.reduce((str, condition) => {
                    // debugger
                    if (condition && isValidCondition(condition as SourceFilter, !!queryAction)) {

                        const condition_operator = (condition.logic_operator === 'and' || condition.logic_operator === '&') ? '&' : '|';
                        if (condition_operator) {
                            str += `${condition_operator} `;
                        }

                        let conditionStr = condition.custom ? condition.value : `${condition.field} ${condition.filter_operator ?? ""} ${condition.value} `

                        if (condition.filter_operator?.includes("column")) {
                            conditionStr = condition?.filter_operator?.replaceAll("{column}", condition.field ?? "")
                        }

                        if (condition.filter_operator?.includes("value")) {
                            conditionStr = conditionStr.replaceAll("{value}", `'${condition.value}'`)
                        }

                        str += `${condition_operator ? '' : ''} ${conditionStr} ${condition_operator ? '' : ''} `;
                    }

                    return str;
                }, '');

                if (filter) {
                    while (isInvalidFilter(filter)) {
                        filter = filter.trim().replace(filter.trim().substr(0, 1), '');
                    }
                }

                if (setFilter){
                    setFilter(filter.length > 0 ? filter : '*')
                }else{
                    if (typeof indexAction !== 'undefined' && queryAction && indexAction >= 0) {
                        setQueryAction({
                            ...queryAction,
                            filter: filter.length > 0 ? filter : '*'
                        }, indexAction)

                    } else {
                        setQuery(query => ({
                            ...query,
                            source: {
                                ...query.source,
                                filters: [...filters].map(filter => ({
                                    ...filter,
                                    filter_operator: filter?.filter_operator?.includes("%") ? "LIKE" : filter?.filter_operator,
                                    value: filter?.filter_operator?.includes("%") ? filter.filter_operator.replace("word", filter.value) : filter?.value
                                })) as SourceFilter[]
                            },
                        }))
                    }
                }


            }
        });
        return () => subscription.unsubscribe();
    }, [watch, setQuery, queryAction, indexAction, query]);

    React.useEffect(() => {
        if (conditionMethods.fields.length === 0 && !onMount.current) {
            if (!queryAction && query.source.filters!?.length > 0 && !setFilter) {
                setValue("filters", query.source.filters! ?? [])
            }

            if (queryAction && queryAction.filter && queryAction.filter!?.length > 0 && queryAction.filter !== "*") {
                const conditionList = [".contains", ".str"].some(filterTransform => queryAction.filter?.includes(filterTransform)) ? queryAction.filter.split(/([&|])/) : removeBracketsFromString(queryAction.filter).split(/([&|])/);
                // const conditionList = queryAction.filter.split(/([&|])/);
                let item: Partial<SourceFilter> = {};
                const actionConditions = conditionList.reduce((arr: Partial<SourceFilter>[], field) => {
                    if (field.trim() === '&' || field.trim() === '|') {
                        // Add operator condition
                        item = {
                            logic_operator: field.trim()
                        };
                    } else {
                        const [column, operator, value] = field.split(/(==|!=|>|<o|is not null|is null|<=|>=')/);
                        if (column && operator && value) {
                            item = {
                                ...item,
                                field: column.trim(),
                                filter_operator: getAnalyticActionFilterOption(t).find(item => item.value === operator.trim())?.value,
                                value: value.trim()
                            };
                        } else {
                            if (column) {

                                let filter_operator = stringOperations.find(item => column.includes(item))

                                if (filter_operator) {
                                    const [column, value] = field.split(filter_operator);
                                    item = {
                                        ...item,
                                        field: column.trim(),
                                        filter_operator: stringOperationsTransform[filter_operator] ?? filter_operator,
                                        value: removeBracketsFromString(value.trim()).replaceAll("'", "")
                                    };
                                } else {
                                    item = {
                                        ...item,
                                        custom: true,
                                        value: column
                                    };
                                }


                            }
                        }

                        arr.push(item);
                        item = {};
                    }

                    return arr;
                }, []);
                setValue("filters", (actionConditions as SourceFilter[]) ?? [])
            }

            onMount.current = true
        }
    }, [query, queryAction, conditionMethods.fields]);

    return (
        <div className="d-flex flex-column">

            <div className="d-flex align-items-center gap-3">
                <ContainerFilter className="align-items-center gap-3">

                    {

                        <div className="d-flex align-items-center flex-wrap gap-2">
                            {conditionMethods.fields.length > 0 ? conditionMethods.fields.map((filter, index) =>
                                <Fragment key={filter.id}>
                                    {
                                        index > 0 &&
                                        <div>
                                            <Controller control={control} render={({field}) => {
                                                return <Switch style={{marginTop: "0.2rem"}}
                                                               unCheckedChildren={'Or'}
                                                               checkedChildren={t('And')}
                                                               onChange={checked => {
                                                                   field.onChange(checked ? 'and' : 'or')
                                                               }}
                                                               checked={field.value === 'and' || field.value === "&"}/>
                                            }} name={`filters.${index}.logic_operator`}/>

                                        </div>
                                    }


                                    {filter.custom ?
                                        <div className="d-flex flex-column"><b>{t("report:custom_filter")}</b>
                                            <div className="input-group mb-3">
                                                <Controller render={({field}) => {
                                                    return <input defaultValue={filter.value || ""} type="text"
                                                                  style={{width: '35em'}} onChange={evt => {
                                                        field.onChange(evt.target.value)
                                                    }}
                                                                  className="form-control"/>
                                                }} name={`filters.${index}.value`} control={control}/>

                                                <span className="input-group-text"
                                                      id="basic-addon2"><FontAwesomeIcon icon={faTimesCircle}
                                                                                         className="pointer px-1"
                                                                                         onClick={() => {
                                                                                             conditionMethods.remove(index)
                                                                                         }}/></span>
                                            </div>
                                        </div> :
                                        <FilterDropdownComponent index={index} model={state.model}
                                                                 queryAction={queryAction}
                                                                 indexAction={indexAction} color={color}
                                                                 update={conditionMethods.update} watch={watch}
                                                                 control={control}
                                                                 remove={conditionMethods.remove}/>
                                    }
                                </Fragment>
                            ) : null}

                            <Dropdown isOpen={dropdownOpen}
                                      toggle={() => setDropdownOpen(prevState => !prevState)}>
                                <DropdownToggle style={{background: color, borderColor: color}}>
                                    {t("report:add_filter")} {" "}
                                    <FontAwesomeIcon className="text-white" icon={faCaretDown}/>
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={() => {
                                        conditionMethods.append({
                                            field: "",
                                            filter_operator: queryAction ? "==" : "=",
                                            logic_operator: queryAction ? "&" : "and",
                                            value: null
                                        })
                                    }}>
                                        <FontAwesomeIcon className="mr-1" color={color} icon={faPlusCircle}/> {" "}
                                        <span>
                                    {t("report:add_filter")}
                                        </span>
                                    </DropdownItem>

                                    {!queryAction && !setFilter && <DropdownItem onClick={() => {
                                        conditionMethods.append({
                                            field: "",
                                            filter_operator: query.source.from === SourceFrom.SBX_EVENT ? "range" :"keys",
                                            logic_operator: "and",
                                            value: null
                                        })
                                    }}>
                                        <FontAwesomeIcon className="mr-1" color={color} icon={faPlusCircle}/> {" "}
                                        <span>{t(`report:${query.source.from === SourceFrom.SBX_EVENT ? "add_filter_by_range" : "add_filter_by_keys"}`)}</span>
                                    </DropdownItem>}
                                    {!!queryAction && <DropdownItem onClick={() => {
                                        conditionMethods.append({
                                            custom: true,
                                            value: "",
                                            field: "",
                                            filter_operator: "",
                                            logic_operator: ""
                                        })
                                    }}>
                                        <FontAwesomeIcon color={color} className="mr-1" icon={faPlusCircle}/> {" "}
                                        <span>
                                        {t("add")} {" "}
                                            {t("report:custom_filter")}
                                        </span>
                                    </DropdownItem>}
                                </DropdownMenu>
                            </Dropdown>
                            {!queryAction && !setFilter &&<FontAwesomeIcon className="pointer" icon={faPlay} onClick={() => {
                                setShowResults(true)
                                handleGetData()
                            }}/>}
                        </div>
                    }




                </ContainerFilter>

            </div>


            {showResults && <div className="p-3">
                <div className="d-flex gap-2 align-items-center card-header mb-2">
                    <span>{t("common:preview")}</span>
                    <FontAwesomeIcon icon={faTimesCircle} className="pointer"
                                     onClick={() => {
                                         setShowResults(false)
                                     }}/>
                </div>

                <CustomTableComponent
                    columns={results && results[0] ? Object.keys(results[0]).map(item => ({
                        name: item,
                        header: item
                    })) : []}
                    data={results?.length > 0 ? results : []}
                    loading={loading === State.PENDING}
                />
            </div>}
        </div>
    );
};

export default ReportFilterComponent