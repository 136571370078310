import { capitalize } from "../../utils";
import { TreeViewDataItem } from "../Shared/TreeComponent";

export const separator = "->";

export interface Data {
  field: string;
  value?: any;
  type: types;
  id: string;
}

export type types =
  | "string"
  | "number"
  | "table"
  | "boolean"
  | "string_object"
  | "list_string"
  | "list_number";

export interface TypeRule {
  field: string;
  type: types;
}

export function recursive(
  p: any,
  arr: Data[],
  keyP: string,
  extra: { rules: TypeRule[] },
  id?: string,
) {
  if (p) {
    const keys = Object.keys(p);
    keys.forEach((key, index) => {
      const pkey = `${keyP}${keyP ? separator : ""}${key}`;
      const pRule = extra.rules.find((rule) => rule.field === pkey);
      const pId = `${id ?? ""}${id ? "_" : ""}${index}`;

      if (pRule) {
        arr.push({ type: pRule.type, value: p[key], field: pkey, id: pId });
      } else {
        let _type = typeof p[key];

        switch (_type) {
          case "string":
          case "number":
          case "boolean":
            arr.push({ type: _type, value: p[key], field: pkey, id: pId });
            break;
          case "object":
            if (Array.isArray(p[key])) {
              const value = p[key];
              const typeValue = typeof value[0];

              switch (typeValue) {
                case "string":
                case "undefined":
                  arr.push({
                    type: "list_string",
                    value: p[key],
                    field: pkey,
                    id: pId,
                  });
                  break;
                case "number":
                  arr.push({
                    type: "list_number",
                    value: p[key],
                    field: pkey,
                    id: pId,
                  });
                  break;
                case "object":
                  if (Array.isArray(value[0])) {
                    arr.push({
                      type: "string_object",
                      value: p[key],
                      field: pkey,
                      id: pId,
                    });
                  } else {
                    arr.push({
                      type: "table",
                      value: p[key],
                      field: pkey,
                      id: pId,
                    });
                  }
                  break;
              }
            } else {
              recursive(p[key], arr, pkey, extra, pId);
            }
            break;
        }
      }
    });
  }

  return arr;
}

export function scrollToView(
  id: string,
  time: number,
  color?: "primary" | "light" | "warning" | "danger",
) {
  const element = document.getElementById(id);
  const child = document.getElementById(id + "child");
  if (element && child) {
    const classAnimation = ` bg-${color ?? "warning"} p-2 rounded `;
    child.className += classAnimation;
    setTimeout(() => {
      element.scrollIntoView(true);
      setTimeout(() => {
        child.className = child.className.replace(classAnimation, "");
      }, 2000);
    }, time);
  }
}

export function scrollTo(id: string) {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
  }
}

export function recursiveTree(
  p: any,
  arr: TreeViewDataItem[],
  keyP: string,
  extra: { rules: any[] },
  id?: string,
) {
  if (p) {
    const keys = Object.keys(p);
    keys.forEach((key, index) => {
      const pkey: string = `${keyP}${keyP ? separator : ""}${key}`;
      const pRule = extra.rules.find((rule) => rule.field === pkey);
      const text = capitalize(key.split("_").join(" "));
      const pId = `${id ?? ""}${id ? "_" : ""}${index}`;

      if (pRule) {
        return arr.push({
          text: key,
          checked: true,
          data: { key: pkey },
          id: pId,
        });
      } else {
        const _type = typeof p[key];
        switch (_type) {
          case "object":
            if (Array.isArray(p[key])) {
              return arr.push({
                text,
                checked: true,
                data: { key: pkey },
                id: pId,
              });
            } else {
              return arr.push({
                text,
                items: recursiveTree(p[key], [], pkey, extra, pId),
                data: { key: pkey },
                id: pId,
              });
            }
          default:
            return arr.push({
              text,
              checked: true,
              data: { key: pkey },
              id: pId,
            });
        }
      }
    });
  }

  return arr;
}
