import * as React from 'react';
import {useContext} from 'react';
import {ReportGeneratorContext} from "../../ReportGeneratorComponent";
import useTranslate from "../../../../hooks/useTranslate";
import CreatableSelectComponent from "../../../Shared/FieldComponents/CreatableSelectComponent";
import {StringOption} from "../../../../types/Select";
import {AnalyticQueryAction} from "../../../../types/Analytic";
import styled from "styled-components";

type Props = {
    action: AnalyticQueryAction
}

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 13em;
  gap: 15px;

  @media (max-width: 998px) {
    grid-template-columns: 1fr;
  }
`

const SegmentationActionComponent = ({action}: Props) => {

    const {getActionColumns} = useContext(ReportGeneratorContext);
    const {t} = useTranslate('common');

    return (
        <Container>
            <div>
                <span>{t("report:id")}</span>
                <CreatableSelectComponent name={"id"} defaultValue={action.id ? {
                    label: action.id,
                    value: action.id
                } : null} options={getActionColumns(action)} onChange={evt => {
                    action.id = evt.value
                }}/>
            </div>
            <div>
                <span>{t("columns")}</span>
                <CreatableSelectComponent name={"binary_columns"}
                                          defaultValue={action.columns ? (action.columns as string[]).map(column => ({
                                              label: column,
                                              value: column
                                          })) : null} options={getActionColumns(action)} isMulti onChange={evt => {
                    if (action.columns) {
                        action.columns = evt.map((event: StringOption) => event.value)
                    }

                }}/>
            </div>

            <div className="d-flex flex-column">
                <span>{t("report:n_segments")} </span>
                <input type="text" className="form-control"
                       placeholder={`${t("report:n_segments")}`} defaultValue={action.n_segments || 0}
                       onChange={evt => {
                           action.n_segments = parseInt(evt.currentTarget.value)
                       }}/>
            </div>


        </Container>
    );
};

export default SegmentationActionComponent