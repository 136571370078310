import React, {useContext, useState} from 'react';
import {ActionFilterOperator, AnalyticQueryAction} from '../../../types/Analytic';
import {getAnalyticActionFilterOption, removeBracketsFromString, uuidV4} from '../../../utils';
import {Switch} from 'antd';
import {Button} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlusCircle, faTrash} from '@fortawesome/free-solid-svg-icons';
import {ReportGeneratorContext} from '../ReportGeneratorComponent';
import useTranslate from '../../../hooks/useTranslate';
import CreatableSelectComponent from "../../Shared/FieldComponents/CreatableSelectComponent";
import {State} from "../../../types/State";
import styled from "styled-components";

type Props = {
    action: AnalyticQueryAction
    transformData?: boolean
}

interface Condition {
    condition_operator?: string | '&' | '|',
    column?: string,
    operator?: ActionFilterOperator,
    value?: string
}

const Container = styled.div`
  display: flex;
  flex-direction: column;

  @keyframes fadeInDown {
    0% {
      opacity: 0;
      transform: translateY(-1.25em);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
    animation-name: fadeInDown;
    animation-duration: 0.5s;
  
`

const isValidCondition = (condition: Condition) => {
    return condition.column && condition.operator && condition.value;
};

const isInvalidFilter = (filter: string) => {
    return filter.trim().startsWith('&') || filter.trim().startsWith('|');
};


const FilterActionComponent = ({action}: Props) => {

    const {t} = useTranslate('common');
    const [conditions, setConditions] = useState<Condition[]>([]);


    const {
        getActionColumns, reportState: {actionLoading}
    } = useContext(ReportGeneratorContext);

    React.useEffect(() => {
        if (conditions.length > 0) {
            let filter = conditions.reduce((str, condition) => {
                if (isValidCondition(condition)) {
                    if (condition.condition_operator) {
                        str += `${condition.condition_operator} `;
                    }

                    // str += `${condition.condition_operator ? '(' : ''} ${condition.column} ${condition.operator} ${isValidNumber(condition.value ?? '') ? parseFloat(condition.value ?? '') : condition.value} ${condition.condition_operator ? ')' : ''} `;
                    str += `${condition.condition_operator ? '(' : ''} ${condition.column} ${condition.operator} ${condition.value} ${condition.condition_operator ? ')' : ''} `;
                }

                return str;
            }, '');

            if (filter) {
                while (isInvalidFilter(filter)) {
                    filter = filter.trim().replace(filter.trim().substr(0, 1), '');
                }
            }

            action.filter = filter.length > 0 ? filter : '*';
        }
    }, [conditions]);

    React.useEffect(() => {

        if (action.filter) {
            const conditionList = removeBracketsFromString(action.filter).split(/([&|])/);

            let item: Condition = {};
            const actionConditions = conditionList.reduce((arr: Condition[], field, index) => {
                if (field.trim() === '&' || field.trim() === '|') {
                    // Add operator condition
                    item = {
                        condition_operator: field.trim()
                    };
                } else {
                    const [column, operator, value] = field.split(/(==|!=|>|<|is not null|is null|<=|>=')/);
                    if (column && operator && value) {
                        item = {
                            ...item,
                            column: column.trim(),
                            operator: getAnalyticActionFilterOption(t).find(item => item.value === operator.trim())?.value,
                            value: value.trim()
                        };
                    }

                    arr.push(item);
                    item = {};
                }

                return arr;
            }, []);

            if (actionConditions.length === 0) {
                action.filter = "*"
            }
            setConditions(actionConditions);
        }


    }, [action]);

    const getConditionDefault = (operator?: ActionFilterOperator) => {
        if (!operator) {
            return null;
        }
        return getAnalyticActionFilterOption(t).find(item => item.value === operator);
    };

    const getBaseInput = ({index}: { index: number }) => {
        return <div className="d-flex flex-column  mt-2" key={index}>
            {
                conditions.length > 1 && index > 0 &&
                <div>
                    <Switch
                        unCheckedChildren={'Or'}

                        checkedChildren={t('And')}
                        onChange={checked => {
                            updateCondition({
                                index,
                                propertyKey: 'condition_operator',
                                value: checked ? '&' : '|'
                            });
                        }}
                        checked={conditions[index].condition_operator === '&'}/>
                </div>
            }
            <div className="row align-items-end">
                <div className="col-xs-12 col-lg-3 col-xl-4">
                    <span>{t('field')}</span>
                    <CreatableSelectComponent id={uuidV4()} name={'column'} defaultValue={conditions[index]?.column ? {
                        label: conditions[index]?.column,
                        value: conditions[index]?.column
                    } : null} menuPosition={'fixed'} onChange={evt => {
                        updateCondition({index, propertyKey: 'column', value: evt.value});
                    }} options={getActionColumns(action)}
                                              loading={action.temporal_id ? actionLoading[action.temporal_id] === State.PENDING : false}
                                              disabled={action.temporal_id ? actionLoading[action.temporal_id] === State.PENDING : false}
                    />
                </div>

                <div className="col-xs-12 col-lg-3 col-xl-4">
                    <span>{t('condition')}</span>
                    <CreatableSelectComponent id={uuidV4()} name={'options'} menuPosition={'fixed'}
                                              defaultValue={getConditionDefault(conditions[index]?.operator)}
                                              loading={action.temporal_id ? actionLoading[action.temporal_id] === State.PENDING : false}
                                              disabled={action.temporal_id ? actionLoading[action.temporal_id] === State.PENDING : false}
                                              onChange={evt => {
                                                  updateCondition({index, propertyKey: 'operator', value: evt.value});

                                                  if (evt.value === ActionFilterOperator.DIFFERENT_OF || evt.value === ActionFilterOperator.EQUAL_TO) {
                                                      updateCondition({
                                                          index,
                                                          propertyKey: 'value',
                                                          value: conditions[index]?.column
                                                      });
                                                  }

                                              }}
                                              options={getAnalyticActionFilterOption(t)}/>
                </div>

                <div className="col-xs-12 col-lg-3 ">
                    {t('value')}
                    <input type="text" className="form-control" placeholder="value"
                           defaultValue={conditions[index]?.value || ''}
                           onChange={event => updateCondition({
                               index,
                               propertyKey: 'value',
                               value: event.currentTarget.value
                           })}/>
                </div>

                <div className="col-xs-12 text-end col-lg-1">
                    <span>&nbsp;</span>
                    <Button color="danger"
                            onClick={() => setConditions(currentCondition => {
                                const newConditions = currentCondition.filter((cond, cIndex) => cIndex !== index)
                                if (newConditions.length === 0) {
                                    action.filter = "*"
                                }
                                return newConditions
                            })}>
                        <FontAwesomeIcon icon={faTrash}/>
                    </Button>
                </div>
            </div>
        </div>;
    };

    const updateCondition = ({
                                 index,
                                 propertyKey,
                                 value
                             }: { index: number, propertyKey: keyof Condition, value: any }) => {


        setConditions(conditions => {
            const newConditions = [...conditions];
            newConditions[index][propertyKey] = value;
            return newConditions;
        });
    };

    return (
        <div className="mt-2 ">
            <div className="text-end gap-2 mb-2">
                <Button color="primary"
                        onClick={() => setConditions(currentConditions => [...currentConditions, currentConditions.length > 0 ? {condition_operator: '&'} : {}])}>
                    <FontAwesomeIcon icon={faPlusCircle} className="me-2"/>
                    {t('add')} {t('condition')}
                </Button>
            </div>

            {conditions.length > 0 &&
                <Container>


                    {conditions.map((condition, index) => {
                        return getBaseInput({index});
                    })}
                </Container>
            }
        </div>
    );
};

export default FilterActionComponent;
