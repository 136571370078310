import {AnyData} from "../../types/AnyData";
import React, {FormEvent, useCallback, useEffect, useState} from "react";
import SmallTextComponent from "../Shared/FieldComponents/SmallTextComponent";
import {Button, Card, CardBody, Col, FormGroup, Label, Row} from "reactstrap";
import EditorComponent from "../Shared/EditorComponent/EditorComponent";
import {validateQuery} from "../Shared/QueryComponent/QueryComponent";
import {Switch} from "antd";
import CustomTableComponent, {CustomTableColumnType} from "../Shared/CustomTableComponent/CustomTableComponent";
import {capitalize, toNumber} from "../../utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faPlus, faPlusCircle, faTrash,} from "@fortawesome/free-solid-svg-icons";
import {InputTags} from "react-bootstrap-tagsinput";
// import {actionsConfig} from '../../store/Config/Slice';
import {actionsModal, ModalTypes} from '../../store/Modal/Slice';
import {PermissionStates} from '../../store/Permission/Types';
// import {FormDataList} from '../Shared/Modal/ConfigModal/AccountConfigModal';
import {useDispatch} from 'react-redux';
import useTranslate from '../../hooks/useTranslate';
import NumberComponent from "../Shared/FieldComponents/NumberComponent";
import TreeViewComponent from "../Shared/TreeViewComponent";
import {Data, recursive, recursiveTree, scrollToView, separator, TypeRule} from "./utils";
import {State} from "../../types/State";
import NewPropertyComponent, {IOption, optionsProperties} from "./NewPropertyComponent";
import {PropsType} from "../../types/PropsType";
import {TreeViewDataItem} from "../Shared/TreeComponent";
import {PARENT_SEPARATOR} from "../Shared/TreeComponent/TreeItemRecursive";

function getField(field: string) {
  let nf: string[] | string = field.split(separator);
  nf = nf.map((key, i) => key.includes(".") ? `["${key}"]` : key).join(".").split(".[").join("[");
  return nf;
}

const getNewRowFromExistingData = (rows: any[]): AnyData => {

  const toObject = (value: AnyData) => {
    const isArray = Array.isArray(value);
    if (isArray) {
      const type = typeof value[0];
      switch (type) {
        case "number":
          return [0];
        case "undefined":
        case "string":
          return [""];
        default:
          return [getNewRowFromExistingData([value[0]])];
      }
    }

    return getNewRowFromExistingData([value]);
  }
  return rows.reduce((obj, row) => {
    Object.keys(row).forEach(key => {
      if (obj[key] === undefined) {
        const type = typeof row[key];
        switch (type) {
          case "boolean":
            obj[key] = false;
            break;
          case "number":
            obj[key] = 0;
            break;
          case "object":
            obj[key] = toObject(row[key]);
            break;
          default:
            obj[key] = "";
            break;
        }
      }
    });
    return obj;
  }, {});
}


const DynamicFieldsFromConfig = (props: {
  identifier?: string;
  config: AnyData;
  rules: TypeRule[];
  height?: string;
  onChange: (data: any) => void;
  filter?: string;
}) => {

  const [types, setTypes] = useState<Data[]>([]);
  const [tree, setTree] = useState<TreeViewDataItem[]>([]);
  const dispatch = useDispatch();
  const [selected, setSelected] = useState<string>("");
  const {t} = useTranslate('config');

  function onView(item: TreeViewDataItem) {
    scrollToView(item.data.key.split(separator).join(""), 100);
  }

  const callback = useCallback((config: AnyData) => {
    const result = recursive(config, [], "", {rules: props.rules});
    const treeData = recursiveTree(config, [], "", {rules: props.rules});
    setTree(treeData);
    setTypes(result);
  }, [])

  useEffect(() => {
    callback(props.config);
  }, [callback]);

  function SplitLabel(props: { label: string }) {
    const id = props.label.split(separator).join("");
    return <Label id={id} className="pt-3">
      <small id={id + "child"} style={{transition: "all .5s"}} className="fw-bolder">
        {props.label.split(separator).map(key => capitalize(key.split("_").join(" "))).join(" - ")}
      </small>
    </Label>
  }


  //update data from json data
  function onChange(value: any, field: string, updateAll?: boolean) {
    let newConfig = {...props.config};
    const tt = typeof value;
    function looseJsonParse() {
      return eval(`(function exec() {
        let cc = ${JSON.stringify(newConfig)};
        cc.${getField(field)} = ${tt === "string" ? `"${value}"` : tt === "object" ? JSON.stringify(value) : value};
        return cc;
      })`)();
    }

    const result = looseJsonParse();
    if (updateAll) {
      callback(result);
      props.onChange(result);
    } else {
      props.onChange(result);
    }
  }


  //remove item from data
  function onRemove(field: string, updateAll?: boolean) {
    let newConfig = {...props.config};

    function looseJsonParse() {
      let nf: string[] | string = field.split(separator);
      nf = nf.map((key, i) => key.includes(".") ? `["${key}"]` : key).join(".").split(".[").join("[");
      return eval(`(function exec() {
        let cc = ${JSON.stringify(newConfig)};
        delete cc.${nf};
        return cc;
      })`)();
    }

    const result = looseJsonParse();
    if (updateAll) {
      callback(result);
      props.onChange(result);
    } else {
      props.onChange(result);
    }
  }

  function onEdit(currentField: string, newField: string, updateAll?: boolean) {
    let newConfig = {...props.config};
    const value = getValue(currentField);
    const tt = typeof value;
    function looseJsonParse() {
      return eval(`(function exec() {
        let cc = ${JSON.stringify(newConfig)};
        cc.${getField(newField)} = ${tt === "string" ? `"${value}"` : tt === "object" ? JSON.stringify(value) : value};
        delete cc.${getField(currentField)};
        return cc;
      })`)();
    }

    const result = looseJsonParse();
    if (updateAll) {
      callback(result);
      props.onChange(result);
    } else {
      props.onChange(result);
    }
  }

  const upsertItemTable = (value: Array<any>, row: any, index: number, field: string) => {

    function update(r: any) {
      onChange(value.map((res, i) => {
        if (index === i) {
          return r;
        } else {
          return res;
        }
      }), field, true)
    }

    function create(r: any) {
      const arr = new Array(...value);
      arr.push(r);
      onChange(arr, field, true);
    }

    dispatch(actionsModal.openModal({
      type: ModalTypes.UPDATE_DYNAMIC_CONFIG,
      title: "Edit ",
      rules: [],
      identifierKey: (props.identifier ?? "") + field,
      data: row ?? getNewRowFromExistingData(value),
      onSuccess: !row ? create : update
    }))
  }

  const removeItemTable = (arr: Array<any>, index: number, field: string) => {
    function onConfirm() {
      onChange(arr.filter((_, i) => index !== i), field);
    }

    dispatch(actionsModal.openModal({
      closeModal: true,
      type: ModalTypes.CONFIRM,
      onConfirm,
      state: PermissionStates.GROUP_PENDING,
      message: <>¿Are you sure to remove this element?</>,
      title: <><FontAwesomeIcon
        icon={faTrash}/> {' ' + t('common:delete')}</>,
    }));
  };


  function getValue(field: string) {
    let value = {...props.config};
    try {
      const keys = field.split(separator);
      let value = {...props.config};
      while (keys.length) {
        value = value[keys.shift() ?? ""];
      }
      return value;
    } catch (e) {
      return value;
    }
  }

  function getFields() {
    if (props.filter === undefined) {
      return types;
    }
    if (!selected) return [];
    const tt = types.filter(e => selected === e.id);
    if (tt.length) {
      return tt;
    } else {

      return types.filter(e => e.id.split(selected).length > 1 &&
        e.id.split(PARENT_SEPARATOR).length === selected.split(PARENT_SEPARATOR).length + 1);
    }
  }


  function onNewItemToConfig(item: TreeViewDataItem) {
    let values: { name: string, value: IOption } = {name: "", value: optionsProperties[0]};

    const key = item.data.key || "form_new_property";

    function submitProp(e: FormEvent<HTMLFormElement>, value: { name: string, option?: IOption }) {
      e.preventDefault();
      onChange(value.option?.default_value, key !== "form_new_property" ? key.concat(separator, value.name) : value.name, true);
      dispatch(actionsModal.closeModal({
        type: ModalTypes.DYNAMIC_COMPONENT_MODAL
      }));
    }

    dispatch(actionsModal.openModal({
      type: ModalTypes.DYNAMIC_COMPONENT_MODAL,
      title: "New property",
      state: State.PENDING,
      form: "form_new_property",
      typeForm: "submit",
      value: values,
      renderComponent: (value, onChangeValue) => (
        <NewPropertyComponent value={value} id="form_new_property" submit={submitProp} onChange={onChangeValue}/>
      )
    }))
  }

  function onRemoveItem(item: TreeViewDataItem) {
    dispatch(actionsModal.openModal({
      type: ModalTypes.CONFIRM,
      title: "Remove property",
      message: <>¿Are you sure to remove this property?</>,
      onConfirm: () => onRemove(item.data.key, true),
      closeModal: true,
    }));
  }

  function onEditItem(item: TreeViewDataItem) {
    function submitProp(e: FormEvent<HTMLFormElement>, value: string) {
      e.preventDefault();
      let newKey = item.data.key.split(separator);
      newKey.pop();
      newKey.push(value)
      newKey = newKey.join(separator);
      onEdit(item.data.key, newKey, true);
      dispatch(actionsModal.closeModal({
        type: ModalTypes.DYNAMIC_COMPONENT_MODAL
      }));
    }

    dispatch(actionsModal.openModal({
      type: ModalTypes.DYNAMIC_COMPONENT_MODAL,
      form: "form_edit_property",
      typeForm: "submit",
      state: State.PENDING,
      value: item.data.key.split(separator).pop(),
      renderComponent: (value, onChangeValue) => (
        <form
          id={"form_edit_property"}
          onSubmit={e => submitProp(e, value)}>
          <div className="form-group">
            <label htmlFor="">Field name</label>
            <input className="form-control" type="text" required value={value}
                   onChange={event => onChangeValue(event.target.value.trim())}/>
          </div>
        </form>
      )
    }));
  }

  const rows = getFields();

  return (
    <div className="pt-3" style={{height: props.height}}>
      <Row>
        <Col
          className="overflow-auto bg-white shadow-sm rounded"
          style={{height: `calc(${props.height})`}} sm={5} lg={3}>
          <>
            <button
              type={"button"}
              onClick={() => onNewItemToConfig({id: "", text: "", data: {key: ""}})}
              className="btn btn-sm btn-link text-success">
              <FontAwesomeIcon icon={faPlusCircle}/> {t("common:new")}
            </button>
          </>
          <TreeViewComponent
            onSelections={setSelected}
            filter={props.filter ?? ''}
            onItemClick={onView}
            onNewItemClick={onNewItemToConfig}
            onEditItem={onEditItem}
            onRemoveItem={onRemoveItem}
            tree={tree}/>
        </Col>

        <Col sm={7} lg={9}>
          <Card>
            <CardBody
              style={{scrollBehavior: "smooth", height: `calc(${props.height})`}}
              className="overflow-auto py-5">
              <Row>
                {rows.sort((a, b) => a.type.localeCompare(b.type))
                  .map(({type, field}) => {
                      const value: any = getValue(field);

                      switch (type as PropsType) {
                        case PropsType.STRING:
                          return (
                            <Col key={field} sm={12}>
                              <FormGroup>
                                <SplitLabel label={field}/>
                                <SmallTextComponent
                                  onChange={e => onChange(e, field)}
                                  value={value ?? ""} id={field}
                                  name={field}/>
                              </FormGroup>
                            </Col>
                          )

                        case PropsType.NUMBER:
                          return (
                            <Col key={field} sm={12}>
                              <FormGroup>
                                <SplitLabel label={field}/>
                                <NumberComponent
                                  onChange={e => onChange(e, field)}
                                  value={value ?? ""} id={field}
                                  name={field}/>
                              </FormGroup>
                            </Col>
                          )

                        case PropsType.OBJECT:
                          return (
                            <Col key={field} sm={12}>
                              <FormGroup>
                                <SplitLabel label={field}/>
                                <EditorComponent
                                  width="100%"
                                  enableSnippets
                                  value={JSON.stringify(
                                    value,
                                    null,
                                    "\t"
                                  )}
                                  onChange={value => {
                                    validateQuery(value ?? "", (query) => {
                                      onChange(query, field);
                                    })
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          )
                        case PropsType.BOOLEAN:
                          return (
                            <Col key={field} sm={12}>
                              <FormGroup>
                                <SplitLabel label={field}/><br/>
                                <Switch onChange={e => onChange(e, field)} checked={value}/>
                              </FormGroup>
                            </Col>
                          )
                        case PropsType.TABLE:
                          return (
                            <FormGroup key={field}>
                              <SplitLabel label={field}/>
                              <div className="d-flex justify-content-end">
                                <Button size="sm" color="primary"
                                        onClick={() => upsertItemTable(value, null, 0, field)}>
                                  <FontAwesomeIcon icon={faPlusCircle}/> {t("common:new")}
                                </Button>
                                <Button className="ms-2" size="sm" color="success"
                                        onClick={() => {
                                          function addNewColumn(e: FormEvent<HTMLFormElement>, valueRow: { name: string, option?: IOption }) {
                                            e.preventDefault();
                                            onChange(value.map((e: any) => ({
                                              ...e,
                                              [valueRow.name]: valueRow.option?.default_value ?? ""
                                            })), field);
                                            dispatch(actionsModal.closeModal({
                                              type: ModalTypes.DYNAMIC_COMPONENT_MODAL
                                            }));
                                          }

                                          dispatch(actionsModal.openModal({
                                            type: ModalTypes.DYNAMIC_COMPONENT_MODAL,
                                            value: {name: "default_column_name"},
                                            form: "new_column_form_id",
                                            typeForm: "submit",
                                            state: State.PENDING,
                                            title: "New column",
                                            renderComponent: (v, cb) => (
                                              <NewPropertyComponent
                                                id="new_column_form_id"
                                                value={v}
                                                onChange={cb}
                                                submit={addNewColumn}/>
                                            )
                                          }))
                                        }}>
                                  <FontAwesomeIcon
                                    icon={faPlus}/> {t("common:new").concat(" ", t("common:column"))}
                                </Button>
                              </div>
                              <CustomTableComponent
                                data={value}
                                actions={[
                                  {
                                    label: <FontAwesomeIcon icon={faEdit}/>,
                                    type: "primary",
                                    onAction: (e, i) => upsertItemTable(value, e, i as number, field)
                                  },
                                  {
                                    label: <FontAwesomeIcon icon={faTrash}/>,
                                    type: "danger",
                                    onAction: (e, i) => removeItemTable(value, i as number, field)
                                  }
                                ]}
                                columns={Object.keys(value ? value[0] : {})
                                  .map(key => {
                                    let _type: CustomTableColumnType = "String";

                                    switch (typeof value[0][key]) {
                                      case "boolean":
                                        _type = "Boolean"
                                        break;
                                      case "object":
                                        _type = "Json";
                                        break;
                                    }
                                    return {
                                      type: _type,
                                      header: capitalize(key),
                                      name: key,
                                    }
                                  })}
                              />
                            </FormGroup>
                          )

                        case PropsType.ARRAY_STRING:
                          return (
                            <Col key={field} sm={12}>
                              <SplitLabel label={field}/>
                              <InputTags onTags={e => onChange(e.values, field)} values={value}/>
                            </Col>
                          )

                        case PropsType.ARRAY_NUMBER:
                          return (
                            <Col key={field} sm={12}>
                              <SplitLabel label={field}/>
                              <InputTags onTags={e => onChange(e.values.map(toNumber), field)} values={value}/>
                            </Col>
                          )
                        default:
                          return null;
                      }
                    }
                  )}

                {!rows.length && (
                  <div className="justify-content-center align-items-center d-flex">
                    <h5 className="text-secondary">{t("common:no_results")}</h5>
                  </div>
                )}
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default DynamicFieldsFromConfig;
