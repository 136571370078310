import React, { useEffect, useState } from "react";
import FullCalendar, { EventClickArg, EventDropArg } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listGridPlugin from "@fullcalendar/list";
import interactionPlugin, {
  DateClickArg,
  EventResizeDoneArg,
} from "@fullcalendar/interaction";
import ModalComponent from "../../Modal/ModalComponent/ModalComponent";
import {
  convertDateToNumberDate,
  convertNumberAndTimeToDate,
  getFormatDate,
  getTimeFormat,
} from "../../../../utils";
import { Appointment } from "../../../../types/Appointment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faTimesCircle } from "@fortawesome/free-solid-svg-icons";

interface IProps {
  disabled?: boolean;
  hiddenDays?: number[];
  readOnly?: boolean;
  loading?: boolean;
  placeholder?: string;
  eventDrop?: (e: EventDropArg) => void;
  dateClickEvent?: (e: DateClickArg) => void;
  eventResize?: (e: EventResizeDoneArg) => void;
  value?: Date | null;
  timeSecondary?: Date;
  currentAppointment?: Appointment;
  events?: Appointment[];
  eventUpdate?: (
    date: Date | null,
    appointment: Appointment[],
    event: MouseEvent,
  ) => void;
  appointmentConfigNew?: {
    title: string;
    color: string;
    data: any;
    allDay: boolean;
    editable: boolean;
    selectable: boolean;
  };
}

const CalendarPickerComponent = (props: IProps) => {
  const {
    loading,
    placeholder,
    value,
    timeSecondary,
    eventDrop,
    eventResize,
    events = [],
    dateClickEvent,
    hiddenDays,
    disabled,
    currentAppointment,
    appointmentConfigNew = {
      title: "no defined",
      color: "red",
      data: {} as Appointment,
      allDay: false,
      editable: true,
      selectable: true,
    },
    eventUpdate = (
      date: Date | null,
      appointment: Appointment[],
      event: MouseEvent,
    ) => null,
  } = props;

  const [open, toggle] = useState(false);
  const [val, setVal] = useState("");

  const onToggle = () => toggle((e) => !e);

  function updateEvent(e: EventClickArg) {
    if (value && timeSecondary) {
      const app = {
        ...e.event._def.extendedProps.data,
        start_date: convertDateToNumberDate(value),
        due_date: convertDateToNumberDate(timeSecondary),
        start_time: getTimeFormat(value).stringTime,
        end_time: getTimeFormat(timeSecondary).stringTime,
      };
      eventUpdate(e.event.start, [app], e.jsEvent);
    }
  }

  useEffect(() => {
    const isEqualDate =
      value &&
      timeSecondary &&
      convertDateToNumberDate(value) === convertDateToNumberDate(timeSecondary);
    const newVal =
      value && timeSecondary
        ? `${getFormatDate(value, "MMMM d, yyyy h:mm aa")} to ${getFormatDate(
            timeSecondary,
            isEqualDate ? "h:mm aa" : "MMMM d, yyyy h:mm aa",
          )}`
        : "";
    if (newVal !== val) {
      setVal(newVal);
    }
  }, [value, timeSecondary]);

  const getColor = (appo: Appointment) => {
    return currentAppointment?._KEY === appo._KEY ? "orange" : undefined;
  };

  const newEvent =
    !currentAppointment && value && timeSecondary
      ? [
          {
            ...appointmentConfigNew,
            start: value,
            end: timeSecondary,
          },
        ]
      : [];

  const eventsMap = events.map((appo) => {
    let start = convertNumberAndTimeToDate(appo.start_date, appo.start_time);
    let end = appo.due_date
      ? convertNumberAndTimeToDate(appo.due_date, appo.end_time ?? "00:00")
      : undefined;
    if (appo._KEY === currentAppointment?._KEY && value) {
      if (value) start = value;

      if (timeSecondary) end = timeSecondary;
    }
    return {
      color: getColor(appo),
      allDay: appo.all_day,
      start,
      end,
      title: appo.summary,
      data: appo,
      editable: true,
      selectable: true,
    };
  });

  return (
    <div>
      <div className="position-relative w-100">
        <FontAwesomeIcon
          className="position-absolute pt-3 ps-2"
          icon={faCalendar}
        />
        <input
          disabled={disabled}
          value={val}
          readOnly
          style={{ paddingLeft: "25px" }}
          className="form-control"
          type="text"
          placeholder={placeholder ?? "Select date"}
          onClick={onToggle}
        />
      </div>

      <ModalComponent size={"xl"} noFooter isOpen={open} toggle={onToggle}>
        <div className="position-relative py-5">
          <FontAwesomeIcon
            style={{ top: "0", right: "0" }}
            onClick={onToggle}
            size="lg"
            className="position-absolute pointer"
            icon={faTimesCircle}
          />

          <FullCalendar
            hiddenDays={hiddenDays}
            events={[...eventsMap, ...newEvent]}
            headerToolbar={{
              left: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
              center: "title",
            }}
            now={value ?? new Date()}
            initialDate={value ?? new Date()}
            initialView="dayGridMonth"
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              listGridPlugin,
              interactionPlugin,
            ]}
            editable={!loading}
            selectable={!loading}
            monthMode
            eventContent={(e) => {
              return {
                html: `<div title='${e.event._def.title}' class='overflow-auto'>
                  <small style='color: ${
                    e.view.type === "dayGridMonth" ? e.backgroundColor : ""
                  }'>
                    ${
                      e.event._def.title.replaceAll("\n", "<br>") ||
                      "Event created without title"
                    }
                  </small>
                  <br/>
                  <small>${
                    e.event._def.extendedProps.data.account_data?.data.company
                      .company_name ?? ""
                  }</small>   
                </div>`,
              };
            }}
            droppable={!loading}
            eventDrop={eventDrop}
            dateClick={dateClickEvent}
            eventClick={updateEvent}
            eventResize={eventResize}
          />
        </div>
      </ModalComponent>
    </div>
  );
};

export default CalendarPickerComponent;
