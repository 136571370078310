import * as React from 'react';
import {useContext, useRef} from 'react';
import {ActionButton, ReportContext} from "../../NewReportGeneratorComponent";
import {useFieldArray, useForm} from "react-hook-form";
import useTranslate from "../../../../hooks/useTranslate";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {AnalyticQueryAction} from "../../../../types/Analytic";
import DefaultValueMenuActionComponent from "./DefaultValueMenuActionComponent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


type Props = {
    index: number
    action: AnalyticQueryAction
    color?: string
};

type Form = {
    column: string
    new_value: string
}
const NewDefaultValueComponent = ({action, index, color}: Props) => {
    const {setQueryAction, query} = useContext(ReportContext)
    const {control, watch} = useForm<{ columns: Form[] }>()
    const {t} = useTranslate("common")
    const {append, fields, update, remove, replace} = useFieldArray({
        control,
        name: "columns"
    })
    let onMount = useRef(false)

    React.useEffect(() => {
        const subscription = watch((obj) => {
            const columns = obj['columns']

            if (columns && columns.length > 0) {
                const defaultValueColumns = columns.reduce((obj: { [key: string]: string }, column) => {
                    if (column && column.column && column.new_value) {
                        obj[column.column] = column.new_value
                    }
                    return obj;
                }, {})


                setQueryAction({
                    ...action,
                    columns: Object.keys(defaultValueColumns).length > 0 ? defaultValueColumns : {}
                }, index)

            }
        });
        return () => subscription.unsubscribe();
    }, [watch, index, action, query]);

    React.useEffect(() => {
        if (!onMount.current) {
            onMount.current = true

            if (action.columns && Object.keys(action.columns).length > 0) {
                const columns = Object.keys(action.columns).map((key) => ({
                    column: key,
                    new_value: action.columns ? (action.columns as { [p: string]: any })[key] : ""
                }))

                replace(columns)
            }
        }
    }, [action]);

    return (
        <div className="d-flex flex-wrap gap-2">

            {fields.map((field, indexField) => (
                <DefaultValueMenuActionComponent update={update} control={control} key={field.id} index={indexField}
                                                 watch={watch} color={color}
                                                 indexAction={index} remove={remove} queryAction={action}/>
            ))}

            <ActionButton className="btn" color={color ?? "white"} onClick={() => {
                append({
                    column: "",
                    new_value: ""
                })
            }}>
                <FontAwesomeIcon icon={faPlus} className="pointer white"/>
                {fields.length === 0 && <span>{t("add")}</span>}
            </ActionButton>
        </div>
    );
};

export default NewDefaultValueComponent